import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";

function TwoSemiCircleGauge({ labels, series, title, subTitle, height = 200 }) {
  let chartOptions = {
    series: [series] || [],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: ["50%", "40%"],
            margin: 3,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          hollow: {
            margin: 5,
            size: "75%",
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
              offsetY: -2,
              fontSize: "22px",
            },
            stroke: {
              lineCap: "round",
              width: ["0%", "0%"],
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#FDD835", "#FF4560"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [
            {
              offset: 0,
              color: "#00E396", // Green
              opacity: 1,
            },
            {
              offset: 50,
              color: "#FDD835", // Yellow
              opacity: 1,
            },
            {
              offset: 100,
              color: "#FF4560", // Red
              opacity: 1,
            },
          ],
        },
      },
      // labels: ["First Semi-Circle", "Second Semi-Circle"],
    },
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Row>
            <Col md={5} sm={5}>
              <div className="header-title">
                <h6 className="m-0">{title}</h6>
                <h4 className="text-danger m-0">{subTitle}</h4>
              </div>
            </Col>
            <Col md={7} sm={7}>
              <div
                id="chart"
                className="d-flex align-items-end justify-content-center"
                style={{ pointerEvents: "none" }} // Disable pointer events
              >
                <Chart
                  options={chartOptions.options}
                  series={chartOptions.series}
                  type="radialBar"
                  height={height}
                  className="mb-n5"
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default TwoSemiCircleGauge;
