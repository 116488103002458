export const requisitionStatus = {
  1: "Draft",
  2: "Pending",
  4: "In Progress",
  5: "Completed",
};
export const requisitionStatusCode = {
  1: "draft",
  2: "pending",
  4: "in-progress",
  5: "completed",
};
export const requisitionStatusColor = {
  1: "warning",
  2: "primary",
  4: "info",
  5: "success",
};
export const getStatusId = (status) => {
  let statusId = 0;
  Object.keys(requisitionStatusCode).forEach((key) => {
    if (requisitionStatusCode[key] === status) {
      statusId = key;
    }
  });
  return statusId;
};
