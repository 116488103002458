import { memo, Fragment, useEffect, useState } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button, FormCheck } from "react-bootstrap";

//components
import Card from "../../../../components/bootstrap/card";

//router
import { Link, useParams } from "react-router-dom";
// react-select
import Select from "react-select";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { getService } from "../../../../api/services/get-services";
import {
  ADD_VENDOR_POST,
  CREATE_VENDOR_GET,
  UPDATE_VENDOR_POST,
  VENDOR_EDIT_GET,
} from "../../../../api/endpoints/vendor-management-endpoints";

// import yup and formik
import * as Yup from "yup";
import { useFormik } from "formik";
import postService from "../../../../api/services/post-service";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import toast from "react-hot-toast";
import withAuthorization from "../../../../components/hoc/with-authorization";
import AddVendorLoader from "../../../../skeleton/vendor-management-loader/add-vendor-loader";
const AddVendor = memo(() => {
  const { redirectTo } = useRedirect();
  const params = useParams();
  //usestate for the departments, principal entity, reporting manager, role
  const [department, setDepartment] = useState([]);
  const [loading, setLoading] = useState(false); //loading state
  const [pageLoader, setPageLoader] = useState(false);
  const [masterEntity, setMasterEntity] = useState([]);
  const [fileType, setFileType] = useState([]);

  //arrange principal entity in the form of array of objects with value and label with parent marked as first then their child
  const arrangePrincipalEntity = (data) => {
    let principalEntity = [];
    data.forEach((_) => {
      if (_.principal_entity_parent_id == 0) {
        console.log("parent", _);
        principalEntity.push({
          principal_entity_id: _.principal_entity_id,
          principal_entity_name: _.principal_entity_name,
          principal_entity_parent_id: null,
        });
      } else {
        console.log("child", _);
        //find its parent
        let parent = data.find(
          (__) => __.principal_entity_id == _.principal_entity_parent_id
        );
        // find parent of parent
        let parentOfParent = data.find(
          (__) => __?.principal_entity_id == parent?.principal_entity_parent_id
        );
        console.log("parent_of_parent", parentOfParent);
        console.log("parent_of_child", parent);
        principalEntity.push({
          principal_entity_id: _.principal_entity_id,
          principal_entity_name:
            (parent?.principal_entity_name
              ? parent?.principal_entity_name + " -> "
              : "") + _.principal_entity_name,
          principal_entity_parent_id:
            parentOfParent?.principal_entity_id || null,
        });
      }
    });
    return principalEntity;
  };
  const getPrincipleEntitiesForSelect = (data) => {
    //untill all elements have principal_entity_parent_id null perform the arrangePrincipalEntity function
    let principalEntity = arrangePrincipalEntity(data);
    if (principalEntity.every((_) => _.principal_entity_parent_id == null)) {
      return principalEntity.map((_) => ({
        value: _.principal_entity_id,
        label: _.principal_entity_name,
      }));
    } else {
      return getPrincipleEntitiesForSelect(principalEntity);
    }
  };

  const getCreateVendorData = async (id = "") => {
    console.log("getCreateVendorData");
    const response = !id
      ? await getService(CREATE_VENDOR_GET)
      : await getService(`${VENDOR_EDIT_GET}/${id}`);
    console.log("VendorAdd", response);
    let { department, master_entity, file_type, vendor } = response.data?.data;
    // let { department, master_entity, file_type, role, vendor } =
    //   response.data?.data;
    setDepartment(
      department.map((_) => ({
        value: _.department_id,
        label: _.department_name,
      }))
    );
    console.log("MASTER_ENTITIES", master_entity);
    let arrangedPrincipalEntity = getPrincipleEntitiesForSelect(master_entity);

    setMasterEntity(arrangedPrincipalEntity);
    setFileType(
      file_type.map((_) => ({ value: _.file_type_id, label: _.file_type_name }))
    );
    // setRole(
    //   role.map((_) => ({
    //     value: _.id,
    //     label: _.name,
    //     description: _.description,
    //   }))
    // );

    if (vendor) {
      //setValues of formik
      formik.setValues({
        name: vendor.name,
        mobile: vendor.phone,
        email: vendor.email,
        employeeCode: vendor.employee_code,
        department: vendor.user_to_department[0]?.department_id,
        fileType: vendor.file_type_id,
        principal_entity: vendor.principal_entity_id,
        // role: "",
        // principalEntity: "",
        // status: "",
      });
      // setVendorSelectedRole(vendor.vendor_role_id);
    }
    setPageLoader(false);
  };

  useEffect(() => {
    setPageLoader(true);
    // alert("VendorAdd" + params?.id);
    getCreateVendorData(params?.id);
    return () => {};
  }, []);
  //initial values
  const initialValues = {
    name: "",
    mobile: "",
    email: "",
    employeeCode: "",
    department: "",
    fileType: [],
    // role: "",
    // principalEntity: "",
    // status: "",
  };

  const onSubmit = (values) => {
    console.log("values", values);
    redirectTo("/vendor-management");
  };
  //schema validation of forms yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Vendor Name is Required"),
    mobile: Yup.string().required("Mobile Number is Required"),
    email: Yup.string().required("Email is Required"),
    employeeCode: Yup.string().required("Vendor Code is Required"),
    department: Yup.string().required("Department is Required"),
    fileType: Yup.array()
      .of(Yup.number().integer("Must be an integer"))
      .required("Required")
      .min(1, "Please select at least one file type"),
    principal_entity: Yup.string().required("Required"),
  });
  //using useFormik hook

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // if (vendorSelectedMasterEntity && vendorSelectedMasterEntity.length > 0) {
      let base_url = window.location.href;
      const splitter = "vendor-management/create";
      const parts = base_url.split(splitter);
      const actualUrl = parts[0] + "auth/create-password";
      let updateData = {
        name: values.name,
        email: values.email,
        phone: values.mobile,
        department: parseInt(values.department),
        principal_entity_id: parseInt(values.principal_entity),
        file_type_id: values.fileType,
        // vendor_role: vendorSelectedRole,
        employee_code: values.employeeCode,
        status: 1,
        created_by: 1,
      };
      let addData = {
        name: values.name,
        email: values.email,
        phone: values.mobile,
        department: parseInt(values.department),
        principal_entity_id: parseInt(values.principal_entity),
        file_type_id: values.fileType,
        // vendor_role: vendorSelectedRole,
        employee_code: values.employeeCode,
        status: 1,
        created_by: 1,
        frontend_url: actualUrl,
      };
      setLoading(true);
      let response = await postService(
        params.id ? `${UPDATE_VENDOR_POST}/${params.id}` : ADD_VENDOR_POST,
        params.id ? updateData : addData
      );
      if (response) {
        setLoading(false);
        if (response.isError) {
          toast.error(response.error);
          return;
        }
        console.log("response", response.data.message);
        toast.success(response?.data?.message);
        redirectTo("vendor-management/listing");
      }
      // } else {
      //   toast.error("Please Select Principal Entity");
      //   return;
      // }
    },
  });
  // const { handleSubmit, handleChange, values, errors } = formik;
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    formik;
  console.log("values", values);
  console.log("errors", errors);
  console.log("touched", touched);

  return (
    <>
      <Fragment>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">
              {params.id ? "Edit Vendor" : "Add New Vendor"}
            </h3>
          </div>
        </div>
        {!pageLoader ? (
          <Row>
            <Col lg="12">
              <Card>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">Vendor Details</h6>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="Name"
                            placeholder="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                          />
                          <label htmlFor="floatingInput">Name</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="mobile no."
                            placeholder="Mobile no."
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            isInvalid={!!errors.mobile}
                          />
                          <label htmlFor="floatingInput">Mobile No.</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.mobile}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="email"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <label htmlFor="floatingInput">Email</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" xl="3" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="name"
                            className=""
                            autoComplete="Vendor Code"
                            placeholder="Vendor Code"
                            name="employeeCode"
                            value={values.employeeCode}
                            onChange={handleChange}
                            isInvalid={!!errors.employeeCode}
                          />
                          <label htmlFor="floatingInput">Vendor Code</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.employeeCode}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">
                          Department & File Type
                        </h6>
                      </Col>
                      <Col lg="6" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            className=""
                            id="floatingInput1"
                            name="department"
                            value={values.department}
                            onChange={handleChange}
                            isInvalid={!!errors.department}
                          >
                            <option value="">Select Department</option>

                            {department.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Label htmlFor="floatingInput">
                            Select Department
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.department}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="6" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          {/* <Form.Select
                            className=""
                            id="floatingInput1"
                            name="fileType"
                            value={values.fileType}
                            onChange={handleChange}
                            isInvalid={!!errors.fileType}
                          >
                            <option value="">Select File Type</option>

                            {fileType.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select> */}
                          <Form.Floating className="custom-form-floating flex-grow-1">
                            <Select
                              name="fileType"
                              options={fileType}
                              placeholder="Select File Types"
                              isMulti
                              onChange={(selected) =>
                                setFieldValue(
                                  "fileType",
                                  selected?.map((f) => f.value)
                                )
                              }
                            />
                          </Form.Floating>
                          {/* <Form.Label htmlFor="floatingInput">
                            Select File Type
                          </Form.Label> */}
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {errors.fileType}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <h6 className="fs-6 fw-400 gray-300">
                          Principal Entity
                        </h6>
                      </Col>
                      <Col lg="6" className="mb-4">
                        {masterEntity.length > 0 && (
                          <div className="d-flex w-100">
                            <Form.Floating className="custom-form-floating flex-grow-1">
                              <Form.Select
                                className=""
                                id="floatingInput1"
                                name="principal_entity"
                                value={values.principalEntity}
                                onChange={handleChange}
                                isInvalid={!!errors.fileType}
                              >
                                <option value="">
                                  Select Principal Entity
                                </option>

                                {masterEntity.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.principal_entity}
                              </Form.Control.Feedback>
                            </Form.Floating>
                          </div>
                        )}
                      </Col>

                      {/* <Col lg="12" className="mb-3">
                      <h6 className="fs-6 fw-400 gray-300">Add Vendor Role</h6>
                    </Col>
                    {role.map((item, index) => (
                      <Col lg="12" className="mb-4 d-flex flex-wrap">
                        <Form.Check className="form-check-inline d-flex align-items-center">
                          <FormCheck.Input
                            type="radio"
                            className="form-check-input mt-0"
                            id="customCheck5"
                            name="role"
                            onChange={(e) => handleRoleChange(e, item.value)}
                            checked={vendorSelectedRole === item.value}
                          />
                          <FormCheck.Label
                            className="form-check-label ps-2"
                            htmlFor="customCheck5"
                          >
                            {item.label}
                          </FormCheck.Label>
                        </Form.Check>
                        {item.description && (
                          <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 bg-soft-secondary rounded-3">
                            {item.description}
                          </span>
                        )}
                      </Col>
                    ))} */}

                      <Col lg="12" className="d-flex justify-content-end">
                        <Button
                          className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                          type="submit"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {loading ? <PaginationLoader /> : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <AddVendorLoader />
        )}
      </Fragment>
    </>
  );
});

AddVendor.displayName = "AddVendor";
export default withAuthorization(AddVendor);
