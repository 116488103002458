import React, { useState, useContext, memo, Fragment } from "react";

// Router
import { Link, useLocation, useParams } from "react-router-dom";

// React-bootstrap
import {
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

// component
import SidebarMenu from "../../../../../../components/partials/components/sidebar/sidebar-menu";

// import { findIcon } from "../../../../dashboard/icons";
import { findIcon } from "../../../../../dashboard/icons";

// function CustomToggle({ children, eventKey, onClick }) {
//   const { activeEventKey } = useContext(AccordionContext);

//   const decoratedOnClick = useAccordionButton(eventKey, (active) =>
//     onClick({ state: !active, eventKey: eventKey })
//   );

//   const isCurrentEventKey = activeEventKey === eventKey;

//   return (
//     <Link
//       to="#"
//       aria-expanded={isCurrentEventKey ? "true" : "false"}
//       className="nav-link"
//       role="button"
//       onClick={(e) => {
//         decoratedOnClick(isCurrentEventKey);
//       }}
//     >
//       {children}
//     </Link>
//   );
// }

const SponsoredVerticalNav = memo(() => {
  let location = useLocation();
  let { pathname } = location;
  const { project_id } = useParams();

  return (
    <Fragment>
      <ul className="sub-nav">
        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/sponsored-content/summary/${project_id}`}
          title="Summary"
          minititle={findIcon("Dashboard", "outline", 20)}
        >
          <i className="icon">{findIcon("Dashboard", "outline", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/sponsored-content/analytics/${project_id}`}
          title="Analytics"
          minititle={findIcon("Overview", "dual-tone", 20)}
        >
          <i className="icon">{findIcon("Overview", "dual-tone", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/sponsored-content/audience/${project_id}`}
          title="Audience Insights"
          minititle={findIcon("Audience", "dual-tone", 20)}
        >
          <i className="icon">{findIcon("Audience", "dual-tone", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/sponsored-content/location/${project_id}`}
          title="Location Insights"
          minititle={findIcon("Location", "outline", 22)}
        >
          <i className="icon">{findIcon("Location", "outline", 22)}</i>
        </SidebarMenu>
        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/sponsored-content/content/${project_id}`}
          title="Contents"
          minititle={findIcon("Creative", "dual-tone", 20)}
        >
          <i className="icon">{findIcon("Creative", "dual-tone", 20)}</i>
        </SidebarMenu>
      </ul>
    </Fragment>
  );
});
SponsoredVerticalNav.displayName = "SponsoredVerticalNav";
export default SponsoredVerticalNav;
