import React ,{memo} from "react";
import { Card, Row, Col } from "react-bootstrap";
import Skeleton from "../../../components/skeleton";

function HcpMeterCardSkeleton ({ labels }) {
  return (
    <Card data-aos="fade-up" data-aos-delay="900" className="h-100">
      <Card.Header className="flex-wrap d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">
            <Skeleton width={100} height={40} />
          </h4>
        </div>
        {/* <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        href="#"
        variant="text-secondary dropdown-toggle"
        size="sm"
        id="dropdownMenuButton1"
      >
        This Week
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#">This Week</Dropdown.Item>
        <Dropdown.Item href="#">This Month</Dropdown.Item>
        <Dropdown.Item href="#">This Year</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
      </Card.Header>
      <Card.Body>
        <div className="flex-wrap d-flex align-items-center justify-content-between">
          {/* <Chart
            className="col-md-8 col-lg-8"
            // options={engagementMeterChart.options}
            // series={engagementMeterChart.series}
            type="radialBar"
            height="300"
          /> */}
          <Skeleton variant="rect" width={300} height={300} />
          <Col md={4} lg={4} className="d-grid gap">
            {labels.map((key, ind) => (
              <div className="d-flex align-items-start">
                <div className="ms-3">
                  <h4 className="m-0">
                    <Skeleton height={40} width={50} />
                  </h4>
                  <span key={key} className="text-secondary">
                    <Skeleton width={90} />
                  </span>
                </div>
              </div>
            ))}
          </Col>
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(HcpMeterCardSkeleton);
