import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function CardSkeleton({ height = "100%", width = "100%" }) {
  return (
    <Card className="h-100 m-0 mb-4">
      <Card.Body className=" p-md-4">
        <Skeleton
          variant="square"
          width={width}
          height={height}
          className="rounded-3"
        />
      </Card.Body>
    </Card>
  );
}

export default memo(CardSkeleton);
