import React, { memo, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { redirect, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import postServiceSmsGenarator from "../../../../api/services/post-service-sms-generator";
import Masonry from "react-masonry-css";
import TemplateSuggestionCard from "../../template-manager/components/template-suggestion-card";
import mobile from "../../../../assets/images/mobile-bg.png";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import GeneratorLoader from "../../../../skeleton/embedded-tools/generator-loader";
import TemplateImageLoader from "../../../../skeleton/embedded-tools/template-image-loader";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};

const EmbeddedSmsCreate = () => {
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
  const [selectedSms, setSelectedSms] = useState("");
  const [templateTone, setTemplateTone] = useState("");
  const [generatedSmsList, setGeneratedSmsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { redirectTo } = useRedirect();
  const initialValues = {
    name: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("values ==>", values);
    },
  });
  const handleEmojiClick = (data) => {
    // alert("handleEmojiClick" + data);
    setTemplateTone(data);
  };
  const checkValidation = () => {
    if (values.name == "") {
      setErrors({ ...errors, name: "Please enter template name" });
      return;
    }
    if (values.description == "") {
      setErrors({ ...errors, description: "Please enter description" });
      return;
    }
    if (!errors.name && !errors.description) {
      getSmsTemplateSuggestion();
    }
  };
  const getSmsTemplateSuggestion = async () => {
    setSuggestionIsLoading(true);
    const templateSuggestionPayload = {
      action: values.name + " " + values.description,
      tone: templateTone,
      type: "sms",
    };
    try {
      // setVariableIsLoading(true);
      const response = await postServiceSmsGenarator(templateSuggestionPayload);
      console.log("suggestion list ", response?.data);
      setSuggestionIsLoading(false);
      parseGeneratedSmsList(response?.data?.output);
    } catch (error) {
      setSuggestionIsLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };
  // const parseGeneratedSmsList = (data) => {
  //   if (data) {
  //     const smsList = Object.values(JSON.parse(data));
  //     // console.log("data", data, "\n", smsList);
  //     setGeneratedSmsList(smsList);
  //   }
  // };

  const parseGeneratedSmsList = (data) => {
    if (data) {
      const smsList = typeof data == "string" ? JSON.parse(data) : data;
      let generatedList = smsList.map((item) => {
        return Object.values(item)[0];
      });
      setGeneratedSmsList(generatedList);
    }
  };
  const onTemplateSuggestionClick = (data) => {
    setSelectedSms(data);
  };

  const onContinueToEditClick = () => {
    // setEditType("newTemplate");
    redirectTo("template-management/sms-template/create", {
      smsEditType: "newTemplate",
      suggestionSmsContent: selectedSms,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      <Card.Body className="p-0 p-md-4">
        <div className="p-0 p-sm-2 p-md-4 bg-soft-secondary rounded-3">
          <Row className="justify-content-center align-items-start">
            <Col lg={9} xs={12} className="position-relative mb-5 mb-lg-0">
              {!loading ? (
                <div className="p-2 p-md-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                  <div>
                    <Row>
                      <Col lg={12} className="mb-3">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="floatingInput1"
                            autoComplete="Name"
                            placeholder="Name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            isInvalid={errors.name}
                            disabled={suggestionIsLoading}
                          />
                          <Form.Label htmlFor="floatingInput">Name</Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg={12} className="mb-3">
                        <Form.Floating className="h-100 custom-form-floating">
                          <Form.Control
                            as="textarea"
                            type="text"
                            className="h-100"
                            id="floatingInput1"
                            autoComplete="Name"
                            placeholder="Name"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            isInvalid={errors.description}
                            disabled={suggestionIsLoading}
                          />
                          <Form.Label htmlFor="floatingInput">
                            Prompt for Template Creation
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg={12} className="mb-4 mt-auto ">
                        <p>Tone Selector:</p>
                        <div className="col-12 align-item-between d-flex flex-wrap gap-3">
                          <span
                            aria-label="Professional"
                            onClick={() => handleEmojiClick("Professional")}
                            className={`${
                              templateTone == "Professional"
                                ? "border-warning fw-semibold"
                                : "text-secondary"
                            } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Professional
                          </span>
                          <span
                            aria-label="Casual"
                            onClick={() => handleEmojiClick("Casual")}
                            className={`${
                              templateTone == "Casual"
                                ? "border-warning fw-semibold"
                                : "text-secondary"
                            } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Casual
                          </span>
                          <span
                            aria-label="Serious"
                            onClick={() => handleEmojiClick("Serious")}
                            className={`${
                              templateTone == "Serious"
                                ? "border-warning fw-semibold"
                                : "text-secondary"
                            } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Serious
                          </span>
                          <span
                            aria-label="Humorous"
                            onClick={() => handleEmojiClick("Humorous")}
                            className={`${
                              templateTone == "Humorous"
                                ? "border-warning fw-semibold"
                                : "text-secondary"
                            } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Humorous
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Col lg={12}>
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                    >
                      {generatedSmsList?.length > 0 &&
                        generatedSmsList.map((item, index) => (
                          <>
                            <TemplateSuggestionCard
                              data={item}
                              click={onTemplateSuggestionClick}
                              selectedSms={selectedSms}
                              type=""
                            />
                          </>
                        ))}
                    </Masonry>
                  </Col>
                  <Col className="flex-grow-0">
                    <div className="d-flex gap-3 justify-content-end">
                      <Button
                        variant="primary"
                        onClick={checkValidation}
                        disabled={suggestionIsLoading}
                      >
                        {!suggestionIsLoading
                          ? "Auto Generate"
                          : "Generating Suggestion..."}
                      </Button>
                      {selectedSms && (
                        <Button
                          variant="primary"
                          onClick={onContinueToEditClick}
                        >
                          Continue To Sms Creator
                        </Button>
                      )}
                    </div>
                  </Col>
                </div>
              ) : (
                <GeneratorLoader />
              )}
            </Col>

            <Col lg={3} xs={12} className="position-sticky top-0">
              {!loading ? (
                <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                  <div className="position-relative flex-fill">
                    <img
                      src={mobile}
                      alt="mobile"
                      className="h-auto w-100 d-block position-relative z-0"
                    />
                    <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                      {selectedSms && (
                        <div className="h-100 rounded-5 p-4 mobile-preview position-relative ">
                          <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                            {selectedSms}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <TemplateImageLoader />
              )}
            </Col>
          </Row>
        </div>
      </Card.Body>
    </>
  );
};

export default EmbeddedSmsCreate;
