export const GET_RESOURCES_LIST = "/resource/list";
export const GET_RESOURCES_CREATE = "/resource/create";
export const RESOURCES_FILE_UPLOAD = "/resource/fileupload";
export const RESOURCES_ADD = "/resource/add";
export const ALL_FAQ_LIST = "/faq/list";
export const RESOURCES_DETAILS = "/resource/detail/";


// support

export const GET_SUPPORT_DATA = "/support/create";
export const SUPPORT_FILE_UPLOAD = "/support/fileupload";
export const SUPPORT_ADD = "/support/add";