import React from 'react'
import CountUp from "react-countup";
import { Card } from "react-bootstrap";
export default function HcpCountCard({ onClick = () => { console.log("hit defaut function") }, variant = "", title = "", totalCount }) {
    console.log("count_start", totalCount, Math.random() * (totalCount + 1), Math.floor(Math.random() * (totalCount + 1)))
    return (
        <Card >{ /**onClick={onClick} */}
            <Card.Body className={`${variant} rounded-3 cursor-pointer`}>
                <div className="d-flex justify-content-between ">
                    <div className={`${variant} rounded p-3 d-flex align-items-center`}>
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                            <path
                                fill="currentColor"
                                d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                            />
                        </svg>
                    </div>
                    <div className="d-flex flex-column justify-content-end align-items-end cursorPointer">
                        <span className="fs-5">{title}</span>
                        <h4 className="mt-4 fw-semibold">
                            {totalCount}
                            {/* <CountUp start={totalCount - 10} end={totalCount} duration={3} /> */}
                        </h4>
                    </div>
                </div>
            </Card.Body>
        </Card >
    )
}
