import {
  KEY_SUMMARY_ANALYTICS,
  KEY_SUMMARY_LIST,
  KEY_SUMMARY_TAGS,
  KEY_SUMMARY_CONTENT_GRAPH,
  KEY_SUMMARY_CONTENT_READ,
  KEY_SUMMARY_CTA_VIEW_READ,
  KEY_SUMMARY_LOCATION_MAP,
  KEY_SUMMARY_DEVICE_DISTRIBUTION,
  KEY_SUMMARY_ZONAL_DISTRIBUTION,
  KEY_ANALYTICS_CONTENT_VIEWS,
  KEY_ANALYTICS_CONTENT_REACH,
  KEY_ANALYTICS_LOCATION_DISTRIBUTION,
  KEY_ANALYTICS_DEVICE_VIEWS,
  KEY_ANALYTICS_ZONE_VIEWS,
  KEY_ANALYTICS_CONTENT_CATEGORY,
  KEY_ANALYTICS_MOBILE_DEVICE_VIEWS,
  KEY_ANALYTICS_BROWSER_VIEWS,
  KEY_AUDIENCE_DAYWISE_PERFORMANCE,
  KEY_AUDIENCE_ZONAL_DISTRIBUTION,
  KEY_AUDIENCE_VIEWS_BY_SCREEN_SIZE,
  KEY_AUDIENCE_DEVICE_DISTRIBUTION,
  KEY_AUDIENCE_SPECIALITY_BREAKUP,
  KEY_AUDIENCE_SPECIALITIES_REPORT,
  KEY_LOCATION_MAP_DISTRIBUTION,
  KEY_LOCATION_MAJOR_CITIES,
  KEY_LOCATION_ZONAL_DISTRIBUTION,
  KEY_LOCATION_REPORT,
  KEY_CONTENT_LIST_P2,
  KEY_CONTENT_LIST,
  CREATIVE_DETAILS,
  KEY_CONTENT_LIST_P3,
  KEY_LOCATION_REPORT_CTA,
  KEY_AUDIENCE_SPECIALITIES_REPORT_CTA,
} from "../../query-constants/workspace/sponsored-content-constant";

import {
  GET_SPONSORED_CONTENT_SUMMARY_ANALYTICS,
  GET_SPONSORED_CONTENT_SUMMARY_LIST,
  GET_SPONSORED_CONTENT_SUMMARY_CONTENT_GRAPH,
  GET_SPONSORED_CONTENT_SUMMARY_CONTENT_READ,
  GET_SPONSORED_CONTENT_SUMMARY_CTA_VIEW_READ,
  GET_SPONSORED_CONTENT_SUMMARY_LOCATION_MAP,
  GET_SPONSORED_CONTENT_SUMMARY_DEVICE_DISTRIBUTION,
  GET_SPONSORED_CONTENT_SUMMARY_ZONAL_DISTRIBUTION,
  GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_VIEWS,
  GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_REACH,
  GET_SPONSORED_CONTENT_ANALYTICS_LOCATION_DISTRIBUTION,
  GET_SPONSORED_CONTENT_ANALYTICS_DEVICE_VIEWS,
  GET_SPONSORED_CONTENT_ANALYTICS_ZONE_VIEWS,
  GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_CATEGORY,
  GET_SPONSORED_CONTENT_ANALYTICS_MOBILE_DEVICE_VIEWS,
  GET_SPONSORED_CONTENT_ANALYTICS_BROWSER_VIEWS,
  GET_SPONSORED_CONTENT_AUDIENCE_DAYWISE_PERFORMANCE,
  GET_SPONSORED_CONTENT_AUDIENCE_ZONAL_DISTRIBUTION,
  GET_SPONSORED_CONTENT_AUDIENCE_VIEWS_BY_SCREEN_SIZE,
  GET_SPONSORED_CONTENT_AUDIENCE_DEVICE_DISTRIBUTION,
  GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITY_BREAKUP,
  GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITIES_REPORT,
  GET_SPONSORED_CONTENT_LOCATION_MAP_DISTRIBUTION,
  GET_SPONSORED_CONTENT_LOCATION_MAJOR_CITIES,
  GET_SPONSORED_CONTENT_LOCATION_ZONAL_DISTRIBUTION,
  GET_SPONSORED_CONTENT_LOCATION_REPORT,
  GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST,
  GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST_P2,
  GET_CREATIVE_DETAILS,
  GET_SPONSORED_CONTENT_ANALYTICS_LOCATION_DISTRIBUTION_READ,
  GET_SPONSORED_CONTENT_ANALYTICS_DEVICE_VIEWS_READ,
  GET_SPONSORED_CONTENT_ANALYTICS_ZONE_VIEWS_READ,
  GET_SPONSORED_CONTENT_ANALYTICS_MOBILE_DEVICE_READ,
  GET_SPONSORED_CONTENT_ANALYTICS_BROWSER_READ,
  GET_SPONSORED_CONTENT_AUDIENCE_READ_BY_SCREEN_SIZE,
  GET_SPONSORED_CONTENT_SUMMARY_LOCATION_MAP_READS,
  GET_SPONSORED_CONTENT_SUMMARY_DEVICE_DISTRIBUTION_READS,
  GET_SPONSORED_CONTENT_SUMMARY_ZONAL_DISTRIBUTION_READS,
  GET_SPONSORED_CONTENT_LOCATION_MAJOR_CITIES_READS,
  GET_SPONSORED_CONTENT_LOCATION_ZONAL_DISTRIBUTION_READS,
  GET_SPONSORED_CONTENT_LOCATION_REPORT_READS,
  GET_SPONSORED_CONTENT_LOCATION_MAP_DISTRIBUTION_READS,
  GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST_P2_CTA,
  GET_SPONSORED_CONTENT_LOCATION_REPORT_CTA,
  GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITIES_REPORT_CTA,
} from "../../../api/endpoints/sponsored-content-endpoints";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../api/instances/axiosInstance";

const staleTime = 300000;
const cacheTime = 600000;

export const useGetSummaryAnalytics = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_ANALYTICS, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_ANALYTICS}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetSummaryList = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_LIST}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.content_data ?? {},
      enabled: !!project_id,
    }
  );
};

export const useGetSummaryContentGraph = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_CONTENT_GRAPH, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_CONTENT_GRAPH}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetSummaryContentRead = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_CONTENT_READ, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_CONTENT_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetSummaryCtaReport = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_CTA_VIEW_READ, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_CTA_VIEW_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: !!project_id && !!payload?.content_id,
      select: (res) => res?.data?.data?.cta_data ?? {},
    }
  );
};

export const useGetSummaryLocationReport = (project_id, type, payload) => {
  return useQuery(
    [KEY_SUMMARY_LOCATION_MAP, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type === "reads"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_LOCATION_MAP_READS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_LOCATION_MAP}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetSummaryDeviceDistribution = (project_id, type, payload) => {
  return useQuery(
    [
      KEY_SUMMARY_DEVICE_DISTRIBUTION,
      project_id,
      type,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        type === "reads"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_DEVICE_DISTRIBUTION_READS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_DEVICE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetSummaryZonalDistribution = (project_id, type, payload) => {
  return useQuery(
    [KEY_SUMMARY_ZONAL_DISTRIBUTION, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type === "reads"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_ZONAL_DISTRIBUTION_READS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_SUMMARY_ZONAL_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

//
export const useGetAnalyticsContentViews = (project_id, payload) => {
  return useQuery(
    [KEY_ANALYTICS_CONTENT_VIEWS, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_VIEWS}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAnalyticsContentReach = (project_id, payload) => {
  return useQuery(
    [KEY_ANALYTICS_CONTENT_REACH, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_REACH}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAnalyticsLocationDistribution = (
  project_id,
  type,
  payload
) => {
  return useQuery(
    [
      KEY_ANALYTICS_LOCATION_DISTRIBUTION,
      project_id,
      type,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        type == "views"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_LOCATION_DISTRIBUTION}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_LOCATION_DISTRIBUTION_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAnalyticsDeviceViews = (project_id, type, payload) => {
  return useQuery(
    [KEY_ANALYTICS_DEVICE_VIEWS, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type == "views"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_DEVICE_VIEWS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_DEVICE_VIEWS_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAnalyticsZoneViews = (project_id, type, payload) => {
  return useQuery(
    [KEY_ANALYTICS_ZONE_VIEWS, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type == "views"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_ZONE_VIEWS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_ZONE_VIEWS_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAnalyticsContentCategory = (project_id, payload) => {
  return useQuery(
    [KEY_ANALYTICS_CONTENT_CATEGORY, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_CATEGORY}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAnalyticsMobileDeviceViews = (project_id, type, payload) => {
  return useQuery(
    [
      KEY_ANALYTICS_MOBILE_DEVICE_VIEWS,
      project_id,
      type,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        type == "views"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_MOBILE_DEVICE_VIEWS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_MOBILE_DEVICE_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAnalyticsBrowserViewsReport = (
  project_id,
  type,
  payload
) => {
  return useQuery(
    [KEY_ANALYTICS_BROWSER_VIEWS, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type == "views"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_BROWSER_VIEWS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_ANALYTICS_BROWSER_READ}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

//
export const useGetAudienceDaywisePerformance = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_DAYWISE_PERFORMANCE, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_DAYWISE_PERFORMANCE}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAudienceZonalDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_ZONAL_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_ZONAL_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAudienceViewsByScreensize = (project_id, type, payload) => {
  return useQuery(
    [
      KEY_AUDIENCE_VIEWS_BY_SCREEN_SIZE,
      project_id,
      type,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        type == "views"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_VIEWS_BY_SCREEN_SIZE}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_READ_BY_SCREEN_SIZE}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAudienceDeviceDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_DEVICE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_DEVICE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAudienceSpecialityBreakup = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_SPECIALITY_BREAKUP, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITY_BREAKUP}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAudienceSpecialitiesReport = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_SPECIALITIES_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITIES_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetAudienceSpecialitiesReportCTA = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_SPECIALITIES_REPORT_CTA, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITIES_REPORT_CTA}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.cta_data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

//
export const useGetLocationMapDistribution = (project_id, type, payload) => {
  return useQuery(
    [KEY_LOCATION_MAP_DISTRIBUTION, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type === "reads"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_MAP_DISTRIBUTION_READS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_MAP_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetLocationMajorCitiesReport = (project_id, type, payload) => {
  return useQuery(
    [KEY_LOCATION_MAJOR_CITIES, project_id, type, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        type === "reads"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_MAJOR_CITIES_READS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_MAJOR_CITIES}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetLocationZonalDistribution = (project_id, type, payload) => {
  return useQuery(
    [
      KEY_LOCATION_ZONAL_DISTRIBUTION,
      project_id,
      type,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        type === "reads"
          ? `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_ZONAL_DISTRIBUTION_READS}`
          : `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_ZONAL_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetLocationReport = (project_id, payload) => {
  return useQuery(
    [KEY_LOCATION_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};

export const useGetLocationReportCTA = (project_id, payload) => {
  return useQuery(
    [KEY_LOCATION_REPORT_CTA, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_LOCATION_REPORT_CTA}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.cta_data ?? {},
      enabled: !!project_id && !!payload?.content_id,
    }
  );
};
//
export const useGetCreativeDataList = (project_id, payload) => {
  return useQuery(
    [KEY_CONTENT_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.content_data ?? {},
      enabled: !!project_id,
    }
  );
};

export const useGetCreativeDataListBQ = (project_id, payload) => {
  return useQuery(
    [KEY_CONTENT_LIST_P2, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST_P2}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!project_id && payload.content_id != "",
    }
  );
};

export const useGetCreativeDataListCTA = (project_id, payload) => {
  return useQuery(
    [KEY_CONTENT_LIST_P3, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST_P2_CTA}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.cta_data ?? {},
      enabled: !!project_id && payload.content_id != "",
    }
  );
};

export const useGetCreativeDetails = (project_id, payload) => {
  return useQuery(
    [CREATIVE_DETAILS, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DETAILS}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.content_data[0] ?? {},
      enabled: !!project_id && payload.content_id != "",
    }
  );
};
