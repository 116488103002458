import React from "react";
import { Card } from "react-bootstrap";

const VideoDescriptionCard = ({
  title,
  description,
  isDescriptionClicked,
  index,
}) => {
  return (
    <div
      className={`border rounded-3 bg-white  p-3 d-flex flex-column gap-3 transition ${
        isDescriptionClicked === index ? "border-success" : "border-transparent"
      }`}
    >
      <div>
        <h6 className="fw-semibold">Video Title</h6>
        <p className="text-black">{title}</p>
      </div>

      <div>
        <h6 className="fw-semibold">Video Description</h6>
        <p className="text-dark">{description}</p>
      </div>
    </div>
  );
};

export default VideoDescriptionCard;
