import React, { useState } from "react";
import { findIcon } from "../../../views/dashboard/icons";
import { getService } from "../../../api/services/get-services";
import { DELETE_PRINCIPLE_ENTITY } from "../../../api/endpoints/principle-entity-endpoints";
import toast from "react-hot-toast";
import DeleteModal from "./delete-modal";
import useBackdrop from "../../custom-hooks/use-backdrop";

const TreeViewList = ({
  data,
  getAllPrincipleEntities,
  fetchPrincipleEntities = () => {},
  post_json = "",
  isEdit = false,
}) => {
  const [showDeleteModal, setDeleteModalShow] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [selectedEntity, setSelectedEntity] = useState({
    entityId: null,
    entityParentId: null,
  });
  const onDeletePrincipleEntityClick = async () => {
    try {
      showBackdrop();
      let response = await getService(
        `${DELETE_PRINCIPLE_ENTITY}/${selectedEntity.entityId}`
      );

      if (response.data.success) {
        getAllPrincipleEntities(selectedEntity.entityParentId);
        fetchPrincipleEntities(post_json?.current);
        toast.success(response?.data?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteModalShow(false);
      setSelectedEntity({
        entityId: null,
        entityParentId: null,
      });
      hideBackdrop();
    }
  };

  const deleteEntityHandler = (id, parent_id) => {
    setDeleteModalShow(true);
    setSelectedEntity({
      entityId: id,
      entityParentId: parent_id,
    });
  };

  const renderTree = (node) => {
    const hasChildren = node?.children && node?.children?.length > 0;

    return (
      <>
        <li
          key={node?.principal_entity_id}
          className="list-group-item border-0"
        >
          <div className="d-flex justify-content-between align-items-center p-1 ps-2 rounded bg-light">
            <span>{node?.principal_entity_name}</span>
            {/* {!hasChildren && isEdit && (
              <button
                className="btn btn-danger btn-sm ms-2 rounded-2"
                onClick={() =>
                  deleteEntityHandler(
                    node?.principal_entity_id,
                    node?.principal_entity_parent_id
                  )
                }
              >
                {findIcon("Trash", "dual-tone", 15)}
              </button>
            )} */}
          </div>
          {hasChildren && (
            <ul className="list-group list-group-flush ms-4">
              {node?.children.map((child) => renderTree(child))}
            </ul>
          )}
        </li>
        <DeleteModal
          show={showDeleteModal}
          modalClose={() => setDeleteModalShow(false)}
          onSubmit={onDeletePrincipleEntityClick}
        />
      </>
    );
  };

  return (
    <div className="mt-4">
      <h5>Child Principal Entity list</h5>
      <ul className="list-group border border-1 rounded p-3 bg-white shadow-sm">
        {renderTree(data)}
      </ul>
    </div>
  );
};

export default TreeViewList;
