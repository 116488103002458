import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  HCP_ACTIVITY,
  HCP_ACTIVITY_TIMELINE,
  HCP_ALL_ENGAGEMENT_LIST,
  HCP_DAY_OF_WEEK,
  HCP_DETAILS,
  HCP_ENGAGEMENT_METER_DATA,
  HCP_GET_DATA_ANALYTICS_ON_ONBOARDED_DATE,
  HCP_GET_DATA_ANALYTICS_ON_REGISTER_DATE,
  HCP_GET_FILTER_DATA,
  HCP_GET_INDIA_TO_INTERNATIONAL_ANALYTICS,
  HCP_GET_ONBOARDING_EFFICIENCY_APPROVAL,
  HCP_GET_ONBOARDING_EFFICIENCY_TASK_COMPLITION,
  HCP_GET_ONBOARDING_EFFICIENCY_TASK_RATE,
  HCP_GET_TOP_INTERNATIONAL_COUNTRIES_ANALYTICS,
  HCP_LISTING,
  HCP_META_PROFILE,
  HCP_NBA_VIEW,
  HCP_REQUEST_DATA_CHNAGE,
  HCP_REQUEST_DATA_COUNT,
  HCP_REQUEST_ONBOARDING_BY_REGISTRATION,
  HCP_REQUEST_REONBOARDING_BY_REGISTRATION,
  HCP_TIME_OF_DAY,
  HCP_UPDATE_RECOMMENDATION_STATUS,
  HCP_UPDATE_SPECIALITY_STATUS,
} from "../../api/endpoints/hcp-endpoints";
import {
  GET_HCP_ACTIVITY_LIST,
  GET_HCP_DETAILS,
  GET_HCP_ENGAGEMENT_METER_DATA,
  POST_HCP_SPECIALITY_DASHBOARD_STATUS,
  GET_HCP_ACTIVITY_TIMELINE_DATA,
  POST_HCP_REQUEST_DATA_CHNAGE,
  POST_HCP_RECOMMENDATION_STATUS,
  GET_HCP_ALL_ENGAGEMENT_DATA,
  GET_HCP_DAY_OF_WEEK_DATA,
  GET_HCP_TIME_OF_DAY_DATA,
  GET_HCP_NBA_VIEW_DATA,
  GET_HCP_DATA_COUNT,
  GET_HCP_ONBOARDING_BY_REGISTRATION,
  GET_HCP_RE_ONBOARDING_BY_REGISTRATION,
  HCP_ONBOARDING_EFFICIENCY_BY_APPROVAL,
  HCP_ONBOARDING_EFFICIENCY_BY_TASK_RATE,
  HCP_ONBOARDING_EFFICIENCY_BY_TASK_COMPLITION,
  HCP_ANALYTICS_ON_REGISTER_DATE,
  HCP_ANALYTICS_ON_ONBOARDED_DATE,
  HCP_ANALYTICS_INDIA_TO_INTERNATIONAL,
  HCP_ANALYTICS_TOP_INTERNATIONAL_COUNTRIES,
  HCP_LIST_DATA,
  HCP_LIST_FILTER_DATA,
  HCP_META_PROFILE_DATA,
} from "../query-constants/hcp-constant";
// import { GET_ACTION_DETAILS } from "../query-constant/action-constant";

const staleTime = 300000;
const cacheTime = 600000;

const fetchHcpDetails = ({ queryKey }) => {
  const id = queryKey[1];
  return axiosInstance.get(`${HCP_DETAILS}/${id}`);
};

export const useHcpDetails = (id, enabled, onSuccess) => {
  return useQuery([GET_HCP_DETAILS, id], fetchHcpDetails, {
    enabled: !!enabled,
    onSuccess,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res.data.data,
  });
};

const fetchHcpActivity = ({ queryKey }) => {
  const payload = queryKey[1];
  return axiosInstance.post(`${HCP_ACTIVITY}`, { hcp_id: payload });
};

export const useHcpActivityList = (payload, enabled, onSuccess) => {
  return useQuery([GET_HCP_ACTIVITY_LIST, payload.hcp_id], fetchHcpActivity, {
    enabled: !!enabled,
    onSuccess,
    cacheTime,
    staleTime,
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data ?? [],
  });
};

const getEngagementMeterData = ({ queryKey }) => {
  const payload = queryKey[1];
  return axiosInstance.post(`${HCP_ENGAGEMENT_METER_DATA}`, {
    hcp_id: payload,
  });
};

export const useEngagementMeterData = (payload, enabled) => {
  return useQuery(
    [GET_HCP_ENGAGEMENT_METER_DATA, payload],
    getEngagementMeterData,
    {
      enabled: !!enabled,
      cacheTime,
      staleTime,
      refetchOnWindowFocus: false,
      select: (res) => res?.data ?? [],
    }
  );
};
const updateSpecialityDashboardStatus = (payload) => {
  return axiosInstance.post(HCP_UPDATE_SPECIALITY_STATUS, payload);
};

export const useChangeSpecialityDashboardStatus = (id, onSuccess) => {
  return useMutation(updateSpecialityDashboardStatus, {
    onMutate: async (obj) => {
      console.log("RQ MUTATION SAVELIKE", obj);
    },
    onError: (_err, _obj, context) => {
      console.log("error spec chnage ", _obj);
    },
    onSettled: (obj) => {
      console.log("settled MUTATION spec change");
    },
    onSuccess,
  });
};

const updateRequestDataChange = (payload) => {
  return axiosInstance.post(HCP_REQUEST_DATA_CHNAGE, payload);
};
export const useRequestDataChange = (id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation(updateRequestDataChange, {
    onMutate: async (obj) => {
      console.log("RQ MUTATION SAVELIKE", obj);
    },
    onError: (_err, _obj, context) => {
      console.log("error spec chnage ", _obj);
    },
    onSettled: (obj) => {
      console.log("settled MUTATION spec change");
    },
    onSuccess,
  });
};

const updateRecommendationStatus = (payload) => {
  return axiosInstance.post(HCP_UPDATE_RECOMMENDATION_STATUS, payload);
};
export const useRecommendationStatus = (id, onSuccess) => {
  const queryClient = useQueryClient();

  return useMutation(updateRecommendationStatus, {
    onMutate: async (obj) => {
      console.log("RQ MUTATION SAVELIKE", obj);
    },
    onError: (_err, _obj, context) => {
      console.log("error spec chnage ", _obj);
    },
    onSettled: (obj) => {
      console.log("settled MUTATION spec change");
    },
    onSuccess,
  });
};

export const useGetAllEngagementData = (payload, enabled, onSuccess) => {
  return useQuery(
    [GET_HCP_ALL_ENGAGEMENT_DATA, payload.hcp_id],
    () => axiosInstance.post(`${HCP_ALL_ENGAGEMENT_LIST}`, payload),
    {
      enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      onSuccess,
      staleTime,
      select: (res) => res?.data?.data ?? [],
    }
  );
};

export const useGetActivityTimelineData = (payload, enabled) => {
  return useQuery(
    [GET_HCP_ACTIVITY_TIMELINE_DATA, payload.hcp_id],
    () => axiosInstance.post(`${HCP_ACTIVITY_TIMELINE}`, payload),
    {
      refetchOnWindowFocus: false,
      enabled,
      cacheTime,
      staleTime,
      select: (res) => res?.data.data ?? [],
    }
  );
};

export const useGetDayOfWeekData = (payload, enabled) => {
  return useQuery(
    [GET_HCP_DAY_OF_WEEK_DATA, payload.hcp_id],
    () => axiosInstance.post(`${HCP_DAY_OF_WEEK}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      enabled,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};
export const useGetTimeOfDayData = (payload, enabled) => {
  return useQuery(
    [GET_HCP_TIME_OF_DAY_DATA, payload.hcp_id],
    () => axiosInstance.post(`${HCP_TIME_OF_DAY}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      enabled,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

let nbaData = {
  success: true,
  data: {
    rows: [
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
      {
        content_id: "Sumit Dutta",
        content_type: "Medwiki",
        content_title: "You can use it with tabs, pills, and any other",
      },
    ],
    totalCount: 418889,
  },
};

export const useGetNbaViewData = (payload, id, enabled, onSuccess) => {
  return useQuery(
    [GET_HCP_NBA_VIEW_DATA, id],
    () => axiosInstance.post(`${HCP_NBA_VIEW}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      enabled: !!enabled,
      staleTime,
      onSuccess,
      select: (res) => res?.data?.data ?? [], //res.data ?? [],
    }
  );
};

//  hcp api dashboard start
export const useGetHcpDataCount = () => {
  return useQuery(
    [GET_HCP_DATA_COUNT],
    () => axiosInstance.post(`${HCP_REQUEST_DATA_COUNT}`, {}),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? [],
    }
  );
};

export const useOnboardingByRegistration = (payload, dropdownType) => {
  return useQuery(
    [GET_HCP_ONBOARDING_BY_REGISTRATION, dropdownType],
    () =>
      axiosInstance.post(`${HCP_REQUEST_ONBOARDING_BY_REGISTRATION}`, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

export const useReOnboardingByRegistration = (payload, dropdownType) => {
  return useQuery(
    [GET_HCP_RE_ONBOARDING_BY_REGISTRATION, dropdownType],
    () =>
      axiosInstance.post(
        `${HCP_REQUEST_REONBOARDING_BY_REGISTRATION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

export const useOnboardingEfficiency = (playload) => {
  return useQuery(
    [HCP_ONBOARDING_EFFICIENCY_BY_TASK_COMPLITION, playload],
    () =>
      axiosInstance.get(
        `${HCP_GET_ONBOARDING_EFFICIENCY_TASK_COMPLITION}/${playload}`
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};

export const useAnalyticsOnRegisterDate = (playload) => {
  return useQuery(
    [HCP_ANALYTICS_ON_REGISTER_DATE, playload.type],
    () =>
      axiosInstance.post(
        `${HCP_GET_DATA_ANALYTICS_ON_REGISTER_DATE}`,
        playload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};
export const useAnalyticsOnOnboardedDate = (playload) => {
  return useQuery(
    [HCP_ANALYTICS_ON_ONBOARDED_DATE, playload.type],
    () =>
      axiosInstance.post(
        `${HCP_GET_DATA_ANALYTICS_ON_ONBOARDED_DATE}`,
        playload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data ?? [],
    }
  );
};
export const useAnalytisIndiaToInternational = (playload) => {
  return useQuery(
    [HCP_ANALYTICS_INDIA_TO_INTERNATIONAL, playload.type],
    () =>
      axiosInstance.post(
        `${HCP_GET_INDIA_TO_INTERNATIONAL_ANALYTICS}`,
        playload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => (res.data?.success ? res.data : ""),
    }
  );
};
export const useAnalytisTopInternationalCountries = (playload, type) => {
  return useQuery(
    [HCP_ANALYTICS_TOP_INTERNATIONAL_COUNTRIES, type],
    () =>
      axiosInstance.post(
        `${HCP_GET_TOP_INTERNATIONAL_COUNTRIES_ANALYTICS}`,
        playload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => (res?.data?.success ? res.data : ""),
    }
  );
};

export const useGetHcpListData = (payload, onSuccess) => {
  console.log("payload111", payload);
  return useQuery(
    [HCP_LIST_DATA, JSON.stringify(payload)],
    () => axiosInstance.post(`${HCP_LISTING}`, payload),
    {
      refetchOnWindowFocus: false,
      onSuccess,
      cacheTime,
      staleTime,
      select: (res) => (res?.data?.success ? res.data : ""),
    }
  );
};

export const useGetHcpFilterData = () => {
  return useQuery(
    [HCP_LIST_FILTER_DATA],
    () => axiosInstance.get(`${HCP_GET_FILTER_DATA}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data.data ?? [],
    }
  );
};

export const useGetMetaProfileData = (id) => {
  return useQuery(
    [HCP_META_PROFILE_DATA, id],
    () => axiosInstance.get(`${HCP_META_PROFILE}/${id}`),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res.data.data ?? [],
    }
  );
};

// hcp dashboard apis end
