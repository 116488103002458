import useRedirect from "../../../../components/custom-hooks/use-redirect";

const useRedirectToAction = () => {
  const { redirectTo } = useRedirect();
  const redirectToAction = (action_type = "", action_id, other = {}) => {
    switch (action_type) {
      case "template_approval":
      case "template_second_step_approval":
      case "template_edit":
        redirectTo(
          `action/details/${action_type?.replace("_", "_")}/${action_id}`
        );
        break;

      case "re_onboarding_review":
      case "onboarding_review":
        redirectTo(`action/onboarding-task-details/onboarding/${action_id}`);
        break;
      case "onboarding_task_view":
        redirectTo(`action/onboarding-task-view/${action_id}`);
        break;
      case "re_onboarding_task":
      case "onboarding_task":
        redirectTo("action/onboarding-task-details/onboarding");
        break;
      case "consent_collection_task":
        redirectTo("action/onboarding-task-details/consent-collection");
        break;
      case "onboarding_review_edit":
        redirectTo(`action/onboarding-review-edit/${action_id}`);
        break;
      case "re_onboarding_review_edit":
        redirectTo(`action/re-onboarding-review-edit/${action_id}`);
        break;
      case "re_onboarding_approval":
      case "onboarding_approval":
        redirectTo(`action/onboarding-approval-details/${action_id}`);
        break;
      case "consent_collection_approval":
        redirectTo(`action/onboarding-approval-details`);
        break;
      case "campaign_approval":
        redirectTo(`action/campaign-approval-details`);
        break;
      case "ab_testing_approval":
        redirectTo(`action/ab-test-approval-details`);
        break;
      case "ab_testing_final_approval":
        redirectTo(`action/ab-test-final-approval-details`);
        break;

      case "sms_template_approval":
        redirectTo(`action/ab-test-final-approval-details`);
        break;
      case "email_template_approval":
        redirectTo(`action/ab-test-final-approval-details`);
        break;
      case "whatsapp_template_approval":
        redirectTo(`action/ab-test-final-approval-details`);
        break;
      case "support_ticket_approval":
        redirectTo(`action/ab-test-final-approval-details`);
        break;

      default:
        break;
    }
  };
  return redirectToAction;
};

export default useRedirectToAction;
