import React from "react";
import BrandAdsLayout from "../layouts/brand-ads-layout";
import Summary from "../pages/summary";
import Audience from "../pages/audience";
import Creative from "../pages/creative";
import LandingPage from "../pages/landing-page";
import Location from "../pages/location";
import Overview from "../pages/overview";
import CreativeDetails from "../pages/creative-details";
import CreativePreview from "../pages/creative-preview";
import RequestContet from "../pages/RequestContet";

export const BrandAdsRouter = [
  {
    path: "brand-ads",
    element: <BrandAdsLayout />,
    children: [
      {
        path: "summary/:project_id",
        element: <Summary />,
      },
      {
        path: "audience/:project_id",
        element: <Audience />,
      },
      {
        path: "creative/:project_id",
        element: <Creative />,
      },
      {
        path: "landing-page/:project_id",
        element: <LandingPage />,
      },
      {
        path: "location/:project_id",
        element: <Location />,
      },
      {
        path: "overview/:project_id",
        element: <Overview />,
      },
      {
        path: "creative/details/:project_id/:creative_id/:campaign_id",
        element: <CreativeDetails />,
      },
      {
        path: "creative/preview/:project_id/:creative_id/:campaign_id",
        element: <CreativePreview />,
      },
      {
        path: "request-conetnt/:project_id",
        element: <RequestContet />,
      },
    ],
  },
];
