import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import CustomToggle from "../../../../components/dropdowns";
// Apexcharts
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { theme_color } from "../../../../store/setting/selectors";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import Select from "react-select";
import SegmentAnalyticsSection from "../components/create-from-component/segment-analytics-section";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { getService } from "../../../../api/services/get-services";
import {
  SEGMENT_ANALYTICS_GET,
  SEGMENT_DETAILS_GET,
  SEGMENT_DETAIL_ANALYTICS_POST,
  SEGMENT_HCP_LIST_GET,
  SEGMENT_SIF_GET,
} from "../../../../api/endpoints/campaign-segment-management-endpoints";
import { useParams } from "react-router-dom";
import postService from "../../../../api/services/post-service";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import withAuthorization from "../../../../components/hoc/with-authorization";
import UserPersonaCard from "../components/create-from-component/user-persona-card";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";

const SegmentDetails = () => {
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { redirectTo } = useRedirect();
  const [segmentDetails, setSegmentDetails] = useState({});
  const [analyticsData, setAnalyticsData] = useState({});
  const [segmentSifData, setSegmentSifData] = useState({});
  const [hcpListData, setHcpListData] = useState([]);
  const [hcpTotalCount, setHcpTotalCount] = useState(0);

  const [loader, setLoader] = useState(false);
  const params = useParams();
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();

  const chart3 = {
    series: [
      {
        name: "DND",
        type: "column",
        data: [440, 505, 414, 671, 227, 413, 229, 617, 412, 500, 420, 431],
      },
      {
        name: "Monthly DND Increase",
        type: "line",
        data: [230, 420, 350, 270, 403, 220, 210, 304, 250, 300, 400, 302],
      },
      // {
      //   name: "Monthly Repeat Active",
      //   type: "column",
      //   data: [330, 202, 313, 340, 500, 215],
      // },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1],
        formatter: (val, { seriesIndex }) => {
          if (seriesIndex == 0) {
            return val;
          } else {
            return val / 10 + "%";
          }
        },
      },
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "Septembar",
        "October",
        "November",
        "December",
      ],
      xaxis: {
        type: "monthtime",
      },
    },
  };

  const ActiveMonthlyUsers = {
    series: [89],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
              formatter: (val, { seriesIndex }) => {
                return val * 20;
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
    },
  };
  const MonthlyVisitFrequency = {
    series: [36.2],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      colors: [variableColors.primary_light],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
              formatter: (val, { seriesIndex }) => {
                return val;
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
    },
  };
  const MonthlyRepeatActive = {
    series: [
      {
        name: "Active HCPs",
        data: [44, 33, 55, 66, 77, 57, 67, 55, 44, 58, 38, 48],
      },
      {
        name: "Percentage of Engagement",
        data: [76, 66, 88, 35, 87, 74, 47, 78, 53, 78, 62, 67],
      },
    ],
    options: {
      chart: {
        type: "bar",
        width: 350,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "75%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val, { seriesIndex }) => {
          return val + "%";
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "Septembar",
          "October",
          "November",
          "December",
        ],
      },
      yaxis: {
        enabled: true,
        labels: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const AverageSessionDuration = {
    series: [76],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      colors: [variableColors.warning],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
              formatter: (val, { seriesIndex }) => {
                return val + " Sec";
              },
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
    },
  };

  useEffect(() => {
    getSegmentDetails();
  }, []);

  const getSegmentDetails = async () => {
    setLoader(true);
    let _payload_id = params.id;
    let response = await getService(
      `${SEGMENT_DETAILS_GET}/${_payload_id}`,
      {}
    );
    if (response) {
      getSegmentAnalyticsById(_payload_id);
      setLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      if (responseData) {
        setSegmentDetails(responseData);
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };

  const getSegmentAnalyticsById = async (_payload_id) => {
    console.log("getSegmentAnalyticsById", _payload_id);
    // setSegmentDetailsIsLoading(true);
    let response = await getService(`${SEGMENT_ANALYTICS_GET}/${_payload_id}`);
    if (response) {
      // setSegmentDetailsIsLoading(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      console.log("segment response", responseData);
      if (responseData) {
        setAnalyticsData(responseData);
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };

  const getSegmentSif = async () => {
    let _payload_id = params.id;
    console.log("getSegmentAnalyticsById", _payload_id);
    // setSegmentDetailsIsLoading(true);
    let response = await getService(`${SEGMENT_SIF_GET}/${_payload_id}`);
    if (response) {
      // setSegmentDetailsIsLoading(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      console.log("segment sif response", responseData);
      if (responseData) {
        setSegmentSifData(responseData);
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };
  const hcpColumns = [
    {
      Header: "Name",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        console.log("hcp_first_name", value);
        return value
          ? `${value.hcp_first_name} ${value.hcp_middle_name} ${value.hcp_last_name}`
          : "-";
      },
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "UTM",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        console.log("hcp_utmsource", value);
        return value ? `${value.hcp_utmsource}` : "-";
      },
    },
    {
      Header: "Age ",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        return value ? `${value?.hcp_age}` : "--";
      },
    },
    {
      Header: "Location",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        return value
          ? `${value?.hcp_to_city?.city_name ?? "--"}, ${
              value?.hcp_to_state?.state_name ?? "--"
            }`
          : "--";
      },
    },
    {
      Header: "Speciality",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        return value?.hcp_to_speciality?.speciality_name
          ? `${value?.hcp_to_speciality?.speciality_name}`
          : "--";
      },
    },
    {
      Header: "Updated on",
      accessor: "updated_at",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Action",
      accessor: "actionDetails",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view"]}
          row={row}
          onView={(data) => {
            console.log("row hcp", row);
            redirectTo(`hcp-profile/details/${row.original.hcp_id}`);
          }}
        />
        // <div className="d-flex justify-content-center">
        //   <Button className="btn btn-icon btn-sm rounded-pill" role="button">
        //     <span className="btn-inner">{row.original.actionDetails}</span>
        //   </Button>
        // </div>
      ),
      // Filter: DefaultColumnFilter,
    },
  ];

  const post_json_hcp_list = useRef({
    segment_id: params.id,
    filter: {
      status: {
        type: "",
        value: 1,
      },
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      segment_id: "desc",
    },
  });

  const getSegmentHcpListById = async () => {
    // let payloadSegment = {
    //   ...post_json_hcp_list.current,
    //   segment_id: params.id,
    // };
    showBackdrop();
    let response = await postService(
      SEGMENT_HCP_LIST_GET,
      post_json_hcp_list.current
    );
    if (response) {
      hideBackdrop();
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      // console.log("response data", responseData.rows[0]?.segment_to_hcp);
      if (responseData && responseData?.rows?.length > 0) {
        // let HcpData = responseData.rows[0]?.segment_to_hcp.map((item) => {
        //   return item.hcp;
        // });
        setHcpListData(responseData.rows);
        setHcpTotalCount(responseData.totalCount);
      }
    }
  };

  const onHcpTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      let modifiedPayload = { ...paylod, segment_id: params.id };
      post_json_hcp_list.current = modifiedPayload;
      getSegmentHcpListById();
    },
    [hcpListData, loader, post_json_hcp_list.current]
  );

  const post_json_segment_details_list = useRef({
    segment_id: params.id,
    type: "retention_matrics",
    // "type": "engagement_matrics"
    // "type": "monthly_active_users"
    //  "type": "average_session_duration"
    //   "type": "monthly_visit_frequency"
  });

  const getSegmentDetailAnalytics = async () => {
    let response = await postService(
      SEGMENT_DETAIL_ANALYTICS_POST,
      post_json_segment_details_list.current
    );
    if (response) {
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      console.log("response data", responseData);
    }
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          {segmentDetails?.segment_name && (
            <h3 className="m-0">{segmentDetails.segment_name}</h3>
          )}
          {segmentDetails?.segment_description && (
            <p>{segmentDetails.segment_description}</p>
          )}
        </div>
      </div>
      <Row>
        <Col lg={12} className="d-flex">
          <Card className="flex-grow-1">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered d-flex justify-content-between px-4 align-items-center">
                <Nav
                  className="m-0 bg-transparent"
                  data-toggle="slider-tab"
                  role="tablist"
                  variant="tabs"
                >
                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="first"
                      variant="  d-flex align-items-center"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-home1"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Details
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="second"
                      variant=" d-flex align-items-center"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-profile1"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => getSegmentHcpListById()}
                    >
                      HCP Listing
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="fourth"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-contact1"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                      onClick={() => getSegmentDetailAnalytics()}
                    >
                      Analytics
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item role="presentation">
                    <Nav.Link
                      className="py-3"
                      eventKey="third"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#pills-contact1"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                      onClick={() => getSegmentSif()}
                    >
                      SIF
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <ActionButtonBar
                  buttons={["edit"]}
                  row={""}
                  onEdit={() => {
                    redirectTo("segment/edit/" + params.id);
                  }}
                />
              </div>
              <Card.Body>
                <Tab.Content>
                  {/* Details */}
                  <Tab.Pane
                    eventKey="first"
                    variant=" fade show active"
                    id="pills-home1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab1"
                  >
                    <Row>
                      <Col lg={12}>
                        <h5>User Persona</h5>
                        <div className="d-flex flex-row flex-wrap gap-3 mb-1 mt-4">
                          {segmentDetails?.segment_to_persona?.length > 0 &&
                            segmentDetails?.segment_to_persona?.map((data) => (
                              <UserPersonaCard
                                name={data?.persona?.persona_name}
                                image={data?.persona?.persona_icon}
                                id={data?.persona?.persona_id}
                                active={true}
                              />
                            ))}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {segmentDetails?.segment_rules?.who_active?.length >
                        0 && (
                        <Col>
                          <Card className="bg-soft-info h-100">
                            <Card.Header>
                              <h5 className="mb-0">User Who Are Active In:</h5>
                            </Card.Header>
                            <Card.Body className="py-0">
                              {segmentDetails?.segment_rules?.who_active?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <p className="mb-0 border border-2 border-dashed border-info p-2 rounded-3 w-auto text-disable">
                                        <span className="me-2">
                                          User{" "}
                                          {item?.name
                                            .replace(/_/g, " ")
                                            .replace(/\b\w/g, (c) =>
                                              c.toUpperCase()
                                            )}
                                        </span>
                                        <Badge
                                          bg={"primary"}
                                          className="text-uppercase"
                                        >
                                          {item.clause}
                                        </Badge>
                                        <span className="ms-2">{`${item.value} Day's`}</span>
                                      </p>
                                      {index <
                                        segmentDetails?.segment_rules
                                          ?.who_active?.length -
                                          1 && (
                                        <div className="d-flex justify-content-center py-2 fs-6 text-uppercase">
                                          {item?.operator}
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                      {segmentDetails?.segment_rules?.engaged_with?.length >
                        0 && (
                        <Col>
                          <Card className="bg-soft-warning h-100">
                            <Card.Header>
                              <h5 className="mb-0">Users Engaged with:</h5>
                            </Card.Header>
                            <Card.Body className="py-0">
                              {segmentDetails?.segment_rules?.engaged_with?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <p className="mb-0 border border-2 border-dashed border-warning p-2 rounded-3 w-auto">
                                        <span className="me-2 ">
                                          {item?.name.replace(/^\w/, (c) =>
                                            c.toUpperCase()
                                          )}
                                        </span>
                                        <Badge
                                          bg={"success"}
                                          className="text-uppercase"
                                        >
                                          {item?.clause}
                                        </Badge>
                                        <span className="ms-2">
                                          Page{" "}
                                          {item?.value?.replace(/^\w/, (c) =>
                                            c?.toUpperCase()
                                          )}
                                        </span>
                                      </p>
                                      {index <
                                        segmentDetails?.segment_rules
                                          ?.engaged_with?.length -
                                          1 && (
                                        <div className="d-flex justify-content-center py-2 fs-6 text-uppercase">
                                          {item?.operator}
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                      {segmentDetails?.segment_rules?.interesed_in?.length >
                        0 && (
                        <Col>
                          <Card className="bg-soft-primary h-100">
                            <Card.Header>
                              <h5 className="mb-3">User Interesed In:</h5>
                            </Card.Header>
                            <Card.Body className="py-0">
                              {segmentDetails?.segment_rules?.interesed_in?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <p className="mb-0 border border-2 border-dashed border-primary p-2 rounded-3 w-auto">
                                        <span className="me-2">
                                          {item?.field_value.replace(
                                            /^\w/,
                                            (c) => c.toUpperCase()
                                          )}
                                        </span>
                                        <Badge
                                          bg={"primary"}
                                          className="text-uppercase"
                                        >
                                          {item?.clause}
                                        </Badge>
                                        <span className="ms-2">
                                          {item?.content_tag_name.replace(
                                            /^\w/,
                                            (c) => c.toUpperCase()
                                          )}
                                        </span>
                                      </p>
                                      {index <
                                        segmentDetails?.segment_rules
                                          ?.interesed_in?.length -
                                          1 && (
                                        <div className="d-flex justify-content-center py-2 fs-6 text-uppercase">
                                          {item?.operator}
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                      {segmentDetails?.segment_rules?.demographic?.length >
                        0 && (
                        <Col>
                          <Card className="bg-soft-danger h-100">
                            <Card.Header>
                              <h5 className="mb-3">User Demographic:</h5>
                            </Card.Header>
                            <Card.Body className="py-0">
                              {segmentDetails?.segment_rules?.demographic?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      <p className="mb-0 border border-2 border-dashed border-danger p-2 rounded-3 w-auto">
                                        <span className="me-2">
                                          {item?.name.replace(/^\w/, (c) =>
                                            c.toUpperCase()
                                          )}
                                        </span>
                                        <Badge
                                          bg={"info"}
                                          className="text-uppercase"
                                        >
                                          {item?.clause}
                                        </Badge>
                                        <span className="ms-2">
                                          {item?.field_value}
                                        </span>
                                      </p>
                                      {index <
                                        segmentDetails?.segment_rules
                                          ?.demographic?.length -
                                          1 && (
                                        <div className="d-flex justify-content-center py-2 fs-6 text-uppercase">
                                          {item?.operator}
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </Row>
                    <Col lg={12} className="mt-3">
                      <SegmentAnalyticsSection
                        segmentSize={analyticsData?.segment_size}
                        preferEmail={analyticsData?.prefer_email}
                        preferWhatsApp={analyticsData?.prefer_whatsapp}
                        preferSms={analyticsData?.prefer_sms}
                        excludingDnd={analyticsData?.excluding_dnd}
                        availableTouchPoint={
                          analyticsData?.avg_available_touch_point
                        }
                        barredByDndStatus={analyticsData?.barred_by_dnd_status}
                        totalReach={analyticsData?.total_reach}
                      />
                    </Col>
                    {analyticsData?.last_refreshed && (
                      <Col
                        lg={12}
                        className="d-flex justify-content-end mb-2 gap-2 align-items-center mb-2 mt-2"
                      >
                        <p class="p-0 m-0">
                          Last Refreshed on {analyticsData?.last_refreshed}
                        </p>
                      </Col>
                    )}
                  </Tab.Pane>

                  {/* HCP Listing */}
                  <Tab.Pane
                    eventKey="second"
                    className="fade"
                    id="pills-profile1"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab1"
                  >
                    {hcpListData && hcpListData?.length > 0 && (
                      <ReactTable
                        data={hcpListData}
                        columns={hcpColumns}
                        recordsTotal={hcpTotalCount}
                        onTableStateChange={onHcpTableStateChange}
                        initialState={post_json_hcp_list.current}
                        recordsPerPage={
                          post_json_hcp_list.current.pagination.limit
                        }
                      />
                    )}
                  </Tab.Pane>

                  {/* SIF Section  */}
                  <Tab.Pane
                    eventKey="third"
                    className=" fade"
                    id="pills-contact1"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab1"
                  >
                    <Row className="p-3">
                      <Col className="col-12 bg-soft-secondary rounded-3 pt-3 w-100 rounded-lg d-flex flex-column flex-sm-row mb-3 mb-sm-5 align-items-start align-items-sm-center">
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0 mt-4 mt-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-30 </strong>
                              Active Users
                            </span>
                            <h5>{segmentSifData?.total_this_month}</h5>
                          </div>
                        </div>
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-7 </strong>
                              Active Users
                            </span>
                            <h4 class="font-weight-bold">
                              {segmentSifData?.total_this_week}
                            </h4>
                          </div>
                        </div>
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T-1 </strong>
                              Active Users
                            </span>
                            <h2 class="font-weight-bold">
                              {segmentSifData?.total_today}
                            </h2>
                          </div>
                        </div>
                        <div class="levelChild flex-1 text-black d-flex align-items-center justify-content-center flex-row flex-sm-column mb-4 mb-sm-0 ml-3 ml-sm-0">
                          <span class="pl-2 ps-sm-0">
                            <i class="fa-solid fa-angle-down rounded-circle p-1 d-flex align-items-center justify-content-center mb-0 mb-sm-2"></i>
                          </span>
                          <div class="d-flex align-items-start align-items-sm-center justify-content-center flex-column ml-4 ml-sm-0">
                            <span>
                              <strong>T </strong>
                              Active Users
                            </span>
                            <h1 class="font-weight-bolder">
                              {segmentSifData?.total_user}
                            </h1>
                          </div>
                        </div>
                      </Col>

                      <Col className="col-12 col-md-8 p-0 pe-4" sm={8}>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-primary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-3 d-flex align-items-center bg-primary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-primary">
                              <span>New User</span>
                              <h1 class="font-weight-bolder">
                                {segmentSifData?.new_user?.total_user}
                              </h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.new_user?.today}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.new_user?.last_week}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.new_user?.last_month}%
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-pink text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-white">
                              <span>Active User</span>
                              <h1 class="font-weight-bolder">
                                {segmentSifData?.active_user?.total_user}
                              </h1>
                            </div>
                            <div class="d-flex flex-row text-white funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.active_user?.today}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.active_user?.last_week}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.active_user?.last_month}%
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>Hot User</span>
                              <h1 class="font-weight-bolder">
                                {segmentSifData?.hot_user?.total_user}
                              </h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.hot_user?.today}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.hot_user?.last_week}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.hot_user?.last_month}%
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>Warm User</span>
                              <h1 class="font-weight-bolder">
                                {segmentSifData?.warm_user?.total_user}
                              </h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.warm_user?.today}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.warm_user?.last_week}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.warm_user?.last_month}%
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>Cold User</span>
                              <h1 class="font-weight-bolder">
                                {segmentSifData?.cold_user?.total_user}
                              </h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.cold_user?.today}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.cold_user?.last_week}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.cold_user?.last_month}%
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="funnelCurrentUser rounded-3 mb-3 border border-secondary rounded-lg d-flex align-items-stretch">
                          <div class="p-2 mr-2 d-flex align-items-center bg-secondary text-white">
                            <i class="fa-solid fa-angle-right"></i>
                          </div>
                          <div class="flex-grow-1 d-flex align-items-center flex-column flex-sm-row justify-content-sm-between p-2">
                            <div class="d-flex flex-column text-secondary">
                              <span>Dormant User</span>
                              <h1 class="font-weight-bolder">
                                {segmentSifData?.dormant_user?.total_user}
                              </h1>
                            </div>
                            <div class="d-flex flex-row funnelStatics">
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-1)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.dormant_user?.today}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-7)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.dormant_user?.last_week}%
                                </h6>
                              </div>
                              <div class="d-flex flex-column mr-3 align-items-center">
                                <span>To (T-30)</span>
                                <h6 class="mt-2">
                                  {segmentSifData?.dormant_user?.last_month}%
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col className="col-12 col-md-4 px-0 pl-sm-5" sm={4}>
                        <div class="rounded-lg d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-5 reUserStatics">
                          <div class="d-flex flex-column align-items-center w-100 py-2">
                            <span class="font-weight-bold">Rescued Users</span>
                            <h2>{segmentSifData?.rescued_user?.total_user}</h2>
                          </div>
                          <div class="rounded-lg d-flex flex-row flex-1 statics w-100 py-2 align-items-center justify-content-center">
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.rescued_user?.today}%
                              </i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-7)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.rescued_user?.last_week}%
                              </i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-30)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.rescued_user?.last_month}%
                              </i>
                            </span>
                          </div>
                        </div>
                        <div class="rounded-lg d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-5 reUserStatics">
                          <div class="d-flex flex-column align-items-center w-100 py-2">
                            <span class="font-weight-bold">Revived Users</span>
                            <h2>{segmentSifData?.revived_user?.total_user}</h2>
                          </div>
                          <div class="rounded-lg d-flex flex-row flex-1 statics w-100 py-2 align-items-center justify-content-center">
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.revived_user?.today}%
                              </i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-7)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.revived_user?.last_week}%
                              </i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-30)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.revived_user?.last_month}%
                              </i>
                            </span>
                          </div>
                        </div>
                        <div class="rounded-lg d-flex flex-column justify-content-center align-items-center mb-3 mb-sm-5 reUserStatics">
                          <div class="d-flex flex-column align-items-center w-100 py-2">
                            <span class="font-weight-bold">Reborn Users</span>
                            <h2>{segmentSifData?.reborn_user?.total_user}</h2>
                          </div>
                          <div class="rounded-lg d-flex flex-row flex-1 statics w-100 py-2 align-items-center justify-content-center">
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-1)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.reborn_user?.today}%
                              </i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-7)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.reborn_user?.last_week}%
                              </i>
                            </span>
                            <span class="d-flex flex-column align-items-center px-2 flex-1">
                              <b class="text-black-50">To (T-30)</b>
                              <i class="font-weight-bold text-dark">
                                {segmentSifData?.reborn_user?.last_month}%
                              </i>
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>

                  {/* Analytics */}
                  <Tab.Pane
                    eventKey="fourth"
                    variant=" fade show active"
                    id="pills-home1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab1"
                  >
                    <Row>
                      {/* <Col>
                        <h5>Revenue Metrics</h5>
                        <Chart
                          options={chart2.options}
                          series={chart2.series}
                          type="bar"
                          height="300"
                        />
                      </Col> */}
                      <Col>
                        <h5>Retention Metrics</h5>
                        <Chart
                          options={chart3.options}
                          series={chart3.series}
                          type="line"
                          height="300"
                        />
                      </Col>
                      {/* <Col>
                        <Chart
                          options={MonthlyRepeatActive.options}
                          series={MonthlyRepeatActive.series}
                          type="pie"
                          height="100%"
                          width="100%"
                        />
                      </Col> */}
                    </Row>
                    <Row>
                      <Col>
                        <Card className="bg-soft-primary">
                          <Card.Body className="d-flex justify-content-center flex-column align-items-center">
                            <Chart
                              options={ActiveMonthlyUsers.options}
                              series={ActiveMonthlyUsers.series}
                              type="radialBar"
                              height="250"
                            />
                            <h6>Active Monthly Users</h6>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="bg-soft-warning">
                          <Card.Body className="d-flex justify-content-center flex-column align-items-center">
                            <Chart
                              options={AverageSessionDuration.options}
                              series={AverageSessionDuration.series}
                              type="radialBar"
                              height="250"
                            />
                            <h6>Average Session Duration</h6>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card className="bg-soft-primary">
                          <Card.Body className="d-flex justify-content-center flex-column align-items-center">
                            <Chart
                              options={MonthlyVisitFrequency.options}
                              series={MonthlyVisitFrequency.series}
                              type="radialBar"
                              height="250"
                            />
                            <h6>Monthly Visit Frequency</h6>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg="12">
                        <h5>Engagement Metrics</h5>
                        <Chart
                          options={MonthlyRepeatActive.options}
                          series={MonthlyRepeatActive.series}
                          type="bar"
                          height="300"
                          // width="1000"
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default memo(SegmentDetails);
