import Skeleton from "../../components/skeleton";
import { Fragment } from "react";

//Componets

import {
  OverlayTrigger,
  Card,
  Tooltip,
  Dropdown,
  Button,
  Col,
  Row,
} from "react-bootstrap";

const RaiseSupportLoader = () => {
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="6">
              <Skeleton
                variant="text"
                width={"20%"}
                height={20}
                className="mb-2"
              />
              <div className="mb-4">
                <Skeleton variant="rounded" width="100%" height={45} />
              </div>
            </Col>
            <Col lg="6">
              <Skeleton
                variant="text"
                width={"20%"}
                height={20}
                className="mb-2"
              />
              <div className="mb-4">
                <Skeleton variant="rounded" width="100%" height={45} />
              </div>
            </Col>
            <Col lg="6">
              <Skeleton
                variant="text"
                width={"20%"}
                height={20}
                className="mb-2"
              />
              <div className="mb-4">
                <Skeleton variant="rounded" width="100%" height={45} />
              </div>
            </Col>
            <Col lg="6">
              <Skeleton
                variant="text"
                width={"20%"}
                height={20}
                className="mb-2"
              />
              <div className="mb-4">
                <Skeleton variant="rounded" width="100%" height={45} />
              </div>
            </Col>
            <Col lg="12" className="mb-4 flex-grow-1">
              <Skeleton
                variant="text"
                width={"15%"}
                height={20}
                className="mb-2"
              />
              <Skeleton variant="rounded" width="100%" height={90} />
            </Col>
          </Row>
          <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap mt-4">
            <Skeleton
              variant="rounded"
              width="10%"
              height={40}
              className="mb-4"
            />
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default RaiseSupportLoader;
