import { Skeleton } from "@mui/material";
import Card from "../../components/bootstrap/card";

const ReactTableLoader = () => {
  let tableData = [...Array(5).keys()].map((_el) => _el + 1);
  return (
    <>
      <Card>
        <Card.Body className="border border-1 rounded-3">
          <table role="table" class="mb-0 table-bordered rounded-2 table">
            <thead>
              <tr role="row">
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width={100} height={30} />
                </th>
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width={100} height={30} />
                </th>
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width={100} height={30} />
                </th>
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width={100} height={30} />
                </th>
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width={100} height={30} />
                </th>
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                  <Skeleton variant="rounded" width={100} height={30} />
                </th>
                <th class="text-start align-top" scope="col">
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    className="mb-2"
                  />
                </th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              {tableData.map((_td, idx) => (
                <tr role="row" class="text-start" key={idx + 1}>
                  <td role="cell">
                    <Skeleton variant="text" width={100} height={20} />
                  </td>
                  <td role="cell">
                    <Skeleton variant="text" width={60} height={20} />
                  </td>
                  <td role="cell">
                    <Skeleton variant="text" width={60} height={20} />
                  </td>
                  <td role="cell">
                    <Skeleton variant="text" width={60} height={20} />
                  </td>
                  <td role="cell">
                    <Skeleton variant="text" width={60} height={20} />
                  </td>
                  <td role="cell">
                    <Skeleton variant="text" width={60} height={20} />
                  </td>
                  <td role="cell">
                    <div class="d-flex justify-content-left align-items-center gap-2">
                      <Skeleton variant="circular" width={30} height={30} />
                      <Skeleton variant="circular" width={30} height={30} />
                      <Skeleton variant="circular" width={30} height={30} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </>
  );
};

export default ReactTableLoader;
