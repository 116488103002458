import { useState, memo, Fragment } from "react";

//React-bootstrap
import { Image, Dropdown } from "react-bootstrap";

//Router
import { Link } from "react-router-dom";

//Components
import Card from "../../../../components/bootstrap/card";

// Fsloghtbox
import FsLightbox from "fslightbox-react";

//Sweet alert
import Swal from "sweetalert2";

// Img
import img1 from "../../../../assets/modules/file-manager/images/8.png";
import img2 from "../../../../assets/modules/file-manager/images/7.png";
import img3 from "../../../../assets/modules/file-manager/images/2.png";
import img4 from "../../../../assets/modules/file-manager/images/1.png";
import img5 from "../../../../assets/modules/file-manager/images/6.png";
import img6 from "../../../../assets/modules/file-manager/images/5.png";
import img7 from "../../../../assets/modules/file-manager/images/4.png";
import { findIcon } from "../../../dashboard/icons";

const Images = memo((props) => {
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }
  function Sweetalert() {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You want to restore this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Restore!", "Your item has been restore.", "success");
      }
    });
  }
  function Sweetalert1() {
    Swal.fire({
      icon: "error",
      title: "Are you sure?",
      text: "You want to delete this item",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  }

  return (
    <Fragment>
      <FsLightbox
        toggler={imageController.toggler}
        sources={[
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
          img7,
          img1,
          img6,
          img5,
          img2,
          img1,
          img4,
          img3,
          img1,
          img7,
        ]}
        slide={imageController.slide}
      />
      <Card className="iq-file-manager">
        <Card.Body
          className={`card-thumbnail ${
            props.extraClass ? props.extraClass : ""
          }`}
        >
          <Link
            onClick={() => imageOnSlide(props.id)}
            to="#"
            className="d-flex justify-content-center"
          >
            <Image
              src={props.imgChange}
              className="img-fluid avatar-120"
              alt=""
            />
          </Link>
          <div className="mt-2">
            <p className="small mb-2">Created on {props.imageDate}</p>
            <div className="d-flex align-items-center mb-2 text-primary gap-2">
              {props.type == "podcast" ? (
                findIcon("Start", "solid")
              ) : props.type == "audio" ? (
                findIcon("Microphone On", "solid")
              ) : props.type == "infographic" ? (
                <svg
                  fill="none"
                  class="icon-20"
                  enable-background="new 0 0 511.603 511.603"
                  height="20"
                  viewBox="0 0 511.603 511.603"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="m511.603 240.578c-7.431-129.456-110.991-233.115-240.552-240.553v240.553z"
                      fill="currentColor"
                      opacity="0.4"
                    />
                    <path
                      d="m64.741 85.48c-89.319 100.198-86.524 254.303 10.29 351.116 48.352 48.353 112.639 74.981 181.02 74.981 136.924 0 247.86-107.002 255.553-241h-261.767z"
                      fill="currentColor"
                    />
                    <path
                      d="m241.051.025c-57.763 3.316-111.908 25.744-155.097 64.243l155.097 155.098z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              ) : props.type == "html" ? (
                findIcon("Document Code", "solid")
              ) : props.type == "presentations" ? (
                <svg
                  fill="none"
                  class="icon-22"
                  height="22"
                  viewBox="0 -32 512 512"
                  width="22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m464.871094 0h-224.941406c-26.03125 0-47.128907 21.101562-47.128907 47.128906v110.800782c11.890625 5.910156 24.738281 12.300781 36.730469 18.261718l18.347656-36.710937c11.109375-22.199219 38.191406-31.21875 60.382813-20.128907 22.238281 11.128907 31.25 38.136719 20.117187 60.378907l-32.707031 65.429687c.867187 8.660156.097656 17.390625-2.34375 25.839844h21.59375l-56.953125 156.609375c-3.378906 9.289063 3.152344 20.128906 14.101562 20.128906 6.121094 0 11.878907-3.777343 14.089844-9.878906l60.679688-166.859375h10.511718l61.296876 166.910156c2.230468 6.070313 7.972656 9.828125 14.089843 9.828125 10.230469 0 17.730469-10.21875 14.070313-20.167969l-57.5-156.570312h75.5625c26.027344 0 47.128906-21.101562 47.128906-47.128906v-176.742188c0-26.027344-21.101562-47.128906-47.128906-47.128906zm-32.140625 206.738281h-67.300781c-8.28125 0-15-6.71875-15-15 0-8.289062 6.71875-15 15-15h67.300781c8.289062 0 15 6.710938 15 15 0 8.28125-6.710938 15-15 15zm0-56.238281h-67.300781c-8.28125 0-15-6.710938-15-15 0-8.28125 6.71875-15 15-15h67.300781c8.289062 0 15 6.71875 15 15 0 8.289062-6.710938 15-15 15zm0-56.230469h-160.660157c-8.289062 0-15-6.71875-15-15s6.710938-15 15-15h160.660157c8.289062 0 15 6.71875 15 15s-6.710938 15-15 15zm0 0"
                    fill="currentColor"
                  />
                  <path
                    d="m263.921875 264.566406c2.710937-7.941406 2.691406-16.238281.402344-23.808594l37.226562-74.445312c3.703125-7.410156.703125-16.421875-6.707031-20.125-7.40625-3.707031-16.417969-.703125-20.125 6.707031l-31.75 63.492188c-18.976562-9.449219-47.808594-23.785157-71.035156-35.335938-21.609375-10.746093-37.03125-20.382812-63.34375-20.382812h-13.253906c20.488281 0 38.773437-9.429688 50.742187-24.1875 9.132813-11.230469 14.59375-25.542969 14.59375-41.140625 0-36.089844-29.25-65.339844-65.34375-65.339844-36.078125 0-65.328125 29.25-65.328125 65.339844 0 17.332031 6.75 33.082031 17.761719 44.769531 11.90625 12.660156 28.816406 20.558594 47.5625 20.5625-25.132813 0-49.703125 10.183594-67.378907 27.90625-18.019531 17.972656-27.945312 41.917969-27.945312 67.425781v48.199219c0 20.75 13.472656 38.40625 32.132812 44.6875v83.84375c0 8.285156 6.714844 15 15 15h96.398438c8.285156 0 15-6.714844 15-15v-168.535156c14.222656 7.109375 32.90625 16.449219 48.023438 24.011719 10.597656 5.296874 22.980468 5.773437 34.023437 1.28125 11.011719-4.53125 19.5-13.550782 23.285156-24.75.019531-.058594.039063-.117188.058594-.175782zm0 0"
                    fill="currentColor"
                    opacity="0.4"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.3328 22H7.66618C4.2769 22 2 19.6229 2 16.0843V7.91672C2 4.37811 4.2769 2 7.66618 2H16.3338C19.7231 2 22 4.37811 22 7.91672V16.0843C22 19.6229 19.7231 22 16.3328 22Z"
                    fill="currentColor"
                  ></path>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2451 8.67496C11.2451 10.045 10.1301 11.16 8.7601 11.16C7.3891 11.16 6.2751 10.045 6.2751 8.67496C6.2751 7.30496 7.3891 6.18896 8.7601 6.18896C10.1301 6.18896 11.2451 7.30496 11.2451 8.67496ZM19.4005 14.0876C19.6335 14.3136 19.8005 14.5716 19.9105 14.8466C20.2435 15.6786 20.0705 16.6786 19.7145 17.5026C19.2925 18.4836 18.4845 19.2246 17.4665 19.5486C17.0145 19.6936 16.5405 19.7556 16.0675 19.7556H7.6865C6.8525 19.7556 6.1145 19.5616 5.5095 19.1976C5.1305 18.9696 5.0635 18.4446 5.3445 18.1026C5.8145 17.5326 6.2785 16.9606 6.7465 16.3836C7.6385 15.2796 8.2395 14.9596 8.9075 15.2406C9.1785 15.3566 9.4505 15.5316 9.7305 15.7156C10.4765 16.2096 11.5135 16.8876 12.8795 16.1516C13.8132 15.641 14.3552 14.7673 14.827 14.0069L14.8365 13.9916C14.8682 13.9407 14.8997 13.8898 14.9311 13.8391C15.0915 13.5799 15.2495 13.3246 15.4285 13.0896C15.6505 12.7986 16.4745 11.8886 17.5395 12.5366C18.2185 12.9446 18.7895 13.4966 19.4005 14.0876Z"
                    fill="currentColor"
                  ></path>
                </svg>
              )}
              {props.type == "audio" ||
              props.type == "html" ||
              props.type == "podcast" ||
              props.type == "presentations" ? (
                <p className=" mb-0 text-dark">
                  {props.imgName}.
                  {props.type == "html"
                    ? "html"
                    : props.type == "presentations"
                    ? "pptx"
                    : "mp3"}
                </p>
              ) : (
                <p className=" mb-0 text-dark">Gallery-{props.imgName}.jpg</p>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <small>
              You opened <Link to="#">{props.imgLink}</Link>
            </small>
            {props.trash === "true" && (
              <Dropdown>
                <Dropdown.Toggle
                  as="svg"
                  height="20"
                  viewBox="0 0 5 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="2.5" cy="3" r="2.5" fill="currentcolor"></circle>
                  <circle cx="2.5" cy="10" r="2.5" fill="currentcolor"></circle>
                  <circle cx="2.5" cy="17" r="2.5" fill="currentcolor"></circle>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <li>
                    <Dropdown.Item
                      className="dropdown-item delete-btn"
                      onClick={Sweetalert1}
                    >
                      Delete
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item
                      className="dropdown-item restore-btn"
                      onClick={Sweetalert}
                    >
                      Restore
                    </Dropdown.Item>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
});

Images.displayName = "Images";
export default Images;
