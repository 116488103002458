import React, { useRef } from "react";
import { Card, Image, Button, Col } from "react-bootstrap";
import avatar from "../../../../../../../assets/images/img1.jpeg";
import { findIcon } from "../../../../../../dashboard/icons";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useGetAllVideoList } from "../../../../../../../queries/query-hooks/workspace/workspace-hook";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import DashboardMyVideoSkeleton from "../../../../../../../skeleton/workspace/doctube/channel/dashboard-my-video-skeleton";
import { useSelector } from "react-redux";
import { currentChannel } from "../../../../../../../store/channel/channel.selector";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
import { useParams } from "react-router-dom";
import { getRelativeDate } from "../../../../../../../common/utils/utils";
import { selectProject } from "../../../../../../../store/project/project.selector";

const MyVideos = () => {
  const { redirectTo } = useRedirect();
  const { channel_id } = useParams();
  const channel = useSelector(currentChannel);
  const project = useSelector(selectProject);
  const allVideoRef = useRef(
    //   {
    //   default: "all",
    //   pagination: {
    //     limit: 10,
    //     offset: 0,
    //   },
    //   filter: {
    //     status: {
    //       type: "eq",
    //       value: 1,
    //     },
    //   },
    //   sort: {
    //     content_child_id: "desc",
    //   },
    //   project_id: 1,
    //   video_type: 1,
    //   channel_id: channel_id,
    // }
    {
      default: "all",
      pagination: {
        limit: 10,
        offset: 0,
      },
      filter: {
        content_category_id: {
          type: "eq",
          value: "30",
        },
        status: {
          type: "eq",
          value: 1,
        },
        // ...selectedFilter,
      },
      join: {
        content_to_contents: {
          reletion: {
            prefix: "content_id",
            suffix: "content_child_id",
          },
          condition: {
            content_parent_id: {
              type: "eq",
              value: channel_id,
            },
          },
        },
      },
      sort: {
        content_id: "DESC",
      },
      project_id: project?.project_id,
      video_type: 1, //reel = 0 || video = 1
      parent_id: channel_id, //437//328
    }
  );
  const { data: videoData, isLoading: isChannelVideoLoading } =
    useGetAllVideoList(allVideoRef.current);

  const { data: channelVideoData, total: totalVideoCount } = videoData || {
    data: [],
    total: 0,
  };
  const onVideoClick = (id) => {
    redirectTo(`channel/video/${id}`);
  };

  // skeleton
  if (isChannelVideoLoading) {
    return <DashboardMyVideoSkeleton />;
  }

  return (
    <>
      {channelVideoData?.length > 0 && (
        <Card data-aos="fade-up" data-aos-delay="500">
          <Card.Body>
            <Col md={12} className="mb-3 d-flex justify-content-between">
              <h5 className="mb-1 fw-semibold">
                My Videos
                {/* {channelVideoData?.length > 0 ? (
                <span className="bg-soft-primary p-1 fw-semibold d-inline-flex align-items-center justify-content-center rounded-pill px-2 fs-6 ms-2">
                  {channelVideoData?.length}
                </span>
              ) : (
                <span className="bg-soft-light p-1 fw-semibold d-inline-flex align-items-center justify-content-center rounded-pill px-2 fs-6 ms-2">
                  0
                </span>
              )}*/}
              </h5>
              <Link
                to={"/channel/videos/" + channel?.channel_id}
                className="ms-auto fs-6"
              >
                View All{" "}
                <span className="transform-rotate-45">
                  {findIcon("Arrow Up", "outline", 24)}
                </span>
              </Link>
            </Col>

            <div className="overflow-hidden d-slider1">
              {!isChannelVideoLoading && channelVideoData?.length > 0 ? (
                <>
                  <Swiper
                    as="ul"
                    className="p-0 m-0 mb-2 list-inline swiper-wrapper"
                    slidesPerView={5}
                    spaceBetween={15}
                    navigation={false}
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 2 },
                      1400: { slidesPerView: 3 },
                      1500: { slidesPerView: 3 },
                      1920: { slidesPerView: 3 },
                    }}
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    {channelVideoData.map((data, key) => (
                      <>
                        <SwiperSlide key={`my-video-${key}`}>
                          <div className="d-flex gap-3 border rounded-3 p-3">
                            <div className="w-rem-8 h-auto rounded-3 ratio-9x16 flex-shrink-0 overflow-hidden">
                              {data?.video_image_data?.content_image_id
                                ?.thumbnail && (
                                <img
                                  src={
                                    data?.video_image_data?.content_image_id
                                      ?.thumbnail
                                  }
                                  alt={data.content_title}
                                  onError={(e) =>
                                    (e.target.src = fallbackImages.noVideo)
                                  }
                                  className="icon-90 w-100 object-fit-cover"
                                />
                              )}
                            </div>
                            <div className="d-flex flex-column justify-content-between flex-grow-1 overflow-hidden">
                              {data.content_title && (
                                <h5 className="fw-normal fs-6 m-0 two-line-clamp text-black mb-2 lh-sm">
                                  {data.content_title}
                                </h5>
                              )}
                              <div className="d-flex justify-content-between align-items-center ">
                                {data?.content_publication_date && (
                                  <p className="m-0 fs-7">
                                    <span>
                                      {data?.channel_tracking_data[0]
                                        ?.total_views_count ?? 0}{" "}
                                      Views |{" "}
                                      {getRelativeDate(
                                        data.content_publication_date
                                      )}{" "}
                                    </span>
                                  </p>
                                )}
                                <Button
                                  className="ms-auto rounded-2 px-3 py-1"
                                  variant="primary"
                                  size="sm"
                                  onClick={() => onVideoClick(data.content_id)}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </>
                    ))}
                  </Swiper>
                </>
              ) : isChannelVideoLoading ? (
                <>
                  <DashboardMyVideoSkeleton />
                </>
              ) : (
                <>
                  <p>No data found </p>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default MyVideos;
