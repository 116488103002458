import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import Chart from "react-apexcharts";
import { dateFilterOptions } from "../../Constant/date-filter-constant";
import GraphTab from '../../components/common/GraphTab';
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";

function AnalyticsEngagement({ data }) {
  const { watch_time_hourly_data } = data || { watch_time_hourly_data: [] }
  const [payload, setPayload] = useState({
    type: dateFilterOptions.initial,
  });

  function transformGraphData(data, seriesKey, labelKey) {
    if (data && data?.length <= 0) {
      return { series: [], label: [] };
    } else {
      return data.reduce(
        (acc, item) => ({
          series: [...acc.series, parseInt(item[seriesKey])],
          label: [...acc.label, item[labelKey]],
        }),
        { series: [], label: [] }
      );
    }
  }


  const watchTimeOptions = {
    series: [
      {
        name: "Seconds Watched",
        data: watch_time_hourly_data &&
          transformGraphData(watch_time_hourly_data, "total_hour", "level")?.series?.length > 0
          ? transformGraphData(watch_time_hourly_data, "total_hour", "level").series
          : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    // markers: {
    //   size: [4, 7],
    // },
    labels: watch_time_hourly_data &&
      transformGraphData(watch_time_hourly_data, "avg_view", "level")?.label?.length > 0
      ? transformGraphData(watch_time_hourly_data, "avg_view", "level").label
      : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const averageViewOptions = {
    series: [
      {
        name: "Seconds Watched",
        data: watch_time_hourly_data &&
          transformGraphData(watch_time_hourly_data, "avg_view", "level")?.series?.length > 0
          ? transformGraphData(watch_time_hourly_data, "avg_view", "level").series
          : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    // markers: {
    //   size: [4, 7],
    // },

    labels: watch_time_hourly_data &&
      transformGraphData(watch_time_hourly_data, "avg_view", "level")?.label?.length > 0
      ? transformGraphData(watch_time_hourly_data, "avg_view", "level").label
      : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };
  function sumArray(arr) {
    if (!arr?.length) { return 0 } else {
      return arr.reduce((sum, current) => sum + current, 0)?.toLocaleString()
    }
  }
  function avgArray(arr) {
    if (!arr?.length) { return 0 } else {
      return arr.reduce((sum, current) => sum + current, 0) / arr.length
    }
  }
  const graphTabs = [
    {
      eventKey: "first-gr", title: "Watch time", dataCount: `${watchTimeOptions.series[0]?.data?.length > 0 ? sumArray(watchTimeOptions.series[0]?.data) : 0}`, content: <>    <Card.Body className="p-0">
        <div>
          <div id="chart-gr">
            <Chart
              options={watchTimeOptions}
              series={watchTimeOptions.series}
              type="line"
              height={350}
            />
          </div>
        </div>
      </Card.Body></>
    },
    {
      eventKey: "second-gr", title: "Average views duration", dataCount: `${averageViewOptions.series[0]?.data?.length > 0 ? avgArray(averageViewOptions.series[0]?.data) : 0}`, content: <> <Card.Body className="p-0">
        <div>
          <div id="chart-watch">
            <Chart
              options={averageViewOptions}
              series={averageViewOptions.series}
              type="line"
              height={350}
            />
          </div>
        </div>
      </Card.Body></>
    }
  ]

  return (
    <Card>
      <Card.Body >
        <GraphTab tabs={graphTabs} />
      </Card.Body>
    </Card>
  );
}

export default AnalyticsEngagement;
