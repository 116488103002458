import React from 'react'
import { Nav, Tab } from "react-bootstrap";
export default function GraphTab({ tabs }) {
    return (
        <>
            <Tab.Container defaultActiveKey={tabs[0].eventKey}>
                <Nav className="tab-bottom-bordered bg-transparent d-flex allign-items-center gap-3 tab-bottom-bordered--thin">
                    <div
                        className="mb-0 nav nav-tabs w-100 gray-tabs"
                        id="nav-tab1"
                        role="tablist"
                    >
                        {tabs.map(({ title, eventKey, dataCount, analytics, content }) => (
                            <Nav.Link
                                key={eventKey}
                                className="p-3 flex-grow-1 flex-shrink-0 d-flex align-items-center justify-content-center"
                                aria-selected="true"
                                eventKey={eventKey}
                                data-bs-toggle="tab"
                                data-bs-target={`#nav-${eventKey}`}
                                type="button"
                                role="tab"
                                aria-controls={`nav-${eventKey}`}
                            >
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    {title && <span className="fs-7">{title}</span>}
                                    {dataCount && <span className="fs-4 fw-bold text-black opacity-50">{dataCount}</span>}
                                    {/* image required */}
                                    {analytics && <span className="fs-7 italic">{analytics}</span>}
                                </div>
                            </Nav.Link>
                        ))}
                    </div>
                </Nav >
                <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
                    {tabs.map(({ eventKey, content }) => (<>
                        <Tab.Pane
                            key={eventKey}
                            eventKey={eventKey}
                            id={`nav-${eventKey}`}
                            role="tabpanel"
                            aria-labelledby={`nav-${eventKey}`}
                        >
                            <div className="d-flex flex-column">{content}</div>
                        </Tab.Pane>
                    </>))}
                </Tab.Content>
            </Tab.Container >
        </>
    )
}
