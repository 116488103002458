import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col, Image, Button, Form, Modal } from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "react-dropzone-uploader/dist/styles.css";

import avatar1 from "../../../../../../../assets/images/avatars/01.png";

import { updateChannelInput } from "../../../../../../../store/channel/channel.action";
import { useDispatch } from "react-redux";
import { findValueByField } from "../../../../../../../common/utils/utils";
import { findIcon } from "../../../../../../dashboard/icons";

function CustomizationBranding({ data, activeTab, eventKey }) {
  const profilePictureInput = useRef();
  const coverPictureInput = useRef();
  const watermarkInput = useRef();
  const [watermarkInputValue, setWatermarkInputValue] = useState("");
  const [profilePictureInputValue, setProfilePictureInputValue] = useState("");
  const [coverPictureInputValue, setCoverPictureInputValue] = useState("");
  const [watermarkPosition, setWatermarkPosition] = useState("bottom-right");
  const dispatch = useDispatch();
  const profilePictureSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can manage your profile image in your Doctube channel.
    </Tooltip>
  );
  const bannerPictureSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can manage your banner image in your Doctube channel.
    </Tooltip>
  );

  const BannerImageTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Banner Section Tooltip
    </Tooltip>
  );

  const videoWatermarkTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Customize where your watermark appears on your videos
    </Tooltip>
  );

  const handleChangeProfilePicture = (e) => {
    if (e.target.files.length > 0)
      setProfilePictureInputValue(e.target.files[0]);
  };

  const handleChangeCoverPicture = (e) => {
    if (e.target.files.length > 0) setCoverPictureInputValue(e.target.files[0]);
  };
  const handleChangeWatermark = (e) => {
    if (e.target.files.length > 0) setWatermarkInputValue(e.target.files[0]);
  };
  useEffect(() => {
    dispatch(
      updateChannelInput({
        profilePicture: profilePictureInputValue,
        coverPicture: coverPictureInputValue,
        watermark: watermarkInputValue,
        watermarkPosition,
      })
    );
  }, [
    profilePictureInputValue,
    coverPictureInputValue,
    watermarkInputValue,
    watermarkPosition,
  ]);
  useEffect(() => {
    if (data?.content_to_attr?.length > 0) {
      setWatermarkPosition(
        findValueByField(data?.content_to_attr, "watermarkPosition")
      );
      setWatermarkInputValue(
        findValueByField(data?.content_to_attr, "watermark")
      );
    }
  }, [data?.content_to_attr]);

  useEffect(() => {
    const profilePicture =
      data?.content_to_image?.length > 0
        ? data?.content_to_image[0]?.content_image_id?.thumbnail
        : "";
    const coverPicture =
      data?.content_to_image?.length > 0
        ? data?.content_to_image[0]?.content_image_id?.base
        : "";

    console.log("cover", coverPicture, profilePicture);
    setProfilePictureInputValue(profilePicture);
    setCoverPictureInputValue(coverPicture);
  }, [data?.content_to_image]);

  const makeStyle = () => {
    if (watermarkPosition) {
      let first = watermarkPosition.split("-")[0];
      let second = watermarkPosition.split("-")[1];
      return {
        [first]: 15,
        [second]: 10,
      };
    }
  };
  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-column gap-md-5 gap-4">
          {/* Profile picture start  */}

          <Row>
            <Col md={12}>
              <h5 className="mb-1">Picture</h5>
              <p>
                Your profile picture will appear where your channel is presented
                on DocTube. the next to your videos and comments
              </p>
            </Col>
            <Col md={4} xs={5}>
              <div className="d-flex align-items-center justify-content-center bg-light p-3">
                <div className="avatar-100 bg-light rounded-circle overflow-hidden  flex-shrink-0">
                  <Image
                    src={
                      profilePictureInputValue &&
                      typeof profilePictureInputValue !== "string"
                        ? URL.createObjectURL(profilePictureInputValue)
                        : profilePictureInputValue || avatar1
                    }
                    className="w-100 object-fit-cover h-100"
                    onError={(e) => (e.target.src = avatar1)}
                    fluid
                  />
                </div>
              </div>{" "}
              {!profilePictureInputValue && (
                <span className="fst-italic text-danger">
                  **No file selected
                </span>
              )}
            </Col>
            <Col lg={5} md={7} xs={7}>
              <div>
                <p className="text-muted ">
                  it's recommend to use a picture that's at least 98 x 98 pixels
                  and 4MB or less . Use a PNG or Gif (no animation) the Make
                  sure your picture follows the doctube community guidelines.
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 200 }}
                    overlay={profilePictureSectionTooltip}
                  >
                    <span className="text-primary cursorPointer ms-1 fw-semibold">
                      (?)
                    </span>
                  </OverlayTrigger>
                </p>
                <div className="d-flex gap-4">
                  <input
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={handleChangeProfilePicture}
                    ref={profilePictureInput}
                  />
                  <Button
                    variant="link"
                    className="flex-shrink-0 p-0  fw-semibold d-inline-flex gap-1 align-items-center"
                    onClick={() => profilePictureInput.current.click()}
                  >
                    Change
                  </Button>
                  <Button
                    variant="link"
                    className="flex-shrink-0 p-0  fw-semibold d-inline-flex gap-1 align-items-center"
                    onClick={() => setProfilePictureInputValue("")}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          {/* Profile picture end */}

          <Row>
            <Col md={12}>
              <h5 className="mb-1">Banner Image</h5>
              <p>This image will be appear across the top of your channel</p>
            </Col>
            <Col md={4} xs={5}>
              <div className="d-flex align-items-center justify-content-center bg-light p-3">
                <div className="bg-light overflow-hidden flex-shrink-0 mw-100 mh-100">
                  <Image
                    src={
                      coverPictureInputValue &&
                      typeof coverPictureInputValue !== "string"
                        ? URL.createObjectURL(coverPictureInputValue)
                        : coverPictureInputValue ||
                          "https://img-cdn.clirnet.com/doctube_logo.svg"
                    }
                    className="mw-100 h-auto"
                    style={{ width: "140px" }}
                    onError={(e) =>
                      (e.target.src =
                        "https://img-cdn.clirnet.com/doctube_logo.svg")
                    }
                  />
                </div>
              </div>
              {!coverPictureInputValue && (
                <span className="fst-italic text-danger">
                  **No file selected
                </span>
              )}
            </Col>
            <Col lg={5} md={7} xs={7}>
              <div>
                <p className="text-muted ">
                  For the best result on all devices, Use an image that's at
                  least 2048 X 1152 pixels. and 6MB or less
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 200 }}
                    overlay={bannerPictureSectionTooltip}
                  >
                    <span className="text-primary cursorPointer ms-1 fw-semibold">
                      (?)
                    </span>
                  </OverlayTrigger>
                </p>
                <div className="d-flex gap-4">
                  <input
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={handleChangeCoverPicture}
                    ref={coverPictureInput}
                  />
                  <Button
                    variant="link"
                    className="flex-shrink-0 p-0  fw-semibold d-inline-flex gap-1 align-items-center"
                    onClick={() => coverPictureInput.current.click()}
                  >
                    Change
                  </Button>
                  <Button
                    variant="link"
                    className="flex-shrink-0 p-0  fw-semibold d-inline-flex gap-1 align-items-center"
                    onClick={() => setCoverPictureInputValue("")}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          {/*  */}

          <Row>
            <Col md={12}>
              <h5 className="mb-1">Video Watermark</h5>
              <p>
                The watermark will appear on your videos according to your
                choice
              </p>
            </Col>
            <Col md={4} xs={5}>
              <div className="d-flex video-logo align-items-center justify-content-center bg-light p-3 h-100 rounded-2 position-relative overflow-hidden">
                <div className="position-absolute end-0 bottom-0 w-100 h-100">
                  <Image
                    src={
                      "https://storage.googleapis.com/gl-clirnet-static-assets-prod/engageUnity/video%20player.svg"
                    }
                    className="w-100 h-100 object-fit-cover position-absolute end-0 bottom-0"
                  />
                </div>
                <div
                  className="bg-light overflow-hidden flex-shrink-0 mw-100 mh-100 z-1 position-absolute mb-0 mb-md-5"
                  style={makeStyle()}
                >
                  <Image
                    src={
                      watermarkInputValue &&
                      typeof watermarkInputValue !== "string"
                        ? URL.createObjectURL(watermarkInputValue)
                        : watermarkInputValue ||
                          "https://doctube.com/favicon.ico"
                    }
                    className="mw-100 rounded-2"
                    style={{
                      maxHeight: "1rem",
                      minHeight: "2rem",
                    }}
                  />
                </div>
              </div>
              {!watermarkInputValue && (
                <span className="fst-italic text-danger">
                  **No file selected
                </span>
              )}
            </Col>
            <Col lg={5} md={7} xs={7}>
              <div>
                <p className="text-muted ">
                  Position
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 50, hide: 200 }}
                    overlay={videoWatermarkTooltip}
                  >
                    <span className="text-primary cursorPointer ms-1 fw-semibold">
                      (?)
                    </span>
                  </OverlayTrigger>
                </p>
                <div className="d-flex gap-2 flex-column">
                  {["top-right", "top-left", "bottom-right", "bottom-left"].map(
                    (item, index) => (
                      <Form.Check className="d-block" key={index}>
                        <Form.Check.Input
                          className="me-2"
                          type="radio"
                          name="flexRadioDefault"
                          checked={watermarkPosition === item}
                          onChange={() => setWatermarkPosition(item)}
                          id={item}
                        />

                        <Form.Check.Label
                          htmlFor={item}
                          className="text-capitalize"
                        >
                          {item?.split("-").join(" ")}
                        </Form.Check.Label>
                      </Form.Check>
                    )
                  )}
                </div>
                <div className="d-flex gap-4 mt-3">
                  <input
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={handleChangeWatermark}
                    ref={watermarkInput}
                  />
                  <Button
                    variant="link"
                    className="flex-shrink-0 p-0  fw-semibold d-inline-flex gap-1 align-items-center"
                    onClick={() => watermarkInput.current.click()}
                  >
                    Change
                  </Button>
                  <Button
                    variant="link"
                    className="flex-shrink-0 p-0  fw-semibold d-inline-flex gap-1 align-items-center"
                    onClick={() => setWatermarkInputValue("")}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default React.memo(CustomizationBranding);
