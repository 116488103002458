import React, { useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormCheck,
  Image,
  ListGroup,
  ListGroupItem,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import CountUp from "react-countup";
import Card from "../../../../components/bootstrap/card";
import avatars11 from "../../../../assets/images/avatars/01.png";
import toast from "react-hot-toast";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import Progress from "../../../../components/progress";

import mobile from "../../../../assets/images/mobile-bg.png";
import mobileWhatsapp from "../../../../assets/images/mobileWhatsapp.png";

const OnboardingApprovalDetails = () => {
  const [modalShow, setModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [datashow, setDataShow] = useState(false);
  const [comment, setComment] = useState("");

  const [isEdit, setIsedit] = useState({
    edit: false,
    selectedMsgDistribution: "Schedule for Later",
    scheduleForlater: { edit: false, data: "2023-06-08T15:52", comment: "" },
    prefferedDayTime: {
      edit: false,
      data: { day: "Monday", time: "15:52" },
      comment: "",
    },
    prefferedTime: { edit: false, data: "15:52", comment: "" },
  });

  const [editedMsgConfigs, setEditedMsgConfigs] = useState({
    scheduleForlater: isEdit.scheduleForlater.data,
    prefferedDayTime: isEdit.prefferedDayTime.data,
    prefferedTime: isEdit.prefferedTime.data,
  });

  const [msgDistributionType, setMsgDistributionType] = useState(
    isEdit.selectedMsgDistribution
  );

  const [results, setResults] = useState([]);

  console.log(comment, results);

  const editMsgDistribution = () => setIsedit({ ...isEdit, edit: true });

  const getMsgDistributionType = (e) => {
    if (!isEdit.edit) {
      return;
    }
    setMsgDistributionType(e.target.value);
  };

  const saveMsgDistribution = () => setIsedit({ ...isEdit, edit: false });
  const cancelEditMsgDistribution = () => {
    setMsgDistributionType(isEdit.selectedMsgDistribution);
    setIsedit({ ...isEdit, edit: false });
  };

  const getConfigureMsgDistributionData = (e, type, dataType = "") => {
    console.log("XXXX show data", e.target.value, type, dataType);
    if (type === "prefferedDayTime") {
      setEditedMsgConfigs({
        ...editedMsgConfigs,
        [type]: {
          ...editedMsgConfigs[type],
          [dataType]: e.target.value,
        },
      });
      // let data = editedMsgConfigs[type][dataType];
      // console.log("XXXX show data 2", data,);
      // setEditedMsgConfigs((prev) => {
      //   let temp = { ...prev };
      //   console.log("XXXX show data", temp[type].data[dataType]);
      //   return temp;
      // });
    } else {
      setEditedMsgConfigs({ ...editedMsgConfigs, [type]: e.target.value });
    }
  };

  const saveEditConfigureMsgDistribution = (type) => {
    setIsedit({
      ...isEdit,
      [type]: {
        ...isEdit[type],
        edit: !isEdit[type].edit,
        data: editedMsgConfigs[type],
      },
    });
  };

  const editConfigureMsgDistributionHandler = (type) => {
    setIsedit({
      ...isEdit,
      [type]: {
        ...isEdit[type],
        edit: !isEdit[type].edit,
      },
    });
  };

  const modalClose = () => {
    setViewModalShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setViewModalShow(false);
    if (comment !== "") {
      setDataShow(true);
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const data = {
        id: results?.length + 1,
        comment: comment,
        createdAt: date + " " + time,
      };

      setResults((prev) => [...prev, data]);
    } else {
      setDataShow(false);
      toast.error("No comments to show");
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Comments",
      accessor: "comment",
    },
    {
      Header: "Added On",
      accessor: "createdAt",
    },
  ];

  console.log("XXXXXXXX", isEdit);
  return (
    <>
      <Row className="m-0">
        <Col lg="12" className="bg-white rounded-3 pb-3">
          <div className="col-lg-12 p-3 my-2 d-flex flex-column justify-content-start align-items-start">
            <div className="d-block fw-normal text-secondary fs-4">
              Approval to Roll Out Final Campaign - SMS
            </div>
            <div className="fs-7 w-100 d-flex flex-row justify-content-between align-items-center gap-3 py-1">
              <div className="d-flex flex-row gap-3">
                <span>
                  Created By: <strong>Swagata Goswami</strong>
                </span>
                <span>
                  Date: <strong>24 Mar 2023</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="w-100 p-3 rounded-3 border border-primary border-0 d-flex flex-column align-items-center justify-content-start shadow-lg">
            <Row className="w-100">
              <div className="col-4 p-0 d-flex flex-column">
                <div className="p-3 mb-3 d-flex flex-column rounded-4 text-black lh-base border border-primary border-2 border-dashed">
                  <p className="m-0 fs-7">Segment Details:</p>
                  <p className="m-0 fw-normal">Nephrology Doctors in Kolkata</p>
                </div>
                <div className="position-relative flex-fill">
                  <img
                    src={mobile}
                    alt="mobile"
                    className="h-auto w-100 d-block position-relative z-0"
                  />
                  <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                    <div className="h-100 rounded-5 p-4 mobile-preview position-relative">
                      <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                        Dear NAME, We are delighted to inform you about the
                        launch of our cutting-edge digital glucose monitoring
                        tool. As a valued client, we believe this product can
                        revolutionize your glucose management. Don't miss out on
                        this opportunity to stay ahead in your health journey.
                        LINK
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="bg-soft-success flex-fill rounded-4 flex-fill">
                  <div className="row row-cols-2 m-0 my-3"> */}
                {/* <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-warning">
                            {findIcon("View Grid", "dual-tone", "30")}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">Segment Size</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={3} end={1223} duration={3} />
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="warning"
                              color="warning"
                              className="shadow-none w-100"
                              value={23}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-success">
                            {findIcon("Whatsapp", "dual-tone", "30")}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">WhatsApp</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={29} end={49} duration={3} />
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="success"
                              color="success"
                              className="shadow-none w-100"
                              value={50}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-dark">
                            {findIcon("Email", "dual-tone", "30")}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">Email</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={29} end={55} duration={3} />
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="dark"
                              color="dark"
                              className="shadow-none w-100"
                              value={55}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-danger">
                            {findIcon("Message-2", "dual-tone", "30")}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">SMS</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={9} end={68} duration={3} />
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="danger"
                              color="danger"
                              className="shadow-none w-100"
                              value={68}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col> */}
                {/* <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-primary">
                            {findIcon("Action", "dual-tone", "30")}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">Touch Point</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={29} end={35} duration={3} /> %
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="primary"
                              color="primary"
                              className="shadow-none w-100"
                              value={35}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col> */}
                {/* <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-info">
                            {findIcon(
                              "Exclamation Triangle",
                              "dual-tone",
                              "30"
                            )}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">Blocked by DND</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={29} end={32} duration={3} />
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="info"
                              color="info"
                              className="shadow-none w-100"
                              value={32}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-0">
                        <Card.Body className="p-2 d-flex flex-column align-items-center justify-content-center">
                          <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-danger">
                            {findIcon("Filter", "dual-tone", "30")}
                          </div>
                          <div className="text-center">
                            <h5 className="my-2 fs-6">Reach</h5>
                            <h6 className="mt-2 mb-0 fw-normal text-secondary">
                              <CountUp start={29} end={72} duration={3} />
                            </h6>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="danger"
                              color="danger"
                              className="shadow-none w-100"
                              value={72}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col> */}
                {/* </div>
                </div> */}
              </div>
              {/* <div className="col-4">
                <div className="bg-soft-primary text-muted h-100 p-3 rounded-4 d-flex flex-column">
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="tabs"
                      data-toggle="slider-tab"
                      role="tablist"
                      className="w-100 bg-transparent d-flex flex-row gap-2 mb-3"
                    >
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="first"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-home1"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variant A
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="second"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-profile1"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variant B
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="third"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-contact1"
                          type="button"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variant C
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="flex-fill">
                      <Tab.Pane
                        eventKey="first"
                        variant=" fade show active"
                        id="pills-home1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab1"
                      >
                        <div className="position-relative flex-fill">
                          <img
                            src={mobile}
                            alt="mobile"
                            className="h-auto w-100 d-block position-relative z-0"
                          />
                          <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                            <div className="h-100 rounded-5 p-4 mobile-preview position-relative">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                                Preview sms template and watsapp messages are
                                goes here
                              </span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="second"
                        className="fade"
                        id="pills-profile1"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab1"
                      >
                        <div className="position-relative flex-fill">
                          <img
                            src={mobileWhatsapp}
                            alt="mobile whatsapp"
                            className="h-auto w-100 d-block position-relative z-0"
                          />
                          <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                            <div className="h-100 rounded-5 p-4 whatsapp-preview position-relative">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base">
                                Preview watsapp messages are goes here
                              </span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="third"
                        className="fade h-100"
                        id="pills-contact1"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab1"
                      >
                        <div className="position-relative h-100 overflow-scroll bg-white border border-1 border-dotted rounded-3 p-3 border-secondary text-center">
                          Preview Newsletter template here
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div> */}
              <div className="col-8 d-flex flex-column">
                <div className="text-muted h-100 p-3 rounded-4 d-flex flex-column border border-1">
                  <div className="bg-soft-warning p-3 mb-3 d-flex flex-column rounded-4 lh-base">
                    <div className="d-flex justify-content-between">
                      <p className="m-0 mb-2">
                        Configured Message Distribution
                      </p>
                      {isEdit.edit ? (
                        <div className="d-flex justify-content-start align-items-center gap-2">
                          <Button
                            variant="success"
                            className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                            type="submit"
                            disabled={
                              msgDistributionType ==
                              isEdit.selectedMsgDistribution
                            }
                            onClick={saveMsgDistribution}
                          >
                            {findIcon("Check", "outline", "18")}
                          </Button>
                          <Button
                            variant="danger"
                            className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                            type="submit"
                            onClick={cancelEditMsgDistribution}
                          >
                            {findIcon("Close-2", "outline", "14")}
                          </Button>
                        </div>
                      ) : (
                        <span
                          className="cursor-pointer d-inline-flex text-secondary"
                          onClick={editMsgDistribution}
                        >
                          {findIcon("Pencil", "dual-tone", "20")}
                        </span>
                      )}
                    </div>

                    <div className="m-0 fw-normal">
                      {/* <ListGroup className="bg-transparent">
                        <ListGroupItem
                          as="label"
                          className="bg-transparent border-0 px-0"
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                          />
                          SMS Template
                        </ListGroupItem>
                        <ListGroupItem
                          as="label"
                          className="bg-transparent border-0 px-0"
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                          />
                          WhatsApp Template
                        </ListGroupItem>
                        <ListGroupItem
                          as="label"
                          className="bg-transparent border-0 px-0"
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                          />
                          Email Template
                        </ListGroupItem>
                      </ListGroup> */}
                      {/* <div className="d-flex justify-content-start align-items-center gap-2"></div> */}
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio3"
                          value={"Send Now"}
                          checked={msgDistributionType === "Send Now"}
                          disabled={!isEdit.edit}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio3">
                          Send Now
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio4"
                          value={"Schedule for Later"}
                          checked={msgDistributionType === "Schedule for Later"}
                          disabled={!isEdit.edit}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio4">
                          Schedule for Later
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio4"
                          value={"Preferred Day & Time"}
                          checked={
                            msgDistributionType === "Preferred Day & Time"
                          }
                          disabled={!isEdit.edit}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio4">
                          Preferred Day & Time
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio4"
                          value={"Preferred Time"}
                          checked={msgDistributionType === "Preferred Time"}
                          disabled={!isEdit.edit}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio4">
                          Preferred Time
                        </FormCheck.Label>
                      </Form.Check>
                    </div>
                  </div>
                  {msgDistributionType === "Preferred Day & Time" &&
                    isEdit.prefferedDayTime.edit && (
                      <div className="d-flex justify-conntent-between">
                        <p className="p-0 mb-3">
                          Please select default Day and Time for users who don't
                          have Preferred Day & Time.
                        </p>

                        <div className="d-flex justify-content-start align-items-center gap-2">
                          <Button
                            variant="success"
                            className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                            type="submit"
                            disabled={
                              editedMsgConfigs.prefferedDayTime.day ===
                                isEdit.prefferedDayTime.data.day ||
                              editedMsgConfigs.prefferedDayTime.time ===
                                isEdit.prefferedDayTime.data.time
                            }
                            onClick={() =>
                              saveEditConfigureMsgDistribution(
                                "prefferedDayTime"
                              )
                            }
                          >
                            {findIcon("Check", "outline", "18")}
                          </Button>
                          <Button
                            variant="danger"
                            className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                            type="submit"
                            onClick={() =>
                              editConfigureMsgDistributionHandler(
                                "prefferedDayTime"
                              )
                            }
                          >
                            {findIcon("Close-2", "outline", "14")}
                          </Button>
                        </div>
                        {/* {isEdit.scheduleForlater.edit ? (
                          <div className="d-flex justify-content-start align-items-center gap-2">
                            <Button
                              variant="success"
                              className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                              type="submit"
                              disabled={
                                editedMsgConfigs.prefferedDayTime.day ===
                                isEdit.prefferedDayTime.data.day||
                                editedMsgConfigs.prefferedDayTime.time ===
                                isEdit.prefferedDayTime.data.time
                              }
                              onClick={() =>
                                saveEditConfigureMsgDistribution(
                                  "prefferedDayTime"
                                )
                              }
                            >
                              {findIcon("Check", "outline", "18")}
                            </Button>
                            <Button
                              variant="danger"
                              className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                              type="submit"
                              onClick={() =>
                                editConfigureMsgDistributionHandler(
                                  "prefferedDayTime"
                                )
                              }
                            >
                              {findIcon("Close-2", "outline", "14")}
                            </Button>
                          </div>
                        ) : (
                          <span
                            className="cursor-pointer d-inline-flex text-secondary"
                            onClick={() =>
                              editConfigureMsgDistributionHandler(
                                "prefferedDayTime"
                              )
                            }
                          >
                            {findIcon("Pencil", "dual-tone", "20")}
                          </span>
                        )} */}
                      </div>
                    )}
                  {msgDistributionType === "Preferred Time" &&
                    isEdit.prefferedTime.edit && (
                      <p className="p-0 mb-3">
                        Please select default Time for users who don't have
                        Preferred Time.
                      </p>
                    )}
                  {msgDistributionType === "Schedule for Later" && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="d-flex justify-content-between align-items-center">
                          <>
                            {isEdit.scheduleForlater.edit
                              ? "Select"
                              : "Selected"}{" "}
                            Date and Time{" "}
                            {!isEdit.scheduleForlater.edit &&
                              `: ${isEdit.scheduleForlater.data.replace(
                                "T",
                                " "
                              )}`}
                          </>
                          {isEdit.scheduleForlater.edit ? (
                            <div className="d-flex justify-content-start align-items-center gap-2">
                              <Button
                                variant="success"
                                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                type="submit"
                                disabled={
                                  editedMsgConfigs.scheduleForlater ===
                                  isEdit.scheduleForlater.data
                                }
                                onClick={() =>
                                  saveEditConfigureMsgDistribution(
                                    "scheduleForlater"
                                  )
                                }
                              >
                                {findIcon("Check", "outline", "18")}
                              </Button>
                              <Button
                                variant="danger"
                                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                type="submit"
                                onClick={() =>
                                  editConfigureMsgDistributionHandler(
                                    "scheduleForlater"
                                  )
                                }
                              >
                                {findIcon("Close-2", "outline", "14")}
                              </Button>
                            </div>
                          ) : (
                            <span
                              className="cursor-pointer d-inline-flex text-secondary"
                              onClick={() =>
                                editConfigureMsgDistributionHandler(
                                  "scheduleForlater"
                                )
                              }
                            >
                              {findIcon("Pencil", "dual-tone", "20")}
                            </span>
                          )}
                        </Form.Label>
                        {isEdit.scheduleForlater.edit && (
                          <Form.Control
                            type="datetime-local"
                            placeholder="Select Date and Time"
                            name="date_time"
                            value={editedMsgConfigs.scheduleForlater}
                            onChange={(e) =>
                              getConfigureMsgDistributionData(
                                e,
                                "scheduleForlater"
                              )
                            }
                          />
                        )}
                      </Form.Group>
                    </>
                  )}
                  {msgDistributionType === "Preferred Day & Time" && (
                    <div className="d-flex gap-2">
                      <Form.Group
                        className="mb-3 flex-1"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          <>
                            {isEdit.prefferedDayTime.edit
                              ? "Select"
                              : "Selected"}{" "}
                            Day{" "}
                            {!isEdit.prefferedDayTime.edit &&
                              `: ${isEdit.prefferedDayTime.data.day}`}
                          </>
                          {/* {isEdit.prefferedDayTime.edit ? (
                            <div className="d-flex justify-content-start align-items-center gap-2">
                              <Button
                                variant="success"
                                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                type="submit"
                                disabled={
                                  editedMsgConfigs.prefferedDayTime ===
                                  isEdit.prefferedDayTime.data
                                }
                                onClick={() =>
                                  saveEditConfigureMsgDistribution(
                                    "prefferedDayTime"
                                  )
                                }
                              >
                                {findIcon("Check", "outline", "18")}
                              </Button>
                              <Button
                                variant="danger"
                                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                type="submit"
                                onClick={() =>
                                  editConfigureMsgDistributionHandler(
                                    "prefferedDayTime"
                                  )
                                }
                              >
                                {findIcon("Close-2", "outline", "14")}
                              </Button>
                            </div>
                          ) : (
                            <span
                              className="cursor-pointer d-inline-flex text-secondary"
                              onClick={() =>
                                editConfigureMsgDistributionHandler(
                                  "prefferedDayTime"
                                )
                              }
                            >
                              {findIcon("Pencil", "dual-tone", "20")}
                            </span>
                          )} */}
                        </Form.Label>
                        {isEdit.prefferedDayTime.edit && (
                          <Form.Select
                            // type="datetime-local"
                            placeholder="Please Select Day"
                            name="date_time"
                            value={editedMsgConfigs.prefferedDayTime.day}
                            onChange={(e) =>
                              getConfigureMsgDistributionData(
                                e,
                                "prefferedDayTime",
                                "day"
                              )
                            }
                          >
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                          </Form.Select>
                        )}
                      </Form.Group>
                      <Form.Group
                        className="mb-3 flex-1"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="d-flex gap-2">
                          <>
                            {isEdit.prefferedDayTime.edit
                              ? "Select"
                              : "Selected"}{" "}
                            Time{" "}
                            {!isEdit.prefferedDayTime.edit &&
                              `: ${isEdit.prefferedDayTime.data.time}`}
                          </>
                          {!isEdit.prefferedDayTime.edit && (
                            <span
                              className="cursor-pointer d-inline-flex text-secondary"
                              onClick={() =>
                                editConfigureMsgDistributionHandler(
                                  "prefferedDayTime"
                                )
                              }
                            >
                              {findIcon("Pencil", "dual-tone", "20")}
                            </span>
                          )}
                        </Form.Label>
                        {isEdit.prefferedDayTime.edit && (
                          <Form.Control
                            type="time"
                            placeholder="Select Date and Time"
                            name="date_time"
                            value={editedMsgConfigs.prefferedDayTime.time}
                            onChange={(e) =>
                              getConfigureMsgDistributionData(
                                e,
                                "prefferedDayTime",
                                "time"
                              )
                            }
                          />
                        )}
                      </Form.Group>
                    </div>
                  )}
                  {msgDistributionType === "Preferred Time" && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="d-flex justify-content-between align-items-center">
                          <>
                            {isEdit.prefferedTime.edit ? "Select" : "Selected"}{" "}
                            Time{" "}
                            {!isEdit.prefferedTime.edit &&
                              `: ${isEdit.prefferedTime.data}`}
                          </>
                          {isEdit.prefferedTime.edit ? (
                            <div className="d-flex justify-content-start align-items-center gap-2">
                              <Button
                                variant="success"
                                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                type="submit"
                                disabled={
                                  editedMsgConfigs.prefferedTime ===
                                  isEdit.prefferedTime.data
                                }
                                onClick={() =>
                                  saveEditConfigureMsgDistribution(
                                    "prefferedTime"
                                  )
                                }
                              >
                                {findIcon("Check", "outline", "18")}
                              </Button>
                              <Button
                                variant="danger"
                                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                                type="submit"
                                onClick={() =>
                                  editConfigureMsgDistributionHandler(
                                    "prefferedTime"
                                  )
                                }
                              >
                                {findIcon("Close-2", "outline", "14")}
                              </Button>
                            </div>
                          ) : (
                            <span
                              className="cursor-pointer d-inline-flex text-secondary"
                              onClick={() =>
                                editConfigureMsgDistributionHandler(
                                  "prefferedTime"
                                )
                              }
                            >
                              {findIcon("Pencil", "dual-tone", "20")}
                            </span>
                          )}
                        </Form.Label>
                        {isEdit.prefferedTime.edit && (
                          <Form.Control
                            type="time"
                            placeholder="Select Date and Time"
                            name="date_time"
                            value={editedMsgConfigs.prefferedTime}
                            onChange={(e) =>
                              getConfigureMsgDistributionData(
                                e,
                                "prefferedTime"
                              )
                            }
                          />
                        )}
                      </Form.Group>
                    </>
                  )}
                  <div>
                    <Form.Floating className="custom-form-floating position-relative d-flex flex-column">
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                      ></textarea>
                      <Form.Label htmlFor="floatingInput">Comment</Form.Label>
                    </Form.Floating>
                  </div>
                  <div className="d-flex gap-3 mt-3 w-50 ms-auto">
                    <Button
                      variant="danger"
                      className="flex-fill d-flex align-items-center justify-content-center fw-semibold rounded-3 px-4 py-2"
                      type="submit"
                    >
                      <span className="">Approve</span>
                    </Button>
                    <Button
                      variant="soft-secondary"
                      className="flex-fill d-flex align-items-center justify-content-center fw-normal rounded-3 px-4 py-2 text-secondary"
                      type="submit"
                    >
                      <span className="">Reject</span>
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OnboardingApprovalDetails;
