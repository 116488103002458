export const fileTypes = [
  {
    file_type_id: 7,
    file_type_name: "HTML",
    file_content_type: "html",
    file_type_code: "html",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/html.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
  {
    file_type_id: 6,
    file_type_name: "PDF",
    file_content_type: "pdf",
    file_type_code: "pdf",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
  {
    file_type_id: 5,
    file_type_name: "Image",
    file_content_type: "image",
    file_type_code: "image",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/image.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
  {
    file_type_id: 4,
    file_type_name: "Zip",
    file_content_type: "zip",
    file_type_code: "zip",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/zip.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
  {
    file_type_id: 3,
    file_type_name: "Video",
    file_content_type: "audio_video",
    file_type_code: "video",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/video.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
  {
    file_type_id: 2,
    file_type_name: "Article",
    file_content_type: "article",
    file_type_code: "article",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
  {
    file_type_id: 1,
    file_type_name: "Audio",
    file_content_type: "audio_video",
    file_type_code: "audio",
    file_type_icon:
      "https://storage.googleapis.com/cipla-cms-dev/icons/file_type/audio.svg",
    status: {
      id: 1,
      value: "Enabled",
    },
    created_by: {
      id: 1,
      name: "Super Admin",
    },
  },
];

export const findIconByFileCode = (code) => {
  return fileTypes?.find((fileType) => fileType.file_type_code === code)
    .file_type_icon;
};
