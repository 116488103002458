import grapesjs from "grapesjs";
import basicBlocks from "grapesjs-blocks-basic";
import pluginCountdown from "grapesjs-component-countdown";
import gjsForms from "grapesjs-plugin-forms";
import "grapesjs/dist/css/grapes.min.css";
import { useEffect } from "react";
// import gjsTabs from "grapesjs-tabs";
import customCodePlugin from "grapesjs-custom-code";
// import grapesjsTouch from "grapesjs-touch";
import webpagePreset from "grapesjs-preset-webpage";
import gjsBGStyle from "grapesjs-style-bg";
import pluginTooltip from "grapesjs-tooltip";
import imagePlugin from "grapesjs-tui-image-editor";
import gjsTyped from "grapesjs-typed";
// import "./css/grapejs.scss";

function GrapejsEditor({
  ref,
  presetData,
  mergeTags,
  setEditor,
  editor,
  previewMode = false,
}) {
  useEffect(() => {
    var plp = "https://via.placeholder.com/350x250/";
    var images = [
      plp + "78c5d6/fff",
      plp + "459ba8/fff",
      plp + "79c267/fff",
      plp + "c5d647/fff",
      plp + "f28c33/fff",
      plp + "e868a2/fff",
      plp + "cc4360/fff",
    ];

    var editor = grapesjs.init({
      // components:
      //   presetData !== null && typeof presetData == "string"
      //     ? JSON.parse(presetData).html
      //     : typeof presetData == "object"
      //     ? presetData
      //     : null,
      // style:
      //   presetData !== null && typeof presetData == "string"
      //     ? JSON.parse(presetData).css
      //     : typeof presetData == "object"
      //     ? presetData
      //     : null,

      components:
        presetData !== null && typeof presetData == "object"
          ? JSON.parse(presetData).html
          : typeof presetData == "string"
          ? presetData
          : null,
      style:
        presetData !== null && typeof presetData == "object"
          ? JSON.parse(presetData).css
          : typeof presetData == "string"
          ? presetData
          : null,

      container: "#gjs",
      showOffsets: true,
      assetManager: {
        embedAsBase64: true,
        assets: images,
      },
      selectorManager: { componentFirst: true },
      styleManager: {
        sectors: [
          {
            name: "General",
            properties: [
              {
                extend: "float",
                type: "radio",
                default: "none",
                options: [
                  { value: "none", className: "fa fa-times" },
                  { value: "left", className: "fa fa-align-left" },
                  { value: "right", className: "fa fa-align-right" },
                ],
              },
              "display",
              { extend: "position", type: "select" },
              "top",
              "right",
              "left",
              "bottom",
            ],
          },
          {
            name: "Dimension",
            open: false,
            properties: [
              "width",
              {
                id: "flex-width",
                type: "integer",
                name: "Width",
                units: ["px", "%"],
                property: "flex-basis",
                toRequire: 1,
              },
              "height",
              "max-width",
              "min-height",
              "margin",
              "padding",
            ],
          },
          {
            name: "Typography",
            open: false,
            properties: [
              "font-family",
              "font-size",
              "font-weight",
              "letter-spacing",
              "color",
              "line-height",
              {
                extend: "text-align",
                options: [
                  { id: "left", label: "Left", className: "fa fa-align-left" },
                  {
                    id: "center",
                    label: "Center",
                    className: "fa fa-align-center",
                  },
                  {
                    id: "right",
                    label: "Right",
                    className: "fa fa-align-right",
                  },
                  {
                    id: "justify",
                    label: "Justify",
                    className: "fa fa-align-justify",
                  },
                ],
              },
              {
                property: "text-decoration",
                type: "radio",
                default: "none",
                options: [
                  { id: "none", label: "None", className: "fa fa-times" },
                  {
                    id: "underline",
                    label: "underline",
                    className: "fa fa-underline",
                  },
                  {
                    id: "line-through",
                    label: "Line-through",
                    className: "fa fa-strikethrough",
                  },
                ],
              },
              "text-shadow",
            ],
          },
          {
            name: "Decorations",
            open: false,
            properties: [
              "opacity",
              "border-radius",
              "border",
              "box-shadow",
              "background", // { id: 'background-bg', property: 'background', type: 'bg' }
            ],
          },
          {
            name: "Extra",
            open: false,
            buildProps: ["transition", "perspective", "transform"],
          },
          {
            name: "Flex",
            open: false,
            properties: [
              {
                name: "Flex Container",
                property: "display",
                type: "select",
                defaults: "block",
                list: [
                  { value: "block", name: "Disable" },
                  { value: "flex", name: "Enable" },
                ],
              },
              {
                name: "Flex Parent",
                property: "label-parent-flex",
                type: "integer",
              },
              {
                name: "Direction",
                property: "flex-direction",
                type: "radio",
                defaults: "row",
                list: [
                  {
                    value: "row",
                    name: "Row",
                    className: "icons-flex icon-dir-row",
                    title: "Row",
                  },
                  {
                    value: "row-reverse",
                    name: "Row reverse",
                    className: "icons-flex icon-dir-row-rev",
                    title: "Row reverse",
                  },
                  {
                    value: "column",
                    name: "Column",
                    title: "Column",
                    className: "icons-flex icon-dir-col",
                  },
                  {
                    value: "column-reverse",
                    name: "Column reverse",
                    title: "Column reverse",
                    className: "icons-flex icon-dir-col-rev",
                  },
                ],
              },
              {
                name: "Justify",
                property: "justify-content",
                type: "radio",
                defaults: "flex-start",
                list: [
                  {
                    value: "flex-start",
                    className: "icons-flex icon-just-start",
                    title: "Start",
                  },
                  {
                    value: "flex-end",
                    title: "End",
                    className: "icons-flex icon-just-end",
                  },
                  {
                    value: "space-between",
                    title: "Space between",
                    className: "icons-flex icon-just-sp-bet",
                  },
                  {
                    value: "space-around",
                    title: "Space around",
                    className: "icons-flex icon-just-sp-ar",
                  },
                  {
                    value: "center",
                    title: "Center",
                    className: "icons-flex icon-just-sp-cent",
                  },
                ],
              },
              {
                name: "Align",
                property: "align-items",
                type: "radio",
                defaults: "center",
                list: [
                  {
                    value: "flex-start",
                    title: "Start",
                    className: "icons-flex icon-al-start",
                  },
                  {
                    value: "flex-end",
                    title: "End",
                    className: "icons-flex icon-al-end",
                  },
                  {
                    value: "stretch",
                    title: "Stretch",
                    className: "icons-flex icon-al-str",
                  },
                  {
                    value: "center",
                    title: "Center",
                    className: "icons-flex icon-al-center",
                  },
                ],
              },
              {
                name: "Flex Children",
                property: "label-parent-flex",
                type: "integer",
              },
              {
                name: "Order",
                property: "order",
                type: "integer",
                defaults: 0,
                min: 0,
              },
              {
                name: "Flex",
                property: "flex",
                type: "composite",
                properties: [
                  {
                    name: "Grow",
                    property: "flex-grow",
                    type: "integer",
                    defaults: 0,
                    min: 0,
                  },
                  {
                    name: "Shrink",
                    property: "flex-shrink",
                    type: "integer",
                    defaults: 0,
                    min: 0,
                  },
                  {
                    name: "Basis",
                    property: "flex-basis",
                    type: "integer",
                    units: ["px", "%", ""],
                    unit: "",
                    defaults: "auto",
                  },
                ],
              },
              {
                name: "Align",
                property: "align-self",
                type: "radio",
                defaults: "auto",
                list: [
                  {
                    value: "auto",
                    name: "Auto",
                  },
                  {
                    value: "flex-start",
                    title: "Start",
                    className: "icons-flex icon-al-start",
                  },
                  {
                    value: "flex-end",
                    title: "End",
                    className: "icons-flex icon-al-end",
                  },
                  {
                    value: "stretch",
                    title: "Stretch",
                    className: "icons-flex icon-al-str",
                  },
                  {
                    value: "center",
                    title: "Center",
                    className: "icons-flex icon-al-center",
                  },
                ],
              },
            ],
          },
        ],
      },
      plugins: [
        basicBlocks,
        gjsForms,
        pluginCountdown,
        // gjsTabs,
        customCodePlugin,
        // grapesjsTouch,
        // parserPostCSS,
        pluginTooltip,
        imagePlugin,
        gjsTyped,
        gjsBGStyle,
        webpagePreset,
      ],
      pluginsOpts: {
        "gjs-blocks-basic": { flexGrid: true },
        "grapesjs-tui-image-editor": {
          script: [
            // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
            "https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js",
            "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js",
            "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js",
          ],
          style: [
            "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css",
            "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css",
          ],
        },
        "grapesjs-tabs": {
          tabsBlock: { category: "Extra" },
        },
        "grapesjs-typed": {
          block: {
            category: "Extra",
            content: {
              type: "typed",
              "type-speed": 40,
              strings: ["Text row one", "Text row two", "Text row three"],
            },
          },
        },
        "grapesjs-preset-webpage": {
          modalImportTitle: "Import Template",
          modalImportLabel:
            '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
          modalImportContent: function (editor) {
            return editor.getHtml() + "<style>" + editor.getCss() + "</style>";
          },
        },
      },
    });
    setEditor(editor);
    var pn = editor.Panels;
    var modal = editor.Modal;
    var cmdm = editor.Commands;

    // Update canvas-clear command
    cmdm.add("canvas-clear", function () {
      editor.runCommand("core:canvas-clear");
      localStorage.removeItem("gjsProject");
    });

    // Add info command
    var mdlClass = "gjs-mdl-dialog-sm";
    var infoContainer = document.getElementById("info-panel");

    cmdm.add("open-info", function () {
      var mdlDialog = document.querySelector(".gjs-mdl-dialog");
      mdlDialog.className += " " + mdlClass;
      infoContainer.style.display = "block";
      modal.setTitle("About this demo");
      modal.setContent(infoContainer);
      modal.open();
      modal.getModel().once("change:open", function () {
        mdlDialog.className = mdlDialog.className.replace(mdlClass, "");
      });
    });

    pn.addButton("options", {
      id: "open-info",
      className: "fa fa-question-circle",
      command: function () {
        editor.runCommand("open-info");
      },
      attributes: {
        title: "About",
        "data-tooltip-pos": "bottom",
      },
    });

    // Add and beautify tooltips
    [
      ["sw-visibility", "Show Borders"],
      ["preview", "Preview"],
      ["fullscreen", "Fullscreen"],
      ["export-template", "Export"],
      ["undo", "Undo"],
      ["redo", "Redo"],
      ["gjs-open-import-webpage", "Import"],
      ["canvas-clear", "Clear canvas"],
    ].forEach(function (item) {
      pn.getButton("options", item[0])?.set("attributes", {
        title: item[1],
        "data-tooltip-pos": "bottom",
      });
    });
    [
      ["open-sm", "Style Manager"],
      ["open-layers", "Layers"],
      ["open-blocks", "Blocks"],
    ].forEach(function (item) {
      pn.getButton("views", item[0]).set("attributes", {
        title: item[1],
        "data-tooltip-pos": "bottom",
      });
    });
    var titles = document.querySelectorAll("*[title]");

    for (var i = 0; i < titles.length; i++) {
      var el = titles[i];
      var title = el.getAttribute("title");
      title = title ? title.trim() : "";
      if (!title) break;
      el.setAttribute("data-tooltip", title);
      el.setAttribute("title", "");
    }

    // Store and load events
    editor.on("storage:load", function (e) {
      console.log("Loaded ", e);
    });
    editor.on("storage:store", function (e) {
      console.log("Stored ", e);
    });

    // // Do stuff on load
    editor.on("load", function () {
      var $ = grapesjs.$;
      console.log("pn", pn.getButton("options", "sw-visibility"));

      // Load and show settings and style manager
      var openTmBtn = pn.getButton("views", "open-tm");
      openTmBtn && openTmBtn.set("active", 1);
      var openSm = pn.getButton("views", "open-sm");
      openSm && openSm.set("active", 1);

      // Remove trait view
      pn.removeButton("views", "open-tm");

      // Add Settings Sector
      var traitsSector_more = $(
        '<div class="gjs-sm-sector no-select">' +
          '<div class="gjs-sm-title"><span class="icon-settings fa fa-cog"></span> Structure</div>' +
          '<div class="gjs-sm-properties" style="display: none;"></div></div>'
      );
      var traitsProps_more = traitsSector_more.find(".gjs-sm-properties");

      console.log("traitsProps_more", traitsProps_more);
      try {
        var traitsSector = $(
          '<div class="gjs-sm-sector no-select">' +
            '<div class="gjs-sm-sector-title"><span class="icon-settings fa fa-cog"></span> <span class="gjs-sm-sector-label">Settings</span></div>' +
            '<div class="gjs-sm-properties" style="display: none;"></div></div>'
        );
        var traitsProps = traitsSector.find(".gjs-sm-properties");
        traitsProps.append($(".gjs-trt-traits"));
        $(".gjs-sm-sectors").before(traitsSector);
        traitsSector.find(".gjs-sm-sector-title").on("click", function () {
          var traitStyle = traitsProps.get(0).style;
          var hidden = traitStyle.display == "none";
          if (hidden) {
            traitStyle.display = "block";
          } else {
            traitStyle.display = "none";
          }
        });

        var openBlocksBtn = editor.Panels.getButton("views", "open-blocks");
        openBlocksBtn && openBlocksBtn.set("active", 1);
      } catch (erro) {
        console.log("erro", erro);
      }
    });

    editor.on("run:preview", () => {
      // Execute a callback on all inner components starting from the root
      // alert("hihi preview");
      editor.DomComponents.getWrapper().onAll(
        (comp) => comp.is("text") && comp.set({ editable: false })
      );
    });

    onLoad();
    return () => {
      localStorage.removeItem("gjsProject");
    };
  }, []);

  useEffect(() => {
    if (editor !== null) {
      if (previewMode) {
        editor.runCommand("preview");
        editor.stopCommand("sw-visibility");
      } else {
        editor.stopCommand("preview");
      }
    }
  }, [previewMode, editor]);

  useEffect(() => {
    if (editor !== null) {
      editor.DomComponents.addType("var-placeholder", {
        model: {
          defaults: {
            textable: 1,
            placeholder: "huiiiii",
          },
          toHTML() {
            return `{{ ${this.get("placeholder")} }}`;
          },
        },
        // The view below it's just an example of creating a different UX
        view: {
          tagName: "span",
          events: {
            change: "updatePlh",
          },
          // Update the model once the select is changed
          updatePlh(ev) {
            this.model.set({ placeholder: ev.target.value });
            this.updateProps();
          },
          // When we blur from a TextComponent, all its children components are
          // flattened via innerHTML and parsed by the editor. So to keep the state
          // of our props in sync with the model so we need to expose props in the HTML
          updateProps() {
            const { el, model } = this;
            el.setAttribute("data-gjs-placeholder", model.get("placeholder"));
          },
          onRender() {
            const { model, el } = this;
            const currentPlh = model.get("placeholder");
            const select = document.createElement("select");
            select.innerHTML = getMergeTagsUI(currentPlh);
            while (el.firstChild) el.removeChild(el.firstChild);
            el.appendChild(select);
            select.setAttribute(
              "style",
              "padding: 5px; border-radius: 3px; border: none; -webkit-appearance: none;"
            );
            this.updateProps();
          },
        },
      });

      // Use the component in blocks
      editor.BlockManager.add("simple-block", {
        label: "Variable",
        content: { type: "var-placeholder" },
      });
    }
  }, [mergeTags, editor]);

  function getMergeTagsUI(currentPlh) {
    console.log("mergeTagsm", mergeTags);
    return mergeTags
      .map((item) => {
        if (typeof item === "object" && item !== null && item.tags.length > 0) {
          return `<optgroup label=${item.name}>
				${item.tags.map((data) => {
          return `<option value="${data.variable_name}" ${
            data === data.variable_name ? "selected" : ""
          }>
					${data.variable_name}
					</option>`;
        })}
				</optgroup>`;
        } else {
          return `<option value="${item.variable_name}" ${
            item.variable_name === currentPlh ? "selected" : ""
          }>
				${item.variable_name}
				</option>`;
        }
      })
      .join("");
  }

  const onLoad = (data) => {
    if (editor !== null && presetData !== null) {
      editor.setComponents(JSON.parse(presetData).html);
      editor.setStyle(JSON.parse(presetData).css);
    }
  };

  useEffect(() => {
    onLoad();
  }, [presetData]);

  return (
    <>
      <div
        id="gjs"
        style={{ height: "0px", overflow: "hidden" }}
        ref={ref}
      ></div>
    </>
  );
}

export default GrapejsEditor;
