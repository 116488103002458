import * as yup from "yup";

const addVideoInitialValues = {
	title: "",
	title_html: "",
	description: "",
	description_html: "",
	references: "",
	references_html: "",
	referenceFile: "",
	notes: "",
	file: null,
	coverImage: null,
	thumbnail: null,
	speciality: "",
	category: "",
	tags: "",
	brand: "",
};

const addVideoSchema = yup.object().shape({
	title: yup.string().required("Please Enter a title"),
	description: yup.string().required("Please Enter a description"),
	references: yup.string().required("Please Enter a references"),

	file: yup.mixed().required("Please add a video"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	coverImage: yup.mixed().required("Please add a Cover Image"),
	brand: yup.string().required("Please select a brand"),
});

const addImageInitialValues = {
	title: "",
	title_html: "",
	description_html: "",
	references_html: "",
	description: "",
	references: "",
	referenceFile: "",
	notes: "",
	file: null,
	coverImage: null,
	thumbnail: null,
	speciality: "",
	category: "",
	tags: "",
	brand: "",
};

const addImageSchema = yup.object().shape({
	title: yup.string().required("Please Enter a title"),
	description: yup.string().required("Please Enter a description"),
	references: yup.string().required("Please Enter a references"),
	file: yup.mixed().required("Please add a video"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	coverImage: yup.mixed().required("Please add a Cover Image"),
	brand: yup.string().required("Please select a brand"),
});

const addAudioInitialValues = {
	title: "",
	title_html: "",
	description_html: "",
	references_html: "",
	description: "",
	references: "",
	referenceFile: "",
	notes: "",
	file: null,
	coverImage: null,
	thumbnail: null,
	speciality: "",
	category: "",
	tags: "",
	brand: "",
};

const addAudioSchema = yup.object().shape({
	title: yup.string().required("Please Enter a title"),
	description: yup.string().required("Please Enter a description"),
	references: yup.string().required("Please Enter a references"),
	file: yup.mixed().required("Please add a video"),
	brand: yup.string().required("Please select a brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	coverImage: yup.mixed().required("Please add a Cover Image"),
});

export {
	addVideoInitialValues,
	addVideoSchema,
	addImageSchema,
	addImageInitialValues,
	addAudioInitialValues,
	addAudioSchema,
};
