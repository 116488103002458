import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../dashboard/icons";

// import ChannelCard from "./channel/components/card/ChannelCard";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import ReactTable from "../../../../lib/table/react-table";
import { useGetProjectList } from "../../../../queries/query-hooks/workspace/workspace-hook";
import PageNotFound from "../../../PageNotFound";
import ChannelListSkeleton from "./../../../../skeleton/workspace/doctube/channel/channel-list-skeleton";
import ProjectCard from "./channel/components/card/ProjectCard";
import {
  getFormatedDate,
  getRelativeDate,
} from "../../../../common/utils/utils";
import { useSelector } from "react-redux";
import * as authSelectors from "../../../../store/auth/selectors";

export default function Workspacelist({ id }) {
  const user = useSelector(authSelectors.user);
  const { data: channelData, isLoading: isChannelLoading } =
    useGetProjectList(id);
  // const { data: channelData, isLoading: isChannelLoading } = useGetProjectList();
  const [isListView, setIsListView] = useState(false);
  const { data: channels } = channelData || { data: [] };
  const { redirectTo } = useRedirect();

  useEffect(() => {
    // redirectTo("project/channels")
  }, []);
  const channelColumns = [
    {
      Header: "Content ID",
      accessor: "content_id",
      columnId: 1,
    },
    {
      Header: "Channel Name",
      accessor: "channel_name",
      columnId: 2,
    },
    {
      Header: "Project Type",
      accessor: "project_type_name",
      columnId: 3,
    },
    {
      Header: "Subscriber Count",
      accessor: "subscriber_count",
      columnId: 4,
    },
    {
      columnId: 5,
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row, value }) =>
        row?.content_id == 0 ? (
          <></>
        ) : (
          <ActionButtonBar
            buttons={["select", "edit"]}
            row={row}
            onSelect={(data) => {
              redirectTo(`channel/dashboard/${data?.content_id}`);
            }}
            onEdit={(data) => {
              redirectTo(`workspace/project-details/${data?.content_id}`);
            }}
          />
        ),
    },
  ];

  const projectColumns = [
    {
      Header: "Project Logo",
      accessor: "project_logo",
      columnId: 1,
      disableFilters: true,
      Cell: ({ value, row }) => {
        return (
          <img
            className="rounded-3 border border-1 icon-60"
            src={row.original?.project_logo}
          />
        );
      },
    },
    {
      Header: "Project Name",
      accessor: "project_name",
      columnId: 2,
      disableFilters: true,
    },
    {
      Header: "Project Type",
      accessor: "project_type_info",
      columnId: 3,
      disableFilters: true,
      Cell: ({ value }) => {
        return value[0]?.project_type_name;
      },
    },
    {
      Header: "Updated on",
      columnId: 4,
      disableFilters: true,
      Cell: ({ row }) => {
        return getFormatedDate(row.original?.updated_at);
      },
    },
    {
      columnId: 5,
      Header: "Action",
      accessor: "id",
      disableFilters: true,
      Cell: ({ row, value }) => (
        <ActionButtonBar
          buttons={["select"]}
          row={row}
          onSelect={(data) => {
            redirectTo(`brand-ads/summary/${data?.id}`);
          }}
        />
      ),
    },
  ];

  const isUserHasProjectCreatePermission = user?.user_has_permissions.some(
    (item) => item?.name === "create_project"
  );

  return (
    <>
      {!isChannelLoading && channels?.length > 0 ? (
        <>
          <Row>
            <div className="d-flex justify-content-between align-items-center pb-4">
              <h4 className="m-0">All Projects</h4>
              <div className="d-flex gap-3 align-items-center">
                {isUserHasProjectCreatePermission ? (
                  <Button
                    variant="outline-primary rounded-5"
                    size="md"
                    onClick={() => redirectTo("workspace/create-project")}
                  >
                    Create Project
                  </Button>
                ) : null}

                {/* <Button
                  variant="outline-primary rounded-5"
                  size="md"
                  onClick={() => {
                    redirectTo("project/create-channel");
                  }}
                >
                  Create Channel
                </Button> */}
                <ul
                  className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center flex-nowrap"
                  id="pills-tab"
                  role="tablist"
                >
                  <li
                    className="nav-item ms-2"
                    role="presentation"
                    onClick={() => setIsListView(false)}
                  >
                    <Button
                      bsPrefix={
                        "nav-link btn-sm btn-icon rounded-pill" +
                        (isListView ? "" : " active")
                      }
                      id="grid-view-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-grid-view"
                      to="#"
                      role="tab"
                      aria-controls="pills-grid-view"
                      aria-selected="true"
                    >
                      <span className="btn-inner d-inline-flex">
                        {findIcon("View Grid", "dual-tone")}
                      </span>
                    </Button>
                  </li>
                  <li
                    className="nav-item ms-2"
                    role="presentation"
                    onClick={() => setIsListView(true)}
                  >
                    <Button
                      bsPrefix={
                        "nav-link btn-sm btn-icon rounded-pill" +
                        (isListView ? " active" : "")
                      }
                      id="list-view-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-list-view"
                      to="#"
                      role="tab"
                      aria-controls="pills-list-view"
                      aria-selected="false"
                    >
                      <span className="btn-inner d-inline-flex">
                        {findIcon("Rows", "dual-tone")}
                      </span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </Row>
          <Row className="mx-md-n2 mt-n3">
            {channels?.length > 0 && isListView && (
              <Card>
                <Card.Body>
                  {id == 1 && (
                    <ReactTable
                      data={channels}
                      columns={channelColumns}
                      isPaginationShow={false}
                    />
                  )}

                  {id == 2 && (
                    <ReactTable
                      data={channels}
                      columns={projectColumns}
                      isPaginationShow={false}
                    />
                  )}
                </Card.Body>
              </Card>
            )}

            {channels?.length > 0 &&
              !isListView &&
              channels.map((data, key) => (
                <Col md={4} lg={4} key={key}>
                  <ProjectCard
                    project_id={data?.id}
                    title={data?.project_name}
                    projectType={data?.project_type_info[0]?.project_type_name}
                    projectTypeCode={
                      data?.project_type_info[0]?.project_type_code
                    }
                    publishDate={data?.updated_at}
                    endDate={data?.project_end_date}
                    channel_logo={data?.project_logo}
                    tags={data?.project_tags}
                    projectTypeId={data?.project_type_id}
                  ></ProjectCard>
                </Col>
              ))}
          </Row>
        </>
      ) : isChannelLoading ? (
        <ChannelListSkeleton />
      ) : (
        <PageNotFound
          title="You dont have any channel yet"
          backgroundImage={findIcon("Support-ticket", "dual-tone", 100)}
          message="please contact support team"
          buttonText="Raise support ticket"
          onFallbackClick={() => {}}
        />
      )}
    </>
  );
}
