import React, { memo }  from "react";
import Skeleton from "../../../../components/skeleton";

function ChannelStatisticsSkeleton() {
  return (
    <div className="card p-3 pt-4 m-0 h-100">
      <div className="mb-4">
        <Skeleton
          variant="text"
          width={"50%"}
          height={"100%"}
          className="mb-2 rounded-3"
        />
      </div>
      <div className="card-body d-flex align-items-center p-0 justify-content-between">
        <Skeleton
          variant="square"
          width={"50%"}
          height={"100%"}
          className="me-2 rounded-3"
        />

        <Skeleton
          variant="square"
          width={"50%"}
          height={"100%"}
          className="me-2 rounded-3"
        />
      </div>
    </div>
  );
}

export default memo(ChannelStatisticsSkeleton);
