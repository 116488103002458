import React, { useState, Fragment, useEffect, useRef, memo } from "react";
import { Badge, Button, Col, Nav, Row, Tab } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import CreateSegmentModal from "../components/create-segment-modal";
import Chart from "react-apexcharts";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import {
  SEGMENT_RECOMMENTDED_GET,
  SEGMENT_REPORT_GET,
} from "../../../../api/endpoints/campaign-segment-management-endpoints";
import Skeleton from "../../../../components/skeleton";
import RecommendedSegmentCard from "../components/RecommendedSegmentCard";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
const segmentInsightChartOptions = {
  series: [],
  options: {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#77B6EA", "#545454"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: ".",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  },
};
const SegmentDashboard = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [segementInsight, setSegmentInsight] = useState(
    segmentInsightChartOptions
  );

  const [recommendedSegments, setRecommendedSegments] = useState([]);
  const [recommendedLoader, setRecommendedLoader] = useState([]);
  const [segmentReportLoader, setSegmentReportLoader] = useState(false);

  const { redirectTo } = useRedirect();
  const post_json_list = useRef({
    type: "frequently_used",
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const modalClose = () => setCreateModalShow(false);
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const recomended_segment_demo_data = [
    {
      segment_name: " Doctors in Mumbai",
      total_reach: "380",
      Preffer_email: "105",
      Preffer_whatsapp: "112",
      Preffer_SMS: "132",
      created_by: { name: "Indranil Kundu" },
      created_on: { date: "01-06-23", time: "21:10" },
      modified_on: { date: "01-06-23", time: "21:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Dermatology Doctors in Delhi",
      total_reach: "470",
      Preffer_email: "155",
      Preffer_whatsapp: "119",
      Preffer_SMS: "156",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "01-06-23", time: "22:20" },
      modified_on: { date: "01-06-23", time: "22:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Bangalore",
      total_reach: "550",
      Preffer_email: "255",
      Preffer_whatsapp: "110",
      Preffer_SMS: "106",
      created_by: { name: "Swagata Das" },
      created_on: { date: "02-06-23", time: "12:20" },
      modified_on: { date: "02-06-23", time: "12:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "ENT Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "02-06-23", time: "13:00" },
      modified_on: { date: "02-06-23", time: "13:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: " Doctors in Ranchi",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "03-06-23", time: "16:20" },
      modified_on: { date: "03-06-23", time: "16:40" },
      status: { id: 1, value: "Draft" },
    },
  ];
  const largest_segment_demo_data = [
    {
      segment_name: "Cardiology Doctors in Mumbai",
      total_reach: "380",
      Preffer_email: "105",
      Preffer_whatsapp: "112",
      Preffer_SMS: "132",
      created_by: { name: "Indranil Kundu" },
      created_on: { date: "03-06-23", time: "17:05" },
      modified_on: { date: "03-06-23", time: "17:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Delhi",
      total_reach: "470",
      Preffer_email: "155",
      Preffer_whatsapp: "119",
      Preffer_SMS: "156",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "03-06-23", time: "18:20" },
      modified_on: { date: "03-06-23", time: "18:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Nephrology Doctors in Kolkata",
      total_reach: "550",
      Preffer_email: "255",
      Preffer_whatsapp: "110",
      Preffer_SMS: "106",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "03-06-23", time: "22:20" },
      modified_on: { date: "03-06-23", time: "22:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Oncology Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "05-06-23", time: "09:20" },
      modified_on: { date: "05-06-23", time: "09:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Segment B2",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "05-06-23", time: "10:20" },
      modified_on: { date: "05-06-23", time: "10:40" },
      status: { id: 1, value: "Draft" },
    },
  ];
  const recently_used_segment_data = [
    {
      segment_name: "ENT Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "02-06-23", time: "13:00" },
      modified_on: { date: "02-06-23", time: "13:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: " Doctors in Ranchi",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "03-06-23", time: "16:20" },
      modified_on: { date: "03-06-23", time: "16:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "ENT Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "02-06-23", time: "13:00" },
      modified_on: { date: "02-06-23", time: "13:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: " Doctors in Ranchi",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "03-06-23", time: "16:20" },
      modified_on: { date: "03-06-23", time: "16:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Gyno Doctors in Kolkata",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "05-06-23", time: "10:20" },
      modified_on: { date: "05-06-23", time: "10:40" },
      status: { id: 1, value: "Draft" },
    },
  ];

  const columns = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },

    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        return value.name;
      },
    },

    {
      Header: "Used on",
      accessor: "modified_on",
      disableFilters: true,
      Cell: ({ value }) => (
        <p className="m-0">
          {value.date} {value.time}
        </p>
      ),
    },
  ];
  const columns_largest = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        return value.name;
      },
    },
    {
      Header: "Reach",
      accessor: "total_reach",
      disableFilters: true,
    },
  ];
  const columns_recently_used = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        // console.log("row");
        return value.name;
      },
    },
    {
      Header: "Created on",
      accessor: "created_on",
      disableFilters: true,
      Cell: ({ value }) => (
        <p className="m-0">
          {value.date} {value.time}
        </p>
      ),
    },
  ];

  const getSegementInsight = async () => {
    setSegmentReportLoader(true);
    let response = await getService(`${SEGMENT_REPORT_GET}`, {});
    if (response) {
      getRecommendedSegement();
      setSegmentReportLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response?.data?.data;
      console.log(SEGMENT_REPORT_GET, ":response:", responseData);
      if (responseData) {
        setSegmentInsight((prevState) => ({
          ...prevState,
          series: responseData,
        }));
      }
    }
  };

  const getRecommendedSegement = async () => {
    setRecommendedLoader(true);
    let response = await postService(
      SEGMENT_RECOMMENTDED_GET,
      post_json_list.current
    );
    if (response) {
      setRecommendedLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response?.data?.data.rows;
      console.log(SEGMENT_RECOMMENTDED_GET, ":response:", responseData);
      if (responseData) {
        setRecommendedSegments(responseData);
      }
    }
  };

  useEffect(() => {
    getSegementInsight();
  }, []);

  const onRecentClick = () => {
    redirectTo("segment/all");
  };

  const onFrequentlyUsedClick = () => {
    redirectTo("segment/all");
  };

  const onLargestClick = () => {
    redirectTo("segment/all");
  };

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => setCreateModalShow(true)}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <Row>
        <Col lg="12">
          <h3 className="mb-3">Segments</h3>
          {!segmentReportLoader ? (
            segementInsight ? (
              <Card>
                <Card.Body>
                  <Chart
                    options={segementInsight.options}
                    series={segementInsight.series}
                    type="line"
                    height={350}
                  />
                </Card.Body>
              </Card>
            ) : (
              <></>
            )
          ) : (
            <Skeleton height={350} width={"100%"} variant="rounded" />
          )}
        </Col>
        <Col lg="12" md="12">
          <Card className="card-block">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav-tabs justify-content-around d-flex"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#frequently-used"
                    type="button"
                    role="tab"
                    aria-controls="frequently-used"
                    aria-selected="true"
                  >
                    Frequently Used Segments
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#largest-segment"
                    type="button"
                    role="tab"
                    aria-controls="largest-segment"
                    aria-selected="false"
                  >
                    5 Largest Segments
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="three"
                    data-bs-toggle="tab"
                    data-bs-target="#recently-created"
                    type="button"
                    role="tab"
                    aria-controls="recently-created"
                    aria-selected="false"
                  >
                    Recently Created Segments
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="frequently-used"
                  role="tabpanel"
                  aria-labelledby="frequently-used"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={recomended_segment_demo_data}
                      columns={columns}
                      recordsTotal={10}
                    />
                    {/* <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        onClick={onFrequentlyUsedClick}
                      >
                        {" "}
                        View More
                      </Button>
                    </div> */}
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="largest-segment"
                  role="tabpanel"
                  aria-labelledby="largest-segment"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={largest_segment_demo_data}
                      columns={columns_largest}
                      recordsTotal={10}
                    />
                    {/* <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        onClick={onLargestClick}
                      >
                        {" "}
                        View More
                      </Button>
                    </div> */}
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="three"
                  id="recently-created"
                  role="tabpanel"
                  aria-labelledby="recently-created"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={recently_used_segment_data}
                      columns={columns_recently_used}
                      recordsTotal={10}
                    />
                    {/* <div className="d-flex justify-content-start mt-4">
                      <Button
                        type="button"
                        variant="outline-info btn-sm rounded-2"
                        onClick={onRecentClick}
                      >
                        {" "}
                        View More
                      </Button>
                    </div> */}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
          {/* <CommonTableSkeleton /> */}
        </Col>

        {!recommendedLoader ? (
          recommendedSegments?.length > 0 ? (
            <>
              <Col lg="12">
                <h5 className="mb-4">Recommended Segments </h5>
              </Col>
              {recommendedSegments.map((item, index) => (
                <Col lg="4">
                  <RecommendedSegmentCard
                    item={item}
                    index={index}
                    name={item?.segment_name}
                    status={item?.status?.value}
                    reach={item?.total_reach}
                    individual_reach={item?.individual_reach}
                    click={() => {
                      alert("onClick");
                    }}
                  />
                </Col>
              ))}
            </>
          ) : (
            <></>
          )
        ) : (
          <Skeleton height={350} width={"100%"} variant="rounded" />
        )}

        {/* <Col lg="12" className="d-flex justify-content-start">
          <Button variant="outline-primary" className="mb-4">
            View More &nbsp;&nbsp;
            {findIcon("Arrow Right", "dual-tone", "20")}
          </Button>
        </Col> */}
      </Row>
      <CreateSegmentModal show={createModalShow} modalClose={modalClose} />
    </Fragment>
  );
};

export default memo(SegmentDashboard);
