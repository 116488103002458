import React from "react";
import { Col, Row } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";

const Poll = ({ poll_data = {} }) => {
  return (
    <Row className="bg-light bg-opacity-25 rounded-3 py-3">
      <Col sm={12} className="d-flex justify-content-between mb-3">
        <div className="bg-success bg-opacity-10 text-success px-2 py-1 rounded-2 fw-semibold">
          {poll_data.point} Points
        </div>
        <div className="d-flex gap-3">
          <div className="text-success cursor-pointer">
            {findIcon("ExportReport", "dual-tone", "20")}
          </div>
          <div className="text-success cursor-pointer">
            {findIcon("Arrow Up", "dual-tone", "20")}
          </div>
        </div>
      </Col>

      <div>
        <h6 className="fw-semibold mb-3">{poll_data.question}</h6>
        <div className="d-flex flex-column gap-2">
          {poll_data.options?.map((option, index) => {
            return (
              <p
                key={`${index}-poll-option`}
                className="bg-white text-dark py-2 px-3 rounded-2"
              >
                {option.label}
              </p>
            );
          })}
        </div>
      </div>
    </Row>
  );
};

export default Poll;
