import { memo, Fragment } from "react";
import { Image } from "react-bootstrap";
import ciplaUnityLogo from "../../../assets/images/client-logo/Unity-Icon.png";

const Logo = memo((props) => {
  return (
    <Fragment>
      <div className="logo-main">
        <div className="logo-normal">
          <Image src={ciplaUnityLogo} alt="Unity" className="icon-24" />

        </div>
        <div className="logo-mini">
          {/* className={`${props.color === true ? "text-primary" : ""} icon-30`} */}
          <svg
            className="icon-30"
            id="Layer_2"
            fill="#195aa7"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1315.25 1341.84"
          >
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                class="cls-1"
                d="m642.34.94c-47.3,2.8-108.8,13.8-156.5,28.1-117.6,35-211.3,90.1-290.6,170.9-28.5,29.1-51.9,57.9-74.9,92.4-16.9,25.2-20.8,31.8-35.5,59-18.4,33.9-31,62.2-46.4,104-13,35.2-20.3,61.3-26.5,94-27.4,144-7.4,289.8,57.4,419.6,20.7,41.4,45,79.5,78.3,122.9,18,23.4,25.1,31.7,41.7,48.8,47,48.5,105,91.3,167.5,123.6,100.9,52,209.8,77.4,332.5,77.6,67.1,0,151-16.4,217.5-42.8,81.2-32.1,147.6-74.6,210.9-135,47-44.8,94.2-106.9,135.3-177.7,25-43.1,45.4-92,54.3-130,2.6-10.8,6.5-33.9,6.5-38.3v-2.2h-370l-2.5,4.9c-3.3,6.6-15.5,26-21.8,34.6-32.4,44.5-70.9,79.1-114.2,102.7-29.3,16-65.6,27.4-98.9,30.9-22.2,2.4-54.5,1.2-81.6-3-6.9-1.1-16.1-2.5-20.5-3.1-21-2.8-46.5-10.2-68.8-19.9-18.1-7.8-28.1-13.4-45.7-25.2-65.9-44.2-110.7-114.9-131.4-206.8-3.7-16.4-4.9-32.3-4.3-56.6,1.4-52.2,11.5-97.9,31.3-142,25.8-57.3,67.6-103.3,124.7-137.4,11.1-6.7,39.2-20.2,47.5-23,14.9-4.9,49.1-11,72.7-13,17.5-1.6,55.7-1.3,71.8.4,65.4,6.9,120,29.6,165.7,69,23.6,20.4,46.7,48.4,64.2,77.7l6.9,11.8h188.4c178.8,0,188.3-.1,187.9-1.8-10.1-45.8-28.1-95.4-52.4-144.7-54.8-110.8-134.7-201-233.6-264.1C937.74,48.84,831.04,13.64,708.94,1.34c-16.4-1.6-43.2-1.8-66.6-.4Z"
              />
            </g>
          </svg>
        </div>
      </div>
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
