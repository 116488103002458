import { memo, Fragment } from "react";

// React-bootstrap
import {
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Image,
  Button,
} from "react-bootstrap";

//Router
import { Link } from "react-router-dom";

//Component
import CustomToggle from "../../../../../components/dropdowns";
import Card from "../../../../../components/bootstrap/card";

//Masonry
import Masonry from "react-masonry-css";

// import sample from "../../../../../lib/email-editor/sample.json";

// import img6 from "../../../../../assets/modules/blog/images/new_template.svg";
import smstemplate from "../../../../../assets/modules/blog/images/abc.jpg";
import TemplateCard from "./component/template-card";
import { isMobile, isMobileOnly } from "react-device-detect";

// const htmlData = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
// <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
// <head>
// <!--[if gte mso 9]>
// <xml>
//   <o:OfficeDocumentSettings>
//     <o:AllowPNG/>
//     <o:PixelsPerInch>96</o:PixelsPerInch>
//   </o:OfficeDocumentSettings>
// </xml>
// <![endif]-->
//   <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
//   <meta name="viewport" content="width=device-width, initial-scale=1.0">
//   <meta name="x-apple-disable-message-reformatting">
//   <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
//   <title></title>

//     <style type="text/css">
//       @media only screen and (min-width: 720px) {
//   .u-row {
//     width: 700px !important;
//   }
//   .u-row .u-col {
//     vertical-align: top;
//   }

//   .u-row .u-col-50 {
//     width: 350px !important;
//   }

//   .u-row .u-col-100 {
//     width: 700px !important;
//   }

// }

// @media (max-width: 720px) {
//   .u-row-container {
//     max-width: 100% !important;
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//   }
//   .u-row .u-col {
//     min-width: 320px !important;
//     max-width: 100% !important;
//     display: block !important;
//   }
//   .u-row {
//     width: 100% !important;
//   }
//   .u-col {
//     width: 100% !important;
//   }
//   .u-col > div {
//     margin: 0 auto;
//   }
// }
// body {
//   margin: 0;
//   padding: 0;
// }

// table,
// tr,
// td {
//   vertical-align: top;
//   border-collapse: collapse;
// }

// p {
//   margin: 0;
// }

// .ie-container table,
// .mso-container table {
//   table-layout: fixed;
// }

// * {
//   line-height: inherit;
// }

// a[x-apple-data-detectors='true'] {
//   color: inherit !important;
//   text-decoration: none !important;
// }

// @media (max-width: 480px) {
//   .hide-mobile {
//     max-height: 0px;
//     overflow: hidden;
//     display: none !important;
//   }
// }

// table, td { color: #ffffff; } #u_body a { color: #0071e3; text-decoration: underline; } #u_content_text_17 a { color: #d2d2d7; text-decoration: none; } @media (max-width: 480px) { #u_content_image_1 .v-src-width { width: auto !important; } #u_content_image_1 .v-src-max-width { max-width: 20% !important; } #u_content_heading_1 .v-font-size { font-size: 45px !important; } #u_content_heading_2 .v-font-size { font-size: 19px !important; } #u_content_text_2 .v-font-size { font-size: 14px !important; } #u_content_button_2 .v-text-align { text-align: center !important; } #u_content_text_3 .v-text-align { text-align: center !important; } #u_content_text_4 .v-font-size { font-size: 18px !important; } #u_content_text_4 .v-line-height { line-height: 140% !important; } #u_content_text_7 .v-font-size { font-size: 18px !important; } #u_content_text_6 .v-font-size { font-size: 18px !important; } #u_content_text_5 .v-font-size { font-size: 18px !important; } #u_content_heading_3 .v-font-size { font-size: 28px !important; } #u_column_8 .v-col-border { border-top: 0px solid transparent !important;border-left: 0px solid transparent !important;border-right: 0px solid transparent !important;border-bottom: 0px solid transparent !important; } #u_column_9 .v-col-border { border-top: 0px solid transparent !important;border-left: 0px solid transparent !important;border-right: 0px solid transparent !important;border-bottom: 0px solid transparent !important; } #u_content_menu_1 .v-layout-display { display: block !important; } }
//     </style>

// </head>

// <body class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #000000;color: #ffffff">
//   <!--[if IE]><div class="ie-container"><![endif]-->
//   <!--[if mso]><div class="mso-container"><![endif]-->
//   <table id="u_body" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #000000;width:100%" cellpadding="0" cellspacing="0">
//   <tbody>
//   <tr style="vertical-align: top">
//     <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
//     <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #000000;"><![endif]-->

// <div class="u-row-container" style="padding: 0px;background-color: transparent">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: transparent;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="700" class="v-col-border" style="width: 700px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;" valign="top"><![endif]-->
// <div class="u-col u-col-100" style="max-width: 320px;min-width: 700px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;"><!--<![endif]-->

// <table id="u_content_image_1" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 0px;font-family:helvetica,sans-serif;" align="left">

// <table width="100%" cellpadding="0" cellspacing="0" border="0">
//   <tr>
//     <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">

//       <img align="center" border="0" src="https://assets.unlayer.com/projects/139/1676495528722-apple_logo_circle_f5f5f7-000_2x.png" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 15%;max-width: 102px;" width="102" class="v-src-width v-src-max-width"/>

//     </td>
//   </tr>
// </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_heading_1" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:helvetica,sans-serif;" align="left">

//   <h1 class="v-text-align v-line-height v-font-size" style="margin: 0px; color: #ffffff; line-height: 140%; text-align: center; word-wrap: break-word; font-size: 48px; font-weight: 400;">MacBook Pro</h1>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_heading_2" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:helvetica,sans-serif;" align="left">

//   <h2 class="v-text-align v-line-height v-font-size" style="margin: 0px; color: #ffffff; line-height: 140%; text-align: center; word-wrap: break-word; font-size: 28px; font-weight: 400;">Mover. Maker. Boundary breaker.</h2>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_text_2" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 17px; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">From $1999 or $166.58/mo. for 12 mo.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 0px;background-color: transparent">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: transparent;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="350" class="v-col-border" style="width: 350px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table id="u_content_button_2" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <!--[if mso]><style>.v-button {background: transparent !important;}</style><![endif]-->
// <div class="v-text-align" align="right">
//   <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="" style="height:40px; v-text-anchor:middle; width:69px;" arcsize="62.5%"  stroke="f" fillcolor="#0071e3"><w:anchorlock/><center style="color:#FFFFFF;font-family:helvetica,sans-serif;"><![endif]-->
//     <a href="" target="_blank" class="v-button v-font-size" style="box-sizing: border-box;display: inline-block;font-family:helvetica,sans-serif;text-decoration: none;-webkit-text-size-adjust: none;text-align: center;color: #FFFFFF; background-color: #0071e3; border-radius: 25px;-webkit-border-radius: 25px; -moz-border-radius: 25px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;font-size: 17px;">
//       <span class="v-line-height" style="display:block;padding:10px 20px;line-height:120%;"><span style="line-height: 20.4px;">Buy</span></span>
//     </a>
//   <!--[if mso]></center></v:roundrect><![endif]-->
// </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
// <!--[if (mso)|(IE)]><td align="center" width="350" class="v-col-border" style="width: 350px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table id="u_content_text_3" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:20px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 17px; color: #0071e3; line-height: 140%; text-align: left; word-wrap: break-word;">
//     <p style="line-height: 140%;">Learn more </p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 15px 15px 70px;background-color: transparent">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 15px 15px 70px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: transparent;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="700" class="v-col-border" style="width: 700px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-100" style="max-width: 320px;min-width: 700px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:helvetica,sans-serif;" align="left">

// <table width="100%" cellpadding="0" cellspacing="0" border="0">
//   <tr>
//     <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">

//       <img align="center" border="0" src="https://assets.unlayer.com/projects/139/1676495949571-hero_2x.jpg" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 700px;" width="700" class="v-src-width v-src-max-width"/>

//     </td>
//   </tr>
// </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_text_4" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 21px; font-weight: 700; color: #9d9d9d; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Supercharged by M2 Pro and M2 Max.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_text_7" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 21px; font-weight: 700; color: #9d9d9d; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Up to 22 hours of battery life.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_text_6" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 21px; font-weight: 700; color: #9d9d9d; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Stunning Liquid Retina XDR display.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_text_5" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 21px; font-weight: 700; color: #9d9d9d; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">All the ports you need and faster Wi-Fi 6E.3</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 50px;background-color: #1d1d1f">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 50px;background-color: #1d1d1f;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: transparent;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="700" class="v-col-border" style="width: 700px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-100" style="max-width: 320px;min-width: 700px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table id="u_content_heading_3" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <h1 class="v-text-align v-line-height v-font-size" style="margin: 0px; color: #ffffff; line-height: 120%; text-align: center; word-wrap: break-word; font-size: 32px; font-weight: 400;">Why Apple is the best place<br />to buy your new Mac.</h1>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 5px;background-color: #1d1d1f">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #000000;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 5px;background-color: #1d1d1f;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: #000000;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="350" class="v-col-border" style="width: 350px;padding: 33px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 33px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 24px; line-height: 120%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 120%;">Get credit toward</p>
// <p style="line-height: 120%;">a new Mac.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; line-height: 150%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 150%;">With Apple Trade In, just give us your eligible Mac and get credit for a new one. It’s good for you and the planet.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; color: #0071e3; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Find your trade-in value</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
// <!--[if (mso)|(IE)]><td align="center" width="350" class="v-col-border" style="width: 350px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:helvetica,sans-serif;" align="left">

// <table width="100%" cellpadding="0" cellspacing="0" border="0">
//   <tr>
//     <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">

//       <img align="center" border="0" src="https://assets.unlayer.com/projects/139/1676496418898-credit_mac_2x.jpg" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 350px;" width="350" class="v-src-width v-src-max-width"/>

//     </td>
//   </tr>
// </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 5px;background-color: #1d1d1f">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #000000;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 5px;background-color: #1d1d1f;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: #000000;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="345" class="v-col-border" style="width: 345px;padding: 33px 0px 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 5px solid #1d1d1f;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div id="u_column_8" class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 33px 0px 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 5px solid #1d1d1f;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 24px; line-height: 120%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 120%;">Apple Card Monthly Installments.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; line-height: 150%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 150%;">Pay over time, interest-free when you choose to check out with Apple Card Monthly Installments.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; color: #0071e3; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Learn more</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:helvetica,sans-serif;" align="left">

// <table width="100%" cellpadding="0" cellspacing="0" border="0">
//   <tr>
//     <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">

//       <img align="center" border="0" src="https://assets.unlayer.com/projects/139/1676497065877-apple_card_2x.jpg" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 350px;" width="350" class="v-src-width v-src-max-width"/>

//     </td>
//   </tr>
// </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
// <!--[if (mso)|(IE)]><td align="center" width="345" class="v-col-border" style="width: 345px;padding: 33px 0px 0px;border-top: 0px solid transparent;border-left: 5px solid #1d1d1f;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div id="u_column_9" class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 33px 0px 0px;border-top: 0px solid transparent;border-left: 5px solid #1d1d1f;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 24px; line-height: 120%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 120%;">Save on a new</p>
// <p style="line-height: 120%;">Mac with Apple</p>
// <p style="line-height: 120%;">education pricing.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; color: #0071e3; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Shop</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:21px 0px 0px;font-family:helvetica,sans-serif;" align="left">

// <table width="100%" cellpadding="0" cellspacing="0" border="0">
//   <tr>
//     <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">

//       <img align="center" border="0" src="https://assets.unlayer.com/projects/139/1676497143860-edu_mac_2x.jpg" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 350px;" width="350" class="v-src-width v-src-max-width"/>

//     </td>
//   </tr>
// </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 5px;background-color: #1d1d1f">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #000000;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 5px;background-color: #1d1d1f;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: #000000;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="350" class="v-col-border" style="width: 350px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:0px;font-family:helvetica,sans-serif;" align="left">

// <table width="100%" cellpadding="0" cellspacing="0" border="0">
//   <tr>
//     <td class="v-text-align" style="padding-right: 0px;padding-left: 0px;" align="center">

//       <img align="center" border="0" src="https://assets.unlayer.com/projects/139/1676496501021-specialist_2x.jpg" alt="" title="" style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 100%;max-width: 350px;" width="350" class="v-src-width v-src-max-width"/>

//     </td>
//   </tr>
// </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
// <!--[if (mso)|(IE)]><td align="center" width="350" class="v-col-border" style="width: 350px;padding: 33px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-50" style="max-width: 320px;min-width: 350px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 33px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 24px; line-height: 120%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 120%;">Shop one on one with</p>
// <p style="line-height: 120%;">a Mac Specialist.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; line-height: 150%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 150%;">Our Specialists can help you choose, configure, and buy the perfect Mac.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 14px; color: #0071e3; line-height: 140%; text-align: center; word-wrap: break-word;">
//     <p style="line-height: 140%;">Find a store</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

// <div class="u-row-container" style="padding: 10px 10px 50px;background-color: #1d1d1f">
//   <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 700px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
//     <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
//       <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 10px 10px 50px;background-color: #1d1d1f;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:700px;"><tr style="background-color: transparent;"><![endif]-->

// <!--[if (mso)|(IE)]><td align="center" width="700" class="v-col-border" style="width: 700px;padding: 0px 30px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
// <div class="u-col u-col-100" style="max-width: 320px;min-width: 700px;display: table-cell;vertical-align: top;">
//   <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
//   <!--[if (!mso)&(!IE)]><!--><div class="v-col-border" style="box-sizing: border-box; height: 100%; padding: 0px 30px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;"><!--<![endif]-->

// <table id="u_content_menu_1" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:5px;font-family:helvetica,sans-serif;" align="left">

// <div class="menu" style="text-align:center">
// <!--[if (mso)|(IE)]><table role="presentation" border="0" cellpadding="0" cellspacing="0" align="center"><tr><![endif]-->

//   <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->

//     <a href="https://www.apple.com/" target="_self" style="padding:5px 10px;display:inline-block;color:#d2d2d7;font-size:14px;text-decoration:none"  class="v-font-size v-layout-display">
//       Shop Online
//     </a>

//   <!--[if (mso)|(IE)]></td><![endif]-->

//     <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->
//     <span style="padding:5px 10px;display:inline-block;color:#424245;font-size:14px;" class="v-font-size hide-mobile">
//       |
//     </span>
//     <!--[if (mso)|(IE)]></td><![endif]-->

//   <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->

//     <a href="https://www.apple.com/" target="_self" style="padding:5px 10px;display:inline-block;color:#d2d2d7;font-size:14px;text-decoration:none"  class="v-font-size v-layout-display">
//       Find a Store
//     </a>

//   <!--[if (mso)|(IE)]></td><![endif]-->

//     <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->
//     <span style="padding:5px 10px;display:inline-block;color:#424245;font-size:14px;" class="v-font-size hide-mobile">
//       |
//     </span>
//     <!--[if (mso)|(IE)]></td><![endif]-->

//   <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->

//     <a href="https://www.apple.com/" target="_self" style="padding:5px 10px;display:inline-block;color:#d2d2d7;font-size:14px;text-decoration:none"  class="v-font-size v-layout-display">
//       1-800-MY-APPLE
//     </a>

//   <!--[if (mso)|(IE)]></td><![endif]-->

//     <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->
//     <span style="padding:5px 10px;display:inline-block;color:#424245;font-size:14px;" class="v-font-size hide-mobile">
//       |
//     </span>
//     <!--[if (mso)|(IE)]></td><![endif]-->

//   <!--[if (mso)|(IE)]><td style="padding:5px 10px"><![endif]-->

//     <a href="https://www.apple.com/" target="_self" style="padding:5px 10px;display:inline-block;color:#d2d2d7;font-size:14px;text-decoration:none"  class="v-font-size v-layout-display">
//       Get the Apple Store App
//     </a>

//   <!--[if (mso)|(IE)]></td><![endif]-->

// <!--[if (mso)|(IE)]></tr></table><![endif]-->
// </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 1px solid #424245;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
//     <tbody>
//       <tr style="vertical-align: top">
//         <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
//           <span>&#160;</span>
//         </td>
//       </tr>
//     </tbody>
//   </table>

//       </td>
//     </tr>
//   </tbody>
// </table>

// <table id="u_content_text_17" style="font-family:helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
//   <tbody>
//     <tr>
//       <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:helvetica,sans-serif;" align="left">

//   <div class="v-text-align v-line-height v-font-size" style="font-size: 12px; color: #86868b; line-height: 200%; text-align: left; word-wrap: break-word;">
//     <p style="line-height: 200%;">If you reside in the U.S. territories, please call Goldman Sachs at 877-255-5923 with questions about Apple Card.</p>
// <p style="line-height: 200%;">TM and © 2023 Apple Inc. One Apple Park Way, MS 96-DM, Cupertino, CA 95014.</p>
// <p style="line-height: 200%;"><a rel="noopener" href="https://www.apple.com/" target="_blank">All Rights Reserved</a>    |   <a rel="noopener" href="https://www.apple.com/" target="_blank">Privacy Policy</a>    |   <a rel="noopener" href="https://www.apple.com/" target="_blank">My Apple ID</a></p>
// <p style="line-height: 200%;">If you prefer not to receive commercial email from Apple, or if you’ve changed your email address, please <a rel="noopener" href="https://www.apple.com/" target="_blank">click here</a>.</p>
//   </div>

//       </td>
//     </tr>
//   </tbody>
// </table>

//   <!--[if (!mso)&(!IE)]><!--></div><!--<![endif]-->
//   </div>
// </div>
// <!--[if (mso)|(IE)]></td><![endif]-->
//       <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
//     </div>
//   </div>
// </div>

//     <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
//     </td>
//   </tr>
//   </tbody>
//   </table>
//   <!--[if mso]></div><![endif]-->
//   <!--[if IE]></div><![endif]-->
// </body>

// </html>`;

const SmsTemplateBank = memo((props) => {
  return (
    <Fragment>
      <div className="mb-5">
        <div className="d-flex justify-content-between mb-4">
          <h4 className="mb-0">SMS Template Bank</h4>
          <Dropdown className="d-flex align-items-center">
            Sorting By
            <Dropdown.Toggle
              as={CustomToggle}
              variant="text-secondary dropdown-toggle"
            >
              Name
            </Dropdown.Toggle>
            <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
              <li>
                <Dropdown.Item href="#">Recent</Dropdown.Item>
              </li>
              <li>
                <Dropdown.Item href="#">Mostly Used</Dropdown.Item>
              </li>
              <li>
                <Dropdown.Item href="#">Most Popular</Dropdown.Item>
              </li>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Masonry
        breakpointCols={isMobileOnly ? 1 : isMobile ? 2 : 3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <Card className={props.classname}>
          <Card.Body className="card-thumbnail">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="mt-5 mb-5">
                <svg
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-success"
                >
                  <path
                    d="M12 4V20M20 12H4"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>
              <div className="mt-5 mb-5">
                <Button
                  type="button"
                  variant="success"
                  className="fs-5 rounded-3"
                >
                  Create New Template
                </Button>
              </div>
            </div>
          </Card.Body>
          {/* <Card.Footer className="p-0">
            <Image
              src={img6}
              alt="02"
              className="img-fluid w-100 rounded object-cover "
              loading="lazy"
            />
          </Card.Footer> */}
        </Card>
        {/* <Masonry
          breakpointCols={1}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        > */}
        {[1, 2].map((_, index) => (
          <Card key={index}>
            <Card.Body>
              {/* <Image
                src={smstemplate}
                alt="02"
                className="img-fluid w-100 rounded object-cover "
                loading="lazy"
              /> */}
              <TemplateCard data={smstemplate} />
            </Card.Body>
            {/* <Card.Footer><TemplateCard data={htmlData} /></Card.Footer> */}
          </Card>
        ))}
        {/* <Card>
          <Card.Body>
            <Image
                src={smstemplate}
                alt="02"
                className="img-fluid w-100 rounded object-cover "
                loading="lazy"
              />
            <TemplateCard data={smstemplate} />
          </Card.Body>
        </Card> */}
        {/* <Card>
          <Card.Body>
            <Image
                src={smstemplate}
                alt="02"
                className="img-fluid w-100 rounded object-cover "
                loading="lazy"
              />
            <TemplateCard data={smstemplate} />
          </Card.Body>
        </Card> */}
        {/* </Masonry> */}
        {/* <Masonry
          breakpointCols={1}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        > */}
        {/* <Card>
          <Card.Body>
            <Image
                src={smstemplate}
                alt="02"
                className="img-fluid w-100 rounded object-cover "
                loading="lazy"
              />
            <TemplateCard data={smstemplate} />
          </Card.Body>
        </Card> */}
        {/* <Card>
          <Card.Body>
            <Image
                src={smstemplate}
                alt="02"
                className="img-fluid w-100 rounded object-cover "
                loading="lazy"
              />
            <TemplateCard data={smstemplate} />
          </Card.Body>
        </Card> */}
        {/* </Masonry> */}

        {/* <TemplateCard data={htmlData} /> */}
      </Masonry>

      <div className="text-center">
        <Link to="/blog/blog-details" className="btn btn-primary mb-3">
          Load More
        </Link>
      </div>
    </Fragment>
  );
});

export default SmsTemplateBank;
