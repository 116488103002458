import { Skeleton } from "@mui/material";
import React from "react";
import { Card } from "react-bootstrap";

const ContentCardLoader = () => {
  return (
    <Card className="position-relative overflow-hidden">
      <Card.Body className="p-3">
        <div className="p-3 d-flex justify-content-center align-items-center rounded bg-light">
          <Skeleton variant="rectangular" width={"100%"} height={80} />
        </div>

        <div className="mt-3">
          <div className="d-flex align-items-start mb-2 gap-2">
            <div className="flex-grow-1 fs-6 text-truncate">
              <h5 className="text-dark mb-1 small text-truncate">
                <Skeleton variant="text" width={"80%"} height={25} />
              </h5>

              <p className="fs-7 mb-0 lh-sm">
                <Skeleton variant="text" width={"30%"} height={25} />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-3">
            {/* <span className="small mb-0 me-2 text-info">{props.imgMb}</span> */}
            <div className="d-flex justify-content-end align-items-center gap-2 ms-auto">
              <Skeleton variant="text" width={60} height={40} />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ContentCardLoader;
