export const hcpReviewDataUser = [
  {
    hcp_id: 198,
    hcp_utmsource: "UTM15",
    hcp_first_name: "Pravat ",
    hcp_middle_name: "",
    hcp_last_name: "Mishra",
    hcp_age: "53",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "10 Sep 2023 00:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "General",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 150,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona One",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "West Bengal",
    },
  },
  {
    hcp_id: 1612,
    hcp_utmsource: "UTM19",
    hcp_first_name: "Madhusudan ",
    hcp_middle_name: "",
    hcp_last_name: "Panigrahi",
    hcp_age: "57",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "12 Sep 2023 00:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Anaesthesiology",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Five",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Odisha",
    },
  },
  {
    hcp_id: 10,
    hcp_utmsource: "UTM109",
    hcp_first_name: "Anand ",
    hcp_middle_name: "",
    hcp_last_name: "Agarwal",
    hcp_age: "59",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "12 Sep 2023 00:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Cardiothoracic Anesthesia",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 14,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Six",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Delhi",
    },
  },
  {
    hcp_id: 190,
    hcp_utmsource: "UTM140",
    hcp_first_name: "Krishnaveni ",
    hcp_middle_name: "",
    hcp_last_name: "Gomati",
    hcp_age: "60",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "12 Sep 2023 10:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Surgery",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 310,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Ten",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Jharkhand",
    },
  },
  {
    hcp_id: 810,
    hcp_utmsource: "UTM1077",
    hcp_first_name: "Pulak ",
    hcp_middle_name: "",
    hcp_last_name: "Deka",
    hcp_age: "48",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "13 Sep 2023 14:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Radiology",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 140,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Three",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "Bihar",
    },
  },
  {
    hcp_id: 142,
    hcp_utmsource: "UTM104",
    hcp_first_name: "Dipak ",
    hcp_middle_name: "Suresh",
    hcp_last_name: "Bathe",
    hcp_age: "58",
    hcp_primary_speciality_id: 8,
    hcp_state_id: 10,
    hcp_city_id: 7,
    updated_at: "20 Sep 2023 15:20:00",
    created_by: {
      id: 1,
      name: "Super Admin",
    },
    modified_by: {
      id: 1,
      name: "Super Admin",
    },
    status: {
      id: 2,
      value: null,
    },
    hcp_to_primary_speciality: {
      speciality_id: 8,
      speciality_name: "Gastroenterology",
      created_by: {
        id: 1,
        name: "Super Admin",
      },
    },
    hcp_to_persona: [
      {
        id: 10,
        hcp_id: 10,
        persona_id: 5,
        all_persona: {
          persona_id: 5,
          persona_name: "Persona Four",
          persona_icon:
            "https://storage.googleapis.com/cipla-cms-dev/icons/persona/avtar_5.png",
        },
      },
    ],
    hcp_to_city: {
      city_id: 10,
      city_name: "Krishna",
    },
    hcp_to_state: {
      state_id: 10,
      state_name: "West Bengal",
    },
  },
];
