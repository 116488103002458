import React, { memo } from "react";
import Chart from "react-apexcharts";

function SparlLineBar({ id, series, labels, height = 35 }) {
  const chartOptions = {
    series: [
      {
        data: series || [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        width: 100,
        height: 35,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "80%",
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: "#f8f8f8", // Light color for 0 value
              },
              {
                from: 0.1,
                to: Infinity,
                color: "#13388c", // Your original color for non-zero values
              },
            ],
            backgroundBarColors: ["#F8F8F8"], // Light color for entire bar background
          },
        },
      },
      colors: ["#13388c"], // Default color for non-zero values
      labels: labels || [],
      xaxis: {
        crosshairs: {
          width: 1,
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
        marker: {
          show: false,
        },
      },
    },
  };

  // 13388c

  return (
    <>
      <div id="chart-5" key={id}>
        <Chart
          options={chartOptions.options}
          series={chartOptions.series}
          type="bar"
          height={height}
          width={100}
        />
      </div>
    </>
  );
}

export default memo(SparlLineBar);
