import React from "react";
import Skeleton from "../../../../components/skeleton";
import ProfileSkeleton from "./ProfileSkeleton";

const ContentInfoSkeleton = () => {
  return (
    <div className="d-flex flex-column gap-3">
      <Skeleton
        variant="square"
        className="ratio ratio-16x9 rounded-3"
        height={250}
        width={"100%"}
      />
      <Skeleton
        variant="square"
        className="rounded-3"
        width={"30%"}
        height={15}
      />
      <Skeleton variant="square" className="rounded-3" width={"100%"} />
      <ProfileSkeleton />
      <Skeleton
        variant="square"
        className="rounded-3"
        width={"100%"}
        height={200}
      />
    </div>
  );
};

export default ContentInfoSkeleton;
