import React from "react";
import { Button, Card, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../dashboard/icons";
import ImageWithFallback from "../../../../../components/ImageWithFallback";
import { fallbackImages } from "../../../../../common/constants/fallback-images";
import { getFormatedDate } from "../../../../../common/utils/utils";

function SponsoredContentCard({
  contentId,
  contentImage,
  contentType,
  contentLogo,
  contentTitle,
  contentDate,
  categoryIcon,
}) {
  const { redirectTo } = useRedirect();

  const { project_id } = useParams();

  const handleOnClick = () => {
    redirectTo(`sponsored-content/${project_id}/content/details/${contentId}`);
  };

  console.log(contentImage, "contentImage");
  return (
    <div className="bg-soft-secondary bg-opacity-50 p-2 p-md-4 mt-3 mt-md-0 rounded-3 sponsoredContentCard">
      <Card className="m-0 p-0">
        <Card.Body className="d-flex flex-column flex-md-row">
          <div className="cardImage">
            <ImageWithFallback
              className="rounded-3 w-100 h-100 object-fit-cover"
              src={contentImage || ""}
              fallbackSrc={fallbackImages?.noImage}
            />
          </div>
          <div className="cardDetails d-flex flex-column w-100 flex-grow-1">
            <div className="detailsHeader d-flex justify-content-between align-items-center ps-0 ps-md-3 py-2 py-md-0">
              <div className="">
                <img src={categoryIcon} alt="" className="icon-22" />
                {contentType}
              </div>
              {contentLogo && (
                <div className="cardLogo icon-30">
                  <ImageWithFallback
                    className="w-100 h-100 object-fit-contain rounded-circle"
                    src={contentLogo}
                    fallbackSrc={fallbackImages?.noImage}
                  />
                </div>
              )}
            </div>
            <h3 className="ms-0 ms-md-n5 mt-auto rounded-3 cardHeading p-3 mb-3">
              <span className="two-line-clamp fs-5">{contentTitle}</span>
            </h3>
            <div className="cardFooter d-flex justify-content-between align-items-center align-items-md-end mt-auto ps-0 ps-md-3">
              <div className="date text-black-50">
                {getFormatedDate(contentDate)}
              </div>
              <Button
                variant="primary"
                className="px-3 rounded-3"
                onClick={handleOnClick}
              >
                {findIcon("Chevron Right", "solid", "24")} Content Details
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SponsoredContentCard;
