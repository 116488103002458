import React,{ memo} from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../../components/skeleton";

function BrandingTabSkeleton() {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-column gap-md-5 gap-4">
          <Row>
            {<ReuseUploadPreviewCard imageRoundedType="rounded-circle" />}
          </Row>

          <Row>{<ReuseUploadPreviewCard />}</Row>

          <Row>
            <Col md={12}>
              <Skeleton variant="text" />
              <Skeleton variant="text" width="40%" />
            </Col>

            <Row>
              <Col md={4} xs={5}>
                <div className="d-flex align-items-center justify-content-center bg-light p-3">
                  <div className="bg-light overflow-hidden flex-shrink-0 mw-100 mh-100">
                    <Skeleton variant="square" width={100} height={100} />
                  </div>
                </div>
              </Col>

              <Col lg={5} md={7} xs={7}>
                <div className="d-flex flex-column mb-1 ">
                  <Skeleton variant="text" width={120} />

                  <div className="d-flex flex-column gap-2">
                    {[...Array(3)].map((_, index) => (
                      <div
                        className="d-flex gap-2 align-items-center pt-1"
                        key={index}
                      >
                        <Skeleton
                          variant="square"
                          className="rounded-circle"
                          height={15}
                          width={15}
                        />
                        <Skeleton
                          variant="text"
                          className="m-0 p-0"
                          width={100}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="d-flex gap-2 mt-2">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(BrandingTabSkeleton);

function ReuseUploadPreviewCard({ imageRoundedType }) {
  return (
    <>
      <Col md={12}>
        <Skeleton variant="text" />
        <Skeleton variant="text" width="40%" />
      </Col>

      <Row>
        <Col md={4} xs={5}>
          <div className="d-flex align-items-center justify-content-center bg-light p-3">
            <div
              className={`avatar-100 bg-light ${imageRoundedType} overflow-hidden flex-shrink-0`}
            >
              <Skeleton variant="square" width={100} height={100} />
            </div>
          </div>
        </Col>

        <Col lg={5} md={7} xs={7}>
          <Skeleton
            variant="text"
            height={80}
            className="m-0 p-0"
            width="80%"
          />
          <div className="d-flex gap-4">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </div>
        </Col>
      </Row>
    </>
  );
}
