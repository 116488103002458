import { Fragment, useCallback, useEffect, useRef, useState } from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";

//components
// import Card from "../../../../../components/bootstrap/card";

// img

import toast from "react-hot-toast";
import {
  SMS_TEMPLATE_DELETE,
  SMS_TEMPLATE_LIST_GET,
} from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import postService from "../../../../../api/services/post-service";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import FilterBar from "../../../../../components/filter-bar";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import ViewModal from "../../../../../components/partials/common/view-modal";
import ReactTable from "../../../../../lib/table/react-table";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";
import { findIcon } from "../../../../dashboard/icons";

const AllSmsTemplates = () => {
  const [modalShow, setModalShow] = useState(false);
  const [smstemplateList, setSmsTemplateList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewSMSData, setViewsmsdata] = useState({});
  const [viewSMSModalShow, setSMSViewModalShow] = useState(false);

  const actionView = findIcon("Eye", "solid");

  const actionEdit = findIcon("Pencil", "dual-tone");
  const columns = [
    {
      Header: "ID",
      accessor: "sms_id",
    },
    {
      Header: "Name",
      accessor: "sms_name",
      Cell: ({ value }) => {
        return (
          <div
            className="text-start text-truncate"
            style={{ maxWidth: "20rem" }}
          >
            {value}
          </div>
        );
      },
    },
    // {
    //   Header: "Content",
    //   accessor: "sms_content",
    //   Cell: ({ value }) => {
    //     return (
    //       <div
    //         className="text-start text-truncate"
    //         style={{ maxWidth: "20rem" }}
    //       >
    //         {value}
    //       </div>
    //     );
    //   },
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Badge bg={statusToBg(row.values.status.value)} className="me-2">
            {row.values.status.value ? (
              <span className="btn-inner">{row.values.status.value}</span>
            ) : (
              <span className="btn-inner">Not Found</span>
            )}
          </Badge>
        </div>
      ),
    },
    {
      Header: "Approved By",
      accessor: "approved_by",
      Cell: ({ value }) => {
        return value && value?.name ? value?.name : "N/A";
      },
    },
    {
      Header: "Creation By",
      accessor: "created_by",
      Cell: ({ value }) => {
        return value && value.name ? value.name : "";
      },
    },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() => {
            // viewSMSModal(row?.original);
            redirectTo(
              "template-management/sms-template/details/" + row.original.sms_id
            );
          }}
          onEdit={() => {
            redirectTo(
              "template-management/sms-template/create/" + row.original.sms_id
            );
          }}
          onDelete={() => {
            setSelectedTemplate(row.original.sms_id);
            modalOpen();
          }}
        />
      ),
    },
  ];

  const { redirectTo } = useRedirect();

  const post_json = useRef({
    // default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      sms_id: "desc",
    },
  });
  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json.current = paylod;
      fetchTemplate();
    },
    [smstemplateList, loading, post_json.current]
  );
  const fetchTemplate = async () => {
    try {
      // setLoading(true);
      const response = await postService(
        SMS_TEMPLATE_LIST_GET,
        post_json.current
      );
      console.log("sms template service ", response.data.data);
      if (response.isError) {
        toast.error(response?.error);
      } else {
        if (response.data.data.rows.length > 0) {
          setSmsTemplateList(response.data.data.rows);
          setTotalCount(response.data.data.totalCount);
          setLoading(false);
        } else {
          setSmsTemplateList([]);
          setTotalCount(0);
          setLoading(false);
        }
      }
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    fetchTemplate();
  }, []);

  const modalOpen = () => {
    setModalShow(true);
  };

  const modalClose = useCallback(() => {
    setSelectedTemplate(null);
    setModalShow(false);
  }, [modalShow]);

  //For Delete data
  const deleteSMSTemplate = async () => {
    // alert("delete");
    try {
      setLoading(true);
      const response = await getService(
        `${SMS_TEMPLATE_DELETE}/${selectedTemplate}`,
        {}
      );
      if (response) {
        setLoading(false);
        setModalShow(false);
        fetchTemplate();
        if (response.data.success) {
          toast.success(response.data.message);
        }
        if (response.isError) {
          toast.error(response?.error);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };

  const viewSMSModal = async (data) => {
    // console.log("data123==>", data);
    // setViewModalShow(true);
    let {
      created_by,
      sms_content,
      sms_id,
      sms_name,
      status,
      approve,
      sms_category_id,
      sms_sender,
      sms_template_type,
      approved_by,
    } = data;
    let smsData = {
      Id: sms_id ?? "",
      Name: sms_name ?? "",
      "Created By": created_by?.name ?? "",
      "Approved By": approved_by?.name ?? "",
      Description: sms_content.substring(0, 16) + "..." ?? "",
      Approve: approve?.value,
      Category: sms_category_id?.category_name,
      "Sender Name": sms_sender,
      "Template Type": sms_template_type?.value,
      Status: status?.value ?? "",
    };

    setViewsmsdata(smsData);
    setSMSViewModalShow(true);
  };

  const viewSMSModalClose = () => {
    setSMSViewModalShow(false);
  };

  useEffect(() => {
    console.log("total count", totalCount);
    console.log("sms template list length", smstemplateList.length);
  }, [totalCount]);

  return (
    // <div>AllSmsTemplates</div>
    <Fragment>
      {/* <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => redirectTo("template-management/sms-template/create")}
      >
        {findIcon("Plus", "outline", "32")}
      </Button> */}
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h3 className="m-0">Sms Templates</h3>
            </div>
            <FilterBar />
            <Button
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() =>
                redirectTo("template-management/sms-template/create")
              }
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* <Card.Header className="d-flex justify-content-between">
              <Row className="w-100 m-0">
                <Col md="6" className="header-title mb-5 mb-md-0 p-0">
                  <h4 className="card-title">Sms Templates</h4>
                </Col>
                <FilterBar />
              </Row>
            </Card.Header> */}

            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                {!loading ? (
                  smstemplateList.length > 0 ? (
                    <ReactTable
                      data={smstemplateList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      recordsPerPage={post_json.current.pagination.limit}
                    />
                  ) : (
                    // <CommonTableLoader />
                    <PageNotFound />
                  )
                ) : (
                  <CommonTableLoader />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteSMSTemplate}
      />

      <ViewModal
        title={"View SMS Template Information"}
        show={viewSMSModalShow}
        modalClose={viewSMSModalClose}
        data={viewSMSData}
      />
    </Fragment>
  );
};

export default AllSmsTemplates;
