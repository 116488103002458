import React, { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Link,
  Form,
  Button,
  Collapse,
  FloatingLabel,
} from "react-bootstrap";
import toast from "react-hot-toast";
import CreateCampaignModal from "../components/video-campaign/CreateCampaignModal";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import CreateCampaignMetaPreview from "../components/video-campaign/CreateCampaignMetaPreview";
// useGetCampaignList
import ReactTable from "../../../../../../lib/table/react-table";
import ActionButtonBar from "../../../../../../components/partials/common/action-button-bar";
import {
  useGetCampaignList,
  useGetVideoAnalytics,
} from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import FilePicker from "../../../../../../components/form-components/file-picker";
import { useFormik } from "formik";
import * as Yup from "yup";
import postService from "../../../../../../api/services/post-service";
import { POST_CAMPAIGN_ADD } from "../../../../../../api/endpoints/channel-endpoint";
import CampaignListSkeleton from "../../../../../../skeleton/workspace/doctube/channel/campaign-list-skeleton";

import { useParams } from "react-router-dom";
import useCopyToClipboard from "../../../../../../components/custom-hooks/useCopyToClipboard";
import { FILE_UPLOAD } from "../../../../../../api/endpoints/requisition-endpoints";
import uploadService from "../../../../../../api/services/upload-service";
import PageNotFound from "../../../../../PageNotFound";
import { findIcon } from "../../../../../dashboard/icons";
import { useGetVideoEdit } from "../../../../../../queries/query-hooks/workspace/channel-video-hook";
import useBackdrop from "../../../../../../components/custom-hooks/use-backdrop";
const innitialPreview = {
  campaign_name: "",
  og_title: "",
  og_description: "",
  og_image: "",
};
function ChannelVideoCampaign() {
  const { redirectTo } = useRedirect();
  const { create_campaign } = useParams();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const copyToClipboard = useCopyToClipboard();
  const { video_id } = useParams();
  const [initialValues, setInitialValues] = useState(innitialPreview);
  const [collapseOpen, setCollapseOpen] = useState(create_campaign ?? false);
  const [createCampaignModdal, setCreateCampaignModal] = useState(false);
  const [campaignListPayload, setCampaignListPayload] = useState({
    filter: {
      content_id: {
        type: "eq",
        value: video_id,
      },
    },
    pagination: {
      limit: 3,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
  });
  const {
    data,
    isLoading: isCampaignListLoading,
    refetch: refetchCampaign,
  } = useGetCampaignList(campaignListPayload);

  const { data: videoData, isFetching: isLoading } = useGetVideoEdit(
    video_id,
    (response) => {
      const { video_detail } = response || {
        data: "",
        video_detail: "",
      };
      formik.setValues({
        og_image:
          video_detail?.content_to_image[0]?.content_image_id?.thumbnail,
        og_title: video_detail?.content_title,
        og_description: video_detail?.content_description,
        campaign_name: video_detail?.content_title,
      });
      setInitialValues({
        og_image:
          video_detail?.content_to_image[0]?.content_image_id?.thumbnail,
        og_title: video_detail?.content_title,
        og_description: video_detail?.content_description,
        campaign_name: video_detail?.content_title,
      });
    }
  );

  useEffect(() => {
    if (isLoading && collapseOpen) {
      showBackdrop();
    } else {
      hideBackdrop();
    }

    return () => {
      hideBackdrop();
    };
  }, [isLoading]);

  const { rows: campaignList, totalCount } = data || {
    rows: [],
    totalCount: 0,
  };

  const [isSubmiting, setIsSubmiting] = useState(false);
  async function submitCampaign(values, url) {
    setIsSubmiting(true);
    const payloadObj = {
      campaign: values?.campaign_name,
      og_title: values?.og_title,
      og_description: values?.og_description,
      og_image: url,
      link_type: "share",
      content_category_id: "30",
      content_id: video_id,
      base_url_id: "2", // for Doctube
    };
    showBackdrop();
    let response = await postService(POST_CAMPAIGN_ADD, payloadObj);
    hideBackdrop();
    if (response.isError) {
      setIsSubmiting(false);
      toast.error(response.error);
    } else {
      setValues(initialValues);
      setIsSubmiting(false);
      toast.success(response.data.message);
      refetchCampaign();
      setCollapseOpen(!collapseOpen);
    }
  }

  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(FILE_UPLOAD, formdata);
      if (response?.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.url);

        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }

  const formik = useFormik({
    initialValues: innitialPreview,
    validationSchema: Yup.object({
      campaign_name: Yup.string().required("campaign name is required "),
      og_title: Yup.string().required("meta title is required"),
      og_description: Yup.string().required("meta description is required"),
    }),
    onSubmit: async (values) => {
      if (values.og_image) {
        let imageUrl = values.og_image;
        if (imageUrl instanceof File) {
          const urls = await uploadFiles([imageUrl]);
          imageUrl = urls[0];
        }
        submitCampaign(values, imageUrl);
      } else {
        submitCampaign(values, null);
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
  } = formik;

  const onActionAnalyticsView = (id) => {
    redirectTo(`channel/video/campaign/analytics/${id}`);
  };

  const isVideoPublished =
    videoData?.video_detail?.content_publishing_status?.value?.value === 3;

  const CAMPAIGN_COLUMNS = [
    {
      Header: "Campaign Name / UTM",
      columnId: 1,
      accessor: "campaign",
      disableFilters: true,
    },
    {
      Header: "Short link",
      accessor: "url_key",
      disableFilters: true,
      columnId: 2,
      Cell: ({ value }) => value?.default_short_url,
    },
    {
      Header: "Date",
      accessor: "created_at",
      disableFilters: true,
      columnId: 3,
    },
    {
      columnId: 4,
      Header: "Clicks",
      accessor: "shortlink_analytics",
      disableFilters: true,
      Cell: ({ value }) => {
        return value?.at(0)?.total_clicks || 0;
      },
    },
    {
      columnId: 5,
      Header: "Views",
      // accessor: "shortlink_analytics",
      disableFilters: true,
      Cell: ({ row }) => {
        return row.original?.shortlink_analytics?.at(0)?.total_views || 0;
      },
    },
    {
      columnId: 6,
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row, value }) => {
        const { url_key, content } = row.original;
        return (
          <ActionButtonBar
            buttons={["analytics", "copy"]}
            row={row}
            onAnalytics={(data) => {
              redirectTo(
                `channel/video/campaign/analytics/${data?.url_key?.url_key}/${content.content_id}`
              );
            }}
            onCopy={(data) => {
              copyToClipboard(url_key.destination_url);
            }}
          />
        );
      },
    },
  ];

  const onTableStateChange = (state) => {
    console.log("STATE", state);
    // let payload = changeTableStateToPayload(state);
    // console.log("PAYLOAD", payload);
    // // post_json.current = { ...post_json.current, ...payload };
    // setHcpListPayload((prev) => ({
    //   ...prev, ...payload
    // }))
    // fetchHcps();
  };

  // const onMetaImageChange = (files) => {
  //   console.log("onMetaImageChange", files.target.files);
  //   if (files?.target?.files?.length > 0) {
  //     setFieldValue({ ...values, og_image: files.target.files[0] });
  //   }
  // };

  const onMetaImageChange = (e) => {
    const { files } = e.target;
    console.log("onMetaImageChange", files);
    if (files.length > 0) {
      setValues({ ...values, og_image: files[0] });
    }
  };

  // skeleton
  if (isCampaignListLoading) {
    return <CampaignListSkeleton />;
  }

  const onUploadFile = () => {
    // redirectTo(`channel/file-upload/${channel_id}`);
  };
  const createShortlinkModalHandler = () => {
    if (!isVideoPublished) {
      toast.error("Video not published yet");
      return;
    }
    setCollapseOpen(!collapseOpen);
  };

  return (
    <Fragment>
      {!collapseOpen && (
        <Col className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="fw-semibold mb-0">Shortlinks</h4>

          <Button
            className="flex-shrink-0"
            variant="success"
            onClick={createShortlinkModalHandler}
            aria-controls="collapse-text"
            aria-expanded={collapseOpen}
          >
            {isSubmiting ? "Creating Shortlink..." : "Create Shortlink"}
          </Button>
        </Col>
      )}
      <Collapse in={collapseOpen} timeout={0}>
        <Card>
          <Card.Body className="rounded-5 p-md-4 p-3">
            <Row>
              <Col md={6}>
                <CreateCampaignMetaPreview
                  meta_title={formik.values.og_title}
                  meta_description={formik.values.og_description}
                  meta_link={"clir.in"}
                  meta_image={formik.values.og_image}
                />
              </Col>
              <Col md={6}>
                <Form className="h-100 d-flex flex-column">
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Campaign Name"
                    className="mb-4 custom-form-floating "
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="campaign_name"
                      value={values.campaign_name}
                      onChange={handleChange}
                      isInvalid={
                        touched.campaign_name && !!errors.campaign_name
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.campaign_name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Title"
                    className="mb-4 custom-form-floating "
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="og_title"
                      value={formik.values.og_title}
                      onChange={handleChange}
                      isInvalid={touched.og_title && !!errors.og_title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.og_title}
                    </Form.Control.Feedback>
                  </FloatingLabel>

                  <FloatingLabel
                    className="custom-form-floating mb-4"
                    controlId="floatingTextarea2 "
                    label="Description"
                  >
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Leave a comment here"
                      resize="none"
                      name="og_description"
                      value={values.og_description}
                      onChange={handleChange}
                      isInvalid={
                        touched.og_description && !!errors.og_description
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.og_description}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingTextarea"
                    label="Image"
                    className="mb-4 custom-form-floating "
                  >
                    <Form.Control
                      type="file"
                      // value={values.og_image}
                      accepts="image/png, image/jpg, image/jpeg"
                      onChange={onMetaImageChange}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.og_image}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                  {/* <FilePicker
                    extraClass="custom-file-picker rounded-3 border-0 bg-soft-primary bg-opacity-50 mb-4"
                    title="Add OG Image"
                    type="image"
                    fileName={values.og_image}
                    accepts="image/png, image/jpg, image/jpeg"
                    onUpdate={onMetaImageChange}
                  /> */}
                  {touched.og_image && errors.og_image ? (
                    <div className="fs-6 text-danger">{errors.og_image}</div>
                  ) : null}
                  <div className="d-flex justify-content-end gap-3 mt-4">
                    <Button
                      variant="light"
                      className="flex-shrink-0"
                      onClick={() => setCollapseOpen(!collapseOpen)}
                      aria-controls="collapse-text"
                      aria-expanded={collapseOpen}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="flex-shrink-0"
                      variant="success"
                      onClick={handleSubmit}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </Col>
              <CreateCampaignModal
                closeCurrentModal={() => setCollapseOpen(!collapseOpen)}
                show={createCampaignModdal}
                onHide={() => setCreateCampaignModal(false)}
              />
            </Row>
          </Card.Body>
        </Card>
      </Collapse>

      {/*  */}

      {/*  */}
      <Card>
        <Card.Body className="rounded-5 p-md-5 p-3">
          {/* campaign link */}
          <Row>
            {collapseOpen && (
              <Col className="d-flex justify-content-between align-items-end mb-4">
                <h5 className="fw-semibold mb-0">Campaign Link</h5>
              </Col>
            )}

            <Col md={12} lg={12}>
              {!isCampaignListLoading &&
              campaignList?.length > 0 &&
              totalCount > 0 ? (
                <ReactTable
                  data={campaignList}
                  columns={CAMPAIGN_COLUMNS}
                  onTableStateChange={onTableStateChange}
                  recordsTotal={totalCount}
                  initialState={campaignListPayload}
                  onRowSelectionChange={(selectedList) => {}}
                  recordsPerPage={campaignListPayload.pagination.limit}
                />
              ) : !isCampaignListLoading && campaignList?.length <= 0 ? (
                <>
                  <PageNotFound
                    title="You don't have any campaign!"
                    // buttonText="Upload Video"
                    message="Create your first campaign Now!"
                    backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                    // onFallbackClick={onUploadFile}
                  />
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/*  */}
    </Fragment>
  );
}

export default ChannelVideoCampaign;
