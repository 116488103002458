import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import TableHeaderSwiper from "../../views/workspace/clirnet/sponsored-content/components/TableHeaderSwiper";
import { findIcon } from "../../views/dashboard/icons";
import Skeleton from "../skeleton";
import PageNotFound from "../../views/PageNotFound";

function LineChart({
  seriesName = "Viewed Time (Mins)",
  series,
  labels,
  height = 300,
  markerSize,
  chartTitle,
  activeCampaignGroup,
  setActiveCampaignGroup,
  sponsoredContentList,
  loading = false,
  hasData = true,
  showActionButtons = true,
}) {
  const chartRef = useRef(null);

  async function downloadPNG() {
    const chartInstance = chartRef.current;

    console.log("chartInstance ", chartInstance);
    const base64 = await chartInstance.chart.dataURI();
    console.log("base 64", base64.imgURI);
    const downloadLink = document.createElement("a");
    downloadLink.href = base64.imgURI;
    downloadLink.download = "image.png";

    // Add the anchor element to the document
    document.body.appendChild(downloadLink);

    // Simulate a click event to initiate the download
    downloadLink.click();

    // Remove the anchor element from the document
    document.body.removeChild(downloadLink);
  }

  function exportCSV() {
    // Initialize csvContent with headers
    let csvContent = "Date,Value\n";

    // Iterate over labels and series to build rows
    labels.forEach((category, index) => {
      let row = [category, series[index]];
      csvContent += row.join(",") + "\n";
    });

    // labels.forEach((category, index) => {
    //   let row = [category, series1[index], series2[index]];
    //   csvContent += row.join(",") + "\n";
    // });

    // Create a blob and download the CSV file
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "chart_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function downloadChart() {
    // Find the ApexCharts instance by ID
    const chartInstance = chartRef.current; //window.Apex._chartInstances.find(chart => chart.id === chartId);

    if (chartInstance) {
      // Download chart as PNG
      downloadPNG(chartInstance);

      // Export chart data as CSV
      // exportCSV(chartInstance);
    } else {
      console.error(`Chart not found.`);
    }
  }

  const chartOptions = {
    series: [
      {
        name: seriesName,
        data: series || [],
      },
    ],
    colors: ["#000000"],
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      // curve: "smooth",
    },
    markers: {
      size: markerSize || 0,
    },

    labels: labels || [],
    xaxis: {
      categories: labels || [],
      tickAmount: 5,
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
      },
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  return (
    <>
      <Card className="h-100 m-0 bg-light bg-opacity-25 shadow-none">
        <Card.Header>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h5 className="m-0 fs-6">{chartTitle}</h5>
            {hasData && showActionButtons && (
              <div className="d-flex flex-row gap-3">
                <div
                  className="text-success cursor-pointer "
                  onClick={downloadChart}
                >
                  {findIcon("DownloadReport", "dual-tone", "18")}
                  <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                    Download Report
                  </span>
                </div>
                <div
                  className="text-success cursor-pointer "
                  onClick={exportCSV}
                >
                  {findIcon("ExportReport", "dual-tone", "20")}
                  <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                    Export
                  </span>
                </div>
              </div>
            )}
          </div>
          {sponsoredContentList &&
            activeCampaignGroup &&
            setActiveCampaignGroup && (
              <TableHeaderSwiper
                title={sponsoredContentList}
                activeCampaignGroup={activeCampaignGroup}
                setActiveCampaignGroup={setActiveCampaignGroup}
              />
            )}
        </Card.Header>
        <div className="d-flex flex-column">
          <Card.Body className="p-0">
            {!loading ? (
              hasData ? (
                <div>
                  <div id="chart-basic-column-bar">
                    <Chart
                      ref={chartRef}
                      options={chartOptions}
                      series={chartOptions.series}
                      type="line"
                      height={height}
                    />
                  </div>
                </div>
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )
            ) : (
              <div className="p-3">
                <Skeleton
                  variant="square"
                  width={"100%"}
                  height={300}
                  className="rounded-3"
                />
              </div>
            )}
          </Card.Body>
        </div>
      </Card>
    </>
  );
}

export default LineChart;
