import React from "react";
import Default from "../../../../layouts/dashboard/default";
import OnboardingListing from "../pages/onboarding-listing";
// import OnboardingManager from "../pages/onboarding-approval-listing";
import OnboardingTasksListing from "../pages/onboarding-task-listing";
import OnboardingTaskDetails from "../pages/onboarding-task-details";
import OnboardingManagerApprovalListing from "../pages/onboarding-approval-listing";
import OnboardingProfileDetails from "../pages/onboarding-profile-details";
import OnboardingManager from "../pages/onboarding-manager";
import OnboardingDashboard from "../pages/onboarding-dashboard";

export const OnboardingRouter = [
  {
    path: "onboarding",
    element: <Default />,
    children: [
      {
        path: "listing",
        element: <OnboardingListing />,
      },
      {
        path: "dashboard",
        element: <OnboardingDashboard />,
      },
      {
        path: "manager",
        element: <OnboardingManager />,
      },
      {
        path: "onboarding-task-Approval-listing",
        element: <OnboardingManagerApprovalListing />,
      },
      {
        path: "onboarding-task-profile-details",
        element: <OnboardingProfileDetails />,
      },
      {
        path: "onboarding-task-details/:id",
        element: <OnboardingTaskDetails />,
      },
      {
        path: "onboarding-tasks",
        element: <OnboardingTasksListing />,
      },
    ],
  },
];
