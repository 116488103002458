import React from "react";
import Skeleton from "../../../../components/skeleton";

const PollSkeleton = () => {
  return (
    <div className="bg-light bg-opacity-25 rounded-3 p-3 my-4">
      <div className="d-flex justify-content-between">
        <div>
          <Skeleton width={90} height={50} />
        </div>
        <div className="d-flex gap-3 align-items-center">
          <Skeleton width={30} height={30} />
          <Skeleton width={30} height={30} />
        </div>
      </div>
      <Skeleton width={"100%"} height={30} />

      <Skeleton width={"100%"} height={40} animation="wave" />
      <Skeleton width={"100%"} height={40} animation="wave" />
      <Skeleton width={"100%"} height={40} animation="wave" />
      <Skeleton width={"100%"} height={40} animation="wave" />
    </div>
  );
};

export default PollSkeleton;
