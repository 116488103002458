//
export const KEY_SUMMARY_ANALYTICS = "KEY_SUMMARY_ANALYTICS";
export const KEY_SUMMARY_LIST = "KEY_SUMMARY_LIST";
export const KEY_SUMMARY_TAGS = "KEY_SUMMARY_TAGS";
export const KEY_SUMMARY_CONTENT_GRAPH = "KEY_SUMMARY_CONTENT_GRAPH";
export const KEY_SUMMARY_CONTENT_READ = "KEY_SUMMARY_CONTENT_READ";
export const KEY_SUMMARY_CTA_VIEW_READ = "KEY_SUMMARY_CTA_VIEW_READ ";
export const KEY_SUMMARY_LOCATION_MAP = "KEY_SUMMARY_LOCATION_MAP";
export const KEY_SUMMARY_DEVICE_DISTRIBUTION =
  "KEY_SUMMARY_DEVICE_DISTRIBUTION";
export const KEY_SUMMARY_ZONAL_DISTRIBUTION = "KEY_SUMMARY_ZONAL_DISTRIBUTION";

//
export const KEY_ANALYTICS_CONTENT_VIEWS = "KEY_ANALYTICS_CONTENT_VIEWS";
export const KEY_ANALYTICS_CONTENT_REACH = "KEY_ANALYTICS_CONTENT_REACH";
export const KEY_ANALYTICS_LOCATION_DISTRIBUTION =
  "KEY_ANALYTICS_LOCATION_DISTRIBUTION";
export const KEY_ANALYTICS_DEVICE_VIEWS = "KEY_ANALYTICS_DEVICE_VIEWS";
export const KEY_ANALYTICS_ZONE_VIEWS = "KEY_ANALYTICS_ZONE_VIEWS";
export const KEY_ANALYTICS_CONTENT_CATEGORY = "KEY_ANALYTICS_CONTENT_CATEGORY";
export const KEY_ANALYTICS_MOBILE_DEVICE_VIEWS =
  "KEY_ANALYTICS_MOBILE_DEVICE_VIEWS";
export const KEY_ANALYTICS_BROWSER_VIEWS = "KEY_ANALYTICS_BROWSER_VIEWS";

//
export const KEY_AUDIENCE_DAYWISE_PERFORMANCE =
  "KEY_AUDIENCE_DAYWISE_PERFORMANCE";
export const KEY_AUDIENCE_ZONAL_DISTRIBUTION =
  "KEY_AUDIENCE_ZONAL_DISTRIBUTION";
export const KEY_AUDIENCE_VIEWS_BY_SCREEN_SIZE =
  "KEY_AUDIENCE_VIEWS_BY_SCREEN_SIZE";
export const KEY_AUDIENCE_DEVICE_DISTRIBUTION =
  "KEY_AUDIENCE_DEVICE_DISTRIBUTION";
export const KEY_AUDIENCE_SPECIALITY_BREAKUP =
  "KEY_AUDIENCE_SPECIALITY_BREAKUP";
export const KEY_AUDIENCE_SPECIALITIES_REPORT =
  "KEY_AUDIENCE_SPECIALITIES_REPORT";
export const KEY_AUDIENCE_SPECIALITIES_REPORT_CTA =
  "KEY_AUDIENCE_SPECIALITIES_REPORT_CTA";

//
export const KEY_LOCATION_MAP_DISTRIBUTION = "KEY_LOCATION_MAP_DISTRIBUTION";
export const KEY_LOCATION_MAJOR_CITIES = "KEY_LOCATION_MAJOR_CITIES";
export const KEY_LOCATION_ZONAL_DISTRIBUTION =
  "KEY_LOCATION_ZONAL_DISTRIBUTION";
export const KEY_LOCATION_REPORT = "KEY_LOCATION_REPORT";
export const KEY_LOCATION_REPORT_CTA = "KEY_LOCATION_REPORT_CTA";

export const KEY_CONTENT_LIST = "KEY_CONTENT_LIST";
export const KEY_CONTENT_LIST_P2 = "KEY_CONTENT_LIST_P2";
export const KEY_CONTENT_LIST_P3 = "KEY_CONTENT_LIST_P3";
export const CREATIVE_DETAILS = "CREATIVE_DETAILS";
