import React from 'react'
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";


function ChannelCreateCampaignMeta() {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
    <Row>
      <Col sm={3}>
        <Nav variant="pills" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="first">
              <svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="icon-32"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.3992 9.14611L21.1194 9.86611C21.6897 10.4261 21.9998 11.1861 21.9998 11.9861C22.0098 12.7861 21.6997 13.5471 21.1395 14.1161C21.1328 14.1234 21.1261 14.1299 21.1194 14.1363C21.1161 14.1396 21.1128 14.1428 21.1094 14.1461L20.3992 14.8561C20.1191 15.1361 19.959 15.5161 19.959 15.9171V16.9461C19.959 18.6061 18.6085 19.9571 16.9479 19.9571H15.9176C15.5174 19.9571 15.1373 20.1161 14.8572 20.3961L14.1369 21.1161C13.5467 21.7071 12.7764 21.9961 12.0061 21.9961C11.2359 21.9961 10.4656 21.7071 9.87537 21.1271L9.14511 20.3961C8.865 20.1161 8.48487 19.9571 8.08472 19.9571H7.05435C5.39375 19.9571 4.04326 18.6061 4.04326 16.9461V15.9171C4.04326 15.5161 3.8832 15.1361 3.6031 14.8461L2.88284 14.1361C1.71241 12.9671 1.70241 11.0561 2.87283 9.87711L3.6031 9.14611C3.8832 8.86611 4.04326 8.48611 4.04326 8.07611V7.05611C4.04326 5.39611 5.39375 4.04711 7.05435 4.04711H8.08472C8.48487 4.04711 8.865 3.88611 9.14511 3.60611L9.86537 2.88611C11.0358 1.70711 12.9465 1.70711 14.1269 2.87711L14.8572 3.60611C15.1373 3.88611 15.5174 4.04711 15.9176 4.04711H16.9479C18.6085 4.04711 19.959 5.39611 19.959 7.05611V8.08711C19.959 8.48611 20.1191 8.86611 20.3992 9.14611ZM9.42521 15.4461C9.66529 15.4461 9.88537 15.3561 10.0454 15.1861L15.1873 10.0471C15.5274 9.70711 15.5274 9.14611 15.1873 8.80611C14.8472 8.46711 14.297 8.46711 13.9569 8.80611L8.81499 13.9461C8.47486 14.2861 8.47486 14.8461 8.81499 15.1861C8.97504 15.3561 9.19512 15.4461 9.42521 15.4461ZM13.6968 14.5661C13.6968 15.0561 14.0869 15.4461 14.5771 15.4461C15.0572 15.4461 15.4474 15.0561 15.4474 14.5661C15.4474 14.0871 15.0572 13.6961 14.5771 13.6961C14.0869 13.6961 13.6968 14.0871 13.6968 14.5661ZM9.43521 8.55611C9.91539 8.55611 10.3055 8.94611 10.3055 9.42611C10.3055 9.91711 9.91539 10.3061 9.43521 10.3061C8.95504 10.3061 8.55489 9.91711 8.55489 9.42611C8.55489 8.94611 8.95504 8.55611 9.43521 8.55611Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">
              {" "}
              <svg
                width="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7695 11.6453C19.039 10.7923 18.7071 10.0531 18.7071 8.79716V8.37013C18.7071 6.73354 18.3304 5.67907 17.5115 4.62459C16.2493 2.98699 14.1244 2 12.0442 2H11.9558C9.91935 2 7.86106 2.94167 6.577 4.5128C5.71333 5.58842 5.29293 6.68822 5.29293 8.37013V8.79716C5.29293 10.0531 4.98284 10.7923 4.23049 11.6453C3.67691 12.2738 3.5 13.0815 3.5 13.9557C3.5 14.8309 3.78723 15.6598 4.36367 16.3336C5.11602 17.1413 6.17846 17.6569 7.26375 17.7466C8.83505 17.9258 10.4063 17.9933 12.0005 17.9933C13.5937 17.9933 15.165 17.8805 16.7372 17.7466C17.8215 17.6569 18.884 17.1413 19.6363 16.3336C20.2118 15.6598 20.5 14.8309 20.5 13.9557C20.5 13.0815 20.3231 12.2738 19.7695 11.6453Z"
                  fill="currentColor"
                ></path>
                <path
                  opacity="0.4"
                  d="M14.0088 19.2283C13.5088 19.1215 10.4627 19.1215 9.96275 19.2283C9.53539 19.327 9.07324 19.5566 9.07324 20.0602C9.09809 20.5406 9.37935 20.9646 9.76895 21.2335L9.76795 21.2345C10.2718 21.6273 10.8632 21.877 11.4824 21.9667C11.8123 22.012 12.1482 22.01 12.4901 21.9667C13.1083 21.877 13.6997 21.6273 14.2036 21.2345L14.2026 21.2335C14.5922 20.9646 14.8734 20.5406 14.8983 20.0602C14.8983 19.5566 14.4361 19.327 14.0088 19.2283Z"
                  fill="currentColor"
                ></path>
              </svg>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Col>
      <Col sm={9}>
        <Tab.Content>
          <Tab.Pane eventKey="first">First tab content</Tab.Pane>
          <Tab.Pane eventKey="second">Second tab content</Tab.Pane>
        </Tab.Content>
      </Col>
    </Row>
  </Tab.Container>
  )
}

export default ChannelCreateCampaignMeta