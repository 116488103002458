import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function AnalyticsSkeleton({ navSkeletonCount }) {
  return (
    <Card>
      {/* <div className="channel-dropdown position-absolute end-0 px-2 pt-1 bg-white rounded-3 w-auto">
        <Skeleton variant="text" />
      </div> */}

      <Card.Body>
        <div>
          <div>
            <div className="mb-0 nav pb-3 nav-tabs w-100 gray-tabs">
              {<NavSkeletonTabs navSkeletonCount={navSkeletonCount} />}
            </div>
          </div>

          <div className="d-flex flex-column pt-3 pb-5 ">
            <Card.Body className="p-0">
              <div>
                <Skeleton
                  className="rounded-3"
                  variant="rect"
                  width="100%"
                  height={330}
                />
              </div>
            </Card.Body>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(AnalyticsSkeleton);

function NavSkeletonTabs({ navSkeletonCount }) {
  return Array.from({ length: navSkeletonCount }, (_, index) => (
    <div
      key={index}
      className=" flex-grow-1 flex-shrink-0 d-flex align-items-center justify-content-center border border-1"
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={100} />
        <Skeleton variant="text" width={100} />
      </div>
    </div>
  ));
}
