import { memo, Fragment, useEffect, useState } from "react";
// React-bootstrap
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.png";

import postService from "../../../../api/services/post-service";
import {
  RESET_PASSWORD,
  RESET_PASSWORD_POST,
} from "../../../../api/endpoints/auth-endpoints";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import app_config from "../../../../common/data/app_config";

const Resetpassword = memo(() => {
  const [loader, showLoader] = useState(false);

  const { redirectTo } = useRedirect();

  const initialValues = {
    email: "",
  };
  const schema = yup.object().shape({
    // email: yup.string().email().required("Please enter your email")
    email: yup
      .string()
      .required("Please enter your email")
      .test("Validate Email", "Please enter valid email", (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
  });
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, action) => {
      resetPassword(value, action);
    },
  });

  const resetPassword = async (value, action) => {
    showLoader(true);
    // console.log('my url==>', window.location);
    let base_url = window.location.href;
    let actualUrl = base_url.replace("reset-password", "change-password");
    // console.log('url==>', actualUrl);
    let data = {
      email: value.email,
      frontend_url: actualUrl,
    };

    let response = await postService(RESET_PASSWORD_POST, data);
    if (response.isError) {
      showLoader(false);
     toast.success(response?.error);
    } else {
      showLoader(false);
      // console.log('reset pass data', response.data);
      // redirectTo('dashboard')
     toast.success(
        "We have sent an email to your email address. Please check your email."
      );
      action.resetForm();
    }
  };

  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="Unity"
                className="ciplaLogo mb-2"
              />
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <div className="mb-2">
                    <h4>Reset Password</h4>
                    <p>
                      Enter your email address and we’ll send you an email with
                      instructions to reset your password
                    </p>
                  </div>
                  <Row>
                    <Form onSubmit={handleSubmit}>
                      <Col lg="12" className="mb-3">
                        <Form.Group>
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <Button
                          className="btn btn-primary w-100"
                          onClick={handleSubmit}
                        >
                          {loader ? <PaginationLoader /> : "Reset"}
                        </Button>
                      </Col>
                    </Form>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

Resetpassword.displayName = "Resetpassword";
export default Resetpassword;
