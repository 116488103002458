import React from "react";
import Summary from "../pages/Summary";
import EventsLayout from "../layouts/EventsLayout";
import LocationInsights from "../pages/LocationInsights";
import AudienceInsights from "../pages/AudienceInsights";
import Analytics from "../pages/Analytics";
import UserEngagement from "../pages/UserEngagement";

export const EventsRouter = [
  {
    path: "events",
    element: <EventsLayout />,
    children: [
      {
        path: "summary/:project_id",
        element: <Summary />,
      },
      {
        path: "user-engagement/:project_id",
        element: <UserEngagement />,
      },
      {
        path: "analytics/:project_id",
        element: <Analytics />,
      },
      {
        path: "audience-insights/:project_id",
        element: <AudienceInsights />,
      },
      {
        path: "location-insights/:project_id",
        element: <LocationInsights />,
      },
    ],
  },
];
