import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

function ProjectDetails() {
  let location = useLocation();
  const { channel_id } = useParams();

  console.log("eventKey", location.state.eventKey);
  console.log("channel_id", channel_id);

  const accordionData = [
    {
      eventKey: "1",
      header: "Add User",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      eventKey: "2",
      header: "View Contacts",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      eventKey: "3",
      header: "Details",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
  ];

  return (
    <Card>
      <Card.Body>
        <Accordion defaultActiveKey={location.state.eventKey}>
          {accordionData.map((item) => (
            <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body>{item.body}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Card.Body>
    </Card>
  );
}

export default ProjectDetails;
