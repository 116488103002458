import React, { useState, useRef, useCallback } from "react";
import { Card, Button } from "react-bootstrap";
import ReactTable from "../../../../../lib/table/react-table";
import { useParams } from "react-router-dom";
import { useGetNbaViewData } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";
import PageNotFound from "../../../../PageNotFound";
import { fallbackImages } from "../../../../../common/constants/fallback-images";

const NbaView = ({ hcpId }) => {
  const [nbaTotalViewCount, setnbaTotalViewCount] = useState(0);

  const payload = useRef({
    hcp_id: hcpId,

    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    filter: {},
    sort: {
      id: "desc",
    },
  });

  const nbaColumn = [
    // {
    //   Header: "Channel",
    //   accessor: "channel",
    //   // Filter: DefaultColumnFilter,
    // },
    {
      Header: "Content ID",
      accessor: "content_id",
      // Filter: DefaultColumnFilter,
    },
    // {
    //   Header: "Content Type",
    //   accessor: "content_type",
    //   // Filter: DefaultColumnFilter,
    // },
    {
      Header: "Content Category Name",
      accessor: "content_category",
      disableFilters: true,
      // Filter: DefaultColumnFilter,
      Cell: ({ value }) => {
        return value.content_category_name ?? "";
      },
    },
    {
      Header: "Title",
      accessor: "title",
      disableFilters: true,
      // Filter: DefaultColumnFilter,
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   disableFilters: true,
    //   Cell: ({ row }) => (
    //     <div className="d-flex justify-content-center">
    //       <Button
    //         className="btn btn-icon btn-sm rounded-pill"
    //         to="#"
    //         role="button"
    //       >
    //         <span className="btn-inner">{row.original.actionDetails}</span>
    //       </Button>
    //     </div>
    //   ),

    //   // Filter: DefaultColumnFilter,
    // },
  ];

  const onNbaSuccess = (res) => {
    if (res?.success) {
      setnbaTotalViewCount(res?.totalCount);
    }
  };

  const { data: nbaViewData, isLoading: isNbaViewDataLoading } =
    useGetNbaViewData(payload.current, hcpId, !!hcpId, onNbaSuccess);

  console.log("nbaViewData", nbaViewData);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">
            {isNbaViewDataLoading ? (
              <Skeleton width={100} height={40} />
            ) : (
              <>NBA View </>
            )}
          </h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100 overflow-auto">
          {!isNbaViewDataLoading && nbaViewData?.rows?.length > 0 ? (
            <ReactTable
              data={nbaViewData.rows}
              columns={nbaColumn}
              tdClass={"threeColTable"}
              thClass={"threeColTable"}
              // onTableStateChange={onTableStateChange}
              recordsTotal={nbaTotalViewCount}
              // initialState={payload.current}
              // isRowSelectionAvailable={true}
              // onRowSelectionChange={(selectedList) =>
              //   onRowSelectionChange(selectedList)
              // }
              // recordsPerPage={payload.pagination.limit}
            />
          ) : isNbaViewDataLoading ? (
            <Skeleton width={600} height={400} />
          ) : (
            <PageNotFound title="No NBA data found" isFullHeight={false} />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default NbaView;
