import shortLinkInstance from "../instances/short-link-instance";

const postShortLinkService = async (endpoint, data) => {
    try {
        let response = await shortLinkInstance({
            method: "POST",
            url: endpoint,
            data,
        });
        if (response) {
            // console.log("POST_SERVICE_RESPONSE", response);
            const responseData = response.data ?? [];

            let errorMessage = "";
            if (
                response?.data?.errors &&
                Object.keys(response?.data?.errors)?.length > 0
            ) {
                errorMessage = "";
                for (const eachErr of Object.values(response?.data?.errors)) {
                    errorMessage += eachErr[0] + " ";
                }
            }
            // console.log("POST_SERVICE_ERROR_MESSAGE", errorMessage);

            switch (response.status) {
                case 200:
                    return { data: responseData, isError: false, error: "" };
                case 201:
                    return { data: responseData, isError: false, error: "" };
                case 203:
                    return { data: responseData, isError: false, error: errorMessage || "" };
                case 204:
                    return { data: responseData, isError: false, error: errorMessage || "" };

                default:
                    return {
                        data: null,
                        isError: true,
                        error: errorMessage || "Something went wrong. Please try again",
                    };
            }
        }
    } catch (error) {
        console.log("POST_SERVICE_ERROR", error.response);
        let errorMessage = "Something went wrong. Please try again";
        if (
            error?.response?.data?.errors &&
            Object.keys(error?.response?.data?.errors)?.length > 0
        ) {
            errorMessage = "";
            //concatinate the Object.values(error?.response?.data?.errors)
            for (const eachErr of Object.values(error?.response?.data?.errors)) {
                errorMessage += eachErr[0] + " ";
            }
        }
        switch (error.response.status) {
            case 400:
                return {
                    data: null,
                    isError: true,
                    error: errorMessage,
                };
            case 401:
                return {
                    data: null,
                    isError: true,
                    error: "Please check your credentials and try again",
                };
            case 403:
                return {
                    data: null,
                    isError: true,
                    error: errorMessage,
                };
            case 404:
                return {
                    data: null,
                    isError: true,
                    error: errorMessage,
                };
            case 422:
                return {
                    data: null,
                    isError: true,
                    error: errorMessage,
                };
            case 500:
                return {
                    data: null,
                    isError: true,
                    error: errorMessage,
                };

            default:
                return {
                    data: null,
                    isError: true,
                    error: errorMessage,
                };
        }
    }
};
export default postShortLinkService;
