import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import toast from "react-hot-toast";
import { ADD_PROJECT } from "../../../../../api/endpoints/channel-endpoint";
import { RESOURCES_FILE_UPLOAD } from "../../../../../api/endpoints/help-and-support-endpoints";
import postService from "../../../../../api/services/post-service";
import uploadService from "../../../../../api/services/upload-service";
import useBackdrop from "../../../../../components/custom-hooks/use-backdrop";
import useDebounce from "../../../../../components/custom-hooks/use-debounce";
import { GET_PROJECT_LIST } from "../../../../../queries/query-constants/workspace/workspace-constant";
import {
  useCreateProject,
  useEditProjectDetails,
  useGetContentCategoryList,
  useGetProjectDetails,
  useGetSearchData,
} from "../../../../../queries/query-hooks/workspace/workspace-hook";
import PageNotFound from "../../../../PageNotFound";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { useParams } from "react-router-dom";
import FilePicker from "../../../../../components/form-components/file-picker";
import BrandAdsForm from "../components/BrandAdsForm";
import SponsoredContentForm from "../components/SponsoredContentForm";
import EventsForm from "../components/EventsForm";

function CreateProject({ project_group_id }) {
  const { data } = useCreateProject();
  const [projectType, setProjectType] = useState("");
  const { project_id } = useParams();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useEditProjectDetails(project_id);

  useEffect(() => {
    if (
      projectDetails &&
      projectDetails?.project_data &&
      projectDetails?.project_data.length > 0 &&
      "project_type_id" in projectDetails?.project_data[0]
    ) {
      setProjectType(projectDetails?.project_data[0].project_type_id);
    }
    if (project_id && isProjectDetailsLoading) {
      showBackdrop();
    } else {
      hideBackdrop();
    }
  }, [projectDetails]);

  return (
    <Container>
      <Row>
        <Col className="p-0 p-md-3 pt-md-0">
          <h4 className="mb-4">Create a New Project</h4>
          <Card>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label controlId="projectType">
                  {" "}
                  Select Project type{" "}
                </Form.Label>
                <Form.Select
                  id="projectType"
                  value={projectType}
                  onChange={(e) => {
                    setProjectType(e.target.value);
                  }}
                  disabled={!!project_id}
                >
                  <option>Select Project type</option>
                  {data &&
                    data?.project_type_masters &&
                    Array.isArray(data?.project_type_masters) &&
                    data?.project_type_masters.length > 0 &&
                    data?.project_type_masters.map((item) => (
                      <option key={item.id} value={item?.id}>
                        {item.project_type_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              {projectType != "" && (
                <RenderForm
                  projectType={
                    projectType != ""
                      ? data?.project_type_masters.filter(
                          (_item) => _item?.id == projectType
                        )[0]
                      : {}
                  }
                  project_group_id={project_group_id}
                  projectDetails={projectDetails?.project_data}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

function RenderForm({ projectType, project_group_id, projectDetails }) {
  const project_type_code = projectType?.project_type_code || "";
  const project_type_id = projectType?.id;

  switch (project_type_code) {
    case "brand_ads":
      return (
        <BrandAdsForm
          project_group_id={project_group_id}
          project_type_id={project_type_id}
          project_type_code={project_type_code}
          projectDetails={projectDetails}
        />
      );
    case "sponsored_content":
      return (
        <SponsoredContentForm
          project_group_id={project_group_id}
          project_type_id={project_type_id}
          project_type_code={project_type_code}
          projectDetails={projectDetails}
        />
      );
    // case "events":
    //   return (
    //     <EventsForm
    //       project_group_id={project_group_id}
    //       project_type_id={project_type_id}
    //       project_type_code={project_type_code}
    //       projectDetails={projectDetails}
    //     />
    //   );
    default:
      return (
        <PageNotFound title="Currently,we are unable to handle this request" />
      );
  }
}

export default CreateProject;
