import axios from "axios";

const searchService = async (endPoint, data) => {
  try {
    let response = await axios.post(
      `https://asia-south1-clirnet-dev.cloudfunctions.net/${endPoint}`,
      data
    );

    if (response) {
      console.log("response", response);
      return response;
    } else {
      return {
        status: 500,
      };
    }
  } catch {
    return {
      status: 500,
    };
  }
};
export default searchService;
