import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const AddUserLoader = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row>
                <Col lg="12" className="mb-3">
                  <Skeleton variant="text" width="15%" height={20} />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
                <Col lg="12" className="mb-3">
                  <Skeleton variant="text" width="15%" height={20} />
                </Col>
                <Col lg="6" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
                <Col lg="6" className="mb-4">
                  <Skeleton variant="rounded" width="100%" height={45} />
                </Col>
                <Col lg="12" className="mb-3">
                  <Skeleton variant="text" width="15%" height={20} />
                </Col>
                <Col lg="6" className="mb-4">
                  <div className="d-flex w-100">
                    <Skeleton variant="rounded" width="100%" height={45} />
                  </div>
                </Col>

                <Col lg="12" className="mb-3">
                  <Skeleton variant="text" width="15%" height={20} />
                </Col>
                {[1, 2, 3, 4, 5, 6].map((_r) => (
                  <div className="d-flex justify-content-start align-items-center gap-2 mb-2">
                    <Skeleton variant="circular" width={15} height={15} />
                    <Skeleton variant="text" width={70} height={20} />
                  </div>
                ))}

                <Col lg="12" className="d-flex justify-content-end">
                  <Skeleton
                    variant="rounded"
                    width="10%"
                    height={40}
                    className="mb-4"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddUserLoader;
