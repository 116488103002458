import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton from "../../../../components/skeleton";

function ChannelListSkeleton() {
  return (
    <Row>
      <div className="pb-4">
        <Skeleton variant="text" width="10%" />
      </div>

      {[...Array(12)].map((_, index) => (
        <Col md={4} lg={4} key={index} >
          <div className="bg-white d-flex rounded-3 p-3 gap-3 mb-5">
            <Skeleton
              variant="square"
              width={20}
              height={20}
              className="rounded-2"
            />

            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-column gap-2">
                <Skeleton variant="text" className="mb-1" width="40%" />
                <Skeleton variant="text" className="mb-1" width="40%" />
              </div>
              <div className="d-flex justify-content-between mt-2">
                <Skeleton variant="text" className="mb-1" width="40%" />
                <Skeleton
                  variant="square"
                  className="rounded-2 mb-1"
                  width="25%"
                  height={20}
                />
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default memo(ChannelListSkeleton);
