export const TIMELINE_DATA = [
  {
    title: "Experienced Paediatrics Surgeon in Kolkata",
    engagement_date: "25 May, 2024",
    channel_name: "WhatsApp",
    template:
      "LIVE NOW - DERMATOLOGICAL INTERNATIONAL SPEAKER PROGRAM Dear Doctor, $ The Highly Anticipated ISP ON DERM DIALOGUE IS LIVE NOW! Join us for this Exclusive webinar featuring Prof. Martin Steinhoff from New York, USA. Click the Button below to JOIN NOW!",
    actions: [
      { name: "Touchpoint sent", value: 1300 },
      { name: "Delivered", value: 900 },
      { name: "Clicked", value: 200 },
    ],
  },
  {
    title: "Experienced Paediatrics Surgeon in Kolkata",
    engagement_date: "6 June, 2024",
    channel_name: "SMS",
    template:
      "LIVE NOW - DERMATOLOGICAL INTERNATIONAL SPEAKER PROGRAM Dear Doctor, $ The Highly Anticipated ISP ON DERM DIALOGUE IS LIVE NOW! Join us for this Exclusive webinar featuring Prof. Martin Steinhoff from New York, USA. Click the Button below to JOIN NOW!",
    actions: [
      { name: "Touchpoint sent", value: 1100 },
      { name: "Delivered", value: 800 },
      { name: "Clicked", value: 152 },
    ],
  },
  {
    title: "Experienced Paediatrics Surgeon in Kolkata",
    engagement_date: "14 June, 2024",
    channel_name: "Email",
    template:
      "LIVE NOW - DERMATOLOGICAL INTERNATIONAL SPEAKER PROGRAM Dear Doctor, $ The Highly Anticipated ISP ON DERM DIALOGUE IS LIVE NOW! Join us for this Exclusive webinar featuring Prof. Martin Steinhoff from New York, USA. Click the Button below to JOIN NOW!",
    actions: [
      { name: "Touchpoint sent", value: 1300 },
      { name: "Delivered", value: 900 },
      { name: "Clicked", value: 200 },
    ],
  },
  {
    title: "Pre-Session SMS Reminder: 1 Hour",
    engagement_date: "24 June, 2024",
    actions: [
      { name: "Touchpoint sent", value: 1300 },
      { name: "Delivered", value: 900 },
    ],
  },
  {
    title: "Pre-session Reminder Call : 15 Mins",
    engagement_date: "24 June, 2024",
    actions: [
      { name: "Reservation", value: 2000 },
      { name: "Reminder call made", value: 1000 },
    ],
  },
  {
    title: "Entry Poll",
    engagement_date: "24 June, 2024",
    entry_content:
      "Can the consumption of probiotic Bacillus clausii [o/c,n/R,SIN and Tstrains] assist in managing gut disorders?",
    actions: [{ name: "Participation", value: 560 }],
  },
  {
    title: "Session Started",
    engagement_date: "24 June, 2024",
    actions: [
      { name: "Reservation", value: 2000 },
      { name: "Reminder call made", value: 1000 },
    ],
  },
];

export const REGISTRATION_BY_TIME_SLOT = {
  series: [
    {
      name: "Jun 24, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Apr 22, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "May 22, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 33,
        },
        {
          x: "after_noon",
          y: 16.5,
        },
        {
          x: "evening",
          y: 16.5,
        },
      ],
    },
    {
      name: "May 24, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "May 28, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Jan 25, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 25,
        },
        {
          x: "evening",
          y: 75,
        },
      ],
    },
    {
      name: "Nov 18, 2023",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 100,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Nov 17, 2023",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 25,
        },
        {
          x: "evening",
          y: 25,
        },
      ],
    },
    {
      name: "Jan 08, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 25,
        },
        {
          x: "evening",
          y: 25,
        },
      ],
    },
    {
      name: "Jan 23, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 100,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Apr 04, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Apr 05, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "May 08, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "May 31, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Feb 08, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "May 11, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 50,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Jan 09, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Jul 12, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Jan 12, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "May 16, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Jan 17, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 16.5,
        },
        {
          x: "evening",
          y: 33.5,
        },
      ],
    },
    {
      name: "Jan 19, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Jan 22, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 100,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Jan 24, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 0,
        },
        {
          x: "evening",
          y: 50,
        },
      ],
    },
    {
      name: "Jan 31, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Feb 20, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
    {
      name: "Mar 29, 2024",
      data: [
        {
          x: "night",
          y: 0,
        },
        {
          x: "morning",
          y: 0,
        },
        {
          x: "noon",
          y: 0,
        },
        {
          x: "after_noon",
          y: 50,
        },
        {
          x: "evening",
          y: 0,
        },
      ],
    },
  ],
};

export const REGISTRATION_BY_ZONE = [
  {
    x: "Assam",
    y: 218,
  },
  {
    x: "Kolkata",
    y: 149,
  },
  {
    x: "Mumbai",
    y: 184,
  },
  {
    x: "Ahmedabad",
    y: 55,
  },
  {
    x: "Bangaluru",
    y: 84,
  },
  {
    x: "Pune",
    y: 31,
  },
  {
    x: "Chennai",
    y: 70,
  },
];

export const REGISTRATION_BY_TIME_SLOT_POLAR = {
  labels: ["Morning", "Noon", "Afternoon", "Evening", "Night"],
  series: [40, 30, 20, 60, 25],
};

export const REGISTRATION_BY_DEVICE = {
  labels: ["Desktop", "Mobile", "Other"],
  series: [40, 30, 20],
};

export const INDIA_MAP_DATA = [
  {
    "Andaman & Nicobar Island": 150,
    "Andhra Pradesh": 470,
    "Arunanchal Pradesh": 248,
    Assam: 528,
    Bihar: 755,
    Chandigarh: 95,
    Chhattisgarh: 1700,
    Delhi: 1823,
    Goa: 508,
    Gujarat: 624,
    Haryana: 1244,
    "Himachal Pradesh": 640,
    "Jammu & Kashmir": 566,
    Jharkhand: 814,
    Karnataka: 2482,
    Kerala: 899,
    Lakshadweep: 15,
    "Madhya Pradesh": 1176,
    Maharashtra: 727,
    Manipur: 314,
    Meghalaya: 273,
    Mizoram: 306,
    Nagaland: 374,
    Odisha: 395,
    Puducherry: 245,
    Punjab: 786,
    Rajasthan: 1819,
    Sikkim: 152,
    "Tamil Nadu": 2296,
    Telangana: 467,
    Tripura: 194,
    "Uttar Pradesh": 2944,
    Uttarakhand: 1439,
    "West Bengal": 1321,
  },
];

export const MAJOR_CITIES = [
  { name: "Assam" },
  { name: "Bihar" },
  { name: "Chandigarh" },
  { name: "Chhattisgarh" },
  { name: "Delhi" },
  { name: "Goa" },
  { name: "Gujarat" },
  { name: "Haryana" },
];

export const VIEWERSHIP_WISE_ATTENDEE_SEGMENTATION = {
  labels: [
    "00:04:00",
    "00:16:00",
    "00:20:00",
    "00:35:00",
    "00:42:00",
    "00.51.00",
    "00.59.00",
    "01:20:00",
    "01:30:00",
  ],
  series: [
    {
      name: "viewership",
      data: [10, 20, 15, 50, 40, 70, 21, 34, 14],
      color: "#15946C",
    },
  ],
};

export const VIEWERSHIP_WISE_ANALYTICS_LEFT = {
  series: [
    {
      name: "View Count",
      data: [
        200, 500, 1000, 1500, 2000, 1500, 100, 200, 100, 500, 1000, 100, 200,
        400, 500, 1500, 300, 100, 200, 1500, 2000, 1500, 200, 500, 100,
      ],
      color: "#3256b8",
    },
  ],
  label: [
    10, 20, 15, 50, 40, 70, 21, 34, 14, 10, 20, 15, 50, 40, 70, 21, 34, 14,
  ],
};

export const VIEWERSHIP_WISE_ANALYTICS_RIGHT = {
  series: [
    {
      name: "View Count",
      data: [
        200, 500, 1000, 1500, 2000, 1500, 100, 200, 100, 500, 1000, 100, 200,
        400, 500, 1500, 300, 100, 200, 1500, 2000, 1500, 200, 500, 100,
      ],
      color: "#15946C",
    },
  ],
  label: [10, 20, 15, 50, 40, 70, 21, 34, 14],
};

export const EVENTS_PILL_FILTERS = [
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Client",
  },
  {
    id: 2,
    name: "Server",
  },
  {
    id: 3,
    name: "Database",
  },
];

export const EVENTS_DROPDOWN_FILTERS = ["Registrations", "Participations"];

export const LIVE_POLL_DATA = [
  {
    id: 1,
    points: 50,
    question:
      "Can the consumption of probiotic Bacillus clausii [o/c,N/R,SIN and T strains] assist in managing gut disorders?",
    options: [
      {
        id: 1,
        value: "Yes",
      },
      {
        id: 2,
        value: "No",
      },
      {
        id: 3,
        value: "Maybe",
      },
      {
        id: 4,
        value: "Interested to know more",
      },
    ],
  },
  {
    id: 2,
    points: 30,
    question:
      "Which treatment option do you most commonly advise as a first-line approach for acute pain non-low back musculoskeletal injuries?",
    options: [
      {
        id: 1,
        value: "Social Media",
      },
      {
        id: 2,
        value: "Email",
      },
      {
        id: 3,
        value: "Friends",
      },
      {
        id: 4,
        value: "Others",
      },
    ],
  },
];

export const FULL_STACKED_BAR_DATA = [
  {
    name: "Yes",
    data: [72],
  },
  {
    name: "No",
    data: [2],
  },
  {
    name: "Maybe",
    data: [10],
  },
  {
    name: "Interested to know more",
    data: [16],
  },
];

export const AUDIENCE_INSIGHTS_TABLE_DATA = [
  {
    participant_id: 353,
    session_id: 2807,
    name: "Dr.ron json",
    reg_body: "",
    reg_id: "sdds",
    city: "",
    speciality: "EC speciality",
    time: "838:59:59",
    state: "West Bengal",
    attend_status: "Attended",
  },
  {
    participant_id: 408,
    session_id: 2807,
    name: "Dr.Poulomi Mukherjee",
    reg_body: "",
    reg_id: "",
    city: "kol",
    speciality: "EC speciality",
    time: "716:08:05",
    state: "",
    attend_status: "Attended",
  },
  {
    participant_id: 409,
    session_id: 2807,
    name: "Dr.Poulomi Mukherjee",
    reg_body: "",
    reg_id: "",
    city: "kol",
    speciality: "EC speciality",
    time: "00:00:01",
    state: "",
    attend_status: "Attended",
  },
  {
    participant_id: 472,
    session_id: 2807,
    name: "Dr.Poulomi Mukherjee",
    reg_body: "Kerala",
    reg_id: "LKFGKFMG",
    city: "Thiruvananthapuram",
    speciality: "EC speciality",
    time: "11:49:42",
    state: "",
    attend_status: "Attended",
  },
  {
    participant_id: 480,
    session_id: 2807,
    name: "Dr.ron json",
    reg_body: "",
    reg_id: "sdds",
    city: "kolkata",
    speciality: "EC speciality",
    time: "00:00:18",
    state: "",
    attend_status: "Attended",
  },
  {
    session_id: 15149,
    ksp_participant_id: 1007439,
    time: null,
    name: null,
    country_code: null,
    country_name: null,
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: null,
  },
  {
    session_id: 15149,
    ksp_participant_id: 441663,
    time: null,
    name: null,
    country_code: 94,
    country_name: "Holy See (Vatican City State)",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: null,
  },
  {
    session_id: 15149,
    ksp_participant_id: 879,
    time: "02:26:36",
    name: "Anant  Sahu",
    country_code: 99,
    country_name: "India",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Attended",
    speciality: "Urology",
  },
  {
    session_id: 15278,
    ksp_participant_id: 96675,
    time: null,
    name: "Poulomi   Mukherjee",
    country_code: 99,
    country_name: "India",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: "Ophthalmology",
  },
  {
    session_id: 15278,
    ksp_participant_id: 4,
    time: null,
    name: "Ashu  Kasera",
    country_code: 99,
    country_name: "India",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: "Diabetology",
  },
  {
    session_id: 15278,
    ksp_participant_id: 4,
    time: null,
    name: "Ashu  Kasera",
    country_code: 99,
    country_name: "India",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: "Rheumatology",
  },
  {
    session_id: 15288,
    ksp_participant_id: 879,
    time: null,
    name: "Anant  Sahu",
    country_code: 99,
    country_name: "India",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: "Urology",
  },
  {
    session_id: 15288,
    ksp_participant_id: 100743,
    time: null,
    name: "Olivia  Dastidar",
    country_code: 99,
    country_name: "India",
    reg_body: "demo registration body",
    reg_id: "demo registratio id",
    state: "demo state",
    attend_status: "Not Attended",
    speciality: "General Surgery",
  },
];
