import { memo, Fragment, useEffect, useState } from "react";

// React-bootstrap
import { Row, Col, Form, Button, Container, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import { useLocation } from "react-router-dom";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import {
  GET_TWO_FACTOR_QR_CODE,
  POST_VERIFY_TWO_FACTOR,
  USER_PROFILE,
} from "../../../../api/endpoints/auth-endpoints";
import axios from "axios";
import app_config from "../../../../common/data/app_config";
import parse from "html-react-parser";
import toast from "react-hot-toast";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../../store/auth/actions";
import { getService } from "../../../../api/services/get-services";
import { MENU_LIST } from "../../../../api/endpoints/common-endpoints";

const Twofactor = memo(() => {
  const { state } = useLocation();
  const [svg, setSvg] = useState("");
  const { redirectTo } = useRedirect();
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  const getTwoFactorQR = async () => {
    let accessToken = `Bearer ${state.token}`;
    try {
      const response = await axios({
        method: "POST",
        url: app_config.api_url + GET_TWO_FACTOR_QR_CODE,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: accessToken,
        },
      });
      console.log(response);
      if (response.data.svg) {
        setSvg(response.data.svg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!state) {
      redirectTo("/");
    }
    getTwoFactorQR();
  }, []);
  function findFirstLeaf(menuArray) {
    try {
      for (let i = 0; i < menuArray.length; i++) {
        const menu = menuArray[i];
        if (menu.sub_menu.length === 0) {
          return menu;
        } else {
          const leaf = findFirstLeaf(menu.sub_menu);
          if (leaf) {
            return leaf;
          }
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  }
  const submitVerificationCode = async (e) => {
    e.preventDefault();
    if (!code || code.length < 6) {
      toast.error("Please enter valid code");
      return;
    }

    let accessToken = `Bearer ${state.token}`;
    try {
      const response = await axios({
        method: "POST",
        url: app_config.api_url + POST_VERIFY_TWO_FACTOR,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: accessToken,
        },
        data: {
          code,
        },
      });
      console.log(response);
      if (response.status === 200) {
        toast.success("Two factor verification successful");
        updateStorage(storages.local, "access_token", state.token);
        let userResponse = await getService(USER_PROFILE);
        let profile = userResponse.data?.data;
        console.log("profile", profile);
        //sync the values to store

        let menuResponse = await getService(MENU_LIST);
        if (menuResponse.isError) {
          toast.error(menuResponse?.error);
        } else {
          console.log("menuResponse", menuResponse?.data);
          let redirectUrl = findFirstLeaf(menuResponse?.data?.data)?.menu_code;
          dispatch(
            loginSuccess({
              user: profile || {},
              userRoles: profile?.user_has_permissions ?? [],
              token: state.token,
              isAuthenticated: true,
              redirectUrl: redirectUrl,
            })
          );

          localStorage.setItem("redirectUrl", redirectUrl);
          redirectTo(redirectUrl || "dashboard");
        }
      } else {
        if (response.data.message) {
          toast.error(response.data.message);
        } else {
          toast.error("Two factor verification failed");
        }
      }
    } catch (error) {
      console.log(error);
      let response = error.response;
      if (!response) {
        toast.error("Two factor verification failed");
        return;
      }
      if (response.data.message) {
        toast.error(response.data.message);
      } else {
        toast.error("Two factor verification failed");
      }
    }
  };
  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              {svg && (
                <div className="d-flex justify-content-center align-items-center">
                  {parse(svg)}
                </div>
              )}

              <h5 className="h5 lh-base mt-5">
                Scan the QR code using any Play Store authenticator like Google
                Authenticator. Enter the received 6-digit code in the provided
                input to complete setup. Contact support for assistance. Your
                account security matters to us. Thank you!
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <div className="mb-2">
                    <h4 className="pb-2">Two Factor - Verification</h4>
                    <p>
                      Enter the received 6-digit code in authenticator app to
                      complete setup.
                    </p>
                  </div>
                  <Form>
                    <Row>
                      <Col lg="12" className="mb-3">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="floatingInput1"
                            placeholder="Enter 6 digit Code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                          <Form.Label htmlFor="code">
                            Enter 6 digit Code
                          </Form.Label>
                        </Form.Floating>
                      </Col>
                      <Col lg="12" className="mb-3">
                        <Button
                          className="btn btn-primary w-100"
                          type="submit"
                          onClick={submitVerificationCode}
                        >
                          Verify
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

Twofactor.displayName = "Twofactor";
export default Twofactor;
