import React from "react";
import Chart from "react-apexcharts";

const FullStackedBar = ({ colors, series }) => {
  const options = {
    colors: colors,
    chart: {
      type: "bar",
      height: 100,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    grid: {
      show: false,
      padding: {
        right: 0,
        left: 0,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      x: false,
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={series}
        type="bar"
        height="120px"
        width="100%"
      />
    </div>
  );
};

export default FullStackedBar;
