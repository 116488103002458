import React, { Fragment } from "react";
import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { findIcon } from "../../../../../../dashboard/icons";

function CommentApproval({ commentCount }) {
  const { channel_id } = useParams();
  return (
    <Fragment>
      <div
        className="card p-3 m-0 d-flex flex-row gap-3 overflow-hidden border border-soft-primary h-100"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <div className="position-relative">
          <span className="text-primary icon-60 d-inline-flex align-items-center justify-content-center flex-shrink-0 mt-n1 ms-n1">
            {findIcon("Comment-notification", "outline", 40)}
          </span>
          <span className="h-rem-6 w-rem-6 position-absolute top-0 start-0 rounded-circle mt-n5 ms-n5 bg-opacity-25 bg-light"></span>
        </div>

        <div className="comment-details d-flex flex-column ps-4">
          <div className="d-flex align-items-center mb-2 gap-3">
            <h6 className="mb-0 text-capitalize fw-medium fs-6">
            Comments Awaiting Approval
            </h6>
            <span className="bg-primary text-white p-1 d-flex align-items-center justify-content-center rounded-circle px-2 fs-6 flex-shrink-0 fw-semibold">
              {commentCount > 0 ? commentCount : 0}
            </span>
          </div>
          <p className="small-text two-line-clamp">
            Approval required before publishing these comments
          </p>
          <Link
            to={`/channel/comments/${channel_id}`}
            className="text-decoration-underline ms-auto mt-auto"
          >
            See All{" "}
            <span className="transform-rotate-45">
              {findIcon("Arrow Up", "outline", 20)}
            </span>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default CommentApproval;
