import React, { useState } from "react";
import {
  Card,
  Col,
  FloatingLabel,
  Row,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { REQUEST_CONTENT_ADD } from "../../../../../../api/endpoints/channel-endpoint";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import postService from "../../../../../../api/services/post-service";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import FilePicker from "../../../../../../components/form-components/file-picker";
import {
  useGetTargetCategories,
  useGetTargetRegion,
} from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import uploadService from "../../../../../../api/services/upload-service";
import { FILE_UPLOAD } from "../../../../../../api/endpoints/requisition-endpoints";

export default function RequestContet() {
  const { channel_id } = useParams();
  const { redirectTo } = useRedirect();
  const [metaImage, setMetaImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { data: categoriesData } = useGetTargetCategories();
  const { video_categories } = categoriesData || { video_categories: [] };
  const { data: regionData } = useGetTargetRegion();
  const { video_geography } = regionData || { video_geography: [] };

  async function submitSupport(values, url) {
    let payloadObj = {
      support_name: values.content_title,
      support_description: values.description,
      support_path: url,
      support_attributes: {
        target_speciality: values.target_speciality,
        target_geography: values.target_geo,
        user_interest: values.user_interest,
      },
      support_priority_id: 1,
      support_module_id: 14,
      support_workflow_id: 7,
    };
    setIsLoading(true);
    let response = await postService(REQUEST_CONTENT_ADD, payloadObj);
    if (response.isError) {
      setIsLoading(false);
      toast.error(response.error);
    } else {
      setIsLoading(false);
      toast.success(
        "Your Reuest Send successfully, we will get back to you shortly!"
      );
      redirectTo("channel/dashboard/" + channel_id);
    }
  }

  const formik = useFormik({
    initialValues: {
      content_title: "",
      reference_file: "",
      description: "",
      user_interest: "",
      target_geo: "",
      target_speciality: "",
    },
    validationSchema: Yup.object({
      content_title: Yup.string().required("Content title is required "),
      reference_file: Yup.string(),
      description: Yup.string().required("Description is required"),
      user_interest: Yup.string().required("User interest is required"),
      target_speciality: Yup.string().required("select terget Specilaity"),
      target_geo: Yup.string(),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      if (values.reference_file) {
        const urls = await uploadFiles([values.reference_file]);
        if (urls?.length > 0) {
          submitSupport(values, urls[0]);
        }
      } else {
        submitSupport(values, null);
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    values,
    touched,
    errors,
    setValues,
    setFieldValue,
  } = formik;

  const onCancelClick = () => {
    redirectTo(`channel/dashboard/${channel_id}`);
  };

  const onReferenceFileSelect = (file) => {
    if (file.length > 0) {
      console.log("onReferenceFileSelect", file.length);
      setValues({ ...values, reference_file: file[0] });
    }
  };

  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(FILE_UPLOAD, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.url);

        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }

  console.log("reference  values", values);
  return (
    <>
      <h5 className="mb-4 fe-semibold">Request content</h5>
      <Card>
        <Card.Body className="d-flex flex-column gap-5">
          <Form className="h-100 d-flex flex-column">
            <Row>
              <Col sm={6} className="mb-3">
                {/* <h5 className="mb-3 fs-6">Content Title</h5> */}

                <FloatingLabel
                  controlId="title"
                  label="Video title"
                  className="mb-3 custom-form-floating "
                >
                  <Form.Control
                    type="text"
                    placeholder="Enter video title"
                    name="content_title"
                    onChange={handleChange}
                    value={values.content_title}
                    isInvalid={touched.content_title && !!errors.content_title}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.content_title}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col sm={6} className="mb-3">
                {/* <h5 className="mb-3 fs-6">User Interest</h5> */}

                <FloatingLabel
                  controlId="title"
                  label="User interest"
                  className="mb-3 custom-form-floating "
                >
                  <Form.Control
                    type="text"
                    placeholder="Write interest"
                    name="user_interest"
                    onChange={handleChange}
                    value={values.user_interest}
                    isInvalid={touched.user_interest && !!errors.user_interest}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.user_interest}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col sm={12} className="mb-3">
                {/* <h5 className="mb-3 fs-6">Description</h5> */}

                <FloatingLabel
                  controlId="title"
                  label="Description"
                  className="mb-3 custom-form-floating "
                >
                  <Form.Control
                    as={"textarea"}
                    rows={4}
                    placeholder="Please provide a detailed description"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    isInvalid={touched.description && !!errors.description}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              {/* <Col sm={12} className="mb-3">
                <h5 className="mb-3 fs-6">User Interest</h5>

                <FloatingLabel
                  controlId="title"
                  label="User interest"
                  className="mb-3 custom-form-floating "
                >
                  <Form.Control
                    type="text"
                    placeholder="Write interest"
                    name="user_interest"
                    onChange={handleChange}
                    value={values.user_interest}
                    isInvalid={touched.user_interest && !!errors.user_interest}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.user_interest}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col> */}
              <Col sm={6} className="mb-3">
                {/* <h5 className="mb-3 fs-6">Target Category</h5> */}
                <FloatingLabel
                  className="mb-3 custom-form-floating "
                  controlId="floatingSelect"
                  label="Select Target Categories"
                >
                  <Form.Select
                    aria-label="Floating label select"
                    name="target_speciality"
                    onChange={handleChange}
                    value={values.target_speciality}
                    isInvalid={
                      touched.target_speciality && !!errors.target_speciality
                    }
                  >
                    <option value="">Select Target Category</option>
                    {video_categories?.length > 0 &&
                      video_categories.map((data, key) => (
                        <option key={key} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {errors.target_speciality}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col sm={6} className="mb-3">
                {/* <h5 className="mb-3 fs-6">Target Geography</h5> */}

                <FloatingLabel
                  className="mb-3 custom-form-floating "
                  controlId="floatingSelect"
                  label="Works with selects"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="target_geo"
                    onChange={handleChange}
                    value={values.target_geo}
                    isInvalid={touched.target_geo && !!errors.target_geo}
                  >
                    <option>Select Target Region</option>
                    {video_geography?.length > 0 &&
                      video_geography.map((data, key) => (
                        <option key={key} value={data.id}>
                          {data.english}
                        </option>
                      ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {errors.target_geo}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col sm={6} className="mb-3">
                <FilePicker
                  title={"Add Reference File"}
                  // source={values.reference_file}
                  onUpdate={onReferenceFileSelect}
                  accepts="image/*"
                  type="image"
                />
              </Col>
              <Col sm={6} className="mb-3">
                {values.reference_file && (
                  <Image
                    className="w-100 h-rem-10 object-fit-contain"
                    alt="reference-files"
                    src={
                      values.reference_file
                        ? typeof values.reference_file === "string"
                          ? values.reference_file
                          : URL.createObjectURL(values.reference_file)
                        : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
                    }
                  />
                )}
              </Col>
              <Col xs={12} className="d-flex">
                <div className="ms-auto d-flex flex-wrap gap-2">
                  <Button variant="light" onClick={onCancelClick}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading}
                    variant="success"
                    onClick={handleSubmit}
                  >
                    {isLoading ? "Submiting..." : "Submit"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
