import React, { memo } from "react";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../../components/dropdowns";

import { useSearchParams } from "react-router-dom";
// import { dateFilterOptions } from "../Constant/date-filter-constant";

function DropDownMenu({
  className,
  constantFilterOption,
  handleDropdownChange,
  title = "",
  label,

}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialDateFilterOption = constantFilterOption.find(
    (item) => item.initial
  );
  const optionLabel = searchParams.has(title)
    ? constantFilterOption.find(
      (item) => item.value === searchParams.get(title)
    )
    : initialDateFilterOption;

  return (
    <Dropdown className={`d-flex justify-content-center ${className ?? ""}`}>
      <Dropdown.Toggle
        variant="text-secondary text-capitalize dropdown-toggle"
        as={CustomToggle}
      >
        {label ? label : optionLabel.label}
      </Dropdown.Toggle>
      <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
        {constantFilterOption.map((item, index) => (
          <Dropdown.Item
            key={index}
            className="text-capitalize"
            onClick={() => {
              handleDropdownChange(item.value, title);
            }}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(DropDownMenu);
