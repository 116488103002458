import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Row,
  Col,
  Button,
  Image,
  FormControl,
  Form,
  Pagination,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { findIcon } from "../../../../../../dashboard/icons";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { useGetAllVideoList } from "../../../../../../../queries/query-hooks/workspace/workspace-hook";
import { useSelector } from "react-redux";
import { currentChannel } from "../../../../../../../store/channel/channel.selector";
import useDebounce from "../../../../../../../components/custom-hooks/use-debounce";
import PaginationLoader from "../../../../../../../components/loader/pagination-loader";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
import PageNotFound from "../../../../../../PageNotFound";
import { selectProject } from "../../../../../../../store/project/project.selector";
const ChannelCard = ({ channelName, channelLogo, onContentSelect }) => {
  return (
    <>
      <Row>
        <Col md={8}>
          <div className="d-flex align-items-center gap-3 mt-3 mr-3">
            {
              <Image
                src={
                  channelLogo ??
                  "https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg"
                }
                roundedSquare
                style={{ height: "70px", width: "120px" }}
                fluid
                className="rounded-3"
              />
            }

            <div className="d-block">
              {channelName && (
                <h6>
                  {channelName} <span className="text-success">(Channel)</span>
                </h6>
              )}
            </div>
          </div>
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <Button variant="success" onClick={onContentSelect}>
            Create
          </Button>
        </Col>
      </Row>
    </>
  );
};

const VideoCard = ({ videoId, videoTitle, thumbnail, onContentSelect }) => {
  return (
    <>
      <Col md="12">
        <div className="d-flex align-items-center gap-3">
          <Image
            src={thumbnail ?? fallbackImages.noVideo}
            onError={(e) => {
              e.target.src = fallbackImages.noVideo;
            }}
            roundedSquare
            style={{ height: "70px" }}
            fluid
            className="rounded-3 flex-shrink-0 ratio-16x9 w-rem-8"
          />
          {videoTitle && (
            <p className="m-0 two-line-clamp flex-grow-1">{videoTitle}</p>
          )}
          <Button
            variant="success"
            onClick={() => onContentSelect(videoId)}
            className="flex-shrink-0 px-3"
          >
            Create
          </Button>
        </div>
      </Col>
    </>
  );
};

function ChannelCreateCampaignModal(props) {
  const [searchText, setSearchText] = useState("");
  const seachTerm = useDebounce(searchText, 3000);
  const project = useSelector(selectProject);
  const { channel_id } = useParams();
  const allVideoRef = useRef({
    default: "all",
    pagination: {
      limit: 3,
      offset: 0,
    },
    filter: {
      content_category_id: {
        type: "eq",
        value: "30",
      },
      content_publishing_status: {
        type: "eq",
        value: 3,
      },
      // ...selectedFilter,
    },
    join: {
      content_to_contents: {
        reletion: {
          prefix: "content_id",
          suffix: "content_child_id",
        },
        condition: {
          content_parent_id: {
            type: "eq",
            value: channel_id,
          },
        },
      },
    },
    sort: {
      content_id: "DESC",
    },
    project_id: project?.project_id,
    video_type: 1, //reel = 0 || video = 1
    parent_id: channel_id, //437//328
  });

  let selectedFilter = seachTerm
    ? {
        content_title: {
          type: "like",
          value: seachTerm,
        },
      }
    : {};
  const { data: videoList, isLoading: isChannelVideoLoading } =
    useGetAllVideoList({
      ...allVideoRef.current,
      filter: {
        ...allVideoRef.current.filter,
        ...selectedFilter,
      },
    });

  const { data: allVideoList, total: totalVideoCount } = videoList || {
    data: [],
    total: 0,
  };

  const { redirectTo } = useRedirect();

  const onSearchInput = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const onVideoContentSelect = (content_id) => {
    redirectTo("channel/video/campaign/" + content_id + "/create_campaign");
  };

  const onUploadFile = () => {
    redirectTo(`channel/file-upload/${channel_id}`);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="p-3"
    >
      <Modal.Body>
        <Row>
          <div className="d-flex justify-content-between align-items-center">
            {/* <h5 className="my-0 fw-semibold"> Create Campaign</h5> */}
            <h5 className="my-0 fw-semibold">Create Shortlink</h5>
            <span onClick={props.onHide} className="cursorPointer">
              {findIcon("Square X", "dual-tone", 25)}
            </span>
          </div>
          <div className="d-flex mt-3">
            <Form.Control
              type="text"
              placeholder="Search video"
              className="rounded-3"
              name="search_query"
              onChange={onSearchInput}
            />
          </div>
        </Row>
        {allVideoList?.length > 0 ? (
          <Row className="gap-3 mt-3">
            {allVideoList?.length > 0
              ? allVideoList.map((data, key) => (
                  <VideoCard
                    key={key}
                    videoId={data.content_id}
                    videoTitle={data.content_title}
                    thumbnail={
                      data?.video_image_data?.content_image_id?.thumbnail
                    }
                    onContentSelect={onVideoContentSelect}
                  />
                ))
              : null}
            {isChannelVideoLoading && <PaginationLoader color="#000" />}
          </Row>
        ) : isChannelVideoLoading ? (
          <PaginationLoader color="#000" />
        ) : (
          <PageNotFound
            title="No data found"
            backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ChannelCreateCampaignModal;
