export const KEY_EVENTS_SUMMARY_CONTENT_INFO =
  "KEY_EVENTS_SUMMARY_CONTENT_INFO";
export const KEY_EVENTS_CONTENT_VIEWS = "KEY_EVENTS_CONTENT_VIEWS";
export const KEY_EVENTS_CONTENT_REGISTRATION =
  "KEY_EVENTS_CONTENT_REGISTRATION";
export const KEY_EVENTS_LOCATION_DISTRIBUTION =
  "KEY_EVENTS_LOCATION_DISTRIBUTION";
export const KEY_EVENTS_DEVICE_DISTRIBUTION = "KEY_EVENTS_DEVICE_DISTRIBUTION";
export const KEY_EVENTS_ZONAL_DISTRIBUTION = "KEY_EVENTS_ZONAL_DISTRIBUTION";
export const KEY_EVENTS_OS_DISTRIBUTION = "KEY_EVENTS_OS_DISTRIBUTION";
export const KEY_EVENTS_BROWSER_DISTRIBUTION =
  "KEY_EVENTS_BROWSER_DISTRIBUTION";
export const KEY_EVENTS_DAYWISE_SLOT_DISTRIBUTION =
  "KEY_EVENTS_DAYWISE_SLOT_DISTRIBUTION";
export const KEY_EVENTS_REGISTRATION_BY_SLOT =
  "KEY_EVENTS_REGISTRATION_BY_SLOT";
export const KEY_EVENTS_VIEW_BY_SEGMENT = "KEY_EVENTS_VIEW_BY_SEGMENT";
export const KEY_GET_EVENTS_PARTICIPANT_LIST =
  "KEY_GET_EVENTS_PARTICIPANT_LIST";
export const KEY_GET_EVENTS_MAJOR_CITIES = "KEY_GET_EVENTS_MAJOR_CITIES";
export const KEY_GET_EVENTS_ALL_PROJECT_EVENTS_LIST =
  "KEY_GET_EVENTS_ALL_PROJECT_EVENTS_LIST";
export const KEY_GET_EVENTS_LOCATION_REPORT = "KEY_GET_EVENTS_LOCATION_REPORT";
export const KEY_GET_EVENTS_ENGAGEMENT_REPORT =
  "KEY_GET_EVENTS_ENGAGEMENT_REPORT";
export const KEY_GET_EVENTS_CONTENT_QUESTION =
  "KEY_GET_EVENTS_CONTENT_QUESTION";
export const KEY_GET_EVENTS_SPECIALITY_BREAKUP =
  "KEY_GET_EVENTS_SPECIALITY_BREAKUP";
export const KEY_GET_EVENTS_ALL_EVENTS_LIST = "KEY_GET_EVENTS_ALL_EVENTS_LIST";
