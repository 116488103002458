import { useState, memo, Fragment } from "react";
import { Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { findIcon } from "../../../../../dashboard/icons";
import HtmlParser from "../../../../../../lib/html-parser/react-html-parser";
export default function TemplateSuggestionCard({
  subject = "",
  body = "",
  click,
  id,
  selectedItem,
}) {
  console.log("TemplateSuggestionCard", selectedItem, id);
  return (
    <>
      {(subject || body || id) && (
        <Card
          onClick={() => click(id)}
          className={`shadow-none border transition ${
            selectedItem == id ? "bg-soft-primary border-primary" : ""
          }`}
          style={{ cursor: "pointer" }}
        >
          <Card.Header className="p-3  d-flex">
            {subject && (
              <h5 className="mb-0 flex-grow-1 d-flex align-items-center">
                <HtmlParser html={subject} />
              </h5>
            )}
            <Button
              variant={`${selectedItem == id ? "primary" : "soft-primary"}`}
              size="sm"
              className="rounded-circle btn-icon flex-shrink-0 ms-3"
            >
              <div className="btn-inner">
                {findIcon("Check", "outline", "16")}
              </div>
            </Button>
          </Card.Header>

          <Card.Body className="p-3 d-flex">
            {body && (
              <h6 className="mb-0 flex-grow-1 d-flex align-items-center">
                <HtmlParser html={body} />
              </h6>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
}
