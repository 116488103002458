import React, { useState } from "react";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import AnalyticsOverview from "../components/analytics/AnalyticsOverview";
import AnalyticsContent from "../components/analytics/AnalyticsContent";
import AnalyticsAudience from "../components/analytics/AnalyticsAudience";
import { useGetChannelAnalytics } from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import AnalyticsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/analytics-skeleton.jsx";
import AudienceSkeleton from "../../../../../../skeleton/workspace/doctube/channel/analytics-audience-skeleton";
import TabNavigation from "../components/TabNavigation";
import {
  audienceFilterOptions,
  dateFilterOptions,
} from "../Constant/date-filter-constant.js";
import PageNotFound from "../../../../../PageNotFound";
import { findIcon } from "../../../../../dashboard/icons";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import { useParams, useSearchParams } from "react-router-dom";
import { selectProject } from "../../../../../../store/project/project.selector";
import { useSelector } from "react-redux";

function ChannelAnalytics() {
  const { redirectTo } = useRedirect();
  const { channel_id } = useParams();

  const [selectedTab, setSelectedTab] = useState("overview");
  const project = useSelector(selectProject);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = dateFilterOptions.find(
    (item) => item.initial
  );
  const sortOption = searchParams.get("sort") || initialDateFilterOption.value;

  const { data: audienceData, isAudienceLoading } = useGetChannelAnalytics({
    channel_id: channel_id,
    type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
    analysis_type: "audience", // overview | reach | engagement | audience
    project_id: project?.project_id,
  });
  const { data: contentData, isLoading: isContentLoading } =
    useGetChannelAnalytics({
      channel_id: channel_id,
      type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
      analysis_type: "content", // overview | reach | engagement | audience
      project_id: project?.project_id,
    });

  const { data: overviewData, isLoading: isOverviewLoading } =
    useGetChannelAnalytics({
      channel_id: channel_id,
      type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
      analysis_type: "overview", // overview | reach | engagement | audience
      project_id: project?.project_id,
    });

  const onUploadFile = () => {
    redirectTo(`channel/file-upload/${channel_id}`);
  };

  const tabs = [
    {
      title: "Overview",
      eventKey: "overview",
      Content: () => (
        <>
          {!isContentLoading &&
          overviewData &&
          Object.keys(overviewData)?.length > 0 ? (
            <AnalyticsOverview data={overviewData} />
          ) : isContentLoading ? (
            <AnalyticsSkeleton navSkeletonCount={3} />
          ) : (
            <PageNotFound
              title="No data to analyze yet"
              buttonText="Upload Video"
              message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
              onFallbackClick={onUploadFile}
            />
          )}
        </>
      ),
    },
    {
      title: "Content",
      eventKey: "content",
      Content: () => (
        <>
          {!isContentLoading &&
          contentData &&
          Object.keys(contentData)?.length > 0 ? (
            <AnalyticsContent data={contentData} />
          ) : isContentLoading ? (
            <AnalyticsSkeleton navSkeletonCount={4} />
          ) : (
            <PageNotFound
              title="No data to analyze yet"
              buttonText="Upload Video"
              message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
              onFallbackClick={onUploadFile}
            />
          )}
        </>
      ),
    },
    {
      title: "Audience",
      eventKey: "audience",
      Content: () => (
        <>
          {!isAudienceLoading &&
          audienceData &&
          Object.values(audienceData)?.length > 0 ? (
            <AnalyticsAudience data={audienceData} />
          ) : isAudienceLoading ? (
            <AudienceSkeleton />
          ) : (
            <PageNotFound
              title="No data to analyze yet"
              buttonText="Upload Video"
              message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
              onFallbackClick={onUploadFile}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Col className="" md={12}>
        <h4 className="fw-semibold mb-0">Channel Analytics</h4>
      </Col>
      <TabNavigation
        tabs={tabs}
        withSorting
        sortOptions={
          selectedTab == "audience" ? audienceFilterOptions : dateFilterOptions
        }
        onSelect={(e) => setSelectedTab(e)}
      />
    </>
  );
}
export default ChannelAnalytics;
