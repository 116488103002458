import { Fragment, memo } from "react";

//react-bootstrap
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//router
import withAuthorization from "../../../components/hoc/with-authorization";

// img
const UserAdd = memo(() => {
  return (
    <>
      <Fragment>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">Add New Employee</h3>
          </div>
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Form>
                  <Row>
                    <Col lg="12" className="mb-3">
                      <h6 className="fs-6 fw-400 gray-300">Employee Details</h6>
                    </Col>
                    <Col lg="6" xl="3" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="name"
                          className=""
                          autoComplete="Name"
                          placeholder="Name"
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </Form.Floating>
                    </Col>
                    <Col lg="6" xl="3" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="name"
                          className=""
                          autoComplete="mobile no."
                          placeholder="Mobile no."
                        />
                        <label htmlFor="floatingInput">Mobile No.</label>
                      </Form.Floating>
                    </Col>
                    <Col lg="6" xl="3" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="name"
                          className=""
                          autoComplete="email"
                          placeholder="Email"
                        />
                        <label htmlFor="floatingInput">Email</label>
                      </Form.Floating>
                    </Col>
                    <Col lg="6" xl="3" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="name"
                          className=""
                          autoComplete="Employee Code"
                          placeholder="Employee Code"
                        />
                        <label htmlFor="floatingInput">Employee Code</label>
                      </Form.Floating>
                    </Col>
                    <Col lg="12" className="mb-3">
                      <h6 className="fs-6 fw-400 gray-300">
                        Department & Reporting
                      </h6>
                    </Col>
                    <Col lg="6" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select className="" id="floatingInput1">
                          {/* <option value="">Select Department</option> */}

                          <option value="Marketing">Marketing</option>

                          <option value="Medical">Medical</option>

                          <option value="Compliance">Compliance</option>
                          <option value="Vendor">Vendor</option>
                          <option value="Sales">Sales</option>
                          <option value="HQ">HQ</option>
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Department
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="6" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select className="" id="floatingInput1">
                          {/* <option value="">Select Reporting Manager</option> */}

                          <option value="Marketing">Arnab Saha</option>
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Reporting Manager
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="12" className="mb-3">
                      <h6 className="fs-6 fw-400 gray-300">Principal Entity</h6>
                    </Col>
                    <Col lg="6" className="mb-4">
                      <div className="d-flex w-100">
                        <Form.Floating className="custom-form-floating flex-grow-1">
                          <Form.Select className="" id="floatingInput1">
                            {/* <option value="">Select Reporting Manager</option> */}

                            <option value="Entity">Entity</option>
                            <option value="EntityRespiratory">
                              Entity &gt; Respiratory
                            </option>
                            <option value="budecort">
                              Entity &gt; Respiratory &gt; budecort
                            </option>
                            <option value="Montair">
                              Entity &gt; Montair FX
                            </option>
                            <option value="Diabetes">
                              Entity &gt; Diabetes
                            </option>
                            <option value="Glygard">
                              Entity &gt; Diabetes &gt; Glygard
                            </option>
                          </Form.Select>
                          <Form.Label htmlFor="floatingInput">
                            Select Reporting Manager
                          </Form.Label>
                        </Form.Floating>
                        <Button
                          type="button"
                          variant="btn btn-secondary flex-shrink-0 ms-3"
                        >
                          Add More
                        </Button>
                      </div>
                    </Col>

                    <Col lg="12" className="mb-3">
                      <h6 className="fs-6 fw-400 gray-300">Add User Role</h6>
                    </Col>
                    <Col lg="12" className="mb-4 d-flex flex-wrap">
                      <Form.Check className="form-check-inline d-flex align-items-center">
                        <FormCheck.Input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="customCheck5"
                        />
                        <FormCheck.Label
                          className="form-check-label ps-2"
                          htmlFor="customCheck5"
                        >
                          Content Creator
                        </FormCheck.Label>
                      </Form.Check>
                      <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 bg-soft-info rounded-3">
                        Will be able to add Content to Unity
                      </span>
                    </Col>
                    <Col lg="12" className="mb-4 d-flex flex-wrap">
                      <Form.Check className="form-check-inline d-flex align-items-center">
                        <FormCheck.Input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="customCheck5"
                        />
                        <FormCheck.Label
                          className="form-check-label ps-2"
                          htmlFor="customCheck5"
                        >
                          Content Approver
                        </FormCheck.Label>
                      </Form.Check>
                      <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 bg-soft-info rounded-3">
                        Will be able to Approve created Content
                      </span>
                    </Col>
                    <Col lg="12" className="mb-4 d-flex flex-wrap">
                      <Form.Check className="form-check-inline d-flex align-items-center">
                        <FormCheck.Input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="customCheck5"
                        />
                        <FormCheck.Label
                          className="form-check-label ps-2"
                          htmlFor="customCheck5"
                        >
                          Content Requisitor
                        </FormCheck.Label>
                      </Form.Check>
                      <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 bg-soft-info rounded-3">
                        Will be able to requisition for a Content
                      </span>
                    </Col>
                    <Col lg="12" className="mb-4 d-flex flex-wrap">
                      <Form.Check className="form-check-inline d-flex align-items-center">
                        <FormCheck.Input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="customCheck5"
                        />
                        <FormCheck.Label
                          className="form-check-label ps-2"
                          htmlFor="customCheck5"
                        >
                          Content Searcher
                        </FormCheck.Label>
                      </Form.Check>
                      <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 rounded-3 bg-soft-info">
                        Will be able to search for all Content in the system
                      </span>
                    </Col>
                    <Col lg="12" className="mb-4 d-flex flex-wrap">
                      <Form.Check className="form-check-inline d-flex align-items-center">
                        <FormCheck.Input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="customCheck5"
                        />
                        <FormCheck.Label
                          className="form-check-label ps-2"
                          htmlFor="customCheck5"
                        >
                          Content Searcher
                        </FormCheck.Label>
                      </Form.Check>
                      <span className="ms-3 mt-1 mt-md-0 fs-7 p-2 bg-soft-info rounded-3">
                        Will be able to search for all Content in the system
                      </span>
                    </Col>
                    <Col lg="12" className="mb-4 d-flex flex-wrap">
                      <Form.Check className="form-check-inline d-flex align-items-center">
                        <FormCheck.Input
                          type="checkbox"
                          className="form-check-input mt-0"
                          id="customCheck5"
                        />
                        <FormCheck.Label
                          className="form-check-label ps-2"
                          htmlFor="customCheck5"
                        >
                          Content Analytics
                        </FormCheck.Label>
                      </Form.Check>
                      <span className="ms-4 mt-2 mt-md-0 fs-7 p-2 bg-soft-info rounded-3">
                        Will be able to see Analytics of Content Usage
                      </span>
                    </Col>
                    <Col lg="12" className="d-flex justify-content-end">
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                        type="submit"
                      >
                        Add New User
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
});

UserAdd.displayName = "UserAdd";
export default withAuthorization(UserAdd);
