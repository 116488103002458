import React, { memo, useState } from 'react'

//react-bootstrap
import { Row, Col, Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom';



//components
import Card from '../../../components/bootstrap/card'
import { copyToClipboard } from '../../../utilities/dom';
import { outline_icons } from '.';
import withAuthorization from '../../../components/hoc/with-authorization';



const Outline = () => {
    const [tooltipText, setTooltipText] = useState('Copy')

    const [searchParams, setSearchParams] = useSearchParams();
    const searchdata = searchParams.get('q') || '';

    const handleSearch = event => {
        const q = event.target.value;
        if (q) {
            setSearchParams({ q });
        }
        else {
            setSearchParams({});
        }
    };
    const copyIcon = (value) => {
        copyToClipboard(value);

        setTooltipText('Copied!')
    }

    return (

        <Row>
            <Col lg="12">
                <Card className="mb-4">
                    <Card.Header>
                        <h4 className="card-title mb-0">Outlined</h4>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search"
                                className="me-2"
                                aria-label="Search"
                                id='search-value'
                                value={searchdata}
                                onChange={handleSearch}
                            />
                        </Form>
                    </Card.Header>
                    <Card.Body>
                        <div className="icon-grid">
                            {outline_icons(32)
                                .filter((solid) => solid.name.toLowerCase().includes(searchdata.toLowerCase()))
                                .map((icon) => (
                                    <div className="icon-box" key={icon.id}>
                                        <div
                                            id={icon.id}
                                            dangerouslySetInnerHTML={{ __html: icon.svgIcon }}
                                        ></div>
                                        <div className="overlay">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip >{tooltipText}</Tooltip>}
                                            >
                                                <Button show='true' onClick={() => copyIcon(icon.name)} onMouseEnter={(e) => { e.stopPropagation() }} onMouseLeave={(e) => { e.stopPropagation(); setTooltipText('Copy') }} variant="btn btn-sm btn-soft-primary">
                                                    Copy
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                        <small>{icon.name}</small>
                                    </div>
                                ))}

                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default withAuthorization(memo(Outline))