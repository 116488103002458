import React, { useState, useRef, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import Badge from "react-bootstrap/Badge";
import DateRangePicker from "./DateRangePicker";
import { Button, Modal } from "react-bootstrap";

function Titlebar({
  tags,
  title,
  subTitle,
  projectType,
  setDateRangePayload,
  projectStartDate,
  dateLabel,
  showDatePicker = true,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  return (
    <div className="header-title mb-3 mb-md-4 d-flex flex-column flex-md-row justify-content-between align-items-md-center">
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-row align-items-center justify-content-md-center mb-3 mb-md-0">
          <h4 className="fw-semibold mb-0 mb-md-0">{title}</h4>
          <Badge pill bg="primary" className="ms-2 me-2 px-2 py-1">
            {projectType}
          </Badge>
          <Badge
            pill
            bg="success light text-success"
            className="ms-2 fs-7 bg-opacity-10 px-2"
          >
            Start Date: {projectStartDate ? projectStartDate : "N/A"}
          </Badge>
          {/* {tags && tags?.length > 0 &&
            tags?.map((item, index) => {
              return (
                <Badge key={index} pill bg="success light text-success" className="ms-2 fs-7 bg-opacity-10 px-2">{item}</Badge>
              );
            })
          } */}
        </div>
        {/* <h6 className="mt-2 fs-6">{subTitle}</h6> */}
      </div>

      <div className="form-group mb-0 d-flex">
        {/* <Flatpickr
          options={{ mode: "range", minDate: "today" }}
          className="form-control range_flatpicker"
          placeholder="24 Jan 2022 to 23 Feb 2022"
        /> */}
        {showDatePicker && (
          <DateRangePicker
            setDateRangePayload={setDateRangePayload}
            dateLabel={dateLabel}
          />
        )}
      </div>
    </div>
  );
}

export default Titlebar;
