import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import {
  GET_RESOURCES_CREATE,
  RESOURCES_ADD,
  RESOURCES_FILE_UPLOAD,
} from "../../../../api/endpoints/help-and-support-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import uploadService from "../../../../api/services/upload-service";

const ContentUploadModal = ({ showModalHandler, show }) => {
  const [options, setOptions] = useState([]);
  const [addResourcesDetails, setAddResourcesDetails] = useState({
    resource_name: "",
    resource_path: "",
    resource_file_type_id: "",
    resource_description: "",
  });
  let fileType = useRef("");
  const acceptsType = useMemo(() => {
    if (fileType.current === "image" || fileType.current === "infographic") {
      return "image/*";
    } else if (fileType.current === "audio") {
      return "audio/*";
    } else if (fileType.current === "video") {
      return "video/*";
    } else if (fileType.current === "pdf") {
      return "application/pdf";
    } else if (fileType.current === "ppt") {
      return "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation";
    } else if (fileType.current === "docx") {
      return ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    } else if (fileType.current === "excel") {
      return ".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else if (fileType.current === "zip") {
      return ".zip,.rar,.7zip,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed";
    } else {
      return "*";
    }
  }, [fileType.current]);
  useEffect(() => {
    if (show) {
      getFileType();
    }
  }, [show]);
  const getFileType = async () => {
    const response = await getService(GET_RESOURCES_CREATE);
    console.log("getFileType", response.data.data.filetypes);
    setOptions(response.data.data.filetypes);
  };
  const getAddResourcesData = async (e) => {
    if (e.target.name === "resource_file_type_id") {
      options.map((_o) => {
        if (_o.file_type_id == e.target.value) {
          fileType.current = _o.file_type_code;
        }
        return _o;
      });
    }
    if (e.target.name === "resource_path") {
      console.log("xxxxxxxxx", e.target.name);
      const { files } = e.target;
      console.log("FILES", files);
      // upload images and then setFieldValue of reference_files
      const fileName = await uploadFiles([...files]);
      console.log("FILE_NAME", fileName);
      if (fileName) {
        setAddResourcesDetails({
          ...addResourcesDetails,
          [e.target.name]: fileName,
        });
      }
    } else {
      setAddResourcesDetails({
        ...addResourcesDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(RESOURCES_FILE_UPLOAD, formdata);
      if (response.data?.success) {
        // console.log("RESPONSE", response.data.data['0'].url);
        // 2. Simulating successful upload and generating URLs
        const name = response.data.data["0"].name;
        console.log("URLS if exist", response.data.data);
        console.log("URLS", name);
        resolve(name);
      } else {
        reject("Something went wrong");
      }
    });
  }
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    console.log("FILES", files);
    // upload images and then setFieldValue of reference_files
    const urls = await uploadFiles([...files]);
    console.log("URLS", urls);
    if (urls) {
      setAddResourcesDetails({
        ...addResourcesDetails,
        resource_path: urls,
      });
    }
  };
  const addResourceFile = async () => {
    let response = await postService(RESOURCES_ADD, addResourcesDetails);
    console.log("response.data.data.message", response.data);
    if (response.data.success === true) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    showModalHandler();
  };

  const isSubmitDisable =
    addResourcesDetails.resource_description === "" ||
    addResourcesDetails.resource_file_type_id === "" ||
    addResourcesDetails.resource_name === "" ||
    addResourcesDetails.resource_path === "";
  console.log("addResourcesDetails", addResourcesDetails, acceptsType);
  return (
    <Modal
      show={show}
      onHide={showModalHandler}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Resources
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg="12">
            <h5 className="mb-3">Resource Name</h5>
            <div className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="text"
                  placeholder="Please enter Resource Name"
                  name="resource_name"
                  onChange={getAddResourcesData}
                />
                <Form.Label>Please enter Resource Name</Form.Label>
              </Form.Floating>
            </div>
          </Col>
          <Col lg="12">
            <h5 className="mb-3">Resource File Type</h5>
            <div className="mb-4">
              <Form.Floating className="custom-form-floating h-100">
                <Form.Select
                  // type="text"
                  placeholder="Select File Type"
                  name="resource_file_type_id"
                  onChange={getAddResourcesData}
                >
                  {" "}
                  <option value="">Select File Type</option>
                  {options &&
                    options.length > 0 &&
                    options.map((_o) => (
                      <option value={_o.file_type_id} key={_o.file_type_id}>
                        {_o.file_type_name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Floating>
            </div>
          </Col>
          <Col lg="12">
            <h5 className="mb-3">Add Files</h5>
            <div className="mb-4">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="file"
                  // placeholder="Enter Ticket Subject"
                  name="resource_path"
                  onChange={getAddResourcesData}
                  accept={acceptsType}
                  disabled={addResourcesDetails.resource_file_type_id === ""}
                />
              </Form.Floating>
            </div>
          </Col>
          <Col lg="12">
            <h5 className="mb-3">Description</h5>
            <Form.Floating className="custom-form-floating">
              <textarea
                className="form-control h-100"
                placeholder="Please provide a detailed description"
                id="floatingTextarea2"
                name="resource_description"
                onChange={getAddResourcesData}
              ></textarea>
              <Form.Label htmlFor="fname">
                Please provide a detailed description
              </Form.Label>
            </Form.Floating>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={showModalHandler ? true : false}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={addResourceFile}
          disabled={isSubmitDisable}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContentUploadModal;
