import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import {
  convertBytes,
  formatSecondToTime,
  getRelativeDate,
  secondsToHms,
} from "../../../../../../../common/utils/utils";

const VideoEncodingDetails = ({
  fileName = "",
  resolution = "",
  bitrate = "",
  fileSize = "",
  fileFormat = "",
  frameRate = "",
  createdOn = "",
  duration = "",
}) => {
  return (
    <Row className="flex-column mb-4 mb-md-0">
      <Col xs={12} className="mb-3">
        <h5 className="mb-1">Encoding Information</h5>
        <p className="mb-0">Encoding Details For This Video</p>
      </Col>
      <Col xs={12}>
        <div className="rounded-3 bg-soft-danger text-dark py-md-3 px-md-4 p-3">
          <ul className="mx-n2 row list-unstyled mt-n2 mb-0">
            <Col
              xs={12}
              md={6}
              className="mt-2 px-2 d-flex flex-column gap-2 fs-7"
            >
              <li className="text-truncate">
                <span className="text-nowrap ">File Name:</span>
                <span className="ms-2 text-black fw-semibold" title={fileName}>
                  {fileName}
                </span>
              </li>
              <li>
                <span className="text-nowrap">Resolution: </span>
                <span className="ms-2 text-black fw-semibold">
                  {resolution}
                </span>
              </li>
              {/* <li>
                <span className="text-nowrap">Bitrate:</span>
                <span className="ms-2 text-black fw-semibold">{bitrate}</span>
              </li> */}
              <li>
                File Size:{" "}
                <span className="ms-2 text-black fw-semibold">
                  {convertBytes(fileSize, "auto")}
                </span>
              </li>
            </Col>
            <Col
              xs={12}
              md={6}
              className="mt-2 px-2 d-flex flex-column gap-2 fs-7"
            >
              <li>
                <span className="text-nowrap">File Format:</span>
                <span className="ms-2 text-black fw-semibold">
                  {fileFormat}
                </span>
              </li>
              {/* <li>
                <span className="text-nowrap">Frame Rate:</span>
                <span className="ms-2 text-black fw-semibold">{frameRate}</span>
              </li> */}
              <li>
                <span className="text-nowrap">Created on:</span>
                <span className="ms-2 text-black fw-semibold">
                  {createdOn ? createdOn : "N/A"}
                </span>
              </li>
              <li>
                <span className="text-nowrap">Duration:</span>
                <span className="ms-2 text-black fw-semibold">
                  {/* {duration > 0 ? formatSecondToTime(duration) : "N/A"} */}
                  {duration > 0 ? secondsToHms(duration) : "N/A"}
                </span>
              </li>
            </Col>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default memo(VideoEncodingDetails);
