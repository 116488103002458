import React, { useState, memo, Fragment } from "react";
import { Row, Col, Modal, Card, Button } from "react-bootstrap";
import { findIcon } from "../../../../../../dashboard/icons";

import Dropzone from "react-dropzone-uploader";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { currentChannel } from "../../../../../../../store/channel/channel.selector";
import { useSelector } from "react-redux";

function UploadVideo() {
  const [show, setShow] = useState(false);
  const { redirectTo } = useRedirect();
  const channel = useSelector(currentChannel);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const onUploadFile = () => {
    redirectTo("channel/file-upload/" + channel?.channel_id);
  };

  return (
    <Fragment>
      <Card className="text-center m-0 h-100" onClick={onUploadFile}>
        <Card.Body className=" sidebar-border-profile-card border-1 border-success rounded-3 m-0 bg-success bg-opacity-5">
          <div className="d-flex justify-content-center gap-3 mt-n2">
            <div className="text-success">
              {findIcon("upload", "dual-tone", 72)}
            </div>
          </div>
          <p className="m-0 mt-3  fs-6 text-dark">
            Drag & Drop or{" "}
            <span className="text-success fw-semibold">browse</span> files to
            Upload
          </p>
        </Card.Body>
        <Modal
          className="fade file-manager-modal"
          show={show}
          onHide={handleClose}
          size="lg"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="text-center">
            <Dropzone
              inputContent={"Drop files here or browse files"}
              inputWithFilesContent={"Add more"}
              submitButtonContent={"Save"}
              onSubmit={handleSubmit}
            />
          </Modal.Body>
        </Modal>
      </Card>
    </Fragment>
  );
}

export default UploadVideo;
