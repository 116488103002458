import React , { memo }  from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../components/skeleton";

function CommentsSkeleton() {
  return (
    <div>
      <div className="m-0 d-flex justify-content-between align-items-center mb-4">
        <div>
          <Skeleton variant="text" width={100} height={30} />
        </div>
        <div className="d-flex gap-3 align-items-center justify-content-center">
          <div>
            <Skeleton
              variant="square"
              className="rounded-3"
              width={50}
              height={20}
            />
          </div>
          <div className="rounded-3 py-2">
            <Skeleton
              variant="square"
              className="rounded-3"
              width={70}
              height={20}
            />
          </div>
          <div className="rounded-3 py-2">
            <Skeleton
              variant="square"
              className="rounded-3"
              width={50}
              height={20}
            />
          </div>
        </div>
      </div>
      <Card>
        <Card.Body>
          {Array.from({ length: 8 }).map((_, index) => (
            <div
              key={index}
              className="d-flex gap-3 border-bottom border-1 mb-3"
            >
              <Skeleton
                variant="rect"
                width={40}
                height={40}
                className="rounded-2"
              />

              <div className="d-flex flex-column flex-grow-1">
                <Skeleton variant="text" width="40%" height={30} />
                <div className="d-flex justify-content-between mt-auto">
                  <div className="d-flex gap-4">
                    <Skeleton variant="text" width={100} />
                    <Skeleton className="pe-3" variant="text" width={100} />
                  </div>

                  <div className="d-flex gap-4">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
}

export default memo(CommentsSkeleton);
