import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./project.state";
import { getStorage } from "../../utilities/setting";

export const projectSlice = createSlice({
  name: "project",
  initialState: getStorage("projectState") || initialState,
  reducers: {
    updateProject: (state, action) => {
      state.project = action.payload;
    },
  },
});

export default projectSlice.reducer;
