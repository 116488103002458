import { memo, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Masonry from "react-masonry-css";
import mobile from "../../../../assets/images/mobileWhatsapp.png";
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import Filter from "./filter";
import PageNotFound from "../../../PageNotFound";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};

const WhatsappTemplatePreview = ({
  suggestionlist,
  modalClose,
  getSelectedTemplate,
  selectedTemplateData,
}) => {
  const [isEnable, setIsEnable] = useState(false);
  const onTemplateSuggestionClick = (data) => {
    getSelectedTemplate(data);
    setIsEnable(true);
  };
  const createNewTemplate = () => {
    modalClose();
    getSelectedTemplate({
      id: "",
      name: "",
      content: "",
      approve: "",
    });
  };
  const selectTemplate = () => {
    modalClose();
    setIsEnable(false);
  };
  console.log("Suggestion list", suggestionlist);
  return (
    <Card>
      <Card.Body className="pt-2">
        <div className="p-4 bg-soft-secondary rounded-3">
          <Row className="justify-content-center align-items-start">
            <Col lg={9} xs={12} className="position-relative">
              <div className="p-4 bg-white rounded-3 d-flex flex-column">
                <div className="mb-4">
                  <Filter />
                </div>

                <Col lg={12}>
                  {suggestionlist?.length > 0 ? (
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                    >
                      {suggestionlist.map((item, index) => (
                        <div onClick={() => onTemplateSuggestionClick(item)}>
                          <Card
                            className={`shadow-none border transition ${
                              selectedTemplateData.id === item.id
                                ? "bg-soft-primary border-primary"
                                : ""
                            }`}
                            style={{ cursor: "pointer" }}
                          >
                            <Card.Body className="p-3 d-flex">
                              <h6 className="mb-0 flex-grow-1 d-flex align-items-center">
                                {item.name}
                              </h6>
                              <Button
                                variant={`${
                                  selectedTemplateData.id === item.id
                                    ? "primary"
                                    : "soft-primary"
                                }`}
                                size="sm"
                                className="rounded-circle btn-icon flex-shrink-0 ms-3"
                              >
                                <div className="btn-inner">
                                  {findIcon("Check", "outline", "16")}
                                </div>
                              </Button>
                            </Card.Body>
                          </Card>
                        </div>
                      ))}
                    </Masonry>
                  ) : (
                    <PageNotFound />
                  )}
                </Col>
                <Col className="flex-grow-0">
                  <div className="d-flex gap-3 justify-content-end">
                    <Button variant="danger" onClick={createNewTemplate}>
                      Create New
                    </Button>
                    <Button
                      variant="primary"
                      disabled={!isEnable}
                      onClick={selectTemplate}
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </div>
            </Col>
            <Col lg={3} xs={12} className="position-sticky top-0">
              <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                {/* <h5>Preview</h5> */}
                <div className="position-relative flex-fill">
                  <img
                    src={mobile}
                    alt="mobile"
                    className="h-auto w-100 d-block position-relative z-0"
                  />
                  <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                    {selectedTemplateData && selectedTemplateData?.content && (
                      <div className="h-100 rounded-5 p-4 mobile-preview position-relative mt-4">
                        <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                          {selectedTemplateData.content}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(WhatsappTemplatePreview);
