import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllClients from "../pages/all-clients";
// pages

export const ClientRouter = [
  {
    path: "/master-management/client",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllClients />,
      },
    ],
  },
];
