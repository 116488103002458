import React, { memo, useState } from "react";
import { Card, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import CreativeDetailsSwiper from "./CreativeDetailsSwiper";
import RadialBarChart from "../../../../../components/chart/RadialBarChart";
import { findIcon } from "../../../../dashboard/icons";
import TabCountCard from "./TabCountCard";
import CollapseTags from "./CollapseTags";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import PageNotFound from "../../../../PageNotFound";
import { useParams } from "react-router-dom";
import { formatNumberWithCommas } from "../../../../../common/utils/utils";

function CampaignMatrix({
  tags,
  reachChart,
  impressionChart,
  creatives,
  countCard,
  activeCampaignGroupId,
}) {
  function flatListCreativeData(data) {
    var array = [];
    for (var i = 0; i < data?.length; i++) {
      data[i]?.campaign_to_creative?.forEach((element) => {
        array.push(element?.campaign_creative[0]);
      });
    }
    return array;
  }

  const { project_id } = useParams();
  const tabCountCardData = [
    {
      text: "Clicks",
      count: countCard?.clicks || "0",
      icon: findIcon("ImpressionTarget", "dual-tone", 36),
      onClickPath: "",
      iconColor: "text-success",
    },
    {
      text: "CTR",
      count: countCard?.ctr + "%" || "0%",
      icon: findIcon("ImpressionDelivered", "dual-tone", 36),
      onClickPath: "",
      iconColor: "text-primary",
    },
    {
      text: "Creatives",
      count: countCard?.creatives || "0",
      icon: findIcon("ImpressionCreatives", "dual-tone", 36),
      onClickPath: "",
      iconColor: "text-success",
    },
    {
      text: "Specialities",
      count: countCard?.specialities || "0",
      icon: findIcon("Specialities", "dual-tone", 36),
      onClickPath: "",
      iconColor: "text-primary",
    },
  ];
  const { redirectTo } = useRedirect();
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <>
      <Row>
        <Col md={3}>
          <RadialBarChart
            max={impressionChart?.max || 0}
            data={impressionChart?.data || 0}
            labels={["Impressions"]}
            color={"#1aa053"}
            title={
              <span className="fs-7 mt-n2 text-center">
                Impression
                {impressionChart?.max !== 0 && (
                  <>
                    <br />
                    Target {formatNumberWithCommas(impressionChart?.max)}
                  </>
                )}
              </span>
            }
          />

          {/* <RadialBarChart
            max={impressionChart || 0}
            data={impressionChart || 0}
            labels={["Impressions"]}
            color={"#1aa053"}
            title={<span className="fs-7 mt-n2 text-center">Impressions</span>}
          /> */}
        </Col>
        <Col md={3}>
          <RadialBarChart
            max={reachChart?.max || 0}
            data={reachChart?.data || 0}
            labels={["Reach"]}
            color={"#3256b8"}
            title={
              <span className="fs-7 mt-n2 text-center">
                Reach
                {reachChart?.max !== 0 && (
                  <>
                    <br />
                    Target {formatNumberWithCommas(reachChart?.max)}
                  </>
                )}
              </span>
            }
          />
        </Col>
        <Col md={6}>
          <Row>
            {tabCountCardData?.length > 0 &&
              tabCountCardData?.map((item, index) => {
                return (
                  <div className="col-6 mb-3" key={index}>
                    <TabCountCard
                      text={item.text}
                      count={item.count}
                      icon={item.icon}
                      iconColor={item.iconColor}
                      handleOnclick={() => {
                        redirectTo(`brand-ads/creative/${project_id}`);
                      }}
                    />
                  </div>
                );
              })}
          </Row>
        </Col>

        {tags && tags?.length > 0 && (
          <Col md={12}>
            <CollapseTags tags={tags} />
          </Col>
        )}

        {creatives && creatives?.length > 0 ? (
          <Col md={12}>
            <div className="bg-soft-secondary bg-opacity-25 p-3 rounded-4 mt-3">
              {console.log("creatives[currentIndex]", creatives[currentIndex])}
              <CreativeDetailsSwiper
                activeCampaignGroupId={activeCampaignGroupId}
                creatives={flatListCreativeData(creatives)}
                buttonText={"Creative Details"}
                handleOnclick={() => {
                  redirectTo(
                    `brand-ads/creative/details/${project_id}/${
                      flatListCreativeData(creatives)[currentIndex]?.creative_id
                    }/${activeCampaignGroupId}`
                  );
                }}
                setCurrentIndex={setCurrentIndex}
              />
            </div>
          </Col>
        ) : (
          <div className="p-5">
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          </div>
        )}
      </Row>
    </>
  );
}

export default memo(CampaignMatrix);
