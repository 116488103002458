import React from "react";


// pages
import FilemanagerLayout from "../layouts/creative-library";
import AllCreatives from "../pages/creative-listing";
import UploadCreative from "../pages/upload-creative";
import CreativePreview from "../pages/creative-preview";
import CreativeSearch from "../pages/creative-search";
import MyContent from "../pages/my-content";
import Default from "../../../../layouts/dashboard/default";

export const CreativeLibraryRouter = [
  {
    path: "creative-library",
    element: <Default />,
    children: [
      {
        path: "listing",
        element: <AllCreatives />,
      },

      {
        path: "upload",
        element: <UploadCreative />,
      },
      {
        path: "preview",
        element: <CreativePreview />,
      },
      {
        path: "search",
        element: <CreativeSearch />,
      },
      {
        path: "my-content",
        element: <MyContent />,
      },
    ],
  },
];
