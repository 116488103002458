import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch } from "react-redux";
import { updateChannelInput } from "../../../../../../../store/channel/channel.action";
import { findIcon } from "../../../../../../dashboard/icons";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { Link, useParams } from "react-router-dom";
import postService from "../../../../../../../api/services/post-service";
import { CHANNEL_VIDEO_UPDATE_BASIC } from "../../../../../../../api/endpoints/channel-video-endpoints";
import useBackdrop from "../../../../../../../components/custom-hooks/use-backdrop";
import toast from "react-hot-toast";
import dayjs from "dayjs";

function CustomizationLayout({ data }) {
  const dispatch = useDispatch();
  const { channel_id } = useParams();
  const [featuredSelectionModal, setFeaturedSelectionModal] = useState(false);
  const [featuredVideo, setFeaturedVideo] = useState(null);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const { redirectTo } = useRedirect();
  const now = dayjs(new Date());

  const allVideos = data?.content_to_contents_with_content
    ?.filter((video) => !!video?.get_child_content_data_published_only)
    ?.map((video) => video?.get_child_content_data_published_only);
  const videos = allVideos
    ?.filter((video) => video?.content_category_id == 30)
    ?.filter((video) => {
      const end = dayjs(video.content_publication_date);
      const dateDifference = now.diff(end, "s");
      return dateDifference > 0; // only published videos will be shown.
    });
  const reels = allVideos?.filter((video) => video?.content_category_id == 33);

  const featuredVideoTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      feature video tooltip
    </Tooltip>
  );

  const featuredSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can customize the layout of your DocTube channel so that viewers can
      view your channel trailer, featured video, and featured sections when they
      get to your channel homepage.
    </Tooltip>
  );
  const videoSpotlightTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Featured video for returning subscriber You can customize the layout of
      your DocTube channel so that viewers can view your featured video.
    </Tooltip>
  );
  const addFeaturedVideo = () => {
    setFeaturedSelectionModal(true);
  };
  const onContentSelect = async (data) => {
    console.log("onContentSelect", data);
    setFeaturedVideo(data);

    setFeaturedSelectionModal(false);
  };
  useEffect(() => {
    const allVideos = data?.content_to_contents_with_content?.map(
      (video) => video?.get_child_content_data_published_only
    );
    const videos = allVideos?.filter(
      (video) => video?.content_category_id == 30
    );
    let featured = videos?.find((video) => video?.content_featured_status == 1);
    if (featured) setFeaturedVideo(featured);
  }, [data]);
  useEffect(() => {
    dispatch(
      updateChannelInput({
        featuredVideo,
      })
    );
  }, [featuredVideo]);
  const removeFeaturedVideoHandler = async () => {
    if (featuredVideo?.content_id) {
      showBackdrop();
      let removeResponse = await postService(CHANNEL_VIDEO_UPDATE_BASIC, {
        content_id: featuredVideo?.content_id,
        field: "content_featured_status",
        value: "0",
      });
      if (removeResponse.data.success) {
        toast.success("Featured Video Removed Successfully");
        setFeaturedVideo(null);
      }
    }
    hideBackdrop();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Col md={12} className="mb-3">
            <h5 className="mb-1">Video Spotlight</h5>
            <p>Add a video to the top of your channel homepage</p>
          </Col>

          {/* Phase 2 */}
          {/* <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
          <div className="avatar-40 bg-light rounded-circle d-flex align-items-center justify-content-center flex-shrink-0">
            <svg
              className="icon-24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.857 20.417C19.73 20.417 21.249 18.899 21.25 17.026V17.024V14.324C20.013 14.324 19.011 13.322 19.01 12.085C19.01 10.849 20.012 9.846 21.249 9.846H21.25V7.146C21.252 5.272 19.735 3.752 17.862 3.75H17.856H6.144C4.27 3.75 2.751 5.268 2.75 7.142V7.143V9.933C3.944 9.891 4.945 10.825 4.987 12.019C4.988 12.041 4.989 12.063 4.989 12.085C4.99 13.32 3.991 14.322 2.756 14.324H2.75V17.024C2.749 18.897 4.268 20.417 6.141 20.417H6.142H17.857Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.3711 9.06303L12.9871 10.31C13.0471 10.432 13.1631 10.517 13.2981 10.537L14.6751 10.738C15.0161 10.788 15.1511 11.206 14.9051 11.445L13.9091 12.415C13.8111 12.51 13.7671 12.647 13.7891 12.782L14.0241 14.152C14.0821 14.491 13.7271 14.749 13.4231 14.589L12.1921 13.942C12.0711 13.878 11.9271 13.878 11.8061 13.942L10.5761 14.589C10.2711 14.749 9.91609 14.491 9.97409 14.152L10.2091 12.782C10.2321 12.647 10.1871 12.51 10.0891 12.415L9.09409 11.445C8.84809 11.206 8.98309 10.788 9.32309 10.738L10.7001 10.537C10.8351 10.517 10.9521 10.432 11.0121 10.31L11.6271 9.06303C11.7791 8.75503 12.2191 8.75503 12.3711 9.06303Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
          <Image src={avatar} /> 
          <div className="d-flex flex-column gap-4">
            <h5 className="mb-0">
              Channel trailer for people who haven't subscribed
            </h5>
            <div className="d-flex gap-3">
              <Image
                src={
                  "https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg"
                }
                roundedSquare
                style={{ height: "100px", width: "200px" }}
                fluid
              />
              <div>
                <h5 className="mb-1">Video title</h5>
                <p className="mb-0">video Discription</p>
              </div>
            </div>
          </div> 
        </div> */}
          {/* Phase 2 */}

          {/*Featured video  */}
          <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
            <div className="avatar-40 bg-light rounded-circle d-flex align-items-center justify-content-center flex-shrink-0">
              <svg
                className="icon-24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.857 20.417C19.73 20.417 21.249 18.899 21.25 17.026V17.024V14.324C20.013 14.324 19.011 13.322 19.01 12.085C19.01 10.849 20.012 9.846 21.249 9.846H21.25V7.146C21.252 5.272 19.735 3.752 17.862 3.75H17.856H6.144C4.27 3.75 2.751 5.268 2.75 7.142V7.143V9.933C3.944 9.891 4.945 10.825 4.987 12.019C4.988 12.041 4.989 12.063 4.989 12.085C4.99 13.32 3.991 14.322 2.756 14.324H2.75V17.024C2.749 18.897 4.268 20.417 6.141 20.417H6.142H17.857Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3711 9.06303L12.9871 10.31C13.0471 10.432 13.1631 10.517 13.2981 10.537L14.6751 10.738C15.0161 10.788 15.1511 11.206 14.9051 11.445L13.9091 12.415C13.8111 12.51 13.7671 12.647 13.7891 12.782L14.0241 14.152C14.0821 14.491 13.7271 14.749 13.4231 14.589L12.1921 13.942C12.0711 13.878 11.9271 13.878 11.8061 13.942L10.5761 14.589C10.2711 14.749 9.91609 14.491 9.97409 14.152L10.2091 12.782C10.2321 12.647 10.1871 12.51 10.0891 12.415L9.09409 11.445C8.84809 11.206 8.98309 10.788 9.32309 10.738L10.7001 10.537C10.8351 10.517 10.9521 10.432 11.0121 10.31L11.6271 9.06303C11.7791 8.75503 12.2191 8.75503 12.3711 9.06303Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>

            <div className="d-flex flex-column gap-3 flex-grow-1">
              <h5 className="mb-0">Featured a Video for Your Channel</h5>
              <div className="d-flex gap-3 align-items-start justify-content-between">
                {featuredVideo ? (
                  <FeaturedChannelCard
                    title={featuredVideo?.content_title}
                    description={featuredVideo?.content_description}
                    thumbnail={
                      featuredVideo?.content_to_image_with_image?.length > 0
                        ? featuredVideo?.content_to_image_with_image[0]
                            ?.content_image_id?.thumbnail
                        : ""
                    }
                    featuredVideoUrl={featuredVideo?.dt_url}
                    removeFeaturedVideoHandler={removeFeaturedVideoHandler}
                  />
                ) : (
                  <div className="flex-grow-1">
                    <p>
                      Highlight a video for your subscribers to watch. This
                      video won't be shown again at the top of your page for
                      subscribers. who have watched it.
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 50, hide: 200 }}
                        overlay={videoSpotlightTooltip}
                      >
                        <span className="text-primary cursorPointer ms-1 fw-semibold">
                          (?)
                        </span>
                      </OverlayTrigger>
                    </p>
                  </div>
                )}
                <Button
                  variant="link"
                  className="flex-shrink-0 p-0  fw-semibold d-flex gap-1 align-items-center"
                  onClick={addFeaturedVideo}
                >
                  <svg
                    className="icon-16"
                    width="32"
                    height="32"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      d="M12 4v16m8-8H4"
                    ></path>
                  </svg>
                  {featuredVideo ? "Change" : "Select Video"}
                </Button>{" "}
              </div>
            </div>
          </div>
          {/* Featured video */}

          {/* <h5 className="mt-5 mb-3">Recomendations for yours viewers</h5> */}

          {/* Recommendations phase 2 */}
          {/* <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
            <div className="avatar-40 bg-light rounded-circle d-flex align-items-center justify-content-center flex-shrink-0">
              <svg
                className="icon-24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.857 20.417C19.73 20.417 21.249 18.899 21.25 17.026V17.024V14.324C20.013 14.324 19.011 13.322 19.01 12.085C19.01 10.849 20.012 9.846 21.249 9.846H21.25V7.146C21.252 5.272 19.735 3.752 17.862 3.75H17.856H6.144C4.27 3.75 2.751 5.268 2.75 7.142V7.143V9.933C3.944 9.891 4.945 10.825 4.987 12.019C4.988 12.041 4.989 12.063 4.989 12.085C4.99 13.32 3.991 14.322 2.756 14.324H2.75V17.024C2.749 18.897 4.268 20.417 6.141 20.417H6.142H17.857Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3711 9.06303L12.9871 10.31C13.0471 10.432 13.1631 10.517 13.2981 10.537L14.6751 10.738C15.0161 10.788 15.1511 11.206 14.9051 11.445L13.9091 12.415C13.8111 12.51 13.7671 12.647 13.7891 12.782L14.0241 14.152C14.0821 14.491 13.7271 14.749 13.4231 14.589L12.1921 13.942C12.0711 13.878 11.9271 13.878 11.8061 13.942L10.5761 14.589C10.2711 14.749 9.91609 14.491 9.97409 14.152L10.2091 12.782C10.2321 12.647 10.1871 12.51 10.0891 12.415L9.09409 11.445C8.84809 11.206 8.98309 10.788 9.32309 10.738L10.7001 10.537C10.8351 10.517 10.9521 10.432 11.0121 10.31L11.6271 9.06303C11.7791 8.75503 12.2191 8.75503 12.3711 9.06303Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>

            <div className="d-flex flex-column gap-1">
              <h5 className="mb-0">For you</h5>
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <p>
                    Doctube will recommend fresh content based on your viewers
                    interests in this section at the top. your channel page.
                    This will be only visible to your viewers. when you have
                    enough content.
                    <Button variant="link text-primary cursorPointer ms-1 lh-1 p-0">
                      More Settings
                    </Button>
                  </p>
                </div>
                <Form.Check
                  className="flex-shrink-0"
                  type="switch"
                  id="custom-switch"
                />
              </div>
            </div>
          </div> */}
          {/*  */}

          {/* Recommendations phase 2 */}

          {/* <Col md={12} className="mb-3 mt-5">
            <h5 className="mb-1">Featured Sections</h5>
            <p>
              Customize the layout of your homepage{" "}
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 200 }}
                overlay={featuredSectionTooltip}
              >
                <span className="text-primary cursorPointer ms-1 fw-semibold">
                  (?)
                </span>
              </OverlayTrigger>{" "}
            </p>
          </Col> */}

          <div className="border p-3 rounded-3 d-flex gap-4 mb-3">
            <div className="avatar-40 bg-light rounded-circle d-flex align-items-center justify-content-center flex-shrink-0">
              <svg
                className="icon-24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.857 20.417C19.73 20.417 21.249 18.899 21.25 17.026V17.024V14.324C20.013 14.324 19.011 13.322 19.01 12.085C19.01 10.849 20.012 9.846 21.249 9.846H21.25V7.146C21.252 5.272 19.735 3.752 17.862 3.75H17.856H6.144C4.27 3.75 2.751 5.268 2.75 7.142V7.143V9.933C3.944 9.891 4.945 10.825 4.987 12.019C4.988 12.041 4.989 12.063 4.989 12.085C4.99 13.32 3.991 14.322 2.756 14.324H2.75V17.024C2.749 18.897 4.268 20.417 6.141 20.417H6.142H17.857Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3711 9.06303L12.9871 10.31C13.0471 10.432 13.1631 10.517 13.2981 10.537L14.6751 10.738C15.0161 10.788 15.1511 11.206 14.9051 11.445L13.9091 12.415C13.8111 12.51 13.7671 12.647 13.7891 12.782L14.0241 14.152C14.0821 14.491 13.7271 14.749 13.4231 14.589L12.1921 13.942C12.0711 13.878 11.9271 13.878 11.8061 13.942L10.5761 14.589C10.2711 14.749 9.91609 14.491 9.97409 14.152L10.2091 12.782C10.2321 12.647 10.1871 12.51 10.0891 12.415L9.09409 11.445C8.84809 11.206 8.98309 10.788 9.32309 10.738L10.7001 10.537C10.8351 10.517 10.9521 10.432 11.0121 10.31L11.6271 9.06303C11.7791 8.75503 12.2191 8.75503 12.3711 9.06303Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            {/* <Image src={avatar} /> */}
            <div className="d-flex flex-column gap-3">
              <h5 className="mb-0">Videos ({videos?.length})</h5>
              {!videos?.length && (
                <Button
                  key="file-upload"
                  variant="link"
                  className="flex-shrink-0 p-0 fw-semibold d-flex gap-1 align-items-center"
                  onClick={() =>
                    redirectTo(`channel/file-upload/${channel_id}`)
                  }
                >
                  <svg
                    className="icon-16"
                    width="32"
                    height="32"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      d="M12 4v16m8-8H4"
                    ></path>
                  </svg>
                  {videos?.length > 0 ? "Add more" : "Select Video"}
                </Button>
              )}
              <div className="d-flex gap-3 flex-wrap">
                {videos?.slice(0, 4).map((video, key) => {
                  const doctubeVideoUrl = video?.dt_url;
                  return (
                    <a
                      key={`featured-video-${key}`}
                      className="ratio-9x16 h-rem-6 d-flex justify-content-center bg-light rounded-3 overflow-hidden"
                      href={`${doctubeVideoUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={
                          video?.content_to_image_with_image?.length > 0
                            ? video?.content_to_image_with_image[0]
                                ?.content_image_id?.thumbnail
                            : "https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg"
                        }
                        className="w-auto mw-100 h-100 object-fit-cover"
                        style={{ aspectRatio: "16/9" }}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
            <Link to={"/channel/videos/" + channel_id} className="ms-auto fs-6">
              View All{" "}
              <span className="transform-rotate-45">
                {findIcon("Arrow Up", "outline", 24)}
              </span>
            </Link>
          </div>

          <div className="border p-3 rounded-3 d-flex gap-4 mb-0">
            <div className="avatar-40 bg-light rounded-circle d-flex align-items-center justify-content-center flex-shrink-0">
              <svg
                className="icon-24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.857 20.417C19.73 20.417 21.249 18.899 21.25 17.026V17.024V14.324C20.013 14.324 19.011 13.322 19.01 12.085C19.01 10.849 20.012 9.846 21.249 9.846H21.25V7.146C21.252 5.272 19.735 3.752 17.862 3.75H17.856H6.144C4.27 3.75 2.751 5.268 2.75 7.142V7.143V9.933C3.944 9.891 4.945 10.825 4.987 12.019C4.988 12.041 4.989 12.063 4.989 12.085C4.99 13.32 3.991 14.322 2.756 14.324H2.75V17.024C2.749 18.897 4.268 20.417 6.141 20.417H6.142H17.857Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3711 9.06303L12.9871 10.31C13.0471 10.432 13.1631 10.517 13.2981 10.537L14.6751 10.738C15.0161 10.788 15.1511 11.206 14.9051 11.445L13.9091 12.415C13.8111 12.51 13.7671 12.647 13.7891 12.782L14.0241 14.152C14.0821 14.491 13.7271 14.749 13.4231 14.589L12.1921 13.942C12.0711 13.878 11.9271 13.878 11.8061 13.942L10.5761 14.589C10.2711 14.749 9.91609 14.491 9.97409 14.152L10.2091 12.782C10.2321 12.647 10.1871 12.51 10.0891 12.415L9.09409 11.445C8.84809 11.206 8.98309 10.788 9.32309 10.738L10.7001 10.537C10.8351 10.517 10.9521 10.432 11.0121 10.31L11.6271 9.06303C11.7791 8.75503 12.2191 8.75503 12.3711 9.06303Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            {/* <Image src={avatar} /> */}
            <div className="d-flex flex-column gap-1">
              <h5 className="mb-0">Reels ({reels?.length})</h5>
              {!reels?.length && (
                <Button
                  variant="link"
                  className="flex-shrink-0 p-0 fw-semibold d-flex gap-1 align-items-center"
                  onClick={() =>
                    redirectTo(`channel/file-upload/${channel_id}`)
                  }
                >
                  <svg
                    className="icon-16"
                    width="32"
                    height="32"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="3"
                      d="M12 4v16m8-8H4"
                    ></path>
                  </svg>
                  Select Video
                </Button>
              )}
              {/* <p>This section will appear after a Reel is uploaded</p> */}
              <div className="d-flex gap-3 flex-wrap">
                {reels?.slice(0, 4).map((reel, key) => {
                  const doctubeReelUrl = reel?.dt_url;
                  return (
                    <a
                      key={key}
                      className="ratio-9x16 h-rem-10 d-flex justify-content-center bg-light rounded-3 overflow-hidden"
                      href={`${doctubeReelUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={
                          reel?.content_to_image_with_image?.length > 0
                            ? reel?.content_to_image_with_image[0]
                                ?.content_image_id?.thumbnail
                            : "https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg"
                        }
                        className="w-auto mw-100 h-100 object-fit-cover"
                        style={{ aspectRatio: "9/16" }}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
            <Link to={"/channel/videos/" + channel_id} className="ms-auto fs-6">
              View All{" "}
              <span className="transform-rotate-45">
                {findIcon("Arrow Up", "outline", 24)}
              </span>
            </Link>
          </div>
        </Card.Body>
      </Card>
      <ChannelCreateCampaignModal
        show={featuredSelectionModal}
        onHide={() => setFeaturedSelectionModal(false)}
        videos={allVideos}
        onContentSelect={onContentSelect}
      />
    </>
  );
}

export default React.memo(CustomizationLayout);
function FeaturedChannelCard({
  title,
  thumbnail,
  description,
  featuredVideoUrl,
  removeFeaturedVideoHandler,
}) {
  return (
    <div className="d-flex gap-3 flex-wrap">
      <div className="position-relative">
        <a
          href={`${featuredVideoUrl}`}
          target="_blank"
          className="ratio-9x16 h-rem-6 d-flex justify-content-center bg-light rounded-3 overflow-hidden"
          rel="noopener noreferrer"
        >
          <Image
            src={thumbnail}
            roundedSquare
            className="w-auto mw-100 h-100 object-fit-cover"
            style={{ aspectRatio: "16/9" }}
          />
        </a>

        <span
          className="position-absolute top-0 end-0 text-danger z-3 mt-n2 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
          onClick={removeFeaturedVideoHandler}
        >
          {findIcon("Trash", "dual-tone", 22)}
        </span>
      </div>

      <div>
        <h5 className="pt-2 mb-1 one-line-clamp ">{title}</h5>
        <p className="mb-0 two-line-clamp ">{description}</p>
      </div>
    </div>
  );
}
function VideoCard({ thumbnail, videoTitle, videoId, onContentSelect }) {
  return (
    <>
      <Col md="12">
        <div className="d-flex align-items-center gap-3">
          <img
            src={thumbnail}
            onError={(e) => {
              e.target.src =
                "https://www.keytechinc.com/wp-content/uploads/2022/01/video-thumbnail.jpg";
            }}
            roundedSquare
            style={{ height: "70px" }}
            fluid
            className="rounded-3 flex-shrink-0 ratio-16x9 w-rem-8"
          />
          {videoTitle && (
            <p className="m-0 two-line-clamp flex-grow-1">{videoTitle}</p>
          )}
          <Button
            variant="success"
            onClick={() => onContentSelect(videoId)}
            className="flex-shrink-0 px-3"
          >
            Select
          </Button>
        </div>
      </Col>
    </>
  );
}
function ChannelCreateCampaignModal(props) {
  const [searchInput, setSearchInput] = useState("");
  const onSearchInput = (e) => setSearchInput(e.target.value);
  let allVideoList = searchInput
    ? props?.videos?.filter(
        (video) =>
          video?.content_title
            ?.toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          video?.content_description
            ?.toLowerCase()
            .includes(searchInput.toLowerCase())
      )
    : props?.videos?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

  console.log("allVideoList", allVideoList);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="p-3"
    >
      <Modal.Body>
        <Row>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="my-0 fw-semibold"> Select video</h5>
            <span onClick={props.onHide} className="cursorPointer">
              {findIcon("Square X", "dual-tone", 25)}
            </span>
          </div>
          <div className="d-flex mt-3">
            <Form.Control
              type="text"
              placeholder="Search video or channel"
              className="rounded-3"
              name="search_query"
              onChange={onSearchInput}
            />
          </div>
        </Row>
        <Row className="gap-3 mt-3">
          {allVideoList?.length > 0
            ? allVideoList.slice(0, 4).map((data, key) => {
                return (
                  <VideoCard
                    key={key}
                    videoId={data.content_id}
                    videoTitle={data.content_title}
                    thumbnail={
                      data?.content_to_image_with_image?.length > 0
                        ? data?.content_to_image_with_image[0]?.content_image_id
                            ?.thumbnail
                        : ""
                    }
                    onContentSelect={() => props.onContentSelect(data)}
                  />
                );
              })
            : null}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
