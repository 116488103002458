import React from "react";
import { Card } from "react-bootstrap";

function TextPillCard({
  data,
  title,
  textSuccess = false,
  bgSecondary = false,
}) {
  return (
    <Card>
      <Card.Header>
        <h5 className="text-center subHeading position-relative">{title}</h5>
      </Card.Header>
      <Card.Body className={`d-flex flex-wrap justify-content-center gap-2 `}>
        {data?.length > 0 &&
          data.map((item, index) => {
            return (
              <span
                key={`${index}`}
                className={`border rounded-pill px-3 py-2 ${
                  bgSecondary ? "bg-soft-secondary" : ""
                } ${textSuccess ? "text-success" : "text-black-50"}`}
              >
                {item.name}
              </span>
            );
          })}
      </Card.Body>
    </Card>
  );
}

export default TextPillCard;
