import React, { memo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../components/skeleton";
import Table from "react-bootstrap/Table";

function CampaignListSkeleton() {
  return (
    <div>
      <Row>
        <Col
          md={12}
          className="d-flex justify-content-between align-items-end mb-4"
        >
          <Skeleton variant="text" />
          <Skeleton
            variant="square"
            className="rounded-2"
            width={150}
            height={30}
          />
        </Col>
      </Row>
      <Row>
        <Card>
          <Card.Body className="rounded-5">
            <Col md={12} lg={12}>
              <div>
                <Table striped>
                  <thead>
                    <tr>
                      {[...Array(5)].map((_, i) => (
                        <th key={i}>
                          <Skeleton variant="text" />
                          <div className="bg-white p-2 rounded-3">
                            <Skeleton
                              variant="square"
                              width="100%"
                              className="rounded-2"
                            />
                          </div>
                        </th>
                      ))}
                      <th className="m-0 p-4 pt-3 d-flex align-items-start justify-content-center">
                        <Skeleton variant="text" />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      {[...Array(5)].map((_, i) => (
                        <td key={i}>
                          <Skeleton variant="text" />
                        </td>
                      ))}

                      <td>
                        <div className="d-flex gap-3 align-items-center justify-content-center">
                          <Skeleton
                            variant="square"
                            width={20}
                            className="rounded-3"
                          />
                          <Skeleton
                            variant="square"
                            width={20}
                            className="rounded-3"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </div>
  );
}

export default memo(CampaignListSkeleton);
