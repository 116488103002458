import React from "react";
import { useDispatch } from "react-redux";
import { toogleBackdrop } from "../../store/common/actions";
const useBackdrop = () => {
  const dispatch = useDispatch();
  const showBackdrop = () => dispatch(toogleBackdrop(true));
  const hideBackdrop = () => dispatch(toogleBackdrop(false));
  return {
    showBackdrop,
    hideBackdrop,
  };
};

export default useBackdrop;
