import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UPDATE_CHANNEL } from "../../../../../../api/endpoints/channel-endpoint";
import { CHANNEL_VIDEO_UPDATE_BASIC } from "../../../../../../api/endpoints/channel-video-endpoints";
import { FILE_UPLOAD } from "../../../../../../api/endpoints/requisition-endpoints";
import postService from "../../../../../../api/services/post-service";
import uploadService from "../../../../../../api/services/upload-service";
import app_config from "../../../../../../common/data/app_config";
import useBackdrop from "../../../../../../components/custom-hooks/use-backdrop";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import { useGetChannelEditData } from "../../../../../../queries/query-hooks/workspace/channel-video-hook";
import CustomizationSkeleton from "../../../../../../skeleton/workspace/doctube/channel/customization/customization-skeleton";
import * as settingSelectors from "../../../../../../store/channel/channel.selector";
import TabNavigation from "../components/TabNavigation";
import CustomizationAppearance from "../components/customization/CustomizationAppearance";
import CustomizationBasicInfo from "../components/customization/CustomizationBasicInfo";
import CustomizationBranding from "../components/customization/CustomizationBranding";
import CustomizationLayout from "../components/customization/CustomizationLayout";
import { GET_CHANNEL_DETAILS_KEY } from "../../../../../../queries/query-constants/workspace/workspace-constant";
import { useQueryClient } from "@tanstack/react-query";
const IMAGE_BASE_URL = app_config.upload_file_destination;
export default function ChannelCustomization() {
  const { redirectTo } = useRedirect();
  const params = useParams();
  const { channel_id } = useParams();
  const [featuredVideoContentId, setFeaturedVideoContentId] = useState(null);
  const [disableSubmit, setDisabledSubmit] = useState(false);
  const { data, isFetching: isLoading } = useGetChannelEditData(
    channel_id,
    () => {}
  );
  const tabs = [
    {
      title: "Layout",
      eventKey: "layout",
      Content: () => (
        <CustomizationLayout eventKey="layout" data={data?.channel_detail} />
      ),
    },
    {
      title: "Branding",
      eventKey: "branding",
      Content: () => (
        <CustomizationBranding
          eventKey="branding"
          data={data?.channel_detail}
        />
      ),
    },
    {
      title: "Basic Info",
      eventKey: "basicInfo",
      Content: () => (
        <CustomizationBasicInfo
          eventKey="basicInfo"
          data={data?.channel_detail}
          setDisabledSubmit={setDisabledSubmit}
        />
      ),
    },
    {
      title: "Appearance",
      eventKey: "appearance",
      Content: () => (
        <CustomizationAppearance
          eventKey="appearance"
          data={data?.channel_detail}
        />
      ),
    },
  ];
  const channelInputs = useSelector(settingSelectors.channelInputs);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const queryClient = useQueryClient();
  const memorizedTabs = React.useMemo(() => tabs, [data?.channel_detail]);

  useEffect(() => {
    setFeaturedVideoContentId(channelInputs?.featuredVideo?.content_id);
  }, []);

  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }

      try {
        const uploadPromises = files.map(async (file) => {
          if (file && typeof file === "object") {
            let formdata = new FormData();
            formdata.append("files[]", file);

            // Upload file to server
            let response = await uploadService(FILE_UPLOAD, formdata);
            if (response.data?.success) {
              return response.data.data[0].url;
            } else {
              return "";
            }
          } else if (typeof file === "string") {
            return file;
          } else {
            return "";
          }
        });

        // Wait for all uploads to complete
        const urls = await Promise.all(uploadPromises);

        resolve(urls);
      } catch (error) {
        reject(error.message);
      }
    });
  }
  const handleSave = async () => {
    // Function to validate required fields
    const validateInputs = () => {
      const requiredFields = [
        // {
        //   value: channelInputs?.featuredVideo?.content_id,
        //   name: "Featured Video",
        // },
        { value: channelInputs?.name, name: "Channel Name" },
        { value: channelInputs?.description, name: "Channel Description" },
        { value: channelInputs?.profilePicture, name: "Profile Picture" },
        { value: channelInputs?.coverPicture, name: "Cover Picture" },
        { value: channelInputs?.channelUrl, name: "Channel URL" },
      ];

      for (let field of requiredFields) {
        if (!field.value) {
          toast.error(`Please provide the ${field.name}`);
          return false;
        }
      }

      // Validate channel URL
      try {
        new URL(channelInputs.channelUrl);
      } catch (e) {
        toast.error("Please provide a valid URL for the Channel URL.");
        return false;
      }

      //validate consult url
      if (channelInputs?.consultUrl) {
        try {
          new URL(channelInputs?.consultUrl);
        } catch (e) {
          toast.error("Please provide a valid URL for the Consult URL.");
          return false;
        }
      }

      return true;
    };

    // Validate inputs before proceeding
    if (!validateInputs()) {
      return; // Early return if validation fails
    }
    showBackdrop();
    // Continue with the existing logic if validation passes
    if (channelInputs?.featuredVideo) {
      if (featuredVideoContentId) {
        let removeResponse = await postService(CHANNEL_VIDEO_UPDATE_BASIC, {
          content_id: featuredVideoContentId,
          field: "content_featured_status",
          value: "0",
        });
      }

      let addResponse = await postService(CHANNEL_VIDEO_UPDATE_BASIC, {
        content_id: channelInputs?.featuredVideo?.content_id,
        field: "content_featured_status",
        value: "1",
      });
    }
    const {
      profilePicture = "",
      coverPicture = "",
      watermark = "",
      channelTopLeftGraphics = "",
      channelRightBottomGraphics = "",
      channelBackground = "",
    } = channelInputs;

    const urls = await uploadFiles([
      profilePicture,
      coverPicture,
      watermark,
      channelTopLeftGraphics,
      channelRightBottomGraphics,
      channelBackground,
    ]);

    const [
      profilePictureUrl,
      coverPictureUrl,
      watermarkUrl,
      channelTopLeftGraphicsUrl,
      channelRightBottomGraphicsUrl,
      channelBackgroundUrl,
    ] = urls;
    console.log("URLS", urls);
    if (urls?.length > 0) {
      const settings = {
        template_id: channelInputs?.theme,
        consult_url: channelInputs?.consultUrl,
        primary_color: channelInputs?.brandPrimaryColor,
        secondary_color: channelInputs?.brandSecondaryColor,
        top_left_conner_bg: channelTopLeftGraphicsUrl,
        bottom_right_conner_bg: channelRightBottomGraphicsUrl,
        full_bg: channelBackgroundUrl,
        external_ga_id: channelInputs?.customGaTag,

        tabs: JSON.stringify(channelInputs?.tabs),
        consultButton: {
          color: channelInputs?.consultButtonColor,
          text: channelInputs?.consultButtonText,
        },
        subscribeButton: {
          color: channelInputs?.subscribeButtonColor,
          text: channelInputs?.subscribeButtonText,
        },
        subscribedButton: {
          color: channelInputs?.subscribeButtonColor,
          text: channelInputs?.subscribeButtonText,
        },
        isAdsOff: channelInputs?.additional.includes("turn-off-ads")
          ? true
          : false,
      };

      let payloadObj = {
        content_id: params?.channel_id,
        content_file_type_id: "0",
        content_title: channelInputs?.name,
        content_description: channelInputs.description,
        content_category_id: "31",
        content_category_code: "doctube channel",
        content_brand_id: 1,
        content_privacy_status: "0",
        content_status: "1",
        content_publishing_status: "3",

        content_featured_status: "0",

        content_to_images: {
          base: coverPictureUrl?.replace(IMAGE_BASE_URL, ""),
          base_full_url: coverPictureUrl,
          thumbnail: profilePictureUrl?.replace(IMAGE_BASE_URL, ""),
          thumbnail_full_url: profilePictureUrl,
        },
        content_to_attributes: {
          watermark: watermarkUrl,
          watermarkPosition: channelInputs?.watermarkPosition,
          channelHandle: channelInputs?.handle,
          channelUrl: channelInputs?.channelUrl,
          additional: JSON.stringify(channelInputs?.additional),
          settings: JSON.stringify(settings),
        },
        dt_payload: {
          channel_name: channelInputs?.name,
          about: channelInputs?.description,
          avatar: profilePictureUrl,
          cover: coverPictureUrl,
          setting_json: settings,
          ga_code: channelInputs?.customGaTag,
          logo: watermarkUrl,
          logo_position: channelInputs?.watermarkPosition,
        },
      };

      let response = await postService(UPDATE_CHANNEL, payloadObj);
      console.log("RESPONSE", response);
      if (response.data?.success) {
        toast.success("Channel updated successfully");
        // redirectTo("channel/dashboard/" + params?.channel_id);
        hideBackdrop();
        queryClient.invalidateQueries({
          queryKey: [GET_CHANNEL_DETAILS_KEY, params?.channel_id],
        });
      } else {
        toast.error("Something went wrong");
        hideBackdrop();
      }
    }
  };
  const handlePreview = async () => {
    const {
      profilePicture = "",
      coverPicture = "",
      watermark = "",
      channelTopLeftGraphics = "",
      channelRightBottomGraphics = "",
      channelBackground = "",
    } = channelInputs;
    showBackdrop();
    const urls = await uploadFiles([
      profilePicture,
      coverPicture,
      watermark,
      channelTopLeftGraphics,
      channelRightBottomGraphics,
      channelBackground,
    ]);

    const [
      profilePictureUrl,
      coverPictureUrl,
      watermarkUrl,
      channelTopLeftGraphicsUrl,
      channelRightBottomGraphicsUrl,
      channelBackgroundUrl,
    ] = urls;
    const templatePreview = {
      channel_name: channelInputs?.name,
      channel_avatar: profilePictureUrl,
      channel_about: channelInputs.description,
      channel_cover: coverPictureUrl,
      template_id: channelInputs?.theme,
      consult_url: channelInputs?.consultUrl,
      primary_color: channelInputs?.brandPrimaryColor,
      secondary_color: channelInputs?.brandSecondaryColor,
      top_left_conner_bg: channelTopLeftGraphicsUrl,
      bottom_right_conner_bg: channelRightBottomGraphicsUrl,
      full_bg: channelBackgroundUrl,
      external_ga_id: channelInputs?.customGaTag,

      tabs: JSON.stringify(channelInputs?.tabs),
      consultButton: {
        color: channelInputs?.consultButtonColor,
        text: channelInputs?.consultButtonText,
      },
      subscribeButton: {
        color: channelInputs?.subscribeButtonColor,
        text: channelInputs?.subscribeButtonText,
      },
      subscribedButton: {
        color: channelInputs?.subscribeButtonColor,
        text: channelInputs?.subscribeButtonText,
      },
      isAdsOff: channelInputs?.additional.includes("turn-off-ads")
        ? true
        : false,
    };
    console.log("TEMPLATE_PREVIEW", templatePreview);
    hideBackdrop();
    window.open(
      app_config.doctube_url +
        channelInputs?.channelSlug +
        "?tm=" +
        btoa(JSON.stringify(templatePreview)?.replaceAll("#", "%23")),
      "_blank"
    );
  };
  if (isLoading) {
    return <CustomizationSkeleton />;
  }

  return (
    <>
      <h4 className="fw-semibold mb-0">Channel Customise</h4>

      {data?.channel_detail && (
        <TabNavigation tabs={memorizedTabs} onSelect={(e) => {}} />
      )}
      <Row>
        <Col className="d-flex mt-3 mb-4">
          <div className="ms-auto d-flex flex-wrap gap-2">
            <Button
              variant="light"
              onClick={() =>
                redirectTo("channel/dashboard/" + params?.channel_id)
              }
            >
              Cancel
            </Button>
            <Button variant="soft-primary" onClick={handlePreview}>
              Preview
            </Button>
            <Button
              variant="primary"
              onClick={handleSave}
              disabled={disableSubmit}
            >
              Save Changes
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}
