import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  FloatingLabel,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import avatars11 from "../../../../assets/images/avatars/01.png";
import toast from "react-hot-toast";
import ReactTable from "../../../../lib/table/react-table";

const ConsentCollectionView = () => {
  const [modalShow, setModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [datashow, setDataShow] = useState(false);
  const [comment, setComment] = useState("");

  const [results, setResults] = useState([]);
  console.log(comment, results);

  const modalClose = () => {
    setViewModalShow(false);
  };
  // const modalOpen = () => {
  //   setModalShow(true);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setViewModalShow(false);
    if (comment !== "") {
      setDataShow(true);
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const data = {
        id: results?.length + 1,
        comment: comment,
        commentator_name: `Commentator ${results?.length + 1}`,
        createdAt: date + " " + time,
      };

      setResults((prev) => [...prev, data]);
    } else {
      setDataShow(false);
      toast.error("No comments to show");
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Comments",
      accessor: "comment",
    },
    {
      Header: "Commentor Name",
      accessor: "commentator_name",
    },
    {
      Header: "Added On",
      accessor: "createdAt",
    },
  ];
  return (
    <>
      <Card>
        <Card.Body>
          <div>
            <div className="d-flex gap-2">
              <Badge pill bg="danger" className="fw-normal">
                High
              </Badge>
              <Badge pill bg="info" className="fw-normal">
                In Progress
              </Badge>
              <Badge pill bg="soft-warning" className="fw-normal">
                Deadline 03 May 2023
              </Badge>
            </div>
            <h4 className="mt-4 mb-0">Action Name Goes Here</h4>

            <p className="mt-1 mb-0">
              Onboard and collect consent from this user. Also, try to verify
              and update information.
            </p>
            <div className="d-flex justify-content-end gap-3 mt-1">
              <span className="text-dark fs-6">Onboarding</span>
              <span> | </span>
              <span className="text-dark fs-6">Assigned By Amit Singh</span>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div>
            <div className="d-flex justify-content-between mb-4 align-items-center">
              <h5 className="mb-0">Onboarding Consent Collection</h5>
              {/* <Button>View All</Button> */}
            </div>
            <Table responsive className="table-bordered">
              <thead>
                <tr>
                  <th className="text-dark fw-semibold">Label</th>
                  <th className="text-dark fw-semibold">Old Record</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={3} className="fs-6 py-2 fw-normal">
                    Personal Information
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Name</td>
                  <td className="text-dark fw-normal">Austin Robertson</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">DOB</td>
                  <td className="text-dark fw-normal">30/09/1998</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Gender</td>
                  <td className="text-dark fw-normal">Male</td>
                </tr>
                <tr>
                  <td colSpan={3} className="fs-6 py-2 fw-normal">
                    Contact Preference
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Email</td>
                  <td className="text-dark fw-normal">
                    Swagata.Das@Clirnet.com
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Mobile No.</td>
                  <td className="text-dark fw-normal">9564300942</td>
                </tr>
                <tr>
                  <td colSpan={3} className="fs-6 py-2 fw-normal">
                    Medical Information
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Collage Name</td>
                  <td className="text-dark fw-normal">
                    Haldia Institute of Tecgnology
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Degree</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Type of Practice</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Registration No.</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Registration State</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">
                    Classification Core SL
                  </td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Interest Speciality</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">
                    Is Government Doctor
                  </td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td colSpan={3} className="fs-6 py-2 fw-normal">
                    Location
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Pincode</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Pincode</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">City</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">State</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Zone</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Country</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td colSpan={3} className="fs-6 py-2 fw-normal">
                    Onboarding Information
                  </td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Owner</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Medium</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">User Type</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
                <tr>
                  <td className="text-dark fw-semibold">Social Media Links</td>
                  <td className="text-dark fw-normal">MCA</td>
                </tr>
              </tbody>
            </Table>
            {/* <Col lg="12" className="d-flex justify-content-end gap-2">
              <Button variant="danger">Reject</Button>
              <Button onClick={() => setViewModalShow(true)}>Approve</Button>
            </Col> */}
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={viewModalShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Table>
            <tbody>
              <FloatingLabel
                controlId="floatingTextarea2"
                label="Comments"
                className="w-100"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  onChange={(e) => setComment(e.target.value)}
                />
              </FloatingLabel>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button variant="danger" onClick={modalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {
        results && results.length > 0 && (
          <ReactTable
            data={results}
            columns={columns}
            style={{ overflow: "wrap" }}
          />
        )
        // <Row className="mt-5">
        //   <Col lg="6" md="6" sm="12">
        //     <h3>Your Comments</h3>
        //     <p style={{ wordBreak: 'break-all' }}>{comment}</p>

        //   </Col>
        // </Row>
      }
    </>
  );
};

export default ConsentCollectionView;
