import React, { useEffect, useState } from "react";
import { Button, Modal, Col, Form, Row, Table } from "react-bootstrap";
import postService from "../../../api/services/post-service";
import toast from "react-hot-toast";
import {
  ADD_PRINCIPLE_ENTITY,
  ALL_PRINCIPLE_ENTITIES,
  UPDATE_PRINCIPLE_ENTITY,
} from "../../../api/endpoints/principle-entity-endpoints";
import TreeViewList from "./TreeViewList";
import useBackdrop from "../../custom-hooks/use-backdrop";

function buildHierarchy(data = []) {
  const entityMap = {};
  data.forEach((entity) => {
    entity.children = [];
    entityMap[entity.principal_entity_id] = entity;
  });

  const hierarchy = [];
  data.forEach((entity) => {
    if (entity.principal_entity_parent_id === 0) {
      hierarchy.push(entity);
    } else {
      const parent = entityMap[entity.principal_entity_parent_id];
      if (parent) {
        parent.children.push(entity);
      }
    }
  });

  return hierarchy;
}

function findEntityById(data, id) {
  let stack = [...data];

  while (stack.length) {
    let current = stack.pop();

    if (current.principal_entity_id === id) {
      return current;
    }

    if (current.children && current.children.length) {
      stack.push(...current.children);
    }
  }

  return null;
}

const ViewModal = ({
  title,
  show,
  modalClose,
  data,
  isEdit = false,
  fetchPrincipleEntities = () => {},
  post_json = "",
  component = "",
}) => {
  const [principleEntityList, setPrincipleEntityList] = useState([]);
  const [treeViewLoading, setTreeViewLoading] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [entity, setEntity] = useState({
    principal_entity_name: "",
    principal_entity_description: "",
  });
  const [principleEntity, setPrincipleEntity] = useState({
    "principle Entity ID": "",
    "principle Entity Name": "",
    "principle Entity Description": "",
    "principal Entity Parent ID": "",
  });

  useEffect(() => {
    setPrincipleEntity({
      "principle Entity ID": data && data["principle Entity ID"],
      "principle Entity Name": data && data["principle Entity Name"],
      "principle Entity Description":
        data && data["principle Entity Description"],
      "principal Entity Parent ID": data && data["principal Entity Parent ID"],
    });
    if (data) {
      getAllPrincipleEntities(data["principle Entity ID"]);
    }

    return () => {
      setPrincipleEntityList([]);
    };
  }, [data]);

  const getAllPrincipleEntities = async (principalEntityId) => {
    try {
      setTreeViewLoading(true);
      let response = await postService(ALL_PRINCIPLE_ENTITIES, {
        default: "all",
        pagination: {
          limit: 100,
          offset: 0,
        },
      });

      if (response?.data?.data?.rows) {
        const formatedAllPrincipleEntities = buildHierarchy(
          response?.data?.data?.rows
        );
        const entityById = findEntityById(
          formatedAllPrincipleEntities,
          principalEntityId
        );
        setPrincipleEntityList(entityById);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTreeViewLoading(false);
    }
  };

  const onChangeHandler = (e) => {
    setPrincipleEntity({
      ...principleEntity,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !principleEntity["principle Entity Name"] ||
      !principleEntity["principle Entity Description"]
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    let postObject = {
      principal_entity_name: principleEntity["principle Entity Name"],
      principal_entity_description:
        principleEntity["principle Entity Description"],
      principal_entity_parent_id: principleEntity["principal Entity Parent ID"],
    };
    const url =
      UPDATE_PRINCIPLE_ENTITY + "/" + principleEntity["principle Entity ID"];

    try {
      showBackdrop();
      let response = await postService(url, postObject);

      if (response?.data?.success) {
        setPrincipleEntity({
          "principle Entity ID": "",
          "principle Entity Name": "",
          "principle Entity Description": "",
        });

        fetchPrincipleEntities(post_json?.current);
        toast.success(response?.data?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      modalClose();
      hideBackdrop();
    }
  };

  const addEntityHandler = (e) => {
    setEntity((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddEntitySubmit = async (e) => {
    e.preventDefault();
    const postData = {
      principal_entity_parent_id: data["principle Entity ID"],
      ...entity,
    };

    if (!entity.principal_entity_name || !entity.principal_entity_description) {
      toast.error("Please fill all required fields");
      return;
    }

    try {
      showBackdrop();
      let response = await postService(ADD_PRINCIPLE_ENTITY, postData);

      if (response?.data?.success) {
        setEntity({
          principal_entity_name: "",
          principal_entity_description: "",
        });
        getAllPrincipleEntities(data["principle Entity ID"]);
        fetchPrincipleEntities(post_json?.current);
        toast.success(response?.data?.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      hideBackdrop();
    }
  };

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onClick={modalClose}>
          <Modal.Title id="contained-modal-title-vcenter">
            {component === "master-management"
              ? `${title} #${data ? data["principle Entity ID"] : ""}`
              : title}
          </Modal.Title>
        </Modal.Header>
        {component === "master-management" ? (
          <Modal.Body>
            {!isEdit ? (
              <Table>
                <tbody>
                  {data &&
                    Object.keys(data).map((key, index) => {
                      if (key === "principle Entity ID") return;
                      const value =
                        (!!key &&
                          ["Principal"]
                            .concat(key?.split(" ")?.slice(1))
                            ?.join(" ")) ||
                        key;
                      return (
                        <tr key={index}>
                          <td className="text-capitalize">{value}</td>
                          <td className="text-wrap">{data[key]}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <Form onSubmit={handleSubmit}>
                {/* <Form.Group
                className="row g-3 align-items-center justify-content-between mb-3"
                controlId="formBasicEmail"
              >
                <div class="col-auto" style={{ width: "15rem" }}>
                  <Form.Label>Principle Entity ID</Form.Label>
                </div>
                <div class="col-auto flex-grow-1">
                  <Form.Control
                    type="text"
                    placeholder="Enter principle Entity ID"
                    disabled
                    name="principle Entity ID"
                    value={principleEntity["principle Entity ID"]}
                    onChange={onChangeHandler}
                  />
                </div>
              </Form.Group> */}

                <Form.Group
                  className="row g-3 align-items-center mb-3"
                  controlId="formBasicPassword"
                >
                  <div class="col-auto" style={{ width: "15rem" }}>
                    <Form.Label>Principal Entity Name</Form.Label>
                  </div>
                  <div class="col-auto flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder="Enter principle Entity Name"
                      name="principle Entity Name"
                      value={principleEntity["principle Entity Name"]}
                      onChange={onChangeHandler}
                    />
                  </div>
                </Form.Group>

                <Form.Group
                  className="row g-3 align-items-center mb-3"
                  controlId="formBasicPassword"
                >
                  <div class="col-auto" style={{ width: "15rem" }}>
                    <Form.Label>Principal Entity Description</Form.Label>
                  </div>
                  <div class="col-auto flex-grow-1">
                    <Form.Control
                      type="text"
                      placeholder="Enter principle Entity Description"
                      name="principle Entity Description"
                      value={principleEntity["principle Entity Description"]}
                      onChange={onChangeHandler}
                    />
                  </div>
                </Form.Group>
                <div className="d-flex justify-content-center">
                  <Button
                    variant="success"
                    type="submit"
                    className="btn-sm rounded-2"
                    disabled={
                      data["principle Entity Name"] ===
                        principleEntity["principle Entity Name"] &&
                      data["principle Entity Description"] ===
                        principleEntity["principle Entity Description"]
                    }
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}

            {!treeViewLoading ? (
              principleEntityList?.children?.length > 0 ? (
                <TreeViewList
                  data={principleEntityList}
                  getAllPrincipleEntities={getAllPrincipleEntities}
                  fetchPrincipleEntities={fetchPrincipleEntities}
                  post_json={post_json}
                  isEdit={isEdit}
                />
              ) : (
                <p className="text-danger bg-light border border-danger rounded p-3 mb-3 shadow-sm text-center fw-semibold mt-3">
                  No child principal entity list found!
                </p>
              )
            ) : (
              <p className="text-primary bg-light border border-primary rounded p-3 mb-3 shadow-sm text-center fw-semibold mt-3">
                Loading...
              </p>
            )}

            {isEdit && (
              <form
                class="d-flex  gap-4 align-items-center my-3"
                onSubmit={handleAddEntitySubmit}
              >
                <div className="flex-grow-1 input-group-sm">
                  <label for="entityName" class="form-label">
                    Principal Entity Name
                  </label>
                  <input
                    name="principal_entity_name"
                    id="entityName"
                    type="text"
                    class="form-control"
                    placeholder="Enter Entity Name*"
                    required
                    value={entity.principal_entity_name}
                    onChange={addEntityHandler}
                  ></input>
                </div>

                <div className="flex-grow-1 input-group-sm">
                  <label for="entityDescription" class="form-label">
                    Principal Entity Description
                  </label>
                  <input
                    name="principal_entity_description"
                    id="entityDescription"
                    type="text"
                    class="form-control"
                    placeholder="Enter Entity Description*"
                    required
                    value={entity.principal_entity_description}
                    onChange={addEntityHandler}
                  ></input>
                </div>

                <div className="flex-grow-2">
                  <button
                    className="btn btn-primary btn-sm rounded-2"
                    type="submit"
                  >
                    Add
                  </button>
                </div>
              </form>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Table>
              <tbody>
                {data &&
                  Object.keys(data).map((key, index) => {
                    return (
                      <tr key={index}>
                        <td>{key}</td>
                        <td className="text-wrap">{data[key]}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button onClick={modalClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModal;
