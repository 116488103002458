import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import app_config from "../../../../../../../common/data/app_config";
import { findValueByField } from "../../../../../../../common/utils/utils";
import useBackdrop from "../../../../../../../components/custom-hooks/use-backdrop";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { updateChannelInput } from "../../../../../../../store/channel/channel.action";
import * as settingSelectors from "../../../../../../../store/channel/channel.selector";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
import { findIcon } from "../../../../../../dashboard/icons";
const IMAGE_BASE_URL = app_config.upload_file_destination;

function CustomizationAppearance({ data }) {
  const dispatch = useDispatch();
  const channelTopLeftGraphicsRef = useRef(null);
  const channelRightBottomGraphicsRef = useRef(null);
  const channelBackgroundRef = useRef(null);

  const tabOptions = [
    {
      item: "home_title",
      tab: "Home",
    },
    {
      item: "videos_title",
      tab: "Videos",
    },
    {
      item: "play_lists_title",
      tab: "Playlists",
    },
    {
      item: "medical_reals_title",
      tab: "Health Reels",
    },
    {
      item: "about_profile_title",
      tab: "About",
    },
  ];

  const [inputs, setInputs] = useState({
    theme: "",
    brandPrimaryColor: "#F15A29",
    brandSecondaryColor: "#594A42",
    channelTopLeftGraphics: "",
    channelRightBottomGraphics: "",
    channelBackground: "",
    consultButtonText: "",
    consultButtonColor: "#594A42",
    subscribeButtonText: "",
    subscribeButtonColor: "#F15A29",
    consultUrl: "",
    tabs: [],
    additional: [],
  });
  // console.log(inputs);
  const themes = [
    {
      name: "Template 1",
      id: 1,
      url: fallbackImages.doctubeTemplates.template_1,
    },
    {
      name: "Template 2",
      id: 2,
      url: fallbackImages.doctubeTemplates.template_2,
    },
    {
      name: "Template 3",
      id: 3,
      url: fallbackImages.doctubeTemplates.template_3,
    },
  ];
  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type == "file" && files?.length <= 0) {
      return;
    }
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]:
        type == "file"
          ? files[0]
          : type === "checkbox" || type === "radio"
          ? checked
          : value,
    }));
  };

  const handleTabsFeatureChange = (item) => {
    if (inputs.tabs.includes(item)) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        tabs: inputs.tabs.filter((tab) => tab !== item),
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        tabs: [...inputs.tabs, item],
      }));
    }
  };

  const handleAdditionalChange = (item) => {
    if (inputs.additional.includes(item)) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        additional: inputs.additional.filter((tab) => tab !== item),
      }));
    } else {
      setInputs((prevInputs) => ({
        ...prevInputs,
        additional: [...inputs.additional, item],
      }));
    }
  };
  useEffect(() => {
    let settings = findValueByField(data?.content_to_attr, "settings");
    let additional = findValueByField(data?.content_to_attr, "additional");
    if (settings) {
      settings = JSON.parse(settings);
    }
    if (additional) {
      additional = JSON.parse(additional);
    }
    setInputs({
      theme: settings?.template_id || "1",
      brandPrimaryColor: settings?.primary_color || "#F15A29",
      brandSecondaryColor: settings?.secondary_color || "#594A42",
      channelTopLeftGraphics: settings?.top_left_conner_bg || "",
      channelRightBottomGraphics: settings?.bottom_right_conner_bg || "",
      channelBackground: settings?.full_bg || "",
      consultButtonText: settings?.consultButton?.text || "Consult",
      consultButtonColor: settings?.consultButton?.color || "#594A42",
      subscribeButtonText: settings?.subscribeButton?.text || "Subscribe",
      subscribeButtonColor: settings?.subscribeButton?.color || "#F15A29",
      consultUrl: settings?.consult_url || "",
      tabs: settings?.tabs
        ? JSON.parse(settings?.tabs)
        : tabOptions.map((item) => item.item),
      additional: additional,
    });
  }, [data?.content_to_attr]);
  useEffect(() => {
    dispatch(updateChannelInput(inputs));
  }, [inputs]);
  console.log(inputs, "inputs");

  async function cleanChannelCache(channel) {
    let response = await Promise.all(
      ["english", "hindi", "bengali", "tamil", "telugu"].map((lang) => {
        return axios.get(
          `${
            app_config.doctube_url
          }api/cache/delete?key=/${channel}&force_revalidate=true${
            lang ? `&lang=${lang}` : ""
          }`
        );
      })
    );
    return response;
  }
  const removeChannelGraphics = (channelGraphic) => () => {
    setInputs((prev) => ({ ...prev, [channelGraphic]: null }));
    switch (channelGraphic) {
      case "channelTopLeftGraphics":
        channelTopLeftGraphicsRef.current.value = "";
        break;
      case "channelRightBottomGraphics":
        channelRightBottomGraphicsRef.current.value = "";
        break;
      case "channelBackground":
        channelBackgroundRef.current.value = "";
        break;
      default:
        break;
    }
  };

  return (
    <Card>
      <Card.Body>
        <Col md={12} className="mb-4">
          <h5>Appearance</h5>
        </Col>
        <div className="d-flex flex-column gap-5">
          <Row className="align-items-md-center">
            <Col
              md={4}
              className="mb-3 mb-md-0 justify-content-center align-items-center"
            >
              <h5 className="mb-1">Interface Theme</h5>
              <p className="mb-0">Select or Customise your Theme</p>
            </Col>

            <Col md={8}>
              <Row>
                {themes?.map(({ name, id, url }) => (
                  <Col xs={6} md={4} key={id}>
                    <div className="form-check position-relative m-0 p-0 d-flex channelCustomization__img_check">
                      <input
                        type="radio"
                        name="theme"
                        class="form-check-input position-absolute top-0 start-0 w-100 h-100 m-0 opacity-0"
                        id={`theme_${id}`}
                        onChange={() => {
                          setInputs((prevInputs) => ({
                            ...prevInputs,
                            theme: id,
                          }));
                        }}
                        checked={inputs.theme == id}
                      />
                      <label
                        class="form-check-label p-2 rounded-3 overflow-hidden border"
                        for={`theme_${id}`}
                      >
                        <div className="w-100 h-auto rounded-2 overflow-hidden">
                          <img src={url} className="w-100 h-auto" alt="" />
                        </div>
                      </label>
                    </div>
                    <h6 className="p-3">{name}</h6>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Row className="align-items-md-center">
            <Col
              md={4}
              className="mb-3 mb-md-0 justify-content-center align-items-center"
            >
              <h5 className="mb-1">Brand Color</h5>
              <p className="mb-0">Pick your brand theme color</p>
            </Col>

            <Col md={8}>
              <Row>
                <Col className="d-flex gap-md-4 gap-3 flex-wrap">
                  <div className="position-relative py-3 px-4 border rounded-pill d-flex align-items-center justify-content-center overflow-hidden gap-2 text-black">
                    <Form.Control
                      className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                      type="color"
                      id="exampleColorInput"
                      title="Choose your color"
                      name="brandPrimaryColor"
                      value={inputs.brandPrimaryColor}
                      onChange={handleInputChange}
                    />
                    <span>{inputs.brandPrimaryColor}</span>
                    <span
                      className="icon-24 rounded-circle channelCustomization__color"
                      style={{
                        border: "1px solid " + inputs.brandPrimaryColor,
                      }}
                    >
                      <div
                        className="w-100 h-100 rounded-circle"
                        style={{ backgroundColor: inputs.brandPrimaryColor }}
                      ></div>
                    </span>
                  </div>
                  <div className="position-relative py-3 px-4 border rounded-pill d-flex align-items-center justify-content-center overflow-hidden gap-2 text-black">
                    <Form.Control
                      className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                      type="color"
                      id="exampleColorInput"
                      title="Choose your color"
                      name="brandSecondaryColor"
                      value={inputs.brandSecondaryColor}
                      onChange={handleInputChange}
                    />
                    <span>{inputs.brandSecondaryColor}</span>
                    <span
                      className="icon-24 rounded-circle channelCustomization__color"
                      style={{
                        border: "1px solid " + inputs.brandSecondaryColor,
                      }}
                    >
                      <div
                        className="w-100 h-100 rounded-circle"
                        style={{ backgroundColor: inputs.brandSecondaryColor }}
                      ></div>
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="align-items-md-start">
            <Col
              md={4}
              className="mb-3 mb-md-0 justify-content-center align-items-center"
            >
              <h5 className="mb-1">Channel Graphics</h5>
              <p className="mb-0">Upload your channel graphics</p>
            </Col>

            <Col md={8}>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Label className="text-black">
                    Top Left Corner Graphics
                  </Form.Label>
                  <Form.Control
                    type="file"
                    ref={channelTopLeftGraphicsRef}
                    onChange={handleInputChange}
                    name="channelTopLeftGraphics"
                  />
                  {inputs.channelTopLeftGraphics && (
                    <div className="d-flex justify-content-start position-relative">
                      <Image
                        src={
                          typeof inputs.channelTopLeftGraphics === "string"
                            ? inputs.channelTopLeftGraphics
                            : URL.createObjectURL(inputs.channelTopLeftGraphics)
                        }
                        width={300}
                        height={150}
                        rounded
                      />
                      <span
                        className="position-absolute top-0 end-0 text-danger z-3 mt-n2 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
                        onClick={removeChannelGraphics(
                          "channelTopLeftGraphics"
                        )}
                      >
                        {findIcon("Trash", "dual-tone", 22)}
                      </span>
                    </div>
                  )}
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label className="text-black">
                    Right Bottom Corner Graphics
                  </Form.Label>
                  <Form.Control
                    type="file"
                    ref={channelRightBottomGraphicsRef}
                    onChange={handleInputChange}
                    name="channelRightBottomGraphics"
                  />
                  {inputs.channelRightBottomGraphics && (
                    <div className="d-flex justify-content-end position-relative">
                      <Image
                        src={
                          typeof inputs.channelRightBottomGraphics === "string"
                            ? inputs.channelRightBottomGraphics
                            : URL.createObjectURL(
                                inputs.channelRightBottomGraphics
                              )
                        }
                        width={300}
                        height={150}
                        rounded
                      />
                      <span
                        className="position-absolute top-0 end-0 text-danger z-3 mt-n2 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
                        onClick={removeChannelGraphics(
                          "channelRightBottomGraphics"
                        )}
                      >
                        {findIcon("Trash", "dual-tone", 22)}
                      </span>
                    </div>
                  )}
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label className="text-black">
                    Background Graphics
                  </Form.Label>
                  <Form.Control
                    type="file"
                    ref={channelBackgroundRef}
                    onChange={handleInputChange}
                    name="channelBackground"
                  />
                  {inputs.channelBackground && (
                    <div className="d-flex justify-content-center position-relative">
                      <Image
                        src={
                          typeof inputs.channelBackground === "string"
                            ? inputs.channelBackground
                            : URL.createObjectURL(inputs.channelBackground)
                        }
                        width={300}
                        height={150}
                        rounded
                      />
                      <span
                        className="position-absolute top-0 end-0 text-danger z-3 mt-n2 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
                        onClick={removeChannelGraphics("channelBackground")}
                      >
                        {findIcon("Trash", "dual-tone", 22)}
                      </span>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="align-items-md-start">
            <Col
              md={4}
              className="mb-3 mb-md-0 justify-content-center align-items-center"
            >
              <h5 className="mb-1">Button</h5>
              <p className="mb-0">Customise your channel buttons</p>
            </Col>

            <Col md={8}>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Label className="text-black">CTA Button 1</Form.Label>
                  <div className="position-relative channelCustomization__color--squarePrnt">
                    <Form.Control
                      type="text"
                      placeholder="Custom button text"
                      name="consultButtonText"
                      value={inputs.consultButtonText}
                      onChange={handleInputChange}
                    />

                    <span
                      className="icon-24 position-absolute rounded-2 top-50 end-0 icon-24 me-3 translate-middle-y channelCustomization__color channelCustomization__color--square"
                      style={{
                        border: "1px solid " + inputs.consultButtonColor,
                      }}
                    >
                      <Form.Control
                        className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                        type="color"
                        id="exampleColorInput"
                        title="Choose your color"
                        name="consultButtonColor"
                        value={inputs.consultButtonColor}
                        onChange={handleInputChange}
                      />
                      <div
                        className="w-100 h-100 rounded-2"
                        style={{ backgroundColor: inputs.consultButtonColor }}
                      ></div>
                    </span>
                  </div>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Label className="text-black">CTA Button 2</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="Custom button text"
                      name="subscribeButtonText"
                      value={inputs.subscribeButtonText}
                      onChange={handleInputChange}
                    />

                    <span
                      className="icon-24 position-absolute rounded-2 top-50 end-0 icon-24 me-3 translate-middle-y channelCustomization__color channelCustomization__color--square"
                      style={{
                        border: "1px solid " + inputs.subscribeButtonColor,
                      }}
                    >
                      <Form.Control
                        className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
                        type="color"
                        id="exampleColorInput"
                        title="Choose your color"
                        name="subscribeButtonColor"
                        value={inputs.subscribeButtonColor}
                        onChange={handleInputChange}
                      />
                      <div
                        className="w-100 h-100 rounded-2"
                        style={{ backgroundColor: inputs.subscribeButtonColor }}
                      ></div>
                    </span>
                  </div>
                </Col>

                <Col className="mb-3">
                  <Form.Label className="text-black">
                    CTA Button 1 url
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Paste your url"
                    name="consultUrl"
                    value={inputs.consultUrl}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="align-items-md-center">
            <Col
              md={4}
              className="mb-3 mb-md-0 justify-content-center align-items-center"
            >
              <h5 className="mb-1">Tabs feature</h5>
              <p className="mb-0">What shows on your channel</p>
            </Col>

            <Col md={8}>
              <Row>
                <Col className="d-flex gap-md-4 gap-3 flex-wrap">
                  {tabOptions?.map(({ item, tab }) => (
                    <div key={tab} className="form-check m-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name={item}
                        id={item + "check"}
                        checked={inputs.tabs?.includes(item)}
                        onChange={() => handleTabsFeatureChange(item)}
                      />
                      <label
                        class="form-check-label text-capitalize pl-2"
                        for={item + "check"}
                      >
                        {tab}
                      </label>
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="align-items-md-start">
            <Col
              md={4}
              className="mb-3 mb-md-0 justify-content-center align-items-center"
            >
              <h5 className="mb-1">
                Additional features{" "}
                <span className="mx-2 bg-primary fs-7 text-white rounded-pill px-2 px-1 btn-shine">
                  Pro
                </span>
              </h5>
              <p className="mb-0">Customise your other UI settings</p>
            </Col>

            <Col md={8}>
              <Row>
                <Col className="d-flex  gap-3 flex-wrap">
                  {[
                    // "turn-off-right-section",
                    // "show-only-my-channels",
                    // "hide-consult-buttons",
                    "turn-off-ads",
                  ]?.map((item, key) => (
                    <div
                      key={key}
                      className="form-check m-0 border rounded-3 py-2 pe-3 ps-5"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name={item}
                        id={item + "check"}
                        checked={inputs.additional?.includes(item)}
                        onChange={() => handleAdditionalChange(item)}
                      />
                      <label
                        class="form-check-label pl-2 text-capitalize"
                        for={item + "check"}
                      >
                        {item?.split("-").join(" ")}
                      </label>
                    </div>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default React.memo(CustomizationAppearance);
