import { useState, memo, Fragment, useEffect, forwardRef, useRef } from "react";

import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
  Badge,
  Dropdown,
  Image,
  Nav,
  Table,
} from "react-bootstrap";
import Chart from "react-apexcharts";
import { Link, useLocation, useParams } from "react-router-dom";
import Circularprogressbar from "../../../../components/circularprogressbar";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import Masonry from "react-masonry-css";
// import LeafletMap from "../components/leafletMap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import OnboardingLogModal from "../../../../components/partials/common/onboarding-log-modal";
import {
  useChangeSpecialityDashboardStatus,
  useEngagementMeterData,
  useGetActivityTimelineData,
  useGetAllEngagementData,
  useGetDayOfWeekData,
  useGetNbaViewData,
  useGetTimeOfDayData,
  useHcpActivityList,
  useHcpDetails,
} from "../../../../queries/query-hooks/hcp-profile-hook";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import CommonModal from "../../../../components/partials/common/common-modal";
import RequestDataChnageModal from "../components/modal/request-data-chnage-modal";
import ChnageRecommendationModal from "../components/modal/chnage-recommendation-modal";
import toast from "react-hot-toast";
import Skeleton from "../../../../components/skeleton";
import { ONBOARDING_STATUS_CONSTANTS } from "../constant/hcp-constatnt";
import { default_post_json_table } from "../constant/hcp-payload";
import PageNotFound from "../../../PageNotFound";

import HcpInformationSkeleton from "../../../../skeleton/hcp/HcpProfileDetails/HcpInformationSkeleton";
import HcpCardSkeleton from "../../../../skeleton/hcp/HcpProfileDetails/HcpCardSkeleton";
import HcpMedicalSkeleton from "../../../../skeleton/hcp/HcpProfileDetails/HcpMedicalSkeleton";

import HcpCardDetails from "../components/HcpProfileDetails/HcpCardDetails";
import EngagementMeter from "../components/HcpProfileDetails/EngagementMeter";
import HcpMedicalInformation from "../components/HcpProfileDetails/HcpMedicalInformation";
import OnboardingInformation from "../components/HcpProfileDetails/OnboardingInformation";
import DayOfWeekAnalysis from "../components/HcpProfileDetails/DayOfWeekAnalysis";
import TimeOfDayAnalysis from "../components/HcpProfileDetails/TimeOfDayAnalysis";
import ActivityTimeline from "../components/HcpProfileDetails/ActivityTimeline";
import NbaView from "../components/HcpProfileDetails/NbaView";
import SystemInformation from "../components/HcpProfileDetails/SystemInformation";
import CampaignInformation from "../components/HcpProfileDetails/CampaignInformation";
import DoctorPersona from "../components/HcpProfileDetails/DoctorPersona";
import EngagementSummary from "../components/HcpProfileDetails/EngagementSummary";
import ProfileLogo from "../components/HcpProfileDetails/ProfileLogo";

// import Flatpickrr from "../../plugins/pages/flatpickr";
const details = (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-22"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
      fill="currentColor"
    ></path>
  </svg>
);
var hospitaldays = ["Monday", "Wednesday", "Friday"];
var clincdays = ["Tuesday", "Thursday", "Saturday"];

const HCP_ProfileDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [hcpActivityList, sethcpActivityList] = useState(true);

  const [speciality_dashboard_modal, set_speciality_dashboard_modal] =
    useState(false);
  const [recommendation_status_modal, set_recommendation_status_modal] =
    useState(false);

  const [request_data_change_modal, set_request_data_change_modal] =
    useState(false);

  const [
    change_recommendation_modal_status,
    set_change_recommendation_modal_status,
  ] = useState(false);

  const [nbaTotalViewCount, setnbaTotalViewCount] = useState(0);

  const [engagementViewCount, setEngagementTotalViewCount] = useState(0);
  const closeModal = () => setShowModal(false);
  const map_one = useRef(null);
  const map_two = useRef(null);
  const { redirectTo } = useRedirect();

  const { id: hcpId } = useParams();

  const allEngagementPayload = useRef({
    hcp_id: hcpId,
    ...default_post_json_table,
  });
  const onSuccess = (response) => {
    //response
    //console.log("reponse", response);
  };

  const onActivitySuccess = (response) => {
    //console.log("onActivitySuccess", response);
    //console.log("hcpData", generateActivityChartData(response));
  };
  const commonPayload = { hcp_id: hcpId };
  const {
    data: hcpDetail,
    refetch: refetchHcpDetails,
    isLoading: isHcpDetailsLoading,
  } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  console.log("hcpDetail", hcpDetail);

  const { data: activityList, isLoading: isActivityLoading } =
    useHcpActivityList(commonPayload, !!hcpId, onActivitySuccess);

  console.log("activityList", activityList);

  const onEngagaeSuccess = (res) => {
    console.log("onEngagaeSuccess", res);
    if (res.success) {
      setEngagementTotalViewCount(res.totalCount);
    }
  };

  const [specialityDashboardTitle, setSpecialityDashboardTitle] = useState(
    hcpDetail?.hcp_speciality_eligiblity_status?.value
  );
  const { data: allEngagement, isLoading: isAllEngagementLoading } =
    useGetAllEngagementData(
      allEngagementPayload.current,
      !!hcpId,
      onEngagaeSuccess
    );

  const { data: activityTimeline, isLoading: isActivityTimelineLoading } =
    useGetActivityTimelineData(commonPayload, !!hcpId);

  const { data: timeOfDay, isLoading: istimeOfDayLoading } =
    useGetTimeOfDayData(commonPayload, !!hcpId);

  const { data: dayOfWeek, isLoading: isdayOfWeekLoading } =
    useGetDayOfWeekData(commonPayload, !!hcpId);

  const { data: engagementMeterData, isLoading: isEngagementMeterDataLoading } =
    useEngagementMeterData(hcpId, !!hcpId);

  const onNbaSuccess = (res) => {
    if (res?.success) {
      setnbaTotalViewCount(res?.totalCount);
    }
  };
  const { data: nbaViewData, isLoading: isNbaViewDataLoading } =
    useGetNbaViewData(hcpId, !!hcpId, onNbaSuccess);

  const onDashboardUpdateSucccess = (response) => {
    //console.log("::::", response?.data.message);
    toast.success(`${response.data.message}`);
    set_speciality_dashboard_modal(!speciality_dashboard_modal);
    refetchHcpDetails();
  };

  const {
    mutate: updateSpecialityDashboardStatus,
    isLoading: specDashboardUpdateLoading,
  } = useChangeSpecialityDashboardStatus(hcpId, onDashboardUpdateSucccess);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };

  const variableColors = getVariableColor();

  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.warning,
  ];

  useEffect(() => {
    if (activityList && typeof activityList == "object") {
      sethcpActivityList(generateActivityChartData(activityList));
    }
  }, [activityList]);

  useEffect(() => {
    return () => colors;
  });

  const weekTimePreferences = {
    width: "100%",
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 0,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [],
  };

  const engagementMeterChart = {
    options: {
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "80%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels:
        !isEngagementMeterDataLoading && engagementMeterData?.labels?.length > 0
          ? engagementMeterData?.labels
          : ["Voice Call Default", "Comments def", "Engagement"],
    },
    series:
      !isEngagementMeterDataLoading && engagementMeterData?.series?.length > 0
        ? engagementMeterData?.series
        : [76, 55, 100],
  };

  const nbaColumn = [
    // {
    //   Header: "Channel",
    //   accessor: "channel",
    //   // Filter: DefaultColumnFilter,
    // },
    {
      Header: "Content ID",
      accessor: "content_id",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Content Type",
      accessor: "content_type",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Title",
      accessor: "content_title",
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            to="#"
            role="button"
          >
            <span className="btn-inner">{row.original.actionDetails}</span>
          </Button>
        </div>
      ),

      // Filter: DefaultColumnFilter,
    },
  ];

  const dayTimePreferences = {
    width: "100%",
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 0,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: timeOfDay?.data?.categories
          ? timeOfDay?.data?.categories
          : [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [],
  };

  function generateActivityChartData(input) {
    const output = [];

    const totalValue = Object.values(input).reduce(
      (acc, value) => acc + value,
      0
    );
    let start = 1;

    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const value = input[key];
        const name = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the name

        const item = {
          hcp_id: input.hcp_id,
          color: variableColors.primary,
          value,
          id: "circle-progress-01",
          svg: <>{value}</>,
          name,
          start,
          end: (start + value / totalValue) * 100,
        };

        output.push(item);
        start = item.end;
      }
    }

    return output;
  }

  const onRequestDataChange = () => {
    redirectTo("hcp-profile/cohothistory");
  };
  const onMetabtnClick = () => {
    redirectTo(`hcp-profile/meta-profile/${hcpId}`);
  };

  function extractDegreeNames(data) {
    const degreeNames = data.map((item) => item.medical_degree.degree_name);
    const result = degreeNames.join(", ");
    return result;
  }

  function extractInterestSpecNames(data) {
    const degreeNames = data.map((item) => item.speciality.speciality_name);
    const result = degreeNames.join(", ");
    return result;
  }

  const onChangeSpecDashboardClick = () => {
    set_speciality_dashboard_modal(!speciality_dashboard_modal);
    setSpecialityDashboardTitle(
      hcpDetail?.hcp_speciality_eligiblity_status?.value == "Off" ? "On" : "Off"
    );
  };

  const onSubmitSpecialityStatus = () => {
    let payload = {
      hcp_id: hcpId,
      hcp_to_speciality_eligiblity_status:
        hcpDetail?.hcp_speciality_eligiblity_status?.id == 2 ? 1 : 2,
    };
    updateSpecialityDashboardStatus(payload);
    // set_speciality_dashboard_modal(!speciality_dashboard_modal);
  };

  const onRequestDataChnageClick = () => {
    set_request_data_change_modal(!request_data_change_modal);
  };

  const onRequestDataChnageSubmit = () => {
    onRequestDataChnageClick();
    refetchHcpDetails();
  };

  const onRecommendationDataChnageClick = () => {
    set_change_recommendation_modal_status(!change_recommendation_modal_status);
  };

  const onSubmitRecommendationStatus = () => {
    onRecommendationDataChnageClick();
    refetchHcpDetails();
  };

  const getDndChannelNames = (input) => {
    const channelNames = input.map((item) => item.dnd.channel_name);
    return channelNames.join(", ");
  };

  function extractDegreeNames(input) {
    return input.map((item) => item.medical_degree.degree_name).join(",");
  }

  const transformSocialLinks = (input) => {
    if (!input) {
      return [];
    }
    const output = input.map((item) => ({
      platform: item.social_link_type.social_link_type_name,
      link: item.social_link,
    }));
    if (output?.length > 0) {
      return output;
    } else {
      return [];
    }
  };

  const getHcpDocument = (alldata, value) => {
    const filteredData = alldata.filter(
      (item) => item.hcp_document_type === value
    );
    //console.log("filter data", filteredData);
    const output = filteredData.map((item) => item.hcp_document_path);
    return output;
  };

  const onTableStateChange = (state) => {
    //console.log("STATE", state);
    // let payload = changeTableStateToPayload(state);
    // //console.log("PAYLOAD", payload);
    // post_json.current = { ...post_json.current, ...payload };
    // // fetchHcps();
  };

  console.log("hcpDetails", hcpDetail);

  console.log("All engagement", allEngagement, engagementViewCount);
  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12}>
          <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4">
            <div className="d-flex align-items-center gap-3">
              {isHcpDetailsLoading ? (
                <Skeleton height={30} width={100} />
              ) : (
                <Button
                  type="button"
                  className="primary"
                  onClick={onMetabtnClick}
                >
                  Meta Profile
                </Button>
              )}
              {/* {hcpDetail?.hcp_onboarding_status?.id ==
                ONBOARDING_STATUS_CONSTANTS.ONBOARDED && (
                <Button
                  type="button"
                  className="bg-secondary"
                  onClick={onRequestDataChnageClick}
                >
                  Re-Onboard
                </Button>
              )} */}
              {isHcpDetailsLoading ? (
                <Skeleton height={30} width={150} />
              ) : (
                <Button
                  type="button"
                  className="bg-info"
                  onClick={onChangeSpecDashboardClick}
                  title={`Current Status: ${hcpDetail?.hcp_speciality_eligiblity_status?.value}`}
                >
                  Speciality Dashboard Status
                </Button>
              )}
              {isHcpDetailsLoading ? (
                <Skeleton height={30} width={150} />
              ) : (
                <Button
                  type="button"
                  className=" bg-warning"
                  title={`Current Status: ${hcpDetail?.hcp_recommendation_status?.value}`}
                  onClick={onRecommendationDataChnageClick}
                >
                  Recommendation Status
                </Button>
              )}
            </div>
          </div>
        </Col>
      </Row>

      <Masonry
        breakpointCols={1}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <Col>
          <Card className="h-100">
            <Row>
              <Col lg="12">
                <ProfileLogo
                  hcpDetail={hcpDetail}
                  isHcpDetailsLoading={isHcpDetailsLoading}
                />
              </Col>
              <Col lg={12} md={12}>
                <div className="overflow-hidden d-slider1">
                  <Swiper
                    as="ul"
                    className="p-0 m-0 mb-2 list-inline swiper-wrapper"
                    slidesPerView={5}
                    spaceBetween={15}
                    modules={[Navigation]}
                    navigation={{
                      nextEl: ".swiper-button-next",
                      prevEl: ".swiper-button-prev",
                    }}
                    breakpoints={{
                      320: { slidesPerView: 1 },
                      550: { slidesPerView: 2 },
                      991: { slidesPerView: 8 },
                      1400: { slidesPerView: 8 },
                      1500: { slidesPerView: 8 },
                      1920: { slidesPerView: 10 },
                      2040: { slidesPerView: 12 },
                      2440: { slidesPerView: 14 },
                    }}
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    {hcpActivityList &&
                      hcpActivityList?.length > 0 &&
                      hcpActivityList.map((item, index) => {
                        return (
                          <SwiperSlide
                            className="card card-slide mb-2"
                            key={index}
                          >
                            <Card.Body className="p-3">
                              <div className="progress-widget flex-column">
                                <Circularprogressbar
                                  stroke={variableColors.primary}
                                  width="60px"
                                  height="60px"
                                  Linecap="rounded"
                                  trailstroke="#ddd"
                                  strokewidth="6px"
                                  style={{ width: 60, height: 60 }}
                                  value={item.value}
                                  id={`circle-progress-${index}}`}
                                >
                                  {item.svg}
                                </Circularprogressbar>
                                <div className="progress-detail ms-0 mt-3 fs-6">
                                  <p className="mb-0">{item.name}</p>
                                </div>
                              </div>
                            </Card.Body>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                  <div className="swiper-button swiper-button-next"></div>
                  <div className="swiper-button swiper-button-prev"></div>
                </div>
              </Col>
            </Row>

            <Masonry
              // breakpointCols={3}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              <Col>
                {isHcpDetailsLoading ? (
                  <HcpCardSkeleton />
                ) : (
                  <HcpCardDetails hcpDetail={hcpDetail} />
                )}
              </Col>

              <Col>
                <EngagementMeter hcpId={hcpId} colors={colors} />
              </Col>

              <Col>
                {isHcpDetailsLoading ? (
                  <HcpMedicalSkeleton />
                ) : (
                  <HcpMedicalInformation hcpDetail={hcpDetail} />
                )}
              </Col>

              <Col>
                {isHcpDetailsLoading ? (
                  <HcpMedicalSkeleton />
                ) : (
                  <OnboardingInformation hcpDetail={hcpDetail} />
                )}
              </Col>

              <Col>
                <DayOfWeekAnalysis
                  hcpId={hcpId}
                  colors={colors}
                  commonPayload={commonPayload}
                />
              </Col>

              <Col>
                <TimeOfDayAnalysis
                  hcpId={hcpId}
                  colors={colors}
                  commonPayload={commonPayload}
                />
              </Col>

              <Col>
                <ActivityTimeline hcpId={hcpId} commonPayload={commonPayload} />
              </Col>

              <Col>
                <NbaView hcpId={hcpId} />
              </Col>
              <Col>
                {isHcpDetailsLoading ? (
                  <HcpInformationSkeleton />
                ) : (
                  <SystemInformation hcpDetail={hcpDetail} />
                )}
              </Col>

              <Col>
                {isHcpDetailsLoading ? (
                  <HcpInformationSkeleton />
                ) : (
                  <CampaignInformation hcpDetail={hcpDetail} />
                )}
              </Col>

              {hcpDetail?.hcp_to_persona?.length > 0 && (
                <Col>
                  <DoctorPersona
                    hcpDetail={hcpDetail}
                    isHcpDetailsLoading={isHcpDetailsLoading}
                  />
                </Col>
              )}
            </Masonry>

            <Col>
              <EngagementSummary hcpId={hcpId} />
            </Col>
          </Card>
        </Col>
      </Masonry>

      <OnboardingLogModal show={showModal} modalClose={closeModal} />
      <CommonModal
        title={"Please Confirm"}
        body={`Are you sure want to ${specialityDashboardTitle} Speciality Dashboard status`}
        show={speciality_dashboard_modal}
        modalClose={onChangeSpecDashboardClick}
        onSubmit={onSubmitSpecialityStatus}
        isLoading={specDashboardUpdateLoading}
        loaderText={"Updating..."}
      />
      <RequestDataChnageModal
        show={request_data_change_modal}
        title={`Request Data Change For ${hcpDetail?.hcp_first_name}`}
        onClose={onRequestDataChnageClick}
        onSubmit={onRequestDataChnageSubmit}
        data={{ hcp_id: hcpId }}
      />
      <ChnageRecommendationModal
        show={change_recommendation_modal_status}
        title={`Change Recommendation Engine For ${hcpDetail?.hcp_first_name}`}
        onClose={onRecommendationDataChnageClick}
        onSubmit={onSubmitRecommendationStatus}
        data={{ hcp_id: hcpId, ...hcpDetail?.hcp_to_recommendation_status }}
      />
    </Fragment>
  );
};

export default HCP_ProfileDetails;
