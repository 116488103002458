import React from "react";
import Skeleton from "../../../../components/skeleton";

const CountCardSkeleton = ({ width = "100%" }) => {
  return (
    <>
      {[...Array(4)].map((_, i) => {
        return (
          <Skeleton
            key={`${i}--summery-key`}
            variant="square"
            width={width}
            height={90}
            className="rounded-3 mb-1"
          />
        );
      })}
    </>
  );
};

export default CountCardSkeleton;
