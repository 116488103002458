import * as yup from "yup";

const addHTMLInitialValue = {
	body: "",
	body_html: "",
	reference: "",
	reference_html: "",
	referenceFile: null,
	title: "",
	title_html: "",
	brand: "",
	thumbnail: null,
	base: null,
};

const AddHTMLSchema = yup.object().shape({
	title: yup.string().trim().required("Please Enter a title"),
	reference: yup.string().trim().required("Please Enter a references"),
	brand: yup.string().trim().required("Please select the brand"),
	thumbnail: yup.mixed().required("Please add a Thumbnail"),
	base: yup.mixed().required("Please add a Cover Image"),
});

export { addHTMLInitialValue, AddHTMLSchema };
