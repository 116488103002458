import React, { memo, Fragment, Suspense, useEffect, useState } from "react";

// router-dom
import { Outlet, useParams } from "react-router-dom";

// Header
import Headerpro from "../../../../../../components/partials/pro/headerstyle/header-pro";

// Sidebar
import ChannelSidebar from "../components/partials/channel-sidebar";

// Footer
import Footer from "../../../../../../components/partials/dashboard/footerstyle/footer";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../../../store/setting/selectors";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";

// Scss e-commerce
import "../../../../../../assets/modules/e-commerce/scss/e-commerce.scss";
import { setCurrentChannel } from "../../../../../../store/channel/channel.action";
import { useGetChannelDetails } from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import PageNotFound from "../../../../../PageNotFound";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";

const ChannelLayout = memo(() => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  const params = useParams();
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const [isValidChannelId, setIsValidChannelId] = useState(true);
  const { data: channelDetailsData, isLoading: isDetailsLoading } =
    useGetChannelDetails(params.channel_id);

  useEffect(() => {
    if (params.channel_id) {
      dispatch(setCurrentChannel({ channel_id: params.channel_id }));
    }
  }, []);

  useEffect(() => {
    if (channelDetailsData?.length < 1) {
      setIsValidChannelId(false);
    }
  }, [channelDetailsData]);

  const fallBackHandler = () => {
    redirectTo("project/channels");
  };

  return (
    <Fragment>
      <ChannelSidebar />
      <main className="main-content">
        <div className="position-relative">
          <Headerpro />
        </div>
        {!isValidChannelId && !isDetailsLoading ? (
          <PageNotFound
            title="You are not authorized to view this content"
            buttonText="Home"
            onFallbackClick={fallBackHandler}
          />
        ) : (
          <div
            className={`container-fluid content-inner pb-0 ${pageLayout}`}
            id="page_layout"
          >
            <Suspense fallback={<div className="react-load"></div>}>
              <Outlet />
            </Suspense>
          </div>
        )}

        <Footer />
      </main>
      {/* <SettingOffCanvas /> */}
    </Fragment>
  );
});

ChannelLayout.displayName = "ChannelLayout";
export default ChannelLayout;
