import React, { memo } from "react";
import { findIcon } from "../../../../../../dashboard/icons";

function ChannelStatistics({ subscriberCount, viewsCount }) {
  // const { data: channelDetailsData, isLoading: isDetailsLoading } = useGetChannelDetails(328);

  return (
    <div
      className="card p-3 pt-4 m-0 h-100"
      data-aos="fade-up"
      data-aos-delay="500"
    >
      <h6 className="mb-4">Channel Statistics</h6>
      <div className="text-center card-body d-flex align-items-start p-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex gap-3">
            <div className="bg-light bg-opacity-50 icon-40 d-flex align-items-center justify-content-center rounded-circle text-primary">
              {/* {findIcon("User Group", "dual-tone", 24)} */}
              {findIcon("Video", "dual-tone", 24)}
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start">
              {subscriberCount && subscriberCount > 0 ? (
                <h2 className="m-0 p-0 fs-4">{parseInt(subscriberCount).toLocaleString()}</h2>
              ) : (
                <h2 className="m-0 p-0 fs-4">0</h2>
              )}
              <p className="text-secondary m-0 fs-6">Videos</p>
            </div>
          </div>
          <hr className="hr-vertial" />
          <div className="d-flex gap-3 me-4">
            <div className="bg-light bg-opacity-50 icon-40 d-flex align-items-center justify-content-center rounded-circle text-primary">
              {findIcon("Eye", "solid", 24)}
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start">
              {viewsCount && viewsCount > 0 ? (
                <h2 className="m-0 p-0 fs-4">{parseInt(viewsCount).toLocaleString()}</h2>
              ) : (
                <h2 className="m-0 p-0 fs-4">0</h2>
              )}
              <p className="text-secondary m-0 fs-6">Views</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ChannelStatistics);
