import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../api/instances/axiosInstance";
import {
  GET_CHANNEL_AUDIENCE_AGE_WISE_REPORT,
  GET_CHANNEL_AUDIENCE_DAY_WISE_REPORT,
  GET_CHANNEL_AUDIENCE_DEVICE_DISTRIBUTION,
  GET_CHANNEL_AUDIENCE_SPECIALITY_REPORT,
  GET_CHANNEL_AUDIENCE_ZONAL_DISTRIBUTION,
  GET_CHANNEL_OVERVIEW_BROWSER_DISTRIBUTION,
  GET_CHANNEL_OVERVIEW_CLICK_REPORT,
  GET_CHANNEL_OVERVIEW_DEVICE_DISTRIBUTION,
  GET_CHANNEL_OVERVIEW_LANDING_CATEGORY,
  GET_CHANNEL_OVERVIEW_LOCATION_MAP,
  GET_CHANNEL_OVERVIEW_MOBILE_DISTRIBUTION,
  GET_CHANNEL_OVERVIEW_VIEW_REPORT,
  GET_CHANNEL_OVERVIEW_ZONE_DISTRIBUTION,
  GET_CREATIVE_DATA_LIST,
  GET_CREATIVE_DEVICE_LATENCEY,
  GET_CREATIVE_DIMENSION_REPORT,
  GET_CREATIVE_OS_LATENCY_REPORT,
  GET_CREATIVE_REPORT_TABLE,
  GET_LANDING_PAGE_CATEGORY,
  GET_LANDING_PAGE_REPORT,
  GET_LANDING_PAGE_TYPE,
  GET_LOCATION_MAJOR_CITIES,
  GET_LOCATION_MAP_DATA,
  GET_LOCATION_ZONAL_DISTRIBUTION,
  GET_SUMMARY_CAMPAIGN_ANALYTICS,
  GET_SUMMARY_CAMPAIGN_GROUP_LIST,
  GET_SUMMARY_CREATIVE_LIST,
  GET_SUMMARY_DAYWISE_DISTRIBUTION,
  GET_SUMMARY_DEVICE_DISTRIBUTION,
  GET_SUMMARY_IMPRESSION_GRAPH,
  GET_SUMMARY_LOCATION_MAP,
  GET_LOCATION_REPORT,
  GET_CREATIVE_DETAILS,
  GET_SUMMARY_GROUP_LIST,
  GET_LOCATION_REPORT_CTR,
  GET_CREATIVE_REPORT_TABLE_CTR,
  GET_LANDING_PAGE_REPORT_CTR,
  GET_CHANNEL_AUDIENCE_SCREEN_SIZE_WISE_REPORT,
  GET_CHANNEL_OVERVIEW_VIEW_COUNT,
  GET_CHANNEL_AUDIENCE_SPECIALITY_REPORT_CTR,
  GET_CREATIVE_PREVIEW
} from "../../../api/endpoints/brand-ads-endpoints";
import {
  KEY_AUDIENCE_AGE_WISE_REPORT,
  KEY_AUDIENCE_DAY_WISE_REPORT,
  KEY_AUDIENCE_DEVICE_DISTRIBUTION,
  KEY_AUDIENCE_SPECIALITY_REPORT,
  KEY_AUDIENCE_ZONAL_DISTRIBUTION,
  KEY_CREATIVE_DATA_LIST,
  KEY_CREATIVE_DEVICE_LATENCY,
  KEY_CREATIVE_DIMENSION_REPORT,
  KEY_CREATIVE_OS_LATENCY_REPORT,
  KEY_CREATIVE_REPORT_TABLE,
  KEY_LANDING_PAGE_CATEGORY_IMPRESSION,
  KEY_LANDING_PAGE_CATEGORY_REPORT,
  KEY_LANDING_PAGE_CATEGORY_TYPE_IMPRESSION,
  KEY_LOCATION_MAJOR_CITIES,
  KEY_LOCATION_MAP,
  KEY_LOCATION_ZONAL_DISTRIBUTION,
  KEY_OVERVIEW_BROWSER_DISTRIBUTION,
  KEY_OVERVIEW_CLICK_REPORT,
  KEY_OVERVIEW_DEVICE_DISTRIBUTION,
  KEY_OVERVIEW_LANDING_CTEGORY,
  KEY_OVERVIEW_LOCATION_MAP,
  KEY_OVERVIEW_MOBILE_DISTRIBUTION,
  KEY_OVERVIEW_VIEW_REPORT,
  KEY_OVERVIEW_ZONAL_DISTRIBUTION,
  KEY_SUMMARY_CAMPAIGN_ANALYTICS,
  KEY_SUMMARY_CAMPAIGN_GROUP_LIST,
  KEY_SUMMARY_CREATIVE_LIST,
  KEY_SUMMARY_DAYWISE_DISTRIBUTION,
  KEY_SUMMARY_DEVICE_DISTRIBUTION,
  KEY_SUMMARY_IMPRESSION_GRAPH,
  KEY_SUMMARY_LOCATION_MAP,
  KEY_LOCATION_LOCATION_REPORT,
  KEY_CREATIVE_DETAILS,
  KEY_CREATIVE_LANDING_PAGE_REPORT,
  KEY_SUMMARY_GROUP_LIST,
  KEY_LOCATION_LOCATION_REPORT_CTR,
  KEY_CREATIVE_REPORT_TABLE_CTR,
  KEY_LANDING_PAGE_CATEGORY_REPORT_CTR,
  KEY_AUDIENCE_SCREEN_SIZE_WISE_DATA,
  KEY_OVERVIEW_VIEW_COUNT,
  KEY_AUDIENCE_SPECIALITY_REPORT_CTR_KEY,
  KEY_CREATIVE_PREVIEW
} from "../../query-constants/workspace/brand-ads-constant";

const staleTime = 300000;
const cacheTime = 600000;

export const useGetOverviewBrandViewReport = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_OVERVIEW_VIEW_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_VIEW_REPORT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetOverviewViewCount = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_OVERVIEW_VIEW_COUNT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_VIEW_COUNT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetOverviewBrandClickReport = (
  project_id,
  payload,
  enabled
) => {
  return useQuery(
    [KEY_OVERVIEW_CLICK_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_CLICK_REPORT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      // enabled: payload?.campaign_list && payload?.campaign_list?.length > 0 ? true : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetOverviewLocationMap = (project_id, payload) => {
  return useQuery(
    [KEY_OVERVIEW_LOCATION_MAP, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_LOCATION_MAP}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetOverviewDeviceDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_OVERVIEW_DEVICE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_DEVICE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetOverviewBrowserDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_OVERVIEW_BROWSER_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_BROWSER_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetOverviewLandingCategory = (project_id, payload) => {
  return useQuery(
    [KEY_OVERVIEW_LANDING_CTEGORY, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_LANDING_CATEGORY}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetOverviewZoneDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_OVERVIEW_ZONAL_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_ZONE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetOverviewMobileDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_OVERVIEW_MOBILE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_OVERVIEW_MOBILE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAudienceDayWiseReport = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_DAY_WISE_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_DAY_WISE_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAudienceAgeWiseReport = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_AGE_WISE_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_AGE_WISE_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAudienceUserByScreenSize = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_SCREEN_SIZE_WISE_DATA, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_SCREEN_SIZE_WISE_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAudienceZonalDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_ZONAL_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_ZONAL_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetAudienceDeviceDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_AUDIENCE_DEVICE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_DEVICE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAudienceSpecialityReport = (
  project_id,
  payload,
  enabled
) => {
  return useQuery(
    [KEY_AUDIENCE_SPECIALITY_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_SPECIALITY_REPORT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: payload && payload?.campaign_list ? true : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAudienceSpecialityCTRReport = (
  project_id,
  payload,
  enabled
) => {
  return useQuery(
    [
      KEY_AUDIENCE_SPECIALITY_REPORT_CTR_KEY,
      project_id,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CHANNEL_AUDIENCE_SPECIALITY_REPORT_CTR}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: payload && payload?.campaign_list ? true : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationMap = (project_id, payload) => {
  return useQuery(
    [KEY_LOCATION_MAP, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LOCATION_MAP_DATA}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationMajorCities = (project_id, payload) => {
  return useQuery(
    [KEY_LOCATION_MAJOR_CITIES, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LOCATION_MAJOR_CITIES}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationZonalDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_LOCATION_ZONAL_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LOCATION_ZONAL_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationReport = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_LOCATION_LOCATION_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LOCATION_REPORT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationReportCtr = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_LOCATION_LOCATION_REPORT_CTR, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LOCATION_REPORT_CTR}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetCreativeDataList = (project_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_DATA_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DATA_LIST}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetCreativeReportTableData = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_CREATIVE_REPORT_TABLE, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_REPORT_TABLE}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetCreativeReportTableDataCtr = (
  project_id,
  payload,
  enabled
) => {
  return useQuery(
    [KEY_CREATIVE_REPORT_TABLE_CTR, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_REPORT_TABLE_CTR}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetCreativeDeviceLatencyReport = (project_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_DEVICE_LATENCY, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DEVICE_LATENCEY}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetCreativeDimensionReport = (project_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_DIMENSION_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DIMENSION_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetOsLatencyReport = (project_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_OS_LATENCY_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_OS_LATENCY_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLandingPageCategory = (project_id, payload) => {
  return useQuery(
    [KEY_LANDING_PAGE_CATEGORY_IMPRESSION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LANDING_PAGE_CATEGORY}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLandingPageCategoryType = (project_id, payload) => {
  return useQuery(
    [
      KEY_LANDING_PAGE_CATEGORY_TYPE_IMPRESSION,
      project_id,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LANDING_PAGE_TYPE}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLandingPageCategoryReport = (project_id, payload) => {
  console.log(
    "useGetLandingPageCategoryReport",
    payload,
    payload?.campaign_list,
    payload?.campaign_list && payload?.campaign_list?.length > 0
      ? "true"
      : "false"
  );
  return useQuery(
    [KEY_LANDING_PAGE_CATEGORY_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LANDING_PAGE_REPORT}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list > 0 ? true : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLandingPageCategoryReportCtr = (project_id, payload) => {
  return useQuery(
    [KEY_LANDING_PAGE_CATEGORY_REPORT_CTR, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_LANDING_PAGE_REPORT_CTR}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list > 0 ? true : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSummaryCamapgingAnalytics = (
  project_id,
  payload,
  enabled
) => {
  return useQuery(
    [KEY_SUMMARY_CAMPAIGN_ANALYTICS, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_CAMPAIGN_ANALYTICS}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: enabled,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSummaryCreativeList = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_CREATIVE_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_CREATIVE_LIST}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!payload.campaign_list
    }
  );
};

export const useGetSummaryCampaignGroupList = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_CAMPAIGN_GROUP_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_CAMPAIGN_GROUP_LIST}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled:
        payload?.campaign_list && payload?.campaign_list?.length > 0
          ? true
          : false,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSummaryGroupList = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_GROUP_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_GROUP_LIST}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSummaryImpressionGraph = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_SUMMARY_IMPRESSION_GRAPH, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_IMPRESSION_GRAPH}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: enabled,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSummaryLocationMap = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_LOCATION_MAP, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_LOCATION_MAP}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!payload?.campaign_list
    }
  );
};

export const useGetSummaryDeviceDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_DEVICE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_DEVICE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!payload?.campaign_list
    }
  );
};

export const useGetSummaryDaywiseDistribution = (project_id, payload) => {
  return useQuery(
    [KEY_SUMMARY_DAYWISE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_SUMMARY_DAYWISE_DISTRIBUTION}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      enabled: !!payload?.campaign_list
    }
  );
};

export const useGetCreativeDetails = (project_id, creative_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_DETAILS, project_id, creative_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DETAILS}/${creative_id}/info`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetCreativeDetailsInfo = (project_id, creative_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_DETAILS, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DETAILS}/${creative_id}/details`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLandingPageReport = (project_id, creative_id, payload) => {
  return useQuery(
    [KEY_CREATIVE_LANDING_PAGE_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_DETAILS}/landingreport-list`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};


export const useGetCreativePreview = (project_id,payload) => {
  return useQuery(
    [KEY_CREATIVE_PREVIEW, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_CREATIVE_PREVIEW}`,
        payload
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data.data ?? [],
    }
  );
};
