import {
  memo,
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";

//react-bootstrap
import { Button, Card, Col, Form, Row, Modal, Badge } from "react-bootstrap";
//components

import ReactTable from "../../../../../lib/table/react-table";
import { findIcon } from "../../../../dashboard/icons";
import TemplateFilter from "../../../../../components/common-components/template-filter";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import postService from "../../../../../api/services/post-service";
import {
  TEMPLATE_CATEGORY_LIST,
  TEMPLATE_CATEGORY_DETAILS_GET,
  TEMPLATE_CATEGORY_DELETE_GET,
  TEMPLATE_CATEGORY_UPDATE,
  TEMPLATE_CATEGORY_ADD,
} from "../../../../../api/endpoints/template-management-endpoints";
import toast from "react-hot-toast";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import * as yup from "yup";
import { useFormik } from "formik";
import PaginationLoader from "../../../../../components/loader/pagination-loader";

import { getService } from "../../../../../api/services/get-services";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";

const Category = () => {
  const { redirectTo } = useRedirect();
  const [categoryList, setCategoryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, showLoader] = useState(true);
  const [viewCategoryData, setViewCategoryData] = useState({});
  const [viewCategoryModalShow, setCategoryViewModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [UpdateCategoryId, setUpdateUpdateCategoryId] = useState("");

  const columns = [
    { Header: "Id", accessor: "category_id" },
    { Header: "Name", accessor: "category_name" },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => {
        return value.name ? value.name : "N/A";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Badge bg={statusToBg(row?.values?.status?.value)} className="me-2">
            <span className="btn-inner">{row.values.status.value}</span>
          </Badge>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "iconAction",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={() => {
            viewCategoryModal(row?.original);
          }}
          onEdit={() => {
            onEditCategoryClick(row?.original);
          }}
          onDelete={() => {
            // console.log('row?.original.category_id', row?.original.category_id);
            setSelectedTemplate(row?.original.category_id);
            deleteModalOpen();
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const post_json = useRef({
    // "default": "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      category_id: "desc",
    },
  });

  const fetchCategoryList = async () => {
    const response = await postService(
      TEMPLATE_CATEGORY_LIST,
      post_json.current
    );
    // showLoader(true);
    if (response.isError) {
      showLoader(false);
      toast(response.error);
    } else {
      // console.log('category list response', response?.data?.data?.rows.length);
      if (response.data?.data?.rows.length > 0) {
        setCategoryList(response?.data?.data?.rows);
        setTotalCount(response?.data?.data?.totalCount);
        showLoader(false);
      } else {
        setCategoryList([]);
        setTotalCount(0);
        showLoader(false);
      }
    }
  };

  const onTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json.current = paylod;
      fetchCategoryList();
    },
    [categoryList, loader, post_json.current]
  );

  const viewCategoryModalClose = () => {
    setCategoryViewModalShow(false);
  };

  const viewCategoryModal = async (data) => {
    let category_id = data?.category_id;
    let categoryData = {};
    try {
      const response = await getService(
        `${TEMPLATE_CATEGORY_DETAILS_GET}/${category_id}`,
        {}
      );
      if (response.isError) {
        toast.error(response?.error);
      }
      if (response.data.success) {
        let data = response.data?.data;
        categoryData = {
          "Category Id": data.category_id ?? "",
          "Category Name":
            data.category_name.replace(/\b\w/g, (match) =>
              match.toUpperCase()
            ) ?? "",
          "Created By": data.created_by?.name ?? "",
          Status: data.status?.value ?? "",
        };
        setViewCategoryData(categoryData && categoryData);
        setCategoryViewModalShow(true);
      } else {
      }
    } catch (error) {}
  };

  const deleteModalOpen = () => {
    setDeleteModalShow(true);
  };
  const deleteModalClose = useCallback(() => {
    setSelectedTemplate(null);
    setDeleteModalShow(false);
  }, [deleteModalShow]);

  const deleteVariableTemplate = async () => {
    console.log("selectedTemplate", selectedTemplate);
    try {
      // showLoader(true);
      const response = await getService(
        `${TEMPLATE_CATEGORY_DELETE_GET}/${selectedTemplate}`,
        {}
      );
      if (response) {
        toast.success(response?.data?.message);
        showLoader(false);
        setDeleteModalShow(false);
        fetchCategoryList();
      }
      if (response.isError) {
        toast.error(response?.error);
        showLoader(false);
      }
    } catch (error) {
      showLoader(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  let myValidationSchema = {
    category_name: yup
      .string()
      .min(3, "Category Name Mustbe Atleast 3 Characters")
      .max(20, "Category Name Mustbe Within 20 Characters")
      .required("Please Enter Category Name"),
    status: yup.number(),
  };
  const validationSchema = yup.object().shape(myValidationSchema);
  //formik
  const formik = useFormik({
    initialValues: {
      category_name: "",
      status: 1,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // console.log("values", values);
      try {
        // showLoader(true);
        const response = await postService(
          isEdit
            ? TEMPLATE_CATEGORY_UPDATE + `/${UpdateCategoryId}`
            : TEMPLATE_CATEGORY_ADD,
          values
        );
        if (response.isError) {
          toast.error(response?.error);
          showLoader(false);
        }
        if (response?.data?.success) {
          // console.log("response add template success", response);
          toast.success(response.data?.message);
          showLoader(false);
          setCreateModalShow(false);
          setIsEdit(false);
          fetchCategoryList();
        } else {
          // console.log("response add template error", response);
          showLoader(false);
        }
      } catch (error) {
        showLoader(false);
        // console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
    setErrors,
  } = formik;

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };

  const handleCategoryStatus = (e) => {
    setValues({ ...values, status: parseInt(e.target.value) });
  };

  const onEditCategoryClick = (category) => {
    console.log("category", category);
    setIsEdit(true);
    setUpdateUpdateCategoryId(category?.category_id);
    //set formik values
    setValues({
      category_name: category.category_name,
      status: category.status?.id,
    });
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
            <div className="d-flex flex-column">
              <h4 className="m-0">Template: Category</h4>
            </div>
            <Button
              type="button"
              variant="success"
              className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
              onClick={() => handleCreate()}
            >
              {findIcon("Plus", "outline", "32")}
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="p-0 px-md-3">
          <Card>
            {/* <Card.Header className="d-flex justify-content-between align-items-center">
              <div className="header-title">
                <h4 className="card-title">Template: Category</h4>
              </div>
              <Button
                type="button"
                variant="success"
                className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "outline", "32")}
              </Button>
            </Card.Header> */}

            <Card.Body className="p-2 p-md-4">
              <div className="table-responsive">
                {!loader ? (
                  categoryList?.length > 0 ? (
                    <ReactTable
                      data={categoryList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      isRowSelectionAvailable={false}
                    />
                  ) : (
                    <PageNotFound />
                  )
                ) : (
                  <CommonTableLoader />
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ViewModal
        title={"Category Template Information"}
        show={viewCategoryModalShow}
        modalClose={viewCategoryModalClose}
        data={viewCategoryData}
      />
      <DeleteModal
        show={deleteModalShow}
        modalClose={deleteModalClose}
        onSubmit={deleteVariableTemplate}
      />
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Category Name */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category Name"
                name="category_name"
                onChange={handleChange}
                value={values.category_name}
                onBlur={handleBlur}
                isInvalid={touched.category_name && errors.category_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.category_name}
              </Form.Control.Feedback>
            </Form.Group>
            {/* Category status */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Category Status</Form.Label>
              <Form.Select
                type="type"
                name="status"
                value={values.status}
                onChange={handleCategoryStatus}
              >
                <option value={1}>Enable</option>
                <option value={2}>Disable</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.status}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {loader ? <PaginationLoader /> : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Category;
