import PropTypes from "prop-types";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { changePayloadToTableState } from "../../common/utils/utils";
import { table_filters } from "../../store/react-table/react-table.selector";
import { COLUMNS } from "./data";
import { AscIcon, DescIcon } from "./icons";
import PaginationBar from "./pagination-bar";
import PageNotFound from "../../views/PageNotFound";
const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  recordsTotal: PropTypes.number,
  recordsPerPage: PropTypes.number,
  onTableStateChange: PropTypes.func,
  isRowSelectionAvailable: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  initialState: PropTypes.object,
};

const defaultProps = {};
const ReactTable = ({
  data,
  columns: columns_data,
  recordsTotal,
  recordsPerPage = 10,
  onTableStateChange,
  isRowSelectionAvailable = false,
  onRowSelectionChange,
  initialState = { pageIndex: 0, pageSize: 10, sortBy: [], filters: [] },
  isHeaderShow = true,
  isPaginationShow = true,
  bordered,
  prrntClassName,
  noDataImage,
  noDataText,
  onFallbackClick,
  noDataButtonText,
}) => {
  // console.log("INITIAL_STATE", changePayloadToTableState(initialState));
  const tableFilters = useSelector(table_filters);
  const isFirstRender = React.useRef(true);
  //usestate for total records and data
  const [totalRecords, setTotalRecords] = useState(recordsTotal);
  const columns = React.useMemo(() => columns_data || COLUMNS, []);
  const { pathname } = useLocation();
  // Debounce our onTableStateChange call for 100ms
  const onFetchDataDebounced = useAsyncDebounce(onTableStateChange, 1000);
  function DefaultColumnFilter({ column }) {
    const { filterValue, preFilteredRows, setFilter } = column;

    // const count = preFilteredRows.length;
    const typeHandler =
      // column.id === "created_by" ||
      column.id === "created_at" || column.id === "modified_on"
        ? // column.id === "modified_by"
          "date"
        : "text";

    // console.log("Coulmn data", column.id);
    // return a input field for filter with small height and border bottom and rounded corners
    return (
      <Form.Control
        className="border-0 border-bottom w-100 h-25 rounded-3 mt-2 p-2"
        type={typeHandler}
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search here...`}
      />
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      disableFilters: false,
      disableSortBy: true,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualSortBy: true,
      autoResetSortBy: false,
      disableMultiSort: true,
      manualFilters: true,
      autoResetFilters: false,
      initialState: changePayloadToTableState(initialState),
      manualPagination: true,
      autoResetPage: false,
      pageCount: Math.ceil(totalRecords / recordsPerPage),
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!isRowSelectionAvailable) return;
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            // console.log("row in selection hook", row);
            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    console.log("table state", state);
    if (
      onTableStateChange &&
      typeof onTableStateChange === "function"
      // state?.pageIndex > 0
    ) {
      // console.log("PAYLOAD_TO_TABLE_STATE", changePayloadToTableState());
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      // console.log("TABLE_STATE", state);
      onTableStateChange(state);
    }
    return () => {
      // console.log("table unmount");
    };
  }, [state.pageIndex, state.pageSize, state.sortBy]);

  useEffect(() => {
    return () => {
      gotoPage(0);
      setPageSize(10);
    };
  }, []);
  //when pathname changes reset the table state
  // useEffect(() => {
  //   if (pathname !== "/") {
  //     gotoPage(0);
  //     setPageSize(10);
  //     // setFilter(undefined);
  //   }
  // }, [pathname]);

  //effect for state.filters which will call onFetchDataDebounced

  useEffect(() => {
    if (onTableStateChange && typeof onTableStateChange === "function") {
      console.log("FILTERS", state.filters, state);
      if (state?.filters?.length > 0) {
        onFetchDataDebounced(state);
      } else {
        onFetchDataDebounced(state);
      }
    }
  }, [state.filters]);

  //effect to listen on selected rows change

  useEffect(() => {
    if (onRowSelectionChange && typeof onRowSelectionChange === "function") {
      // console.log("STATE AFTER SELECTION", state);
      onRowSelectionChange(state.selectedRowIds);
    }
  }, [state.selectedRowIds]);

  const pageIndex = useMemo(() => state.pageIndex, [state.pageIndex]);
  const pageSize = useMemo(() => state.pageSize, [state.pageSize]);

  const totalPage = useMemo(
    () => Math.ceil(totalRecords / pageSize),
    [totalRecords, pageSize]
  );
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <Form.Check type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  useEffect(() => {
    if (tableFilters?.length > 0) {
      // console.log("tableFilters", tableFilters);
      for (let tableFilter of tableFilters) {
        setFilter(tableFilter.id, tableFilter.value);
      }
    }
  }, [tableFilters]);

  return (
    <>
      <div className={`fancy-table table-responsive rounded ${prrntClassName}`}>
        <Table
          {...getTableProps()}
          className={`mb-0 ${bordered ? "table-bordered" : ""}`}
        >
          {isHeaderShow && (
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <>
                      <th className="text-start align-top" scope="col">
                        <div
                          className="d-flex justify-content-start align-items-center"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}

                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <div className="ms-1">
                                  <DescIcon />
                                </div>
                              ) : (
                                <div className="ms-1">
                                  <AscIcon />
                                </div>
                              )
                            ) : (
                              <div className="ms-1">{/* <BothIcon /> */}</div>
                            )}
                          </span>
                        </div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    </>
                  ))}
                </tr>
              ))}
            </thead>
          )}

          {data?.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="text-start">
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          title={
                            typeof cell?.value === "string" ? cell?.value : ""
                          }
                          className="text-wrap max-width"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <></>
          )}
        </Table>
        {(!data || data.length === 0) && (
          <PageNotFound
            title={noDataText}
            backgroundImage={noDataImage}
            onFallbackClick={onFallbackClick}
            buttonText={noDataButtonText}
          />
        )}
      </div>

      {isPaginationShow && (
        <PaginationBar
          gotoPage={gotoPage}
          onNextPage={nextPage}
          onPreviousPage={previousPage}
          totalPage={totalPage}
          pageIndex={pageIndex}
        />
      )}
    </>
  );
};

ReactTable.propTypes = propTypes;
ReactTable.defaultProps = defaultProps;
// #endregion

export default memo(ReactTable);
