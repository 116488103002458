import React, { useEffect, memo, Fragment } from "react";

//Siderbarlogo
import Sidebarlogo from "../../../../../../components/partials/components/sidebar/sidebar-logo";

//Sidebarprofile
import Sidebarprofilecard from "../../../../../../components/partials/components/sidebar/sidebar-profile-card";

//E-commerce-vartical-nav
import BrandAdsVerticalNav from "./brand-ads-vertical-nav";

//Scrollbar
// import Scrollbar from "smooth-scrollbar";

import * as SettingSelector from "../../../../../../store/setting/selectors";
import { useSelector } from "react-redux";
// import { useGetChannelDetails, useGetChannelList } from "../../../../../../../queries/query-hooks/workspace/workspace-hook";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
// import { currentChannel } from "../../../../../../../store/channel/channel.selector";
import { useParams } from "react-router-dom";
import { useGetProjectDetails } from "../../../../../../queries/query-hooks/workspace/workspace-hook";

// import SidebarCardSkeleton from "../../../../../../../skeleton/workspace/doctube/channel/sidebar-card-skeleton";

const BrandsAdsSidebar = memo(() => {
  const { redirectTo } = useRedirect();
  const { project_id } = useParams();

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);
  // const { channel_name, channel_logo } = channelDetailsData || {};

  const appName = useSelector(SettingSelector.app_name);

  const sidebarColor = useSelector(SettingSelector.sidebar_color);
  const sidebarHide = useSelector(SettingSelector.sidebar_show); // array
  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  //   useEffect(() => {
  //     Scrollbar.init(document.querySelector("#my-scrollbar"));
  //   });
  //   const channel = useSelector(currentChannel);

  const onBackButtonClick = () => {
    redirectTo("workspace/clirnet");
  };

  const onTitleClick = () => {
    // redirectTo(`channel/dashboard/${channel_id}`);
  };

  return (
    <Fragment>
      <aside
        className={`${sidebarColor} ${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} ${sidebarHide.join(" ") ? "sidebar-none" : "sidebar"
          } sidebar-base `}
      >
        <Sidebarlogo app_name={appName} />

        <div className="sidebar-body pt-0 data-scrollbar" id="my-scrollbar">
          {<Sidebarprofilecard
            title={projectDetails && projectDetails?.length > 0 ? projectDetails[0]?.project_name : ""}
            logo={projectDetails && projectDetails?.length > 0 ? projectDetails[0]?.project_logo : ""}
            isLoading={isProjectDetailsLoading}
            previousPageName={"Projects"}
            onBackButtonClick={onBackButtonClick}
            onTitleClick={onTitleClick}
          />}

          <hr className="hr-horizontal" />
          <div className="sidebar-list">
            <BrandAdsVerticalNav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </Fragment>
  );
});

BrandsAdsSidebar.displayName = "BrandsAdsSidebar";
export default BrandsAdsSidebar;