import React, { useEffect, memo, Fragment } from "react";

//Siderbarlogo
import Sidebarlogo from "../../../../../../../components/partials/components/sidebar/sidebar-logo";

//Sidebarprofile
import Sidebarprofilecard from "../../../../../../../components/partials/components/sidebar/sidebar-profile-card";

//E-commerce-vartical-nav
import ChannelVideoVarticalnav from "./channel-video-vertical-nav";

//Scrollbar
import Scrollbar from "smooth-scrollbar";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../../../../store/setting/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";
import { useGetVideoDetails } from "../../../../../../../queries/query-hooks/workspace/channel-video-hook";
import { useParams } from "react-router-dom";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { currentChannel } from "../../../../../../../store/channel/channel.selector";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images"
const ChannelVideoSidebar = memo((type = "reels") => {
  const appName = useSelector(SettingSelector.app_name);
  const sidebarColor = useSelector(SettingSelector.sidebar_color);
  const sidebarHide = useSelector(SettingSelector.sidebar_show); // array
  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  const { redirectTo } = useRedirect();
  const { video_id } = useParams();
  const channel = useSelector(currentChannel);
  const { data: videoDetailsData, isLoading: isVideoLoading } = useGetVideoDetails(
    video_id,
    () => { }
  );

  const { video_detail: videoDetails } = videoDetailsData || {
    video_detail: {},
  };

  useEffect(() => {
    Scrollbar.init(document.querySelector("#my-scrollbar"));
  });

  const onBackButtonClick = () => {
    redirectTo("channel/videos/" + channel?.channel_id);
  };

  const onTitleClick = (id) => {
    redirectTo("channel/dashboard/" + channel?.channel_id);
  };

  return (
    <Fragment>
      <aside
        className={`${sidebarColor} ${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} ${sidebarHide.join(" ") ? "sidebar-none" : "sidebar"
          } sidebar-base `}
      >
        <Sidebarlogo app_name={appName} />
        <div className="sidebar-body pt-0 data-scrollbar" id="my-scrollbar">
          <Sidebarprofilecard
            imgContainer={`${type !== "reels" ? "ratio-9x16" : "ratio-16x9"}`}
            imgSize="object-fit-cover w-auto h-auto"
            title={videoDetails?.content_title}
            logo={!isVideoLoading && videoDetails?.content_to_image?.length > 0 ? videoDetails?.content_to_image[0]?.content_image_id?.thumbnail : !isVideoLoading && fallbackImages.noVideo
            }
            isLoading={isVideoLoading}
            previousPageName={"Videos"}
            onBackButtonClick={onBackButtonClick}
            onTitleClick={onTitleClick}

          />
          <hr className="hr-horizontal" />
          <div className="sidebar-list">
            <ChannelVideoVarticalnav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </Fragment>
  );
});

ChannelVideoSidebar.displayName = "ChannelVideoSidebar";
export default ChannelVideoSidebar;
