import { useQuery } from "@tanstack/react-query";
import {
  GET_REQUISITION_CREATE,
  GET_REQUISITION_EDIT,
} from "../query-constants/requisition-constants";
import axiosInstance from "../../api/instances/axiosInstance";
import {
  CREATE_REQ_POST,
  EDIT_REQ_POST,
} from "../../api/endpoints/requisition-endpoints";

const staleTime = 300000;
const cacheTime = 600000;
export const useFetchCreateRequisition = (id, onSuccess, onError) => {
  return useQuery(
    id ? [GET_REQUISITION_EDIT, id] : [GET_REQUISITION_CREATE],
    () => axiosInstance.get(id ? EDIT_REQ_POST + "/" + id : CREATE_REQ_POST),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime: 0,
      onSuccess,
      onError,
      select: (res) => res.data?.data ?? [],
    }
  );
};
// export const useFetchEditRequisition = (id, onSuccess, onError) => {
//   return useQuery(
//     [GET_REQUISITION_EDIT, id],
//     () => axiosInstance.get(EDIT_REQ_POST + "/" + id),
//     {
//       refetchOnWindowFocus: false,
//       cacheTime,
//       staleTime,
//       enabled: !!id,
//       onSuccess,
//       onError,
//       select: (res) => res.data?.data ?? [],
//     }
//   );
// };
