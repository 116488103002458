import React, { Fragment, useEffect, memo, createRef } from "react";
import _ from "lodash";

//choices
import Choices from "choices.js";
import "../assets/custom/css/choices.css";
import { Form } from "react-bootstrap";

const ChoicesJs = memo((props) => {
  const single = createRef();
  const isMultiple = props.select === "multi" ? true : false;
  const random = () => {
    return Math.floor(Math.random() * 1000) + 1;
  };
  // useEffect(() => {
  //   try {
  //     if (!single.current.classList.contains("choices__input")) {
  //       if (props.options.length > 0) {
  //         const obj = {
  //           removeItemButton: isMultiple,
  //           allowHTML: true,
  //           shouldSort: false,
  //         };
  //         new Choices(single.current, obj);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [isMultiple, single, props]);
  return (
    <Fragment>
      <Form.Select
        ref={single}
        id={random()}
        name={props.name}
        value={props.value}
        className={props.className}
        onChange={(e) =>
          _.isFunction(props.onChange) ? props.onChange(e) : e.preventDefault()
        }
        multiple={isMultiple}
      >
        <option value={""}>-- Select Option--</option>
        {props.options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Select>
    </Fragment>
  );
});
ChoicesJs.displayName = "ChoicesJs";
export default React.memo(ChoicesJs);
