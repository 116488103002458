import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import Search from "../pages/faq";
import FAQ from "../pages/faq";
import RaiseSupportTicket from "../pages/raise-support-ticket";
import Resources from "../pages/resources";


// pages

export const HelpAndSupportRouter = [
  {
    path: "/help-and-support",
    element: <Default />,
    children: [
      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "raise-support-ticket",
        element: <RaiseSupportTicket />,
      },
      {
        path: "resources",
        element: <Resources />,
      },
    ],
  },
];
