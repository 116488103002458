import Skeleton from "../../components/skeleton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const UserDetailsLoader = () => {
  return (
    <>
      {/* <Card className="mt-4 flex-grow-1">
        <Card.Body>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1 border border-2 border-primary rounded-circle p-1">
                <Skeleton variant="circular" width={85} height={85} />
                <span className="position-absolute p-1 end-0 bottom-0 rounded-circle bg-primary text-white d-flex justify-content-center align-items-center border border-4 border-white">
                  <Skeleton variant="circular" width={20} height={20} />
                </span>
              </div>
              <div className="d-flex flex-wrap flex-column align-items-start mb-3 mb-sm-0">
                <Skeleton variant="text" width={140} height={20} />
                <Skeleton variant="text" width={160} height={20} />
              </div>
            </div>

            <div>
              <Skeleton
                variant="rounded"
                width={80}
                height={40}
                className="rounded-pill"
              />
            </div>
          </div>
        </Card.Body>
      </Card> */}
      <Card className="flex-grow-1">
        <Card.Header>
          <Skeleton variant="text" width={140} height={20} />
        </Card.Header>
        <Card.Body>
          <div className="user-bio">
            <Skeleton variant="text" width={"100%"} height={20} />
            <Skeleton variant="text" width={"100%"} height={20} />
          </div>
          <div className="mt-2">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={"100%"} height={20} />
          </div>
          <div className="mt-2">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={"100%"} height={20} />
          </div>
          <div className="mt-2">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={"100%"} height={20} />
          </div>
          <div className="mt-2">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={"100%"} height={20} />
          </div>
          <div className="mt-2">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="text" width={"100%"} height={20} />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserDetailsLoader;
