import Skeleton from "../../components/skeleton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const GeneratorLoader = () => {
  return (
    <>
      <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
        <div>
          <Row>
            <Col lg={9} className="mb-3">
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-3"
              />
            </Col>
            <Col lg={9} className="mb-3">
              <Skeleton
                variant="rounded"
                width="100%"
                height={80}
                className="mb-3"
              />
            </Col>
            <Col lg={9} className="mb-4 mt-auto">
              <Skeleton
                variant="text"
                width="20%"
                height={20}
                className="mb-3"
              />
              <div className="col-12 align-item-between d-flex gap-3">
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={45}
                  className="mb-3"
                />
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={45}
                  className="mb-3"
                />
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={45}
                  className="mb-3"
                />
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={45}
                  className="mb-3"
                />
              </div>
            </Col>
          </Row>
        </div>
        <Col className="flex-grow-0">
          <div className="d-flex gap-3 justify-content-end">
            <Skeleton
              variant="rounded"
              width="18%"
              height={45}
              className="mb-3"
            />
          </div>
        </Col>
      </div>
    </>
  );
};

export default GeneratorLoader;
