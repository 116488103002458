import React, { memo } from "react";
import Skeleton from "../../../components/skeleton";
function UserPersonaSkeleton() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="avatar-80 rounded-circle overflow-hidden border border-secondary border-0">
        <Skeleton variant="circular" width={80} height={80} />
      </div>

      <div className="fs-7 mt-2">
        <Skeleton variant="text" width={100} height={15} />
      </div>
    </div>
  );
}
export default memo(UserPersonaSkeleton);
