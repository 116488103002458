import React, { memo, Fragment, Suspense } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as SettingSelector from "../../../../../store/setting/selectors";
import Headerpro from "../../../../../components/partials/pro/headerstyle/header-pro";
import EventsSidebar from "./EventsSidebar";
import Footer from "../../../../../components/partials/dashboard/footerstyle/footer";
import "../../../../../assets/modules/e-commerce/scss/e-commerce.scss";

const EventsLayout = memo(() => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  //   const params = useParams();
  //   const dispatch = useDispatch();

  return (
    <Fragment>
      <EventsSidebar />
      <main className="main-content">
        <div className="position-relative">
          <Headerpro />
        </div>

        <div
          className={`container-fluid content-inner pb-0 ${pageLayout}`}
          id="page_layout"
        >
          <Suspense fallback={<div className="react-load"></div>}>
            <Outlet />
          </Suspense>
        </div>

        <Footer />
      </main>
    </Fragment>
  );
});

export default EventsLayout;
