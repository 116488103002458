import React, { useState, useContext, memo, Fragment } from "react";

// Router
import { Link, useLocation, useParams } from "react-router-dom";

// React-bootstrap
import {
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

// component
import SidebarMenu from "../../../../../../../components/partials/components/sidebar/sidebar-menu";
import { findIcon } from "../../../../../../dashboard/icons";

const ChannelVideoVarticalnav = memo(() => {
  const { video_id } = useParams();
  return (
    <Fragment>
      <ul className="sub-nav ">
        <SidebarMenu
          isTag="false"
          staticIcon="false"
          pathname={"/channel/video/" + video_id}
          title="Details"
          minititle={findIcon("Dashboard", "dual-tone", 20)}
        >
          <i className="icon">
            {findIcon("Dashboard", "dual-tone", 20)}
          </i>
        </SidebarMenu>

        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/video/analytics/" + video_id}
          title="Analytics"
          minititle={findIcon("Analytics", "dual-tone", 20)}
        >
          <i className="icon">

            {findIcon("Analytics", "dual-tone", 20)}
          </i>
        </SidebarMenu>

        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/video/comments/" + video_id}
          title="Comments"
          minititle={findIcon("Comment-notification", "dual-tone", 20)}
        >
          <i className="icon">
            {findIcon("Comment-notification", "dual-tone", 20)}
          </i>
        </SidebarMenu>
        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/video/campaign/" + video_id}
          title="Campaign"
          minititle={findIcon("Campaign", "dual-tone", 20)}
        >
          <i className="icon">
            {findIcon("Campaign", "dual-tone", 20)}
          </i>
        </SidebarMenu>
      </ul>
    </Fragment>
  );
});
ChannelVideoVarticalnav.displayName = "ChannelVideoVarticalnav";
export default ChannelVideoVarticalnav;
