import Skeleton from "../components/skeleton";
import { Fragment } from "react";

//Componets

const CommonTableLoader = ({
  tableRowLength = 10,
  hasSearch = true,
  hasActions = true,
}) => {
  let tableData = [...Array(tableRowLength).keys()].map((_el) => _el + 1);
  return (
    <Fragment>
      <table role="table" class="mb-0 table-bordered rounded-3 table">
        <thead>
          <tr role="row">
            <th class="text-start align-top">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                className="mb-2"
              />
              {hasSearch && (
                <Skeleton variant="rounded" width={100} height={30} />
              )}
            </th>
            <th class="text-start align-top">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                className="mb-2"
              />
              {hasSearch && (
                <Skeleton variant="rounded" width={100} height={30} />
              )}
            </th>
            <th class="text-start align-top">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                className="mb-2"
              />
              {hasSearch && (
                <Skeleton variant="rounded" width={100} height={30} />
              )}
            </th>
            <th class="text-start align-top">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                className="mb-2"
              />
              {hasSearch && (
                <Skeleton variant="rounded" width={100} height={30} />
              )}
            </th>
            <th class="text-start align-top">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                className="mb-2"
              />
              {hasSearch && (
                <Skeleton variant="rounded" width={100} height={30} />
              )}
            </th>
            <th class="text-start align-top">
              <Skeleton
                variant="text"
                width={60}
                height={20}
                className="mb-2"
              />
              {hasSearch && (
                <Skeleton variant="rounded" width={100} height={30} />
              )}
            </th>
            {hasActions && (
              <th class="text-start align-top">
                <Skeleton
                  variant="text"
                  width={60}
                  height={20}
                  className="mb-2"
                />
              </th>
            )}
          </tr>
        </thead>
        <tbody role="rowgroup">
          {tableData.map((_td, idx) => (
            <tr role="row" className="text-start" key={idx + 1}>
              <td role="cell">
                <Skeleton variant="text" width={100} height={20} />
              </td>
              <td role="cell">
                <Skeleton variant="text" width={60} height={20} />
              </td>
              <td role="cell">
                <Skeleton variant="text" width={60} height={20} />
              </td>
              <td role="cell">
                <Skeleton variant="text" width={60} height={20} />
              </td>
              <td role="cell">
                <Skeleton variant="text" width={60} height={20} />
              </td>
              <td role="cell">
                <Skeleton variant="text" width={60} height={20} />
              </td>
              {hasActions && (
                <td role="cell">
                  <div class="d-flex justify-content-left align-items-center gap-2">
                    <Skeleton variant="circular" width={30} height={30} />
                    <Skeleton variant="circular" width={30} height={30} />
                    <Skeleton variant="circular" width={30} height={30} />
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default CommonTableLoader;
