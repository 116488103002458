import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { initialDate } from "../constant/date-range-constant";
import JSZip from "jszip";
import { useGetCreativePreview } from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import { useParams } from "react-router-dom";
import PageNotFound from "../../../../PageNotFound";
import { findIcon } from "../../../../dashboard/icons";
import Skeleton from "../../../../../components/skeleton";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
// import homeZip from '../../../../../data/homePageDesktop.zip';
function CreativePreview() {
  const { project_id, creative_id, campaign_id } = useParams();

  const { data, isLoading } = useGetCreativePreview(project_id, {
    creative_id: creative_id,
    campaign_list: campaign_id,
  });
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [htmlContentMobile, setHtmlContentMobile] = useState("");
  const [htmlContentDesktop, setHtmlContentDesktop] = useState("");

  const tabs = [
    {
      title: "Desktop",
      eventKey: "Desktop",
      Content: () => (
        <IframeViewer html={htmlContentDesktop} isLoading={isLoading} />
      ),
    },
    {
      title: "Mobile",
      eventKey: "Mobile",
      Content: () => (
        <IframeViewer html={htmlContentMobile} isLoading={isLoading} />
      ),
    },
  ];

  const downloadAndExtractHTML = async (zipFileUrl) => {
    try {
      const response = await fetch(zipFileUrl);
      console.log("response", response);
      if (!response.ok) {
        throw new Error(`Failed to fetch ZIP file: ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      const zip = await JSZip.loadAsync(arrayBuffer);

      let htmlContent = null;
      let htmlFilename = null;

      // Find the HTML file
      for (let filename in zip.files) {
        if (filename.endsWith(".html") && !filename.includes("/")) {
          htmlFilename = filename;
          const file = zip.files[filename];
          htmlContent = await file.async("text");
          break;
        }
      }

      if (!htmlContent) {
        throw new Error("No HTML file found in the ZIP archive");
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");

      // Function to resolve relative paths
      const resolvePath = (base, path) => {
        if (path.startsWith("/")) return path.slice(1);
        const stack = base.split("/");
        const parts = path.split("/");
        stack.pop();
        for (let i = 0; i < parts.length; i++) {
          if (parts[i] === ".") continue;
          if (parts[i] === "..") stack.pop();
          else stack.push(parts[i]);
        }
        return stack.join("/");
      };

      // Process CSS files
      const cssPromises = Array.from(
        doc.querySelectorAll('link[rel="stylesheet"]')
      ).map(async (link) => {
        const cssFile = resolvePath(htmlFilename, link.getAttribute("href"));
        const cssContent = await zip.file(cssFile)?.async("text");
        if (cssContent) {
          const style = doc.createElement("style");
          style.textContent = cssContent;
          link.parentNode.replaceChild(style, link);
        }
      });

      // Process JS files
      const jsPromises = Array.from(doc.querySelectorAll("script[src]")).map(
        async (script) => {
          const jsFile = resolvePath(htmlFilename, script.getAttribute("src"));
          const jsContent = await zip.file(jsFile)?.async("text");
          if (jsContent) {
            const newScript = doc.createElement("script");
            newScript.textContent = jsContent;
            script.parentNode.replaceChild(newScript, script);
          }
        }
      );

      // Process inline CSS imports
      const stylePromises = Array.from(doc.querySelectorAll("style")).map(
        async (style) => {
          let cssContent = style.textContent;
          const importRegex =
            /@import\s+(?:url\(\s*)?['"]?([^'")]+)['"]?\s*\)?;/g;
          let match;
          while ((match = importRegex.exec(cssContent)) !== null) {
            const importPath = resolvePath(htmlFilename, match[1]);
            const importedCss = await zip.file(importPath)?.async("text");
            if (importedCss) {
              cssContent = cssContent.replace(match[0], importedCss);
            }
          }
          style.textContent = cssContent;
        }
      );

      // Process img tags
      const imgPromises = Array.from(doc.querySelectorAll("img")).map(
        async (img) => {
          const imgFile = resolvePath(htmlFilename, img.getAttribute("src"));
          const imgContent = await zip.file(imgFile)?.async("arraybuffer");
          if (imgContent) {
            const blob = new Blob([imgContent]);
            const reader = new FileReader();
            const dataUrl = await new Promise((resolve) => {
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(blob);
            });
            img.src = dataUrl;
          }
        }
      );

      // Wait for all file processing to complete
      await Promise.all([
        ...cssPromises,
        ...jsPromises,
        ...stylePromises,
        ...imgPromises,
      ]);

      return doc.documentElement.outerHTML;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      downloadAndExtractHTML(data[0]?.link).then((htmlContent) => {
        if (htmlContent) {
          setHtmlContentMobile(htmlContent);
          // console.log('Extracted HTML Content:', htmlContent);
        } else {
          console.log("Failed to extract HTML content");
        }
      });

      downloadAndExtractHTML(data[1]?.link).then((htmlContent) => {
        if (htmlContent) {
          setHtmlContentDesktop(htmlContent);
          // console.log('Extracted HTML Content:', htmlContent);
        } else {
          console.log("Failed to extract HTML content");
        }
      });
    }
  }, [data]);

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Creative Preview"}
            projectType={"Brand Ads"}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            // dateLabel={dateRangePayload}
            // setDateRangePayload={setDateRangePayload}
            showDatePicker={false}
          />
        </Col>

        <Col md={12}>
          <HorizontalTab tabs={tabs} />
        </Col>
      </Row>
    </>
  );
}

export default CreativePreview;

function HorizontalTab({ tabs }) {
  return (
    <>
      <Tab.Container defaultActiveKey={tabs[0].eventKey}>
        <Nav className="bg-transparent d-flex align-items-center">
          <div
            className="mb-0 nav nav-tabs bg-transparent fs-6 gap-3 "
            id="nav-tab1"
            role="tablist"
          >
            {tabs?.length > 0 &&
              tabs?.map(({ title, eventKey }) => (
                <Nav.Link
                  key={eventKey}
                  className="px-3 py-1 rounded-pill text-decoration-none border border-secondary"
                  eventKey={eventKey}
                  data-bs-toggle="tab"
                  data-bs-target={`#nav-${eventKey}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${eventKey}`}
                >
                  {title}
                </Nav.Link>
              ))}
          </div>
        </Nav>
        <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
          {tabs?.length > 0 &&
            tabs?.map(({ eventKey, Content }) => (
              <Tab.Pane
                key={eventKey}
                eventKey={eventKey}
                id={`nav-${eventKey}`}
                role="tabpanel"
                aria-labelledby={`nav-${eventKey}`}
              >
                <div className="d-flex flex-column">{<Content />}</div>
              </Tab.Pane>
            ))}
        </Tab.Content>
      </Tab.Container>
    </>
  );
}

const IframeViewer = ({ html, isLoading }) => {
  // const htmlCode = `
  //   <html>
  //     <head>
  //       <title>My Page</title>
  //     </head>
  //     <body>
  //       <h1>Hello, World!</h1>
  //       <p>This is a paragraph.</p>
  //     </body>
  //   </html>
  // `;
  if (isLoading) {
    return (
      <Skeleton
        variant="square"
        width={"100%"}
        height={340}
        className="rounded-3"
      />
    );
  } else if (!isLoading && !html) {
    return (
      <PageNotFound
        title="No data found"
        backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
      />
    );
  }
  return (
    <div>
      <Card>
        <Card.Body className="mb-0 pb-0">
          {html && (
            <iframe
              title="html-viewer"
              style={{
                width: "100%",
                height: "500px",
                border: "1px solid black",
                marginTop: "10px",
              }}
              // src={html}
              srcDoc={html}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};
