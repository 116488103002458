import { memo, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

function VideoUploadBox({ videoFile, thumbnail, setVideoDuration }) {
  const videoRef = useRef(null);
  useEffect(() => {
    if (videoRef.current && videoFile) {
      videoRef.current.wrapper.style.height =
        (videoRef.current.wrapper.offsetWidth * 9) / 16 + "px";
    }
  }, [videoRef.current, videoFile]);
  return (
    <ReactPlayer
      ref={videoRef}
      className="w-100 d-flex bg-light rounded-3 overflow-auto justify-content-center"
      url={
        videoFile && typeof videoFile === "string"
          ? videoFile
          : URL.createObjectURL(videoFile)
      }
      width="100%"
      onDuration={
        setVideoDuration
          ? (duration) => setVideoDuration(duration)
          : (e) => console.log("videoDuration", e)
      }
      light={
        thumbnail
          ? typeof thumbnail === "string"
            ? thumbnail
            : URL.createObjectURL(thumbnail)
          : false
      }
      controls
      muted={true}
      playing={true}
      playsinline={true}
      // vimeoConfig={{
      //   iframeParams: { fullscreen: 0 },
      // }}
    />
  );
}
export default memo(VideoUploadBox);
