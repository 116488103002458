import React from "react";
import Skeleton from "../../../../components/skeleton";

function SidebarCardSkeleton() {
  return (

      <div className="d-flex flex-column justify-content-center align-items-center gap-3 mt-3">
        <Skeleton
          variant="square"
          width="80%"
          height={30}
          className="rounded-3 m-2"
        />

          <div className="d-flex flex-column justify-content-center align-items-center gap-1">
            <Skeleton
              variant="square"
              height={100}
              width={100}
              className="rounded-2"
            />
            <Skeleton variant="text" width={100} />
          </div>
       
      </div>
    
  );
}

export default SidebarCardSkeleton;
