import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { findIcon } from "../../views/dashboard/icons";

function BasicLineChart({ title, series, labels, height = 300 }) {
  const chartRef = useRef(null);

  async function downloadPNG() {
    const chartInstance = chartRef.current;

    console.log("chartInstance ", chartInstance);
    const base64 = await chartInstance.chart.dataURI();
    console.log("base 64", base64.imgURI);
    const downloadLink = document.createElement("a");
    downloadLink.href = base64.imgURI;
    downloadLink.download = "image.png";

    // Add the anchor element to the document
    document.body.appendChild(downloadLink);

    // Simulate a click event to initiate the download
    downloadLink.click();

    // Remove the anchor element from the document
    document.body.removeChild(downloadLink);
  }

  function exportCSV() {
    let csvContent = "Date," + series.map((s) => s.name).join(",") + "\n";
    labels.forEach((category, index) => {
      let row = [category];

      series.forEach((series) => {
        row.push(series.data[index]);
      });
      csvContent += row.join(",") + "\n";
    });

    // Create a blob and download the CSV file
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "chart_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function downloadChart() {
    // Find the ApexCharts instance by ID
    const chartInstance = chartRef.current; //window.Apex._chartInstances.find(chart => chart.id === chartId);

    if (chartInstance) {
      // Download chart as PNG
      downloadPNG(chartInstance);

      // Export chart data as CSV
      // exportCSV(chartInstance);
    } else {
      console.error(`Chart not found.`);
    }
  }

  const chartOptions = {
    series: series || [],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#13388C", "#159A6C", "#156078"],
      dataLabels: {
        enabled: false,
        style: {
          colors: ["#000"],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
        },
      },
      stroke: {
        width: 2,
      },
      markers: {
        size: 7,
      },
      xaxis: {
        categories: labels || [],
      },
    },
  };

  return (
    <Card>
      <Card.Header className="">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h5 className="m-0">{title}</h5>
          <div className="d-flex flex-row gap-3">
            <div
              className="text-success cursor-pointer "
              onClick={downloadChart}
            >
              {findIcon("DownloadReport", "dual-tone", "18")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                Download Report
              </span>
            </div>
            <div className="text-success cursor-pointer " onClick={exportCSV}>
              {findIcon("ExportReport", "dual-tone", "20")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                Export
              </span>
            </div>
          </div>
        </div>
      </Card.Header>
      <div className="d-flex flex-column">
        <Card.Body className="p-0 pe-2 pe-md-0">
          <div id="chart">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="line"
              height={height}
              ref={chartRef}
            />
          </div>
        </Card.Body>
      </div>
    </Card>
  );
}

export default BasicLineChart;
