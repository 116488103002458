import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import Chart from "react-apexcharts";
import GraphTab from '../../components/common/GraphTab';
import { analyticsdateFilterOptions } from "../../Constant/date-filter-constant";
import {
  useGetChannelAnalytics,
  useGetVideoAnalytics,
} from "../../../../../../../queries/query-hooks/workspace/workspace-hook";
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";

function AnalyticsOverview({ data: overViewData }) {
  const { subscriber, watch_time, views } =
    overViewData && Object.values(overViewData)?.length > 0
      ? overViewData
      : {
        subscriber: [],
        views: [],
        watch_time: [],
      };

  const [payload, setPayload] = useState({
    type: analyticsdateFilterOptions.initial,
  });

  function handleDropdownChange(type) {
    setPayload({
      ...payload,
      type,
    });
  }

  function transformGraphData(data, seriesKey, labelKey) {
    if (data && data?.length <= 0) {
      return { series: [], label: [] };
    } else {
      return data.reduce(
        (acc, item) => ({
          series: [...acc.series, parseInt(item[seriesKey])],
          label: [...acc.label, item[labelKey]],
        }),
        { series: [], label: [] }
      );
    }
  }

  const viewsOptions = {
    series: [
      {
        name: "View Count",
        data:
          views &&
            transformGraphData(views, "views", "level")?.series?.length > 0
            ? transformGraphData(views, "views", "level").series
            : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    labels:
      views && transformGraphData(views, "views", "level")?.label?.length > 0
        ? transformGraphData(views, "views", "level").label
        : [],

    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const watchTimeOptions = {
    series: [
      {
        name: "Duration",
        data: watch_time &&
          transformGraphData(watch_time, "watch_time", "level")?.series?.length > 0
          ? transformGraphData(watch_time, "watch_time", "level").series
          : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },

    markers: {
      size: [4, 7],
    },
    dataLabels: {
      enabled: false,
    },
    labels: watch_time &&
      transformGraphData(watch_time, "watch_time", "level")?.label?.length > 0
      ? transformGraphData(watch_time, "watch_time", "level").label
      : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const subscribersOptions = {
    series: [
      {
        name: "Subscriber Count",
        data: subscriber && transformGraphData(subscriber, "subcriber_count", "level")?.series?.length > 0
          ? transformGraphData(subscriber, "subcriber_count", "level").series
          : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },

      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    markers: {
      size: [4, 7],
    },
    labels: subscriber && transformGraphData(subscriber, "subcriber_count", "level")?.label?.length > 0
      ? transformGraphData(subscriber, "subcriber_count", "level").label
      : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  function sumArray(arr) {
    if (!arr?.length) { return 0 } else {
      return arr.reduce((sum, current) => sum + current, 0)?.toLocaleString()
    }
  }


  const graphTabs = [
    {
      eventKey: "first-gr", title: "Views", dataCount: `${viewsOptions.series[0]?.data?.length > 0 ? sumArray(viewsOptions.series[0]?.data) : 0}`, content: <>    <Card.Body className="p-0">
        <div>
          <div id="chart-gr">
            {viewsOptions?.series?.length > 0 && (
              <Chart
                options={viewsOptions}
                series={viewsOptions.series}
                type="area"
                height={350}
              />
            )}
          </div>
        </div>
      </Card.Body></>
    },
    {
      eventKey: "second-gr", title: "Watch Time (Min)", dataCount: `${watchTimeOptions.series[0]?.data?.length > 0 ? sumArray(watchTimeOptions.series[0]?.data) : 0}`, content: <> <Card.Body className="p-0">
        <div>
          <div id="chart-watch">
            {watchTimeOptions.series?.length > 0 && (
              <Chart
                options={watchTimeOptions}
                series={watchTimeOptions.series}
                type="area"
                height={350}
              />
            )}
          </div>
        </div>
      </Card.Body></>
    },
    // {
    //   eventKey: "third-gr", title: "Subscribers", content: <>
    //     <Card.Body className="p-0">
    //       <div>
    //         <div id="chart-sub">
    //           {subscribersOptions.series?.length > 0 && (
    //             <Chart
    //               options={subscribersOptions}
    //               series={subscribersOptions.series}
    //               type="area"
    //               height={350}
    //             />
    //           )}
    //         </div>
    //       </div>
    //     </Card.Body></>
    // }
  ]

  return (
    <Card>
      <Card.Body>
        <GraphTab tabs={graphTabs} />
      </Card.Body>
    </Card>
  );
}

export default AnalyticsOverview;
