import React from "react";
// layout
import Default from "../../../layouts/dashboard/default";
import Workspacelist from "../doctube/pages/Workspacelist";
import { ChannelRouter } from "../doctube/pages/channel/router/channel-router";
import ProjectDetails from "../doctube/pages/channel/pages/project-details";
import { BrandAdsRouter } from "../clirnet/brandAds/router/brand-ads-router";
import CreateProject from "../clirnet/central/pages/CreateProject";
import { SponsoredContentRouter } from "../clirnet/sponsored-content/router/sponsored-content-router";
import { EventsRouter } from "../clirnet/events/router/eventsRouter";
export const WorkspaceRouter = [
  ...ChannelRouter,
  ...BrandAdsRouter,
  ...SponsoredContentRouter,
  ...EventsRouter,
  {
    path: "/workspace",
    element: <Default />,
    children: [
      // {
      //     path: "list",
      //     element: <Workspacelist />,
      // },
      {
        path: "doctube",
        element: <Workspacelist id={1} />,
      },
      {
        path: "clirnet",
        // element: <>Under Development</>,
        element: <Workspacelist id={2} />,
      },
      {
        path: "create-project",
        element: <CreateProject project_group_id={2} />,
      },
      {
        path: "edit-project/:project_id",
        element: <CreateProject project_group_id={2} />,
      },
      {
        path: "project-details/:channel_id",
        element: <ProjectDetails />,
      },
    ],
  },
];
