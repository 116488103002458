import { useQuery } from "@tanstack/react-query";
import {
  GET_CHANNEL_EDIT,
  GET_CHANNEL_REACH,
  GET_CHANNEL_VIDEO_CREATE,
  GET_CHANNEL_VIDEO_DETAILS,
  GET_CHANNEL_VIDEO_EDIT,
  GET_CHECK_CHANNEL_NAME,
} from "../../query-constants/workspace/workspace-constant";
import axiosInstance from "../../../api/instances/axiosInstance";
import * as channelVideoConstants from "../../../api/endpoints/channel-video-endpoints";
const staleTime = 300000;
const cacheTime = 600000;
export const useGetVideoCreate = (channel_id) => {
  return useQuery(
    [GET_CHANNEL_VIDEO_CREATE, channel_id],
    () =>
      axiosInstance.get(
        channelVideoConstants.CHANNEL_VIDEO_CREATE + channel_id
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};
export const useGetVideoEdit = (video_id, onSuccess) => {
  return useQuery(
    [GET_CHANNEL_VIDEO_EDIT, video_id],
    () =>
      axiosInstance.get(channelVideoConstants.CHANNEL_VIDEO_EDIT + video_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      select: (res) => res?.data ?? {},
      onSuccess,
    }
  );
};
export const useGetVideoDetails = (video_id, onSuccess) => {
  return useQuery(
    [GET_CHANNEL_VIDEO_DETAILS, video_id],
    () =>
      axiosInstance.get(channelVideoConstants.CHANNEL_VIDEO_DETAILS + video_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      onSuccess,
    }
  );
};

export const useGetChannelEditData = (channel_id, onSuccess) => {
  return useQuery(
    [GET_CHANNEL_EDIT, channel_id],
    () =>
      axiosInstance.get(channelVideoConstants.CHANNEL_EDIT_GET + channel_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      // staleTime,
      select: (res) => res?.data ?? {},
      onSuccess,
    }
  );
};

export const useGetChannelReach = (channel_id, onSuccess) => {
  return useQuery(
    [GET_CHANNEL_REACH, channel_id],
    () =>
      axiosInstance.get(channelVideoConstants.CHANNEL_REACH_GET + channel_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
      onSuccess,
    }
  );
};

export const useCheckChannelName = (channelName, enabled) => {
  return useQuery(
    [GET_CHECK_CHANNEL_NAME, channelName],
    () =>
      axiosInstance.post(channelVideoConstants.CHANNEL_NAME_CHECK, {
        channel_name: channelName,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data ?? {},
      enabled,
    }
  );
};
