import React from "react";
import { useSelector } from "react-redux";
import * as authSelectors from "../../store/auth/selectors";
import { Navigate } from "react-router-dom";

const withAuthorization = (WrappedComponent) => {
  return function Wrapper(props) {
    // const isAuthenticated = useSelector(authSelectors.isAuthenticated);
    // return isAuthenticated ? <WrappedComponent /> : <Navigate to={"/"} />;
    return <WrappedComponent />
  };
};

export default withAuthorization;
