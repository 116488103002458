import React, { memo, Fragment, useEffect, useState } from "react";

import { Button, Card, Col, Dropdown, Nav, Row } from "react-bootstrap";
import CampaignStatsCard from "../components/campaign-stats-card";
import CustomToggle from "../../../../components/dropdowns";
// Apexcharts
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { theme_color } from "../../../../store/setting/selectors";
import { findIcon } from "../../../dashboard/icons";

//Count-up
import CountUp from "react-countup";

//progressbar
// import Progress from "../../components/progress.js";
import Progress from "../../../../components/progress.js";

//flatpickr
import Flatpickr from "react-flatpickr";
import { getService } from "../../../../api/services/get-services";
import { CAMPAIGN_DETAILS_GET } from "../../../../api/endpoints/campaign-endpoints";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const svg1 = findIcon("Eye", "solid");

const svg2 = findIcon("Action", "dual-tone");

const svg3 = findIcon("Users", "dual-tone");

const svg4 = findIcon("Users", "dual-tone");

const CampaignDetails = () => {
  const [loading, setLoading] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getCampaignData();
    }
  }, []);
  const getCampaignData = async () => {
    try {
      setLoading(true);
      const response = await getService(CAMPAIGN_DETAILS_GET + `/${params.id}`);
      console.log("content studio details response ", response.data);

      if (response.isError) {
        toast.error(response?.error);
      } else {
        setCampaignDetails(response?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const themeColor = useSelector(theme_color);
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color5 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}success`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      success: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
      primary: color5.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.success,
    variableColors.warning,
  ];
  useEffect(() => {
    return () => colors;
  });

  const chart1 = {
    options: {
      colors: colors,
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        width: 3,
        dashArray: 0,
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "WhatsApp",
        data: [7, 4, 9, 4, 7, 3, 8],
      },
      {
        name: "SMS",
        data: [3, 5, 3, 7, 4, 6, 9],
      },
      {
        name: "Emial",
        data: [5, 6, 7, 2, 5, 8, 3],
      },
    ],
  };
  const chart2 = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "50%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels: ["Apples", "Oranges"],
    },
    series: [85, 75],
  };
  return (
    <Fragment>
      <Row className="m-0">
        <Col
          lg={12}
          className="bg-white rounded-3 mb-4 p-3 d-flex justify-content-between align-items-center"
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <h4 className="m-0">{campaignDetails?.campaign_name}</h4>
            </div>
            <ul className="d-flex list-unstyled flex-row gap-4 m-0 mt-2 align-items-center">
              <li eventKey="disabled">
                <a
                  href="javascript:void(0)"
                  className="d-inline-flex align-items-center gap-1 text-success lh-base"
                >
                  {findIcon("Circle", "solid", "14")}
                  Running
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 lh-base"
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                  Edit
                </a>
              </li>
              <li>
                <Button
                  variant="soft-danger"
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 lh-base btn-sm rounded-2"
                >
                  {findIcon("Circle", "dual-tone", "20")}
                  Stop
                </Button>
              </li>
              {/* <li>
                <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 lh-base"
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                  Clone
                </a>
              </li> */}
            </ul>
          </div>
          <div className="form-group m-0 d-flex gap-4">
            {/* <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            /> */}
            <div className="d-flex align-items-center">
              <span className="border bg-soft-success rounded p-2">
                {findIcon("Users", "dual-tone", "24")}
              </span>
              <span className="d-flex flex-column ms-3">
                <b className="fw-400 text-secondary">Campaign Send</b>{" "}
                <i>03 Jan 2023</i>
              </span>
            </div>
            <div className="d-flex align-items-center">
              <div className="border bg-soft-warning rounded p-2">
                {findIcon("Location Arrow", "dual-tone", "24")}
              </div>
              <div className="d-flex flex-column ms-3">
                <h5 className="counter m-0 fs-6 mb-2">86% of Completed</h5>
                <div>
                  <Progress
                    softcolors="warning"
                    color="warning"
                    className="shadow-none w-100"
                    value={86}
                    minvalue={0}
                    maxvalue={100}
                    style={{ height: "6px" }}
                  />
                </div>
              </div>
            </div>
            {/* <div className="d-flex">
              <span className="bg-soft-warning p-2 rounded-3">
                {findIcon("File", "dual-tone", "24")}
              </span>
              <span className="d-flex flex-column ms-3">
                <b className="fw-400 text-secondary">Campaign Created by</b>{" "}
                <i>Satyananda Ganguly</i>
              </span>
            </div> */}
          </div>
        </Col>
      </Row>
      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
        <Col className="d-flex">
          <CampaignStatsCard
            color="danger"
            svg={svg4}
            percentage="86%"
            counter="440"
            content="Sent"
            tooltipContent="Total number of times the campaign has been delivered to users."
          />
        </Col>
        <Col className="d-flex">
          <CampaignStatsCard
            color="success"
            svg={svg4}
            percentage="10%"
            counter="440"
            content="Delivered"
            tooltipContent="Total number of times the campaign has been delivered to users."
          />
        </Col>
        <Col className="d-flex">
          <CampaignStatsCard
            color="warning"
            svg={svg1}
            percentage="9%"
            counter="1206"
            content="Viewed"
            tooltipContent="Total number of times the campaign has been viewed by users."
          />
        </Col>
        <Col className="d-flex">
          <CampaignStatsCard
            color="primary"
            svg={svg2}
            counter="1,187"
            percentage="27%"
            content="Clicks"
            tooltipContent="Total number of times the campaign has been clicked by users."
          />
        </Col>
        {/* <Col className="d-flex">
          <CampaignStatsCard
            color="info"
            svg={svg3}
            counter="238"
            percentage="43%"
            tooltipContent="Total number of times the campaign has been clicked by users."
            content="CTR"
          />
        </Col> */}
      </Row>
      <Row>
        <Col lg={6} className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
              <Card.Title>
                <h4 className="mb-0">Performance Trend</h4>
                <p className="mb-0 fs-7 text-secondary mt-2">Last 30 Days</p>
              </Card.Title>

              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="text-secondary dropdown-toggle"
                >
                  30 Days
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                  <Dropdown.Item href="#">Last 3 Months</Dropdown.Item>
                  <Dropdown.Item href="#">Last 6 Years</Dropdown.Item>
                  <Dropdown.Item href="#">This Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart1.options}
                series={chart1.series}
                type="line"
                height="300"
              />
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <div className="d-flex align-items-center text-success">
                  {findIcon("Whatsapp", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">WhatsApp</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-warning">
                  {findIcon("Email", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">SMS</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-info">
                  {findIcon("Message-2", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">E-Mail</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
              <Card.Title>
                <h4 className="mb-0">Conversion performance</h4>
                <p className="mb-0 fs-7 text-secondary mt-2">Last 30 Days</p>
              </Card.Title>

              {/* <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="text-secondary dropdown-toggle">
                  This Year
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                  <Dropdown.Item href="#">Another action</Dropdown.Item>
                  <Dropdown.Item href="#">Something else here</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart2.options}
                series={chart2.series}
                type="radialBar"
                height="300"
              />
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <div className="d-flex align-items-center text-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                  <div className="ms-2">
                    <span className="text-secondary">Viewed</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                  <div className="ms-2">
                    <span className="text-secondary">Clicked</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CampaignDetails;
