import React, { useState } from "react";
import { Modal, Col, Form, Button } from "react-bootstrap";
import SubmitButton from "../../../../components/form-components/submit-button";
import * as Yup from "yup";
import { useFormik } from "formik";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { UPLOAD_FILES_POST } from "../../../../api/endpoints/action-endpoints";
import uploadService from "../../../../api/services/upload-service";
import { Link } from "react-router-dom";
import MediaRenderer from "./MediaRenderer";

const initialValues = {
  medium: "",
  comment: "",
  reference_files: [],
};

const onboardingSchema = Yup.object().shape({
  comment: Yup.string().required("Please Enter Comment"),
  reference_files: Yup.array()
    .of(Yup.string().required("Unsupported file type"))
    .test("required-if-medium-2", "Please select file", function (value) {
      const { medium } = this.parent;
      return medium !== "2" || (medium === "2" && value && value.length > 0);
    }),
  medium: Yup.string().required("Required"),
});

export default function AddCommentsModal({
  show,
  onSubmit,
  onHide,
  onboardingDropdownValues,
}) {
  const [previewImages, setPreviewImages] = useState(null);
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: onboardingSchema,
    onSubmit: (values) => {
      onSubmit({ ...values, hcp_medium: values.medium });
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const { medium: mediumOptions } = onboardingDropdownValues || { medium: [] };

  async function uploadFiles(files) {
    showBackdrop();
    return new Promise(async (resolve, reject) => {
      if (files.length === 0) {
        hideBackdrop();
        reject("No files to upload.");
        return;
      }

      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        hideBackdrop();
        const urls = response.data.data;
        resolve(urls);
      } else {
        hideBackdrop();
        reject("Something went wrong");
      }
    });
  }

  const changeFileUpload = async (fieldName, file) => {
    if (Array.isArray(file)) {
      const urls = await uploadFiles(file);

      if (urls?.length > 0) {
        setFieldValue(fieldName, [urls[0].name]);
        setPreviewImages(urls[0].url);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reason For Rejection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col lg="12" className="mb-4">
          <Form.Floating className="custom-form-floating">
            <Form.Select
              id="floatingSelectGrid"
              name="medium"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.medium}
              isInvalid={touched.medium && !!errors.medium}
            >
              <option value="">Select Medium</option>
              {mediumOptions?.map(({ value, level }) => (
                <option key={value} value={value}>
                  {level}
                </option>
              ))}
            </Form.Select>
            <Form.Label htmlFor="floatingInput1">
              Medium <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.medium}
            </Form.Control.Feedback>
          </Form.Floating>
        </Col>
        <Col lg="12" className="mb-4">
          <Form.Label>
            {values.medium === "2"
              ? "Upload Call Recording *"
              : "Upload Supporting Document (.JPG,PNG,JPEG,PDF)"}
          </Form.Label>
          <Form.Control
            type="file"
            accept={values.medium === "2" ? "audio/*" : "image/*"}
            multiple
            name="reference_files"
            isInvalid={touched.reference_files && !!errors.reference_files}
            onChange={(e) =>
              changeFileUpload("reference_files", Object.values(e.target.files))
            }
          />
          <Form.Control.Feedback type="invalid">
            {errors.reference_files}
          </Form.Control.Feedback>
          {values.reference_files?.length > 0 && previewImages !== null && (
            <Col lg="12" className="text-center mt-5">
              <MediaRenderer
                url={previewImages}
                alt="uploaded file"
                className="img-fluid rounded"
              />
            </Col>
          )}
        </Col>
        <Col lg="12" className="mb-4">
          <Form.Floating
            controlId="floatingTextarea2"
            className="custom-form-floating"
          >
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              name="comment"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comment}
              isInvalid={touched.comment && !!errors.comment}
            />
            <Form.Label>Comments</Form.Label>
          </Form.Floating>
        </Col>
        <Col lg="12" className="d-flex justify-content-end">
          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </Col>
      </Modal.Body>
    </Modal>
  );
}
