import { memo, useEffect } from "react";

// React-bootstrap

// Router

//Quill
import "quill/dist/quill.snow.css";
import { useQuill } from "react-quilljs";

//Components

const Quilleditor = ({ children, updateData }) => {
  const theme = "snow";

  const placeholder = "Compose an epic...";
  const addParagraphWithId = () => {
    const id = prompt("Enter ID for the paragraph:");
    if (id) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);
      quill.insertText(range.index, "\n", "user");
      quill.insertText(range.index + 1, `<p id="${id}"></p>`, "user");
      quill.setSelection(range.index + 1, Quill.sources.SILENT);
      quill.focus();
    }
  };
  const modules = {
    magicUrl: true,
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        ["link", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["clean"],
      ],
    },
  };

  const { quillRef, quill, Quill } = useQuill({
    placeholder,
    modules,
  });

  if (Quill && !quill) {
    // For execute this line only once.
    const MagicUrl = require("quill-magic-url").default; // Install with 'yarn add quill-magic-url'
    Quill.register("modules/magicUrl", MagicUrl);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (content, delta, source, editor) => {
        updateData({
          html: quill.root.innerHTML,
          rawText: quill.getText(),
        });
      });
    }
  }, [quill]);
  return (
    <div ref={quillRef} style={{ minHeight: "100px" }}>
      {children}
    </div>
  );
};

Quilleditor.displayName = "Quilleditor";
export default memo(Quilleditor);
