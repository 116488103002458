import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./setting/reducers";
import authReducer from "./auth/reducers";
import reactTableReducer from "./react-table/react-table.reducer";
import commonReducer from "./common/reducers.js";
import segmentReducer from "./segment/segment.reducer";
import contentStudioReducer from "./content-studio/content-studio.reducer";
import channelReducer from "./channel/channel.reducer.js";
import projectReducer from "./project/project.reducer.js";

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    auth: authReducer,
    reactTable: reactTableReducer,
    common: commonReducer,
    contentStudio: contentStudioReducer,
    segment: segmentReducer,
    channel: channelReducer,
    project: projectReducer,
  },
});
