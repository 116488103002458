import React,{memo} from "react";
import { Card, Row } from "react-bootstrap";
import Skeleton from "../../../components/skeleton";

function HcpCardSkeleton() {
  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">
            <Skeleton width={100} height={40} />
          </h4>
        </div>
      </Card.Header>

      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />{" "}
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />{" "}
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(HcpCardSkeleton);
