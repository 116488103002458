import React, { memo, Fragment, useMemo, useState, useEffect } from "react";

//React-bootstrap
import { Dropdown, Image } from "react-bootstrap";

//Router-dom
import { Link } from "react-router-dom";

//Componet
import Card from "../../../../components/bootstrap/card";
import { findIcon } from "../../../dashboard/icons";
import NotificationLoader from "../../../../skeleton/notification-loader";

const Notificationcard = memo((props) => {
  const { image, title, svg, color, date, read } = props;
  console.log("NOTIFICATION PROPS", image, title, svg, color, date, props);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const svgHandler = useMemo(() => {
    switch (svg) {
      case "upload":
        return findIcon("Save Up", "dual-tone", 20);
      case "add":
        return findIcon("Square Plus", "dual-tone", 20);
      case "comment":
        return findIcon("Chat", "dual-tone", 20);
      default:
        return "";
    }
  }, [svg]);
  return (
    <Fragment>
      {!loading ? (
        <Card
          className={`${
            read ? "" : "bg-soft-secondary"
          } mb-2 notification_Card position-relative`}
        >
          <Card.Body>
            <ul className="notification-list m-0 p-0">
              <li className="d-flex align-items-center justify-content-between gap-3">
                <div className="user-img img-fluid position-relative">
                  <Image
                    src={image}
                    alt="story-img"
                    className="avatar-80 rounded-circle"
                    loading="lazy"
                  />
                  {read && (
                    <span className="bg-primary position-absolute rounded-circle w-25 h-25 top-0 end-0"></span>
                  )}
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>{title}</h6>
                      <p className="mb-0">{date}</p>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <Link
                        to="/"
                        onClick={(e) => e.preventDefault()}
                        className={`iq-notify bg-soft-${color} rounded d-flex justify-content-center align-items-center p-1`}
                      >
                        {svgHandler}
                      </Link>
                      <div className="card-header-toolbar d-flex align-items-center cursor-pointer">
                        <Dropdown>
                          <Dropdown.Toggle
                            as="svg"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentcolor"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 20.6788C10 21.9595 11.0378 23 12.3113 23C13.5868 23 14.6265 21.9595 14.6265 20.6788C14.6265 19.3981 13.5868 18.3576 12.3113 18.3576C11.0378 18.3576 10 19.3981 10 20.6788ZM10 12.0005C10 13.2812 11.0378 14.3217 12.3113 14.3217C13.5868 14.3217 14.6265 13.2812 14.6265 12.0005C14.6265 10.7198 13.5868 9.67929 12.3113 9.67929C11.0378 9.67929 10 10.7198 10 12.0005ZM12.3113 5.64239C11.0378 5.64239 10 4.60192 10 3.3212C10 2.04047 11.0378 1 12.3113 1C13.5868 1 14.6265 2.04047 14.6265 3.3212C14.6265 4.60192 13.5868 5.64239 12.3113 5.64239Z"
                              fill="#8A92A6"
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className=" dropdown-menu-right">
                            <Dropdown.Item className=" d-flex align-items-center">
                              <svg
                                width="18"
                                viewBox="0 0 24 24"
                                className="me-3"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.4"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.7366 6.04606C19.4439 7.36388 20.8976 9.29455 21.9415 11.7091C22.0195 11.8924 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8924 2.05854 11.7091C4.14634 6.87903 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M14.4308 11.997C14.4308 13.3255 13.3381 14.4115 12.0015 14.4115C10.6552 14.4115 9.5625 13.3255 9.5625 11.997C9.5625 11.8321 9.58201 11.678 9.61128 11.5228H9.66006C10.743 11.5228 11.621 10.6695 11.6601 9.60184C11.7674 9.58342 11.8845 9.57275 12.0015 9.57275C13.3381 9.57275 14.4308 10.6588 14.4308 11.997Z"
                                  fill="currentColor"
                                ></path>
                              </svg>{" "}
                              View
                            </Dropdown.Item>
                            <Dropdown.Item className=" d-flex align-items-center">
                              <svg
                                width="15"
                                viewBox="0 0 24 24"
                                className="me-3"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.4"
                                  d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
                                  fill="currentColor"
                                ></path>
                              </svg>{" "}
                              Delete
                            </Dropdown.Item>
                            {/* <Dropdown.Item className="d-flex align-items-center">
                            <svg
                              width="18"
                              viewBox="0 0 24 24"
                              className="me-3"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M19.9927 18.9534H14.2984C13.7429 18.9534 13.291 19.4124 13.291 19.9767C13.291 20.5422 13.7429 21.0001 14.2984 21.0001H19.9927C20.5483 21.0001 21.0001 20.5422 21.0001 19.9767C21.0001 19.4124 20.5483 18.9534 19.9927 18.9534Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M10.309 6.90385L15.7049 11.2639C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8344 7.72908 20.8452L4.23696 20.8882C4.05071 20.8903 3.88775 20.7613 3.84542 20.5764L3.05175 17.1258C2.91419 16.4915 3.05175 15.8358 3.45388 15.3306L9.88256 6.95545C9.98627 6.82108 10.1778 6.79743 10.309 6.90385Z"
                                fill="currentColor"
                              ></path>
                              <path
                                opacity="0.4"
                                d="M18.1208 8.66544L17.0806 9.96401C16.9758 10.0962 16.7874 10.1177 16.6573 10.0124C15.3927 8.98901 12.1545 6.36285 11.2561 5.63509C11.1249 5.52759 11.1069 5.33625 11.2127 5.20295L12.2159 3.95706C13.126 2.78534 14.7133 2.67784 15.9938 3.69906L17.4647 4.87078C18.0679 5.34377 18.47 5.96726 18.6076 6.62299C18.7663 7.3443 18.597 8.0527 18.1208 8.66544Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="d-flex align-items-center">
                            <svg
                              width="18"
                              viewBox="0 0 24 24"
                              className="me-3"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_321_127)">
                                <path
                                  d="M16.0002 4.5H8.00024C6.89567 4.5 6.00024 5.39543 6.00024 6.5V6.80859H18.0002V6.5C18.0002 5.39543 17.1048 4.5 16.0002 4.5Z"
                                  fill="currentColor"
                                />
                                <rect
                                  x="6.00024"
                                  y="12"
                                  width="12"
                                  height="7.5"
                                  rx="2"
                                  fill="currentColor"
                                />
                                <path
                                  opacity="0.4"
                                  d="M19 6.80666H5C3.89543 6.80666 3 7.70209 3 8.80665V13.6928C3 14.7974 3.89543 15.6928 5 15.6928H17.625H19C20.1046 15.6928 21 14.7974 21 13.6928V8.80666C21 7.70209 20.1046 6.80666 19 6.80666Z"
                                  fill="currentColor"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_321_127">
                                  <rect
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            Print
                          </Dropdown.Item>
                          <Dropdown.Item className="d-flex align-items-center">
                            <svg
                              width="18"
                              viewBox="0 0 24 24"
                              className="me-3"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M18.8089 9.021C18.3574 9.021 17.7594 9.011 17.0149 9.011C15.199 9.011 13.7059 7.508 13.7059 5.675V2.459C13.7059 2.206 13.503 2 13.2525 2H7.96436C5.49604 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59109 22 8.1703 22H16.0455C18.5059 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6238 9.016 19.1168 9.021 18.8089 9.021Z"
                                fill="currentColor"
                              ></path>
                              <path
                                opacity="0.4"
                                d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1732 7.55437 17.2792 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M15.1052 12.7088C14.8132 12.4198 14.3432 12.4178 14.0512 12.7108L12.4622 14.3078V9.48084C12.4622 9.06984 12.1282 8.73584 11.7172 8.73584C11.3062 8.73584 10.9722 9.06984 10.9722 9.48084V14.3078L9.38224 12.7108C9.09124 12.4178 8.62024 12.4198 8.32924 12.7088C8.03724 12.9988 8.03724 13.4698 8.32624 13.7618L11.1892 16.6378H11.1902C11.2582 16.7058 11.3392 16.7608 11.4302 16.7988C11.5202 16.8358 11.6182 16.8558 11.7172 16.8558C11.8172 16.8558 11.9152 16.8358 12.0052 16.7978C12.0942 16.7608 12.1752 16.7058 12.2432 16.6388L12.2452 16.6378L15.1072 13.7618C15.3972 13.4698 15.3972 12.9988 15.1052 12.7088Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                            Download
                          </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </Card.Body>
        </Card>
      ) : (
        <NotificationLoader />
      )}
    </Fragment>
  );
});

Notificationcard.displayName = "Notificationcard";
export default Notificationcard;
