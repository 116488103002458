import React, { memo, Fragment } from "react";

//Router
import { Link, useLocation } from "react-router-dom";

//React-bootstrap
import { Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import Skeleton from "../../components/skeleton";

const MenuSkeleton = memo((props) => {
  return (
    <Fragment>
      <Nav.Item as="li">
        <Link
          className={`active nav-link`}
          //   to={props.pathname}
          //   onClick={props.modalopen}
          //   target={props.target}
        >
          <Skeleton variant="rounded" width={20} height={20} />
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Loading...</Tooltip>}
          >
            <i className="sidenav-mini-icon">
              <Skeleton variant="rounded" width={20} height={20} />
            </i>
          </OverlayTrigger>
          <span className="item-name">
            <Skeleton variant="text" width={100} height={20} />{" "}
          </span>
        </Link>
      </Nav.Item>
    </Fragment>
  );
});

MenuSkeleton.displayName = "MenuSkeleton";
export default MenuSkeleton;
