import Default from "../../../../layouts/dashboard/default";
import OnboardingApproval from "../pages/onboarding-approval";
import OnboardingApprovalDetails from "../pages/onboarding-approval-editable";
import CampaignApproval from "../pages/campaign-approval";
import CampaignApprovalDetails from "../pages/campaign-approval-details";
import OnboardingTask from "../pages/onboarding-task";
import OnboardingTaskDetails from "../pages/onboarding-task-details";
import AbTestApprovalDetails from "../pages/ab-test-approval-details";
import AbTestFinalApprovalDetails from "../pages/ab-test-final-approval-details";
import ActionList from "../pages/all-action-list";
import EngagementTasks from "../pages/engagement-tasks";
import EngagementTaskDetails from "../pages/engagement-task-details";
import ReplyTaskDetails from "../pages/reply-task-details";
import ActionDetails from "../pages/action-details";
import MyActionList from "../pages/my-action-list";
import OnboardingTaskView from "../pages/onboarding-task-view";
export const ActionRouter = [
  {
    path: "action",
    element: <Default />,
    children: [
      {
        path: "onboarding-task",
        element: <OnboardingTask />,
      },
      {
        path: "engagement-task",
        element: <EngagementTasks />,
      },
      {
        path: "engagement-task/:id",
        element: <EngagementTaskDetails />,
      },
      {
        path: "reply-task/:id",
        element: <ReplyTaskDetails />,
      },
      {
        path: "onboarding-task-details/:type/:actionId",
        element: <OnboardingTaskDetails />,
      },
      {
        path: "onboarding-task-view/:actionId",
        element: <OnboardingTaskView />,
      },
      {
        path: "onboarding-approval",
        element: <OnboardingApproval />,
      },
      {
        path: "onboarding-approval-details/:actionId",
        element: <OnboardingApprovalDetails />,
      },
      {
        path: "onboarding-review-edit/:actionId",
        element: <OnboardingApprovalDetails />,
      },
      {
        path: "re-onboarding-review-edit/:actionId",
        element: <OnboardingApprovalDetails />,
      },
      {
        path: "campaign-approval",
        element: <CampaignApproval />,
      },
      {
        path: "campaign-approval-details",
        element: <CampaignApprovalDetails />,
      },
      {
        path: "ab-test-approval-details",
        element: <AbTestApprovalDetails />,
      },
      {
        path: "ab-test-final-approval-details",
        element: <AbTestFinalApprovalDetails />,
      },
      {
        path: "ab-test-final-approval-details/:id/:name",
        element: <AbTestFinalApprovalDetails />,
      },
      {
        path: "all-action",
        element: <ActionList />,
      },
      {
        path: "my-action",
        element: <MyActionList />,
      },
      {
        path: "my-action/:type",
        element: <MyActionList />,
      },
      {
        path: "details/:type/:id",
        element: <ActionDetails />,
      },
    ],
  },
];
