import React from "react";
import MyChannels from "../pages/my-channels";
import Profile from "../pages/channel-profile";
import ChannelLayout from "../layouts/channel-layout";
import ChannelAnalytics from "../pages/channel-analytics";
import ChannelCustomization from "../pages/channel-customization";
import ChannelComments from "../pages/channel-comments";
import CreateChannel from "../pages/create-channel";
// import CustomizeChannel from "../pages/customize-channel";
import ChannelVideos from "../pages/channel-videos";
import ChannelDashboard from "../pages/channel-dashboard";
import ChannelVideoLayout from "../layouts/channel-video-layout";
import ChannelVideoDetails from "../pages/channel-video-details";
import ChannelVideoAnalytics from "../pages/channel-video-analytics";
import ChannelVideoComments from "../pages/channel-video-comments";
import ChannelVideoCampaign from "../pages/channel-video-campaign";
import VideoCampaignAnalytics from "../pages/video-campaign-analytics";
import ChannelFileUpload from "../pages/channel-file-upload";
import ChannelCampaign from "../pages/channel-campaign";
import ChannelCreateCampaign from "../pages/channel-create-campaign";
import Default from "../../../../../../layouts/dashboard/default";
import RequestContet from "../pages/RequestContet";

// layout
export const ChannelRouter = [
  {
    path: "project",
    element: <Default />,
    children: [
      {
        path: "channels",
        element: <MyChannels />,
      },
      {
        path: "create-channel/:project_id",
        element: <CreateChannel />,
      },
    ],
  },
  {
    path: "channel",
    element: <ChannelLayout />,
    children: [
      {
        path: ":channel_id",
        element: <Profile />,
      },
      // {
      //   path: "customize/:channel_id",
      //   element: <CustomizeChannel />,
      // },
      {
        path: "dashboard/:channel_id",
        element: <ChannelDashboard />,
      },
      {
        path: "request-conetnt/:channel_id",
        element: <RequestContet />,
      },
      {
        path: "videos/:channel_id",
        element: <ChannelVideos />,
      },
      {
        path: "analytics/:channel_id",
        element: <ChannelAnalytics />,
      },
      {
        path: "comments/:channel_id",
        element: <ChannelComments />,
      },
      {
        path: "customization/:channel_id",
        element: <ChannelCustomization />,
      },
      {
        path: "campaign/:channel_id",
        element: <ChannelCampaign />,
      },
      {
        path: "campaign/analytics/:campaign_key/:channel_id",
        element: <VideoCampaignAnalytics />,
      },
      {
        path: "create-campaign/:channel_id",
        element: <ChannelCreateCampaign />,
      },
      {
        path: "file-upload/:channel_id",
        element: <ChannelFileUpload />,
      },
    ],
  },
  {
    path: "channel/video",
    element: <ChannelVideoLayout />,
    children: [
      {
        path: ":video_id",
        element: <ChannelVideoDetails />,
      },
      {
        path: "analytics/:video_id",
        element: <ChannelVideoAnalytics />,
      },
      {
        path: "comments/:video_id",
        element: <ChannelVideoComments />,
      },
      {
        path: "campaign/:video_id/:create_campaign",
        element: <ChannelVideoCampaign />,
      },
      {
        path: "campaign/:video_id",
        element: <ChannelVideoCampaign />,
      },
      {
        path: "campaign/analytics/:campaign_key/:video_id",
        element: <VideoCampaignAnalytics />,
      },
    ],
  },
];
