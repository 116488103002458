import { Button, Col, Form, Row } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
export default function AddEnviroment({ formik }) {
  const dropdown = [
    {
      env_id: "1",
      value: "IN",
    },
    {
      env_id: "2",
      value: "GI",
    },
  ];
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;
  const addMoreEnvField = () => {
    setFieldValue("content_to_env_price", [
      ...values?.content_to_env_price,
      { env_id: "0", price: 0 },
    ]);
    // console.log(values);
  };

  const removeEnvField = (i) => {
    console.log(i);
    let newValues = values?.content_to_env_price?.filter((item, idx) => {
      return idx !== i;
    });
    setFieldValue("content_to_env_price", newValues);
  };

  return (
    <>
      <Button variant="primary" onClick={addMoreEnvField} className="mt-1">
        Add More ENV
      </Button>

      <Row className="my-4">
        {values?.content_to_env_price?.map((field, i) => (
          <div className="text-secondary border border-1 p-3 rounded-3 w-100 d-flex flex-row gap-2">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                className=""
                id="floatingInput1"
                name={`content_to_env_price.${i}.env_id`}
                value={field.env_id}
                onChange={handleChange}
                // isInvalid={
                //   touched.field.env_id && !!errors.field.env_id
                // }
              >
                <option value="" selected hidden>
                  {" "}
                  Dropdown
                </option>
                {dropdown.map((item) => (
                  <>
                    {/* {console.log("dropdown env id ",item.env_id)} */}
                    <option value={item.env_id}>{item.value}</option>
                  </>
                ))}
              </Form.Select>
              <Form.Label htmlFor="floatingInput">
                Enviroment <span className="text-danger">*</span>
              </Form.Label>
            </Form.Floating>

            <Form.Group className="form-group">
              <Form.Floating className="custom-form-floating">
                <Form.Control
                  type="number"
                  placeholder="Price"
                  name={`content_to_env_price.${i}.price`}
                  value={field.price}
                  onChange={handleChange}
                  isInvalid={touched.price && !!errors.price}
                />
                <Form.Label htmlFor="gr_video_title">Price</Form.Label>
              </Form.Floating>
            </Form.Group>

            {/* <Button className="px-3"
                        variant="danger"
                          onClick={()=>removeEnvField(i)}
                        >
                          Remove
                        </Button> */}
            <div className="border p-2 bg-danger h-1 rounded-3 cursor-pointer">
              <div
                className="px-3 p-2 text-light "
                id={`file_type_no_of_submissions_${i}`}
                onClick={() => removeEnvField(i)}
              >
                {findIcon("Trash", "dual-tone", 20)}
              </div>
            </div>
          </div>
        ))}
      </Row>
    </>
  );
}
