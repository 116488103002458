import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { findIcon } from "../../views/dashboard/icons";

function BoxPlotChart({series, title, graphColor, height = 300}) {
  let chartOptions = {
    series: [
      {
        type: "boxPlot",
        data: series ?? [],
      },
    ],
    options: {
      chart: {
        type: "boxPlot",
        height: 350,
        toolbar: {
          show: false // Disable the toolbar
        }
      },
      // title: {
      //   text: "Basic BoxPlot Chart",
      //   align: "left",
      // },
      plotOptions: {
        boxPlot: {
          colors: {
            upper: "#159a6c",
            lower: "#73c2a7",
          },
        },
      },
    },
  };

  return (
    <>
      <Card className="h-100 m-0">
      <Card.Header className="d-flex align-items-center justify-content-between">
          <h5 className="m-0">{title}</h5>
          {/* <div className="d-flex flex-row gap-3">
            <div className="text-success">
              {findIcon("DownloadReport", "dual-tone", "18")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">Download Report</span>
            </div>
            <div className="text-success">
              {findIcon("ExportReport", "dual-tone", "20")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">Export</span>
            </div>
          </div> */}
        </Card.Header>
        <Card.Body className="p-2">
          <div id="chart">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="boxPlot"
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default BoxPlotChart;
