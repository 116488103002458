import React, { memo, Fragment, Suspense, useEffect } from "react";

// router-dom
import { Outlet, useParams } from "react-router-dom";

// Header
import Headerpro from "../../../../../components/partials/pro/headerstyle/header-pro";

// Sidebar
import SponsoredSidebar from "../components/partials/sponsored-sidebar";

// Footer
import Footer from "../../../../../components/partials/dashboard/footerstyle/footer";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../../store/setting/selectors";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";

// Scss e-commerce
import "../../../../../assets/modules/e-commerce/scss/e-commerce.scss";
// import { setCurrentChannel } from "../../../../../../store/channel/channel.action";

const SponsoredLayout = memo(() => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  const params = useParams();
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     if (params.channel_id) {
  //       dispatch(setCurrentChannel({ channel_id: params.channel_id }));
  //     }
  //   }, []);

  return (
    <Fragment>
      <SponsoredSidebar />
      <main className="main-content">
        <div className="position-relative">
          <Headerpro />
        </div>
        <div
          className={`container-fluid content-inner pb-0 ${pageLayout}`}
          id="page_layout"
        >
          <Suspense fallback={<div className="react-load"></div>}>
            <Outlet />
          </Suspense>
        </div>

        <Footer />
      </main>
      {/* <SettingOffCanvas /> */}
    </Fragment>
  );
});

SponsoredLayout.displayName = "SponsoredLayout";
export default SponsoredLayout;
