import React, { useState, useEffect, useCallback } from "react";
import UserWhoDidFilter from "./user-who-did-filter";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { debounce } from "lodash";
import postService from "../../../../../api/services/post-service";
import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";

const clauseOptions = [
  {
    label: "Is",
    value: "is",
  },
];

const activeOptions = [
  {
    label: "Topic",
    value: "Topic",
  },
];
/**
 *   title="User Who did:"
     contentCategoryList={contentCategoryList}
     activityList={activityList}
     payload={segment_add_payload.current}
     onFromDataChange={onUserWhoDidChnage}
 * @param {*}  
 * @returns 
 */
export default function UserInterestedIn({
  title = "",
  onUserInterestSelected,
  isEditMode = false,
  editData = "",
}) {
  const [name, setName] = useState();
  const [clause, setClause] = useState();
  const [selectedOptions, setSelectedOptions] = useState("");
  const [selectedClause, setSelctedClause] = useState("");
  const [selectedValue, setSelctedValue] = useState("");

  const _loadSuggestions = (inputValue) => {
    return new Promise(async (resolve) => {
      const response = await postService("/segment/search", {
        filter: {
          content_tag_name: {
            type: "like",
            value: inputValue,
          },
        },
        pagination: {
          limit: 10,
          offset: 0,
        },
        sort: {
          content_tag_id: "asc",
        },
      });

      if (!response.isError) {
        const searchData = [];

        response?.data?.data?.rows.forEach((elem) => {
          searchData.push({
            value: elem.content_tag_id,
            label: elem.content_tag_name,
          });
        });
        resolve(searchData);
      } else {
        toast.error("Error fetching data!");
      }
    });
  };

  const filterOptions = (options = [], inputValue) => {
    console.group("filter");
    console.log(
      options.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
    console.groupEnd("filter");
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      _loadSuggestions(inputText)
        .then((options) => {
          console.log("fetched data", options);
          callback(filterOptions(options, inputText));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    console.log(isEditMode, ":user Interest:", editData);
    if (isEditMode && Object.keys(editData)?.length > 0) {
      const selecetdOption = activeOptions.filter(
        (option) => option.value == editData.field_value
      );
      const selecetdClause = clauseOptions.filter(
        (option) => option.value == editData.clause
      );

      console.log(":user Interest 1:", selecetdOption);
      setSelectedOptions(selecetdOption?.length > 0 ? selecetdOption[0] : "");
      setSelctedClause(selecetdClause?.length > 0 ? selecetdClause[0] : "");
      setSelctedValue({
        label: editData.content_tag_name,
        value: editData.value,
      });
    }
  }, [editData, isEditMode]);

  const onOptionChange = (options) => {
    console.log("onOptionChange", options);
    setSelectedOptions(options);
  };

  const onClauseChange = (options) => {
    console.log("onOptionChange2", options);
    setSelctedClause(options);
    // setValues({ clause: options.value });
  };
  const onValueChange = (options) => {
    console.log("onOptionChange3", options);
    setSelctedValue(options);
    // setValues({ value: options.value });
  };
  return (
    <>
      <div className="mb-4">
        {title && <h6 className="mb-3">{title}</h6>}

        <Row className="mt-n3">
          <Col lg={4} className="mt-4">
            {
              <Select
                value={activeOptions.filter(
                  (option) => option.value == selectedOptions.value
                )}
                onChange={onOptionChange}
                options={activeOptions}
              />
            }
          </Col>
          <Col lg={4} className="mt-4">
            <Select
              value={clauseOptions.filter(
                (option) => option.value == selectedClause.value
              )}
              onChange={onClauseChange}
              options={clauseOptions}
            />
          </Col>
          <Col lg={4} className="mt-4">
            {isEditMode ? (
              <AsyncSelect
                value={selectedValue}
                cacheOptions
                loadOptions={loadOptions}
                noOptionsMessage={() => "Type to search"}
                onChange={onUserInterestSelected}
              />
            ) : (
              <AsyncSelect
                // value={  }
                cacheOptions
                loadOptions={loadOptions}
                noOptionsMessage={() => "Type to search"}
                onChange={onUserInterestSelected}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
