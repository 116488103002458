import React, { memo, useState, useEffect } from "react";
import { Button, Card, Image } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

const campaignCardConfig = {
  whatsapp: {
    background: "",
    border_background: "",
    button_color: "",
  },
  facebook: {
    background: "",
    border_background: "",
    button_color: "",
  },
};
function CreateCampaignMetaPreview({
  meta_title,
  meta_description,
  meta_image,
  meta_link,
}) {
  const [selectedTab, setSelectedTab] = useState("first");
  const [metaImage, setMetaImage] = useState("");
  const handleSelect = (key) => {
    setSelectedTab(key);
  };

  useEffect(() => {
    setMetaImage(meta_image);
  }, [meta_image]);

  const PreviewCard = memo(
    ({
      platform = "",
      maxWidth = "",
      borderRadius = "",
      backgroundColor = "",
      buttonColor = "",
      previewImage,
      metaTitle,
      metaDescriptiion,
      metaLink,
      withLinkButton,
    }) => {
      return (
        <Card
          className="p-2 m-0"
          style={{
            backgroundColor: backgroundColor,
            maxWidth: maxWidth,
            borderRadius: borderRadius,
          }}
        >
          <Card.Body
            className={`p-0 overflow-hidden bg-black bg-opacity-10 ${
              platform == "x" ? "rounded-3" : ""
            }`}
          >
            <Image
              src={
                previewImage
                  ? typeof previewImage === "string"
                    ? previewImage
                    : URL.createObjectURL(previewImage)
                  : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
              }
              alt={metaTitle ?? "meta title"}
              className="w-100 h-rem-18 object-fit-fill"
            />
            <div className="d-flex flex-column p-2 ">
              {platform == "facebook" && platform == "whatsapp" ? (
                <Button
                  variant="link"
                  className="p-0 me-auto text-truncate text-white"
                >
                  {"doctube.com"}
                </Button>
              ) : (
                <></>
              )}
              {!withLinkButton && platform === "facebook" && (
                <Button
                  variant="link"
                  className="p-0 me-auto text-truncate text-white"
                >
                  {metaLink ? metaLink : "doctube.com"}
                </Button>
              )}
              <h5
                className={`mt-2 mb-2 pt-1 text-white ${
                  platform === "x" ? "one-line-clamp" : "two-line-clamp"
                }`}
              >
                {metaTitle ?? "Your meta title"}
              </h5>
              {metaDescriptiion &&
                !(platform === "facebook" && metaTitle?.length >= 61) && (
                  <Card.Text
                    className={`mb-2 text-light ${
                      platform === "x"
                        ? "two-line-clamp"
                        : platform === "facebook"
                        ? "one-line-clamp"
                        : platform === "instagram"
                        ? "textFourLine"
                        : "textDoubleLine"
                    }`}
                  >
                    {metaDescriptiion ?? "Your meta description"}
                  </Card.Text>
                )}

              {!withLinkButton && platform != "facebook" && (
                <Button
                  variant="link"
                  className="p-0 me-auto text-truncate text-white"
                >
                  {metaLink ? metaLink : "doctube.com"}
                </Button>
              )}
            </div>
          </Card.Body>
          {withLinkButton && (
            <div className="d-flex justify-content-between py-2">
              <Button
                variant="link"
                className="p-0 me-auto text-truncate"
                style={{ color: buttonColor }}
              >
                {meta_link ?? "Your Meta Link Goes Here"}
              </Button>
            </div>
          )}
        </Card>
      );
    }
  );
  return (
    <>
      <h5 className="fw-semibold mb-4">Create Video Campaign</h5>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="first"
        activeKey={selectedTab}
        onSelect={handleSelect}
      >
        <div
          className="rounded-4 p-md-4 p-3 mb-4 mb-md-0 bg-light position-relative d-flex justify-content-center align-items-center"
          style={{ border: "2px solid #005C4B" }}
        >
          <div className="position-relative createCampaignMetaMainBox d-flex flex-md-row flex-column-reverse w-100">
            <Nav className="flex-md-column jaustify-content-center flex-row flex-md-nowrap flex-wrap m-0 gap-md-2 gap-1 bg-transparent overflow-y-auto py-md-3 mt-3 mt-md-0 flex-shrink-0">
              <Nav.Item className="ms-auto">
                <Nav.Link
                  className="d-flex align-items-center justify-content-center justify-content-md-start position-relative transition"
                  eventKey="first"
                >
                  <span
                    style={{ backgroundColor: "#005C4B" }}
                    className="h-100 w-100 position-absolute top-0 start-0 transition createCampaignMeta__Nav__LinkActive"
                  ></span>
                  <svg
                    className="icon-35"
                    height="512"
                    viewBox="0 0 152 152"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_4494494"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="_08.whatsapp" data-name="08.whatsapp">
                        <circle
                          id="background"
                          cx="76"
                          cy="76"
                          fill="#2aa81a"
                          r="76"
                        ></circle>
                        <g id="icon" fill="#fff">
                          <path d="m102.81 49.19a37.7 37.7 0 0 0 -60.4 43.62l-4 19.42a1.42 1.42 0 0 0 .23 1.13 1.45 1.45 0 0 0 1.54.6l19-4.51a37.7 37.7 0 0 0 43.6-60.26zm-5.94 47.37a29.56 29.56 0 0 1 -34 5.57l-2.66-1.32-11.67 2.76v-.15l2.46-11.77-1.3-2.56a29.5 29.5 0 0 1 5.43-34.27 29.53 29.53 0 0 1 41.74 0l.13.18a29.52 29.52 0 0 1 -.15 41.58z"></path>
                          <path d="m95.84 88c-1.43 2.25-3.7 5-6.53 5.69-5 1.2-12.61 0-22.14-8.81l-.12-.11c-8.29-7.74-10.49-14.19-10-19.3.29-2.91 2.71-5.53 4.75-7.25a2.72 2.72 0 0 1 4.25 1l3.07 6.94a2.7 2.7 0 0 1 -.33 2.76l-1.56 2a2.65 2.65 0 0 0 -.21 2.95 29 29 0 0 0 5.27 5.86 31.17 31.17 0 0 0 7.3 5.23 2.65 2.65 0 0 0 2.89-.61l1.79-1.82a2.71 2.71 0 0 1 2.73-.76l7.3 2.09a2.74 2.74 0 0 1 1.54 4.14z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center justify-content-center justify-content-md-start position-relative transition"
                  eventKey="second"
                >
                  <span
                    style={{ backgroundColor: "#3a559f87" }}
                    className="h-100 w-100 position-absolute top-0 start-0 transition createCampaignMeta__Nav__LinkActive"
                  ></span>
                  <svg
                    className="icon-35"
                    height="512"
                    viewBox="0 0 176 176"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                    id="fi_3670032"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="_01.facebook" data-name="01.facebook">
                        <circle
                          id="background"
                          cx="88"
                          cy="88"
                          fill="#3a559f"
                          r="88"
                        ></circle>
                        <path
                          id="icon"
                          d="m115.88 77.58-1.77 15.33a2.87 2.87 0 0 1 -2.82 2.57h-16l-.08 45.45a2.05 2.05 0 0 1 -2 2.07h-16.21a2 2 0 0 1 -2-2.08v-45.44h-12a2.87 2.87 0 0 1 -2.84-2.9l-.06-15.33a2.88 2.88 0 0 1 2.84-2.92h12.06v-14.8c0-17.18 10.2-26.53 25.16-26.53h12.26a2.88 2.88 0 0 1 2.85 2.92v12.9a2.88 2.88 0 0 1 -2.85 2.92h-7.52c-8.13 0-9.71 4-9.71 9.78v12.81h17.87a2.88 2.88 0 0 1 2.82 3.25z"
                          fill="#fff"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center justify-content-center justify-content-md-start position-relative transition"
                  eventKey="third"
                >
                  <span
                    style={{ backgroundColor: "#5b6369" }}
                    className="h-100 w-100 position-absolute top-0 start-0 transition createCampaignMeta__Nav__LinkActive"
                  ></span>
                  <svg
                    id="icon-x"
                    className="icon-35"
                    enableBackground="new 0 0 1227 1227"
                    viewBox="0 0 1227 1227"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path d="m613.5 0c-338.815 0-613.5 274.685-613.5 613.5s274.685 613.5 613.5 613.5 613.5-274.685 613.5-613.5-274.685-613.5-613.5-613.5z"></path>
                      <path
                        d="m680.617 557.98 262.632-305.288h-62.235l-228.044 265.078-182.137-265.078h-210.074l275.427 400.844-275.427 320.142h62.239l240.82-279.931 192.35 279.931h210.074l-285.641-415.698zm-335.194-258.435h95.595l440.024 629.411h-95.595z"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  className="d-flex align-items-center justify-content-center justify-content-md-start position-relative transition"
                  eventKey="four"
                >
                  <span
                    style={{ backgroundColor: "#69add0" }}
                    className="h-100 w-100 position-absolute top-0 start-0 transition createCampaignMeta__Nav__LinkActive"
                  ></span>
                  <svg
                    version="1.1"
                    id="icon-35"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 112.196 112.196"
                  >
                    <g>
                      <circle
                        fill="#007AB9"
                        cx="56.098"
                        cy="56.097"
                        r="56.098"
                      ></circle>
                      <g>
                        <path
                          fill="#F1F2F2"
                          d="M89.616,60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118
            c-3.705,0-5.906,2.491-6.878,4.903c-0.353,0.862-0.444,2.059-0.444,3.268v22.524H48.684c0,0,0.18-36.546,0-40.329h13.411v5.715
            c-0.027,0.045-0.065,0.089-0.089,0.132h0.089v-0.132c1.782-2.742,4.96-6.662,12.085-6.662
            C83.002,42.462,89.616,48.226,89.616,60.611L89.616,60.611z M34.656,23.969c-4.587,0-7.588,3.011-7.588,6.967
            c0,3.872,2.914,6.97,7.412,6.97h0.087c4.677,0,7.585-3.098,7.585-6.97C42.063,26.98,39.244,23.969,34.656,23.969L34.656,23.969z
             M27.865,83.739H41.27V43.409H27.865V83.739z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="me-auto">
                <Nav.Link
                  className="d-flex align-items-center justify-content-center justify-content-md-start position-relative transition"
                  eventKey="five"
                >
                  <span
                    style={{ backgroundColor: "#7c1cc7" }}
                    className="h-100 w-100 position-absolute top-0 start-0 transition createCampaignMeta__Nav__LinkActive"
                  ></span>
                  <svg
                    id="icon-insta"
                    className="icon-35"
                    height="512"
                    viewBox="0 0 152 152"
                    width="512"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <linearGradient
                      id="linear-gradient"
                      gradientUnits="userSpaceOnUse"
                      x1="22.26"
                      x2="129.74"
                      y1="22.26"
                      y2="129.74"
                    >
                      <stop offset="0" stop-color="#fae100"></stop>
                      <stop offset=".15" stop-color="#fcb720"></stop>
                      <stop offset=".3" stopColor="#ff7950"></stop>
                      <stop offset=".5" stopColor="#ff1c74"></stop>
                      <stop offset="1" stopColor="#6c1cd1"></stop>
                    </linearGradient>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Circle">
                        <g id="_03.Instagram" data-name="03.Instagram">
                          <rect
                            id="Background"
                            fill="url(#linear-gradient)"
                            height="152"
                            rx="76"
                            width="152"
                          ></rect>
                          <g fill="#fff">
                            <path
                              id="Shade"
                              d="m133.2 26c-11.08 20.34-26.75 41.32-46.33 60.9s-40.56 35.22-60.87 46.3q-1.91-1.66-3.71-3.46a76 76 0 1 1 107.45-107.48q1.8 1.8 3.46 3.74z"
                              opacity=".1"
                            ></path>
                            <g id="Icon">
                              <path d="m94 36h-36a22 22 0 0 0 -22 22v36a22 22 0 0 0 22 22h36a22 22 0 0 0 22-22v-36a22 22 0 0 0 -22-22zm15 54.84a18.16 18.16 0 0 1 -18.16 18.16h-29.68a18.16 18.16 0 0 1 -18.16-18.16v-29.68a18.16 18.16 0 0 1 18.16-18.16h29.68a18.16 18.16 0 0 1 18.16 18.16z"></path>
                              <path d="m90.59 61.56-.19-.19-.16-.16a20.16 20.16 0 0 0 -14.24-5.88 20.52 20.52 0 0 0 -20.38 20.67 20.75 20.75 0 0 0 6 14.61 20.19 20.19 0 0 0 14.42 6 20.73 20.73 0 0 0 14.55-35.05zm-14.59 28a13.56 13.56 0 1 1 13.37-13.56 13.46 13.46 0 0 1 -13.37 13.56z"></path>
                              <path d="m102.43 54.38a4.88 4.88 0 0 1 -4.85 4.92 4.81 4.81 0 0 1 -3.42-1.43 4.93 4.93 0 0 1 3.43-8.39 4.82 4.82 0 0 1 3.09 1.12l.1.1a3.05 3.05 0 0 1 .44.44l.11.12a4.92 4.92 0 0 1 1.1 3.12z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content className="flex-grow-1" onSele>
              <Tab.Pane eventKey="first">
                <PreviewCard
                  platform="whatsapp"
                  withLinkButton={true}
                  backgroundColor="#005C4B"
                  buttonColor="#3AB6D5"
                  previewImage={
                    metaImage
                      ? typeof metaImage === "string"
                        ? metaImage
                        : URL.createObjectURL(metaImage)
                      : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
                  }
                  metaTitle={meta_title}
                  metaDescriptiion={meta_description}
                  metaLink={meta_link}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <PreviewCard
                  platform="facebook"
                  withLinkButton={false}
                  maxWidth="500px"
                  backgroundColor="#3a559f"
                  borderRadius="0px"
                  buttonColor="#3AB6D5"
                  previewImage={
                    metaImage
                      ? typeof metaImage === "string"
                        ? metaImage
                        : URL.createObjectURL(metaImage)
                      : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
                  }
                  metaTitle={meta_title}
                  metaDescriptiion={meta_description}
                  metaLink={meta_link}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <PreviewCard
                  platform="x"
                  backgroundColor="#d3d5d6"
                  buttonColor="#5b6369"
                  previewImage={
                    metaImage
                      ? typeof metaImage === "string"
                        ? metaImage
                        : URL.createObjectURL(metaImage)
                      : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
                  }
                  metaTitle={meta_title}
                  metaDescriptiion={meta_description}
                  metaLink={meta_link}
                />
              </Tab.Pane>
              {/* linkdn */}
              <Tab.Pane eventKey="four">
                <PreviewCard
                  backgroundColor="#007ab9"
                  buttonColor="#6c757d"
                  borderRadius="0px"
                  previewImage={
                    metaImage
                      ? typeof metaImage === "string"
                        ? metaImage
                        : URL.createObjectURL(metaImage)
                      : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
                  }
                  metaTitle={meta_title}
                  // metaDescriptiion={meta_description}
                  metaLink={meta_link}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="five">
                <PreviewCard
                  platform="instagram"
                  withLinkButton={true}
                  backgroundColor="#c31c9a"
                  buttonColor="#ffffff"
                  previewImage={
                    metaImage
                      ? typeof metaImage === "string"
                        ? metaImage
                        : URL.createObjectURL(metaImage)
                      : "https://images.pexels.com/photos/268941/pexels-photo-268941.jpeg"
                  }
                  metaTitle={meta_title}
                  metaDescriptiion={meta_description}
                  metaLink={meta_link}
                />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
}

export default memo(CreateCampaignMetaPreview);
