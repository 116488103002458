import React, { useState, useEffect, memo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
const initialValues = {
  name: "",
  clause: "",
  value: "",
  operator: "and",
};

const options = [
  {
    label: "Select an Option",
    value: "",
  },
  {
    label: "3 Days",
    value: 3,
  },
  {
    label: "7 Days",
    value: 7,
  },
  {
    label: "15 Days",
    value: 15,
  },
  {
    label: "30 Days",
    value: 30,
  },
];

const clauseOptions = [
  {
    label: "Between",
    value: "between",
  },
  {
    label: "Not Between",
    value: "not_between",
  },
];

const activeOptions = [
  {
    label: "User Last Active",
    value: "activity_date_time",
  },
  // {
  //   label: "User Not Active",
  //   value: "not_active",
  // },
];

function UserWhoActiveForm({
  onFormDataChange,
  isEditMode = false,
  editData = "",
}) {
  const [selectedOptions, setSelectedOptions] = useState("");
  const [selectedClause, setSelctedClause] = useState("");
  const [selectedValue, setSelctedValue] = useState("");

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    clause: Yup.string(),
    value: Yup.string(),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    setFieldValue,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    if (values.name && values.clause && values.value) {
      onFormDataChange(values);
    }
  }, [values]);

  useEffect(() => {
    if (isEditMode && Object.keys(editData)?.length > 0) {
      const selecetdOption = activeOptions.filter(
        (option) => option.value == editData.name
      );
      const selecetdClause = clauseOptions.filter(
        (option) => option.value == editData.clause
      );
      const selecetdValue = options.filter(
        (option) => option.value == editData.value
      );
      setSelectedOptions(selecetdOption?.length > 0 ? selecetdOption[0] : "");
      setSelctedClause(selecetdClause?.length > 0 ? selecetdClause[0] : "");
      setSelctedValue(selecetdValue?.length > 0 ? selecetdValue[0] : "");
    }
  }, [editData, isEditMode]);

  const onOptionChange = (options) => {
    setSelectedOptions(options);
    setValues({ ...values, name: options.value });
  };

  const onClauseChange = (options) => {
    console.log("onOptionChange2", options);
    setSelctedClause(options);
    setValues({ ...values, clause: options.value });
  };
  const onValueChange = (options) => {
    console.log("onOptionChange3", options);
    setSelctedValue(options);
    setValues({ ...values, value: options.value });
  };
  return (
    <div className="mb-4">
      <h6 className="mb-3">User Who Are Active In:</h6>
      <Row className="mt-n4">
        <Col lg={4} className="mt-4">
          <Select
            value={activeOptions.filter(
              (option) => option.value == selectedOptions.value
            )}
            onChange={onOptionChange}
            options={activeOptions}
          />
        </Col>
        <Col lg={4} className="mt-4">
          <Select
            value={clauseOptions.filter(
              (option) => option.value == selectedClause.value
            )}
            onChange={onClauseChange}
            options={clauseOptions}
          />
        </Col>
        <Col lg={4} className="mt-4">
          <Select
            value={options.filter(
              (option) => option.value == selectedValue.value
            )}
            onChange={onValueChange}
            options={options}
          />
        </Col>
      </Row>
    </div>
  );
}

export default memo(UserWhoActiveForm);
