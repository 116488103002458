import React, { useEffect } from "react";
import { Col, Row, Dropdown } from "react-bootstrap";
// import Card from "../../../../components/bootstrap/card";
import CountUp from "react-countup";
import { Card } from "react-bootstrap";

import CustomToggle from "../../../../components/dropdowns";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import Chart from "react-apexcharts";
import Plotly from "plotly.js-dist";
import { useGetHcpDataCount } from "../../../../queries/query-hooks/hcp-profile-hook";
import HcpCountCard from "../components/dashboard/HcpCountCard";
import HcpCountCardSkeleton from "../../../../skeleton/hcp/hcp-count-card-skeleton";
import OnboardingByRegistrationGraph from "../components/dashboard/OnboardingByRegistrationGraph";
import ReOnboardingByRegistrationGraph from "../components/dashboard/ReOnboardingByRegistrationGraph";
import OnboardingEfficiencyGraph from "../components/dashboard/OnboardingEfficiencyGraph";
import UserDataAnalyticsOnRegisterDate from "../components/dashboard/UserDataAnalyticsOnRegisterDate";
import UserDataAnalyticsOnboardedDate from "../components/dashboard/UserDataAnalyticsOnboardedDate";
import IndiaToInternationalAnalytics from "../components/dashboard/IndiaToInternationalAnalytics";
import TopInternationalCountriesAnalytics from "../components/dashboard/TopInternationalCountriesAnalytics";

const plotlyConfig = {
  displayModeBar: false,

};

var GRAPH_LAYOUT = {
  displayModeBar: false,
}; // { width: 600, height: 500 };

const HcpDashboard = () => {
  const { redirectTo } = useRedirect();
  // hcp dashboard api queries hook start

  /**
   * hcp data count dashboard 
   */
  const { data: hcpDataCount, isLoading: isHcpDataCountLoading } = useGetHcpDataCount();

  /**
   * Re-Onboarding By Registration query 
   */

  const onAssignedClick = () => {
    redirectTo("hcp-profile/all/assigned");
  };
  const onPendingClick = () => {
    redirectTo("hcp-profile/all/pending");
  };
  const onApprovedClick = () => {
    redirectTo("hcp-profile/all/approved");
  };
  const onReviewClick = () => {
    redirectTo("hcp-profile/all/review");
  };
  const onOverDueClick = () => {
    redirectTo("hcp-profile/all/overdue");
  };





  return (
    <>
      <div className="d-flex mb-4">
        <h3>HCP Dashboard</h3>
      </div>
      <Col lg="12">
        <Card>
          <Card.Body>
            <Row>
              {!isHcpDataCountLoading && hcpDataCount?.length > 0 ? <Col>
                <HcpCountCard onClick={onAssignedClick} variant="bg-soft-danger" title={"Assigned Hcps"} totalCount={hcpDataCount[0].assigned_hcp} />
              </Col> : <Col><HcpCountCardSkeleton /></Col>}
              {!isHcpDataCountLoading && hcpDataCount?.length > 0 ? <Col>
                <HcpCountCard onClick={onPendingClick} variant="bg-soft-warning" title={"Pending Hcps"} totalCount={hcpDataCount[0].pending_hcp} />
              </Col> : <Col><HcpCountCardSkeleton /></Col>}
              {!isHcpDataCountLoading && hcpDataCount?.length > 0 ? <Col>
                <HcpCountCard onClick={onApprovedClick} variant="bg-soft-success" title={"Approved Hcps"} totalCount={hcpDataCount[0].approved_hcp} />
              </Col> : <Col><HcpCountCardSkeleton /></Col>}
              {!isHcpDataCountLoading && hcpDataCount?.length > 0 ? <Col>
                <HcpCountCard onClick={onReviewClick} variant="bg-soft-primary" title={"In-Review Hcps"} totalCount={hcpDataCount[0].in_review_hcp} />
              </Col> : <Col><HcpCountCardSkeleton /></Col>}
            </Row>
            <Row>
              {!isHcpDataCountLoading && hcpDataCount?.length > 0 ? <Col>
                <HcpCountCard onClick={onOverDueClick} variant="bg-soft-primary" title={"Over-due HCPs"} totalCount={hcpDataCount[0].over_due_hcp} />
              </Col> : <Col><HcpCountCardSkeleton /></Col>}
              {!isHcpDataCountLoading && hcpDataCount?.length > 0 ? <Col>
                <HcpCountCard onClick={onOverDueClick} variant="bg-soft-primary" title={"Total Users"} totalCount={hcpDataCount[0].total_hcp} />
              </Col> : <Col><HcpCountCardSkeleton /></Col>}
            </Row>
          </Card.Body>
        </Card>
      </Col>

      <Col lg="12">
        <Row>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <OnboardingByRegistrationGraph />
          </Col>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <ReOnboardingByRegistrationGraph />
          </Col>
          <Col lg="12" className="d-flex p-0 px-md-3">
            <OnboardingEfficiencyGraph />
          </Col>
        </Row>
      </Col>
      <Col lg="12">
        <Row>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <UserDataAnalyticsOnRegisterDate />
          </Col>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <UserDataAnalyticsOnboardedDate />
          </Col>
        </Row>
      </Col>
      <Col lg="12">
        <Row>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <IndiaToInternationalAnalytics />
          </Col>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <TopInternationalCountriesAnalytics />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default HcpDashboard;
