import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../components/dropdowns";
import { usePostOnboardingCallAnalytics } from "../../../../queries/query-hooks/onboarding-hook";

import dateFilterOptions from "../Constant";
import OnboardingCardSkeleton from "../../../../skeleton/onboarding/OnboardingCardSkeleton";

import Chart from "react-apexcharts";

import DashboardDropdown from "../../../../components/DashboardDropdown";

const CallAnalytics = () => {
  const [payload, setPayload] = useState({
    employee_id: "",
    type: dateFilterOptions.initial,
    next: "",
    prev: "",
    startdate: "",
    enddate: "",
  });

  const handleDropdownChange = (item) => {
    setPayload({
      ...payload,
      type: item,
    });
  };

  const { data: onboardingCallAnalytics, isLoading: isonboardingCallLoading } =
    usePostOnboardingCallAnalytics(payload);

  console.log("callAnalytics", onboardingCallAnalytics);

  // 1 options.optionsMixedChart
  // 2 options.seriesMixedChart

  var options = {
    optionsMixedChart: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: onboardingCallAnalytics?.data?.labels,
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 100,
      },
    },
    seriesMixedChart: [
      {
        name: "Inprogress",
        type: "line",
        data: onboardingCallAnalytics?.data?.inprogress_call,
      },
      {
        name: "Approved",
        type: "column",
        data: onboardingCallAnalytics?.data?.approve_call,
      },
      {
        name: "Disapproved",
        type: "column",
        data: onboardingCallAnalytics?.data?.disapprove_call,
      },
      {
        name: "Total Calls",
        type: "column",
        data: onboardingCallAnalytics?.data?.call,
      },
    ],
    // optionsRadial: {
    //   plotOptions: {
    //     radialBar: {
    //       startAngle: -135,
    //       endAngle: 225,
    //       hollow: {
    //         margin: 0,
    //         size: "70%",
    //         background: "#fff",
    //         image: undefined,
    //         imageOffsetX: 0,
    //         imageOffsetY: 0,
    //         position: "front",
    //         dropShadow: {
    //           enabled: true,
    //           top: 3,
    //           left: 0,
    //           blur: 4,
    //           opacity: 0.24,
    //         },
    //       },
    //       track: {
    //         background: "#fff",
    //         strokeWidth: "67%",
    //         margin: 0, // margin is in pixels
    //         dropShadow: {
    //           enabled: true,
    //           top: -3,
    //           left: 0,
    //           blur: 4,
    //           opacity: 0.35,
    //         },
    //       },

    //       dataLabels: {
    //         showOn: "always",
    //         name: {
    //           offsetY: -20,
    //           show: true,
    //           color: "#888",
    //           fontSize: "13px",
    //         },
    //         value: {
    //           formatter: function (val) {
    //             return val;
    //           },
    //           color: "#111",
    //           fontSize: "30px",
    //           show: true,
    //         },
    //       },
    //     },
    //   },
    //   fill: {
    //     type: "gradient",
    //     gradient: {
    //       shade: "dark",
    //       type: "horizontal",
    //       shadeIntensity: 0.5,
    //       gradientToColors: ["#ABE5A1"],
    //       inverseColors: true,
    //       opacityFrom: 1,
    //       opacityTo: 1,
    //       stops: [0, 100],
    //     },
    //   },
    //   stroke: {
    //     lineCap: "round",
    //   },
    //   labels: ["Percent"],
    // },
    // seriesRadial: [76],
    // optionsBar: {
    //   chart: {
    //     stacked: true,
    //     stackType: "100%",
    //     toolbar: {
    //       show: false,
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //     },
    //   },
    //   dataLabels: {
    //     dropShadow: {
    //       enabled: true,
    //     },
    //   },
    //   stroke: {
    //     width: 0,
    //   },
    //   xaxis: {
    //     categories: ["Fav Color"],
    //     labels: {
    //       show: false,
    //     },
    //     axisBorder: {
    //       show: false,
    //     },
    //     axisTicks: {
    //       show: false,
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //     type: "gradient",
    //     gradient: {
    //       shade: "dark",
    //       type: "vertical",
    //       shadeIntensity: 0.35,
    //       gradientToColors: undefined,
    //       inverseColors: false,
    //       opacityFrom: 0.85,
    //       opacityTo: 0.85,
    //       stops: [90, 0, 100],
    //     },
    //   },

    //   legend: {
    //     position: "bottom",
    //     horizontalAlign: "right",
    //   },
    // },
    // seriesBar: [
    //   {
    //     name: "blue",
    //     data: [32],
    //   },
    //   {
    //     name: "green",
    //     data: [41],
    //   },
    //   {
    //     name: "yellow",
    //     data: [12],
    //   },
    //   {
    //     name: "red",
    //     data: [65],
    //   },
    // ],
  };

  if (isonboardingCallLoading) {
    return <OnboardingCardSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <h4 className="mb-0">Call Analytics</h4>
          <p className="mb-0 fs-7 text-capitalize mt-2">{payload.type}</p>
        </Card.Title>

        <DashboardDropdown
          handleDropdownChange={handleDropdownChange}
          type={payload.type}
          constantFilterOption={dateFilterOptions.options}
        />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Chart
          options={options?.optionsMixedChart}
          series={options?.seriesMixedChart}
          type="area"
          // height="400"
        />
      </Card.Body>
    </Card>
  );
};

export default CallAnalytics;
