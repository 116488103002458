import React, { useState } from "react";
import { findIcon } from "../../views/dashboard/icons";
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  Image,
} from "react-bootstrap";
import { Form } from "react-router-dom";
import Select from "react-select";
import CustomToggle from "../dropdowns";

const TemplateFilter = ({
  dropdownList = [],
  onRefreshClick,
  getFilterActivateStatus,
  getFilterData,
  filterData,
  isResetEnable,
  resetDataHandler,
}) => {
  const [selectedItem, setSelectedItem] = useState([]);
  const [sortData, setSortData] = useState(dropdownList);
  const getSelectedItems = (e, id) => {
    const data = sortData.map((i) => {
      if (i.id === id) {
        if (e.target.checked) {
          i.check = true;
          // return { ...i, check: true };
        } else {
          i.check = false;
          // return { ...i, check: false };
        }
      }
      return i;
    });
    setSortData(data);
    console.log("dropdown changed", data);
    let checkedData = sortData
      .filter((i) => i.check === true)
      .map((_i) => _i.id);
    setSelectedItem(checkedData);
    getFilterData({
      target: {
        name: "SortData",
        value: checkedData,
      },
    });
  };
  console.log("dropdown", sortData);
  const resetClicked = () => {
    resetDataHandler(true);
    setSortData(
      sortData.map((i) => {
        i.check = false;
        return i;
      })
    );
    setSelectedItem([]);
  };
  console.log("sortData", dropdownList);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-0 px-md-2 ms-auto gap-2">
        <Button
          type="button"
          // variant="outline-info"
          variant={`${
            Object.keys(filterData).length <= 0 ? "info" : "outline-info"
          }`}
          className="d-flex justify-content-center align-items-center gap-2 position-relative"
          onClick={getFilterActivateStatus}
        >
          <span
            className={`notification-alert ${
              // !isResetEnable || Object.keys(filterData).length > 0
              Object.keys(filterData).length <= 0 ? "d-none" : ""
            }`}
          ></span>
          {findIcon("Filter", "dual-tone", "18")}
          Filter
        </Button>{" "}
        <Dropdown>
          <Dropdown.Toggle
            as={CustomToggle}
            //  variant="btn btn-outline-info"
            variant={`btn btn-${
              selectedItem.length <= 0 ? "info" : "outline-info"
            }`}
          >
            <span
              className={`notification-alert ${
                // !isResetEnable || selectedItem.length > 0 ? "d-none" : ""
                selectedItem.length <= 0 ? "d-none" : ""
              }`}
            ></span>
            {findIcon("Adjustment", "dual-tone", "18")}
          </Dropdown.Toggle>

          <Dropdown.Menu variant="end p-0 sub-drop">
            <Card className="m-0 shadow-none ">
              <Card.Body className="p-3 max-17 scroll-thin">
                {sortData &&
                  sortData.length > 0 &&
                  sortData?.map((_li) => (
                    <div className="pb-2 d-flex">
                      {/* <Form.Check className=""> */}
                      <FormCheck.Input
                        className="me-3"
                        type="checkbox"
                        name={_li.value}
                        checked={_li.check}
                        value={_li.value}
                        onClick={(e) => getSelectedItems(e, _li.id)}
                        // onChange={getSortData}
                      />
                      <FormCheck.Label className="">
                        {_li.value}
                      </FormCheck.Label>
                      {/* </Form.Check> */}
                    </div>
                  ))}
              </Card.Body>
            </Card>
          </Dropdown.Menu>
        </Dropdown>
        <Button
          // onClick={refreshClick}
          variant={`outline-${isResetEnable ? "primary" : "secondary"}`}
          disabled={!isResetEnable}
          onClick={resetClicked}
        >
          {findIcon("Refresh", "dual-tone", "18")}
        </Button>
      </div>
    </>
  );
};

export default TemplateFilter;
