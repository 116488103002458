import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

function DonutChart({
  title,
  series,
  labels,
  graphColor,
  height = 350,
  grayBackground = false,
}) {
  const chartOptions = {
    series: series || [], // Represents Android and iOS respectively
    options: {
      colors: graphColor || [],
      chart: {
        type: "donut",
      },
      legend: {
        position: "bottom",
      },
      labels: labels || [], // Data labels for Android and iOS
      plotOptions: {
        pie: {
          donut: {
            size: "80%", // Adjust the size percentage to control the outer size of the donut
            width: "50%", // Adjust the width to control the thickness of the donut
          },
        },
      },
      dataLabels: {
        enabled: false, // Disable the percentage labels
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
    },
  };
  return (
    <>
      <Card
        className={`h-100 ${
          grayBackground ? "bg-light bg-opacity-25 shadow-none" : ""
        }`}
      >
        <Card.Header className=" align-items-center justify-content-between">
          <h5
            className={`${
              grayBackground ? "text-start fs-6" : "mb-4 subHeading text-center"
            } position-relative`}
          >
            {title}
          </h5>
        </Card.Header>
        <Card.Body className="p-2">
          <div
            id="chart"
            className="d-flex align-items-center justify-content-center"
          >
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="donut"
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default DonutChart;
