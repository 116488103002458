import React, { useState } from "react";
import { Button, Card, Col, Form, FormCheck, Row } from "react-bootstrap";
import FilePicker from "../../../../components/form-components/file-picker";
import Select from "react-select";
import BasicQuill from "../../../../plugins/quill-editor";
import ContentDetails from "../../../../components/partials/common/content-details";
// import Multiselect from "multiselect-react-dropdown";
import { useFormik } from "formik";
import {
  addAudioInitialValues,
  addAudioSchema,
  addImageInitialValues,
  addImageSchema,
  addVideoInitialValues,
  addVideoSchema,
} from "../constants/add-media";
import uploadService from "../../../../api/services/upload-service";
import {
  ADD_AV,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import postService from "../../../../api/services/post-service";
import toast from "react-hot-toast";
import cmsPostService from "../../../../api/services/cms-post-service";
import cmsUploadService from "../../../../api/services/cms-upload-service";

const AddMedia = ({
  type = "",
  formFields,
  content_category_id,
  accepts,
  content_file_type_id,
}) => {
  function getInitialValues() {
    if (type === "video") return addVideoInitialValues;
    else if (type === "image") return addImageInitialValues;
    else if (type === "audio") return addAudioInitialValues;
    else return addVideoInitialValues;
  }

  function getSchema() {
    if (type === "video") return addVideoSchema;
    else if (type === "image") return addImageSchema;
    else if (type === "audio") return addAudioSchema;
    else return addVideoSchema;
  }

  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getSchema(),
    onSubmit: (value, action) => {
      const payload = {
        content_category_id,
        content_brand_id: value.brand,
        content_title: value.title,
        content_description: value.description,
        content_file_type_id,
        file_type_code: type,
        title_html: value.title_html,
        description_html: value.description_html,
        path: value.file.name,
        cleaned_text: "",
        reference_file: value.referenceFile.name,
        thumbnail: value.thumbnail.name,
        base: value.coverImage.name,
        main: value.file.name,
      };

      cmsPostService(ADD_AV, payload).then((data) => {
        if (!data.isError) {
          if (data.data?.success) {
            toast.success(data.data.message);
            resetForm();
          } else {
            toast.error("Error Adding content!");
          }
        }
      });
    },
  });

  function setFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      cmsUploadService(UPLOAD_FILE, formdata)
        .then((data) => {
          if (data !== null) setFieldValue(keyName, data.data.data[0]);
          else toast.error("File upload Failed");
        })
        .catch((err) => console.log(err));
    } else {
      setFieldValue(keyName, null);
    }
  }
  console.log("values", values);
  console.log("errors", errors);
  return (
    <>
      <Col lg="12" className="d-flex p-0 p-md-3">
        <Card>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="6" lg="8">
                <Card.Body className="p-3 p-md-4">
                  <div className="row">
                    <Col lg="12" className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          name="brand"
                          value={values.brand}
                          onChange={handleChange}
                          isInvalid={touched.brand && !!errors.brand}
                        >
                          <option value="" selected hidden>
                            {" "}
                            Select Brand
                          </option>
                          {formFields &&
                            formFields?.brands &&
                            formFields?.brands.map((item) => (
                              <option value={item?.principal_entity_id}>
                                {item?.principal_entity_name}
                              </option>
                            ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Brand <span className="text-danger">*</span>
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Form.Group className="col-12 form-group">
                      <Form.Label className="d-flex justify-content-between">
                        <div>
                          Title <span className="text-danger">*</span>{" "}
                        </div>
                      </Form.Label>
                      <div>
                        <BasicQuill
                          value={values.title}
                          updateData={(value) => {
                            setFieldValue("title", value.rawText);
                            setFieldValue("title_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.title && "title" in errors && (
                          <div style={{ color: "red" }}>{errors.title}</div>
                        )}
                      </div>
                      {/* )} */}
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        Description <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <div>
                        <BasicQuill
                          value={values.description}
                          updateData={(value) => {
                            setFieldValue("description", value.rawText);
                            setFieldValue("description_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.description && "description" in errors && (
                          <div style={{ color: "red" }}>
                            {errors.description}
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        References <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <div>
                        <BasicQuill
                          value={values.references}
                          updateData={(value) => {
                            setFieldValue("references", value.rawText);
                            setFieldValue("references_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.references && "references" in errors && (
                          <div style={{ color: "red" }}>
                            {errors.references}
                          </div>
                        )}
                      </div>
                      {/* )} */}
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>Add Reference Files</Form.Label>
                      <Form.Control
                        type="file"
                        id="customFile"
                        name="referenceFile"
                        isInvalid={!!errors.referenceFile}
                        onChange={(e) =>
                          setFile(e.target.files, "referenceFile")
                        }
                      />
                    </Form.Group>
                  </div>
                </Card.Body>
              </Col>
              <Col xl="6" lg="4" className="">
                <Row className="px-3 pt-3">
                  <Col lg="12" className="mb-4">
                    <FilePicker
                      title={
                        values.file !== null
                          ? values.file?.name
                          : type == "video"
                          ? "Add Video"
                          : type == "image"
                          ? "Add Image"
                          : type == "audio"
                          ? "Add Audio"
                          : ""
                      }
                      onUpdate={(val) => setFile(val, "file")}
                      accepts={accepts}
                      type={type}
                      source={
                        type === "image" ? values?.file?.url : values?.file
                      }
                    />
                  </Col>
                  {touched.file && "file" in errors && (
                    <div style={{ color: "red" }}>{errors.file}</div>
                  )}
                  <Col lg="6" className="mb-4">
                    <FilePicker
                      title={"Add Cover Image"}
                      accepts="image/png, image/gif, image/jpeg"
                      onUpdate={(val) => setFile(val, "coverImage")}
                      source={values?.coverImage?.url}
                      type="image"
                    />
                    {touched.coverImage && "coverImage" in errors && (
                      <div style={{ color: "red" }}>{errors.coverImage}</div>
                    )}
                  </Col>
                  <Col lg="6" className="mb-4">
                    <FilePicker
                      title={"Add Thumbnail"}
                      onUpdate={(val) => setFile(val, "thumbnail")}
                      accepts="image/png, image/gif, image/jpeg"
                      source={values?.thumbnail?.url}
                      type="image"
                    />
                    {touched.thumbnail && "thumbnail" in errors && (
                      <div style={{ color: "red" }}>{errors.thumbnail}</div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex gap-2 gap-md-3 justify-content-end flex-wrap flex-md-nowrap my-3 mx-3">
              <Button
                type="submit"
                variant="btn btn-primary flex-md-grow-0 flex-grow-1 text-nowrap"
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="btn btn-light flex-md-grow-0 flex-grow-1 text-nowrap"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </>
  );
};

export default AddMedia;
