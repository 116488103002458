import React, { useState, useContext, memo, Fragment } from "react";

// Router
import { Link, useLocation, useParams } from "react-router-dom";

// React-bootstrap
import {
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

// component
import SidebarMenu from "../../../../../../../components/partials/components/sidebar/sidebar-menu";

import { findIcon } from "../../../../../../dashboard/icons";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const Ecommercevarticalnav = memo(() => {
  const [activeMenu, setActiveMenu] = useState(false);
  // const [active, setActive] = useState('')
  let location = useLocation();
  let { pathname } = location;
  const { channel_id } = useParams();
  const isActive = (path) => pathname.includes(path);
  return (
    <Fragment>
      <ul className="sub-nav">
        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={"/channel/dashboard/" + channel_id}
          title="Dashboard"
          minititle={findIcon("Dashboard", "dual-tone", 20)}
        >

          <i className="icon">
            {findIcon("Dashboard", "dual-tone", 20)}
          </i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={"/channel/videos/" + channel_id}
          title="Videos"
          minititle={findIcon("Video", "dual-tone", 20)}
        >

          <i className="icon">

            {findIcon("Video", "dual-tone", 20)}
          </i>
        </SidebarMenu>

        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/analytics/" + channel_id}
          title="Analytics"
          minititle={findIcon("Analytics", "dual-tone", 20)}
        >

          <i className="icon">

            {findIcon("Analytics", "dual-tone", 20)}
          </i>

        </SidebarMenu>

        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/comments/" + channel_id}
          title="Comments"
          minititle={findIcon("Comment-notification", "dual-tone", 20)}
        >
          <i className="icon">

            {findIcon("Comment-notification", "dual-tone", 20)}
          </i>
        </SidebarMenu>

        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/campaign/" + channel_id}
          title="Campaign"
          minititle={findIcon("Campaign", "dual-tone", 20)}
        >

          <i className="icon">

            {findIcon("Campaign", "dual-tone", 20)}
          </i>
        </SidebarMenu>
        <SidebarMenu
          isTag="true"
          staticIcon="false"
          pathname={"/channel/customization/" + channel_id}
          title="Customization"
          minititle={findIcon("Tools", "dual-tone", 20)}
        >

          <i className="icon">
            {findIcon("Tools", "dual-tone", 20)}
          </i>
        </SidebarMenu>
      </ul>
    </Fragment>
  );
});
Ecommercevarticalnav.displayName = "Ecommercevarticalnav";
export default Ecommercevarticalnav;
