import React, { Fragment, memo } from "react";
import avatar from "../../../../../../../assets/images/img1.jpeg";
import { Image } from "react-bootstrap";

function VideoThumbnailCard({ image = "", isChecked = false }) {
  return (
    <div
      className={`border border-2 p-2 rounded-3 w-rem-10 transition ratio-9x16 ${
        isChecked ? "border-success" : "border-light"
      }`}
    >
      <Image
        className="rounded-3 w-100 h-100 object-fit-cover"
        src={typeof image === "string" ? image : URL.createObjectURL(image)}
        // alt={`Thumbnail}
      />
      <input
        type="radio"
        checked={isChecked}
        className="position-absolute top-0 start-0 w-100 h-100 opacity-0"
      />
    </div>
  );
}

export default memo(VideoThumbnailCard);
