import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { GRAPH_FILL_COLOR } from "../../views/workspace/doctube/pages/channel/Constant/graph-color-scheme";
import { findIcon } from "../../views/dashboard/icons";

function BasicColumnChart({ title, series, labels }) {
  const chartRef = useRef(null);

  async function downloadPNG() {
    const chartInstance = chartRef.current;

    console.log("chartInstance ", chartInstance);
    const base64 = await chartInstance.chart.dataURI();
    console.log("base 64", base64.imgURI);
    const downloadLink = document.createElement("a");
    downloadLink.href = base64.imgURI;
    downloadLink.download = "image.png";

    // Add the anchor element to the document
    document.body.appendChild(downloadLink);

    // Simulate a click event to initiate the download
    downloadLink.click();

    // Remove the anchor element from the document
    document.body.removeChild(downloadLink);
  }

  function exportCSV() {
    let csvContent = "Date," + series.map((s) => s.name).join(",") + "\n";

    labels.forEach((category, index) => {
      let row = [category];

      series.forEach((series) => {
        row.push(series.data[index]);
      });
      csvContent += row.join(",") + "\n";
    });

    // Create a blob and download the CSV file
    var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "chart_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function downloadChart() {
    // Find the ApexCharts instance by ID
    const chartInstance = chartRef.current; //window.Apex._chartInstances.find(chart => chart.id === chartId);

    if (chartInstance) {
      // Download chart as PNG
      downloadPNG(chartInstance);

      // Export chart data as CSV
      // exportCSV(chartInstance);
    } else {
      console.error(`Chart not found.`);
    }
  }

  const chartOption = {
    series: series || [],
    options: {
      chart: {
        type: "bar",
        height: 380,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
        curve: "smooth",
      },
      markers: {
        size: [4, 7],
      },
      // xaxis: {
      //   // type: 'category',
      //   categories: labels || [],
      //   tickAmount: 5,
      //   tickPlacement: 'between',
      //   min: undefined,
      //   max: undefined,
      //   stepSize: undefined,
      //   range: undefined,
      //   floating: false,
      //   decimalsInFloat: undefined,
      //   overwriteCategories: undefined,
      //   position: 'bottom',
      //   labels: {
      //     show: true,
      //     rotate: -45,
      //     rotateAlways: false,
      //     hideOverlappingLabels: true,
      //     showDuplicates: false,
      //     trim: false,
      //     minHeight: undefined,
      //     maxHeight: 120,
      //     style: {
      //       colors: [],
      //       fontSize: '12px',
      //       fontFamily: 'Helvetica, Arial, sans-serif',
      //       fontWeight: 400,
      //       cssClass: 'apexcharts-xaxis-label',
      //     },
      //     offsetX: 0,
      //     offsetY: 0,
      //     format: undefined,
      //     formatter: undefined,
      //     datetimeUTC: true,
      //     datetimeFormatter: {
      //       year: 'yyyy',
      //       month: "MMM 'yy",
      //       day: 'dd MMM',
      //       hour: 'HH:mm',
      //     },
      //   },
      //   group: {
      //     groups: [],
      //     style: {
      //       colors: [],
      //       fontSize: '12px',
      //       fontWeight: 400,
      //       fontFamily: undefined,
      //       cssClass: ''
      //     }
      //   },
      //   axisBorder: {
      //     show: true,
      //     color: '#78909C',
      //     height: 1,
      //     width: '100%',
      //     offsetX: 0,
      //     offsetY: 0
      //   },
      //   axisTicks: {
      //     show: true,
      //     borderType: 'solid',
      //     color: '#78909C',
      //     height: 6,
      //     offsetX: 0,
      //     offsetY: 0
      //   },

      //   title: {
      //     text: undefined,
      //     offsetX: 0,
      //     offsetY: 0,
      //     style: {
      //       color: undefined,
      //       fontSize: '12px',
      //       fontFamily: 'Helvetica, Arial, sans-serif',
      //       fontWeight: 600,
      //       cssClass: 'apexcharts-xaxis-title',
      //     },
      //   },
      //   crosshairs: {
      //     show: true,
      //     width: 1,
      //     position: 'back',
      //     opacity: 0.9,
      //     stroke: {
      //       color: '#b6b6b6',
      //       width: 0,
      //       dashArray: 0,
      //     },
      //     fill: {
      //       type: 'solid',
      //       color: '#B1B9C4',
      //       gradient: {
      //         colorFrom: '#D8E3F0',
      //         colorTo: '#BED1E6',
      //         stops: [0, 100],
      //         opacityFrom: 0.4,
      //         opacityTo: 0.5,
      //       },
      //     },
      //     dropShadow: {
      //       enabled: false,
      //       top: 0,
      //       left: 0,
      //       blur: 1,
      //       opacity: 0.4,
      //     },
      //   },
      //   tooltip: {
      //     enabled: true,
      //     formatter: undefined,
      //     offsetY: 0,
      //     style: {
      //       fontSize: 0,
      //       fontFamily: 0,
      //     },
      //   },
      // }
      xaxis: {
        categories: labels || [],
        tickAmount: 6,
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
        },
      },
    },
  };

  // onClick={() => downloadPNG("chart-basic-column-bar")}
  return (
    <>
      <Card className="h-100 m-0">
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5 className="m-0">{title}</h5>
          <div className="d-flex flex-row gap-3">
            <div
              className="text-success cursor-pointer"
              onClick={downloadChart}
            >
              {findIcon("DownloadReport", "dual-tone", "18")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                Download Report
              </span>
            </div>
            <div className="text-success cursor-pointer" onClick={exportCSV}>
              {findIcon("ExportReport", "dual-tone", "20")}
              <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                Export
              </span>
            </div>
          </div>
        </Card.Header>
        <div className="d-flex flex-column">
          <Card.Body className="p-0 pe-2">
              <div id="chart-basic-column-bar">
                <Chart
                  ref={chartRef}
                  options={chartOption.options}
                  series={chartOption.series}
                  type="bar"
                  height={300}
                />
              </div>
          </Card.Body>
        </div>
      </Card>
    </>
  );
}

export default BasicColumnChart;

// hide number label
// total views 13388c total click 159a6c
