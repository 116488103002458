import React, { memo, Fragment, useEffect } from "react";

// React-bootstrap
import { Button, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import FilterBar from "../../../../components/filter-bar";
import ReactTable from "../../../../lib/table/react-table";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const Dashboard = memo(() => {
  const { redirectTo } = useRedirect();

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Comming soon...</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          <div className="form-group mb-0">
            {/* <Button
              variant="primary"
              className="flex-fill d-flex align-items-center justify-content-center fw-semibold rounded-3 px-4 py-2 h-100"
              type="submit"
            >
              <span className="">Filter</span>
            </Button> */}
            {/* <FilterBar
              // inputs={inputFilters}
              pageType="All-persona"
              advanceData={advanceData}
            /> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
});
Dashboard.displayName = "Dashboard";
export default Dashboard;
