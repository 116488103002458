import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { initialDate } from "../constant/date-range-constant";
import { Button, Card, Col, Row } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ContentListingCard from "../components/ContentListingCard";
import ReactTable from "../../../../../lib/table/react-table";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { Link, useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import {
  useGetCreativeDataList,
  useGetCreativeDataListBQ,
  useGetCreativeDataListCTA,
} from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import PageNotFound from "../../../../PageNotFound";
import Skeleton from "../../../../../components/skeleton";
import ImageWithFallback from "../../../../../components/ImageWithFallback";
import { fallbackImages } from "../../../../../common/constants/fallback-images";
import {
  downloadTablePNG,
  formatLocalizedDate,
  getFormatedDate,
  openInNewTab,
} from "../../../../../common/utils/utils";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import { checkProjectAccess } from "../utils/common";

const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Content() {
  const { redirectTo } = useRedirect();
  const { project_id } = useParams();
  const { downloadCSV } = useCsvDownloader();

  const [tableColumnData, setTableColumnData] = useState([]);
  const [isListView, setIsListView] = useState(true);
  const [dateRangePayload, setDateRangePayload] = useState({
    start_date: initialDate.start_date,
    end_date: initialDate.end_date,
  });

  const { data: creativeDataList, isLoading: isCreativeDataListLoading } =
    useGetCreativeDataList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
    });

  const { data: creativeDataListBQ, isLoading: isCreativeDataBQListLoading } =
    useGetCreativeDataListBQ(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id:
        creativeDataList &&
        Array.isArray(creativeDataList) &&
        creativeDataList.length > 0
          ? creativeDataList.map((item) => item.content_id).join(",")
          : "",
    });

  const { data: creativeDataListcta, isLoading: isCreativeDataCTAListLoading } =
    useGetCreativeDataListCTA(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id:
        creativeDataList &&
        Array.isArray(creativeDataList) &&
        creativeDataList.length > 0
          ? creativeDataList.map((item) => item.content_id).join(",")
          : "",
    });

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);
  function mergeContentData(data1, data2, data3) {
    // Ensure all inputs are arrays or default to empty arrays
    data1 = Array.isArray(data1) ? data1 : [];
    data2 = Array.isArray(data2) ? data2 : [];
    data3 = Array.isArray(data3) ? data3 : [];

    // Create maps for efficient lookup
    const data2Map = new Map(data2.map((item) => [item.type_id, item]));
    const data3Map = new Map(
      data3.map((item) => [item.name || item.content_id, item])
    );

    // Get all possible keys from all three datasets
    const allKeys = new Set([
      ...data1.flatMap(Object.keys),
      ...data2.flatMap(Object.keys),
      ...data3.flatMap(Object.keys),
    ]);

    // Merge data
    let mergedData = data1.map((item) => {
      const match2 = data2Map.get(
        item?.fetch_with_contents_list?.at(0).content_old_id
      );
      const match3 = data3Map.get(
        item?.fetch_with_contents_list?.at(0).content_old_id
      );
      const mergedItem = { ...item };

      allKeys.forEach((key) => {
        if (!(key in mergedItem)) {
          if (match2 && key in match2) {
            mergedItem[key] = match2[key];
          } else if (match3 && key in match3) {
            mergedItem[key] = match3[key];
          } else {
            mergedItem[key] = "N/A";
          }
        }
      });

      return mergedItem;
    });

    // Function to add missing items
    const addMissingItems = (dataArray, dataMap) => {
      dataArray.forEach((item) => {
        const itemName = item.name || item.state_name;
        if (!mergedData.some((mergedItem) => mergedItem.name === itemName)) {
          const newItem = { name: itemName };
          allKeys.forEach((key) => {
            newItem[key] = key in item ? item[key] : "N/A";
          });
          mergedData.push(newItem);
        }
      });
    };

    // Add items from data2 and data3 that are not in mergedData
    addMissingItems(data2, data2Map);
    addMissingItems(data3, data3Map);

    return mergedData;
  }
  const contentListData = mergeContentData(
    creativeDataList,
    creativeDataListBQ,
    creativeDataListcta
  );

  console.log("contentListData", contentListData);

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };
  const tableRef = useRef();

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: initialDate.end_date,
      });
    }
  }, [projectDetails]);

  const formatDataforExport = (data) => {
    return data.map((item) => {
      return {
        ...item,
        thumbnail:
          item?.fetch_with_contents_list[0]?.content_image_data[0]
            ?.content_image_data?.base,

        content_title: item?.fetch_with_contents_list[0]?.content_title,

        content_category:
          item?.fetch_with_contents_list[0]?.content_category
            ?.content_category_name,

        speciality:
          item?.fetch_with_contents_list[0]?.content_speciality[0]
            ?.master_speciality?.speciality_name,

        views: item?.views,
        reads: item?.reads,

        cta: item?.cta,
      };
    });
  };

  const tableCsvFormat = {
    header: [
      "Thumbnail",
      "Content Title",
      "Content Category",
      "Speciality",
      "Viewed",
      "Reads",
      "CTA's",
    ],
    column: [
      "thumbnail",
      "content_title",
      "content_category",
      "speciality",
      "views",
      "reads",
      "cta",
    ],
    rows: formatDataforExport(contentListData),
  };

  console.log("contentListData", contentListData);

  const hasViewAccess = checkProjectAccess("show_view", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);
  const hasCTAAccess = checkProjectAccess("show_CTA", projectDetails);

  useEffect(() => {
    let columns = [
      {
        Header: "Thumbnail",
        columnId: 1,
        accessor:
          "fetch_with_contents_list[0].content_image_data[0].content_image_data",
        disableFilters: true,
        Cell: ({ value, row }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return (
              <>
                <ImageWithFallback
                  onClick={() => openInNewTab(value.base)}
                  src={value.thumbnail}
                  fallbackSrc={fallbackImages.noImage}
                  className="rounded-3 border border-1 icon-60 cursor-pointer"
                />
              </>
            );
          }
        },
      },
      {
        Header: "Content Title",
        columnId: 2,
        accessor: "fetch_with_contents_list[0]",
        disableFilters: true,
        width: 300,
        Cell: ({ cell: { value } }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return (
              <div
                style={{ maxWidth: "20rem" }}
                className="text-truncate"
                title={value?.content_title}
              >
                <Link
                  to={`/sponsored-content/${project_id}/content/details/${value?.content_id}`}
                  className="text-black "
                >
                  {value?.content_title}
                </Link>
              </div>
            );
          }
        },
      },
      {
        Header: "Content Category",
        columnId: 3,
        accessor:
          "fetch_with_contents_list[0].content_category.content_category_name",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return value;
          }
        },
      },
      {
        Header: "Speciality",
        columnId: 4,
        accessor:
          "fetch_with_contents_list[0].content_speciality[0].master_speciality.speciality_name",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return value;
          }
        },
      },
    ];

    if (hasViewAccess) {
      columns.push({
        Header: "Viewed",
        columnId: 5,
        accessor: "views",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return value;
          }
        },
      });
    }

    if (hasReadAccess) {
      columns.push({
        Header: "Reads",
        columnId: 6,
        accessor: "reads",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return value;
          }
        },
      });
    }

    if (hasCTAAccess) {
      columns.push({
        Header: "CTA",
        columnId: 7,
        accessor: "user_count",
        disableFilters: true,
        Cell: ({ cell: { value } }) => {
          if (value === -1) {
            return <Skeleton variant="text" width={100} height={20} />;
          } else if (value == undefined || value == null) {
            return "N/A";
          } else {
            return value;
          }
        },
      });
    }

    columns.push({
      Header: "Trends",
      columnId: 8,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ cell: { value } }) => {
        if (value === -1) {
          return <Skeleton variant="text" width={100} height={20} />;
        } else if (value == undefined || value == null) {
          return "N/A";
        } else if (value === "N/A") {
          return value;
        } else {
          return (
            <SparlLineBar
              series={value && value?.length > 0 ? value.split(",") : []}
              labels={[]}
              height={50}
            />
          );
        }
      },
    });

    setTableColumnData(columns);
  }, [hasViewAccess, hasReadAccess, hasCTAAccess]);

  return (
    <>
      <Row>
        <Col md={12} className="mb-2">
          {!isProjectDetailsLoading && (
            <Titlebar
              title={"Content"}
              projectType={"Sponsored Content"}
              setDateRangePayload={setDateRangePayload}
              projectStartDate={projectDetails[0]?.project_start_date || ""}
              dateLabel={dateRangePayload}
              showListView={true}
              setIsListView={setIsListView}
              isListView={isListView}
            />
          )}
        </Col>

        <Row className="pe-0">
          {isListView && !isProjectDetailsLoading && (
            <>
              <Col md={12} sm={12} className="pe-0">
                <Card>
                  <Card.Header className="d-flex flex-column justify-content-end flex-md-row">
                    {!isCreativeDataListLoading &&
                    creativeDataList?.length > 0 ? (
                      <div className="d-flex flex-row gap-3 flex-shrink-0">
                        <div
                          className="text-success"
                          onClick={() => downloadTablePNG(tableRef)}
                        >
                          {findIcon("DownloadReport", "dual-tone", "18")}
                          <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                            Download Report
                          </span>
                        </div>
                        <div
                          className="text-success cursor-pointer"
                          onClick={handleTableCsvDownload}
                        >
                          {findIcon("ExportReport", "dual-tone", "20")}
                          <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                            Export
                          </span>
                        </div>
                      </div>
                    ) : (
                      //  <TableCsvDownloader data={data} />
                      <></>
                    )}
                  </Card.Header>
                  <Card.Body>
                    {!isCreativeDataListLoading &&
                    !isCreativeDataBQListLoading &&
                    !isCreativeDataCTAListLoading &&
                    creativeDataList.length > 0 &&
                    tableColumnData.length > 0 ? (
                      <div ref={tableRef}>
                        <ReactTable
                          data={contentListData}
                          columns={tableColumnData}
                          isPaginationShow={false}
                          bordered
                          noDataText="no data"
                          noDataImage={fallbackImages.noActionData}
                        />
                      </div>
                    ) : isCreativeDataListLoading ||
                      isCreativeDataBQListLoading ||
                      isCreativeDataCTAListLoading ||
                      tableColumnData.length === 0 ? (
                      <CommonTableLoader tableRowLength={5} />
                    ) : (
                      <PageNotFound
                        title="No data found"
                        // message="Upload one to start analyzing data!"
                        backgroundImage={findIcon(
                          "Empty-folder",
                          "dual-tone",
                          100
                        )}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}

          {!isProjectDetailsLoading &&
            !isListView &&
            !isCreativeDataListLoading &&
            contentListData?.length > 0 &&
            contentListData?.map((item) => (
              <Col key={item.content_id} md={4} lg={4} className="pe-0 pe-md-3">
                <ContentListingCard
                  contentTags={
                    item?.fetch_with_contents_list[0]?.content_tags &&
                    Array.isArray(
                      item?.fetch_with_contents_list[0]?.content_tags
                    )
                      ? item?.fetch_with_contents_list[0]?.content_tags?.map(
                          (item) => {
                            return { name: item?.tag_data?.content_tag_name };
                          }
                        )
                      : []
                  }
                  contentDate={getFormatedDate(
                    item?.fetch_with_contents_list[0]?.created_at
                  )}
                  contentTitle={
                    item?.fetch_with_contents_list[0]?.content_title
                  }
                  contentReads={item?.reads}
                  contentViews={item?.views}
                  contentType={
                    item?.fetch_with_contents_list[0]?.content_category
                      .content_category_name
                  }
                  contentLogo={
                    item?.fetch_with_contents_list[0]?.content_category
                      .content_category_icon
                  }
                  contentImage={
                    item?.fetch_with_contents_list[0]?.content_image_data[0]
                      ?.content_image_data?.base
                  }
                  fallbackContentImage={fallbackImages?.noImage}
                  contentId={item?.content_id}
                />
              </Col>
            ))}
        </Row>
      </Row>
    </>
  );
}

export default Content;
