import React, { useState, useEffect, useRef, memo } from "react";
import { Button, Col, Form, FormCheck, Row } from "react-bootstrap";
import { getValuesByKey } from "../../../../../common/utils/utils";
import { findIcon } from "../../../../dashboard/icons";

const innitialFilterData = [
  {
    id: 1,
    name: "",
    operator: "",
    clause: "",
    value: "",
  },
];

const UserDemograpicFilter = ({
  id,
  filterData,
  selectedJson: selectedJsonProp,
  onDeleteItem,
  onCreateBtnClick,
  isDeleteBtn,
  isAddBtn,
  onItemSelect,
  index,
}) => {
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [secondFilterData, setSecondFilterData] = useState([]);
  const [selectedJson, setSelectedJson] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("");
  const tempPayload = useRef(selectedJsonProp);

  useEffect(() => {
    if (typeof filterData == "object" && Object.keys(filterData).length > 0) {
      setFirstFilterData(Object.keys(filterData));
    }
  }, [filterData]);

  useEffect(() => {
    if (
      typeof filterData == "object" &&
      Object.keys(filterData).length > 0 &&
      selectedJsonProp
    ) {
      setSelectedJson(selectedJsonProp);
      const { name, operator, clause, value } = selectedJsonProp[index];
      // tempPayload.current = selectedJson[index];
      setSelectedOperator(operator);
      setSecondFilterData(getValuesByKey(filterData, name)[0]);
    }
  }, [selectedJsonProp, filterData, index]);

  const onFirstFilterSelect = (value) => {
    // console.group("onCategoryFilterSelect");

    // const { value } = e.target;

    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];
    // console.log(index, "availableFilterData", value, availableFilterData);
    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], name: value };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      // console.log("if:", tempData, "::", tempPayload.current);
    } else {
      // console.log("else:", tempPayload.current);
      tempData.name = value;
      tempPayload.current[id] = tempData;
    }
    // console.log(
    //   "exit:",
    //   tempPayload.current,
    //   "\n",
    //   availableFilterData,
    //   "\n",
    //   selectedJson
    // );
    // console.log("temp data", tempData);
    // console.groupEnd("onCategoryFilterSelect");
    onItemSelect(tempData);
    const data = getValuesByKey(filterData, value);
    if (data && data?.length > 0) {
      setSecondFilterData(data[0]);
    }
  };

  const onFilterOptionSelect = (e) => {
    // console.group("onActivityFilterSelect");
    const { value } = e.target;
    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];

    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], value: value };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      console.log("if:", tempData, "::", tempPayload.current);
    } else {
      console.log("else:", tempPayload.current);
      tempData.value = value;
      tempPayload.current[id] = tempData;
    }
    // console.log(
    //   "exit:",
    //   tempPayload.current,
    //   availableFilterData,
    //   selectedJson
    // );
    // console.groupEnd("onActivityFilterSelect");
    onItemSelect(tempData);
  };

  const onClauseSelect = (e) => {
    const { value } = e.target;
    let tempData = innitialFilterData[0];
    const availableFilterData =
      selectedJson && selectedJson?.length > 0
        ? selectedJson.filter((item) => item.id == id)
        : [];
    // console.log(index, "onDemographiclausechange");
    if (
      selectedJson &&
      selectedJson?.length > 0 &&
      availableFilterData?.length > 0
    ) {
      tempData = { ...availableFilterData[0], clause: value };
      const index = tempPayload.current.findIndex((item) => item.id == id);
      tempPayload.current[index] = tempData;
      console.log("if:", tempData, "::", tempPayload.current);
    } else {
      console.log("else:", tempPayload.current);
      tempData.clause = value;
      tempPayload.current[id] = tempData;
    }
    // console.log(
    //   "exit:",
    //   tempPayload.current,
    //   availableFilterData,
    //   selectedJson
    // );
    // console.groupEnd("onDemographiclausechange");
    onItemSelect(tempData);
  };

  return (
    <>
      <div className="mb-4">
        <Row className="mt-n4">
          {(index > 0 || isDeleteBtn) && (
            <Col lg={12} className="d-flex justify-content-end mt-4">
              {isDeleteBtn && (
                <Button
                  variant="danger"
                  className="btn-icon btn-sm rounded-circle"
                  onClick={(e) => onDeleteItem(id)}
                >
                  <div className="btn-inner">
                    {findIcon("Trash", "dual-tone")}
                  </div>
                </Button>
              )}
            </Col>
          )}

          <Col lg={4} className="mt-4">
            <Form.Select
              name="type"
              className="selectpicker form-control"
              data-style="py-0"
              onChange={(e) => onFirstFilterSelect(e.target.value)}
              value={selectedJson[index]?.name}
            >
              <option value="">Select a Filter</option>
              {firstFilterData?.length > 0 &&
                firstFilterData?.map((spec) => (
                  <option value={spec}>{spec}</option>
                ))}
            </Form.Select>
          </Col>
          <Col lg={4} className="mt-4">
            <Form.Select
              name="type"
              className="selectpicker form-control"
              data-style="py-0"
              onChange={onClauseSelect}
              value={selectedJson[index]?.clause}
            >
              <option value="">Select Clause</option>
              <option value="is">Is</option>
              <option value="is_not">Is Not</option>
            </Form.Select>
          </Col>
          <Col lg={4} className="mt-4">
            <Form.Select
              name="type"
              className="selectpicker form-control"
              data-style="py-0"
              onChange={onFilterOptionSelect}
              value={selectedJson[index]?.value}
            >
              <option value="">Select an Option</option>
              {secondFilterData &&
                secondFilterData?.length > 0 &&
                secondFilterData.map((el, index) => (
                  <option value={Object.values(el)[0]}>
                    {Object.values(el)[1]}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Row>
      </div>
      {isAddBtn && (
        <Col lg={12} className="d-flex justify-content-center">
          <Button
            variant="outline-info"
            className="d-flex align-items-center border-dashed justify-content-center gap-2"
            onClick={onCreateBtnClick}
          >
            {findIcon("Plus", "solid", "20")}
            Add More
          </Button>
        </Col>
      )}
    </>
  );
};

export default memo(UserDemograpicFilter);
