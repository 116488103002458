import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function VerticalTabSkeleton({ height = 120, width = "100%" }) {
  return (
    <div>
      <Row>
        {/* <Col md={3} className="mb-4">
          <div className="d-flex flex-column flex-md-column nav-pills gap-4 flex-wrap">
            {[1, 2, 3, 4].map((_, i) => {
              return (
                <Skeleton
                  key={i}
                  variant="square"
                  width={width}
                  height={50}
                  className="rounded-3"
                />
              );
            })}
          </div>
        </Col> */}

        <Col md={12}>
          <Row>
            {[1, 2].map((_, i) => {
              return (
                <Col md={3} key={i}>
                  <Skeleton
                    variant="circular"
                    width={width}
                    height={150}
                    className="rounded-3"
                  />
                </Col>
              );
            })}

            <Col md={6} className="">
              <Row>
                {[...Array(4)].map((_, i) => {
                  return (
                    <Col md={6} key={i}>
                      <Skeleton
                        variant="square"
                        width={width}
                        height={70}
                        className="rounded-3 mb-3"
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>

            <Col md={12} className="mb-4">
              <Skeleton
                variant="text"
                width={"15%"}
                className="rounded-3 mb-2 ms-2"
              />

              <div className="d-flex align-items-center gap-2">
                {[...Array(6)].map((_, i) => {
                  return (
                    <Skeleton
                      key={i}
                      variant="circular"
                      width={100}
                      height={20}
                      className="rounded-3 ms-2 px-3 py-2 fs-7 lh-1 "
                    />
                  );
                })}
              </div>
            </Col>

            <Col md={12} className="mb-4">
              <Skeleton
                variant="square"
                width={width}
                height={300}
                className="rounded-3"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default memo(VerticalTabSkeleton);
