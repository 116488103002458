import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

function TreeMapChart({ series, title, height = 300 }) {
  let chartOptions = {
    series: [
      {
        data: series || [],
      },
    ],
    options: {
      colors: ["#3256B8"],
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: "treemap",

        toolbar: {
          show: false,
        },
      },
      toolbar: {
        show: false,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  return (
    <>
      <Card className="h-100 m-0">
        <Card.Header className="">
          <h5 className="text-center subHeading position-relative">{title}</h5>
        </Card.Header>
        <Card.Body className="p-4 py-2">
          <div id="chart">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="treemap"
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default TreeMapChart;

// 13388c , 305ab8 , 3b4b71 , 3e6cd2 , 647fbb , 87a1e0 , 88a8f1

// 06112a , 0d2762 , 2b4c98 , 7188ba
