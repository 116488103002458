export const FILTER_VALUE_SEPARATOR = "__::__";
export const FILTER_OPERATERS = {
    EQUALS: "eq",
    NOT_EQUALS: "neq",
    GREATER_THAN: "gt",
    GREATER_THAN_OR_EQUAL: "gte",
    LESS_THAN: "lt",
    LESS_THAN_OR_EQUAL: "lte",
    LIKE: "like",
    NOT_LIKE: "nlike",
};
export const FILTER_TYPES = {
    TEXT: "text",
    NUMBER: "number",
    DATE: "date",
    SELECT: "select",
    RANGE: "range",
    RADIO: "radio",
    CHECKBOX: "checkbox",
    CUSTOM: "custom",
    DATE_RANGE: "dateRange",
};

export const OPERATOR_TYPES = {
    IS_OR_IS_NOT: [
        {
            label: "is",
            value: FILTER_OPERATERS.EQUALS,
        },

        {
            label: "is not",
            value: FILTER_OPERATERS.NOT_EQUALS,
        },
    ],
    EQUAL_LESS_THAN_GREAT_THAN: [
        {
            label: "equals",
            value: FILTER_OPERATERS.EQUALS,
        },
        {
            label: "less than",
            value: FILTER_OPERATERS.LESS_THAN,
        },
        {
            label: "greater than",
            value: FILTER_OPERATERS.GREATER_THAN,
        },
        {
            label: "less than or equal",
            value: FILTER_OPERATERS.LESS_THAN_OR_EQUAL,
        },
        {
            label: "greater than or equal",
            value: FILTER_OPERATERS.GREATER_THAN_OR_EQUAL,
        },
    ],
    LIKE_OR_NOT_LIKE: [
        {
            label: "like",
            value: FILTER_OPERATERS.LIKE,
        },
        {
            label: "not like",
            value: FILTER_OPERATERS.NOT_LIKE,
        },
    ],
};
