import React, { memo, Fragment } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as SettingSelector from "../../../../../store/setting/selectors";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import Sidebarlogo from "../../../../../components/partials/components/sidebar/sidebar-logo";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import SidebarProfileCard from "../../../../../components/partials/components/sidebar/sidebar-profile-card";
import EventsVerticalNav from "./EventsVerticalNav";

const EventsSidebar = memo(() => {
  const { redirectTo } = useRedirect();
  const { project_id } = useParams();

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const appName = useSelector(SettingSelector.app_name);
  const sidebarColor = useSelector(SettingSelector.sidebar_color);
  const sidebarHide = useSelector(SettingSelector.sidebar_show);
  const sidebarType = useSelector(SettingSelector.sidebar_type);
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  const onBackButtonClick = () => {
    redirectTo("workspace/clirnet");
  };

  return (
    <Fragment>
      <aside
        className={`${sidebarColor} ${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} ${
          sidebarHide.join(" ") ? "sidebar-none" : "sidebar"
        } sidebar-base `}
      >
        <Sidebarlogo app_name={appName} />

        <div className="sidebar-body pt-0 data-scrollbar" id="my-scrollbar">
          {
            <SidebarProfileCard
              title={
                projectDetails && projectDetails?.length > 0
                  ? projectDetails[0]?.project_name
                  : ""
              }
              logo={
                projectDetails && projectDetails?.length > 0
                  ? projectDetails[0]?.project_logo
                  : ""
              }
              isLoading={isProjectDetailsLoading}
              previousPageName={"Projects"}
              onBackButtonClick={onBackButtonClick}
            />
          }

          <hr className="hr-horizontal" />
          <div className="sidebar-list">
            <EventsVerticalNav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </Fragment>
  );
});

export default EventsSidebar;
