import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import RenderField from "./RenderField";
import {
  useCreateProject,
  useGetSearchData,
} from "../../../../../queries/query-hooks/workspace/workspace-hook";
import useDebounce from "../../../../../components/custom-hooks/use-debounce";
import postService from "../../../../../api/services/post-service";
import {
  ADD_PROJECT,
  GET_BANNER_GROUP,
  UPDATE_PROJECT,
} from "../../../../../api/endpoints/channel-endpoint";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { GET_PROJECT_LIST } from "../../../../../queries/query-constants/workspace/workspace-constant";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import useBackdrop from "../../../../../components/custom-hooks/use-backdrop";
import { debounce } from "lodash";
import { getPrincipleEntitiesForSelect } from "../../../../../common/utils/utils";

function BrandAdsForm({
  project_type_code,
  project_group_id,
  project_type_id,
  projectDetails = [],
}) {
  const { redirectTo } = useRedirect();
  const queryClient = useQueryClient();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  useEffect(() => {
    if (projectDetails.length > 0) {
      preFillProjectForm();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      showSelection: false,
      project_name: "",
      project_desc: "",
      principal_entity_id: "",
      project_start_date: "",
      project_end_date: "",
      project_contact_name: "",
      project_contact_email: "",
      project_contact_phone: "",
      project_po_ref: "",
      project_po_date: "",
      project_logo: "",
      project_owner: "",
      project_group_id: project_group_id,
      ga_status: 0,
      project_type_id: project_type_id,
      project_impressions_target: 0,
      project_status: 1,
      project_attributes: 1,
      project_campaign_groups: [
        {
          label: "",
          group_id: "",
          group_impressions_target: 0,
          group_reach_target: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Project Name is required"),
      project_desc: Yup.string().required("Project Description is required"),
      principal_entity_id: Yup.string().required(
        "Principal Entity is required"
      ),
      project_start_date: Yup.date().required("Start date is required"),
      project_end_date: Yup.date().required("End date is required"),
      project_contact_name: Yup.string().required(
        "Project contact person name is required"
      ),
      project_contact_email: Yup.string()
        .email()
        .required("Project contact person Email is required"),
      project_contact_phone: Yup.string()
        .matches(/^[6-9]\d{9}$/, {
          message: "Please enter valid number.",
          excludeEmptyString: false,
        })
        .required("Project contact person phone is required"),
      project_po_ref: Yup.string().required("Project PO Reference is required"),
      project_po_date: Yup.string().required("Project PO Date is required"),
      project_logo: Yup.string().required("Project Logo is required"),
      ga_status: Yup.number().required("GA Status is required"),
      project_impressions_target: Yup.number()
        .required("Project Impressions Target is required")
        .moreThan(0, "Please enter valid Impression target."),

      project_campaign_groups: Yup.array()
        .required("At least one Campaign Group is required")
        .of(
          Yup.object().shape({
            label: Yup.string()
              .trim()
              .required("Campaign Group Name is required"),

            group_id: Yup.string()
              .trim()
              .required("Campaign Group ID is required"),

            group_impressions_target: Yup.number()
              .required("Campaign Group Impressions Target is required")
              .moreThan(0, "Please enter valid Impression target."),

            group_reach_target: Yup.number()
              .required("Campaign Group Reach Target is required")
              .moreThan(0, "Please enter valid Reach target."),
          })
        ),
      project_owner: Yup.string().required("Project Owner name is required"),
    }),
    onSubmit: async (values) => {
      const payload = { ...values };
      payload.project_attributes = [
        {
          key: "demographic_by_age",
          value: values.project_attributes === 1 ? "true" : "false",
        },
      ];
      showBackdrop();
      let response;
      if (projectDetails.length > 0) {
        response = await postService(UPDATE_PROJECT, payload);
      } else {
        response = await postService(ADD_PROJECT, payload);
      }
      if (!response.data?.success) {
        toast.error("Something went wrong");
        hideBackdrop();
      } else {
        queryClient.invalidateQueries({
          queryKey: [GET_PROJECT_LIST],
        });

        if (projectDetails.length > 0) {
          toast.success("Project updated successfully");
        } else {
          toast.success("Project created successfully");
        }

        hideBackdrop();
        redirectTo("workspace/clirnet");
      }
    },
  });
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
  } = formik;
  const { data: createProjectData, isLoading: principalEntitiesLoading } =
    useCreateProject();
  const fields = [
    {
      id: 1,
      label: "Project Name",
      field_name: "project_name",
      type: "text",
      placeholder: "Enter Project Name",
      required: true,
    },
    {
      id: 2,
      label: "Project Description",
      field_name: "project_desc",
      type: "textarea",
      placeholder: "Enter Project Description",
      required: true,
    },
    {
      id: 3,
      label: "Principal Entity",
      field_name: "principal_entity_id",
      type: "select",
      options: getPrincipleEntitiesForSelect(
        createProjectData?.principal_entities || []
      ),
      required: true,
      optionLabelKey: "principal_entity_name",
      optionValueKey: "principal_entity_id",
    },
    {
      id: 4,
      label: "GA Status",
      field_name: "ga_status",
      type: "switch",
      required: false,
    },
    {
      id: 4,
      label: "Show demographic with age and gender",
      field_name: "project_attributes",
      type: "switch",
      required: false,
    },
    {
      id: 4,
      label: "Start Date",
      field_name: "project_start_date",
      type: "date",
      required: true,
    },
    {
      id: 5,
      label: "End Date",
      field_name: "project_end_date",
      type: "date",
      required: true,
    },
    {
      id: 6,
      label: "Project Owner Name",
      field_name: "project_owner",
      type: "text",
      placeholder: "Enter Project Owner",
      required: true,
    },
    {
      id: 7,
      label: "Project Contant Person Name",
      field_name: "project_contact_name",
      type: "text",
      placeholder: "Enter Owner Contact",
      required: true,
    },
    {
      id: 8,
      label: "Project Contant Person Email",
      field_name: "project_contact_email",
      type: "email",
      placeholder: "Enter Owner Email",
      required: true,
    },
    {
      id: 9,
      label: "Project Contant Person Phone",
      field_name: "project_contact_phone",
      type: "number",
      placeholder: "Enter Owner Phone",
      required: true,
    },
    {
      id: 11,
      label: "Purchase Order Reference",
      field_name: "project_po_ref",
      type: "text",
      placeholder: "Enter Purchase Order Reference",
      required: true,
    },
    {
      id: 12,
      label: "Purchase Order Date",
      field_name: "project_po_date",
      type: "date",
      placeholder: "Enter Purchase Order Date",
      required: true,
    },
    {
      id: 13,
      label: "Project Logo",
      field_name: "project_logo",
      type: "file",
      required: true,
    },
    {
      id: 14,
      label: "Project Impressions Target",
      field_name: "project_impressions_target",
      type: "number",
      required: true,
    },
  ];
  const [searchText, setSearchText] = useState({});
  const seachTerm = useDebounce(searchText, 1000);
  const { data: searchData, isFetching: issearchDataLoading } =
    useGetSearchData(
      {
        search_key: seachTerm,
      },
      project_type_code
    );

  const _loadSuggestions = (inputValue) => {
    return new Promise(async (resolve) => {
      const response = await postService(GET_BANNER_GROUP, {
        search_key: inputValue,
      });

      if (!response.isError) {
        const searchData = [];

        response?.data?.data.forEach((elem) => {
          searchData.push({
            value: elem.id,
            label: elem.title,
          });
        });
        resolve(searchData);
      } else {
        toast.error("Error fetching data!");
      }
    });
  };

  function preFillProjectForm() {
    const projectImpressionsTarget =
      projectDetails?.at(0)?.project_target?.impressions_target || 0;

    const projectCampaignGroup = projectDetails
      ?.at(0)
      ?.project_group_data?.map((elem) => {
        return {
          group_id: elem?.group_id,
          group_impressions_target: elem?.impressions_target,
          group_reach_target: elem?.reach_target,
          label: elem?.group_data?.group_title,
        };
      });

    setValues({
      ...values,
      ...projectDetails?.at(0),
      project_attributes:
        projectDetails?.at(0)?.project_attributes?.at(0)?.attribute_value ===
        "true"
          ? 1
          : 0,
      project_impressions_target: projectImpressionsTarget,
      project_campaign_groups: projectCampaignGroup || [],
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      {fields &&
        fields.map((field) => (
          <RenderField
            {...field}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            key={field.id}
            setFieldValue={setFieldValue}
          />
        ))}

      {/* <Form.Check
        type="switch"
        id="custom-switch"
        label="Add Campaign Groups"
        onChange={() => setFieldValue("showSelection", !values?.showSelection)}
        className="mb-3"
      /> */}
      {/* <Collapse in={values?.showSelection}> */}
      <div>
        <FormikProvider value={formik}>
          <FieldArray name="project_campaign_groups">
            {(prop) => (
              <>
                {values.project_campaign_groups?.map((item, index) => (
                  <Row key={index}>
                    <Col className="mb-3">
                      <RenderField
                        required={true}
                        type="searchable"
                        placeholder="Select Campaign Group"
                        label={`Select Campaign Group *`}
                        field_name={`project_campaign_groups[${index}].label`}
                        searchText={searchText}
                        loadOptions={debounce((inputText, callback) => {
                          setSearchText(inputText);
                          _loadSuggestions(inputText)
                            .then((options) => {
                              callback(options);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }, 300)}
                        handleChange={(val) => {
                          if (val) {
                            setFieldValue(
                              `project_campaign_groups[${index}].group_id`,
                              val?.value || ""
                            );
                            setFieldValue(
                              `project_campaign_groups[${index}].label`,
                              val?.label || ""
                            );
                          } else {
                            setFieldValue(
                              `project_campaign_groups[${index}].group_id`,
                              ""
                            );
                            setFieldValue(
                              `project_campaign_groups[${index}].label`,
                              ""
                            );
                          }
                        }}
                        selected={
                          values.project_campaign_groups[index]?.label
                            ? {
                                label:
                                  values.project_campaign_groups[index]?.label,
                                value:
                                  values.project_campaign_groups[index]
                                    ?.group_id,
                              }
                            : ""
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col lg={2}>
                      <RenderField
                        type="number"
                        field_name={`project_campaign_groups[${index}].group_impressions_target`}
                        placeholder="Impressions Target"
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        label={"Impressions Target"}
                        required={true}
                      />
                    </Col>
                    <Col lg={2}>
                      <RenderField
                        type="number"
                        field_name={`project_campaign_groups[${index}].group_reach_target`}
                        placeholder="Reach Target"
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        label={"Reach Target"}
                        required={true}
                      />
                    </Col>
                    <Col lg={2}>
                      <Button
                        variant="outline-primary"
                        className="border-dashed me-3"
                        onClick={() => prop?.remove(index)}
                        disabled={index == 0 ? true : false}
                      >
                        -
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="border-dashed"
                        onClick={() =>
                          prop?.push({
                            group_id: "",
                            label: "",
                            group_impressions_target: 0,
                            group_reach_target: 0,
                          })
                        }
                        disabled={
                          values[`project_campaign_groups`][index]?.label == ""
                            ? true
                            : false
                        }
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </FieldArray>
        </FormikProvider>
      </div>
      {/* </Collapse> */}

      <Button type="submit" className="me-4">{`${
        projectDetails.length > 0 ? "Update" : "Submit"
      }`}</Button>

      <Button
        variant="secondary"
        onClick={() => redirectTo("workspace/clirnet")}
      >
        Cancel
      </Button>
    </Form>
  );
}

export default BrandAdsForm;
