import React from "react";
import Skeleton from "../../../../components/skeleton";

const ProfileSkeleton = () => {
  return (
    <div className="d-flex gap-3 w-100">
      <div>
        <Skeleton variant="circular" width={50} height={50} />
      </div>
      <div className="d-flex flex-column gap-2 justify-content-center">
        <Skeleton
          variant="square"
          className="rounded-3"
          width={200}
          height={15}
        />
        <Skeleton
          variant="square"
          className=" rounded-3"
          width={150}
          height={15}
        />
      </div>
    </div>
  );
};

export default ProfileSkeleton;
