export const fallbackImages = {
  template: "",
  segment: "",
  action: "",
  content_library: "",
  campaign: "",
  hcp_profile: "",
  rejected:
    "https://img.freepik.com/free-vector/man-saying-no-concept-illustration_114360-14222.jpg",
  completed:
    "https://img.freepik.com/free-vector/completed-concept-illustration_114360-3449.jpg",
};
