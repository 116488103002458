import React from "react";
import Leaflet from "../../../../components/leaflet";
// import Leaflet from "../components/leafletMap";

const HCP_Profile = () => {
  return (
    <div className="test 1">
      {/* <Leaflet /> */}
      <Leaflet />
    </div>
  );
};

export default HCP_Profile;
