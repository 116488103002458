import { Fragment, memo, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { CREATE_CONTENT } from "../../../../api/endpoints/add-content-endpoints";
import { getService } from "../../../../api/services/get-services";
import withAuthorization from "../../../../components/hoc/with-authorization";
import AddArticle from "../components/add-article";
import AddMedia from "../components/add-media";
import AddFile from "../components/add-file";
import AddHtml from "../components/add-html";
import AddContentTypeLoader from "../../../../skeleton/add-content-loader/add-content-type-loader";
import AddContentAreaLoader from "../../../../skeleton/add-content-loader/add-content-area-loader";
// import img4 from "../../../../assets/modules/blog/images/blog-avatar/07.png";
// import img5 from "../../../../assets/modules/blog/images/blog-avatar/08.png";

const AddContent = () => {
  const [type, setType] = useState(null);
  const [formFields, setFormFields] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [fileTypeId, setFileTypeId] = useState(null);
  const [contentData, setContentData] = useState(null);
  function getData() {
    getService(CREATE_CONTENT)
      .then((data) => {
        let response = data.data.data;
        setFormFields(response);

        setLoading(false);
        let editData = false;
        if (editData) {
          setType(editData.file_type_code);
          setFileTypeId(editData.content_file_type_id);
          setSelectedCategory(
            response?.category?.find(
              (c) => c.content_category_id === editData.content_category_id
            )
          );
          setContentData(editData);
        } else {
          setType(response.filetypes[0].file_type_code);
          setFileTypeId(response.filetypes[0].file_type_id);
        }
      })
      .catch((e) => console.log("error", e));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Fragment>
        {/* <div className="h-100 overflow-x-auto"> */}
        {!loading ? (
          <>
            {/* {console.log(selectedCategory)} */}
            {!selectedCategory && (
              <div className="d-flex gap-2 mb-2 overflow-auto scroll-addcontent pb-2">
                {formFields &&
                  formFields?.category &&
                  formFields?.category?.map(
                    ({ content_category_id, content_category_name }) => (
                      <>
                        {/* {console.log(content_category_name)} */}
                        <Button
                          key={content_category_id}
                          variant={
                            content_category_id ===
                            selectedCategory?.content_category_id
                              ? "primary"
                              : "outline-secondary"
                          }
                          onClick={() => {
                            setSelectedCategory({
                              content_category_id,
                              content_category_name,
                            });
                          }}
                        >
                          Add {content_category_name}
                        </Button>
                      </>
                    )
                  )}
              </div>
            )}
            {selectedCategory && (
              <div className="d-flex gap-2 mb-2 overflow-auto scroll-addcontent pb-2">
                <i
                  className="icon text-primary fs-2 px-2 cursor-pointer"
                  onClick={() => setSelectedCategory(null)}
                >
                  <svg
                    width="20"
                    className="icon-20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.25 12.2744L19.25 12.2744"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </i>

                {formFields &&
                  formFields.filetypes &&
                  formFields.filetypes.map((fileType) => (
                    <Button
                      key={fileType.file_type_id}
                      variant={
                        type === fileType.file_type_code
                          ? "primary"
                          : "outline-secondary"
                      }
                      onClick={() => {
                        setType(fileType.file_type_code);
                        setFileTypeId(fileType.file_type_id);
                      }}
                    >
                      Add {fileType.file_type_name}
                    </Button>
                  ))}
              </div>
            )}
          </>
        ) : (
          <AddContentTypeLoader />
        )}
        {/* </div> */}
        {!loading ? (
          <>
            {type === "article" && (
              <Row>
                <AddArticle
                  fields={formFields}
                  contentCategory={selectedCategory}
                  content_file_type_id={fileTypeId}
                  isAction={false}
                  actionData={null}
                  onCreateContent={null}
                  content_category_id={selectedCategory?.content_category_id}
                />
              </Row>
            )}
            {/* End Add Medwiki Section */}

            {/* Start PDF upload */}
            {type == "file" && (
              <Row>
                <AddFile
                  type="file"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts="*"
                />
              </Row>
            )}

            {/* UPLOAD VIDEO */}
            {type == "video" && (
              <Row>
                <AddMedia
                  type="video"
                  formFields={formFields}
                  content_category_id={selectedCategory?.content_category_id}
                  accepts="video/*"
                  content_file_type_id={fileTypeId}
                  contentData={contentData}
                />
              </Row>
            )}
            {/* UPLOAD IMAGE */}
            {type == "infographic" && (
              <Row>
                <AddMedia
                  type="image"
                  formFields={formFields}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  accepts="image/*"
                  content_file_type_id={fileTypeId}
                />
              </Row>
            )}
            {type == "image" && (
              <Row>
                <AddMedia
                  type="image"
                  formFields={formFields}
                  content_category_id={selectedCategory?.content_category_id}
                  accepts="image/*"
                  content_file_type_id={fileTypeId}
                  contentData={contentData}
                />
              </Row>
            )}
            {/* UPLOAD AUDIO */}
            {type == "audio" && (
              <Row>
                <AddMedia
                  type="audio"
                  formFields={formFields}
                  content_category_id={selectedCategory?.content_category_id}
                  accepts="audio/*"
                  content_file_type_id={fileTypeId}
                />
              </Row>
            )}

            {/* UPLOAD PDF */}
            {type == "pdf" && (
              <Row>
                <AddFile
                  type="pdf"
                  content_category_id={selectedCategory?.content_category_id}
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts="application/pdf"
                />
              </Row>
            )}
            {/* UPLOAD PPT */}
            {type == "ppt" && (
              <Row>
                <AddFile
                  type="ppt"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts="application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                />
              </Row>
            )}

            {/* UPLOAD DOCX */}
            {type == "docx" && (
              <Row>
                <AddFile
                  type="docx"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </Row>
            )}

            {/* UPLOAD DOCX */}
            {type == "excel" && (
              <Row>
                <AddFile
                  type="xlsx"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </Row>
            )}
            {/* UPLOAD zip */}
            {type == "zip" && (
              <Row>
                <AddFile
                  type="zip"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts=".zip,.rar,.7zip,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                />
              </Row>
            )}

            {(type === "html" || type === "email") && (
              <Row>
                <AddHtml
                  content_file_type_id={fileTypeId}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                />
              </Row>
            )}
          </>
        ) : (
          <AddContentAreaLoader />
        )}
      </Fragment>
    </>
  );
};

export default withAuthorization(memo(AddContent));
