import React from "react";
import { Card, Col, Row, Badge } from "react-bootstrap";

const ContentDetails = ({
  date = "",
  title = "",
  brand = "",
  fileTypes = [],
  description = "",
  department = "",
  userName = "",
}) => {
  let filesList =
    fileTypes.length > 0 ? `${fileTypes}`.replace(",", " | ") : "";
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div>
                <span className="text-primary">{date}</span>
                {/* <Badge pill bg="soft-info" className="ms-2 fw-normal">
                  UnityMED
                </Badge>
                <Badge pill bg="soft-info" className="ms-2 fw-normal">
                  Respiratory
                </Badge> */}
                <h4 className="mt-2">{title}</h4>
                <div className="d-flex flex-column">
                  <span className="text-dark fs-6">{brand}</span>
                  <span className="text-dark fs-6">{filesList}</span>
                </div>

                <p className="mt-3">{description}</p>
                <div className="d-flex justify-content-end gap-3">
                  <span className="text-dark fs-6">{department}</span>
                  <span> | </span>
                  <span className="text-dark fs-6">Created By {userName}</span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ContentDetails;
