import React, { Fragment, memo, useCallback, useEffect, useRef } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  ALL_REQS_GET,
  DELETE_REQUISITION,
  MY_REQUISTIONS,
} from "../../../../api/endpoints/requisition-endpoints";
import { getService } from "../../../../api/services/get-services";
import postService from "../../../../api/services/post-service";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import ReactTable from "../../../../lib/table/react-table";
import {
  getStatusId,
  requisitionStatus,
  requisitionStatusColor,
} from "../constants/requisition-constants";
import FloatingButton from "../../../../components/form-components/floating-button";
import withAuthorization from "../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../skeleton/react-table-loader/react-table-loader";
import ColumnTextWrapper from "../../../../components/common-components/column-text-wrapper";
const AllRequisitions = () => {
  //usestate for table_data and column_data
  const { pathname } = useLocation();
  const statusId = getStatusId(pathname.split("/")[2]);
  console.log("STATUS_ID", statusId);
  const getInitialFilter = () =>
    statusId == 0 ? {} : { status: { type: "eq", value: statusId } };
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [pageTitle, setPageTitle] = React.useState("Requsitions");
  const [loading, setLoading] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const { redirectTo } = useRedirect();
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      requisition_id: "desc",
    },
  });

  const columns = [
    {
      Header: "ID",
      accessor: "requisition_id",
      Cell: ({ row }) => (
        <p onClick={() => redirectTo("requisition/details")}>
          {row.values.requisition_id}
        </p>
      ),
    },
    {
      Header: "Title",
      accessor: "requisition_title",
      Cell: ({ row }) => (
        <ColumnTextWrapper onClick={() => redirectTo("requisition/details")}>
          {row.values.requisition_title}
        </ColumnTextWrapper>
      ),
    },
    // {
    //   Header: "Description",
    //   accessor: "requisition_description",
    //   show: false,
    //   Cell: ({ row }) => (
    //     <p onClick={() => redirectTo("requisition/details")}>
    //       {row.values.requisition_description}
    //     </p>
    //   ),
    // },
    {
      Header: "Due Date",
      accessor: "requisition_duedate",
      Cell: ({ row }) => (
        <p onClick={() => redirectTo("requisition/details")}>
          {row.values.requisition_duedate}
        </p>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge
          bg={requisitionStatusColor[value?.id]}
          onClick={() => redirectTo("requisition/details")}
        >
          {value?.value ?? ""}
        </Badge>
      ),
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ value }) => (
        <p onClick={() => redirectTo("requisition/details")}>
          {value?.name ?? ""}
        </p>
      ),
    },
    {
      Header: "Brand",
      accessor: "requisition_brand_id",

      Cell: ({ value }) => (
        <p onClick={() => redirectTo("requisition/details")}>
          {value?.principal_entity_name ?? ""}
        </p>
      ),
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onDelete={(data) => {
            setDeleteId(row.original.requisition_id);
            setShowDeleteModal(true);
          }}
          onEdit={(data) => {
            redirectTo("requisition/edit/" + row.original.requisition_id);
          }}
          onView={() => {
            redirectTo("requisition/details/" + row.original.requisition_id);
          }}
        />
      ),
    },
    // {
    //   Header: "Reference Files",
    //   accessor: "reference_files",
    // },
  ];

  const fetchRequisitions = async () => {
    // alert(pathname);
    // setLoading(true);
    const response = await postService(
      pathname == "/requisition/listing" ? ALL_REQS_GET : MY_REQUISTIONS,
      post_json.current
    );
    // console.log(Object.keys(response?.rows[0]));
    if (response.data.data.rows.length > 0) {
      setTableData(response.data.data.rows);
      setTotalCount(response.data.data.totalCount);
      setLoading(false);
    } else {
      setTableData([]);
      setTotalCount(0);
      setLoading(false);
    }
  };

  //useEffect to call fetchRequisitions
  useEffect(() => {
    setLoading(true);
    post_json.current.filter = getInitialFilter();
    // alert(requisitionStatus[statusId]);
    setPageTitle(requisitionStatus[statusId]);
    fetchRequisitions();
    return () => {
      //set all states to initial state
      setTableData([]);
      setTotalCount(0);
      setLoading(false);
    };
  }, [pathname]);

  const onTableStateChange = useCallback(
    async (state) => {
      console.log("STATE_IN_CALLBACK", state);
      let payload = changeTableStateToPayload(state);
      console.log("payload", payload);
      post_json.current = payload;
      fetchRequisitions();
    },
    [tableData, post_json.current, loading]
  );
  const onDelete = async () => {
    let response = await getService(DELETE_REQUISITION + deleteId);
    console.log("DELETE_RESPONSE", response);
    if (response.isError) return;

    setShowDeleteModal(false);
    fetchRequisitions();
  };

  return (
    <>
      <Fragment>
        <FloatingButton onClick={() => redirectTo("requisition/create")} />
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            <h3 className="m-0">
              {pageTitle || "Requisitions"} ({totalCount})
            </h3>
          </div>
          <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
            {/* <div className="form-group mb-0 ">
              <select className="form-select">
                <option defaultValue>Sort By</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="form-group mb-0 ">
              <Flatpickr
                options={{ mode: "range", minDate: "today" }}
                className="form-control range_flatpicker"
                placeholder="24 Jan 2022 to 23 Feb 2022"
              />
            </div> */}
            {/* Add requisisiton button */}
          </div>
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                {!loading ? (
                  tableData?.length > 0 && (
                    <ReactTable
                      columns={columns}
                      data={tableData}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      isRowSelectionAvailable={false}
                      hiddenColumns={["requisition_description"]}
                    />
                  )
                ) : (
                  <ReactTableLoader />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
      <DeleteModal
        modalClose={() => setShowDeleteModal(false)}
        onSubmit={onDelete}
        show={showDeleteModal}
      />
    </>
  );
};

export default withAuthorization(memo(AllRequisitions));
