import React, { memo } from "react";
import Skeleton from "../../components/skeleton";
import { Card } from "react-bootstrap";

function OnboardingCardSkeleton() {
  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <h4 className="mb-0">
            <Skeleton width={120} height={35} />
          </h4>
          <p className="mb-0 fs-7 mt-2">
            <Skeleton width={50} />
          </p>
        </Card.Title>
        <Skeleton width={50} />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Skeleton width={550} height={300} />
      </Card.Body>
    </Card>
  );
}

export default memo(OnboardingCardSkeleton);
