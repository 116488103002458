import { useQuery, useMutation } from "@tanstack/react-query";
import {
  GET_ONBOARDING_CITIES,
  GET_ONBOARDING_STATES,
  GET_ONBOARDING_ZONES,
  ONBOARDING_CREATE_TASK_DATA,
  ONBOARDING_EDIT_TASK_DATA,
  ONBOARDING_LOG_TYPES,
  ONBOARDING_TASK_ANALYTICS,
  ONBOARDING_CALL_ANALYTICS,
  ONBOARDING_CALL_LOG_ANALYTICS,
  ONBOARDING_SEARCH_MEDIUM_ANALYTICS,
} from "../../api/endpoints/onboarding-endpoints";

import axiosInstance from "../../api/instances/axiosInstance";

import {
  GET_ONBOARDING_LOG_TYPES,
  ONBOARDING_CITIES,
  ONBOARDING_CREATE_TASK_KEY,
  ONBOARDING_EDIT_DATA,
  ONBOARDING_STATES,
  ONBOARDING_ZONES,
  POST_ONBOARDING_TASK_ANALYTICS,
  POST_ONBOARDING_CALL_ANALYTICS,
  POST_ONBOARDING_CALL_LOG_ANALYTICS,
  POST_ONBOARDING_MEDIUM_ANALYTICS,
} from "../query-constants/onboarding-constants";

const staleTime = 300000;
const cacheTime = 600000;
export const useFetchOnboardingCreation = () => {
  return useQuery(
    [ONBOARDING_CREATE_TASK_KEY],
    () => axiosInstance.get(ONBOARDING_CREATE_TASK_DATA),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useOnboardingEdit = () => {
  return useQuery(
    [ONBOARDING_EDIT_DATA],
    () => axiosInstance.get(ONBOARDING_EDIT_TASK_DATA),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useFetchOnboardingZones = (country_id) => {
  return useQuery(
    [ONBOARDING_ZONES, country_id],
    () => axiosInstance.get(GET_ONBOARDING_ZONES + country_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: !!country_id,
      select: (res) => res?.data?.data?.zone ?? [],
    }
  );
};
export const useFetchOnboardingStates = (zone_id) => {
  return useQuery(
    [ONBOARDING_STATES, zone_id],
    () => axiosInstance.get(GET_ONBOARDING_STATES + zone_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: !!zone_id,
      select: (res) => res?.data?.data?.state ?? [],
    }
  );
};
export const useFetchOnboardingCities = (state_id) => {
  return useQuery(
    [ONBOARDING_CITIES, state_id],
    () => axiosInstance.get(GET_ONBOARDING_CITIES + state_id),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      enabled: !!state_id,
      select: (res) => res?.data?.data?.city ?? [],
    }
  );
};

export const useFetchOnboardingLogTypes = () => {
  return useQuery(
    [GET_ONBOARDING_LOG_TYPES],
    () => axiosInstance.get(ONBOARDING_LOG_TYPES),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data?.data?.log_type ?? [],
    }
  );
};

// ---

export const usePostOnboardingTaskAnalytics = (payload) => {
  return useQuery(
    [POST_ONBOARDING_TASK_ANALYTICS, payload.type],
    () => axiosInstance.post(ONBOARDING_TASK_ANALYTICS, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data ?? {},
    }
  );
};
export const usePostOnboardingMediumAnalytics = (payload) => {
  return useQuery(
    [POST_ONBOARDING_MEDIUM_ANALYTICS, payload.type],
    () => axiosInstance.post(ONBOARDING_SEARCH_MEDIUM_ANALYTICS, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data ?? {},
    }
  );
};

export const usePostOnboardingCallAnalytics = (payload) => {
  return useQuery(
    [POST_ONBOARDING_CALL_ANALYTICS, payload.type],
    () => axiosInstance.post(ONBOARDING_CALL_ANALYTICS, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data ?? {},
    }
  );
};

export const usePostOnboardingCallLogAnalytics = (payload) => {
  return useQuery(
    [POST_ONBOARDING_CALL_LOG_ANALYTICS, payload.type],
    () => axiosInstance.post(ONBOARDING_CALL_LOG_ANALYTICS, payload),
    {
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
      select: (res) => res?.data ?? {},
    }
  );
};
