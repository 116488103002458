import React, { memo } from "react";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../components/dropdowns";

function DashboardDropdown({
  constantFilterOption,
  handleDropdownChange,
  type,
}) {
  return (
    <Dropdown className="d-flex justify-content-center">
      <Dropdown.Toggle
        variant="text-secondary text-capitalize dropdown-toggle"
        as={CustomToggle}
      >
        {type}
      </Dropdown.Toggle>
      <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
        {constantFilterOption.map((item, index) => (
          <Dropdown.Item
            key={index}
            href="#"
            className="text-capitalize"
            onClick={() => {
              handleDropdownChange(item);
            }}
          >
            {item}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default memo(DashboardDropdown);
