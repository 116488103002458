import React, { useState, useEffect, useCallback, memo } from "react";
import { Row, Form, FormCheck, Col } from "react-bootstrap";
import { getValuesByKey } from "../../../../../common/utils/utils";
import UserDemograpicFilter from "./user-demographic-filter";

const innitialUserWhoDidFilterData = [
  {
    id: 1,
    name: "",
    operator: "",
    clause: "",
    value: "",
  },
];
const innitialFilterData = [
  {
    id: 1,
    name: "",
    operator: "",
    clause: "",
    value: "",
  },
];

const AND = "and";
const OR = "or";
function UserDemograpicForm({
  filterData = {},
  onFromDataChange,
  isEditMode = false,
  editData = "",
}) {
  const [firstFilterData, setFirstFilterData] = useState([]);
  const [secondFilterData, setSecondFilterData] = useState([]);
  const [segemntFilter, setSegemntFilter] = useState(innitialFilterData);
  const [selectedOperator, setSelectedOperator] = useState(OR);

  useEffect(() => {
    console.log("edit data ", editData);
    if (isEditMode && editData?.length > 0) {
      setSegemntFilter(editData);
      setSelectedOperator(editData[0]?.operator);
    }
  }, [editData, isEditMode]);

  useEffect(() => {
    if (typeof filterData == "object" && Object.keys(filterData).length > 0) {
      setFirstFilterData(Object.keys(filterData));
    }
  }, [filterData]);

  const onFirstFilterSelect = (e) => {
    const { value } = e.target;
    const data = getValuesByKey(filterData, value);
    if (data && data?.length > 0) {
      console.log("data", data[0]?.length, data, data?.length);
      setSecondFilterData(data[0]);
    }
  };

  const onAddFilterClick = () => {
    console.log("add filter");
  };

  const handleCreateForm = (e) => {
    console.log("handleCreateForm", segemntFilter);
    e.preventDefault();
    setSegemntFilter([
      ...segemntFilter,
      {
        id: segemntFilter.length + 1,
        name: "",
        operator: "",
        value: "",
        clause: "",
      },
    ]);
  };

  const onDeleteFilter = useCallback(
    (id) => {
      // alert("delete click" + id);
      const resultItems = segemntFilter.filter((el) => el.id !== id);
      console.log("resultItems", segemntFilter, resultItems);
      if (resultItems.length > 0) {
        setSegemntFilter(resultItems);
      }
    },
    [segemntFilter]
  );

  function replaceObjectInArray(targetObject, objectArray, modifiedObject) {
    for (let i = 0; i < objectArray.length; i++) {
      if (isEqual(targetObject, objectArray[i])) {
        objectArray[i] = modifiedObject;
        break;
      }
    }
    return objectArray;
  }

  function isEqual(obj1, obj2) {
    // Compare the properties of the two objects
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  //
  const onselectFilter = useCallback(
    (data) => {
      console.group("onselectfilter");
      const filterData = segemntFilter.filter((el) => el.id === data.id);
      const updatedArray = replaceObjectInArray(
        filterData[0],
        segemntFilter,
        data
      );
      const tempSegment = [...updatedArray];

      console.log("data", data);
      console.log("filter array", filterData);
      console.log("updated array", updatedArray);
      console.log("temp data", tempSegment);
      onFromDataChange(tempSegment);
      setSegemntFilter(tempSegment);

      console.groupEnd("onselectfilter");
    },
    [segemntFilter]
  );

  const resetFrom = () => {
    setSegemntFilter(innitialFilterData);
    // if (onReset) {
    //   onReset();
    // }
  };

  function updateFilterOperator(key, filter, value) {
    return filter.map((item) => {
      return {
        ...item,
        [key]: value,
      };
    });
  }

  const onToogleOperator = (e) => {
    console.group("toogle operator");
    const { value } = e.target;
    if (selectedOperator == AND) {
      const updatedFilter = updateFilterOperator("operator", segemntFilter, OR);
      console.log("updated", updatedFilter);
      setSegemntFilter(updatedFilter);
      setSelectedOperator(OR);
    } else {
      const updatedFilter = updateFilterOperator(
        "operator",
        segemntFilter,
        AND
      );
      setSegemntFilter(updatedFilter);
      setSelectedOperator(AND);
    }

    console.groupEnd("toogle operator");
  };

  return (
    <>
      <div className="mb-3">
        <Row>
          <Col lg={6} className="mt-2 float-start">
            <h6 className="mb-0">User Demographic:</h6>
          </Col>
          <Col lg={6} className="mt-2 float-start">
            <Col lg={12} className="d-flex justify-content-end mt-2">
              {/* filter section */}
              {segemntFilter?.length > 1 && (
                <div className="d-flex flex-row gap-3 ">
                  <Form.Check className="fs-6 form-switch">
                    <FormCheck.Input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name={AND}
                      onChange={onToogleOperator}
                      checked={selectedOperator == AND}
                    />
                    <FormCheck.Label
                      className="form-check-label text-black lh-base"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      And
                    </FormCheck.Label>
                  </Form.Check>
                  <Form.Check className="fs-6 form-switch">
                    <FormCheck.Input
                      className="form-check-input"
                      type="checkbox"
                      name={OR}
                      id="flexSwitchCheckChecked"
                      checked={selectedOperator == OR}
                      onChange={onToogleOperator}
                    />
                    <FormCheck.Label
                      className="form-check-label text-black lh-base"
                      htmlFor="flexSwitchCheckChecked"
                    >
                      Or
                    </FormCheck.Label>
                  </Form.Check>
                </div>
              )}
              {/** delete section */}
            </Col>
          </Col>
        </Row>
        {segemntFilter?.length > 0 &&
          segemntFilter.map((seg, ind) => (
            <>
              <UserDemograpicFilter
                index={ind}
                selectedJson={segemntFilter}
                id={seg.id}
                filterData={filterData}
                onDeleteItem={onDeleteFilter}
                onCreateBtnClick={handleCreateForm}
                onItemSelect={onselectFilter}
                isDeleteBtn={ind > 0 && segemntFilter?.length - 1 == ind}
                isAddBtn={ind == segemntFilter.length - 1 ? true : false}
              />
            </>
          ))}
      </div>
    </>
  );
}

export default memo(UserDemograpicForm);
