import React from "react";

export default function MediaRenderer({ url }) {
  if (!url) {
    return <>No Attchment found</>;
  }
  // Function to determine the media type based on the file extension
  const getMediaType = (url) => {
    const extension = url.split(".").pop();
    switch (extension) {
      case "mp3":
        return "audio";
      case "mp4":
        return "video";
      case "jpg":
      case "jpeg":
      case "png":
        return "image";
      default:
        return "unknown";
    }
  };

  const mediaType = getMediaType(url);

  return (
    <div>
      {mediaType === "audio" && (
        <audio controls>
          <source src={url} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
      {mediaType === "video" && (
        <video controls width="600">
          <source src={url} type="video/mp4" />
          Your browser does not support the video element.
        </video>
      )}
      {mediaType === "image" && (
        <img src={url} alt="media" width={300} height={200} />
      )}
      {mediaType === "unknown" && <p>Unsupported media type</p>}
    </div>
  );
}
