import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Titlebar from "../components/Titlebar";
import { findIcon } from "../../../../dashboard/icons";
import CountCard from "../components/CountCard";
import SummeryHeaderContent from "../components/SummeryHeaderContent";
import Timeline from "../components/Timeline";
import LivePolls from "../components/LivePolls";
import EventCompleted from "../components/EventCompleted";
import {
  useGetEngagementReport,
  useGetSummaryContentInfo,
} from "../../../../../queries/query-hooks/workspace/events-hook";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import ContentInfoSkeleton from "../../../../../skeleton/workspace/clirnet/events/ContentInfoSkeleton";
import TimelineSkeleton from "../../../../../skeleton/workspace/clirnet/events/TimelineSkeleton";
import CardNotFound from "../components/CardNotFound";
import CountCardSkeleton from "../../../../../skeleton/workspace/clirnet/events/CountCardSkeleton";

const Summary = () => {
  const { project_id } = useParams();

  const { data: summaryContentInfo, isLoading: isSummaryContentInfoLoading } =
    useGetSummaryContentInfo(project_id);

  const { data: engagementReport, isLoading: isEngagementReport } =
    useGetEngagementReport(project_id);

  const eventCompletionStatus =
    summaryContentInfo?.project_detail?.event_completion_status;

  const projectStartDate = dayjs(
    summaryContentInfo?.project_detail?.project_start_date
  ).format("DD-MM-YYYY");

  const countCardData = [
    {
      text: "Touchpoint given",
      count: summaryContentInfo?.event_details?.touchpoint_given ?? "---",
      icon: findIcon("TouchPoint", "dual-tone", 42),
      bgPrimary: true,
    },
    {
      text: "Registration recieved",
      count: summaryContentInfo?.event_details?.total_registration ?? "---",
      icon: findIcon("Registration", "dual-tone", 42),
      bgPrimary: false,
    },
    {
      text: "Creatives",
      count: summaryContentInfo?.event_details?.hcp_attended ?? "---",
      icon: findIcon("ImpressionDelivered", "dual-tone", 34),
      bgPrimary: true,
    },
    {
      text: "Campaigns",
      count: summaryContentInfo?.event_details?.avg_duration ?? "---",
      icon: findIcon("StopWatch", "dual-tone", 42),
      bgPrimary: false,
    },
  ];

  return (
    <div>
      <Titlebar
        title={"Summary"}
        projectType={"Events"}
        projectStartDate={projectStartDate}
        isLoading={isSummaryContentInfoLoading}
      />
      <Card>
        <Card.Body>
          <Row>
            <Col md={8} sm={12} className="pe-md-2 mb-4 mb-md-0">
              {!isSummaryContentInfoLoading ? (
                <SummeryHeaderContent
                  summaryContentEventDetails={summaryContentInfo?.event_details}
                />
              ) : (
                <ContentInfoSkeleton />
              )}
            </Col>
            <Col md={4} sm={12}>
              <div className="d-flex flex-column gap-3">
                {!isSummaryContentInfoLoading ? (
                  countCardData?.map((item, index) => {
                    return (
                      <div key={index} lg={4} md={4} sm={12}>
                        <CountCard
                          key={`${index}-summery-events-counts`}
                          text={item.text}
                          count={item?.count || "---"}
                          icon={item.icon}
                          onclickPath={item.onClickPath}
                          bgPrimary={item.bgPrimary}
                        />
                      </div>
                    );
                  })
                ) : (
                  <CountCardSkeleton />
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {eventCompletionStatus === 0 ? (
        <Card>
          <Card.Body className="d-flex flex-column">
            {!isEngagementReport && engagementReport?.length > 0 ? (
              <Timeline engagementReport={engagementReport} />
            ) : isEngagementReport ? (
              <TimelineSkeleton />
            ) : (
              <CardNotFound
                title={"User Engagement"}
                isTitleCenter={true}
                height={360}
              />
            )}
          </Card.Body>
        </Card>
      ) : null}

      {eventCompletionStatus === 1 ? (
        <Card>
          <Card.Body>
            <LivePolls
              session_id={summaryContentInfo?.event_details?.session_id}
              eventType={summaryContentInfo?.project_detail?.event_type}
            />
          </Card.Body>
        </Card>
      ) : null}

      {eventCompletionStatus === 2 ? (
        <EventCompleted
          session_id={summaryContentInfo?.event_details?.session_id}
          eventType={summaryContentInfo?.project_detail?.event_type}
        />
      ) : null}
    </div>
  );
};

export default Summary;
