import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { findIcon } from "../../../views/dashboard/icons";
import Select from "react-select";
import {
  TARGET_SELF_TEST,
  RAISE_TICKET,
} from "../../../api/endpoints/content-studio-endpoints";
import postServiceAxios from "../../../api/services/post-service-axios";
import { toast } from "react-hot-toast";

const options = [
  { value: "Template Issue", label: "Template Issue" },
  { value: "Content Issue", label: "Content Issue" },
  { value: "Delivery Issue", label: "Delivery Issue" },
  { value: "", label: "None" },
];
const SpamScoreModal = ({
  variations,
  show,
  modalClose,
  channelStatus = {},
  isSpamScoreDone,
  variationId,
  modalStep = 0,
  loaderMessage = "",
  progressMessage = "",
  content_studio_id = 0,
}) => {
  const [step, setStep] = useState(1);
  const [allCheck, setAllCheck] = useState([]);
  const [isDisable, setIsDisable] = useState({
    whatsapp: false,
    email: false,
    sms: false,
  });
  const [selectedIsuue, setSelectedIsuue] = useState({
    whatsapp: "",
    email: "",
    sms: "",
  });
  const [checkStataus, setCheckStatus] = useState({
    whatsapp: false,
    sms: false,
    email: false,
  });
  const whatsappSelectRef = useRef(null);
  const emailSelectRef = useRef(null);
  const smsSelectRef = useRef(null);

  useEffect(() => {
    setStep(modalStep);
  }, [modalStep]);

  useEffect(() => {
    console.log("checkStataus", checkStataus);
    if (checkStataus?.whatsapp && checkStataus?.email && checkStataus?.sms) {
      setStep(3);
    }
  }, [checkStataus]);

  const receivedValidator = (e, type) => {
    // console.log("issue type", type);
    if (e.target.value === "yes") {
      setAllCheck([...allCheck, 1]);
      setIsDisable({
        ...isDisable,
        [type]: true,
      });
      // clearSelectOption(type);
    } else {
      setAllCheck(allCheck.filter((i) => i != 1));
      setIsDisable({
        ...isDisable,
        [type]: false,
      });
    }
  };

  const submitSpamScAndSelfTest = () => {
    setStep(1);
    modalClose();
    isSpamScoreDone(variationId, true);
    // alert('came')
  };

  function generateTemplatePayload(input) {
    const templates = {};
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        templates[key] = input[key].id;
      }
    }
    return { templates };
  }

  const onTargetSelfTest = async () => {
    if (variations && variations?.length > 0 && variationId >= 0) {
      let templatesData = generateTemplatePayload(
        variations[variationId]?.templates
      );
      const variationData = {
        variations: [
          {
            variation_name: variations[variationId]?.variation_name,
            ...templatesData,
          },
        ],
      };

      try {
        const response = await postServiceAxios(
          `${TARGET_SELF_TEST}/${content_studio_id}`,
          variationData
        );
        console.log("self tets resp:", response);
        if (response.data.success) {
          setStep(4);
        }
      } catch (error) {
        console.log("error", error);
        // callback("error");
      }
    } else {
      console.log("variations:", variations);
    }
  };

  const onRaiseTicketClick = async () => {
    const payload = {
      cotent_studio_id: content_studio_id,
    };

    try {
      const response = await postServiceAxios(RAISE_TICKET, payload);
      console.log("self tets resp:", response);
      if (response.data.success) {
        toast.success(
          `Ticket Raise Successfully For ${variations[variationId]?.templates}`
        );
      }
    } catch (error) {
      console.log("error", error);
      // callback("error");
    }
  };

  return (
    <>
      <Modal
        show={true}
        // show={show}
        onHide={modalClose}
        size={step == 4 ? "md" : "sm"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          {step == 1 && (
            <>
              <div className="d-flex flex-column">
                {loaderMessage ? (
                  <h6 className="text-center">{loaderMessage}</h6>
                ) : (
                  <h6 className="text-center">Please Wait ...</h6>
                )}

                <div className="d-flex justify-content-center gap-3 mt-3">
                  {channelStatus.whatsapp && (
                    <div
                      className={`d-inline-block avatar-60 rounded-3 flex-shrink-0 bg-soft-success position-relative`}
                    >
                      {findIcon("Whatsapp", "dual-tone", "32")}
                    </div>
                  )}
                  {channelStatus.email && (
                    <div
                      className={`d-inline-block avatar-60 rounded-3 flex-shrink-0 bg-soft-warning`}
                    >
                      {findIcon("Email", "dual-tone", "32")}
                    </div>
                  )}
                  {channelStatus.sms && (
                    <div
                      className={`d-inline-block avatar-60 rounded-3 flex-shrink-0 bg-soft-info`}
                    >
                      {findIcon("Message-2", "dual-tone", "32")}
                    </div>
                  )}
                </div>

                <div className="d-flex gap-2 justify-content-center mt-3">
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="success"
                  />
                  <Spinner
                    style={{ animationDelay: "0.5s" }}
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="warning"
                  />
                  <Spinner
                    style={{ animationDelay: "1s" }}
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="info"
                  />
                </div>
                {progressMessage && (
                  <p className="mt-3 text-center">{progressMessage}</p>
                )}
              </div>
            </>
          )}
          {step == 2 && (
            <div className="d-flex flex-column gap-3">
              <div className="text-center">
                <div
                  className={`m-auto d-inline-block avatar-80 rounded-pill flex-shrink-0 bg-soft-danger`}
                >
                  {findIcon("Exclamation Triangle", "dual-tone", "44")}
                </div>
                <h4 className="text-danger mb-0 mt-3">Failed</h4>
              </div>
              <Button onClick={onRaiseTicketClick} className="flex-grow-1">
                Raise Ticket
              </Button>
            </div>
          )}
          {step == 3 && (
            <>
              <div className="d-flex flex-column gap-3">
                <h6 className="text-center">Target Self Test</h6>
                <div className="text-center d-flex gap-3 justify-content-center">
                  <div
                    className={`d-inline-block avatar-60 rounded-3 flex-shrink-0 bg-soft-success`}
                  >
                    {findIcon("Whatsapp", "dual-tone", "32")}
                  </div>
                  <div
                    className={`d-inline-block avatar-60 rounded-3 flex-shrink-0 bg-soft-warning`}
                  >
                    {findIcon("Email", "dual-tone", "32")}
                  </div>
                  <div
                    className={`d-inline-block avatar-60 rounded-3 flex-shrink-0 bg-soft-info`}
                  >
                    {findIcon("Message-2", "dual-tone", "32")}
                  </div>
                </div>
                <Button onClick={onTargetSelfTest} className="flex-grow-1">
                  Send
                </Button>
              </div>
            </>
          )}
          {step == 4 && (
            <>
              <div className="d-flex flex-column gap-3">
                {" "}
                <Row className="text-center d-flex gap-2">
                  <Col xs={12}>
                    <div className="bg-soft-secondary p-3 d-flex align-items-center rounded-3 justify-content-evenly">
                      <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                        <h2 className="fs-5 text-muted">Channel</h2>
                      </div>

                      {/* <div className="d-flex justify-content-center align-items-center gap-3"> */}
                      <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                        <h2 className="fs-5 text-muted">Received</h2>
                      </div>
                      <div className="w-auto w-md-50 d-flex align-items-center justify-content-center">
                        <h2 className="fs-5 text-muted">Cause</h2>
                      </div>

                      {/* </div> */}
                    </div>
                  </Col>
                  {channelStatus.whatsapp && (
                    <Col xs={12}>
                      <div className="bg-soft-success p-2 d-flex align-items-center rounded-3 justify-content-evenly">
                        <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                          <div
                            className={`d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-success`}
                          >
                            {findIcon("Whatsapp", "dual-tone", "24")}
                          </div>
                        </div>
                        <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <Form.Check className="form-check-inline m-0">
                              <FormCheck.Input
                                type="radio"
                                name="Wpradios"
                                className=""
                                id="exampleRadio1"
                                value="yes"
                                onChange={(e) =>
                                  receivedValidator(e, "whatsapp")
                                }
                              />
                              <FormCheck.Label
                                className=""
                                htmlFor="exampleRadio1"
                              >
                                Yes
                              </FormCheck.Label>
                            </Form.Check>
                            <Form.Check className="form-check-inline m-0">
                              <FormCheck.Input
                                type="radio"
                                name="Wpradios"
                                className=""
                                id="exampleRadio2"
                                value="no"
                                onChange={(e) =>
                                  receivedValidator(e, "whatsapp")
                                }
                              />
                              <FormCheck.Label
                                className=""
                                htmlFor="exampleRadio2"
                              >
                                No
                              </FormCheck.Label>
                            </Form.Check>
                          </div>
                        </div>

                        <div className="w-auto w-md-50 d-flex align-items-center">
                          <Select
                            ref={whatsappSelectRef}
                            className="react-select-customize w-100"
                            name="Template"
                            options={options}
                            placeholder={
                              isDisable.whatsapp ? "None" : "Not Received"
                            }
                            isDisabled={isDisable.whatsapp}
                            onChange={(e) => {
                              //     console.log("issue change", e);
                              setSelectedIsuue({
                                ...selectedIsuue,
                                whatsapp: e.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                  {channelStatus.email && (
                    <Col xs={12}>
                      <div className="bg-soft-warning p-2 d-flex align-items-center rounded-3 justify-content-evenly">
                        <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                          <div
                            className={`d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-warning`}
                          >
                            {findIcon("Email", "dual-tone", "24")}
                          </div>
                        </div>

                        {/* <div className="d-flex justify-content-center align-items-center gap-3"> */}
                        <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <Form.Check className="form-check-inline m-0">
                              <FormCheck.Input
                                type="radio"
                                name="Emailradios"
                                className=""
                                id="exampleRadio3"
                                value="yes"
                                onChange={(e) => receivedValidator(e, "email")}
                              />
                              <FormCheck.Label
                                className=""
                                htmlFor="exampleRadio3"
                              >
                                Yes
                              </FormCheck.Label>
                            </Form.Check>
                            <Form.Check className="form-check-inline m-0">
                              <FormCheck.Input
                                type="radio"
                                name="Emailradios"
                                className=""
                                id="exampleRadio4"
                                value="no"
                                onChange={(e) => receivedValidator(e, "email")}
                              />
                              <FormCheck.Label
                                className=""
                                htmlFor="exampleRadio4"
                              >
                                No
                              </FormCheck.Label>
                            </Form.Check>
                          </div>
                        </div>
                        <div className="w-auto w-md-50 d-flex align-items-center">
                          <Select
                            ref={emailSelectRef}
                            className="react-select-customize w-100"
                            name="Template"
                            options={
                              isDisable.email
                                ? [{ value: "", label: "None" }]
                                : options
                            }
                            placeholder={
                              isDisable.email ? "None" : "Not Received"
                            }
                            isDisabled={isDisable.email}
                            onChange={(e) =>
                              setSelectedIsuue({
                                ...selectedIsuue,
                                email: e.value,
                              })
                            }
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </Col>
                  )}
                  {channelStatus.sms && (
                    <Col xs={12}>
                      <div className="bg-soft-info p-2 d-flex align-items-center rounded-3 justify-content-evenly">
                        <div className="w-auto w-md-25 d-flex align-items-center justify-content-center">
                          <div
                            className={`d-inline-block avatar-40 rounded-3 flex-shrink-0 bg-soft-info`}
                          >
                            {findIcon("Message-2", "dual-tone", "24")}
                          </div>
                        </div>

                        {/* <div className="d-flex justify-content-center align-items-center gap-3"> */}
                        <div className="d-flex align-items-center justify-content-center w-auto w-md-25">
                          <div className="d-flex align-items-center justify-content-center gap-2">
                            <Form.Check className="form-check-inline m-0">
                              <FormCheck.Input
                                type="radio"
                                name="Smsradios"
                                className=""
                                id="exampleRadio5"
                                value="yes"
                                onChange={(e) => receivedValidator(e, "sms")}
                              />
                              <FormCheck.Label
                                className=""
                                htmlFor="exampleRadio5"
                              >
                                Yes
                              </FormCheck.Label>
                            </Form.Check>
                            <Form.Check className="form-check-inline m-0">
                              <FormCheck.Input
                                type="radio"
                                name="Smsradios"
                                className=""
                                id="exampleRadio6"
                                value="no"
                                onChange={(e) => receivedValidator(e, "sms")}
                              />
                              <FormCheck.Label
                                className=""
                                htmlFor="exampleRadio6"
                              >
                                No
                              </FormCheck.Label>
                            </Form.Check>
                          </div>
                        </div>
                        <div className="w-auto w-md-50 d-flex align-items-center">
                          <Select
                            ref={smsSelectRef}
                            className="react-select-customize w-100"
                            name="Template"
                            options={options}
                            placeholder={
                              isDisable.sms ? "None" : "Not Received"
                            }
                            isDisabled={isDisable.sms}
                            onChange={(e) =>
                              setSelectedIsuue({
                                ...selectedIsuue,
                                sms: e.value,
                              })
                            }
                          />
                        </div>

                        {/* </div> */}
                      </div>
                    </Col>
                  )}
                </Row>
                <Form.Floating className="custom-form-floating w-100">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                  ></textarea>
                  <Form.Label htmlFor="fname">Write your comments</Form.Label>
                </Form.Floating>
                <Button
                  onClick={submitSpamScAndSelfTest}
                  className="flex-grow-1"
                  // channelStatus.message
                  disabled={
                    (isDisable.whatsapp ||
                      selectedIsuue.whatsapp != "" ||
                      !channelStatus.whatsapp) &&
                    (isDisable.email ||
                      selectedIsuue.email != "" ||
                      !channelStatus.email) &&
                    (isDisable.sms ||
                      selectedIsuue.sms != "" ||
                      !channelStatus.sms)
                      ? false
                      : true
                  }
                >
                  {!(
                    selectedIsuue.whatsapp ||
                    selectedIsuue.email ||
                    selectedIsuue.sms
                  ) ||
                  (isDisable.whatsapp && isDisable.email && isDisable.sms)
                    ? "Approve template"
                    : "Raise Ticket & Send"}
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SpamScoreModal;
