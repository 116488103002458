import React from "react";

const ChannelProfile = () => {
  return (
    <>
   hi
    </>
  );
};

export default ChannelProfile;