import { memo, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import withAuthorization from "../../../../components/hoc/with-authorization";
import Plotly from "plotly.js-dist";

import { Link, useSearchParams } from "react-router-dom";
import Select from "react-select";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { findIcon } from "../../../dashboard/icons";
import ColumnTextWrapper from "../../../../components/common-components/column-text-wrapper";
import FilePreviewModal from "../../../../components/partials/common/file-preview-modal";

import axios from "axios";
let baseUrl = "https://shivamroy2.jupyter.clirdev.com/";
const Research = () => {
  const svg1 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      class="icon-32"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        d="M22.4541 11.3918C22.7819 11.7385 22.7819 12.2615 22.4541 12.6082C21.0124 14.1335 16.8768 18 12 18C7.12317 18 2.98759 14.1335 1.54586 12.6082C1.21811 12.2615 1.21811 11.7385 1.54586 11.3918C2.98759 9.86647 7.12317 6 12 6C16.8768 6 21.0124 9.86647 22.4541 11.3918Z"
        fill="currentColor"
      ></path>
      <path
        d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
        fill="white"
      ></path>
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill="currentColor"
      ></path>
      <mask
        id="mask0_18_1017"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="6"
        height="6"
      >
        <path
          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
          fill="currentColor"
        ></path>
      </mask>
      <g mask="url(#mask0_18_1017)">
        <path
          opacity="0.89"
          d="M13.5 12C14.3284 12 15 11.3284 15 10.5C15 9.67157 14.3284 9 13.5 9C12.6716 9 12 9.67157 12 10.5C12 11.3284 12.6716 12 13.5 12Z"
          fill="white"
          fill-opacity="0.6"
        ></path>
      </g>
    </svg>
  );
  const fileSvg = (
    <svg
      width="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
        fill="currentColor"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");
  const [specialities, setSpecialities] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [item, setItem] = useState({ title: "", speciality: "" });
  const [htmlContent, setHtmlContent] = useState("");
  const selectedSpeciality = searchParams.get("speciality");
  const query = searchParams.get("query");
  const setSelectedSpeciality = (speciality) => {
    if (selectedSpeciality === speciality) return;
    if (speciality) {
      setSearchParams((prev) => {
        prev.delete("query");
        prev.set("speciality", speciality);
        return prev;
      });
    } else {
      setSearchParams((prev) => {
        prev.delete("speciality");
        return prev;
      });
    }
  };
  const handleChange = (e) => setInput(e.target.value);
  const setQuery = (queryInput) => {
    if (queryInput) {
      setSearchParams((prev) => {
        prev.set("query", queryInput);
        return prev;
      });
    } else {
      setSearchParams((prev) => {
        prev.delete("query");
        return prev;
      });
    }
  };
  const onKeyUp = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      setQuery(input);
    }
  };

  const handleChangeParams = async (selectedValue, query) => {
    showBackdrop();
    console.log("Selected speciality:", selectedValue);

    let apiUrl;
    if (query) {
      apiUrl = `unity_search?speciality_id=${
        selectedValue ? selectedValue : 0
      }&query=%27${query}%27`;
    } else if (selectedValue) {
      apiUrl = `content?speciality_id=${selectedValue}`;
    } else {
      apiUrl = "all_content";
    }
    let url = await axios.get(baseUrl + apiUrl);
    if (url.data?.output && typeof url.data?.output === "object") {
      console.log(url.data);
      setHtmlContent("");
      let { traces, layout } = url?.data?.output;

      traces = traces.map((item) => ({
        name: item.cluster_name || item?.highlight,
        x: item.tsne_x,
        y: item.tsne_y,
        mode: item.mode,
        marker: {
          size: item.size,
        },
        text: item?.content_info?.map((_) => ({
          ..._,
          cluster_name: _.cluster_name || item.cluster_name || item?.highlight,
        })),
        hovertemplate:
          "<b>%{text.title}</b><br><br>" +
          "Content id: %{text.content_id}<br>" +
          "Content type: %{text.content_type_id}<br>" +
          "Cluster: %{text.cluster_name}<br>" +
          "<extra></extra>",
      }));

      // Plot the scatter plot
      Plotly.newPlot("scatterPlot", traces, layout);
      hideBackdrop();

      if (selectedValue || query) {
        // setSearchResults(url?.data?.output);
        apiUrl = `unity_dropdown?speciality_id=${
          selectedValue ? selectedValue : 0
        }${query ? `&query=%27${query}%27` : ""}`;
        url = await axios.get(baseUrl + apiUrl);
        setSearchResults(url.data?.output?.content);
        console.log("Search results:", url?.data?.output?.content);
      }
    } else if (url.data?.output && typeof url.data?.output === "string") {
      setHtmlContent(url.data?.output);
      document.getElementById("scatterPlot").innerHTML = "";
    }
  };
  async function fetchSpecialities() {
    try {
      let response = await axios.get(baseUrl + "speciality");
      let data = response?.data?.output;
      console.log("Specialities:", data);
      let toProceed = data && typeof data === "object" && data?.length > 0;
      if (toProceed) {
        data.sort((a, b) => {
          let nameA = a.speciality_name.toUpperCase(); // Convert names to uppercase
          let nameB = b.speciality_name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // Names are equal
          return 0;
        });
        setSpecialities(data);
      }
    } catch (error) {
      setSpecialities([]);
    }
  }
  useEffect(() => {
    fetchSpecialities();
  }, []);

  useEffect(() => {
    handleChangeParams(selectedSpeciality, query);
  }, [selectedSpeciality, query]);
  
  const onReset = () => {
    setQuery("");

    setSelectedSpeciality("");
  };
  const options = useMemo(() => {
    return specialities?.map((item) => {
      return {
        value: item?.speciality_id,
        label: item?.speciality_name,
      };
    });
  }, [specialities]);
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Header className="w-75 ms-auto">
              <div className="form-group input-group mb-0 search-input">
                {specialities?.length > 0 && (
                  <Select
                    options={options}
                    onChange={(e) => setSelectedSpeciality(e.value)}
                    styles={{
                      container: (base) => ({
                        ...base,
                        width: "18rem",
                        marginRight: "1rem",
                        height: "100%",
                      }),
                    }}
                  />
                )}
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={input}
                  onChange={handleChange}
                  onKeyUp={onKeyUp}
                />
                <span
                  className="input-group-text cursor-pointer"
                  onClick={() => setQuery(input)}
                >
                  <svg
                    width="20"
                    className="icon-20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </div>
              <span
                className="input-group-text ms-2 cursor-pointer"
                onClick={onReset}
              >
                {findIcon("Refresh", "dual-tone", 32)}
              </span>
            </Card.Header>
            <Card.Body className="overflow-hidden">
              <div id="scatterPlot"></div>
              {htmlContent && (
                <iframe
                  style={{ minHeight: "80vh", marginTop: "-5.4rem" }}
                  src={htmlContent}
                  // style="border:0px #ffffff none;"
                  name="myiFrame"
                  scrolling="no"
                  frameborder="1"
                  marginheight="0px"
                  marginwidth="0px"
                  height="400px"
                  width="600px"
                  className="w-100 border-0 mt-n5"
                  onLoad={hideBackdrop}
                  allowfullscreen
                ></iframe>
              )}
            </Card.Body>
          </Card>
        </Col>

        {searchResults?.length > 0 && (
          <Col lg="12">
            <Card>
              <Card.Header className="d-flex align-tems-center justify-content-between">
                <h4>Search Results</h4>
              </Card.Header>
              <div className="table-responsive">
                <table className="table table-borderless iq-file-manager-table mb-0">
                  <thead>
                    <tr className="border-bottom bg-transparent text-dark">
                      <th scope="col">Topic</th>
                      <th scope="col">Speciality</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <span className="avatar-40 bg-soft-primary rounded-pill">
                                {fileSvg}
                              </span>
                              <h6 className="mb-0 text-wrap">{item.title}</h6>
                            </div>
                          </td>
                          <td>
                            <ColumnTextWrapper
                              className="text-muted"
                              width={250}
                            >
                              {item.speciality}
                            </ColumnTextWrapper>
                          </td>

                          <td
                            onClick={() => {
                              setShow(true);
                              setItem({
                                title: item.title,
                                speciality: item?.speciality,
                              });
                            }}
                          >
                            <Link className="d-flex align-items-center text-danger">
                              <span className="btn-inner">{svg1}</span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        )}
      </Row>{" "}
      {show && (
        <FilePreviewModal
          createdOn={new Date()}
          mediaType="pdf"
          show={show}
          viewModalClose={() => setShow(false)}
          title={item.title}
          brand="Brand 1"
          createdBy="Super Admin"
          description={item.speciality}
          icon="https://storage.googleapis.com/cipla-cms-dev/icons/file_type/pdf.svg"
          file_url={
            "https://storage.googleapis.com/cipla-cms-dev/cipla/cluster-1/respiratory/sub-division-2/pdf/510_what_are_the_indications_and_contraindications_for_bariatric_surgery/1695221299_744941695221284_731.pdf"
          }
        />
      )}
    </>
  );
};

export default withAuthorization(memo(Research));
