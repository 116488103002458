import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Badge } from "react-bootstrap";

const HeaderPillFilters = ({
  titles = [],
  activeFilter,
  setActiveFilter,
  width = "30rem",
}) => {
  useEffect(() => {
    if (titles && titles.length > 0 && !!activeFilter) {
      setActiveFilter({
        index: 0,
        id: titles[0].id,
      });
    }
  }, []);

  function generateClassName(index) {
    if (index === activeFilter.index) {
      return "tertiary-bg-black-50 ms-0 me-2 fs-7 px-3 py-2 border-1 border-primary border border-opacity-25 w-auto fw-semibold";
    } else if (!("index" in activeFilter) && index === 0) {
      return "tertiary-bg-black-50 ms-0 me-2 fs-7 px-3 py-2 border-1 border-primary border border-opacity-25 w-auto fw-semibold";
    }

    return "ms-0 me-3 fs-7 px-3 py-2 border-1 border-secondary bg-opacity-50 text-black-50 mb-1 mb-md-0 w-auto fw-semibold";
  }

  return (
    // TODO:
    <div
      className="overflow-hidden d-slider1 flex-grow-1 me-0 me-md-0"
      style={{ width: width }}
    >
      <Swiper
        as="ul"
        className="p-0 m-0 mb-0 swiper-wrapper list-inline tableHeaderSwiper pb-1"
        modules={[Navigation]}
        data-aos="fade-up"
        data-aos-delay="700"
        slidesPerView="auto"
      >
        {titles &&
          titles?.map((title, index) => {
            return (
              <SwiperSlide
                className="cursor-pointer pb-1 pb-md-0"
                key={title?.event_id}
              >
                <Badge
                  pill
                  bg={`light fw-normal ${
                    index === activeFilter.index ? "text-primary" : ""
                  }`}
                  onClick={() =>
                    setActiveFilter({
                      index: index,
                      id: title?.event_id,
                    })
                  }
                  className={generateClassName(index)}
                >
                  {title.event_name}
                </Badge>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default HeaderPillFilters;
