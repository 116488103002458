import { memo, Fragment } from "react";
//React-bootstrap
import { Image, Button } from "react-bootstrap";
//Img
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../views/dashboard/icons";
import { useSelector } from "react-redux";
import { currentChannel } from "../../../../store/channel/channel.selector";
import Skeleton from "../../../../components/skeleton";
import { fallbackImages } from "../../../../common/constants/fallback-images";
import { stringToSlug } from "../../../../common/utils/utils";
import app_config from "../../../../common/data/app_config";

const DOCTUBE_LINK = app_config.doctube_url;
function Sidebarprofilecard({
  onBackButtonClick,
  previousPageName,
  logo,
  title,
  withSlug = false,
  slug,
  slugLink = "doctube.com",
  imgSize = "img-fluid",
  imgContainer,
  isLoading,
}) {
  const { redirectTo } = useRedirect();

  const channel = useSelector(currentChannel);
  const onChannelNameClick = () => {
    redirectTo("channel/dashboard/" + channel?.channel_id);
  };

  return (
    <div className="sidebar-profile-card mt-3 d-flex flex-column">
      {previousPageName && (
        <Button
          onClick={onBackButtonClick}
          variant="outline-light"
          className="text-dark px-0 flex-grow-1 text-start mb-4 rounded-3 d-flex align-items-center backto__prevPage"
        >
          <span className="flex-shrink-0 px-3 d-inline-flex justify-content-center align-items-center">
            {findIcon("Chevron Left", "outline", 20)}
          </span>
          <h5 className="mb-0 nav-link text-black fw-normal">
            <span className="small mb-1 d-block text-primary">Back to</span>
            {previousPageName}
          </h5>
        </Button>
      )}
      <div className="sidebar-profile-body">
        <div className="d-flex justify-content-center">
          <div
            className={`rounded-3 border border-primary p-1 channel__sidebar__imgContainer ${imgContainer}`}
          >
            {!isLoading && logo ? (
              <img
                src={logo}
                onError={(e) => (e.target.src = fallbackImages.noImage)}
                alt="1"
                className={`rounded ${imgSize}`}
              />
            ) : isLoading ? (
              <>
                <Skeleton height={50} width={60} variant="square" />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {!isLoading && title ? (
          <div className="sidebar-profile-detail mt-3">
            <h5 className="sidebar-profile-name one-line-clamp">{title}</h5>
            {withSlug && (
              <a
                className="sidebar-profile-name rounded-pill btn btn-primary"
                href={`${DOCTUBE_LINK}${stringToSlug(title)}`}
                target="_blank"
                title={`Click To Visit :${slugLink}`}
              >
                Visit Channel
              </a>
            )}
          </div>
        ) : isLoading ? (
          <span className="p-3 me-2">
            <Skeleton height={20} width={255} />
          </span>
        ) : (
          <></>
        )}

        {/* {!isLoading && title ? (
          <div className="sidebar-profile-detail mt-2">
            <a className="sidebar-profile-name text-muted text-decoration-underline" href={slugLink} target="_blank" title={slugLink}>{stringToSlug(title)}</a>
          </div>
        ) : isLoading ? <span className="p-3 me-2"><Skeleton height={20} width={255} /></span> : <></>} */}
      </div>
    </div>
  );
}

Sidebarprofilecard.displayName = "Sidebarprofilecard";
export default memo(Sidebarprofilecard);
