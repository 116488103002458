import React from "react";
import { useHcpDetails } from "../../../../../queries/query-hooks/hcp-profile-hook";
import { Card, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HcpMedicalSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpMedicalSkeleton";

const OnboardingInformation = ({ hcpDetail }) => {
  const transformSocialLinks = (input) => {
    if (!input) {
      return [];
    }
    const output = input.map((item) => ({
      platform: item.social_link_type.social_link_type_name,
      link: item.social_link,
    }));
    return output.length > 0 ? output : [];
  };

  const getHcpDocument = (alldata, value) => {
    const filteredData = alldata.filter(
      (item) => item.hcp_document_type === value
    );
    //console.log("filter data", filteredData);
    const output = filteredData.map((item) => item.hcp_document_path);
    return output;
  };

  // const { id: hcpId } = useParams();
  // //   const commonPayload = { hcp_id: hcpId };
  // const onSuccess = (response) => {
  //   //response
  //   //console.log("reponse", response);
  // };

  // const {
  //   data: hcpDetail,
  //   refetch: refetchHcpDetails,
  //   isLoading,
  // } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  return (
    <Card className="h-100 bg-soft-warning">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Onboarding Information</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">User Type:</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_type?.hcp_type_name
                ? hcpDetail?.hcp_to_type?.hcp_type_name
                : "NA"}
            </div>

            <div className="col-7 mb-3">Status:</div>
            <div className="col-5 mb-3">
              {hcpDetail?.hcp_onboarding_status?.value ?? "NA"}
            </div>
            <div className="col-7 mb-3">Medium:</div>
            <div className="col-5 mb-3">
              {hcpDetail?.hcp_medium?.value ?? "NA"}
            </div>
            <div className="col-7 mb-3"> Speciality Document :</div>
            <div className="col-4 mb-3">
              {" "}
              {hcpDetail?.hcp_to_document?.length > 0 &&
              getHcpDocument(hcpDetail?.hcp_to_document, "speciality")?.length >
                0
                ? getHcpDocument(hcpDetail?.hcp_to_document, "speciality").map(
                    (data) => (
                      <a
                        className=""
                        href={data ?? "javacript:void(0)"}
                        target="_blank"
                      >
                        View{" "}
                      </a>
                    )
                  )
                : "N/A"}
            </div>

            <div className="col-7 mb-3">Registration Document :</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_document?.length > 0 &&
              getHcpDocument(hcpDetail?.hcp_to_document, "registration")
                ?.length > 0
                ? getHcpDocument(
                    hcpDetail?.hcp_to_document,
                    "registration"
                  ).map((data) => (
                    <a
                      className=""
                      href={data ?? "javacript:void(0)"}
                      target="_blank"
                    >
                      View
                    </a>
                  ))
                : "N/A"}
            </div>

            <div className="col-7 mb-3">Other Document:</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_document?.length > 0 &&
              getHcpDocument(hcpDetail?.hcp_to_document, "other")?.length > 0
                ? getHcpDocument(hcpDetail?.hcp_to_document, "other").map(
                    (data) => (
                      <a
                        className=""
                        href={data ?? "javacript:void(0)"}
                        target="_blank"
                      >
                        View
                      </a>
                    )
                  )
                : "N/A"}
            </div>
            <div className="col-7 mb-3">Call Recording :</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_document?.length > 0 &&
              getHcpDocument(hcpDetail?.hcp_to_document, "recording")?.length >
                0
                ? getHcpDocument(hcpDetail?.hcp_to_document, "recording").map(
                    (data) => (
                      <a
                        className=""
                        href={data ?? "javacript:void(0)"}
                        target="_blank"
                      >
                        View
                      </a>
                    )
                  )
                : "N/A"}
            </div>

            <div className="col-7 mb-3">Social Links:</div>
            <div className="col-4 mb-3 ">
              {transformSocialLinks(hcpDetail?.hcp_to_social_links)?.length >
              0 ? (
                transformSocialLinks(hcpDetail?.hcp_to_social_links).map(
                  (data, ind) =>
                    data?.link && (
                      <li key={ind} title={data?.link}>
                        <a
                          className="text-start"
                          href={`${
                            data.link ? data.link : "javascript:void(0)"
                          }`}
                          target="_blank"
                        >
                          {data.platform}{" "}
                        </a>
                      </li>
                    )
                )
              ) : (
                <>NA</>
              )}
            </div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OnboardingInformation;
