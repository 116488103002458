import React from 'react'
import { useState } from "react";
import { Form } from "react-bootstrap";
function TableFilterDropDown({ column, options = [] }) {
    const { filterValue, preFilteredRows, setFilter } = column;
    const [selectedValue, setSelectedValue] = useState(filterValue || "");

    const handleFilterChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedValue(selectedValue);
        setFilter(selectedValue || "");
    };

    return (
        <Form.Select value={selectedValue} onChange={handleFilterChange}>
            <option value="">Select Filter</option>
            {options?.length > 0 &&
                options.map((item) => {
                    const { label, value } = item;
                    return (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    );
                })}
        </Form.Select>
    );
}

export default TableFilterDropDown;