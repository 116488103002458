import React from "react";
import Skeleton from "../../../../components/skeleton";

const QuestionAnalyticsSkeleton = () => {
  return (
    <div>
      <Skeleton height={50} width={"100%"} />
      <Skeleton height={70} width={"100%"} />

      <div className="d-flex gap-2 justify-content-center align-items-center">
        <Skeleton variant="circular" width={15} height={15} />
        <Skeleton variant="circular" width={15} height={15} />
        <Skeleton variant="circular" width={15} height={15} />
        <Skeleton variant="circular" width={15} height={15} />
      </div>
    </div>
  );
};

export default QuestionAnalyticsSkeleton;
