import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import Chart from "react-apexcharts";
import { dateFilterOptions } from "../../Constant/date-filter-constant";
import GraphTab from '../../components/common/GraphTab';
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";

function AnalyticssReach({ data: reachData, }) {
  const [payload, setPayload] = useState({
    type: dateFilterOptions.initial,
  });


  const { impression, ctr, views, total_ctr, total_impression, total_views } =
    reachData && Object.keys(reachData)?.length > 0
      ? reachData
      : {
        ctr: {},
        impression: {},
        views: {},
        total_ctr: 0,
        total_impression: 0,
        total_views: 0
      };

  function handleDropdownChange(type) {
    setPayload({
      ...payload,
      type,
    });
  }


  const prepareGraphData = (input) => {
    return input.reduce((acc, { count, level }) => ({
      series: [...acc.series, parseInt(count)],
      label: [...acc.label, level]
    }), { series: [], label: [] });
  }
  const impressionOptions = {
    series: [
      {
        name: "Impression Count",
        data: prepareGraphData(impression)?.series?.length > 0 ? prepareGraphData(impression).series : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    labels: prepareGraphData(impression)?.label?.length > 0 ? prepareGraphData(impression)?.label : [],

    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const impressionClickThroughOptions = {
    series: [
      {
        name: "Click Through Rate (%)",
        data: prepareGraphData(ctr)?.series?.length > 0 ? prepareGraphData(ctr).series : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    // markers: {
    //   size: [4, 7],
    // },
    labels: prepareGraphData(ctr)?.label?.length > 0 ? prepareGraphData(ctr)?.label : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      floating: true,
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const prepareViewsData = (input) => {
    return input.reduce((acc, { count, level }) => ({
      series: [...acc.series, parseInt(count)],
      label: [...acc.label, level]
    }), { series: [], label: [] });
  }

  const viewsOptions = {
    series: [
      {
        name: "View Count",
        data: prepareViewsData(views)?.series?.length > 0 ? prepareViewsData(views).series : [],
      },
    ],
    fill: {
      type: 'gradient',

      gradient: {
        shade: 'dark',
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: []
      }
    },
    chart: {
      // colors: GRAPH_FILL_COLOR,
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    // markers: {
    //   size: [4, 7],
    // },
    labels: prepareViewsData(views)?.label?.length > 0 ? prepareViewsData(views).label : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const graphTabs = [
    {
      eventKey: "first-gr", title: "Impressions", dataCount: `${total_impression ? parseInt(total_impression).toLocaleString() : 0}`, content: <>    <Card.Body className="p-0">
        <div>
          <div id="chart-gr">
            {impression && Object.values(impression)?.length > 0 && impressionOptions.series?.length > 0 && <Chart
              options={impressionOptions}
              series={impressionOptions.series}
              type="line"
              height={350}
            />}
          </div>
        </div>
      </Card.Body></>
    },
    {
      eventKey: "second-gr", title: "Click Through Rate", dataCount: `${total_ctr ? parseInt(Math.ceil(total_ctr)).toLocaleString() : 0}`, content: <> <Card.Body className="p-0">
        <div>
          <div id="chart-watch">
            {impressionClickThroughOptions?.series?.length > 0 && <Chart
              options={impressionClickThroughOptions}
              series={impressionClickThroughOptions.series}
              type="line"
              height={350}
            />}
          </div>
        </div>
      </Card.Body></>
    },
    {
      eventKey: "ctr-video-reach-graph", dataCount: `${total_views ? parseInt(Math.ceil(total_views)).toLocaleString() : 0}`, title: "Views", content: <> <Card.Body className="p-0">
        <div>
          <div id="chart-watch">
            <Chart
              options={viewsOptions}
              series={viewsOptions.series}
              type="line"
              height={350}
            />
          </div>
        </div>
      </Card.Body></>
    }
  ]


  return (
    <Card>
      <Card.Body >
        <GraphTab tabs={graphTabs} />
      </Card.Body>
    </Card>
  );
}

export default AnalyticssReach;
