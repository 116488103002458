import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  InputGroup,
} from "react-bootstrap";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useDispatch } from "react-redux";
import { updateChannelInput } from "../../../../../../../store/channel/channel.action";
import {
  findValueByField,
  stringToSlug,
} from "../../../../../../../common/utils/utils";
import app_config from "../../../../../../../common/data/app_config";
import dayjs from "dayjs";
import { useCheckChannelName } from "../../../../../../../queries/query-hooks/workspace/channel-video-hook";
import useDebounce from "../../../../../../../components/custom-hooks/use-debounce";
import useBackdrop from "../../../../../../../components/custom-hooks/use-backdrop";

const DOCTUBE_LINK = app_config.doctube_url;
function CustomizationBasicInfo({
  data,
  activeTab,
  eventKey,
  setDisabledSubmit,
}) {
  const dispatch = useDispatch();

  const channelUrlInputRef = useRef(null);
  const gaTagInputRef = useRef(null);
  const [inputs, setInputs] = useState({
    name: "",
    handle: "",
    description: "",
    channelUrl: "",
    customGaTag: "",
    enableTracking: false,
  });
  const [searchEnabled, setSearchEnabled] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [isChannelNameExist, setIsChannelNameExist] = useState(false);
  const channelTitleToCheck = useDebounce(inputs.name, 2000);
  const { data: channelNameData, isLoading } = useCheckChannelName(
    channelTitleToCheck,
    searchEnabled && channelTitleToCheck ? true : false
  );

  const channelCreatedDate = dayjs(data?.created_at);
  const currentDate = dayjs();
  const isEditNameDisabled = currentDate.isAfter(
    channelCreatedDate.add(14, "day")
  );

  useEffect(() => {
    if (channelNameData) {
      setIsChannelNameExist(!channelNameData.success);
      setDisabledSubmit(!channelNameData.success);
    }
  }, [channelNameData]);

  useEffect(() => {
    if (isLoading && searchEnabled) {
      setDisabledSubmit(true);
      showBackdrop();
    } else {
      hideBackdrop();
    }
  }, [isLoading, searchEnabled]);

  const handleInputChange = (e) => {
    console.log("e", e);
    const { name, value, type, checked } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const copyToChannelClipboard = () => {
    if (channelUrlInputRef.current) {
      channelUrlInputRef.current.select();
      document.execCommand("copy");
    }
  };

  const copyToGaTagClipboard = () => {
    if (gaTagInputRef.current) {
      gaTagInputRef.current.select();
      document.execCommand("copy");
    }
  };

  const channelTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      You can view the URL associated with your channel at doctube.com
    </Tooltip>
  );

  const handleTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      handle tooltip
    </Tooltip>
  );

  const nameTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Choose a channel name that represents you changes are visible only on
      Doctube
    </Tooltip>
  );

  useEffect(() => {
    let settings = findValueByField(data?.content_to_attr, "settings");
    if (settings) {
      settings = JSON.parse(settings);
    }

    setInputs({
      name: data?.content_title,
      channelSlug:
        findValueByField(data?.content_to_attr, "channelSlug") ||
        stringToSlug(data?.content_title),
      handle: findValueByField(data?.content_to_attr, "channelHandle"),
      description: data?.content_description,
      channelUrl: findValueByField(data?.content_to_attr, "channelSlug")
        ? DOCTUBE_LINK + findValueByField(data?.content_to_attr, "channelSlug")
        : `${DOCTUBE_LINK}${stringToSlug(data?.content_title)}`,
      customGaTag: settings?.external_ga_id || "",
      enableTracking: !!settings?.external_ga_id,
    });
  }, [data]);
  useEffect(() => {
    dispatch(updateChannelInput(inputs));
  }, [inputs]);

  const onBlurChannelNameField = useCallback(() => {
    if (inputs.name && inputs.name?.length > 0) {
      setSearchEnabled(true);
    } else {
      setSearchEnabled(false);
    }
  }, [inputs.name, searchEnabled]);

  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-column gap-md-5 gap-4">
          {/*  */}

          <Row>
            <Col md={12}>
              <h5 className="mb-1">Name</h5>
              <p>
                Choose a channel name that represents you and your content.
                Changes made to your name and picture are visible only on
                doctube and not other Google services. You can change the name
                within 14 days.
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 50, hide: 200 }}
                  overlay={nameTooltip}
                >
                  <span className="text-primary cursorPointer ms-1 fw-semibold">
                    (?)
                  </span>
                </OverlayTrigger>
              </p>
            </Col>
            <Col md={10} xs={12}>
              <Form.Control
                type="text"
                name="name"
                value={inputs.name}
                onChange={handleInputChange}
                disabled={isEditNameDisabled}
                onFocus={() => {
                  setSearchEnabled(false);
                  setIsChannelNameExist(false);
                }}
                onBlur={onBlurChannelNameField}
                isInvalid={isChannelNameExist}
              />
              <Form.Control.Feedback type="invalid">
                {`Channel name ${inputs.name} already exists.`}
              </Form.Control.Feedback>
            </Col>
          </Row>

          {/*  */}

          {/*  */}

          <Row>
            {/* <Col md={12}>
              <h5 className="mb-1">Handle</h5>
              <p>
                Choose your unique handle. by adding letters and numbers. You
                can change your handle back within 14 days. Handles can be
                changed twice every 14 days
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 50, hide: 200 }}
                  overlay={nameTooltip}
                >
                  <span className="text-primary cursorPointer ms-1 fw-semibold">
                    (?)
                  </span>
                </OverlayTrigger>
              </p>
            </Col> */}
            {/* <Col md={10} xs={12}>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  name="handle"
                  value={inputs.handle}
                  onChange={handleInputChange}
                />
                <Button
                  variant="outline-primary"
                  id="button-addon2"
                  onClick={() => {
                    setInputs((prev) => {
                      return {
                        ...prev,
                        handle: slugify(inputs?.name),
                      };
                    });
                  }}
                >
                  Generate
                </Button>
              </InputGroup>
              <p className="mb-0 mt-2">
                https://www.doctube.com/{inputs?.handle}
              </p>
            </Col> */}
          </Row>

          {/*  */}
          {/*  */}

          <Row>
            <Col md={12}>
              <h5 className="mb-2">Description</h5>
            </Col>
            <Col md={10} xs={12}>
              <Form.Control
                type="text"
                rows="3"
                as="textarea"
                name="description"
                value={inputs.description}
                onChange={handleInputChange}
              />
              {/* <p className="mb-0 mt-2">https://www.doctube.com</p> */}
            </Col>
          </Row>

          {/*  */}
          {/*  */}

          <Row>
            <Col md={12}>
              <h5 className="mb-1">Channel URL</h5>
              <p>
                This is the standard web address for your channel. It includes
                your channel ID i.e. the numbers and letters at the end of the
                URl.
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 50, hide: 200 }}
                  overlay={channelTooltip}
                >
                  <span className="text-primary cursorPointer ms-1 fw-semibold">
                    (?)
                  </span>
                </OverlayTrigger>
              </p>
            </Col>
            <Col md={10} xs={12}>
              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  disabled
                  name="channelUrl"
                  value={inputs.channelUrl}
                  onChange={handleInputChange}
                  ref={channelUrlInputRef}
                />
                <Button
                  className="flex-shrink-0 d-inline-flex gap-2 px-3 align-items-center jucstify-content-center"
                  onClick={copyToChannelClipboard}
                  // className=""
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-copy"
                    viewBox="0 0 16 16"
                    className="icon-16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                    />
                  </svg>
                  Copy
                </Button>
              </div>
            </Col>
          </Row>

          {/*  */}
          {/*  */}

          <Row>
            <Col md={12} className="d-flex align-items-start gap-3 mb-3">
              <h5 className="mb-0">Enable Tracking using your custom GA Tag</h5>
              <Form.Check
                className="flex-shrink-0"
                checked={inputs?.enableTracking}
                onClick={() =>
                  setInputs((prev) => {
                    return {
                      ...prev,
                      enableTracking: !prev?.enableTracking,
                    };
                  })
                }
                type="switch"
              />
            </Col>

            {inputs?.enableTracking && (
              <Col md={10} xs={12}>
                <div className="d-flex gap-2">
                  <div className="flex-shrink-0 d-inline-flex align-items-center justify-content-center">
                    <svg
                      className="icon-48"
                      width="37"
                      height="37"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.6847 1.54747L10.3804 7.97805C9.96335 8.40345 9.62692 9.23961 9.63259 9.83462L9.8057 27.2941C9.81685 28.4856 10.8027 29.4521 11.9935 29.4389L24.9933 29.3097C26.1834 29.2986 27.149 28.3137 27.1367 27.122L26.965 9.66384C26.9591 9.06915 26.6052 8.23885 26.1807 7.8227L19.7488 1.51708C18.898 0.682997 17.5187 0.69672 16.6847 1.54747ZM19.8397 10.7092C19.002 11.5637 17.6294 11.5766 16.7756 10.7396C15.9204 9.90122 15.9075 8.53001 16.7452 7.6755C17.5829 6.82099 18.9543 6.80649 19.8095 7.64489C20.6633 8.48186 20.6774 9.85471 19.8397 10.7092Z"
                        fill="#355FD3"
                      />
                      <path
                        d="M13 20C13 17.243 15.243 15 18 15C19.1135 15 20.1674 15.3582 21.0478 16.036L19.8859 17.5453C19.3415 17.1263 18.6894 16.9048 18 16.9048C16.2933 16.9048 14.9048 18.2933 14.9048 20C14.9048 21.7067 16.2933 23.0952 18 23.0952C19.3746 23.0952 20.5428 22.1946 20.9453 20.9524H18V19.0476H23V20C23 22.757 20.757 25 18 25C15.243 25 13 22.757 13 20Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <Form.Control
                    type="text"
                    name="customGaTag"
                    value={inputs.customGaTag}
                    onChange={handleInputChange}
                    ref={gaTagInputRef}
                    placeholder="Custom doctube Analytics Tag"
                  />
                  <Button
                    className="flex-shrink-0 d-inline-flex gap-2 px-3 align-items-center jucstify-content-center"
                    onClick={copyToGaTagClipboard}
                    // className=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-copy"
                      viewBox="0 0 16 16"
                      className="icon-16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                      />
                    </svg>
                    Copy
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          {/*  */}
        </div>
      </Card.Body>
    </Card>
  );
}

export default React.memo(CustomizationBasicInfo);
