import React from "react";
import TemplateManager from "../layouts/template-manager";
import EmailTemplateBank from "../pages/email/template-bank";
import AllEmailTemplates from "../pages/email/all-templates";
import CreateEmailTemplate from "../pages/email/create-template";
import AllSmsTemplates from "../pages/sms/all-templates";
import SmsTemplateBank from "../pages/sms/template-bank";
import CreateSmsTemplate from "../pages/sms/create-template";
import CreateWhatsappTemplate from "../pages/whatsapp/create-template";
import WhatsappTemplateBank from "../pages/whatsapp/template-bank";
import AllWhatsappTemplates from "../pages/whatsapp/all-templates";
import CreateTelegramTemplate from "../pages/telegram/create-template";
import TelegramTemplateBank from "../pages/telegram/template-bank";
import AllTelegramTemplates from "../pages/telegram/all-templates";
import EmailTemplateDetails from "../pages/email/template-details";
import SmsTemplateDetails from "../pages/sms/template-details";
import WhatsappTemplateDetails from "../pages/whatsapp/template-details";
import Default from "../../../../layouts/dashboard/default";
import AllVariables from "../pages/variable/all-variable";
import AllCategory from "../pages/category/all-category";
import AllBank from "../pages/bank/all-bank";
import CreateBank from "../pages/bank/create-bank";
import BankDetails from "../pages/bank/bank-details";
import Analytics from "../pages/analytics";

export const TemplateManagerRouter = [
  {
    path: "template-management",
    element: <Default />,
    children: [
      {
        path: "email-template",
        element: <AllEmailTemplates />,
        key: "email-teplate-list",
      },
      {
        path: "email-template/list",
        element: <AllEmailTemplates />,
        key: "email-teplate-list-alt",
      },
      {
        path: "email-template/bank",
        element: <EmailTemplateBank />,
        key: "email-teplate-bank",
      },
      {
        path: "email-template/create/",
        element: <CreateEmailTemplate />,
        key: "email-teplate-create",
      },
      {
        path: "email-template/create/:id",
        element: <CreateEmailTemplate />,
        key: "email-teplate-create-by-id",
      },
      {
        path: "email-template/details/:id",
        element: <EmailTemplateDetails />,
        key: "email-teplate-details-by-id",
      },
      {
        path: "sms-template",
        element: <AllSmsTemplates />,
        key: "sms-teplate-list",
      },
      {
        path: "sms-template/list",
        element: <AllSmsTemplates />,
        key: "sms-teplate-list-alt",
      },
      {
        path: "sms-template/bank",
        element: <SmsTemplateBank />,
        key: "sms-teplate-bank",
      },
      {
        path: "sms-template/create/",
        element: <CreateSmsTemplate />,
        key: "sms-teplate-create",
      },
      {
        path: "sms-template/create/:id",
        element: <CreateSmsTemplate />,
        key: "sms-teplate-create-by-id",
      },
      {
        path: "sms-template/details/:id",
        element: <SmsTemplateDetails />,
        key: "sms-teplate-details-by-id",
      },
      {
        path: "telegram-template",
        element: <AllTelegramTemplates />,
        key: "telegram-teplate-list",
      },
      {
        path: "telegram-template/bank",
        element: <TelegramTemplateBank />,
        key: "telegram-teplate-bank",
      },
      {
        path: "telegram-template/create",
        element: <CreateTelegramTemplate />,
        key: "telegram-teplate-create",
      },
      {
        path: "whatsapp-template",
        element: <AllWhatsappTemplates />,
        key: "whatsapp-teplate-list",
      },
      {
        path: "whatsapp-template/bank",
        element: <WhatsappTemplateBank />,
        key: "whatsapp-teplate-bank",
      },
      {
        path: "whatsapp-template/create/:id",
        element: <CreateWhatsappTemplate />,
        key: "whatsapp-teplate-create-by-id",
      },
      {
        path: "whatsapp-template/create",
        element: <CreateWhatsappTemplate />,
        key: "whatsapp-teplate-create",
      },
      {
        path: "whatsapp-template/details/:id",
        element: <WhatsappTemplateDetails />,
        key: "whatsapp-teplate-details-by-id",
      },
      {
        path: "variable",
        element: <AllVariables />,
      },
      {
        path: "category",
        element: <AllCategory />,
      },
      {
        path: "bank",
        element: <AllBank />,
      },
      {
        path: "create-bank",
        element: <CreateBank />,
      },
      {
        path: "create-bank/:id",
        element: <CreateBank />,
      },
      {
        path: "bank/details/:id",
        element: <BankDetails />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
    ],
  },
];
