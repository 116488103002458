import React, { Fragment, useState } from "react";
import { Card, Tab, Nav, Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";

import DropDownMenu from "../components/DropDownMenu";
import DatamapsIndia from "react-datamaps-india";
import { dateFilterOptions } from "../Constant/date-filter-constant";
import AnalyticsAudience from "../components/analytics/AnalyticsAudience";
import {
  useGetCampaignAnalytics,
  useGetVideoAnalytics,
} from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import AudienceSkeleton from "../../../../../../skeleton/workspace/doctube/channel/analytics-audience-skeleton";
import CampaignAnalyticsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/campaign-analytics-skeleton";
import { useParams, useSearchParams } from "react-router-dom";
import { transformGraphData } from "../../../../../../common/utils/utils";

function VideoCampaignAnalytics() {
  const { channel_id, campaign_key } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = dateFilterOptions.find(
    (item) => item.initial
  );
  const sortOption = searchParams.get("datefilter") || initialDateFilterOption.value;
  console.log("sortOption", sortOption);


  const { data: audienceData, isLoading: isAudienceLoading } =
    useGetCampaignAnalytics({
      campaign_key: campaign_key,
      type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
      analysis_type: "audience", //  | reach | engagement | audience
    });

  // const audienceSerics = audienceData?.data?.views?.length > 0 ? transformGraphData(audienceData?.data?.views, "views", "level")?.series : [];

  console.log("audienceData views", audienceData?.viwes?.length, audienceData?.viwes);
  if (audienceData?.viwes?.length > 0) {
    console.log("transformGraphData", 11, transformGraphData(audienceData.views, 'views', 'level'));
  }

  // Audience View
  const AudienceViewOptions = {
    series: [
      {
        name: "Views",
        data: audienceData?.views?.length > 0 ? transformGraphData(audienceData.views, 'views', 'level').series : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false, // hide the toolbar
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        // type: "datetime",
        categories: audienceData?.views?.length > 0 ? transformGraphData(audienceData.views, 'views', 'level')?.label : [],
      },
      //   tooltip: {
      //     x: {
      //       format: "dd/MM/yy HH:mm",
      //     },
      //   },
    },
  };

  // function handleDropdownChange(type) {
  //   setPayload({
  //     ...payload,
  //     type,
  //   });
  // }

  // skeleton
  if (isAudienceLoading) {
    return <CampaignAnalyticsSkeleton />;
  }


  return (
    <Fragment>
      <h4 className="fw-semibold mb-4">Campaign Analytics</h4>
      <Row>
        <Col md={12} sm={12}>
          <Card>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5> Audience View</h5>

              <DropDownMenu
                title="datefilter"
                constantFilterOption={dateFilterOptions}
                handleDropdownChange={(key, item) => {
                  setSearchParams({ ...searchParams, [item]: key });
                }}
              />

            </Card.Header>
            <div className="d-flex flex-column">
              <Card.Body className="p-0">
                <div>
                  {audienceData?.views?.length > 0 && <div id="chart">
                    <Chart
                      options={AudienceViewOptions.options}
                      series={AudienceViewOptions.series}
                      type="area"
                      height={350}
                    />
                  </div>}

                </div>
              </Card.Body>
            </div>
          </Card>
        </Col>
      </Row>
      {audienceData && Object.values(audienceData)?.length > 0 && (
        <AnalyticsAudience data={audienceData} />
      )}
    </Fragment>
  );
}

export default VideoCampaignAnalytics;
