import React, { memo } from "react";
import { Card, Row, Col, Button, Tab, Nav } from "react-bootstrap";

import RadialBarChart from "../../../../../components/chart/RadialBarChart";
import { findIcon } from "../../../../dashboard/icons";

import CollapseTags from "./CollapseTags";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import PageNotFound from "../../../../PageNotFound";
import { useParams } from "react-router-dom";
import SponsoredContentCard from "./SponsoredContentCard";
import { formatNumberWithCommas } from "../../../../../common/utils/utils";
import { checkProjectAccess } from "../utils/common";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";

function SponsoredMatrix({
  tags,
  viewsChart = {},
  readChart = {},
  hcpEngagedChart = {},
  avgDurationChart = 0,
  content,
  content_sponsors,
}) {
  const { project_id } = useParams();

  function formatTime(secondsInput) {
    const totalSeconds = Number(secondsInput);

    if (isNaN(totalSeconds) || totalSeconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor((totalSeconds % 3600) % 60);

    let result = "";

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? "s" : ""} `;
    }

    if (minutes > 0) {
      result += `${minutes} minute${minutes > 1 ? "s" : ""} `;
    }

    if (seconds > 0 || result === "") {
      result += `${seconds} second${seconds > 1 ? "s" : ""}`;
    }

    return result.trim();
  }

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const hasViewAccess = checkProjectAccess("show_view", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);

  return (
    <>
      <Row>
        <Col md={12} className="mb-3">
          <SponsoredContentCard
            contentImage={content?.content_image?.base}
            contentType={content?.content_type}
            contentLogo={
              content_sponsors &&
              Array.isArray(content_sponsors) &&
              content_sponsors.length > 0 &&
              content_sponsors[0]?.sponsor_data?.client_logo
            }
            categoryIcon={content?.content_category_icon}
            contentTitle={content?.content_title}
            contentDate={content?.content_date}
            contentId={content?.content_id}
          />
        </Col>

        {hasReadAccess && (
          <Col md={3}>
            <RadialBarChart
              max={readChart?.max || 0}
              data={readChart?.data || 0}
              labels={["Reads"]}
              color={"#1aa053"}
              title={
                <span className="fs-7 mt-n2 text-center">Total Reads</span>
              }
            />
          </Col>
        )}
        {hasViewAccess && (
          <Col md={3}>
            <RadialBarChart
              max={viewsChart?.max || 0}
              data={viewsChart?.data || 0}
              labels={["Views"]}
              color={"#3256b8"}
              title={
                <span className="fs-7 mt-n2 text-center">
                  Views
                  {viewsChart?.max !== 0 && (
                    <>
                      <br />
                      {findIcon("ImpressionTarget", "dual-tone", 12)}{" "}
                      {formatNumberWithCommas(viewsChart?.max)}
                    </>
                  )}
                </span>
              }
            />
          </Col>
        )}
        <Col md={3}>
          <RadialBarChart
            max={hcpEngagedChart?.max || 0}
            data={hcpEngagedChart?.data || 0}
            labels={["Hcp's Engaged"]}
            color={"#1aa053"}
            title={
              <span className="fs-7 mt-n2 text-center">
                HCPs Engaged
                {hcpEngagedChart?.max !== 0 && (
                  <>
                    <br />
                    {findIcon("ImpressionTarget", "dual-tone", 12)} Target{" "}
                    {formatNumberWithCommas(hcpEngagedChart?.max)}
                  </>
                )}
              </span>
            }
          />
        </Col>
        <Col md={3} className="position-relative avgDuration">
          <div className="h-rem-10 w-rem-10 rounded-circle bg-opacity-50 bg-light position-absolute position-absolute top-50 start-50 translate-middle fs-7 d-flex flex-column align-items-center justify-content-center lh-base">
            <span className="text-center px-2">
              <i className="text-primary fw-semibold fs-6 fst-normal">
                {formatTime(avgDurationChart)}
              </i>
            </span>
            <span className="text-black-50">Avg Duration</span>
          </div>
        </Col>

        {tags && tags?.length > 0 && (
          <Col md={12}>
            <CollapseTags tags={tags} />
          </Col>
        )}
      </Row>
    </>
  );
}

export default memo(SponsoredMatrix);
