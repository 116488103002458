import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Image,
  Row,
  Nav,
  Tab,
  Button,
  Dropdown,
  Form,
  ProgressBar,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import avatars11 from "../../../../assets/images/avatars/01.png";
import { findIcon } from "../../../dashboard/icons";

import UserHeaderLoader from "../../../../skeleton/user/user-header-loader";
import UserDetailsLoader from "../../../../skeleton/user/user-details-loader";
import UserFormLoader from "../../../../skeleton/user/user-form-loader";
import { useGetProfileDetails } from "../../../../queries/query-hooks/profile-hook";
import withAuthorization from "../../../../components/hoc/with-authorization";
import { useFormik } from "formik";
import {
  chnagePasswordInitialValues,
  chnagePasswordSchema,
} from "../constants/form-helpers";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import toast from "react-hot-toast";
import postService from "../../../../api/services/post-service";
import { UPDATE_PASSWORD } from "../../../../api/endpoints/profile-endpoints";
const UserProfile = () => {
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const updatePassword = async (values) => {
    showBackdrop();
    let response = await postService(UPDATE_PASSWORD, values);
    if (response.isError) {
      hideBackdrop();
      toast.error(response.error);
      return;
    } else {
      if (response.data.success) {
        hideBackdrop();
        toast.success(response.data.message);
        resetForm();
      } else {
        hideBackdrop();
        toast.error("Something went wrong");
      }
    }
  };
  const formik = useFormik({
    initialValues: chnagePasswordInitialValues,
    validationSchema: chnagePasswordSchema,
    onSubmit: (values) => {
      updatePassword(values);
    },
  });
  const {
    values,
    setValues,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = formik;

  const onProfileGetSuccess = (data) => {
    console.log("onProfile detaisls", data);
  };
  const { data: profileDetails, isLoading: loading } =
    useGetProfileDetails(onProfileGetSuccess);

  return (
    <>
      <Row>
        <Col lg="12" className="d-flex mt-5 mt-lg-0">
          {!loading ? (
            <Card className="mt-4 flex-grow-1">
              <Card.Body>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1 border border-2 border-primary rounded-circle">
                      {profileDetails?.profile_img ? (
                        <Image
                          className="theme-color-default-img  img-fluid rounded-pill avatar-100"
                          src={profileDetails?.profile_img}
                          alt="profile-pic"
                        />
                      ) : (
                        <Image
                          className="theme-color-default-img  img-fluid rounded-pill avatar-100"
                          src={avatars11}
                          alt="profile-pic"
                        />
                      )}
                      {/* <span className="position-absolute p-1 end-0 bottom-0 rounded-circle bg-primary text-white d-flex justify-content-center align-items-center border border-4 border-white">
                        <input
                          type={"file"}
                          // onClick={(e) => postMessageReactNative(e)}
                          className="userPropfilePicInput cursorPointer position-absolute top-0 start-0 w-100 h-100 rounded-circle bg-danger z-3 opacity-0 gtm_cl_userProfile_profilePic_edit"
                          // onChange={(e) => uploadProfileImage(e.target.files[0])}
                        />
                        {findIcon("Camera", "solid", "20")}
                      </span> */}
                    </div>
                    {profileDetails?.name && (
                      <div className="d-flex flex-wrap flex-column align-items-start mb-3 mb-sm-0">
                        <h4 className="me-2 mb-0 fs-2">
                          {profileDetails?.name}
                        </h4>
                        {/* <span>Sample data content</span> */}
                      </div>
                    )}
                  </div>
                  {/* <Button variant="primary" className="rounded-pill">
                  Change Password
                </Button> */}

                  {profileDetails?.role && (
                    <div>
                      <Badge pill bg="primary" className="p-3">
                        {profileDetails?.role}
                      </Badge>
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          ) : (
            <UserHeaderLoader />
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="5" className="d-flex">
          {!loading ? (
            <Card className="flex-grow-1">
              <Card.Header>
                <h5>About</h5>
              </Card.Header>

              <Card.Body>
                <div className="user-bio">
                  {/* <p>
                    Tart I love sugar plum I love oat cake. Sweet roll caramels
                    I love jujubes. Topping cake wafer.
                  </p> */}
                </div>
                <div className="mt-2">
                  <h6 className="mb-1">Employee Code:</h6>
                  {profileDetails?.employee_code ? (
                    <p>{profileDetails?.employee_code}</p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
                <div className="mt-2">
                  <h6 className="mb-1">Email:</h6>
                  {profileDetails?.email ? (
                    <Link
                      to={`mailto:${profileDetails?.email}`}
                      className="text-body"
                    >
                      {profileDetails?.email}
                    </Link>
                  ) : (
                    <p>---</p>
                  )}
                </div>
                <div className="mt-2">
                  <h6 className="mb-1">Phone:</h6>
                  {profileDetails?.phone ? (
                    <p>
                      <Link
                        to={`tel:${profileDetails?.phone}`}
                        className="text-body"
                      >
                        {profileDetails?.phone}
                      </Link>
                    </p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
                <div className="mt-2">
                  <h6 className="mb-1">Department:</h6>
                  {profileDetails?.department ? (
                    <p>{profileDetails?.department}</p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
                <div className="mt-2">
                  <h6 className="mb-1">Reporting Manager:</h6>
                  {profileDetails?.reporting_manager_name ? (
                    <p>{profileDetails?.reporting_manager_name}</p>
                  ) : (
                    <p>---</p>
                  )}
                </div>
              </Card.Body>
            </Card>
          ) : (
            <UserDetailsLoader />
          )}
        </Col>
        <Col lg="7" className="d-flex">
          {!loading ? (
            <Card className="flex-grow-1">
              <Card.Header className="d-flex justify-content-start align-items-center gap-2">
                {findIcon("Lock Closed", "solid", "20")}
                <h5 className="m-0">Change Password</h5>
              </Card.Header>
              <Card.Body>
                <div className="mb-4">
                  <h6 className="fs-6 fw-400 gray-300">Current Password</h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      type="password"
                      autoComplete="password"
                      placeholder="Current Password"
                      name="old_password"
                      value={values.old_password}
                      onChange={handleChange}
                      isInvalid={!!errors.old_password && touched.old_password}
                    />
                    <label htmlFor="floatingInput">
                      Enter Your Current Password
                    </label>
                    <Form.Control.Feedback type="invalid">
                      {errors.old_password}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </div>
                <div className="mb-4">
                  <h6 className="fs-6 fw-400 gray-300">New Password</h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      // type="password"
                      autoComplete="password"
                      placeholder="New Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <label htmlFor="floatingInput">
                      Enter Your New Password
                    </label>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </div>
                <div className="mb-4">
                  <h6 className="fs-6 fw-400 gray-300">Confirm New Password</h6>
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      // type="password"
                      className=""
                      autoComplete="password"
                      placeholder="Confirm New Password"
                      name="password_confirmation"
                      value={values.password_confirmation}
                      onChange={handleChange}
                      isInvalid={
                        touched.password_confirmation &&
                        !!errors.password_confirmation
                      }
                    />
                    <label htmlFor="floatingInput">
                      Confirm Your New Password
                    </label>
                    <Form.Control.Feedback type="invalid">
                      {errors.password_confirmation}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </div>

                <div className="d-flex justify-content-center justify-content-md-end">
                  <Button variant="primary" onClick={handleSubmit}>
                    Change password
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <UserFormLoader />
          )}
        </Col>
      </Row>
    </>
  );
};

export default withAuthorization(UserProfile);
