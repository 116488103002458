import React, { useState, useRef, memo } from "react";
import Card from "../../../../components/bootstrap/card";
import { Button, Col, Image, Row } from "react-bootstrap";
import Masonry from "react-masonry-css";
import TemplateSuggestionCard from "../../template-manager/components/template-suggestion-card";
import mobile from "../../../../assets/images/mobile-bg.png";
import img5 from "../../../../assets/modules/blog/images/tem-E.png";
import { Link } from "react-router-dom";
import ReactEmailEditor from "../../../../lib/email-editor/react-email-editor";
import sample from "../../../../lib/email-editor/sample.json";
import Filter from "./filter";
import toast from "react-hot-toast";
import { getService } from "../../../../api/services/get-services";
import { EMAIL_TEMPLATE_DETAILS_GET } from "../../../../api/endpoints/template-management-endpoints";

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};

const EmailTemplatePreview = ({
  suggestionlist,
  // modalClose,
  getSelectedTemplate,
  isEmailTemplatePreview,
  emailTemplatePreview,
  isEmailViewDetails,
  // selectedTemplate = "",
}) => {
  // const [selectedSms, setSelectedSms] = useState(
  //   selectedTemplate !== "" ? selectedTemplate : ""
  // );
  // const [loading, setLoading] = useState(false);
  // const [isInPreviewMode, setIsInPreviwewMode] = useState(true);
  // const [templateId, setTemplateId] = useState("");
  // const [templateDetails, setTemplateDetails] = useState({});

  const viewDetails = (item) => {
    isEmailTemplatePreview();
    isEmailViewDetails();
    // setTemplateId(item.id);
    fetchEmailTemplateDetails(item.id, item);
    console.log("show items", item);
  };
  const emailEditorRef = useRef(null);

  const onLoad = (json_content) => {
    if (json_content) {
      emailEditorRef.current?.editor?.loadDesign(json_content);
    }
  };

  const onReady = () => {
    emailEditorRef.current?.editor?.showPreview("desktop");
  };

  const fetchEmailTemplateDetails = async (id, item) => {
    try {
      // setLoading(true);
      const response = await getService(EMAIL_TEMPLATE_DETAILS_GET + `/${id}`);
      console.log("EMAIL_TEMPLATE_DETAILS_GET", response.data);
      if (response.data.success == true) {
        // setTemplateDetails(response.data.data);
        // setLoading(false);
        // let htmlContent = response.data.data?.email_content;
        let json_content = response.data.data?.email_json;
        getSelectedTemplate({ ...item, content: json_content });
        console.log("json_content", json_content, response.data.data);
        // if (emailEditorRef.current) {
        //     emailEditorRef.current.editor.loadDesign(template);
        // }
        onLoad(json_content);
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      // setLoading(false);
      // console.log("TemplateField", error);
    }
  };

  return (
    <>
      {!emailTemplatePreview ? (
        <div className="p-4 bg-soft-secondary rounded-3">
          <Row className="justify-content-center align-items-start">
            <Col>
              <Card>
                <Card.Body>
                  <Filter />
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {suggestionlist.map((item, index) => (
                  <Card key={index + 1}>
                    <Card.Header className="card-thumbnail">
                      <div className="w-100 rounded object-cover "></div>
                      <Image
                        src={img5}
                        alt="02"
                        className="img-fluid w-100 rounded object-cover "
                        loading="lazy"
                      />
                    </Card.Header>
                    <Card.Body className="card-thumbnail">
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        {/* {item.created_at && (
                          <small className="text-primary">
                            <span className="text-secondary">Added :</span>{" "}
                            {item.created_at}
                          </small>
                        )} */}
                        {item.name && (
                          <h4 className="mt-2 mb-3 text-ellipsis short-2">
                            {item.name}
                          </h4>
                        )}
                        <div>
                          <Button
                            type="button"
                            variant="outline-success"
                            onClick={() => viewDetails(item)}
                          >
                            View Details
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </Masonry>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="flex-grow-1 reactEmailEditorPrrnt">
          <ReactEmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            onLoad={onLoad}
          />
        </div>
      )}
    </>
  );
};

export default memo(EmailTemplatePreview);
