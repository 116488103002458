import React from "react";
import { findIcon } from "../../../dashboard/icons";
import { Badge } from "react-bootstrap";
import Card from "../../../../components/bootstrap/card";
import Chart from "react-apexcharts"

export default function RecommendedSegmentCard({
  name = "",
  status = "",
  reach = 0,
  individual_reach = "",
  created_at = "",
  click,
}) {
  const servicechart1={
    options : {
     colors: ["#344ed1"],
       chart: {
       sparkline: {
             enabled: true,
         }
     },
     dataLabels: {
       enabled: false
     },
     stroke: {
       curve: 'smooth'
     },
     xaxis: {
       type: 'datetime',
       categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z"]
     },
     tooltip: {
       enabled: false,
     },
     },
     series: [{
       name: 'series1',
       data: [1, 1, 1, 30]
     }]
 }
  return (
    <Card>
      <Card.Body>
        <div
          className="d-flex justify-content-between align-items-start"
          onClick={click}
        >
          {name ? <div className="fs-6 text-secondary pe-3">{name}</div> : null}
          {status || created_at ? (
            <div className="d-flex flex-grow-1 flex-column align-items-end justify-content-end w-50">
              {status ? (
                <Badge variant={"primary"} className="">
                  {status}
                </Badge>
              ) : null}
              {created_at ? (
                <p className="fs-7 mt-2 text-end">{created_at}</p>
              ) : null}
            </div>
          ) : null}
        </div>
        {reach >= 0 ? (
          <div className="d-flex gap-2 mt-3 align-items-center">
            <div className="d-flex align-items-center justify-content-center p-2 rounded-3 me-2">
              {/* <div className="d-inline-block avatar-40 rounded-3 bg-soft-secondary">
                {findIcon("Analytics", "dual-tone", "60")}
              </div> */}
              <Chart  options={servicechart1.options} series={servicechart1.series} type="area" width="140" height="65" />
              <div className="d-flex flex-column px-3 lh-base">
                <span className="fs-7">Reach</span>
                <span className="fs-2 fw-semibold text-info">{reach}</span>
              </div>
            </div>
            {individual_reach ? (
              <>
                {individual_reach?.whatsapp ? (
                  <div className="d-flex align-items-center justify-content-center avatar-40 rounded-3 bg-soft-success">
                    {findIcon("Whatsapp", "dual-tone", "24")}
                  </div>
                ) : null}
                {individual_reach?.email ? (
                  <div className="d-flex align-items-center justify-content-center avatar-40 rounded-3 bg-soft-warning disabled">
                    {findIcon("Email", "dual-tone", "24")}
                  </div>
                ) : null}
                {individual_reach?.sms ? (
                  <div className="d-flex align-items-center justify-content-center avatar-40 rounded-3 bg-soft-info">
                    {findIcon("Message-2", "dual-tone", "24")}
                  </div>
                ) : null}
                <div className="ms-auto d-flex align-items-center justify-content-center avatar-40 rounded-3 text-secondary">
                  {findIcon("Arrow Right", "outline", "26")}
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </Card.Body>
    </Card>
  );
}
