import React from "react";
import { Row, Col, Button, Dropdown, Image, Form } from "react-bootstrap";

//Apexcharts
import Chart from "react-apexcharts";

//Component
import Card from "../../../../components/bootstrap/card";
import CustomToggle from "../../../../components/dropdowns";
// import AnalyticsCard from "../components/analytics-card";
// import Blogcard from "../components/blogcard";

import CountUp from "react-countup";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const RequisitionDashboard = () => {
  const { redirectTo } = useRedirect();
  const chart1 = {
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "25 Jan",
          "8 Feb",
          "22 Feb",
          "8 Mar",
          "22 Mar",
          "5 Apr",
          "2 May",
          "26 May",
          "1 June",
        ],
      },
    },
    series: [
      {
        name: "Raised",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
  };
  const chart2 = {
    options: {
      chart: {
        fontFamily:
          '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      colors: ["#3e1ca8", "#b0e0e6"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          minWidth: 19,
          maxWidth: 19,
          style: {
            colors: "#8A92A6",
          },
          offsetX: -5,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        labels: {
          minHeight: 22,
          maxHeight: 22,
          show: true,
          style: {
            colors: "#8A92A6",
          },
        },
        lines: {
          show: false, //or just here to disable only x axis grids
        },
        categories: ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug"],
      },
      grid: {
        show: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.4,
          opacityTo: 0.1,
          stops: [0, 50, 80],
          colors: ["#3e1ca8", "#b0e0e6"],
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    series: [
      {
        name: "total",
        data: [94, 80, 94, 80, 94, 80, 94],
      },
      {
        name: "pipline",
        data: [72, 60, 84, 60, 74, 60, 78],
      },
    ],
  };
  const chart3 = {
    options: {
      chart: {},
      colors: ["#7b8cde", "#ffba13", "#841ca8"],
      labels: ["Vendor 1", "Vendor 2", "vendor 3"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 41],
  };
  const iqchartbox1 = {
    options: {
      colors: ["#344ed1"],
      chart: {
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "",
        align: "left",
      },

      xaxis: {
        categories: ["Jan", "Feb", "Mar"],
      },
      tooltip: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Total sales",
        data: [10, 10, 35, 10],
      },
    ],
  };
  const chart4 = {
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Category A", "Category B", "Category C", "Category D"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 13, 43],
  };
  const chart5 = {
    series: [42, 47, 52, 58, 65],

    options: {
      chart: {
        type: "polarArea",
      },
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      labels: ["Brand A", "Brand B", "Brand C", "Brand D", "Brand E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const chart6 = {
    options: {
      chart: {},
      colors: ["#0000ff", "#ff0000", "#66cdaa", "#ec4d9a"],
      labels: ["Divison 1", "Divison 2", "Divison 3", "Divison 4"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [44, 55, 41, 33],
  };
  return (
    <>
      <div className="mb-5">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <h3 className="mb-0">My Requisitions</h3>
          <div className="d-flex rounded gap-3">
            <Button
              className="btn btn-primary flex-fill"
              type="submit"
              onClick={() => redirectTo("requisition/create")}
            >
              Add Requisition
            </Button>
            <Form.Floating className="custom-form-floating">
              <Form.Select className="" id="floatingInput1">
                {/* <option value="">Select Department</option> */}
                <option value="Medical">This Month</option>
                <option value="Marketing"> Last Week</option>
                <option value="Marketing"> This Week</option>
              </Form.Select>
              {/* <Form.Label htmlFor="floatingInput">Select Department</Form.Label> */}
            </Form.Floating>
          </div>
        </div>
        {/* <Row>
          <Col lg="12">
            <Card>
              <Card.Body className="overflow-hidden">
                <iframe
                  style={{ minHeight: "80vh", marginTop: "-5.4rem" }}
                  src="https://atlas.nomic.ai/map/8ce86d25-f5ca-465e-9053-23d4c986651d/0e3ea15b-ea69-4070-9d46-e16bab70c5a5"
                  // style="border:0px #ffffff none;"
                  name="myiFrame"
                  scrolling="no"
                  frameborder="1"
                  marginheight="0px"
                  marginwidth="0px"
                  height="400px"
                  width="600px"
                  className="w-100 border-0 mt-n5"
                  allowfullscreen
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col lg="3" md="6">
            <Card className="card-block card-stretch card-height">
              <Card.Body>
                <div className="d-flex align-items-start justify-content-between mb-2">
                  <p className="mb-0 text-dark">Total Raised</p>
                </div>
                <div className="mb-2">
                  <h4 className="counter">
                    <CountUp
                      start={0}
                      end={1350}
                      duration={10}
                      useEasing={true}
                      separator=","
                    />
                  </h4>
                </div>
                <div>
                  <Chart
                    options={chart1.options}
                    series={chart1.series}
                    type="bar"
                    height={200}
                  ></Chart>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Row>
              <Col>
                <Card onClick={() => redirectTo("/requisition/listing")}>
                  <Card.Body className="bg-soft-danger rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-danger rounded p-3 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M22.4541 11.3918C22.7819 11.7385 22.7819 12.2615 22.4541 12.6082C21.0124 14.1335 16.8768 18 12 18C7.12317 18 2.98759 14.1335 1.54586 12.6082C1.21811 12.2615 1.21811 11.7385 1.54586 11.3918C2.98759 9.86647 7.12317 6 12 6C16.8768 6 21.0124 9.86647 22.4541 11.3918Z"
                            stroke="currentColor"
                          ></path>
                          <path
                            d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z"
                            stroke="currentColor"
                          ></path>
                          <path
                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                            fill="currentColor"
                          ></path>
                          <mask
                            id="mask0_18_1038"
                            maskUnits="userSpaceOnUse"
                            x="9"
                            y="9"
                            width="6"
                            height="6"
                          >
                            <path
                              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                              fill="currentColor"
                            ></path>
                          </mask>
                          <g mask="url(#mask0_18_1038)">
                            <path
                              opacity="0.89"
                              d="M13.5 12C14.3284 12 15 11.3284 15 10.5C15 9.67157 14.3284 9 13.5 9C12.6716 9 12 9.67157 12 10.5C12 11.3284 12.6716 12 13.5 12Z"
                              fill="white"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-6 pb-2">Review Pending</span>
                        <h4 className="fw-semibold">
                          <CountUp start={10} end={36} duration={3} />
                        </h4>
                        <span>
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon-32"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89176 17.108 2.74976 12 2.74976C6.892 2.74976 2.75 6.89176 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M10.5576 15.4709L14.0436 11.9999L10.5576 8.52895"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col onClick={() => redirectTo("/requisition/completed")}>
                <Card>
                  <Card.Body className="bg-soft-success rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-success rounded p-3 d-flex align-items-center">
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.6272"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-6 pb-2">Approved</span>
                        <h4 className="fw-semibold">
                          <CountUp start={89} end={378} duration={3} />
                        </h4>
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89176 17.108 2.74976 12 2.74976C6.892 2.74976 2.75 6.89176 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M10.5576 15.4709L14.0436 11.9999L10.5576 8.52895"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card onClick={() => redirectTo("/requisition/in-progress")}>
                  <Card.Body className="bg-soft-dark rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-dark rounded p-3 d-flex align-items-center">
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.4316 14.9429L11.6616 12.6939V7.84692"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-6 pb-2">Acceptance Pending</span>
                        <h4 className="fw-semibold">
                          <CountUp start={5} end={18} duration={3} />
                        </h4>
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89176 17.108 2.74976 12 2.74976C6.892 2.74976 2.75 6.89176 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M10.5576 15.4709L14.0436 11.9999L10.5576 8.52895"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col>
                <Card>
                  <Card.Body className="bg-soft-success rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-success rounded p-3">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-5">Approved</span>
                        <h4 className="mt-4 fw-semibold">
                          <CountUp start={789} end={18378} duration={3} />K
                        </h4>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="counter">
                        <b>
                          <CountUp start={789} end={18378} duration={3} />K
                        </b>
                      </h6>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body className="bg-soft-dark rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-primary rounded p-3">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div>
                        <span>Vendor Acceptance Pending</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="counter">
                        <b>
                          $<CountUp start={789} end={18378} duration={3} />
                        </b>
                      </h6>
                      <Chart
                        options={iqchartbox1.options}
                        series={iqchartbox1.series}
                        type="line"
                        width="100"
                        height="50"
                      />
                      <div className=" d-flex align-items-center text-primary">
                        <b>+14%</b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <Card onClick={() => redirectTo("/requisition/in-progress")}>
                  <Card.Body className="bg-soft-warning rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-warning rounded p-3 d-flex align-items-center">
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M16.8397 20.1642V6.54639"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M20.9173 16.0681L16.8395 20.1648L12.7617 16.0681"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M6.91102 3.83276V17.4505"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M2.8335 7.92894L6.91127 3.83228L10.9891 7.92894"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-6 pb-2">Creation In-progress</span>
                        <h4 className="fw-semibold">
                          <CountUp start={10} end={245} duration={3} />
                        </h4>
                        <span>
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon-32"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89176 17.108 2.74976 12 2.74976C6.892 2.74976 2.75 6.89176 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M10.5576 15.4709L14.0436 11.9999L10.5576 8.52895"
                              stroke="currentColor"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card onClick={() => redirectTo("/requisition/in-progress")}>
                  <Card.Body className="bg-soft-primary rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-primary rounded p-3 d-flex align-items-center">
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-6 pb-2">In-review</span>
                        <h4 className="fw-semibold">
                          <CountUp start={98} end={287} duration={3} />
                        </h4>
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89176 17.108 2.74976 12 2.74976C6.892 2.74976 2.75 6.89176 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M10.5576 15.4709L14.0436 11.9999L10.5576 8.52895"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card onClick={() => redirectTo("/requisition/in-progress")}>
                  <Card.Body className="bg-soft-danger rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-danger rounded p-3 d-flex align-items-center">
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.25 12.0005C21.25 17.1095 17.109 21.2505 12 21.2505C6.891 21.2505 2.75 17.1095 2.75 12.0005C2.75 6.89149 6.891 2.75049 12 2.75049C17.109 2.75049 21.25 6.89149 21.25 12.0005Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M15.4316 14.9429L11.6616 12.6939V7.84692"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                      <div className="d-flex flex-column justify-content-end align-items-end">
                        <span className="fs-6 pb-2">Submission Overdue</span>
                        <h4 className="fw-semibold">
                          <CountUp start={50} end={183} duration={3} />
                        </h4>
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-32"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 21.2498C17.108 21.2498 21.25 17.1088 21.25 11.9998C21.25 6.89176 17.108 2.74976 12 2.74976C6.892 2.74976 2.75 6.89176 2.75 11.9998C2.75 17.1088 6.892 21.2498 12 21.2498Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M10.5576 15.4709L14.0436 11.9999L10.5576 8.52895"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col>
                <Card>
                  <Card.Body className="bg-soft-warning rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-warning rounded p-3">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div>
                        <span>Creation In-progress</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="counter">
                        <b>
                          <CountUp start={789} end={18378} duration={3} />K
                        </b>
                      </h6>
                      <Chart
                        options={iqchartbox1.options}
                        series={iqchartbox1.series}
                        type="line"
                        width="100"
                        height="50"
                      />
                      <div className=" d-flex align-items-center text-primary">
                        <b>+14%</b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body className="bg-white rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-primary rounded p-3">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div>
                        <span>Submission In-review</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="counter">
                        <b>
                          $<CountUp start={789} end={18378} duration={3} />
                        </b>
                      </h6>
                      <Chart
                        options={iqchartbox1.options}
                        series={iqchartbox1.series}
                        type="line"
                        width="100"
                        height="50"
                      />
                      <div className=" d-flex align-items-center text-primary">
                        <b>+14%</b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body className="bg-soft-primary rounded-3">
                    <div className="d-flex justify-content-between">
                      <div className="bg-soft-warning rounded p-3">
                        <svg width="24px" height="24px" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                          />
                        </svg>
                      </div>
                      <div>
                        <span>Submission Overdue</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="counter">
                        <b>
                          <CountUp start={789} end={18378} duration={3} />K
                        </b>
                      </h6>
                      <Chart
                        options={iqchartbox1.options}
                        series={iqchartbox1.series}
                        type="line"
                        width="100"
                        height="50"
                      />
                      <div className=" d-flex align-items-center text-primary">
                        <b>+14%</b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="d-flex flex-column flex-md-row gap-4">
            <Card
              data-aos="fade-up"
              data-aos-delay="800"
              className="col-12 col-md-8 flex-fill"
            >
              <div className="flex-wrap card-header d-flex justify-content-between align-items-center">
                <div className="header-title">
                  <h4 className="card-title">Requisition Type</h4>
                </div>
                <div className="d-flex align-items-center align-self-center">
                  <div className="d-flex align-items-center text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                    <div className="ms-2">
                      <span className="text-secondary">Create</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center ms-3 text-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <g>
                        <circle
                          cx="12"
                          cy="12"
                          r="8"
                          fill="currentColor"
                        ></circle>
                      </g>
                    </svg>
                    <div className="ms-2">
                      <span className="text-secondary">Repurpose</span>
                    </div>
                  </div>
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    href="#"
                    variant="text-secondary dropdown-toggle"
                    size="sm"
                    id="dropdownMenuButton1"
                  >
                    This Year
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">This Year</Dropdown.Item>
                    <Dropdown.Item href="#">This Month</Dropdown.Item>
                    <Dropdown.Item href="#">This Year</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Card.Body>
                <Chart
                  options={chart2.options}
                  series={chart2.series}
                  type="area"
                  height="200"
                />
              </Card.Body>
            </Card>
            {/* </Col>
          <Col lg="4"> */}
            <Card className="col-12 col-md-4 flex-fill">
              <Card.Body>
                <div className="d-flex flex-column justify-content-center justify-items-center gap-2 px-2 pt-4">
                  <Chart
                    options={chart5.options}
                    series={chart5.series}
                    type="polarArea"
                    height="300"
                  />{" "}
                  <h4 className="d-flex flex-column justify-content-center align-items-center">
                    Requisition by Brand
                  </h4>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="d-flex flex-column flex-md-row gap-4">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center px-2 gap-4">
                  <div className="d-flex flex-column justify-content-center gap-2 px-2">
                    <Chart
                      options={chart4.options}
                      series={chart4.series}
                      type="pie"
                      height="200"
                    />
                    <h4 className="d-flex flex-column justify-content-center align-items-center">
                      Requisitions by Category
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center px-2 gap-4">
                  <div className="d-flex flex-column justify-content-center gap-2 px-2">
                    <Chart
                      options={chart3.options}
                      series={chart3.series}
                      type="donut"
                      height="200"
                    />{" "}
                    <h4 className="d-flex flex-column justify-content-center align-items-center">
                      Requisition by Vendor
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center px-2 gap-4">
                  <div className="d-flex flex-column justify-content-center justify-items-center gap-2 px-2">
                    <Chart
                      options={chart6.options}
                      series={chart6.series}
                      type="donut"
                      height="200"
                    />{" "}
                    <h4 className="d-flex flex-column justify-content-center align-items-center">
                      Requisition by Division
                    </h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RequisitionDashboard;
