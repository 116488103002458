import React, { memo } from "react";
import { Card, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import CampaignMatrix from "./CampaignMatrix";

function VerticalTabs({ tabs }) {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={tabs[0].id}>
      <Row>
        <Col sm={3}>
          <Nav className="d-flex flex-column  flex-md-column nav-pills nav-pills-green nav-iconly gap-3 flex-wrap rounded-0">
            {tabs?.length > 0 &&
              tabs?.map(({ group_title, id }, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    key={id}
                    className="px-0 py-3"
                    eventKey={id}
                    data-bs-toggle="tab"
                    data-bs-target={`#nav-${id}`}
                    type="button"
                    role="tab"
                    aria-controls={`nav-${id}`}
                  >
                    <div
                      className="px-3 d-inline-block text-truncate w-100 max-tab-width"
                      title={group_title}
                    >
                      {findIcon("Layers", "dual-tone", 24)}
                      <span className="ms-2">{group_title}</span>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
        </Col>

        <Col sm={9}>
          <Tab.Content className="iq-tab-fade-up w-100">
            {console.log("tabs", tabs)}

            {tabs?.length > 0 &&
              tabs?.map(
                ({
                  id,
                  tags,

                  creatives,
                  specialities_count,
                  group_to_campaign,
                  target_impressions,
                  target_reach,
                  actual_impressions,
                }) => (
                  <Tab.Pane
                    key={id}
                    eventKey={id}
                    id={`nav-${id}`}
                    role="tabpanel"
                    aria-labelledby={`nav-${id}`}
                  >
                    <CampaignMatrix
                    id={id}
                      tags={tags?.length > 0 ? tags?.split(",") : []}
                      impressionChart={{
                        max: target_impressions || 0,
                        data:
                          actual_impressions &&
                          Array.isArray(actual_impressions) &&
                          actual_impressions.length > 0
                            ? actual_impressions[0].curr_impression
                            : 0,
                      }}
                      reachChart={{
                        max: target_reach || 0,
                        data:
                          actual_impressions &&
                          Array.isArray(actual_impressions) &&
                          actual_impressions.length > 0
                            ? actual_impressions[0].curr_reach
                            : 0,
                      }}
                      creatives={group_to_campaign}
                      countCard={{
                        clicks:
                          actual_impressions &&
                          Array.isArray(actual_impressions) &&
                          actual_impressions.length > 0
                            ? actual_impressions[0].curr_clicks
                            : 0,

                        ctr:
                          actual_impressions &&
                          Array.isArray(actual_impressions) &&
                          actual_impressions.length > 0
                            ? actual_impressions[0].curr_ctr
                            : 0,

                        creatives: creatives,
                        specialities: specialities_count,
                      }}
                      activeCampaignGroupId={id}
                    />
                  </Tab.Pane>
                )
              )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}

export default memo(VerticalTabs);
