import * as yup from "yup";

const addArticleInitialValues = {
  body: "",
  body_html: "",
  notes: "",
  reference: "",
  reference_html: "",
  referenceFile: null,
  title: "",
  title_html: "",
  coverPhoto: null,
  thumbnail: null,
  brand: "",
  topic: "",
  category: "",
  type: "",
  speciality: [],
  category: "",
  tags: [],
  is_featured: false,
  privacy_status: "",
  base_likes: 0,
  template_id: 0,
  content_to_env_price: [
    {
      env_id: "1",
      price: 0,
    },
  ],
};

const articleSchema = yup.object().shape({
  title: yup.string().required("Please Enter a title"),
  title_html: yup.string().required("Please Enter a title"),
  body: yup.string().required("Please Enter a description"),
  body_html: yup.string().required("Please Enter a description"),
  reference: yup.string().required("Please Enter a references"),
  is_featured: yup.boolean(),
  thumbnail: yup.mixed().required("Please add a Thumbnail"),
  coverPhoto: yup.mixed().required("Please add a Cover Image"),
  // brand: yup.string().required("Please select a brand"),
  notes: yup.string(),
  // privacy_status: yup.string(),
  speciality: yup.array().min(1, "Please select at least one speciality"),

  // tags: yup.array().min(1, "Please select at least one tag"),
});

export { addArticleInitialValues, articleSchema };
