export const HCP_LISTING = "/hcp/list";
export const HCP_DETAILS = "/hcp/detail";
export const HCP_ACTIVITY = "/hcp/activity"; //
export const HCP_ENGAGEMENT_METER_DATA = "/hcp/get_engagement_meter_data";
export const HCP_DAY_OF_WEEK = "/hcp/day_of_week";
export const HCP_NBA_VIEW = "/hcp/get_nba_view";
export const HCP_TIME_OF_DAY = "/hcp/time_of_day";
export const HCP_ACTIVITY_TIMELINE = "/hcp/activity_timeline";
export const HCP_ALL_ENGAGEMENT_LIST = "/hcp/all_engagement";
export const HCP_REQUEST_DATA_CHNAGE = "/hcp/request_data_change";
export const HCP_UPDATE_SPECIALITY_STATUS =
  "/hcp/update_speciality_dashboard_status";
export const HCP_UPDATE_RECOMMENDATION_STATUS =
  "/hcp/update_recommendation_dashboard_status";

// hcp dashboard apis start
export const HCP_REQUEST_DATA_COUNT = "/hcp/data_count";
export const HCP_META_PROFILE = "/hcp/meta_profile_data";
export const HCP_REQUEST_ONBOARDING_BY_REGISTRATION = "/hcp/onboarding-by-registration";
export const HCP_REQUEST_REONBOARDING_BY_REGISTRATION = "/hcp/re-onboarding-by-registration";
export const HCP_GET_ONBOARDING_EFFICIENCY_APPROVAL = "/hcp/onboarding-efficiency/approval_rate";
export const HCP_GET_ONBOARDING_EFFICIENCY_TASK_RATE = "/hcp/onboarding-efficiency/task_rate";
export const HCP_GET_ONBOARDING_EFFICIENCY_TASK_COMPLITION = "/hcp/onboarding-efficiency";
export const HCP_GET_DATA_ANALYTICS_ON_REGISTER_DATE = "hcp/analytics_on_register_date";
export const HCP_GET_DATA_ANALYTICS_ON_ONBOARDED_DATE = "/hcp/analytics_on_onboarded_date";
export const HCP_GET_INDIA_TO_INTERNATIONAL_ANALYTICS = "/onboarding/india_to_international_chart";
export const HCP_GET_TOP_INTERNATIONAL_COUNTRIES_ANALYTICS = "/onboarding/top_ten_international_country";
export const HCP_GET_FILTER_DATA = "/hcp/master_filter";
// hcp dashboard apis end