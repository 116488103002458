import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import {
  useGetChannelAnalytics,
  useGetVideoAnalytics,
} from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import AudienceSkeleton from "../../../../../../skeleton/workspace/doctube/channel/analytics-audience-skeleton";
import AnalyticsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/analytics-skeleton";
import VideoAnalyticsOverviewSkeleton from "../../../../../../skeleton/workspace/doctube/channel/video-analytics-overview-skeleton";
import AnalyticsAudience from "../components/analytics/AnalyticsAudience";
import AnalyticsEngagement from "../components/analytics/AnalyticssEngagement";
import AnalyticsReach from "../components/analytics/AnalyticssReach";
import VideoAnalyticsOverview from "../components/analytics/VideoAnalyticsOverview";

import { useParams, useSearchParams } from "react-router-dom";
import TabNavigation from "../components/TabNavigation";
import {
  audienceFilterOptions,
  videoAnalyticsFilterOptions,
} from "../Constant/date-filter-constant";
import { selectProject } from "../../../../../../store/project/project.selector";
import { useSelector } from "react-redux";

function ChannelVideoAnalytics() {
  const { video_id } = useParams();
  const project = useSelector(selectProject);
  const [selectedTab, setSelctedTab] = useState("overview");
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = videoAnalyticsFilterOptions.find(
    (item) => item.initial
  );
  const sortOption = searchParams.get("sort") || initialDateFilterOption.value;

  const {
    data: overviewData,
    isLoading: isOverviewLoading,
    refetch: refetchOverview,
  } = useGetVideoAnalytics({
    video_id: video_id,
    type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
    analysis_type: "overview", // overview | reach | engagement | audience
    project_id: project?.project_id,
  });

  const {
    data: reachData,
    isLoading: isReachLoading,
    refetch: refetchReach,
  } = useGetVideoAnalytics({
    video_id: video_id,
    type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
    analysis_type: "reach", //  | reach | engagement | audience
    project_id: project?.project_id,
  });

  const {
    data: engagementData,
    isLoading: isEngagementLoading,
    refetch: refetchEngagement,
  } = useGetVideoAnalytics({
    video_id: video_id,
    type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
    analysis_type: "engagement", //  | reach | engagement | audience
    project_id: project?.project_id,
  });
  const {
    data: audienceData,
    isLoading: isAudienceLoading,
    refetch: refetchAudience,
  } = useGetVideoAnalytics({
    video_id: video_id,
    type: sortOption, // | "yearly" | "weekly || daily || yesterday || monthly"
    analysis_type: "audience", //  | reach | engagement | audience
    project_id: project?.project_id,
  });

  const tabs = [
    {
      title: "Overview",
      eventKey: "overview",
      Content: () => (
        <>
          {isOverviewLoading ? (
            <VideoAnalyticsOverviewSkeleton />
          ) : (
            overviewData &&
            Object.values(overviewData)?.length > 0 && (
              <VideoAnalyticsOverview data={overviewData} />
            )
          )}
        </>
      ),
    },
    {
      title: "Reach",
      eventKey: "reach",
      Content: () => (
        <>
          {isReachLoading ? (
            <AnalyticsSkeleton navSkeletonCount={2} />
          ) : (
            reachData &&
            Object.values(reachData)?.length > 0 && (
              <AnalyticsReach data={reachData} />
            )
          )}
        </>
      ),
    },
    {
      title: "Engagement",
      eventKey: "engagement",
      Content: () => (
        <>
          {isEngagementLoading ? (
            <AnalyticsSkeleton navSkeletonCount={2} />
          ) : (
            engagementData &&
            Object.values(engagementData)?.length > 0 && (
              <AnalyticsEngagement data={engagementData} />
            )
          )}
        </>
      ),
    },
    {
      title: "Audience",
      eventKey: "audience",
      Content: () => (
        <>
          {isAudienceLoading ? (
            <AudienceSkeleton />
          ) : (
            audienceData &&
            Object.values(audienceData)?.length > 0 && (
              <AnalyticsAudience data={audienceData} />
            )
          )}
        </>
      ),
    },
  ];
  const memorizedTabs = React.useMemo(
    () => tabs,
    [
      overviewData,
      reachData,
      engagementData,
      audienceData,
      isOverviewLoading,
      isReachLoading,
      isEngagementLoading,
      isAudienceLoading,
    ]
  );

  return (
    <>
      <Col className="" md={12}>
        <h4 className="fw-semibold mb-0">Video analytics</h4>
      </Col>
      <TabNavigation
        tabs={memorizedTabs}
        withSorting
        sortOptions={
          selectedTab == "audience"
            ? audienceFilterOptions
            : videoAnalyticsFilterOptions
        }
        onSelect={(e) => setSelctedTab(e)}
      />
    </>
  );
}

export default ChannelVideoAnalytics;
