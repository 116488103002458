import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const AddVendorLoader = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row>
                <Col lg="12" className="mb-3">
                  <Skeleton variant="text" width="15%" height={20} />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-4"
                  />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-4"
                  />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-4"
                  />
                </Col>
                <Col lg="6" xl="3" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-4"
                  />
                </Col>
                <Col lg="12" className="mb-3">
                <Skeleton variant="text" width="20%" height={20} />
                </Col>
                <Col lg="6" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-4"
                  />
                </Col>
                <Col lg="6" className="mb-4">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={45}
                    className="mb-4"
                  />
                </Col>
                <Col lg="12" className="mb-3">
                <Skeleton variant="text" width="15%" height={20} />
                </Col>
                <Col lg="6" className="mb-4">
                  <div className="d-flex w-100">
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={45}
                      className="mb-4"
                    />
                  </div>
                </Col>

                <Col lg="12" className="d-flex justify-content-end">
                  <Skeleton
                    variant="rounded"
                    width="10%"
                    height={40}
                    className="mb-4"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddVendorLoader;
