import React, { useState } from "react";
import { Badge, Collapse } from "react-bootstrap";

function CollapseTags({ tags }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <h5 className="fs-6 ms-2 my-3">Tags</h5>
      <div className="d-flex gap-1 flex-wrap">
        {tags &&
          tags?.length > 0 &&
          tags?.map((item, index) => {
            return index < 5 ? (
              <Badge
                key={index}
                pill
                bg="light text-success"
                className="ms-2 px-3 py-2 fs-7 lh-1 bg-opacity-25"
              >
                {item}
              </Badge>
            ) : null;
          })}
        {open
          ? null
          : tags?.length > 5 && (
              <Badge
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                pill
                className="ms-2 cursor-pointer fs-7 lh-1 bg-opacity-25"
                bg="light text-success"
              >
                ...
              </Badge>
            )}
      </div>
      {tags?.length > 5 && (
        <Collapse in={open}>
          <div id="example-collapse-text">
            {tags.slice(5).map((item, index) => (
              <Badge
                key={index + 5}
                pill
                bg="light text-success"
                className="mt-2 ms-2 px-3 py-2 fs-7 lh-1 bg-opacity-25"
              >
                {item}
              </Badge>
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
}

export default CollapseTags;
