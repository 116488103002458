import { memo, Fragment } from "react";

//Componets

import { OverlayTrigger, Card, Tooltip } from "react-bootstrap";

const CampaignStatsCard = memo((props) => {
  return (
    <Fragment>
      <Card className="flex-grow-1">
        <Card.Body className="position-relative p-3">
          <div className="d-flex align-items-center gap-3">
            <div
              className={`bg-soft-${props.color} p-2 rounded-3 flex-shrink-0 position-relative`}
            >
              {props.svg}
            </div>
            <div className="flex-grow-1">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="counter">{props.counter}</h4>
                <h4 className="counter h6">{props.percentage}</h4>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="text-dark">{props.content}</span>
                <OverlayTrigger
                  placement="auto-end"
                  overlay={<Tooltip>{props.tooltipContent}</Tooltip>}
                >
                  <div className="btn btn-icon btn-xs rounded-pill btn btn-dark">
                    <div className="btn-inner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        height="32"
                      >
                        <path
                          d="M8 10.5378C8 9.43327 8.89543 8.53784 10 8.53784H11.3333C12.4379 8.53784 13.3333 9.43327 13.3333 10.5378V19.8285C13.3333 20.9331 14.2288 21.8285 15.3333 21.8285H16C16 21.8285 12.7624 23.323 10.6667 22.9361C10.1372 22.8384 9.52234 22.5913 9.01654 22.3553C8.37357 22.0553 8 21.3927 8 20.6832V10.5378Z"
                          fill="currentColor"
                        ></path>
                        <rect
                          opacity="0.4"
                          x="8"
                          y="1"
                          width="5"
                          height="5"
                          rx="2.5"
                          fill="currentColor"
                        ></rect>
                      </svg>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
});

CampaignStatsCard.displayName = "CampaignStatsCard";
export default CampaignStatsCard;
