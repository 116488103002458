import { useCallback, useEffect, useState } from "react";
// import "./textEditor.css";
import Quill from "quill";
import "quill/dist/quill.snow.css";
// import * as Y from "yjs";
// import { WebsocketProvider } from "y-websocket";
// import { QuillBinding } from "y-quill";
// import QuillCursors from "quill-cursors";
// import ListGroup from "react-bootstrap/ListGroup";

// Quill.register("modules/cursors", QuillCursors);

const TOOLBAR_OPTIONS = {
  container: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [{ align: [] }],
    ["image", "blockquote", "code-block"],
    ["clean"],
  ],
};

const ReactQuill = ({ getQuillText }) => {
  const [socket, setSocket] = useState(null);
  const [quill, setQuill] = useState(null);

  // USEREF TO INITIALIZE QUILL IN A PROPER MANNER

  const wrapperRef = useCallback((wrapper) => {
    if (wrapper == null) return;
    wrapper.innerHTML = "";
    const editor = document.createElement("div");
    wrapper.append(editor);
    const get_Quill = new Quill(editor, {
      theme: "snow",
      modules: { toolbar: TOOLBAR_OPTIONS },
    });
    setQuill(get_Quill);
  }, []);

  // USEEFFECT TO GET EMBEDED HTML
  useEffect(() => {
    if (quill == null) return;

    const handler = () => {
      const delta = quill.getContents();
      const text = quill.getText();
      const html = quill.root.innerHTML;
      //   console.log("delta", delta);
      //   console.log("text", text);
      //   console.log("html", html);
      //   getQuillText(text)
      //  setText(text);
      //  setHtml(html);
    };

    quill.on("text-change", handler);
    return () => {
      quill.off("text-change", handler);
    };
  }, [quill]);
  return (
    <>
      <div
        className="d-flex flex-column mb-4 p-0 quillEditor"
        ref={wrapperRef}
      ></div>
    </>
  );
};

export default ReactQuill;
