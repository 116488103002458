import React from "react";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";

function CountCard({ count, text, icon, bgPrimary = true }) {
  // const { redirectTo } = useRedirect();

  // const handleOnclick = () => {
  //   redirectTo(onClickPath);
  // };

  return (
    <>
      <div
        className={`d-flex align-items-start gap-3 ${bgPrimary ? "bg-primary" : "border border-primary"
          } rounded-3 p-4`}
      // onClick={handleOnclick}
      >
        <div
          className={`rounded d-flex align-items-center ${bgPrimary ? "text-white" : "text-primary"
            }`}
        >
          {icon}
        </div>
        <div className="d-flex flex-column">
          <h3
            className={`fs-4 fw-semibold m-0 mb-1 ${bgPrimary ? "text-white" : "text-black"
              }`}
          >
            {count}
          </h3>
          {/* <CountUp start={10} end={240} duration={3} /> */}
          <h4
            className={`fs-6 fw-light m-0 ${bgPrimary ? "text-white-50" : "text-black-50"
              }`}
          >
            {text}
          </h4>
        </div>
      </div>
    </>
  );
}

export default CountCard;
