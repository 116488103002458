import React, { memo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../../components/skeleton";

function LayoutTabSkeleton() {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12} className="mb-3">
            <div className="mb-1">
              <Skeleton variant="text" width="15%" />
            </div>
            <Skeleton variant="text" width="30%" />
          </Col>
        </Row>

        {/*  */}
        <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
          <Skeleton
            variant="square"
            className="rounded-circle"
            width={40}
            height={40}
          />

          <div className="d-flex flex-column gap-4">
            <Skeleton variant="text" className="mb-0" width="100%" />
            <div className="d-flex gap-3">
              <Skeleton
                variant="square"
                width={180}
                height={80}
                className="rounded-3 "
              />
              <div>
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={100} />
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        {<TextLineCard />}

        {/*  */}
        <div className="mt-5 mb-3">
          <Skeleton variant="text" width="29%" />
        </div>

        {/*  */}
        {<TextLineCard height={40} />}

        {/*  */}
        <div className="mb-3  mt-5 w-100">
          <div className="mb-1">
            <Skeleton variant="text" width="15%" />
          </div>
          <Skeleton variant="text" width="30%" />
        </div>

        {/*  */}
        <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
          <Skeleton
            variant="square"
            className="rounded-circle"
            width={40}
            height={40}
          />

          <div className="d-flex flex-column w-100 ">
            <Skeleton variant="text" />
            <div className="d-flex gap-3 flex-wrap">
              {[...Array(3)].map((_, i) => (
                <Skeleton
                  key={i}
                  variant="square"
                  width={150}
                  height={90}
                  className="rounded-3 "
                />
              ))}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
          <Skeleton
            variant="square"
            className="rounded-circle"
            width={40}
            height={40}
          />

          <div className="d-flex flex-column w-100 ">
            <Skeleton variant="text" />
            <Skeleton variant="text" width="20%" />
            <div className="d-flex gap-3 flex-wrap">
              {[...Array(3)].map((_, i) => (
                <Skeleton
                  key={i}
                  variant="square"
                  width={120}
                  height={60}
                  className="rounded-3 "
                />
              ))}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(LayoutTabSkeleton);

function TextLineCard({ height = 20 }) {
  return (
    <div className="border p-3 rounded-3 d-flex gap-3 mb-3">
      <Skeleton
        variant="square"
        className="rounded-circle"
        width={40}
        height={40}
      />

      <div className="d-flex flex-column  w-100 ">
        <Skeleton variant="text" width="25%" />
        <div>
          <Skeleton
            variant="square"
            className="rounded-3"
            height={height}
            width="90%"
          />
        </div>
      </div>
    </div>
  );
}
