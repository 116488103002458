import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";
import AllVendor from "../pages/all-vendor";
import AddVendor from "../pages/add-vendor";


// pages

export const VendorManagementRouter = [
  {
    path: "/vendor-management",
    element: <Default />,
    children: [
      {
        path: "listing",
        element: <AllVendor />,
      },
      {
        path: "create",
        element: <AddVendor />,
      },
      {
        path: "edit-vendor/:id",
        element: <AddVendor />,
      },
    ],
  },
];
