import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFetchOnboardingLogTypes } from "../../../../queries/query-hooks/onboarding-hook";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
const AddLogModal = ({ show, modalClose, handleSubmit }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSubLogType, setSelectedSubLogType] = useState(null);
  const [hasChildren, setHasChildren] = useState(false);
  const { data: logTypeOptions } = useFetchOnboardingLogTypes();
  const [followupTime, setFollowupTime] = useState("");
  const [isFollowupSelected, setIsFollowupSelected] = useState(false);
  const [textInput, setTextInput] = useState("");

  const flattenString = (str) => {
    return str.split(" ").join("").toLowerCase();
  };
  const handleLogTypeChange = (e) => {
    let item = logTypeOptions.find((item) => item.id == e.target.value);
    console.log("item", item);
    if (isFollowupSelected) {
      setIsFollowupSelected(false);
    }
    setSelectedType([]);
    setSelectedType(item);
    setHasChildren(item?.child_log_type?.length > 0);
  };

  // const handleStatus = (e) => {
  //   let selectedStatus = selectedType?.child_log_type?.find(
  //     (item) => item.id == e.target.value
  //   );

  //   console.log("selectedStatus:", selectedStatus);
  //   setSelectedSubLogType(selectedStatus);

  //   if (flattenString(selectedStatus.name) == "followup") {
  //     setIsFollowupSelected(true);
  //   } else {
  //     setIsFollowupSelected(false);
  //   }
  //   console.log(
  //     "selectedStatus:",
  //     flattenString(selectedStatus.name) == "followup"
  //   );
  // };

  const handleStatus = (e) => {
    const selectedStatus = selectedType?.child_log_type?.find(
      (item) => item.id == e.target.value
    );

    if (selectedStatus) {
      setSelectedSubLogType(selectedStatus);

      if (flattenString(selectedStatus.name) === "followup") {
        setIsFollowupSelected(true);
      } else {
        setIsFollowupSelected(false);
      }
    } else {
      setSelectedSubLogType(null);
      setIsFollowupSelected(false);
    }
  };

  selectedType?.child_log_type?.map((item, index) => {
    console.log("item", item.name);
  });

  const handleSubmitLog = (e) => {
    e.preventDefault();
    if (!selectedType) {
      toast.error("Please select log type");
      return;
    }
    if (selectedType?.child_log_type?.length > 0 && !selectedSubLogType) {
      toast.error("Please select sub log type");
      return;
    }
    if (
      selectedType &&
      selectedSubLogType &&
      isFollowupSelected &&
      !followupTime
    ) {
      toast.error("Please select followup time");
      return;
    }
    if (!textInput) {
      toast.error("Please enter a comment");
      return;
    }
    let payload = {
      onboarding_log_type_id: selectedType.id,
      onboarding_log_subtype_id: selectedSubLogType?.id ?? 0,
      call_back_date: followupTime
        ? dayjs(followupTime).format("YYYY-MM-DD")
        : "",
      call_back_time: followupTime
        ? dayjs(followupTime).format("HH:mm:ss")
        : "",
      comment: textInput,
      // action_id: actionId,
      // hcp_id: hcpInformation?.hcp_id,
    };
    handleSubmit(payload);
  };
  return (
    <Modal
      show={show}
      onHide={modalClose}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-n4">
          {logTypeOptions && logTypeOptions?.length > 0 && (
            <Col className="mt-4" lg={hasChildren ? 6 : 12}>
              <Form.Floating
                controlId="floatingTextarea4"
                label="Select Type"
                className="custom-form-floating"
              >
                <Form.Select name="type" onChange={handleLogTypeChange}>
                  <option value="">Select</option>
                  {logTypeOptions?.map(({ id, name }) => (
                    <option value={id} key={name}>
                      {name}
                    </option>
                  ))}
                </Form.Select>

                <Form.Label>Select Type</Form.Label>
              </Form.Floating>
            </Col>
          )}

          {hasChildren && (
            <Col className="mt-4" lg={6}>
              {isFollowupSelected ? (
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="datetime-local"
                    id="exampleInputdatetime"
                    onChange={(e) => setFollowupTime(e.target.value)}
                  />
                </Form.Floating>
              ) : (
                <Form.Floating className="custom-form-floating">
                  <Form.Select name="status" onChange={handleStatus}>
                    {selectedType?.child_log_type?.length > 0 && (
                      <>
                        <option value="">Select Status</option>
                        {selectedType.child_log_type.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                  <Form.Label>Status</Form.Label>
                </Form.Floating>
              )}
            </Col>
          )}

          <Col lg={12} className="mt-4">
            <Form.Floating
              controlId="floatingTextarea2"
              label="Comments"
              className="custom-form-floating"
            >
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                onChange={(e) => setTextInput(e.target.value)}
              />
              <Form.Label>Comments</Form.Label>
            </Form.Floating>
          </Col>
          <Col
            lg={12}
            className="d-flex align-items-center justify-content-end mt-4"
          >
            <Button onClick={handleSubmitLog} disabled={!selectedType}>
              Submit
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
export default AddLogModal;
