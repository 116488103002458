import React, { memo } from "react";
import Card from "react-bootstrap/Card";
import Skeleton from "../../components/skeleton";

const HcpReonboardingSkeleton = () => {
  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between ">
        <Card.Title>
          <Skeleton width={100} />
          <Skeleton width={50} />
        </Card.Title>
        <Skeleton width={30} />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Skeleton width={450} height={450} />
      </Card.Body>
    </Card>
  ); 
};

export default memo(HcpReonboardingSkeleton);
