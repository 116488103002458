import { createSlice } from "@reduxjs/toolkit";

import { getStorage } from "../../utilities/setting";
import { channelInitialState } from "./channel.state";
export const channelSlice = createSlice({
  name: "channel",
  initialState: getStorage("channelState") || channelInitialState,
  reducers: {
    updateChannelInput: (state, action) => {
      state.channelUpdateInputs = {
        ...state.channelUpdateInputs,
        ...action.payload,
      };
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    setCurrentChannel: (state, action) => {
      state.currentChannel = action.payload;
    },
    setCurrentChannelVideo: (state, action) => {
      state.currentChannelVideo = action.payload;
    },
  },
});

export default channelSlice.reducer;
