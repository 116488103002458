import React from "react";
import { Row, Card, Col } from "react-bootstrap";
import Skeleton from "../../../components/skeleton";

export default function SegmentAnalyticsSkeleton() {
  return (
    <>
      <Row className="mt-n4 flex-wrap">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
          <Col lg={3} md={6} className="mt-4 d-flex" key={key}>
            <Card className="flex-grow-1 mb-0">
              <Card.Body className="p-3 h-100">
                <div className="d-flex gap-3">
                  <div
                    className={`bg-soft-primary d-inline-block avatar-50 rounded-3 flex-shrink-0`}
                  >
                    {/* <Skeleton variant="rounded" width={40} height={40} /> */}
                  </div>

                  <div className="flex-grow-1">
                    <h2 className="counter mb-0">
                      <Skeleton variant="rounded" width={30} height={30} />
                    </h2>
                    <h6 className={`mb-0 text-primary`}>
                      <Skeleton variant="text" width={100} height={15} />
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}
