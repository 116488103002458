import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function VideoDetailsSkeleton() {
  return (
    <Card>
      <Card.Body className="d-flex flex-column gap-5">
        {/* video and description  */}
        <div className="w-100">
          <Row>
            <Col md={12} className="mb-3">
              <Skeleton variant="text" width={100} />
            </Col>

            <Col xs={12} md={5} className="d-flex">
              <Skeleton
                variant="square"
                width="100%"
                className="rounded-3"
                height="100%"
              />
            </Col>

            <Col xs={12} md={7}>
              <div className="h-100 d-flex flex-column gap-3 ">
                <Skeleton
                  variant="square"
                  className="rounded-2"
                  width="100%"
                  height={50}
                />
                <Skeleton
                  variant="square"
                  className="rounded-2"
                  width="100%"
                  height={120}
                />
              </div>
            </Col>
          </Row>
        </div>

        {/* Ai generated description */}
        <div className="w-100">
          <Row className="bg-soft-dark mx-n4">
            <Col className="p-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <h5 className="mb-1">
                    <Skeleton variant="text" width={100} />
                  </h5>
                  <p className="mb-0">
                    <Skeleton variant="text" width={250} />
                  </p>
                </Col>
                <Col xs={12}>
                  <div
                    className="d-flex gap-2 overflow-auto w-100"
                    style={{
                      WebkitOverflowScrolling: "none",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {[...Array(6)].map((_, index) => (
                      <div key={index} className="me-3">
                        <div
                          className="border rounded-3 bg-white p-3 d-flex flex-column gap-2 transition"
                          style={{ width: "220px" }}
                        >
                          <div>
                            <Skeleton variant="text" width={100} />
                            <Skeleton
                              variant="square"
                              width="100%"
                              height={50}
                            />
                          </div>
                          <div>
                            <Skeleton variant="text" width={150} />
                            <Skeleton
                              variant="square"
                              width="100%"
                              height={100}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* encoding and thumbnail */}
        <div className="w-100">
          <Row>
            <Col md={6}>
              <Row className="flex-column">
                <Col xs={12} className="mb-3">
                  <Skeleton variant="text" width={80} />
                  <Skeleton variant="text" width={200} />
                </Col>

                <Col xs={12}>{<EncodingDetailsSkeleton />}</Col>
              </Row>
            </Col>

            {/* thumbnail */}
            <Col md={6}>
              <Row className="flex-column">
                <Col xs={12} className="mb-3">
                  <Skeleton variant="text" width={80} />
                  <Skeleton variant="text" width={200} />
                </Col>

                <Col xs={12}>
                  <div
                    className="d-flex overflow-auto scroll-x w-100"
                    style={{
                      WebkitOverflowScrolling: "touch",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    {[...Array(4)].map((_, index) => (
                      <div key={index} className="me-3">
                        <div className="d-flex flex-column transition">
                          <div>
                            <Skeleton
                              className="rounded-3"
                              variant="square"
                              width={125}
                              height={170}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* tags */}
        <div className="w-100">
          <Row>
            <Col xs={12} className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-1">
                <Skeleton variant="text" width="10%" />
                <Skeleton variant="text" width="10%" />
              </div>
              <Skeleton
                variant="square"
                className="rounded-2"
                height={10}
                width="50%"
              />
            </Col>

            <Col md={12}>
              <Skeleton
                variant="square"
                className="rounded-4"
                width={"100%"}
                height={45}
              />
              <Skeleton variant="text" width="20%" className="mt-2" />
            </Col>
          </Row>
        </div>

        {/* language */}
        {<DropDownSkeleton />}

        {/* Transcription */}
        <div className="w-100">
          <Row>
            <Col xs={12} className="d-flex gap-4 align-items-center">
              <Skeleton variant="text" width="15%" />
              <Skeleton variant="text" width={30} />
            </Col>
          </Row>
        </div>

        {/* Category and Schedule Video */}
        {<DropDownSkeleton />}

        {/* Schedule */}
        <div className="w-100">
          <Row className="mx-md-n5">
            {[1, 2].map((_, index) => (
              <Col md={5} key={index} className="mb-4 px-md-5">
                <Skeleton variant="text" className="mb-3" width="30%" />

                <Row className="mx-n2">
                  <Col xs={8} className="px-2">
                    <Skeleton
                      variant="square"
                      className="rounded-2"
                      height={40}
                      width="100%"
                    />
                  </Col>

                  <Col xs={4} className="px-2">
                    <Skeleton
                      variant="square"
                      className="rounded-2"
                      height={40}
                      width="100%"
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>

        {/* License */}
        {<DropDownSkeleton />}

        {/* Publish */}
        <div className="w-100">
          <Col xs={12} className="mb-3">
            <Skeleton variant="text" />
          </Col>
          <Row>{<CheckboxSkeleton />}</Row>
        </div>

        {/* Comments */}
        <div className="w-100">
          <Col xs={12} className="mb-3">
            <Skeleton variant="text" />
            <Skeleton variant="text" width={200} />
          </Col>
          <Row>{<CheckboxSkeleton />}</Row>
        </div>

        {/* button */}
        <div className="w-100 d-flex">
          <div className="ms-auto d-flex flex-wrap gap-2">
            {[1, 2].map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  variant="square"
                  className="rounded-2"
                  width={100}
                  height={30}
                />
              );
            })}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(VideoDetailsSkeleton);

function DropDownSkeleton() {
  return (
    <div className="w-100">
      <Row>
        <Col xs={12} className="mb-3">
          <Skeleton variant="text" width="10%" />
          <Skeleton
            variant="square"
            className="rounded-2"
            height={10}
            width="32%"
          />
        </Col>
        <div>
          <Row>
            <Col sm={8} md={4} xs={12}>
              <Skeleton
                variant="square"
                className="rounded-2"
                width="100%"
                height={40}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
}

function CheckboxSkeleton() {
  return (
    <Col>
      {[1, 2].map((_, index) => (
        <div key={index} className="d-flex gap-2">
          <Skeleton variant="text" width={20} />
          <Skeleton variant="text" width={170} />
        </div>
      ))}
    </Col>
  );
}

function EncodingDetailsSkeleton() {
  return (
    <div className="mb-3 rounded-3 bg-soft-danger p-md-4 p-3">
      <div className="mx-n2 row mt-n2 mb-0">
        {[1, 2].map((_, index) => (
          <Col
            key={index}
            xs={12}
            md={6}
            className="mt-2 px-2 d-flex flex-row gap-4"
          >
            <div className="d-flex flex-column gap-2">
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </div>
            <div className="d-flex flex-column gap-2">
              <Skeleton variant="text" width={150} />
              <Skeleton variant="text" width={150} />
              <Skeleton variant="text" width={150} />
              <Skeleton variant="text" width={150} />
            </div>
          </Col>
        ))}
      </div>
    </div>
  );
}
