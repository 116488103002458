import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import Plotly from "plotly.js-dist";
import { Col, Row, Dropdown } from "react-bootstrap";
import { useOnboardingEfficiency } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";

export default function OnboardingEfficiencyGraph() {
  /**
   * Onboarding Efficiency By Task complition  query
   */

  const plotlyConfig = {
    displayModeBar: false,
  };
  const onboardingEfficiencyGraph = {
    title: {
      text: "",
    },
    height: 350,
    showlegend: true,
    legend: {
      x: 0,
      xanchor: "bottom",
      y: 1,
    },
  };

  const { data: onboardingApproval, isLoading: isOnboardingApprovalLoading } =
    useOnboardingEfficiency("approval_rate");

  const { data: onboardingTaskRate, isLoading: isOnboardingTaskRateLoading } =
    useOnboardingEfficiency("task_rate");

  const {
    data: onboardingTaskComplitionRate,
    isLoading: isOnboardingTaskComplitionLoading,
  } = useOnboardingEfficiency("task_complition");

  //  ---
  console.log("task_rate", onboardingTaskRate);
  console.log("approval_rate", onboardingApproval);
  console.log("task_complition", onboardingTaskComplitionRate);

  useEffect(() => {
    if (onboardingApproval && onboardingApproval?.data?.length > 0) {
      Plotly.newPlot(
        "onboarding-efficiency-by-approval",
        onboardingApproval?.data,
        onboardingEfficiencyGraph,
        plotlyConfig
      );
    }
  }, [onboardingApproval]);

  // useEffect(() => {
  //   if (onboardingTaskRate && onboardingTaskRate?.data?.length > 0) {
  //     const data = onboardingTaskRate.data.map((series) => ({
  //       mode: "lines",
  //       type: "scatter",
  //       name: series.name,
  //       x: series.x,
  //       y: series.y,
  //       textposition: "top center",
  //       text: series.name,
  //     }));

  //     Plotly.newPlot(
  //       "onboarding-efficiency-by-task-rate",
  //       data,
  //       onboardingEfficiencyGraph,
  //       plotlyConfig
  //     );
  //   }
  // }, [onboardingTaskRate]);

  useEffect(() => {
    if (onboardingTaskRate && onboardingTaskRate?.data?.length > 0) {
      // const data = onboardingTaskRate.data.map((series) => ({
      //   ...series,
      //   textposition: "top center",
      //   text: series.name,
      // }));

      Plotly.newPlot(
        "onboarding-efficiency-by-task-rate",
        onboardingTaskRate?.data,
        onboardingEfficiencyGraph,
        plotlyConfig
      );
    }
  }, [onboardingTaskRate]);

  useEffect(() => {
    if (
      onboardingTaskComplitionRate &&
      onboardingTaskComplitionRate?.data?.length > 0
    ) {
      Plotly.newPlot(
        "onboarding-efficiency-by-task-complition",
        onboardingTaskComplitionRate?.data,
        onboardingEfficiencyGraph,
        plotlyConfig
      );
    }
  }, [onboardingTaskComplitionRate]);

  return (
    <Card className="flex-grow-1">
      <Card.Header className="p-2 p-md-3 pb-md-0">
        {!isOnboardingApprovalLoading ? (
          <h5 className="mx-0 mx-md-2 my-0">Onboarding Efficiency</h5>
        ) : (
          <Skeleton width={100} />
        )}
      </Card.Header>
      <Row>
        <Col lg={4}>
          {!isOnboardingApprovalLoading ? (
            <div id="onboarding-efficiency-by-approval"></div>
          ) : (
            <Skeleton width={280} height={300} />
          )}
        </Col>
        <Col lg={4}>
          {!isOnboardingTaskRateLoading ? (
            <div id="onboarding-efficiency-by-task-rate"></div>
          ) : (
            <Skeleton width={280} height={300} />
          )}
        </Col>
        <Col lg={4}>
          {!isOnboardingTaskComplitionLoading ? (
            <div id="onboarding-efficiency-by-task-complition"></div>
          ) : (
            <Skeleton width={280} height={300} />
          )}
        </Col>
      </Row>
      {/* </Card.Body> */}
    </Card>
  );
}

// const yAxisConfig = {
//   tickvals: data
//     .flatMap((serie) => serie.y)
//     .filter(
//       (value) =>
//         value !== 1 &&
//         value !== 0.5 &&
//         value !== 0 &&
//         value !== -0.5 &&
//         value !== -1
//     ),
// };
// { ...onboardingEfficiencyGraph, yaxis: yAxisConfig },
