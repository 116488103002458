export const SEGMENT_CREATE_POST = "/segment/create";
export const SEGMENT_SEGMENT_POST = "/segment/create";

export const SEGMENT_EDIT_GET = "/segment/edit";
export const SEGMENT_ADD_POST = "/segment/add";
export const SEGMENT_LIST_POST = "/segment/list";
export const SEGMENT_DETAILS_GET = "/segment/detail";

export const SEGMENT_ANALYTICS_GET = "/segment/analytics";
export const SEGMENT_REFRESH_ANALYTICS_GET = "/segment/refresh";
export const SEGMENT_DETAIL_ANALYTICS_POST = "/segment/analytic_details";
export const SEGMENT_UPDATE_POST = "/segment/update";
export const SEGMENT_HCP_LIST_GET = "/segment/hcp_list";
export const SEGMENT_SIF_GET = "/segment/sif";
export const SEGMENT_REPORT_GET = "/segment/report";
export const SEGMENT_RECOMMENTDED_GET = "/segment/recommended";
