import React from "react";
import SponsoredLayout from "../layouts/sponsored-layout";

import Summary from "../pages/summary";
import Location from "../pages/location";
import Analytics from "../pages/analytics";
import Audience from "../pages/audience";
import Content from "../pages/content";
import RequestConetnt from "../pages/request-conetnt";
import ContentDetails from "../pages/content-details";

export const SponsoredContentRouter = [
  {
    path: "sponsored-content",
    element: <SponsoredLayout />,
    children: [
      {
        path: "summary/:project_id",
        element: <Summary />,
      },
      {
        path: "analytics/:project_id",
        element: <Analytics />,
      },
      {
        path: "audience/:project_id",
        element: <Audience />,
      },
      {
        path: "location/:project_id",
        element: <Location />,
      },
      {
        path: "content/:project_id",
        element: <Content />,
      },
      {
        path: ":project_id/content/details/:content_id",
        element: <ContentDetails />,
      },
      {
        path: "request-conetnt/:project_id",
        element: <RequestConetnt />,
      },
    ],
  },
];
