export const ONBOARDING_STATUS_CONSTANTS = {
  NEW: 1,
  ASSIGN: 2,
  IN_PROGRESS: 3,
  ONBOARDED: 4,
  APPROVED: 5,
  REJECTED: 6,
};
export const dateFilterOptions = {
  options: ["daily", "weekly", "monthly", "yearly"],
  initial: "yearly",
};

export const dateRangeOptions = [
  {
    label: "Last 3 months",
    value: "03",
    initial: true,
  },
  {
    label: "Last 6 months",
    value: "06",
    initial: false,
  },
  {
    label: "This Year",
    value: new Date().getFullYear().toString(),
    initial: false,
  },
];

export const yearlyFilterOptions = [
  {
    label: "This Year",
    value: new Date().getFullYear().toString(),
    initial: true,
  },
  {
    label: "Year 2023",
    value: "2023",
    initial: false,
  },
  {
    label: "Year 2022",
    value: "2022",
    initial: false,
  },
  {
    label: "Year 2021",
    value: "2021",
    initial: false,
  },
  {
    label: "Year 2020",
    value: "2020",
    initial: false,
  },
];
