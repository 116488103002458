import React, { useState, useContext, memo, Fragment } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Nav,
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

import SidebarMenu from "../../../../../components/partials/components/sidebar/sidebar-menu";
import { findIcon } from "../../../../dashboard/icons";

const EventsVerticalNav = memo(() => {
  let location = useLocation();
  let { pathname } = location;
  const { project_id } = useParams();

  return (
    <Fragment>
      <ul className="sub-nav">
        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/events/summary/${project_id}`}
          title="Summary"
          minititle={findIcon("Dashboard", "outline", 20)}
        >
          <i className="icon">{findIcon("Dashboard", "outline", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/events/user-engagement/${project_id}`}
          title="User Engagement"
          minititle={findIcon("Audience", "dual-tone", 20)}
        >
          <i className="icon">{findIcon("Audience", "dual-tone", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/events/analytics/${project_id}`}
          title="Analytics"
          minititle={findIcon("Overview", "dual-tone", 20)}
        >
          <i className="icon">{findIcon("Overview", "dual-tone", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/events/audience-insights/${project_id}`}
          title="Audience Insights"
          minititle={findIcon("Creative", "dual-tone", 20)}
        >
          <i className="icon">{findIcon("Creative", "dual-tone", 20)}</i>
        </SidebarMenu>

        <SidebarMenu
          isTag={"true"}
          staticIcon="false"
          pathname={`/events/location-insights/${project_id}`}
          title="Location Insights"
          minititle={findIcon("Location", "outline", 22)}
        >
          <i className="icon">{findIcon("Location", "outline", 22)}</i>
        </SidebarMenu>
      </ul>
    </Fragment>
  );
});

export default EventsVerticalNav;
