import dayjs from "dayjs";
import html2canvas from "html2canvas";
import app_config from "../data/app_config";
const relativeTime = require("dayjs/plugin/relativeTime");
const LocalizedFormat = require("dayjs/plugin/localizedFormat");
const utc = require("dayjs/plugin/utc");
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export function titleCase(string) {
  if (typeof string === "number") return string;
  const excludedStrings = ["iOS"];
  if (excludedStrings.includes(string)) return string;
  if (string?.toLowerCase() === "ai") {
    return "DDX";
  }
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const transformMapData = (mapData) => {
  const output = {};
  for (const [key, value] of Object.entries(mapData)) {
    const formattedKey = key.replace(/_/g, " ");
    output[formattedKey] = {
      value: value || 0,
    };
  }
  return output;
};

export const transformHeatMapData = (
  inputArray,
  xKey = "name",
  yKey = "reach"
) => {
  return inputArray.map((data) => ({
    x: data[xKey] || 0,
    y: data[yKey] || 0,
  }));
};

export function transformGraphData(data, seriesKey, labelKey) {
  if (data?.length > 0) {
    return data?.reduce(
      (acc, item) => ({
        series: [...acc.series, item[seriesKey]],
        label: [...acc.label, titleCase(item[labelKey])],
      }),
      { series: [], label: [] }
    );
  } else {
    return { series: [], label: [] };
  }
}

export function transformToPolarGraphData(data) {
  let labels = [];
  let series = [];

  if (data) {
    for (let key in data) {
      if (key === "total") continue;
      labels.push(key);
      series.push(typeof data[key] === "number" ? data[key] : 0);
    }
    return {
      labels: labels,
      series: series,
    };
  } else {
    return { series: [], label: [] };
  }
}

export function getWeeksBetween(startDate, endDate) {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  // Ensure end date is after start date
  if (!end.isAfter(start)) {
    return 0; // Handle cases where end date is before or equal to start date
  }

  // Calculate the difference in days, considering weeks start on Sundays
  const diffInDays =
    end.diff(start, "day") + (start.day() === 0 ? 0 : 6 - start.day());

  // Calculate the number of weeks (rounding down for partial weeks)
  return Math.floor(diffInDays / 7);
}

export function stringToSlug(str) {
  return str
    .toLowerCase() // Convert to lowercase
    .trim() // Trim whitespace from both sides
    .replace(/[\s\W_-]+/g, "-") // Replace spaces, non-word characters, and underscores with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
}
export const getRelativeDate = (date) => {
  return dayjs().from(dayjs(date));
};
export const formatLocalizedDate = (_date, _format) => {
  console.log(dayjs(_date).format(_format));
  return dayjs(_date).format(_format);
};

export function formatSecondToTime(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    throw new Error("Invalid seconds value");
  }

  if (seconds > 120) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 120) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${Math.ceil(hours)}:${Math.ceil(remainingMinutes)}:${Math.ceil(
        remainingSeconds
      )} Hours`;
    } else {
      return `${Math.ceil(minutes)}:${Math.ceil(remainingSeconds)} Minutes`;
    }
  } else {
    return `${Math.ceil(seconds)} seconds`;
  }
}
export function secondsToHms(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    throw new Error("Invalid seconds value");
  }

  const totalSeconds = Math.round(Number(seconds));

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const secs = (totalSeconds % 3600) % 60;

  if (hours > 0) {
    return `${hours} Hours ${minutes} Minutes ${secs} seconds`;
  } else if (minutes > 0) {
    return `${minutes} Minutes ${secs} seconds`;
  } else {
    return `${secs} seconds`;
  }
}

export const storages = {
  local: "localStorage",
  session: "sessionStorage",
};

export const nameValidator = (name = "") => {
  const nameRegex = /^[A-Za-z ]+$/;
  if (name != "") {
    return nameRegex.test(name?.trim());
  } else {
    return false;
  }
};
export const phoneNumberValidator = (number = "") => {
  const phoneNumberRegex = /^\+?[1-9][0-9]{7,14}$/;
  if (number != "") {
    return phoneNumberRegex.test(number?.trim());
  } else {
    return false;
  }
};
export const emailValidator = (email = "") => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email != "") {
    return emailRegex.test(email?.trim());
  } else {
    return false;
  }
};
export const multiSelectOptHandler = (selectedOptions = []) => {
  if (selectedOptions && selectedOptions.length > 0) {
    let selectedValueArr = selectedOptions.map((i) => i.value);
    return selectedValueArr;
  } else {
    return [];
  }
};

// create a function to map the status to bg props of Badge react-bootsrap
export const statusToBg = (status) => {
  switch (status) {
    case 1:
      return "danger";
    case 2:
      return "secondary";
    case 3:
      return "primary";
    case 4:
      return "warning";
    case 5:
      return "info";
    case 6:
      return "success";
    default:
      return "info";
  }
};

export const changeTableStateToPayload = (tableState) => {
  let { pageIndex, pageSize, sortBy, filters } = tableState;
  let payload = {};
  payload.default = "all";
  payload.pagination = {
    limit: pageSize,
    offset: pageIndex * pageSize,
  };
  payload.sort =
    sortBy.length > 0
      ? {
          [sortBy[0].id]: sortBy[0].desc ? "desc" : "asc",
        }
      : {};

  if (filters.length > 0) {
    payload.filter = {};

    // if same type of filter is applied on same column then it will be merged into one
    filters.forEach((filter) => {
      if (payload.filter[filter.id]) {
        payload.filter[filter.id].value.push(filter.value);
      } else {
        payload.filter[filter.id] = {
          type:
            typeof filter.value === "object" &&
            "start" in filter.value &&
            "end" in filter.value
              ? "range"
              : isNaN(parseInt(filter.value))
              ? "like"
              : "eq",
          value: filter.value,
        };
      }
    });
  }

  return payload;
};
export const changePayloadToTableState = (payload) => {
  if (!payload) return { pageIndex: 0, pageSize: 10, sortBy: [], filters: [] };
  let { pagination, sort, filter } = payload;
  let tableState = {};
  if (!pagination) {
    pagination = {
      limit: 10,
      offset: 0,
    };
  }
  tableState.pageIndex = pagination.offset / pagination.limit;
  tableState.pageSize = pagination.limit;
  tableState.sortBy = [];
  if (sort) {
    if (Object.keys(sort).length > 0) {
      tableState.sortBy.push({
        id: Object.keys(sort)[0],
        desc: sort[Object.keys(sort)[0]] === "desc" ? true : false,
      });
    }
  }
  tableState.filters = [];
  if (filter) {
    if (Object.keys(filter).length > 0) {
      Object.keys(filter).forEach((key) => {
        // for (const _eachFilterValue of filter[key].value) {
        tableState.filters.push({
          id: key,
          value: filter[key].value,
        });
        // }
      });
    }
  }

  return tableState;
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const getFormatedDate = (date) => {
  //format using dayjs
  return date ? dayjs(date).format("DD MMM YYYY") : "";
};
export const getSubtext = (text, length = 20) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};
export const parseQueryString = (queryString) => {
  let params = {};
  let searchParams = new URLSearchParams(queryString);
  for (let key of searchParams.keys()) {
    params[key] = searchParams.get(key);
  }

  return params;
};

export function formatNumberWithCommas(number) {
  if (number) {
    const numStr = number.toString();
    const parts = numStr.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";

    // Format the integer part
    let formattedInteger = integerPart.replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");

    return formattedInteger + decimalPart;
  } else {
    return 0;
  }
}

export const previewSecondstoMinutes = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}.${remainingSeconds}`;
};
export function getValuesByKey(obj, key) {
  const values = [];

  function recursiveSearch(obj) {
    if (typeof obj === "object") {
      for (let prop in obj) {
        if (prop === key) {
          values.push(obj[prop]);
        } else {
          recursiveSearch(obj[prop]);
        }
      }
    }
  }

  recursiveSearch(obj);
  return values;
}
export function modifyKeyNames(array, keyMappings) {
  return new Promise((resolve) => {
    const modifiedArray = [];

    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      const newObj = {};

      for (let key in keyMappings) {
        if (obj.hasOwnProperty(key)) {
          const newKey = keyMappings[key];
          newObj[newKey] = obj[key];
        }
      }

      modifiedArray.push(newObj);
    }

    resolve(modifiedArray);
  });
}
export const findValueByField = (data = [], field) => {
  if (!data?.length) return "";
  if (!field) return "";
  if (!data.some((item) => item?.field === field)) return "";
  return data?.find((item) => item?.field === field)?.value || "";
};

export const findValueByKey = (obj, key) => {
  if (!obj) return "";
  if (!key) return "";
  if (!obj.hasOwnProperty(key)) return "";
  try {
    let parsed = JSON.parse(obj[key]);
    return parsed;
  } catch (error) {
    return obj[key];
  }
};

export function convertBytes(bytes, unit = "KB") {
  console.log("convertBytes", bytes);
  if (isNaN(bytes) || bytes < 0) {
    throw new Error("Invalid byte value");
  }

  let converted;
  let unitLabel;

  switch (unit.toUpperCase()) {
    case "AUTO":
      if (bytes > 1000 * 1024) {
        converted = bytes / (1024 * 1024);
        unitLabel = "MB";
      } else {
        converted = bytes / 1024;
        unitLabel = "KB";
      }
      break;
    case "MB":
      converted = bytes / (1024 * 1024);
      unitLabel = "MB";
      break;

    case "KB":
    default:
      converted = bytes / 1024;
      unitLabel = "KB";
      break;
  }

  return `${converted.toFixed(2)} ${unitLabel}`;
}

export const constructImage = (image) => {
  if (!image) return "";

  if (image?.startsWith("https://")) {
    return image;
  } else {
    return `${app_config.upload_file_destination}${image}`;
  }
};

export const downloadTablePNG = async (tableRef) => {
  if (!tableRef || !tableRef.current) {
    throw new Error("Invalid table reference provided.");
  }

  try {
    const canvas = await html2canvas(tableRef.current, {
      scale: 2, // Adjust for higher resolution screenshots (optional)
      logging: false, // Suppress console warnings
    });

    const imageData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = imageData;
    link.download = "image.png";
    link.click();
  } catch (error) {
    console.error("Error taking screenshot:", error);
    // Handle errors gracefully (e.g., display an error message to the user)
  }
};

export function openInNewTab(url) {
  window.open(url, "_blank");
}

function arrangePrincipalEntity(data = []) {
  let principalEntity = [];
  data.forEach((_) => {
    if (_.principal_entity_parent_id == 0) {
      principalEntity.push({
        principal_entity_id: _.principal_entity_id,
        principal_entity_name: _.principal_entity_name,
        principal_entity_parent_id: null,
      });
    } else {
      let parent = data.find(
        (__) => __.principal_entity_id == _.principal_entity_parent_id
      );
      let parentOfParent = data.find(
        (__) => __?.principal_entity_id == parent?.principal_entity_parent_id
      );
      principalEntity.push({
        principal_entity_id: _.principal_entity_id,
        principal_entity_name:
          (parent && parent?.principal_entity_name
            ? parent?.principal_entity_name + " -> "
            : "") + _.principal_entity_name,
        principal_entity_parent_id: parentOfParent?.principal_entity_id || null,
      });
    }
  });
  return principalEntity;
}
export function getPrincipleEntitiesForSelect(data = []) {
  if (data.length === 0) return [];
  let principalEntity = arrangePrincipalEntity(data);
  if (principalEntity.every((_) => _.principal_entity_parent_id == null)) {
    return principalEntity.map((_) => ({
      value: _.principal_entity_id,
      label: _.principal_entity_name,
    }));
  } else {
    return getPrincipleEntitiesForSelect(principalEntity);
  }
}
