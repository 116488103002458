import React,{memo} from "react";
import Skeleton from "../../../../../components/skeleton";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useGetTimeOfDayData } from "../../../../../queries/query-hooks/hcp-profile-hook";
import { useParams } from "react-router-dom";

function TimeOfDayAnalysis({ hcpId, colors,commonPayload }) {
  // const { id: hcpId } = useParams();

  // const commonPayload = { hcp_id: hcpId };

  const { data: timeOfDay, isLoading: istimeOfDayLoading } =
    useGetTimeOfDayData(commonPayload, !!hcpId);

  // const getVariableColor = () => {
  //   let prefix =
  //     getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
  //   if (prefix) {
  //     prefix = prefix.trim();
  //   }
  //   const color1 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}primary`
  //   );
  //   const color2 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}info`
  //   );
  //   const color3 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}primary-tint-20`
  //   );
  //   const color4 = getComputedStyle(document.body).getPropertyValue(
  //     `--${prefix}warning`
  //   );
  //   return {
  //     primary: color1.trim(),
  //     info: color2.trim(),
  //     warning: color4.trim(),
  //     primary_light: color3.trim(),
  //   };
  // };

  // const variableColors = getVariableColor();

  // const colors = [
  //   variableColors.primary,
  //   variableColors.info,
  //   variableColors.warning,
  // ];

  const dayTimePreferences = {
    width: "100%",
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 0,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: timeOfDay?.data?.categories
          ? timeOfDay?.data?.categories
          : [],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [],
  };

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between">
        {!istimeOfDayLoading ? (
          <div className="header-title">
            <h4 className="card-title">Time Of Day Analysis</h4>
          </div>
        ) : (
          <Skeleton width={200} height={40} />
        )}
      </Card.Header>
      <Card.Body>
        <div className="">
          {!istimeOfDayLoading && timeOfDay?.data?.series ? (
            <Chart
              options={dayTimePreferences.options}
              series={timeOfDay?.data?.series}
              type="line"
              height="350"
            />
          ) : istimeOfDayLoading ? (
            <Skeleton
              variant="text"
              width={"100%"}
              height={350}
              className="mb-2"
            />
          ) : (
            <Chart
              options={dayTimePreferences.options}
              series={dayTimePreferences.series}
              type="line"
              height="350"
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default memo(TimeOfDayAnalysis);
