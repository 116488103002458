import React,{memo} from 'react'
import { Card } from "react-bootstrap";
import Skeleton from '../../components/skeleton';

 function HcpCountCardSkeleton() {
    return (
        <Card >
            <Card.Body className={"text-secondary rounded-3 cursor-pointer"}>
                <div className="d-flex justify-content-between ">
                    <div className={"text-secondary rounded p-3 d-flex align-items-center"}>
                        <Skeleton width={50} height={50} variant="circular" />
                    </div>
                    <div className="d-flex flex-column justify-content-end align-items-end cursorPointer">
                        <span className="fs-5"><Skeleton width={120} /></span>
                        <h4 className="mt-4 fw-semibold">
                            <Skeleton />
                        </h4>
                    </div>
                </div>
            </Card.Body>
        </Card >
    )
}

export default memo(HcpCountCardSkeleton)