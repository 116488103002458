import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../api/instances/axiosInstance";

import {
  GET_EVENTS_ALL_EVENTS_LIST,
  GET_EVENTS_ALL_PROJECT_EVENTS_LIST,
  GET_EVENTS_BROWSER_DISTRIBUTION,
  GET_EVENTS_CONTENT_QUESTION,
  GET_EVENTS_CONTENT_REGISTRATION,
  GET_EVENTS_CONTENT_VIEWS,
  GET_EVENTS_DAYWISE_SLOT_DISTRIBUTION,
  GET_EVENTS_DEVICE_DISTRIBUTION,
  GET_EVENTS_ENGAGEMENT_REPORT,
  GET_EVENTS_LOCATION_DISTRIBUTION,
  GET_EVENTS_LOCATION_REPORT,
  GET_EVENTS_MAJOR_CITIES,
  GET_EVENTS_OS_DISTRIBUTION,
  GET_EVENTS_PARTICIPANT_LIST,
  GET_EVENTS_REGISTRATION_BY_SLOT,
  GET_EVENTS_SPECIALITY_BREAKUP,
  GET_EVENTS_SUMMARY_CONTENT_INFO,
  GET_EVENTS_VIEW_BY_SEGMENT,
  GET_EVENTS_ZONAL_DISTRIBUTION,
} from "../../../api/endpoints/events-endpoints";
import {
  KEY_EVENTS_BROWSER_DISTRIBUTION,
  KEY_EVENTS_CONTENT_REGISTRATION,
  KEY_EVENTS_CONTENT_VIEWS,
  KEY_EVENTS_DAYWISE_SLOT_DISTRIBUTION,
  KEY_EVENTS_DEVICE_DISTRIBUTION,
  KEY_EVENTS_LOCATION_DISTRIBUTION,
  KEY_EVENTS_OS_DISTRIBUTION,
  KEY_EVENTS_REGISTRATION_BY_SLOT,
  KEY_EVENTS_SUMMARY_CONTENT_INFO,
  KEY_EVENTS_VIEW_BY_SEGMENT,
  KEY_EVENTS_ZONAL_DISTRIBUTION,
  KEY_GET_EVENTS_ALL_EVENTS_LIST,
  KEY_GET_EVENTS_ALL_PROJECT_EVENTS_LIST,
  KEY_GET_EVENTS_CONTENT_QUESTION,
  KEY_GET_EVENTS_ENGAGEMENT_REPORT,
  KEY_GET_EVENTS_LOCATION_REPORT,
  KEY_GET_EVENTS_MAJOR_CITIES,
  KEY_GET_EVENTS_PARTICIPANT_LIST,
  KEY_GET_EVENTS_SPECIALITY_BREAKUP,
} from "../../query-constants/workspace/events-constant";

const staleTime = 300000;
const cacheTime = 600000;

export const useGetSummaryContentInfo = (project_id, enabled) => {
  return useQuery(
    [KEY_EVENTS_SUMMARY_CONTENT_INFO, project_id],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_SUMMARY_CONTENT_INFO}`
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetContentViews = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_CONTENT_VIEWS, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_CONTENT_VIEWS}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetContentRegistration = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_CONTENT_REGISTRATION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_CONTENT_REGISTRATION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationDistribution = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_LOCATION_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_LOCATION_DISTRIBUTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetDeviceDistribution = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_DEVICE_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_DEVICE_DISTRIBUTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetZonalDistribution = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_ZONAL_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_ZONAL_DISTRIBUTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetOsDistribution = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_OS_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_OS_DISTRIBUTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetBrowserDistribution = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_BROWSER_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_BROWSER_DISTRIBUTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetRegistrationBySlot = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_REGISTRATION_BY_SLOT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_REGISTRATION_BY_SLOT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetDaywiseSlotDistribution = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_DAYWISE_SLOT_DISTRIBUTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_DAYWISE_SLOT_DISTRIBUTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetViewBySegment = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_EVENTS_VIEW_BY_SEGMENT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_VIEW_BY_SEGMENT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetParticipantList = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_PARTICIPANT_LIST, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_PARTICIPANT_LIST}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetMajorCities = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_MAJOR_CITIES, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_MAJOR_CITIES}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAllProjectEventsList = (project_id, payload, enabled) => {
  return useQuery(
    [
      KEY_GET_EVENTS_ALL_PROJECT_EVENTS_LIST,
      project_id,
      JSON.stringify(payload),
    ],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_ALL_PROJECT_EVENTS_LIST}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetLocationReport = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_LOCATION_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_LOCATION_REPORT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetEngagementReport = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_ENGAGEMENT_REPORT, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_ENGAGEMENT_REPORT}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetContentQuestion = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_CONTENT_QUESTION, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_CONTENT_QUESTION}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetSpecialityBreakup = (project_id, payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_SPECIALITY_BREAKUP, project_id, JSON.stringify(payload)],
    () =>
      axiosInstance.post(
        `project/${project_id}/${GET_EVENTS_SPECIALITY_BREAKUP}`,
        payload
      ),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};

export const useGetAllEventsList = (payload, enabled) => {
  return useQuery(
    [KEY_GET_EVENTS_ALL_EVENTS_LIST, JSON.stringify(payload)],
    () => axiosInstance.post(`project/${GET_EVENTS_ALL_EVENTS_LIST}`, payload),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,

      select: (res) => res?.data?.data ?? {},
    }
  );
};
