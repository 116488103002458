import axiosInstance from "../instances/axiosInstance";

const postService = async (endpoint, data) => {
  let postJson = {};
  // ensure data is an object and no empty values will be there
  if (typeof data === "object" && Object.keys(data).length > 0) {
    for (const key in data) {
      if (
        (typeof data[key] !== "object" && data[key]) ||
        (typeof data[key] === "object" && Object.keys(data[key]).length > 0)
      ) {
        postJson[key] = data[key];
      }
    }
  } else {
    postJson = "";
  }
  try {
    let response = await axiosInstance({
      method: "POST",
      url: endpoint,
      data,
    });
    if (response) {
      console.log("POST_SERVICE_RESPONSE", response);
      const responseData = response.data ?? [];

      let errorMessage = "";
      if (
        response?.data?.errors &&
        Object.keys(response?.data?.errors)?.length > 0
      ) {
        errorMessage = "";
        for (const eachErr of Object.values(response?.data?.errors)) {
          errorMessage += eachErr[0] + " ";
        }
      }
      // console.log("POST_SERVICE_ERROR_MESSAGE", errorMessage);

      switch (response.status) {
        case 200:
        case 201:
          return { data: responseData, isError: false, error: "" };
        case 203:
        case 204:
          return {
            data: responseData,
            isError: false,
            error: errorMessage || "",
          };
        default:
          return {
            data: null,
            isError: true,
            error: errorMessage || "Something went wrong. Please try again",
          };
      }
    }
  } catch (error) {
    console.log("POST_SERVICE_ERROR", error.response);
    if (!error.response)
      return {
        data: null,
        isError: true,
        error: "Something went wrong. Please try again",
      };
    let errorMessage = "Something went wrong. Please try again";
    if (
      error?.response?.data?.errors &&
      Object.keys(error?.response?.data?.errors)?.length > 0
    ) {
      errorMessage = "";
      //concatinate the Object.values(error?.response?.data?.errors)
      for (const eachErr of Object.values(error?.response?.data?.errors)) {
        errorMessage += eachErr[0] + " ";
      }
    }
    switch (error.response.status) {
      case 400:
      case 404:
      case 422:
      case 500:
      default:
        return {
          data: null,
          isError: true,
          error: errorMessage || "Something went wrong. Please try again",
        };
      case 401:
      case 403:
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";
        return {
          data: null,
          isError: true,
          error: "Please check your credentials and try again",
        };
    }
  }
};
export default postService;
