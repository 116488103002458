import React, { useRef, useState, useEffect, memo } from "react";
import { Card, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { findIcon } from "../../../dashboard/icons";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import postServiceSmsGenarator from "../../../../api/services/post-service-sms-generator";
import { getService } from "../../../../api/services/get-services";
import { SMS_TEMPLATE_CREATE_GET } from "../../../../api/endpoints/template-management-endpoints";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import toast from "react-hot-toast";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import mobile from "../../../../assets/images/mobile-bg.png";
import TemplateSuggestionCard from "./template-suggestion-card";
import Masonry from "react-masonry-css";
import uploadService from "../../../../api/services/upload-service";
import {
  UPDATE_ACTION_POST,
  UPLOAD_FILES_POST,
} from "../../../../api/endpoints/action-endpoints";
import app_config from "../../../../common/data/app_config";
import { STATUS_CONSTANTS } from "../constants/template-manager-constant";
import { getFormatedDate } from "../../../../common/utils/utils";
import postService from "../../../../api/services/post-service";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import PageNotFound from "../../../PageNotFound";
const NEW_TEMPLATE = "newTemplate";
const AUTO_GENERATE = "autoGenerate";
const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};
const SmsWpCreator = ({
  handleCloseClick,
  type = "sms",
  id,
  presetValues,
  onSubmitForm,
  presetContent,
  comments = [],
  actionId,
  isApprovalTask = false,
}) => {
  const templateContentRef = useRef(null);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [smsText, setSmsText] = useState("");
  const [templateTone, setTemplateTone] = useState("formal");
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
  const [generatedSmsList, setGeneratedSmsList] = useState([]);
  const [selectedSms, setSelectedSms] = useState("");
  const [TemplateVariableType, setTemplateVariableType] = useState([]);
  const [selectedVariableType, setSelectedVariableType] = useState("");
  const [loading, setLoading] = useState(false);
  const [templateCategory, setTemplateCategory] = useState([]);
  const [templateVariableList, setTemplateVariableList] = useState([]);
  const [openSmsSugession, setOpenSmsSugession] = useState(false);
  //formik values

  const [editType, setEditType] = useState(id ? NEW_TEMPLATE : AUTO_GENERATE);
  useEffect(() => {
    fetchSmsCreateData();
    if (presetValues) {
      setValues({
        ...values,
        ...presetValues,
      });
    }
    if (presetContent) {
      setSmsText(presetContent);
    }
  }, []);
  const initialValues = {
    name: "",
    description: "",
    template_name: "",
    template_category: "",
    template_type: "",
    // template_id: "",
    sender: "",
    vendor_id: "",
  };

  // in the validation schema if isApprovalTask is true then vendor_id is required
  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    template_name: Yup.string().required("Please Enter Template Name"),
    template_category: Yup.string().required("Please select category"),
    template_type: Yup.string().required("Please Select Template Type"),
    sender: Yup.string().required("Please Enter Sender"),
    vendor_id: Yup.string().required("Please Select Vendor"),
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (Object.keys(errors).length > 0) {
        hideBackdrop();
        return;
      }
      if (!isApprovalTask) {
        let response = await postService(UPDATE_ACTION_POST + actionId, {
          action_id: actionId,
          status: STATUS_CONSTANTS.COMPLETED,
          comment: "",
        });
        if (response) {
          onSubmitForm({
            ...values,
            template_content: smsText,
            template_tone: templateTone,
          });
        }
      } else {
        onSubmitForm({
          ...values,
          template_content: smsText,
          template_tone: templateTone,
        });
      }
    },
  });
  console.log("ERRORS", errors);
  console.log("values", values);
  const handleTemplateVariableClick = (e) => {
    const textarea = templateContentRef.current;
    if (textarea) {
      const textToAdd = e.target.outerText;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos);
      const newText = textBefore + textToAdd + textAfter;

      setSmsText(newText);
    }
  };
  const onContinueToEditClick = () => {
    setEditType(NEW_TEMPLATE);
    setSmsText(selectedSms);
  };
  const onCreateBtnClick = () => {
    setEditType(NEW_TEMPLATE);
  };
  const onTemplateSuggestionClick = (data) => {
    setSelectedSms(data);
    // setEditType("templateSuggestion");
  };
  const getSmsTemplateSuggestion = async () => {
    setSuggestionIsLoading(true);
    const templateSuggestionPayload = {
      action: values.name + " " + values.description,
      tone: templateTone,
      type,
    };
    try {
      const response = await postServiceSmsGenarator(templateSuggestionPayload);
      console.log("suggestion list ", response.data);
      setSuggestionIsLoading(false);
      parseGeneratedSmsList(response.data.output);
    } catch (error) {
      setSuggestionIsLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };
  const parseGeneratedSmsList = (data) => {
    console.log("parseGeneratedSmsList", data);
    if (data) {
      const smsList = JSON.parse(data);
      let generatedList = smsList.map((item) => {
        return Object.values(item)[0];
      });
      setGeneratedSmsList(generatedList);
    }
  };
  const fetchSmsCreateData = async () => {
    try {
      setLoading(true);
      const response = await getService(SMS_TEMPLATE_CREATE_GET);
      if (response.data.data.variable) {
        let data = response.data.data.variable;
        let allVariableType = data.map((item) => item.variable_type);
        const uniqueSet = new Set(allVariableType.map((item) => item));
        const uniqueVariableType = [...uniqueSet];
        setTemplateVariableType(uniqueVariableType);
        setSelectedVariableType(uniqueVariableType[0]);
        let variableTypeList = [];
        uniqueVariableType.forEach((item) => {
          let temp = data.filter((item1) => item1.variable_type == item);
          variableTypeList.push(temp);
        });
        setTemplateVariableList(variableTypeList);

        setLoading(false);
      }
      if (response.data.data.category) {
        setTemplateCategory(response.data.data.category);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleSmsText = (e) => {
    setSmsText(e.target.value);
  };
  const handleEmojiClick = (data) => {
    // alert("handleEmojiClick" + data);
    setTemplateTone(data);
  };

  const checkValidation = () => {
    if (values.name == "") {
      setErrors({ ...errors, name: "Please enter template name" });
      return;
    }
    if (values.description == "") {
      setErrors({ ...errors, description: "Please enter description" });
      return;
    }
    if (!errors.name && !errors.description) {
      getSmsTemplateSuggestion();
    }
  };
  return (
    <Card>
      <Card.Header className="py-3">
        <Row className="align-items-center">
          <div className="d-flex justify-content-between">
            <h3 className="m-0">
              Review {type == "sms" ? "SMS" : "WhatsApp"} Template
            </h3>
            {/* <div className="d-flex justify-content-start gap-4">
              <a
                href="javascript:void(0)"
                eventKey="link-1"
                className="d-inline-flex align-items-center gap-1"
              >
                {findIcon("Setting", "dual-tone", 22)}
                Save Draft
              </a>
              <a
                href="javascript:void(0)"
                eventKey="link-1"
                className="d-inline-flex align-items-center gap-1 link-danger"
                onClick={handleCloseClick}
              >
                {findIcon("Square X", "dual-tone", 22)}
                Close
              </a>
            </div> */}
          </div>
        </Row>
      </Card.Header>

      {editType == NEW_TEMPLATE && (
        <Card.Body className="pt-2">
          <div className="p-4 bg-soft-secondary rounded-3">
            <Row className="justify-content-center align-items-start">
              <Col lg={9} className="position-relative mt-3">
                <div className="p-4 bg-white rounded-3 d-flex flex-column">
                  <Row>
                    {/* name  */}
                    <Col lg={4} md={6} className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="text"
                          className=""
                          id="template_name"
                          autoComplete="Name"
                          placeholder="Name"
                          name="template_name"
                          onChange={handleChange}
                          value={values.template_name}
                          isInvalid={
                            touched.template_name && !!errors.template_name
                          }
                        />
                        <Form.Label htmlFor="template_name">Name</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.template_name}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    {/* sender  */}
                    <Col lg={4} md={6} className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="text"
                          className=""
                          id="sender"
                          autoComplete="Name"
                          placeholder="Sender"
                          name="sender"
                          onChange={handleChange}
                          value={values.sender}
                          isInvalid={touched.sender && !!errors.sender}
                        />
                        <Form.Label htmlFor="sender">Sender</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.sender}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    {/* category  */}
                    <Col lg={4} md={6} className="mb-4">
                      <Form.Floating className="custom-form-floating form-floating">
                        <Form.Select
                          // id="template_category"
                          name="template_category"
                          value={values.template_category}
                          isInvalid={
                            touched.template_category &&
                            !!errors.template_category
                          }
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {templateCategory?.map((item, index) => (
                            <option key={index} value={item.category_id}>
                              {item.category_name}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Label htmlFor="template_category">
                          Category
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.template_category}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    {/* template type  */}
                    <Col lg={4} md={6} className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Select
                          id="template_type"
                          name="template_type"
                          value={values.template_type}
                          isInvalid={
                            touched.template_type && !!errors.template_type
                          }
                          onChange={handleChange}
                        >
                          <option value="">Select Template Type</option>
                          <option value="0">Promotional</option>
                          <option value="1">Transactional</option>
                        </Form.Select>
                        <Form.Label htmlFor="template_type">
                          Template Type
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.template_type}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    {/* template id  */}

                    {/* Vendor ID  */}
                    <Col lg={4} md={6} className="mb-4">
                      <Form.Floating className="custom-form-floating">
                        <Form.Control
                          type="text"
                          className=""
                          id="vendor_id"
                          autoComplete="Name"
                          placeholder="Vendor ID"
                          name="vendor_id"
                          onChange={handleChange}
                          value={values.vendor_id}
                          isInvalid={touched.vendor_id && !!errors.vendor_id}
                        />
                        <Form.Label htmlFor="template_namename">
                          Vendor ID
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.vendor_id}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </Col>

                    {/* content  */}
                    <Col lg={12}>
                      <Form.Floating className="custom-form-floating position-relative d-flex flex-column">
                        <textarea
                          ref={templateContentRef}
                          className={
                            // smsText?.length <= 150
                            //   ? "form-control border border-1 border-danger"
                            //   :
                            "form-control"
                          }
                          aria-label="With textarea"
                          value={smsText}
                          onChange={handleSmsText}
                        ></textarea>
                        <Form.Label htmlFor="floatingInput">Message</Form.Label>
                        <Button
                          title="Select Variable"
                          onClick={() => setOpenSmsSugession(!openSmsSugession)}
                          variant="warning"
                          className="btn-icon w-auto px-2 position-absolute sms-suggestion__button"
                        >
                          {findIcon("Suggestion", "dual-tone", "24")}
                        </Button>
                      </Form.Floating>
                      <div className="d-flex justify-content-between align-items-center mt-1">
                        <div className="ms-auto">{smsText?.length} sms</div>
                      </div>
                    </Col>
                  </Row>
                </div>

                {openSmsSugession == true ? (
                  <Col lg={12} className="mt-4">
                    <div className="w-100 rounded-3 sms-suggestion mt-3 p-3 h-auto bg-white z-index-2">
                      <div className="justify-content-between align-items-center mb-3 d-flex">
                        <h5 className="mb-0">Varibale Suggestion</h5>
                        <a
                          href="javascript:void(0)"
                          onClick={() => setOpenSmsSugession(false)}
                          className="d-inline-flex align-items-center gap-1 link-danger"
                        >
                          {findIcon("Square X", "dual-tone", 22)}
                          Close
                        </a>
                      </div>
                      <div className="d-flex gap-3 p-2 border border-1 rounded-3">
                        {TemplateVariableType?.length > 0 ? (
                          <div
                            className="nav sms-suggestion__tabNav gap-2 overflow-auto flex-shrink-0 flex-column"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {TemplateVariableType.map((item) => (
                              <a
                                href="javascript:void(0)"
                                className={`position-relative nav-link flex-grow-1 rounded-3  ${selectedVariableType == item
                                  ? "bg-primary text-white active"
                                  : "bg-soft-primary text-dark"
                                  }`}
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                                onClick={(e) => setSelectedVariableType(item)}
                              >
                                {item}
                                <div className="sms-suggestion__tabNav__icon position-absolute transition">
                                  {findIcon("Arrow Right", "solid", "16")}
                                </div>
                              </a>
                            ))}
                          </div>
                        ) : (
                          <PageNotFound
                            title="No Variable Found "
                            message=""
                            buttonText=""
                          />
                        )}

                        <div
                          className="tab-content flex-grow-1 p-2"
                          id="v-pills-tabContent"
                        >
                          <div
                            className="tab-pane d-flex gap-2 fade show active flex-wrap "
                            role="tabpanel"
                            aria-labelledby="v-pills-home-tab"
                          >
                            {templateVariableList.map((item) =>
                              item.map((item1) =>
                                item1.variable_type == selectedVariableType ? (
                                  <Badge
                                    pill
                                    bg="soft-info"
                                    className="fw-normal px-3 border border-1 border-info cursor-pointer"
                                    onClick={(e) => {
                                      handleTemplateVariableClick(e);
                                      setOpenSmsSugession(false);
                                    }}
                                  >
                                    {item1.variable_value}
                                  </Badge>
                                ) : null
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : null}

                <Col lg={12} className="mt-5 d-flex justify-content-end">
                  {!isApprovalTask && (
                    <SubmitButton
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={smsText.length == 0}
                    >
                      {id ? (
                        loading ? (
                          <PaginationLoader />
                        ) : (
                          "Update"
                        )
                      ) : loading ? (
                        <PaginationLoader />
                      ) : (
                        "Send for Approval"
                      )}
                    </SubmitButton>
                  )}
                </Col>
              </Col>
              <Col lg={3} xs={12} className="position-sticky top-0">
                <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                  <div className="d-flex position-relative mobile-preview">
                    {/* <p>Preview is not available for SMS channel</p> */}
                    <img
                      src={mobile}
                      alt="mobile"
                      className="mobile h-auto w-100 position-relative"
                    />
                    <div className="p-2 h-100 w-100 position-absolute d-flex pt-5 pb-4">
                      <div className="px-5 mx-100 overflow-auto w-100 flex-grow-1 mt-3">
                        <span className="d-block position-relative px-3 py-2 rounded-3 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                          {smsText}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {isApprovalTask && (
            <div className="p-4 bg-soft-secondary rounded-3 mt-4">
              <Row>
                <Col lg={6}>
                  <h5 className="mb-3">Reviews</h5>
                  <Reviews comments={comments} />
                </Col>
                <Col lg={6}>
                  <AddApprovalForm
                    actionId={actionId}
                    onAddApproval={handleSubmit}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Card.Body>
      )}
      {editType == AUTO_GENERATE && (
        <Card.Body>
          <div className="p-4 bg-soft-secondary rounded-3">
            <Row className="justify-content-center align-items-start">
              <Col lg={9} xs={12} className="position-relative">
                <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                  <div>
                    <Row>
                      <Col lg={9} className="mb-3">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="floatingInput1"
                            autoComplete="Name"
                            placeholder="Name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            isInvalid={errors.name}
                            disabled={suggestionIsLoading}
                          />
                          <Form.Label htmlFor="floatingInput1">Name</Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg={9} className="mb-3">
                        <Form.Floating className="h-100 custom-form-floating">
                          <Form.Control
                            as="textarea"
                            type="text"
                            className="h-100"
                            id="floatingInput2"
                            autoComplete="Name"
                            placeholder="Name"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            isInvalid={errors.description}
                            disabled={suggestionIsLoading}
                          />
                          <Form.Label htmlFor="floatingInput2">
                            Prompt for Template Creation
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>
                      <Col lg={9} className="mb-4 mt-auto ">
                        <p>Tone Selector:</p>
                        <div className="col-12 align-item-between d-flex gap-3">
                          <span
                            aria-label="Professional"
                            onClick={() => handleEmojiClick("Professional")}
                            className={`${templateTone == "Professional"
                              ? "border-warning fw-semibold"
                              : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning cursor-pointer`}
                          >
                            Professional
                          </span>
                          <span
                            aria-label="Casual"
                            onClick={() => handleEmojiClick("Casual")}
                            className={`${templateTone == "Casual"
                              ? "border-warning fw-semibold"
                              : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Casual
                          </span>
                          <span
                            aria-label="Serious"
                            onClick={() => handleEmojiClick("Serious")}
                            className={`${templateTone == "Serious"
                              ? "border-warning fw-semibold"
                              : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Serious
                          </span>
                          <span
                            aria-label="Humorous"
                            onClick={() => handleEmojiClick("Humorous")}
                            className={`${templateTone == "Humorous"
                              ? "border-warning fw-semibold"
                              : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                          >
                            Humorous
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Col lg={12}>
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                    >
                      {generatedSmsList?.length > 0 &&
                        generatedSmsList.map((item, index) => (
                          <>
                            <TemplateSuggestionCard
                              data={item}
                              click={onTemplateSuggestionClick}
                              selectedSms={selectedSms}
                              type=""
                            />
                          </>
                        ))}
                    </Masonry>
                  </Col>
                  <Col className="flex-grow-0">
                    <div className="d-flex gap-3 justify-content-end">
                      <Button
                        variant="info"
                        onClick={onCreateBtnClick}
                        disabled={suggestionIsLoading}
                      >
                        Skip
                      </Button>
                      <Button
                        variant="primary"
                        onClick={checkValidation}
                        disabled={suggestionIsLoading}
                      >
                        {!suggestionIsLoading
                          ? "Auto Generate"
                          : "Generating Suggestion..."}
                      </Button>
                      {selectedSms && (
                        <Button
                          variant="primary"
                          onClick={onContinueToEditClick}
                        >
                          Continue To Edit
                        </Button>
                      )}
                    </div>
                  </Col>
                </div>
              </Col>
              <Col lg={3} xs={12} className="position-sticky top-0">
                <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                  {/* <h5>Preview</h5> */}
                  <div className="position-relative flex-fill">
                    <img
                      src={mobile}
                      alt="mobile"
                      className="h-auto w-100 d-block position-relative z-0"
                    />
                    <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                      {selectedSms && (
                        <div className="h-100 rounded-5 p-4 mobile-preview position-relative ">
                          <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                            {selectedSms}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default memo(SmsWpCreator);
function Reviews({ comments }) {
  //console.log("comments", comments);
  return (
    <div className="reviews">
      {comments?.length > 0 &&
        comments?.map((_comment, _idx) => {
          let { created_at, comment, user_id } = _comment;
          return (
            <div
              className="d-flex flex-column justify-content-between shadow-sm p-3 mb-3 rounded-3"
              key={_idx + 1}
            >
              <div className="d-flex flex-row justify-content-between mb-2">
                <div className="d-flex flex-column">
                  <span>{user_id?.name ?? ""}</span>
                  <span className="fs-7 text-black">
                    {getFormatedDate(created_at)}
                  </span>
                </div>
              </div>
              <div>{comment || ""}</div>
            </div>
          );
        })}
    </div>
  );
}
function AddApprovalForm({ actionId, onAddApproval }) {
  const [values, setValues] = useState({ reference_files: [], input: "" });
  const [loading, setLoading] = useState(false);
  const { showBackdrop } = useBackdrop();
  const uploadFiles = (files) => {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      let formdata = new FormData();
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(UPLOAD_FILES_POST, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  };
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    const urls = await uploadFiles([...files]);
    if (urls) {
      setValues({
        ...values,
        reference_files: [...values.reference_files, ...urls],
      });
    }
  };

  const submitReview = async (status) => {
    if (status == STATUS_CONSTANTS.REJECTED && !values.input) {
      toast.error("Please enter comment");
      return;
    }
    showBackdrop();
    setLoading(true);
    let response = await postService(UPDATE_ACTION_POST + actionId, {
      action_id: actionId,
      status,
      comment: values.input,
      // reference_files: values.reference_files,
    });
    if (response.isError) {
      setLoading(false);
      toast.error(response.error);
      return;
    }

    if (response.data.success) {
      toast.success("Your action has been submitted successfully");

      onAddApproval();

      setLoading(false);
    }
  };
  const onCrossImageClick = (e) => {
    let tempRequisitionFiles = values.reference_files
      ? [...values.reference_files]
      : [];
    tempRequisitionFiles = tempRequisitionFiles.filter(
      (file, idx) => idx !== e
    );
    setValues({
      ...values,
      reference_files: tempRequisitionFiles,
    });
  };
  return (
    <Form>
      <Col lg="12" className="mb-2 mt-0">
        <Form.Floating className="custom-form-floating">
          <textarea
            className="form-control"
            placeholder="Leave a comment here"
            id="floatingTextarea2"
            name="input"
            onChange={(e) => {
              setValues({ ...values, input: e.target.value });
            }}
          ></textarea>
          <Form.Label htmlFor="fname">Write your comments</Form.Label>
        </Form.Floating>
      </Col>
      <Col lg="12" className="mb-2">
        <h6 className="mb-2 mt-3 fw-normal">Add Reference Files</h6>
        <Form.Control
          type="file"
          id="customFile"
          multiple
          name="reference_files"
          onChange={handleChangeReferenceFiles}
        />
        <div className="d-flex flex-wrap flex-row gap-3 mt-3">
          {values.reference_files?.map((file, _idx) => (
            <div className="position-relative" key={_idx + 1}>
              <img
                className="rounded img-fluid avatar-120"
                src={app_config.fileStoragePath + file}
                alt="profile"
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  //   e.target.src = article_1;
                }}
              />
              <span
                className="position-absolute top-0 end-0"
                onClick={() => onCrossImageClick(_idx)}
              >
                {findIcon("Square X", "solid", 20)}
              </span>
            </div>
          ))}
        </div>
      </Col>
      <Col lg="12" className="d-flex gap-3">
        <SubmitButton
          type="button"
          variant="warning"
          disabled={loading}
          onClick={() => submitReview(STATUS_CONSTANTS.REJECTED)}
          className="w-100 py-3 px-1 mt-2 fs-6 rounded-3"
        >
          Reject
        </SubmitButton>
        <SubmitButton
          type="button"
          variant="primary"
          disabled={loading}
          onClick={() => submitReview(STATUS_CONSTANTS.COMPLETED)}
          className="w-100 py-3 px-1 mt-2 fs-6 fw-semibold rounded-3"
        >
          Approve
        </SubmitButton>
      </Col>
    </Form>
  );
}
