export const LOGIN_POST = "/login";
export const USER_PROFILE = "/profile";
export const REGISTRATION_POST = "/register ";
export const RESET_PASSWORD_POST = "/forgot_password";
export const CHANGE_PASSWORD_POST = "/password_reset";
export const CREATE_PASSWORD_POST = "/create_password";
export const LOGOUT_POST = "/logout";

export const REFRESH_TOKEN_POST = "/forgot_password/verify";
export const CREATE_PASSWORD_REFRESH_TOKEN_POS = "/create_password/verify";
export const GET_GOOGLE_LOGIN_LINK = "/login/google";
export const GET_FACEBOOK_LOGIN_LINK = "/login/facebook";
export const VERIFY_GOOGLE_LOGIN = "/login/callback/google";
export const VERIFY_FACEBOOK_LOGIN = "/login/callback/facebook";
export const GET_TWO_FACTOR_QR_CODE = "/two-factor-qr-code";
export const POST_VERIFY_TWO_FACTOR = "/two-factor-confirm";
