import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";

import { SEGMENT_LIST_POST } from "../../../../api/endpoints/campaign-segment-management-endpoints";
import postService from "../../../../api/services/post-service";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import CreateSegmentModal from "../components/create-segment-modal";

import FilterBar from "../../../../components/filter-bar";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";
import withAuthorization from "../../../../components/hoc/with-authorization";
const Segmentlisting = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const modalClose = () => setCreateModalShow(false);
  const post_json = useRef({
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      segment_id: "desc",
    },
  });
  const { redirectTo } = useRedirect();

  // table data with createdOn as date in UTC
  const onSegementDeleteModal = () => {
    setDeleteModalShow(true);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "segment_id",
    },
    { Header: "Segment name", accessor: "segment_name", isVisible: true },
    // {
    //   Header: "Segment Description",
    //   accessor: "segment_description",
    // },
    {
      Header: "Total Reach",
      accessor: "total_reach",
    },
    {
      Header: "Created by",
      accessor: "created_by",
      Cell: ({ value }) => {
        return value.name;
      },
    },
    // {
    //   Header: "Created At",
    //   accessor: "created_at",
    //   Cell: ({ value }) =>
    //     value ? <p className="m-0">{value.split(" ")[0]}</p> : null,
    // },
    {
      Header: "Modified by",
      accessor: "modified_by",
      // Cell: ({ value }) => value.name,
      Cell: ({ value }) => (value ? <p className="m-0">{value.name}</p> : null),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view", "edit"]}
          row={row}
          onView={(data) => {
            console.log("row", row);
            redirectTo("segment/details/" + row.original.segment_id);
          }}
          onEdit={() => {
            redirectTo("segment/edit/" + row.original.segment_id);
          }}
          onDelete={() => {
            setSelectedSegment(row.original.segment_id);
            onSegementDeleteModal();
          }}
        />
      ),
    },
  ];

  const fetchSegmentList = async () => {
    try {
      // setLoading(true);
      const response = await postService(SEGMENT_LIST_POST, post_json.current);

      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setSegmentList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("fetch segment error", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchSegmentList();
  }, []);

  const fetchAllSegmentList = useCallback(
    async (state) => {
      post_json.current = changeTableStateToPayload(state);
      fetchSegmentList();
    },
    [segmentList, loading, post_json.current]
  );

  const onDeleteModalClose = useCallback(() => {
    setSelectedSegment(null);
    setDeleteModalShow(false);
  }, [deleteModalShow]);

  // const deleteSegment = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await getService(
  //       `${EMAIL_TEMPLATE_DELETE}/${selectedSegment}`,
  //       {}
  //     );
  //     console.log(response);
  //     if (response) {
  //       setLoading(false);
  //       setCreateModalShow(false);
  //       if (response.data.success) {
  //         toast.success(response.data.message);
  //       }
  //       setTimeout(() => {
  //         fetchTemplate();
  //       }, 500);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     // console.log("USER_SERVICE_ERROR", error);
  //   }
  // };

  const advanceData = [
    { value: "Segment Name", label: "Segment Name", type: "string" },
    {
      value: "Created by",
      label: "Created by",
      elements: [
        { value: "Creator 1", label: "Creator 1" },
        { value: "Creator 2", label: "Creator 2" },
        { value: "Creator 3", label: "Creator 3" },
      ],
      type: "string",
    },
    {
      value: "Modified by",
      label: "Modified by",
      elements: [
        { value: "Modifier 1", label: "Modifier 1" },
        { value: "Modifier 2", label: "Modifier 2" },
        { value: "Modifier 3", label: "Modifier 3" },
      ],
      type: "string",
    },
  ];

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => setCreateModalShow(true)}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">All Segments</h3>
        </div>
        {/* <Filter type="Segment" /> */}
        <FilterBar pageType="Segment" advanceData={advanceData} />
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          {/* <Button
            className="btn btn-primary flex-grow-1 flex-sm-grow-0"
            type="submit"
            onClick={() => redirectTo("segment/create")}
          >
            Create Segment
          </Button> */}
          {/* <div className="form-group mb-0 ">
            <select className="form-select">
              <option defaultValue>Sort By</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          {/* <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />
          </div> */}
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              {/* <div className="fancy-table table-responsive rounded"> */}
              {!loading ? (
                segmentList?.length > 0 ? (
                  <ReactTable
                    data={segmentList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={fetchAllSegmentList}
                    initialState={post_json.current}
                    recordsPerPage={post_json.current.pagination.limit}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
              {/* </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <DeleteModal
        show={deleteModalShow}
        modalClose={onDeleteModalClose}
        onSubmit={deleteSegment}
      /> */}
      <CreateSegmentModal show={createModalShow} modalClose={modalClose} />
    </Fragment>
  );
};

export default memo(Segmentlisting);
