import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
} from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import {
  ADD_FILE_TYPE,
  ALL_FILE_TYPES,
  DELETE_FILE_TYPE,
  UPDATE_FILE_TYPE,
  FILE_TYPE_BY_ID,
} from "../../../../../api/endpoints/file-type-endpoints";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ActionButtonBar from "../../../../../components/partials/common/action-button-bar";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../components/form-components/floating-button";
import withAuthorization from "../../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../../skeleton/react-table-loader/react-table-loader";

const AllFileTypes = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [fileTypeList, setFileTypeList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewFileType, setViewFileType] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const post_json = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      file_type_id: "desc",
    },
  });
  //yup and formik for validation of form fields of file_type_name

  const validationSchema = yup.object().shape({
    file_type_name: yup.string().trim().required("File Type Name is required"),
    file_content_type: yup
      .string()
      .trim()
      .required("File Content Type is required"),
    file_type_code: yup.string().trim().required("File Type Code is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      file_type_name: "",
      file_content_type: "",
      file_type_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const postValues = {
        file_type_name: values.file_type_name.trim(),
        file_content_type: values.file_content_type.trim(),
        file_type_code: values.file_type_code.trim(),
      };
      try {
        setLoading(true);
        const response = await postService(
          isEdit ? UPDATE_FILE_TYPE + "/" + selectedFileType : ADD_FILE_TYPE,
          {
            ...postValues,
            status: 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchFileTypes(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("FILE_TYPE_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  //fetch file_types

  const fetchFileTypes = async (json) => {
    try {
      // setLoading(true);
      const response = await postService(ALL_FILE_TYPES, json);
      console.log("FILE_TYPE_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setFileTypeList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("FILE_TYPE_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (file_type) => {
    const { file_type_id, file_type_name, file_content_type } = file_type;
    let dataJson = {
      "File Type ID": file_type_id,
      "File Type Name": file_type_name,
      "File Content Type": file_content_type,
    };
    setViewFileType(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteFileType = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${DELETE_FILE_TYPE}/${selectedFileType}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchFileTypes(post_json.current);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("FILE_TYPE_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchFileTypes(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchFileTypes(payload);
    },
    [fileTypeList, loading, post_json.current]
  );
  const onDeleteFileType = (file_type) => {
    // alert("deleting" + file_type.file_type_id);
    setSelectedFileType(file_type.file_type_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "File Type ID",
      accessor: "file_type_id",
    },
    {
      Header: "File Type Name",
      accessor: "file_type_name",
    },
    {
      Header: "File Content Type",
      accessor: "file_content_type",
    },
    {
      Header: "File Type Code",
      accessor: "file_type_code",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onView={(data) => viewModal(data)}
          onEdit={(data) => onEditFileTypeClick(data)}
          onDelete={(data) => onDeleteFileType(data)}
        />
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditFileTypeClick = (file_type) => {
    console.log("file_type===>", file_type);
    setIsEdit(true);
    setSelectedFileType(file_type.file_type_id);
    //set formik values
    setValues({
      file_type_name: file_type.file_type_name,
      file_content_type: file_type.file_content_type,
      file_type_code: file_type.file_type_code,
    });
    setCreateModalShow(true);
  };

  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All File Types</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add File Type
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                fileTypeList.length > 0 && (
                  <ReactTable
                    data={fileTypeList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteFileType}
      />
      <ViewModal
        title={"View File Type Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewFileType}
      />
      {/* create a add FileType modal with FileType name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} File Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                File Type Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter File Type Name"
                name="file_type_name"
                onChange={handleChange}
                value={values.file_type_name}
                onBlur={handleBlur}
                isInvalid={touched.file_type_name && errors.file_type_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_type_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                File Content Type <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter File Content Type"
                name="file_content_type"
                onChange={handleChange}
                value={values.file_content_type}
                onBlur={handleBlur}
                isInvalid={
                  touched.file_content_type && errors.file_content_type
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_content_type}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>File Type Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter File Type Code"
                name="file_type_code"
                onChange={handleChange}
                value={values.file_type_code}
                onBlur={handleBlur}
                isInvalid={touched.file_type_code && errors.file_type_code}
              />
              <Form.Control.Feedback type="invalid">
                {errors.file_type_code}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default withAuthorization(memo(AllFileTypes));
