import React, { useEffect, useRef, useState } from "react";
import { useGetAllEngagementData } from "../../../../../queries/query-hooks/hcp-profile-hook";
import { default_post_json_table } from "../../constant/hcp-payload";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import ReactTable from "../../../../../lib/table/react-table";
import Skeleton from "../../../../../components/skeleton";
import PageNotFound from "../../../../PageNotFound";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
const EngagementSummary = ({ hcpId }) => {
  const [engagementViewCount, setEngagementTotalViewCount] = useState(0);

  // const { id: hcpId } = useParams();

  const onEngagaeSuccess = (res) => {
    console.log("onEngagaeSuccess", res);
    if (res.success) {
      setEngagementTotalViewCount(res.totalCount);
    }
  };

  const engagementColumn = [
    // { Header: "Sl No", accessor: "sl_no" },
    { Header: "Content Id", accessor: "content_id" },
    { Header: "Content Category", accessor: "content_category" },
    { Header: "UTM Source", accessor: "utm_source" },
    // { Header: "Template", accessor: "template" },
    { Header: "NTF Sent", accessor: "notification_sent", disableFilters: true },
    {
      Header: "NTF Delvrd",
      accessor: "notification_delivered",
      disableFilters: true,
    },
    {
      Header: "Shortlink Click",
      accessor: "shortlink_click",
      disableFilters: true,
    },
    {
      Header: "Viewed ",
      accessor: "notification_viewed",
      disableFilters: true,
    },
    {
      Header: "Read ",
      accessor: "read",
      disableFilters: true,
    },

    { Header: "Commented ", accessor: "comment", disableFilters: true },

    // { Header: "Status", accessor: "status" },
  ];

  const [payload, setPayload] = useState({
    hcp_id: hcpId,
    ...default_post_json_table,
  });

  const {
    data: allEngagement,
    isLoading: isAllEngagementLoading,
    refetch: fetchEngagement,
  } = useGetAllEngagementData(payload, !!hcpId, onEngagaeSuccess);

  const onTableStateChange = (state) => {
    let payload = changeTableStateToPayload(state);

    setPayload((prev) => ({
      ...prev,
      ...payload,
    }));
  };

  useEffect(() => {
    fetchEngagement();
  }, [payload]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">
            {isAllEngagementLoading ? (
              <Skeleton width={100} height={40} />
            ) : (
              <>Engagement Summary</>
            )}
          </h4>
        </div>
      </Card.Header>
      <Card.Body>
        {!isAllEngagementLoading && allEngagement?.rows?.length > 0 ? (
          <ReactTable
            data={allEngagement?.rows}
            columns={engagementColumn}
            onTableStateChange={onTableStateChange}
            recordsTotal={allEngagement?.totalCount}
            initialState={payload}
            // isRowSelectionAvailable={true}
            // onRowSelectionChange={(selectedList) =>
            //   onRowSelectionChange(selectedList)
            // }
            recordsPerPage={payload.pagination.limit}
          />
        ) : isAllEngagementLoading ? (
          <CommonTableLoader />
        ) : (
          <PageNotFound title="No engagement data found" isFullHeight={false} />
        )}
      </Card.Body>
    </Card>
  );
};

export default EngagementSummary;
