export const default_post_json_table = {
  default: "all",
  pagination: {
    limit: 10,
    offset: 0,
  },
  sort: {
    id: "desc",
  },
  filter: {
    status: {
      type: "eq",
      value: 1,
    },
  },
};
