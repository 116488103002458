import React, { Fragment , memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function VideoAnalyticsOverviewSkeleton() {
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          {/* graph  */}
          {<GraphSkeleton />}

          {/*  */}
          <div className="p-4 bg-white my-3 rounded-3">
            <div className="bg-soft-light">
              <Skeleton
                variant="square"
                width="100%"
                height={100}
                className="rounded-3"
              />
            </div>
          </div>

          {/*  graph */}
          {<GraphSkeleton />}
        </Col>

        <Col md={6}>
          <div className="bg-white rounded-3 pt-4">
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
              <Skeleton variant="text" width="50%" />
              <Skeleton variant="text" width="50%" />
            </div>

            <div className="pt-4">
              <Row>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div className="d-flex flex-column gap-2">
                    {[1, 2].map((_, index) => {
                      return (
                        <div key={index} >
                          <Skeleton variant="text" width={180} />
                          <Skeleton
                            variant="square"
                            className="rounded-3"
                            width={60}
                            height={25}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <Skeleton
                    variant="rect"
                    className="rounded-3"
                    width="40%"
                    height={100}
                  />
                </div>
              </Row>
            </div>

            {/* graph  */}
            <div className="pt-4">{<GraphSkeleton />}</div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}

export default memo(VideoAnalyticsOverviewSkeleton);

function GraphSkeleton() {
  return (
    <div className="bg-white p-4 rounded-3">
      <div className="mb-3">
        <Skeleton variant="text" width="30%" />
      </div>
      <Skeleton
        variant="rect"
        className="rounded-3"
        width="100%"
        height={250}
      />
    </div>
  );
}
