import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

function HeatMapsChart({ title, height = 300, series }) {
  const chartOptions = {
    series: series || [],
    options: {
      chart: {
        height: 350,
        type: "heatmap",
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#0f2d70"],
      yaxis: {
        labels: {
          formatter: function (value) {
            return value ? value + " ( % ) " : "";
          },
        },
      },
      // tooltip: {
      //   y: {
      //     title: {
      //         formatter: (value) => {
      //           return value + " ( % ) "
      //         },
      //     },
      // }
      // }
    },
  };

  return (
    <>
      <Card className="h-100 m-0">
        <Card.Header className=" align-items-center justify-content-between">
          <h5 className="text-center subHeading position-relative">{title}</h5>
        </Card.Header>
        <Card.Body className="p-2">
          <div id="chart">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="heatmap"
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default HeatMapsChart;

// max 13388c min e7ebf4
