import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
} from "react";

// React-bootstrap
import { Row, Nav, Tab, Dropdown, Button, Card, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
//Img
import img1 from "../../../../assets/images/avatars/avtar_6.png";
import toast from "react-hot-toast";
import postService from "../../../../api/services/post-service";
import {
  SEGMENT_ADD_POST,
  SEGMENT_ANALYTICS_GET,
  SEGMENT_EDIT_GET,
  SEGMENT_SEGMENT_POST,
  SEGMENT_UPDATE_POST,
  SEGMENT_HCP_LIST_GET,
  SEGMENT_REFRESH_ANALYTICS_GET,
} from "../../../../api/endpoints/campaign-segment-management-endpoints";
import {
  changeTableStateToPayload,
  getValuesByKey,
} from "../../../../common/utils/utils";
import { useParams, useLocation } from "react-router-dom";
import { segmentAddData } from "../../../../store/segment/segment.selector";
import { addSegment } from "../../../../store/segment/segment.action";
import UserWhoActiveForm from "../components/create-from-component/user-who-active-form";
import UserPersonaCard from "../components/create-from-component/user-persona-card";
import UserWhoDidForm from "../components/create-from-component/user-who-did-form";
import UserDemograpicForm from "../components/create-from-component/user-demographic-form";
import SegmentAnalyticsSection from "../components/create-from-component/segment-analytics-section";
import { getService } from "../../../../api/services/get-services";
import UserInterestedIn from "../components/create-from-component/user-interested-in-form";
import { findIcon } from "../../../dashboard/icons";
import ReactTable from "../../../../lib/table/react-table";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { useGetSegmentCreateData } from "../../../../queries/query-hooks/segment-hook";
import UserPersonaSkeleton from "../../../../skeleton/segment/component/UserPersonaSkeleton";

import PageNotFound from "../../../PageNotFound";
import SegmentAnalyticsSkeleton from "../../../../skeleton/segment/component/segment-analytics-skeleton";
import CommonTableLoader from "../../../../skeleton/common-table-loader";

const innitialPayload = {
  segment_name: "",
  segment_description: "",
  options: {
    persona: [],
    who_active: [],
    engaged_with: [],
    interesed_in: [],
    demographic: [],
    other: [],
  },
};

const payloadKeyMap = {
  speciality: "primary_speciality_id",
  state: "state_id",
  city: "city_id",
  degree: "medical_degree_id",
  channel: "channel_id",
  consented: "consent",
};

const payloadToDataKeyMap = {
  primary_speciality_id: "speciality",
  state_id: "state",
  city_id: "city",
  medical_degree_id: "degree",
  channel_id: "channel",
};

const DEFAULT_SEGMENT_TYPE = "dynamic";
const LIST_SEGMENT_TYPE = "list";
const CreateSegment = () => {
  const location = useLocation();
  const { redirectTo } = useRedirect();
  const { id: segment_id } = useParams();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [userPersonaList, setUserPersonaList] = useState([]);
  const [contentCategoryList, setContentCategoryList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [segementAddPostLoader, setSegementAddPostLoader] = useState(false);
  const [analyticsData, setAnalyticsData] = useState("");
  const [showTable, setShowTable] = useState(false);

  const [segmentType, setSegmentType] = useState(DEFAULT_SEGMENT_TYPE);
  const [getAnanlyticsDataLoader, setGetAnanlyticsDataLoader] = useState(false);
  const [getHcpListLoader, setGetHcpListLoader] = useState(false);
  const [hcpListData, setHcpListData] = useState([]);
  const [totalHcpCount, setTotalHcpCount] = useState(0);

  const [segmentPayloadData, setSegmentPayloadData] = useState(innitialPayload);
  const segment_add_payload = useRef(innitialPayload);

  const [segmentDetails, setSegmentDetails] = useState({
    isNameEdit: false,
    isDescriptionEdit: false,
    name: "",
    // name: segmentPayload?.segment_name,
    description: "",
    // description: segmentPayload?.segment_description,
  });

  const onCreateSegmentSuccess = (responseData) => {
    if (responseData) {
      const personaData = getValuesByKey(responseData, "persona");
      const contentCategoryData = getValuesByKey(
        responseData,
        "content_category"
      );

      const activityData = getValuesByKey(responseData, "activity");

      if (personaData?.length > 0) {
        setUserPersonaList([
          {
            persona_icon: img1,
            persona_id: "all",
            persona_name: "All",
          },
          ...personaData[0],
        ]);
        setActivityList(activityData[0]);
        setContentCategoryList(contentCategoryData[0]);
      }
      if (Object.keys(responseData).length > 0) {
        delete responseData.persona;
        delete responseData.content_category;

        console.log("segment data", responseData);
        setFilterData(responseData);
      }
    }
  };

  const { data: segmentcreateData, isLoading: isSegmentCreateLoading } =
    useGetSegmentCreateData(onCreateSegmentSuccess);

  const post_json_hcp_list = useRef({
    segment_id: segment_id,
    filter: {
      status: {
        type: "",
        value: 1,
      },
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      segment_id: "desc",
    },
  });

  const timeFormat = "YYYY-MM-DD HH:mm:ss";
  const getCurrentTime = () => dayjs(new Date().getTime()).format(timeFormat);

  const hcpColumns = [
    {
      Header: "Name",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        console.log("hcp_first_name", value);
        return value
          ? `${value.hcp_first_name} ${value.hcp_middle_name} ${value.hcp_last_name}`
          : "-";
      },
      // Filter: DefaultColumnFilter,
    },
    {
      Header: "UTM",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        console.log("hcp_utmsource", value);
        return value ? `${value.hcp_utmsource}` : "-";
      },
    },
    {
      Header: "Age ",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        return value ? `${value?.hcp_age}` : "--";
      },
    },
    {
      Header: "Location",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        return value
          ? `${value?.hcp_to_city?.city_name ?? "--"}, ${
              value?.hcp_to_state?.state_name ?? "--"
            }`
          : "--";
      },
    },
    {
      Header: "Speciality",
      accessor: (row) => {
        return row.hcp;
      },
      Cell: ({ value }) => {
        return value?.hcp_to_speciality?.speciality_name
          ? `${value?.hcp_to_speciality?.speciality_name}`
          : "--";
      },
    },
    {
      Header: "Updated on",
      accessor: "updated_at",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Action",
      accessor: "actionDetails",
      disableFilters: true,
      Cell: ({ row }) => (
        <ActionButtonBar
          buttons={["view"]}
          row={row}
          onView={(data) => {
            console.log("row hcp", row);
            redirectTo(`hcp-profile/details/${row.original.hcp_id}`);
          }}
        />
        // <div className="d-flex justify-content-center">
        //   <Button className="btn btn-icon btn-sm rounded-pill" role="button">
        //     <span className="btn-inner">{row.original.actionDetails}</span>
        //   </Button>
        // </div>
      ),
      // Filter: DefaultColumnFilter,
    },
  ];

  const editSegmentName = () =>
    setSegmentDetails({ ...segmentDetails, isNameEdit: true });

  const editSegmentDescription = () => {
    setSegmentDetails({ ...segmentDetails, isDescriptionEdit: true });
  };

  const saveSegmentName = () =>
    setSegmentDetails({ ...segmentDetails, isNameEdit: false });

  const saveSegmentDescription = () =>
    setSegmentDetails({ ...segmentDetails, isDescriptionEdit: false });

  const CancelEditSegmentName = () =>
    setSegmentDetails({
      ...segmentDetails,
      isNameEdit: false,
    });

  const CancelEditSegmentDescription = () => {
    setSegmentDetails({
      ...segmentDetails,
      isDescriptionEdit: false,
      // description: segmentDetails?.segment_description,
    });
  };
  const getSegmentName = (e) => {
    segment_add_payload.current.segment_name = e.target.value;
    setSegmentDetails({
      ...segmentDetails,
      name: e.target.value,
    });
  };
  const getSegmentDescription = (e) => {
    segment_add_payload.current.segment_description = e.target.value;
    setSegmentDetails({ ...segmentDetails, description: e.target.value });
  };

  // useEffect(() => {
  //   if (segmentcreateData) {
  //     onCreateSegmentSuccess(segmentcreateData);
  //   }
  // }, [segmentcreateData]);

  useEffect(() => {
    if ((!segment_id || segment_id <= 0) && location.state) {
      const { segment_name, segment_description } = location.state || {
        segment_name: "",
        segment_description: "",
      };
      segment_add_payload.current.segment_name = segment_name;
      segment_add_payload.current.segment_description = segment_description;

      setSegmentDetails({
        ...segmentDetails,
        name: segment_name,
        description: segment_description,
      });
    } else {
      setSegmentDetails({
        ...segmentDetails,
        name: "",
        description: "",
        isDescriptionEdit: true,
        isNameEdit: true,
      });
    }
  }, [location, segment_id]);

  useEffect(() => {
    if (segment_id > 0) {
      getEditSegmentData(segment_id);
    } else {
      // getCreateSegmentData();
    }

    return () => {
      segment_add_payload.current = innitialPayload;
    };
  }, []);

  function updatePersonaStatus(persona_id, alldata) {
    const updatedData = alldata.map((item) => {
      if (persona_id === "all") {
        if (
          item.status &&
          item.status.value &&
          item.status.value === "active"
        ) {
          item.status.value = "";
        } else {
          item.status.value = { value: "active" };
        }
      } else {
        if (item.persona_id === persona_id) {
          if (
            item.status &&
            item.status.value &&
            item.status.value === "active"
          ) {
            item.status.value = "";
          } else {
            item.status = { value: "active" };
          }
        }
      }
      return item;
    });

    if (persona_id === "all") {
      return updatedData.map((item) => ({
        ...item,
        status: { value: "active" },
      }));
    } else {
      return updatedData;
    }
  }

  function isAllPersonaActive(personaList) {
    // Check if "all" persona is present and has active status
    const allPersona = personaList.find(
      (persona) => persona.persona_id === "all"
    );

    if (
      allPersona &&
      allPersona.status &&
      allPersona.status.value === "active"
    ) {
      return true;
    } else {
      return false;
    }
  }

  const onUserPersonaSelect = (id) => {
    console.group("onUserPersonaSelect");
    console.log("id", id);
    let newPersonaList = [];

    if (id === "all" && isAllPersonaActive(userPersonaList)) {
      console.log("persona list:::::::: in if ");
      segment_add_payload.current.options.persona = [];
      newPersonaList = userPersonaList.map((el) => {
        return { ...el, status: { value: "" } };
      });
    } else if (id === "all" && !isAllPersonaActive(userPersonaList)) {
      console.log("persona list:::::::: in if else");
      newPersonaList = userPersonaList.map((el) => {
        return { ...el, status: { value: "active" } };
      });
    } else {
      newPersonaList = userPersonaList.map((el) =>
        el.persona_id == "all" && el?.status?.value == "active"
          ? { ...el, status: "" }
          : el.persona_id == id
          ? el?.status?.value == "active"
            ? { ...el, status: "" }
            : { ...el, status: { value: "active" } }
          : el
      );
    }

    const selectedUserPersona = newPersonaList.filter(
      (el) => el.persona_id != "all" && el.status?.value == "active"
    );
    console.log("<<------------>>", userPersonaList?.length, newPersonaList);
    console.log("<<------------>>", selectedUserPersona);

    const personaPayload = selectedUserPersona.map(({ persona_id }) => ({
      name: "persona_id",
      operator: "and",
      clause: "is",
      value: persona_id,
    }));
    segment_add_payload.current.options.persona = personaPayload;

    if (step <= 1) {
      setStep((prev) => prev + 1);
    }
    setUserPersonaList(newPersonaList);
    console.log("Payload:", segment_add_payload.current);
    console.groupEnd("onUserPersonaSelect");
  };

  function updateSelectedData(all_data, selectedData) {
    const updatedData = all_data.map((persona) => {
      const selectedPersona = selectedData.find(
        (selected) => selected.persona.persona_id === persona.persona_id
      );
      if (selectedPersona) {
        return { ...persona, status: { value: "active" } };
      } else {
        return persona;
      }
    });

    return updatedData;
  }

  const getEditSegmentData = async (_payload_id) => {
    let response = await getService(`${SEGMENT_EDIT_GET}/${_payload_id}`);
    if (response) {
      // getSegmentAnalyticsById(_payload_id);
      // getSegmentHcpListById(_payload_id);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      if (Object.keys(responseData)?.length > 0) {
        const { segment: segmentData, persona: allPersona } = responseData;

        const selectedPersona = updateSelectedData(
          allPersona,
          segmentData.segment_to_persona
        );

        const previous_segment_data = responseData.segment;

        if (selectedPersona?.length > 0) {
          selectedPersona.push({
            persona_icon: img1,
            persona_id: "all",
            persona_name: "All",
          });
          setUserPersonaList(selectedPersona);
          setContentCategoryList(responseData.content_category);
          setActivityList(responseData.activity);
          setStep(2);
        }
        if (Object.keys(responseData).length > 0) {
          console.log("responseData:", responseData);
          delete responseData.persona;
          delete responseData.content_category;
          delete responseData.segment;
          delete responseData.activity;
          setFilterData(responseData);
        }
        if (
          previous_segment_data &&
          Object.keys(previous_segment_data).length > 0
        ) {
          presetEditData(previous_segment_data, responseData);
        }
      }
      // setSegmentCreateDataIsLoading(false);
    }
  };

  const presetEditData = async (allData) => {
    const {
      segment_name,
      segment_description,
      segment_rules: {
        who_active,
        engaged_with,
        demographic,
        other,
        interesed_in,
      },
    } = allData;
    const mergedDemographicData = demographic.concat(other);
    const transformedData = await transformPayload(
      mergedDemographicData,
      payloadToDataKeyMap
    );
    setSegmentDetails({
      ...segmentDetails,
      name: segment_name,
      description: segment_description,
    });

    const oldPayload = {
      segment_name: segment_name,
      segment_description: segment_description,
      options: {
        persona: userPersonaList,
        who_active: who_active,
        engaged_with: engaged_with,
        interesed_in: interesed_in,
        demographic: transformedData,
        other: other,
      },
    };
    setSegmentPayloadData(oldPayload);

    segment_add_payload.current = oldPayload;

    // setUserWhoActiveEditData(who_active);
    // setUserInterestEditData(interesed_in);
    // setUserWhoEngagedEditData(engaged_with);
    // setUserDemographicEditData(transformedData);
  };

  function isFilterEmpty(obj) {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) && Array.isArray(obj[key])
          ? obj[key].length > 0
          : obj[key]
      ) {
        return false;
      }
    }
    return true;
  }

  const onPreviewSegmentClick = () => {
    console.log(
      "isFilterEmpty",
      isFilterEmpty(segment_add_payload.current),
      segment_add_payload.current.options
    );
    if (
      !segment_add_payload.current ||
      !segment_add_payload.current.hasOwnProperty("segment_name") ||
      !segment_add_payload.current?.segment_name
    ) {
      toast.error("Please enter segment name");
      return false;
    } else if (isFilterEmpty(segment_add_payload.current)) {
      toast.error("Please select atleast one filter");
      return false;
    } else {
    }

    if (segment_id || analyticsData.segment_id) {
      const tempPayload = {
        ...segment_add_payload.current,
        segment_id: segment_id ?? analyticsData.segment_id,
      };
      updateSegmentSend(tempPayload);
    } else {
      const payloadData = segment_add_payload.current;
      addSegmentSend(payloadData);
    }
  };

  async function transformPayload(payload, keyMap) {
    return payload.map((item) => {
      const { name, ...rest } = item || { name: "" };
      const transformedName = keyMap[name] || name;
      return { ...rest, name: transformedName };
    });
  }

  const addSegmentSend = async (payload) => {
    let data = payload;
    if (payload?.options?.demographic?.length > 0) {
      data.options.demographic = await transformPayload(
        payload?.options?.demographic,
        payloadKeyMap
      );
    } else if (payload?.options?.other?.length > 0) {
      data.options.other = await transformPayload(
        payload?.options?.other,
        payloadKeyMap
      );
    } else {
    }

    setSegementAddPostLoader(true);
    let response = await postService(SEGMENT_ADD_POST, data);
    if (response) {
      if (response.isError) {
        setSegementAddPostLoader(false);
        toast.error(response.data.message);
        return false;
      } else {
        if (response?.data?.data?.segment_id) {
          getSegmentAnalyticsById(response?.data?.data?.segment_id);
          getSegmentHcpListById(response?.data?.data?.segment_id);
          if (step == 3) {
            setAnalyticsData("");
            setShowTable(false);
            setStep(1);
            toast.success("Segment created successfully");
          }
        } else {
          toast.error("Something error happen. please try again");
        }
      }
      setSegementAddPostLoader(false);
    }
  };

  const updateSegmentSend = async (payload) => {
    let data = payload;
    if (payload?.options?.demographic?.length > 0) {
      data.options.demographic =
        payload?.options?.demographic?.length > 0
          ? await transformPayload(payload?.options?.demographic, payloadKeyMap)
          : [];
    } else if (payload?.options?.other?.length > 0) {
      data.options.other =
        payload?.options?.other?.length > 0
          ? await transformPayload(payload?.options?.other, payloadKeyMap)
          : [];
    } else {
    }

    setSegementAddPostLoader(true);
    let response = await postService(SEGMENT_UPDATE_POST, data);
    if (response) {
      if (response.isError) {
        setSegementAddPostLoader(false);
        return false;
      } else {
        // console.log("response", response);
        setSegementAddPostLoader(false);
        if (step < 3) {
          setAnalyticsData(response.data.data);
          setStep((prev) => prev + 1);
        } else if (step == 3) {
          setStep(1);
          setAnalyticsData("");
          toast.success("Segment created successfully");
          redirectTo("segment/listing");
        } else {
          // toast.error("Something went wrong");
        }
        console.log("step", step);
      }
    }
  };

  const getSegmentAnalyticsById = async (_payload_id) => {
    setGetAnanlyticsDataLoader(true);
    let response = await getService(`${SEGMENT_ANALYTICS_GET}/${_payload_id}`);
    if (response) {
      setGetAnanlyticsDataLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      if (responseData) {
        setAnalyticsData(responseData);
        setStep(3);
      }
    }
  };

  const getSegmentHcpListById = async (_payload_id) => {
    let payloadData = {
      ...post_json_hcp_list.current,
      segment_id: _payload_id,
    };
    // setGetHcpListLoader(true);
    let response = await postService(`${SEGMENT_HCP_LIST_GET}`, payloadData);
    if (response) {
      setGetHcpListLoader(false);
      if (response.isError) {
        return false;
      }
      const responseData = response.data.data;
      if (responseData && responseData?.rows?.length > 0) {
        const { rows, totalCount } = responseData || {
          rows: [],
          totalCount: 0,
        };

        setHcpListData(rows);
        setTotalHcpCount(totalCount);
      }
    }
  };

  function categorizeInput(input) {
    const output = input.reduce(
      (result, item) => {
        if (
          item.name === "degree" ||
          item.name === "channel" ||
          item.name == "consented"
        ) {
          result.other.push(item);
        } else {
          result.main.push(item);
        }
        return result;
      },
      { other: [], main: [] }
    );

    return output;
  }

  const onDemograpicFilterChange = useCallback((data) => {
    if (data?.length > 0) {
      let exportedData = categorizeInput(data);
      const { other, main } = exportedData || { other: [], main: [] };

      if (other?.length > 0) {
        segment_add_payload.current.options.other = other;
      }
      if (main?.length > 0) {
        segment_add_payload.current.options.demographic = main;
      }
    }
    setSegmentPayloadData(segment_add_payload.current);
  }, []);

  const onUserWhoActiveChnage = useCallback(
    (data) => {
      segment_add_payload.current.options.who_active[0] = data;
      console.log("who active", segment_add_payload.current);
      setSegmentPayloadData(segment_add_payload.current);
    },
    [segmentPayloadData]
  );

  const onUserWhoDidChnage = useCallback(
    (data) => {
      segment_add_payload.current.options.engaged_with = data;
      setSegmentPayloadData(segment_add_payload.current);
    },
    [segmentPayloadData]
  );

  const onUserInterestSelected = (val) => {
    segment_add_payload.current.options.interesed_in = [
      {
        name: "content_id",
        operator: "and",
        clause: "is",
        value: val.value,
      },
    ];
    // setSegmentPayloadData(segment_add_payload.current);
  };

  const onSegmentTypeChange = (type) => {
    setSegmentType(type);
  };

  const onRefreshButttonClick = async () => {
    let payload = {
      segment_id: analyticsData?.segment_id,
    };
    if (analyticsData?.segment_id) {
      setGetAnanlyticsDataLoader(true);
      let response = await postService(SEGMENT_REFRESH_ANALYTICS_GET, payload);
      if (response) {
        setGetAnanlyticsDataLoader(false);
        if (response.isError) {
          return false;
        }
        const responseData = response.data.data;
        if (responseData) {
          setAnalyticsData(responseData);
          setStep(3);
        }
      }
    }
  };
  const onHcpRefreshButttonClick = () => {
    if (analyticsData?.segment_id) {
      setGetHcpListLoader(true);
      getSegmentHcpListById(analyticsData?.segment_id);
    }
  };

  const onHcpTableStateChange = useCallback(
    async (state) => {
      let paylod = changeTableStateToPayload(state);
      post_json_hcp_list.current = { ...paylod, segment_id: segment_id };
      console.log("hcp post json", post_json_hcp_list.current);
      getSegmentHcpListById(segment_id);
    },
    [hcpListData, segment_id, post_json_hcp_list.current]
  );

  return (
    <Fragment>
      <div className="col-12 col-md-10 mb-4 d-flex justify-content-between w-100">
        <div className="d-flex justify-content-between flex-column">
          {segmentDetails && segmentDetails.isNameEdit ? (
            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="text"
                placeholder=" Name "
                className="border-0 rounded-3 px-2 py-1"
                value={segmentDetails.name}
                onChange={getSegmentName}
              />
              <Button
                variant="success"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={saveSegmentName}
              >
                {findIcon("Check", "outline", "18")}
              </Button>
              <Button
                variant="danger"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={CancelEditSegmentName}
              >
                {findIcon("Close-2", "outline", "14")}
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-start align-items-center gap-2">
              <h4 className="mb-0 mt-2">
                {segmentDetails.name ?? "Enter Name"}
              </h4>
              <span
                className="cursor-pointer d-inline-flex"
                onClick={editSegmentName}
              >
                {findIcon("Pencil", "dual-tone", "20")}
              </span>
            </div>
          )}
          {segmentDetails && segmentDetails.isDescriptionEdit ? (
            <div className="d-flex justify-content-start align-items-center gap-2 mt-2">
              <input
                type="text"
                placeholder="Description "
                className="border-0 rounded-3 px-2 py-1"
                value={segmentDetails.description}
                onChange={getSegmentDescription}
              />
              <Button
                variant="success"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={saveSegmentDescription}
              >
                {findIcon("Check", "outline", "18")}
              </Button>
              <Button
                variant="danger"
                className="p-0 icon-32 rounded-circle d-flex align-items-center justify-content-center"
                type="submit"
                onClick={CancelEditSegmentDescription}
              >
                {findIcon("Close-2", "outline", "14")}
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-start align-items-center gap-2 mt-2">
              <p className="mb-0 mt-1">
                {/* {segmentPayload?.segment_description} */}
                {segmentDetails?.description ?? "Enter Description"}
              </p>
              <span
                className="cursor-pointer d-inline-flex"
                onClick={editSegmentDescription}
              >
                {findIcon("Pencil", "dual-tone", "20")}
              </span>
            </div>
          )}
        </div>
        {/* <Dropdown className="me-4">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            type="button"
            id="dropdownMenuButtonSM"
          >
            Segment Type
          </Dropdown.Toggle>
          <Dropdown.Menu aria-labelledby="dropdownMenuButtonSM">
            <Dropdown.Item
              onClick={() => onSegmentTypeChange(DEFAULT_SEGMENT_TYPE)}
            >
              Dynamic
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => onSegmentTypeChange(LIST_SEGMENT_TYPE)}
            >
              List
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </div>
      <Card>
        <Card.Body>
          <Row>
            {
              <Col lg={12}>
                {<h5>Select Persona(s)</h5>}
                <div className="d-flex flex-row flex-wrap gap-3 mb-1 mt-4">
                  {!isSegmentCreateLoading && userPersonaList?.length > 0 ? (
                    userPersonaList?.map((persona) => (
                      <UserPersonaCard
                        name={persona.persona_name}
                        image={persona.persona_icon ?? img1}
                        id={persona.persona_id}
                        active={persona?.status?.value == "active"}
                        click={onUserPersonaSelect}
                      />
                    ))
                  ) : isSegmentCreateLoading ? (
                    [1, 2, 3, 4, 5, 6].map(() => <UserPersonaSkeleton />)
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            }

            {step >= 2 && (
              <Col lg={12} className="mt-4">
                <UserWhoActiveForm
                  onFormDataChange={onUserWhoActiveChnage}
                  isEditMode={segment_id > 0}
                  editData={
                    segmentPayloadData &&
                    segmentPayloadData?.options?.who_active?.length > 0
                      ? segmentPayloadData?.options?.who_active[0]
                      : []
                  }
                />
                <UserWhoDidForm
                  title="Users Engaged with:"
                  contentCategoryList={contentCategoryList}
                  activityList={activityList}
                  onFromDataChange={onUserWhoDidChnage}
                  isEditMode={segment_id > 0}
                  editData={
                    segmentPayloadData &&
                    segmentPayloadData?.options?.engaged_with?.length > 0
                      ? segmentPayloadData?.options?.engaged_with
                      : []
                  }
                />

                <UserInterestedIn
                  title="User Interesed In"
                  onUserInterestSelected={onUserInterestSelected}
                  isEditMode={segment_id > 0}
                  editData={
                    segmentPayloadData &&
                    segmentPayloadData?.options?.interesed_in?.length > 0
                      ? segmentPayloadData?.options?.interesed_in[0]
                      : []
                  }
                />

                <UserDemograpicForm
                  filterData={filterData}
                  payload={segment_add_payload.current}
                  onFromDataChange={onDemograpicFilterChange}
                  isEditMode={segment_id > 0}
                  editData={
                    segmentPayloadData &&
                    segmentPayloadData?.options?.demographic?.length > 0
                      ? segmentPayloadData.options.demographic
                      : []
                  }
                />
              </Col>
            )}
            {step == 2 && (
              <Col
                lg={12}
                className="d-flex flex-row gap-3 justify-content-md-center justify-content-center"
              >
                <Button
                  type="button"
                  className=""
                  onClick={onPreviewSegmentClick}
                  disabled={segementAddPostLoader}
                >
                  {!segementAddPostLoader
                    ? "Preview Segment"
                    : "Collecting Segment..."}
                </Button>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>

      {analyticsData && step >= 3 && (
        <Card>
          <Card.Body>
            <Row>
              <Col lg="12" md="12">
                <Tab.Container defaultActiveKey="first">
                  <div className="tab-bottom-bordered">
                    <Nav
                      className="mb-0 nav-tabs justify-content-around d-flex"
                      id="nav-tab1"
                      role="tablist"
                    >
                      <Nav.Link
                        className="py-3 "
                        eventKey="first"
                        data-bs-toggle="tab"
                        data-bs-target="#analytics"
                        type="button"
                        role="tab"
                        aria-controls="analytics"
                        aria-selected="true"
                      >
                        Analytics
                      </Nav.Link>
                      <Nav.Link
                        className="py-3"
                        eventKey="second"
                        data-bs-toggle="tab"
                        data-bs-target="#hcp-listing"
                        type="button"
                        role="tab"
                        aria-controls="hcp-listing"
                        aria-selected="false"
                      >
                        Hcp Listing
                      </Nav.Link>
                    </Nav>
                  </div>
                  <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                    <Tab.Pane
                      eventKey="first"
                      id="analytics"
                      role="tabpanel"
                      aria-labelledby="analytics"
                    >
                      <>
                        {analyticsData?.last_refreshed && (
                          <div className="d-flex justify-content-between pt-4">
                            <p>
                              Last refreshed on {analyticsData?.last_refreshed}
                            </p>
                            <div className="d-flex justify-content-end mb-3 gap-3 align-items-center">
                              <span
                                className={
                                  getAnanlyticsDataLoader
                                    ? "animate_spinner"
                                    : ""
                                }
                                onClick={onRefreshButttonClick}
                              >
                                {findIcon("Refresh", "outline", "20")}
                              </span>
                            </div>
                          </div>
                        )}
                        {!getAnanlyticsDataLoader && analyticsData ? (
                          <SegmentAnalyticsSection
                            segmentSize={analyticsData?.segment_size}
                            preferEmail={analyticsData?.prefer_email}
                            preferWhatsApp={analyticsData?.prefer_whatsapp}
                            preferSms={analyticsData?.prefer_sms}
                            excludingDnd={analyticsData?.exclcuding_dnd}
                            availableTouchPoint={
                              analyticsData?.avg_available_touch_point
                            }
                            barredByDndStatus={
                              analyticsData?.barred_by_dnd_status
                            }
                            totalReach={analyticsData?.total_reach}
                          />
                        ) : getAnanlyticsDataLoader ? (
                          <SegmentAnalyticsSkeleton />
                        ) : (
                          <PageNotFound
                            title="Not Found"
                            message="No Hcp Found For This Segment"
                            buttonText=""
                          />
                        )}
                      </>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="second"
                      id="hcp-listing"
                      role="tabpanel"
                      aria-labelledby="hcp-listing"
                    >
                      {analyticsData?.last_refreshed && (
                        <div className="d-flex justify-content-between pt-4">
                          <p>
                            Last refreshed on {analyticsData?.last_refreshed}
                          </p>
                          <div className="d-flex justify-content-end mb-3 gap-3 align-items-center">
                            <span
                              className={
                                getHcpListLoader ? "animate_spinner" : ""
                              }
                              onClick={onHcpRefreshButttonClick}
                            >
                              {findIcon("Refresh", "outline", "20")}
                            </span>
                          </div>
                        </div>
                      )}
                      {!getHcpListLoader &&
                      hcpListData &&
                      hcpListData?.length > 0 ? (
                        <ReactTable
                          data={hcpListData}
                          columns={hcpColumns}
                          tdClass={"threeColTable"}
                          thClass={"threeColTable"}
                          recordsTotal={totalHcpCount}
                          onTableStateChange={onHcpTableStateChange}
                          initialState={post_json_hcp_list.current}
                          recordsPerPage={
                            post_json_hcp_list.current.pagination.limit
                          }
                        />
                      ) : getHcpListLoader ? (
                        <CommonTableLoader />
                      ) : (
                        <PageNotFound
                          title="Not Found"
                          message="No Hcp Found For This Segment"
                          buttonText=""
                        />
                      )}
                    </Tab.Pane>
                    <Col
                      lg={12}
                      className="d-flex justify-content-end mb-3 gap-3 align-items-center mb-5 mt-5"
                    >
                      {/* <Button type="button" variant="secondary" className="">
                          Save as Draft
                        </Button> */}

                      <Button
                        type="button"
                        className=""
                        onClick={onPreviewSegmentClick}
                      >
                        {segementAddPostLoader
                          ? "Creating Segment..."
                          : "Create Segment"}
                      </Button>
                      {/* <SubmitButton variant="primary" /> */}
                    </Col>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};
export default memo(CreateSegment);
