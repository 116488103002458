import React from "react";
import { findIcon } from "../../../dashboard/icons";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import ReactTable from "../../../../lib/table/react-table";
import Flatpickr from "react-flatpickr";
const EngagementTasks = () => {
  const { redirectTo } = useRedirect();

  const engagement_data = {
    data: [
      {
        checkBox: "",
        taskName: "Onboarding for Doctor",
        taskType: "Onboarding",
        deadline: "2023-05-31",
        priority: "High",
        status: { status: "Pending", color: "danger" },
        taskId: findIcon("Pencil", "dual-tone"),
        assignedBy: "Arnab Saha",
      },
      {
        checkBox: "",
        taskName: "Consent from Doctor",
        taskType: "Engagement",
        deadline: "2023-06-15",
        priority: "Medium",
        status: { status: "Completed", color: "primary" },
        taskId: findIcon("Eye", "solid"),
        assignedBy: "Sumit Mandal",
      },
      {
        checkBox: "",
        taskName: "Onboarding for Doctor",
        taskType: "Onboarding",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "info" },
        taskId: findIcon("Pencil", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Consent from Doctor",
        taskType: "Engagement",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "info" },
        taskId: findIcon("Pencil", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Onboarding for Doctor",
        taskType: "Onboarding",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "info" },
        taskId: findIcon("Pencil", "dual-tone"),
        assignedBy: "Indranil Kundu",
      },
      {
        checkBox: "",
        taskName: "Personal engagement task",
        taskType: "engagement",
        deadline: "2023-06-15",
        priority: "Low",
        status: { status: "In Progress", color: "info" },
        taskId: findIcon("Pencil", "dual-tone"),
        assignedBy: "Indranil kumar",
      },
    ],
    columns: [
      //   {
      //     Header: "",
      //     accessor: "checkBox",
      //     Cell: ({ row }) => <input type="checkbox" />,
      //   },
      {
        Header: "Action Name",
        accessor: "taskName",
      },
      {
        Header: "Action Type",
        accessor: "taskType",
      },
      {
        Header: "Deadline",
        accessor: "deadline",
      },
      {
        Header: "Priority",
        accessor: "priority",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.priority == "Low"
                  ? "secondary"
                  : row.values.priority == "Medium"
                  ? "warning"
                  : row.values.priority == "High"
                  ? "danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.priority}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          // console.log("row", row, row.values.status.status),
          <div className="d-flex align-items-center justify-content-center">
            <Badge
              bg={
                row.values.status.status == "In Progress"
                  ? "soft-info"
                  : row.values.status.status == "Completed"
                  ? "soft-success"
                  : row.values.status.status == "Pending"
                  ? "soft-danger"
                  : ""
              }
              className="me-2"
            >
              <span className="btn-inner">{row.values.status.status}</span>
            </Badge>
          </div>
        ),
      },
      {
        Header: "Assigned by",
        accessor: "assignedBy",
      },
      {
        Header: "Action",
        accessor: "taskId",
        // disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => {
                if (row.values.taskType == "engagement") {
                  redirectTo("action/engagement-task/12");
                } else {
                  redirectTo("action/reply-task/12");
                }
              }}
            >
              <span className="btn-inner">{row.values.taskId}</span>
            </Button>
          </div>
        ),
      },
    ],
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Engagement Task List</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          <div className="form-group mb-0 ">
            <select className="form-select">
              <option defaultValue>Sort By</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />
          </div>
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <ReactTable
                data={engagement_data.data}
                columns={engagement_data.columns}
                recordsPerPage={10}
                recordsTotal={engagement_data.data?.length}
              />
              {/* <CommonTableLoader /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EngagementTasks;
