import React from "react";
import TabNavigation from "../../../../../views/workspace/doctube/pages/channel/components/TabNavigation";
import AppearanceTabSkeleton from "./appearance-tab-skeleton";
import BasicInfoTabSkeleton from "./basic-info-tab-skeleton";
import LayoutTabSkeleton from "./layout-tab-skeleton";
import BrandingTabSkeleton from "./branding-tab-skeleton";

function CustomizationSkeleton() {
  const tabs = [
    {
      title: "Layout",
      eventKey: "first",
      Content: () => <LayoutTabSkeleton />,
    },
    {
      title: "Branding",
      eventKey: "second",
      Content: () => <BrandingTabSkeleton />,
    },
    {
      title: "Basic Info",
      eventKey: "thired",
      Content: () => <BasicInfoTabSkeleton />,
    },
    {
      title: "Appearance",
      eventKey: "fourth",
      Content: () => <AppearanceTabSkeleton />,
    },
  ];
  return <TabNavigation tabs={tabs} />;
}

export default CustomizationSkeleton;
