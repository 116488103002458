import React from "react";
import { Card } from "react-bootstrap";
import Timeline from "../components/Timeline";
import Titlebar from "../components/Titlebar";
import TimelineSkeleton from "../../../../../skeleton/workspace/clirnet/events/TimelineSkeleton";
import { useParams } from "react-router-dom";
import {
  useGetEngagementReport,
  useGetSummaryContentInfo,
} from "../../../../../queries/query-hooks/workspace/events-hook";
import dayjs from "dayjs";
import CardNotFound from "../components/CardNotFound";

const UserEngagement = () => {
  const { project_id } = useParams();

  const { data: summaryContentInfo, isLoading: isSummaryContentInfoLoading } =
    useGetSummaryContentInfo(project_id);

  const { data: engagementReport, isLoading: isEngagementReport } =
    useGetEngagementReport(project_id);

  const eventStartDate = dayjs(
    summaryContentInfo?.project_detail?.project_start_date
  ).format("DD-MM-YYYY");

  return (
    <div>
      <Titlebar
        title={"Summary"}
        projectType={"Events"}
        projectStartDate={eventStartDate}
        isLoading={isSummaryContentInfoLoading}
      />
      <Card>
        <Card.Body>
          {!isEngagementReport && engagementReport?.length > 0 ? (
            <Timeline engagementReport={engagementReport} />
          ) : isEngagementReport ? (
            <TimelineSkeleton />
          ) : (
            <CardNotFound
              title={"User Engagement"}
              isTitleCenter={true}
              height={360}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UserEngagement;
