import { useFormik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  EMAIL_TEMPLATE_ADD,
  EMAIL_TEMPLATE_BANK_DETAILS,
  EMAIL_TEMPLATE_CREATE_GET,
  EMAIL_TEMPLATE_EDIT,
  EMAIL_TEMPLATE_UPDATE,
  TEMPLATE_VARIABLE_LIST_GET,
} from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import postService from "../../../../../api/services/post-service";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import PaginationLoader from "../../../../../components/loader/pagination-loader";
import GrapejsEditor from "../../../../../lib/email-editor/grapejs-editor";
import { findIcon } from "../../../../dashboard/icons";
import SubmitButton from "../../../../../components/form-components/submit-button";
const sampleHtml =
  "https://api.unlayer.com/v2/templates?page=1&perPage=10&includeDesign=1";
const CreateEmailTemplate = ({ modalClose, type }) => {
  const [templateName, setTemplateName] = useState("");
  const [presetHTML, setPresetHTML] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editor, setEditor] = useState(null);
  const { id } = useParams();
  const { state } = useLocation();
  const emailEditorRef = useRef(null);
  const { redirectTo } = useRedirect();
  const [edit, setEditMode] = useState(false);
  const [mergeTags, setMergeTags] = useState("");

  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  useEffect(() => {
    if (id) {
      // console.log('here id is', id);
      setEditMode(true);
    }
  }, [id]);

  useEffect(() => {
    console.log("presetHTML", presetHTML);
  }, [presetHTML]);

  useEffect(() => {
    createTemplate(edit ? true : false);
    getVariableList();
  }, [edit]);

  useEffect(() => {
    if (state) {
      setTemplateName(state?.template_name ? state?.template_name : "");
      setValues({
        ...values,
        name: state?.template_name ? state.template_name : "",
      });
      if (state?.bank_id) {
        getBankDetails(state?.bank_id);
      }

      console.log("state.suggestionContent", typeof state.suggestionContent);
      if (state?.editType == "autogenerated") {
        if (state?.suggestionContent) {
          setSelectedSuggestion(JSON.stringify(state.suggestionContent.body));
          setPresetHTML(JSON.stringify(state.suggestionContent.body));
        } else {
          toast.error("Please Select a suggestion");
        }
      }
    }
  }, []);

  const getBankDetails = async (id) => {
    try {
      let response = await getService(EMAIL_TEMPLATE_BANK_DETAILS + "/" + id);
      if (response) {
        console.log("bank details response", response);
        if (response.isError) {
          toast.error(response?.error);
          return false;
        }
        if (response.data.success) {
          let data = response.data.data;
          setPresetHTML(data.bank_html);
          onLoad();
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const initialValues = {
    category_id: "",
    email_template_type: "",
    name: "",
    email_subject: "",
    from_name: "",
    from_email: "",
    status: "",
  };

  const validationSchema = Yup.object().shape({
    category_id: Yup.string().required("Please select category"),
    email_template_type: Yup.string().required("Please select template type"),
    name: Yup.string().required("Please enter template name"),
    email_subject: Yup.string().required("Please enter email subject"),
    // email_services_id: Yup.string().required("Please select service type"),
    from_name: Yup.string().required("Please enter from name"),
    from_email: Yup.string().required("Please enter from email"),
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("onSaveDraftClick 2", values);
      addEditTemplate({ html: editor.getHtml(), css: editor.getCss() });
      // await emailEditorRef.current.editor.exportHtml((data) => {
      // 	console.log("export data", data);
      // 	const { design, html } = data;
      // 	console.log("exportHtml", html);
      // 	console.log("exportJson", design);
      // 	// console.log("exportDesign", design);
      // 	addEditTemplate(data);
      // });

      // await emailEditorRef.current.editor.exportJson((data) => {
      //   // const { design, html } = data;
      //   console.log("exportdata", data);
      //   // console.log("exportDesign", design);
      //   // addEditTemplate(html);
      // });
    },
  });

  const addEditTemplate = async (Data) => {
    setLoading(true);

    let postObj = {
      email_name: values.name,
      email_content: JSON.stringify(Data),
      email_jsons: Data,
      email_subject: values.email_subject,
      email_category_id: values.category_id,
      email_template_type: values.email_template_type,
      email_from_name: values.from_name,
      email_from_email: values.from_email,
      is_draft: values.is_draft ?? 0,
      email_duedate: "2024-03-09",
      email_priority_id: 1,
      email_workflow_id: 5,
    };

    console.log("postObj::====>", postObj);

    try {
      let response = await postService(
        edit ? EMAIL_TEMPLATE_UPDATE + "/" + id : EMAIL_TEMPLATE_ADD,
        postObj
      );
      if (response) {
        if (response.isError) {
          toast.error(response?.error);
          setLoading(false);
          // return false;
        }

        if (response.data.success) {
          handleReset();

          if (!edit) {
            let email_id = response.data.data.email_id;
            // let actionAddResponse = await postService(
            //   "/template_email/second-step-add/" + email_id,
            //   {}
            // );
            // console.log("actionAddResponse", actionAddResponse);
          }
          toast.success(response.data.message);
          setLoading(false);
          redirectTo("template-management/email-template");
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (editor && selectedSuggestion) {
      editor.setComponents(presetHTML);
      setPresetHTML(presetHTML);
    }
  }, [selectedSuggestion, editor, presetHTML]);

  const onReady = () => {
    if (selectedSuggestion) {
      editor.setComponents(selectedSuggestion);
    }
  };

  const onLoad = () => {
    if (editor !== null && presetHTML !== null) {
      editor.setComponents(JSON.parse(presetHTML).html);
      editor.setStyle(JSON.parse(presetHTML).css);
    }
  };

  const createTemplate = async (isEdit = false) => {
    try {
      setLoading(true);
      const response = await getService(
        isEdit ? EMAIL_TEMPLATE_EDIT + "/" + id : EMAIL_TEMPLATE_CREATE_GET
        // post_json.current
      );

      // console.log("category list", response.data.data.category);
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.category.length > 0) {
        // console.log("category list", response.data.data.category);
        setCategoryList(response.data.data.category);
        if (response.data.data.template) {
          let values = response.data.data.template;
          // console.log("values::======>>", values);
          setPresetHTML(
            values.email_content
              ? values.email_content
              : "<h1>No Content Found</h1>"
          );
          setValues({
            ...values,
            category_id: values?.email_category_id?.category_id,
            email_template_type: values?.email_template_type?.id,
            name: values.email_name,
            email_subject: values.email_subject,
            from_name: values.email_from_name,
            from_email: values.email_from_email,
            status: values.status.id,
          });

          // onLoad(values.email_json ? values.email_json : "");
        }

        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCloseClick = () => {
    if (type === "content-studio") {
      modalClose();
    } else {
      redirectTo("template-management/email-template");
    }
  };

  const post_json_variable = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      variable_id: "desc",
    },
  });

  const getVariableList = async () => {
    try {
      setLoading(true);
      const response = await postService(
        TEMPLATE_VARIABLE_LIST_GET,
        post_json_variable.current
      );
      // console.log("Variable template service", response.data.data);
      if (response.data.data.rows.length > 0) {
        arrangeVariableData(response.data.data.rows);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const arrangeVariableData = (data) => {
    const variableObjectField = [];
    let modifiedVariableArray = [];
    let requiredArrayObjectForEditor = [];
    data.forEach((obj) => {
      const { variable_name, variable_type, variable_id } = obj;
      variableObjectField.push({ variable_name, variable_type, variable_id });
    });
    variableObjectField.forEach((obj, index) => {
      const {
        variable_name,
        variable_type: { id: variable_type_id, value: variable_type_value },
        variable_id,
      } = obj;
      const modifiedObject = {
        variable_name,
        variable_type_id,
        variable_type_value,
        variable_id,
      };
      modifiedVariableArray.push(modifiedObject);
    });
    // console.log("modifiedVariableArray", modifiedVariableArray);

    let allVariableType = modifiedVariableArray.map(
      (item) => item.variable_type_value
    );
    const uniqueSet = new Set(allVariableType.map((item) => item));
    const uniqueVariableType = [...uniqueSet];
    // console.log("uniqueVariableType ==>", uniqueVariableType);

    let variableTypeList = [];
    uniqueVariableType.forEach((item) => {
      let temp = modifiedVariableArray.filter(
        (item1) => item1.variable_type_value == item
      );
      variableTypeList.push(temp);
    });
    // console.log("variableTypeList:: ==>", variableTypeList);

    let temp = [];
    let myObjectNeeded = {};
    console.log("uniqueVariableType", uniqueVariableType, variableTypeList);
    uniqueVariableType.map((item) => {
      variableTypeList.forEach((item1) => {
        if (item1[0].variable_type_value == item) {
          temp.push({
            name: item,
            tags: item1,
          });
        }
      });
    });
    setMergeTags(temp);
  };
  const onSaveDraftClick = (val) => {
    console.log("onSaveDraftClick", val);

    setValues({ ...val, is_draft: 1 });
    handleSubmit();
  };

  return (
    <>
      <Fragment>
        <Card>
          <Card.Header className="mb-3">
            <div className="d-flex justify-content-between">
              <h3 className="m-0">
                {edit
                  ? "Template: Edit Email Template"
                  : templateName != ""
                    ? templateName
                    : "Create Email Template"}
              </h3>
              <div className="d-flex justify-content-start gap-4">
                {/* <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1"
                  onClick={() => onSaveDraftClick(values)}
                >
                  {findIcon("Save", "dual-tone", 22)}
                  Save Draft
                </a> */}
                <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 link-danger"
                  onClick={handleCloseClick}
                >
                  {findIcon("Square X", "dual-tone", 22)}
                  Close
                </a>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Select
                    id="category_id"
                    name="category_id"
                    value={values?.category_id}
                    isInvalid={touched.category_id && !!errors.category_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Category</option>
                    {!loading && categoryList?.length > 0 ? (
                      categoryList.map((item, index) => (
                        <option key={index} value={item.category_id}>
                          {item.category_name}
                        </option>
                      ))
                    ) : loading ? (
                      <option value="">Loading...</option>
                    ) : null}
                  </Form.Select>
                  <Form.Label htmlFor="category_id">Category</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.category_id}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Select
                    id="email_template_type"
                    name="email_template_type"
                    value={values.email_template_type}
                    isInvalid={
                      touched.email_template_type &&
                      !!errors.email_template_type
                    }
                    onChange={handleChange}
                  >
                    <option value="">Select Template Type</option>
                    <option value="0">Promotional</option>
                    <option value="1">Transactional</option>
                  </Form.Select>
                  <Form.Label htmlFor="email_template_type">
                    Select Type
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.email_template_type}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              {edit || templateName == "" ? (
                <Col md={6} className="mb-4">
                  <Form.Floating className="custom-form-floating">
                    <Form.Control
                      type="text"
                      className=""
                      id="name"
                      autoComplete="Name"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Label htmlFor="name">Name</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Floating>
                </Col>
              ) : (
                ""
              )}
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    id="email_subject"
                    autoComplete="Subject"
                    placeholder="Subject"
                    name="email_subject"
                    value={values.email_subject}
                    isInvalid={touched.email_subject && !!errors.email_subject}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="email_subject">Subject</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.email_subject}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    id="from_name"
                    autoComplete="From Name"
                    placeholder="From Name"
                    name="from_name"
                    value={values.from_name}
                    isInvalid={touched.from_name && !!errors.from_name}
                    onChange={handleChange}
                  />
                  <Form.Label htmlFor="from_name">From Name</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.from_name}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    id="from_email"
                    autoComplete="From Email"
                    placeholder="From Email"
                    name="from_email"
                    onChange={handleChange}
                    value={values.from_email}
                    isInvalid={touched.from_email && !!errors.from_email}
                  />
                  <Form.Label htmlFor="from_email">From Email</Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {errors.from_email}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col md={12} className="mb-3 mt-2">
                <h5 className="mb-0">Content</h5>
              </Col>
              <Col md={12} className="mb-4">
                <div className="boredr-2 border border-dashed p-4 rounded-3">
                  {mergeTags && (
                    // <ReactEmailEditor
                    //   ref={emailEditorRef}
                    //   onReady={onReady}
                    //   onLoad={onLoad}
                    //   mergeTags={mergeTags && mergeTags}
                    // />

                    <GrapejsEditor
                      ref={emailEditorRef}
                      onReady={onReady}
                      onLoad={onLoad}
                      mergeTags={mergeTags ? mergeTags : ""}
                      presetData={presetHTML}
                      setEditor={(e) => setEditor(e)}
                      editor={editor}
                    />
                  )}
                </div>
              </Col>
              <div className="d-flex justify-content-end">
                <SubmitButton variant="primary" onClick={handleSubmit}>
                  {edit ? (
                    loading ? (
                      <PaginationLoader />
                    ) : (
                      "Update Template for Approval"
                    )
                  ) : loading ? (
                    <PaginationLoader />
                  ) : (
                    "Create Template for Approval"
                  )}
                </SubmitButton>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Fragment>
    </>
  );
};

export default CreateEmailTemplate;
