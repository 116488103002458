import React from "react";
import PageNotFound from "../../../../PageNotFound";
import { findIcon } from "../../../../dashboard/icons";

const CardNotFound = ({
  title,
  height = "100%",
  width = "100%",
  isTitleCenter = false,
}) => {
  return (
    <div
      style={{ height: height, width: width }}
      className="bg-white rounded-3 p-3"
    >
      <h5 className={`m-0 ${isTitleCenter ? "text-center" : ""}`}>{title}</h5>
      <div className="d-flex w-100 h-100 justify-content-center align-items-center">
        <PageNotFound
          title="No Data Found"
          backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
        />
      </div>
    </div>
  );
};

export default CardNotFound;
