import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

function BarChart({ series, labels, title, height = 300, name }) {
  let chartOptions = {
    series: [
      {
        data: series || [], // Adjust the data to have three values
        name: name ? name : "",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          horizontal: true,
          distributed: true, // for diff clor
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + `ms`;
        },
      },
      xaxis: {
        categories: labels || [], // Ensure there are three categories
      },
      colors: ["#305ab8", "#647fbb", "#3b4b71"],
    },
  };

  return (
    <>
      <Card className="h-100 m-0">
        <Card.Header className=" align-items-center justify-content-between">
          <h5 className="text-center subHeading position-relative">{title}</h5>
        </Card.Header>
        <Card.Body className="p-3">
          <div id="chart">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="bar"
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default BarChart;
