//
export const GET_SPONSORED_CONTENT_SUMMARY_ANALYTICS =
  "sponsored-content/summary/analytics";
export const GET_SPONSORED_CONTENT_SUMMARY_LIST =
  "sponsored-content/summary/list";
export const GET_SPONSORED_CONTENT_SUMMARY_CONTENT_GRAPH =
  "sponsored-content/summary/content-graph";
export const GET_SPONSORED_CONTENT_SUMMARY_CONTENT_READ =
  "sponsored-content/summary/content-read";
export const GET_SPONSORED_CONTENT_SUMMARY_CTA_VIEW_READ =
  "sponsored-content/summary/cta-views-read";
export const GET_SPONSORED_CONTENT_SUMMARY_LOCATION_MAP =
  "sponsored-content/summary/location-distribution";

export const GET_SPONSORED_CONTENT_SUMMARY_LOCATION_MAP_READS =
  "sponsored-content/summary/location-distribution-reads";

export const GET_SPONSORED_CONTENT_SUMMARY_DEVICE_DISTRIBUTION =
  "sponsored-content/summary/device-distribution";
export const GET_SPONSORED_CONTENT_SUMMARY_DEVICE_DISTRIBUTION_READS =
  "sponsored-content/summary/device-distribution-reads";
export const GET_SPONSORED_CONTENT_SUMMARY_ZONAL_DISTRIBUTION =
  "sponsored-content/summary/zonal-distribution";
export const GET_SPONSORED_CONTENT_SUMMARY_ZONAL_DISTRIBUTION_READS =
  "sponsored-content/summary/zonal-distribution-reads";
//
export const GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_VIEWS =
  "sponsored-content/analytics/content-views";
export const GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_REACH =
  "sponsored-content/analytics/content-reach";
export const GET_SPONSORED_CONTENT_ANALYTICS_LOCATION_DISTRIBUTION =
  "sponsored-content/analytics/location-distribution";
export const GET_SPONSORED_CONTENT_ANALYTICS_DEVICE_VIEWS =
  "sponsored-content/analytics/device-views";
export const GET_SPONSORED_CONTENT_ANALYTICS_ZONE_VIEWS =
  "sponsored-content/analytics/zone-views";
export const GET_SPONSORED_CONTENT_ANALYTICS_CONTENT_CATEGORY =
  "sponsored-content/analytics/content-category-views";
export const GET_SPONSORED_CONTENT_ANALYTICS_MOBILE_DEVICE_VIEWS =
  "sponsored-content/analytics/mobile-device-views";
export const GET_SPONSORED_CONTENT_ANALYTICS_BROWSER_VIEWS =
  "sponsored-content/analytics/browser-views";
export const GET_SPONSORED_CONTENT_ANALYTICS_LOCATION_DISTRIBUTION_READ =
  "sponsored-content/analytics/location-reads";
export const GET_SPONSORED_CONTENT_ANALYTICS_DEVICE_VIEWS_READ =
  "sponsored-content/analytics/device-reads";
export const GET_SPONSORED_CONTENT_ANALYTICS_ZONE_VIEWS_READ =
  "sponsored-content/analytics/zone-reads";
export const GET_SPONSORED_CONTENT_AUDIENCE_READ_BY_SCREEN_SIZE =
  "sponsored-content/analytics/reads-by-screensize";
export const GET_SPONSORED_CONTENT_ANALYTICS_MOBILE_DEVICE_READ =
  "sponsored-content/analytics/mobile-device-reads";
export const GET_SPONSORED_CONTENT_ANALYTICS_BROWSER_READ =
  "sponsored-content/analytics/browser-reads";

//
export const GET_SPONSORED_CONTENT_AUDIENCE_DAYWISE_PERFORMANCE =
  "sponsored-content/audience/daywise-performance";
export const GET_SPONSORED_CONTENT_AUDIENCE_ZONAL_DISTRIBUTION =
  "sponsored-content/audience/zonal-distribution";
export const GET_SPONSORED_CONTENT_AUDIENCE_VIEWS_BY_SCREEN_SIZE =
  "sponsored-content/audience/views-by-screensize";
export const GET_SPONSORED_CONTENT_AUDIENCE_DEVICE_DISTRIBUTION =
  "sponsored-content/audience/device-distribution";
export const GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITY_BREAKUP =
  "sponsored-content/audience/speciality-breakup";
export const GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITIES_REPORT =
  "sponsored-content/audience/specialities-report";

//
export const GET_SPONSORED_CONTENT_LOCATION_MAP_DISTRIBUTION =
  "sponsored-content/location/location-map-distribution";
export const GET_SPONSORED_CONTENT_LOCATION_MAP_DISTRIBUTION_READS =
  "sponsored-content/location/location-map-distribution-reads";
export const GET_SPONSORED_CONTENT_LOCATION_MAJOR_CITIES =
  "sponsored-content/location/major-cities";
export const GET_SPONSORED_CONTENT_LOCATION_MAJOR_CITIES_READS =
  "sponsored-content/location/major-cities-reads";
export const GET_SPONSORED_CONTENT_LOCATION_ZONAL_DISTRIBUTION =
  "sponsored-content/location/zonal-distribution";
export const GET_SPONSORED_CONTENT_LOCATION_ZONAL_DISTRIBUTION_READS =
  "sponsored-content/location/zonal-distribution-reads";
export const GET_SPONSORED_CONTENT_LOCATION_REPORT =
  "sponsored-content/location/location-report";
export const GET_SPONSORED_CONTENT_LOCATION_REPORT_READS =
  "sponsored-content/location/location-report-reads";

//
export const GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST =
  "sponsored-content/creative/creativedata-list";
export const GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST_P2 =
  "sponsored-content/creative/creativedata-list-p2";
export const GET_CREATIVE_DETAILS = "sponsored-content/creative/info";

export const GET_SPONSORED_CONTENT_CREATIVE_CREATIVEDATA_LIST_P2_CTA =
  "sponsored-content/summary/cta-contents";
export const GET_SPONSORED_CONTENT_LOCATION_REPORT_CTA =
  "sponsored-content/summary/cta-locationinsights";
export const GET_SPONSORED_CONTENT_AUDIENCE_SPECIALITIES_REPORT_CTA =
  "sponsored-content/summary/cta-audiencereport";
