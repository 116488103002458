import {
  memo,
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

//react-bootstrap
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import ReactTable from "../../../../lib/table/react-table";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import DeleteModal from "../../../../components/partials/common/delete-modal";
import postService from "../../../../api/services/post-service";
import {
  ALL_VENDOR_GET,
  DELETE_VENDOR_POST,
  VENDOR_DETAILS_GET,
} from "../../../../api/endpoints/vendor-management-endpoints";
import { findIcon } from "../../../dashboard/icons";
import ViewModal from "../../../../components/partials/common/view-modal";
import { getService } from "../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import toast from "react-hot-toast";
import FloatingButton from "../../../../components/form-components/floating-button";
import withAuthorization from "../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../skeleton/react-table-loader/react-table-loader";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";

const AllVendor = memo(() => {
  const { redirectTo } = useRedirect();
  const [modalShow, setModalShow] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewUser, setViewUser] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const viewModal = async (vendor) => {
    let response = await getService(VENDOR_DETAILS_GET + "/" + vendor.id);

    if (response.data.data) {
      let {
        user_to_department,
        email,
        employee_code,
        id,
        name,
        phone,
        user_to_principal_entity,
        status,
      } = response.data.data;
      let dataObj = {
        Id: id ?? "",
        Name: name ?? "",
        Phone: phone ?? "",
        "Principal Entity":
          user_to_principal_entity?.length > 0
            ? user_to_principal_entity[0]?.master_principal_entity
                ?.principal_entity_name
            : "",
        Department:
          user_to_department?.length > 0
            ? user_to_department[0]?.master_department?.department_name
            : "",
        Email: email ?? "",
        "Employee Code": employee_code ?? "",
        // "Reporting Manager": reporting_manager?.name ?? "",
        Status: status?.value ?? "",
      };
      setViewUser(dataObj);
      setViewModalShow(true);
    }
    // setViewUser(response.data.data);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <ActionButtonBar
          row={row}
          onDelete={() => {
            setSelectedUser(row.original.id);
            modalOpen();
          }}
          onEdit={() =>
            redirectTo("vendor-management/edit-vendor/" + row.original.id)
          }
          onView={() => viewModal(row.original)}
        />
      ),
    },
  ];
  const post_json = useRef({
    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
  });
  const modalClose = () => {
    setModalShow(false);
  };
  const modalOpen = () => {
    setModalShow(true);
  };

  const fetchVendor = async () => {
    try {
      const response = await postService(ALL_VENDOR_GET, post_json.current);
      console.log("USER_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setVendorList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchVendor();
    return () => {};
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = {
        ...post_json.current,
        ...payload,
      };
      fetchVendor();
    },
    [vendorList, loading, post_json.current]
  );
  const handleCreate = () => {
    redirectTo("vendor-management/create");
  };
  const deleteUser = async () => {
    try {
      setLoading(true);
      const response = await postService(
        `${DELETE_VENDOR_POST}/${selectedUser}`,
        {}
      );
      if (response) {
        console.log("listing response", response);
        setLoading(false);
        setModalShow(false);
        fetchVendor();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("USER_SERVICE_ERROR", error);
    }
  };

  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Vendor</h4>
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                vendorList.length > 0 && (
                  <div className="table-responsive">
                    <ReactTable
                      data={vendorList}
                      columns={columns}
                      recordsTotal={totalCount}
                      onTableStateChange={onTableStateChange}
                      initialState={post_json.current}
                      recordsPerPage={10}
                    />
                  </div>
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={modalShow}
        modalClose={modalClose}
        onSubmit={deleteUser}
      />
      <ViewModal
        title={"View User Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewUser}
      />
    </Fragment>
  );
});

AllVendor.displayName = "AllVendor";
export default withAuthorization(AllVendor);
