export const PrevIcon = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-18"
    width="18"
    height="18"
    viewBox="0 0 24 24"
  >
    <path
      d="M15.5 19L8.5 12L15.5 5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);

export const NextIcon = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-18"
    width="18"
    height="18"
    viewBox="0 0 24 24"
  >
    <path
      d="M8.5 5L15.5 12L8.5 19"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);

export const DescIcon = () => (
  <svg
    width="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-18"
    height="18"
  >
    <path
      opacity="0.4"
      d="M12.6588 3.71101V12.189C12.6588 12.5812 12.3405 12.8995 11.9483 12.8995C11.5561 12.8995 11.2378 12.5812 11.2378 12.189V3.71101C11.2378 3.3188 11.5561 3.00049 11.9483 3.00049C12.3405 3.00049 12.6588 3.3188 12.6588 3.71101Z"
      fill="currentColor"
    ></path>
    <mask
      id="mask4"
      maskUnits="userSpaceOnUse"
      x="6"
      y="11"
      width="12"
      height="10"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 11.4788H17.3964V20.4182H6.5V11.4788Z"
        fill="white"
      ></path>
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M17.3967 12.1893C17.3967 12.3219 17.3597 12.4527 17.2877 12.5682L12.5499 20.0865C12.4192 20.2931 12.1928 20.4191 11.9484 20.4191C11.7039 20.4191 11.4775 20.2931 11.3468 20.0865L6.609 12.5682C6.47163 12.3494 6.4631 12.0728 6.5891 11.8463C6.71416 11.619 6.95195 11.4788 7.21058 11.4788H16.6862C16.9448 11.4788 17.1826 11.619 17.3076 11.8463C17.3673 11.9534 17.3967 12.0718 17.3967 12.1893Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export const AscIcon = () => (
  <svg
    width="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-18"
    height="18"
  >
    <path
      opacity="0.4"
      d="M11.3412 20.289L11.3412 11.811C11.3412 11.4188 11.6595 11.1005 12.0517 11.1005C12.4439 11.1005 12.7622 11.4188 12.7622 11.811L12.7622 20.289C12.7622 20.6812 12.4439 20.9995 12.0517 20.9995C11.6595 20.9995 11.3412 20.6812 11.3412 20.289Z"
      fill="currentColor"
    ></path>
    <mask
      id="mask3"
      maskUnits="userSpaceOnUse"
      x="6"
      y="3"
      width="12"
      height="10"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5 12.5212L6.6036 12.5212L6.6036 3.58178L17.5 3.58178V12.5212Z"
        fill="white"
      ></path>
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M6.60332 11.8107C6.60332 11.6781 6.64027 11.5473 6.71227 11.4318L11.4501 3.91345C11.5808 3.70692 11.8072 3.58092 12.0516 3.58092C12.2961 3.58092 12.5225 3.70692 12.6532 3.91345L17.391 11.4318C17.5284 11.6506 17.5369 11.9272 17.4109 12.1537C17.2858 12.381 17.0481 12.5212 16.7894 12.5212L7.31384 12.5212C7.05521 12.5212 6.81742 12.381 6.69237 12.1537C6.63269 12.0466 6.60332 11.9282 6.60332 11.8107Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export const BothIcon = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-18"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M16.8397 20.1642V6.54639"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M20.9173 16.0681L16.8395 20.1648L12.7617 16.0681"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M6.91102 3.83276V17.4505"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M2.8335 7.92894L6.91127 3.83228L10.9891 7.92894"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);
