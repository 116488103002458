import { storages } from "../../common/utils/utils";
import { updateStorage } from "../../utilities/setting";

export const persistAuthState = ({ auth, channel, project }) => {
  try {
    updateStorage(storages.local, "authState", auth);
    updateStorage(storages.local, "channelState", channel);
    updateStorage(storages.local, "projectState", project);
  } catch (error) {
    // Handle error if unable to persist state
    console.error("Error persisting authentication state:", error);
  }
};
