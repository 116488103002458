import {
  S3Client,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
} from "@aws-sdk/client-s3";

import dayjs from "dayjs";
import { useState } from "react";
import toast from "react-hot-toast";
import useBackdrop from "./use-backdrop";
const useR2FileUpload = () => {
  const [progress, setProgress] = useState(0);

  const S3_BUCKET = "clirnet-unity-dev";
  const REGION = "auto"; // R2 uses the "auto" region
  const R2_ENDPOINT = process.env.REACT_APP_CLOUDFLARE_R2_STORAGE_ENDPOINT; // Replace with your Cloudflare R2 endpoint
  const accessKeyId = process.env.REACT_APP_CLOUDFLARE_R2_ACCESS_KEY_ID;
  const secretAccessKey = process.env.REACT_APP_CLOUDFLARE_R2_SECRET_ACCESS_KEY;
  const FOLDER_NAME = "DocTube/test-upload-video/";

  const R2_BASE_ENDPOINT = `${R2_ENDPOINT}${S3_BUCKET}`;
  const s3Client = new S3Client({
    region: REGION,
    endpoint: R2_ENDPOINT,
    credentials: {
      accessKeyId,
      secretAccessKey,
    },
  });
  const createMultipartUpload = async (bucketName, objectKey) => {
    const command = new CreateMultipartUploadCommand({
      Bucket: bucketName,
      Key: objectKey,
    });
    const response = await s3Client.send(command);
    return response.UploadId;
  };

  const uploadPart = async (
    bucketName,
    objectKey,
    uploadId,
    partNumber,
    body
  ) => {
    const command = new UploadPartCommand({
      Bucket: bucketName,
      Key: objectKey,
      UploadId: uploadId,
      PartNumber: partNumber,
      Body: body,
    });
    const response = await s3Client.send(command);
    return response.ETag;
  };

  const completeMultipartUpload = async (
    bucketName,
    objectKey,
    uploadId,
    partETags
  ) => {
    const command = new CompleteMultipartUploadCommand({
      Bucket: bucketName,
      Key: objectKey,
      UploadId: uploadId,
      MultipartUpload: {
        Parts: partETags,
      },
    });
    const response = await s3Client.send(command);
    return response;
  };

  const uploadFileToStorage = async (file, callback) => {
    if (!file) {
      toast.error("No file selected");
      return false;
    }
    setProgress(1);
    let extension = file?.name?.substring(file?.name?.lastIndexOf(".") + 1);
    let fileName =
      file?.name?.substr(0, file?.name?.lastIndexOf(".")) + dayjs().unix();
    const bucketName = S3_BUCKET;
    const objectKey = `${FOLDER_NAME}/${fileName}.${extension}`;

    try {
      const uploadId = await createMultipartUpload(bucketName, objectKey);
      const partSize = 5 * 1024 * 1024; // 5MB part size
      const partETags = [];
      let partNumber = 1;
      let uploadedBytes = 0;

      for (let start = 0; start < file.size; start += partSize) {
        const end = Math.min(start + partSize, file.size);
        const body = file.slice(start, end);
        const eTag = await uploadPart(
          bucketName,
          objectKey,
          uploadId,
          partNumber,
          body
        );
        partETags.push({ PartNumber: partNumber, ETag: eTag });
        partNumber++;

        uploadedBytes += end - start;

        setProgress(Math.round((uploadedBytes / file.size) * 100));
      }

      let completionResponse = await completeMultipartUpload(
        bucketName,
        objectKey,
        uploadId,
        partETags
      );

      let videoLocation = completionResponse?.Location;
      let video_url = videoLocation.replace(
        R2_BASE_ENDPOINT,
        process.env.REACT_APP_CLOUDFLARE_R2_PUBLIC_URL
      );
      callback && callback(video_url, file.name);

      setProgress(100);
    } catch (error) {
      console.error("Error during upload:", error);
      console.log("Upload failed");
      setProgress(0);
    }
  };

  return {
    progress,
    uploadFileToStorage,
  };
};

export default useR2FileUpload;
