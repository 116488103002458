import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col } from "react-bootstrap";

function NavSwiperSkeleton({ height = 270, width = "100%" }) {
  return (
    <div className="overflow-hidden mb-2 d-slider1 flex-grow-1 me-0 me-md-3">
      <Skeleton
        variant="text"
        width={"100%"}
        height={25}
        className="rounded-3"
      />
    </div>
  );
}

export default memo(NavSwiperSkeleton);
