import React, { useState, useRef, useEffect, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { findIcon } from "../../../../dashboard/icons";
import "flatpickr/dist/flatpickr.min.css";
import { formatLocalizedDate } from "../../../../../common/utils/utils";

const DateRangePicker = ({ setDateRangePayload, dateLabel }) => {
  const [modalShow, setModalShow] = useState(false);

  const onModalHide = useCallback(() => {
    setModalShow(false);
  }, [modalShow]);
  return (
    <>
      <div
        className="border rounded-3 p-2 border-soft-secondary bg-white cursor-pointer d-flex align-items-center gap-2"
        onClick={() => setModalShow(true)}
      >
        {findIcon("Calendar", "solid", 20)}
        <p className="p-0 m-0">
          {formatLocalizedDate(dateLabel?.start_date, "YYYY-MM-DD")} -{" "}
          {formatLocalizedDate(dateLabel?.end_date, "YYYY-MM-DD")}
        </p>
      </div>

      <DateRangeModal
        show={modalShow}
        onHide={onModalHide}
        setDateRangePayload={setDateRangePayload}
        dateLabel={dateLabel}
      />
    </>
  );
};

export default DateRangePicker;

const DateRangeModal = ({ show, onHide, setDateRangePayload, dateLabel }) => {
  const [modalState, setModalState] = useState(show);
  const [dates, setDates] = useState([]);

  const [selectedRange, setSelectedRange] = useState(7);
  const flatpickrRef = useRef(null);

  const [firstOnChange, setFirstOnChange] = useState(false);

  useEffect(() => {
    if (show) {
      // setDates((prev) => [getLastDate(selectedRange), getLastDate(0)]);
      setDates((prev) => [
        new Date(dateLabel?.start_date),
        new Date(dateLabel?.end_date),
      ]);
      setModalState(true);
    } else {
      setDates(() => []);
      setModalState(false);
    }
  }, [show]);

  // function formatDate(dateString) {
  //   let date = new Date(dateString);
  //   // date.setHours(16, 20, 48);
  //   return date.toString().split(" (")[0];
  // }

  const getLastDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  };

  /**
   * A function that handles the application logic based on selected dates.
   *
   */
  const handleApply = () => {
    console.group("handle apply");
    if (dates?.length > 1) {
      const start_date = dates[0];
      const end_date = dates[1];
      console.log("handle apply end", start_date, end_date);
      if (start_date && end_date) {
        if (end_date.toDateString() == new Date().toDateString()) {
          end_date.setDate(end_date.getDate() - 1);
          console.log("handle apply end1 ", end_date);
        } else {
          setDateRangePayload({
            start_date: formatLocalizedDate(
              start_date,
              "YYYY-MM-DD[T]00:00:00.SSSZ"
            ),
            end_date: formatLocalizedDate(
              end_date,
              "YYYY-MM-DD[T]23:59:59.SSSZ"
            ),
          });
        }
        onHide();
      } else {
        console.log("in else ", start_date, end_date);
      }
    }
    console.groupEnd("handle apply");
  };

  const handleDateChange = (selectedDates) => {
    if (!firstOnChange) {
      setFirstOnChange(true);
    }
    setDates(selectedDates);
  };

  const dateOptions = [
    { label: "Last 7 days", value: 7 },
    { label: "Last 28 days", value: 28 },
    { label: "Last 30 days", value: 30 },
    { label: "Last 90 days", value: 90 },
    // { label: "Last 12 months", value: 365 },
    { label: "custom", value: 0 },
  ];

  return (
    <Modal show={modalState} onHide={onHide} size="md" centered>
      <Modal.Body>
        <div className="d-flex flex-column flex-md-row align-items-start overflow-hidden">
          <ul className="dateRangeTime list-unstyled mb-2 mb-md-0 flex-shrink-0 flex-grow-1 me-3 d-flex flex-row flex-md-column overflow-x-scroll gap-1 align-items-center align-items-md-start">
            <span className="fw-semibold mb-2 fs-6 lh-base flex-shrink-0 me-3 me-md-0">
              Select Date
              <br /> Range
            </span>

            {dateOptions.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  setDates([getLastDate(item.value), getLastDate(1)]);
                  setSelectedRange(item.value);
                  setFirstOnChange(false);
                }}
                className={`d-flex flex-shrink-0 justify-content-between ps-3 px-1 py-3 rounded-3 cursor-pointer mb-2 btn-light border ${
                  (selectedRange === item.value && !firstOnChange) ||
                  (firstOnChange && item.label === "custom")
                    ? "text-white bg-calendarGreen fw-semibold"
                    : ""
                }`}
              >
                {item.label}
                {(selectedRange === item.value && !firstOnChange) ||
                (firstOnChange && item.label === "custom")
                  ? findIcon("Chevron Right", "outline", "20")
                  : ""}
              </li>
            ))}
          </ul>

          <div className="dateRange w-100">
            <div className="d-flex gap-3 rangeHeader mb-3 mt-2">
              <div className="flex-shrink-0 flex-grow-1 border border-secondary border-opacity-25 rounded-3 px-3">
                <div className="m-0 mt-n2 fs-7 bg-white position-relative w-fit-content pe-3 ps-1 opacity-75">
                  Start Date
                </div>
                <p className="mb-2">
                  {dates[0] ? formatLocalizedDate(dates[0], "YYYY-MM-DD") : ""}
                </p>
              </div>

              <div className="flex-shrink-0 flex-grow-1 border border-secondary border-opacity-25 rounded-3 px-3">
                <div className="m-0 mt-n2 fs-7 bg-white position-relative w-fit-content pe-3 ps-1 opacity-75">
                  End Date
                </div>
                <p className="mb-2">
                  {dates[1] ? formatLocalizedDate(dates[1], "YYYY-MM-DD") : ""}
                </p>
              </div>
            </div>

            <Flatpickr
              ref={flatpickrRef}
              value={[
                formatLocalizedDate(dates[0], "YYYY-MM-DD"),
                formatLocalizedDate(dates[1], "YYYY-MM-DD"),
              ]}
              onChange={handleDateChange}
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                inline: true,
                // defaultDate: [getLastDate(selectedRange), getLastDate(0)],
                maxDate: getLastDate(1),
              }}
            />

            <div className="d-flex justify-content-end py-3 gap-3">
              <Button variant="light" onClick={onHide}>
                Cancel
              </Button>
              <Button onClick={handleApply}>Apply</Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
