import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import Chart from "react-apexcharts";
import GraphTab from '../../components/common/GraphTab';
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";
import { isMobile } from "react-device-detect";

function AnalyticsContent({ data: contentData }) {
  const { avg_view_duration, click_through_rate, impression, views } =
    contentData && Object.values(contentData)?.length > 0
      ? contentData
      : {
        avg_view_duration: [],
        ctr: [],
        views: [],
        impression: [],
      };

  function transformGraphData(data, seriesKey, labelKey) {
    // console.log("data", data);
    if (data?.length > 0) {
      return data?.reduce(
        (acc, item) => ({
          series: [...acc.series, parseInt(item[seriesKey])],
          label: [...acc.label, item[labelKey]],
        }),
        { series: [], label: [] }
      );
    } else {
      return { series: [], label: [] };

    }
  }

  const viewsOptions = {
    series: [
      {
        name: "View Count",
        data:
          transformGraphData(views, "views", "level")?.series?.length > 0
            ? transformGraphData(views, "views", "level").series
            : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    labels:
      transformGraphData(views, "views", "level")?.label?.length > 0
        ? transformGraphData(views, "views", "level").label
        : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const impressionOptions = {
    series: [
      {
        name: "Impression Count",
        data:
          transformGraphData(impression, "impression", "level")?.series?.length > 0
            ? transformGraphData(impression, "impression", "level").series
            : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    markers: {
      size: [4, 7],
    },
    labels:
      transformGraphData(impression, "impression", "level")?.label?.length > 0
        ? transformGraphData(impression, "impression", "level").label
        : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const impressionsClickThroughOptions = {
    series: [
      {
        name: "Click Through Rate (%)",
        data:
          transformGraphData(click_through_rate, "click_through_rate", "level")?.series?.length > 0
            ? transformGraphData(click_through_rate, "click_through_rate", "level").series
            : [],
      },
    ],
    title: isMobile ? {
      text: "Impression Click Through Rate",
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      },
    } : {},
    colors: GRAPH_FILL_COLOR,
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    markers: {
      size: [4, 7],
    },
    labels:
      transformGraphData(click_through_rate, "click_through_rate", "level")?.label?.length > 0
        ? transformGraphData(click_through_rate, "click_through_rate", "level").label
        : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  const averageViewOptions = {
    series: [
      {
        name: "Duration",
        data:
          transformGraphData(avg_view_duration, "avg_views", "level")?.series
            ?.length > 0
            ? transformGraphData(avg_view_duration, "avg_views", "level").series
            : [],
      },
    ],
    colors: GRAPH_FILL_COLOR,
    title: isMobile ? {
      text: "Average View Duration",
      align: 'center',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      },
    } : {},
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false, // Set to false to hide the toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    markers: {
      size: [4, 7],
    },
    labels:
      transformGraphData(avg_view_duration, "avg_views", "level")?.label
        ?.length > 0
        ? transformGraphData(avg_view_duration, "avg_views", "level").label
        : [],
    // xaxis: {
    //   type: "datetime",
    // },
    yaxis: {
      opposite: true,
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  function sumArray(arr) {
    if (!arr?.length) { return 0 } else {
      return arr.reduce((sum, current) => sum + current, 0)?.toLocaleString()
    }
  }

  function avgArray(arr) {
    if (!arr?.length) { return 0 } else {
      return arr.reduce((sum, current) => sum + current, 0) / arr.length
    }
  }


  const graphTabs = [
    {
      eventKey: "second-gr", title: "Impressions", dataCount: `${impressionOptions.series[0]?.data?.length > 0 ? sumArray(impressionOptions.series[0]?.data) : 0} `, content: <> <Card.Body className="p-0">
        <div>
          <div id="chart-watch">
            {impressionOptions?.series?.length > 0 && (
              <Chart
                options={impressionOptions}
                series={impressionOptions.series}
                type="area"
                height={350}
              />
            )}
          </div>
        </div>
      </Card.Body></>
    },
    {
      eventKey: "first-gr",
      title: "Views",
      dataCount: `${viewsOptions.series[0]?.data?.length > 0 ? sumArray(viewsOptions.series[0]?.data) : 0}`,
      content: <><Card.Body className="p-0">
        <div>
          <div id="chart-gr">
            {viewsOptions?.series?.length > 0 && (
              <Chart
                options={viewsOptions}
                series={viewsOptions?.series}
                type="area"
                height={350}
              />
            )}
          </div>
        </div>
      </Card.Body></>
    },

    {
      eventKey: "third-gr", title: `${isMobile ? "CTR" : "Impression Click-through-rate"}`, dataCount: `${impressionsClickThroughOptions.series[0]?.data?.length > 0 ? avgArray(impressionsClickThroughOptions.series[0]?.data) : 0} %`, content: <>
        <Card.Body className="p-0">
          <div>
            <div id="chart-sub">
              {impressionsClickThroughOptions.series?.length > 0 && (
                <Chart
                  options={impressionsClickThroughOptions}
                  series={impressionsClickThroughOptions.series}
                  type="area"
                  height={350}
                />
              )}
            </div>
          </div>
        </Card.Body></>
    },
    {
      eventKey: "four-gr", title: `${isMobile ? "Avg Dur" : "Average View Duration"}`,
      dataCount: `${averageViewOptions.series[0]?.data?.length > 0 ? avgArray(averageViewOptions.series[0]?.data) : 0}`,
      content: <>
        <Card.Body className="p-0">
          <div>
            <div id="chart-sub">
              {averageViewOptions.series?.length > 0 && (
                <Chart
                  options={averageViewOptions}
                  series={averageViewOptions.series}
                  type="area"
                  height={350}
                />
              )}
            </div>
          </div>
        </Card.Body></>
    }]

  return (
    <Card>

      <Card.Body>
        <GraphTab tabs={graphTabs} />
      </Card.Body>
    </Card>
  );
}

export default AnalyticsContent;
