import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import CreativeList from "../components/CreativeList";
import CountCard from "../components/CountCard";
import { Card, Row, Col } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import VerticalTabs from "../components/VerticalTabs";
import HeatMapsChart from "../../../../../components/chart/HeatMapsChart";
import IndiaMap from "./../components/IndiaMap";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import DonutChart from "../../../../../components/chart/DonutChart";
import LineChart from "../../../../../components/chart/LineChart";
import CampaignCard from "../components/CampaignCard";
import { initialDate } from "./../constant/date-range-constant";
import {
  useGetCampaignAnalytics,
  useGetProjectDetails,
} from "../../../../../queries/query-hooks/workspace/workspace-hook";
import {
  useGetCreativeDataList,
  useGetSummaryCamapgingAnalytics,
  useGetSummaryCreativeList,
  useGetSummaryDaywiseDistribution,
  useGetSummaryDeviceDistribution,
  useGetSummaryImpressionGraph,
  useGetSummaryLocationMap,
  useGetSummaryCampaignGroupList,
  useGetSummaryGroupList,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import {
  formatLocalizedDate,
  formatNumberWithCommas,
  transformGraphData,
} from "../../../../../common/utils/utils";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import CountCardSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/count-card-skeleton";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import VerticalTabSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/vertical-tab-skeleton";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import CardSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/card-skeleton";
import VideoSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/video-swiper-skeleton";

import { Skeleton } from "@mui/material";
import PageNotFound from "../../../../PageNotFound";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { useParams } from "react-router-dom";
import * as dayjs from "dayjs";

function Summary() {
  const { project_id } = useParams();
  const { redirectTo } = useRedirect();
  const chartRef = useRef(null);
  const [analysisType, setAnalysisType] = useState("week");
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);
  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: initialDate.end_date,
  });

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: initialDate.end_date,
      });
    }
  }, [projectDetails]);

  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const { data: campaignGroupList, isLoading: isCampaignGroupListLoading } =
    useGetSummaryCampaignGroupList(project_id, {
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: campaignGroupWiseData, isLoading: isCampaignGroupWiseLoading } =
    useGetSummaryGroupList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  // const [activeCampaignGroup, setActiveCampaignGroup] = useState(
  //   campaignGroupList?.length > 0 ? campaignGroupList[0] : ""
  // );

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  useEffect(() => {
    if (
      campaignGroupList &&
      Array.isArray(campaignGroupList) &&
      campaignGroupList?.length > 0 &&
      !("id" in activeCampaignGroup)
    ) {
      setActiveCampaignGroup(campaignGroupList[0]);
    }
  }, [campaignGroupList]);

  const { data: camapignAnalyticsData, isLoading: isCamapignAnalyticsLoading } =
    useGetSummaryCamapgingAnalytics(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: creativeDataList, isLoading: isCreativeDataListLoading } =
    useGetSummaryCreativeList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: impressionGraphData, isLoading: isImpressionGraphDataLoading } =
    useGetSummaryImpressionGraph(
      project_id,
      {
        start_date: dateRangePayload.start_date,
        end_date: dateRangePayload.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );

  const { data: locationMapData, isLoading: isLocationMapData } =
    useGetSummaryLocationMap(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const {
    data: deviceDistributionData,
    isLoading: isDeviceDistributionDataLoading,
  } = useGetSummaryDeviceDistribution(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const {
    data: daywiseDistributionData,
    isLoading: isDaywiseDistributionDataLoading,
  } = useGetSummaryDaywiseDistribution(project_id, {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    analysis_type: analysisType,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const countCardData = [
    {
      text: "Impression Target",
      count: formatNumberWithCommas(camapignAnalyticsData?.target) || "0",
      icon: findIcon("ImpressionTarget", "dual-tone", 42),
      bgPrimary: true,
    },
    {
      text: "Impression Delivered",
      count:
        formatNumberWithCommas(camapignAnalyticsData?.impressions_count) || "0",
      icon: findIcon("ImpressionDelivered", "dual-tone", 42),
      bgPrimary: false,
    },
    {
      text: "Creatives",
      count: formatNumberWithCommas(camapignAnalyticsData?.creatives) || "0",
      icon: findIcon("ImpressionCreatives", "dual-tone", 42),
      bgPrimary: true,
    },
    {
      text: "Campaigns",
      count: projectDetails
        ? formatNumberWithCommas(projectDetails[0]?.project_group_data?.length)
        : "0",
      icon: findIcon("ImpressionCampaigns", "dual-tone", 42),
      bgPrimary: false,
    },
  ];

  const formatDaywiseReportData = (data) => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const result = data.reduce((acc, { Day, label, Reach }) => {
      if (!acc[label]) {
        acc[label] = {
          name: analysisType === "week" ? `Week ${label}` : label,
          data: daysOfWeek.map((day) => ({ x: day || "", y: 0 })),
        };
      }

      const dayIndex = daysOfWeek.indexOf(Day);

      if (acc[label].data.length >= dayIndex && dayIndex >= 0) {
        acc[label].data[dayIndex].y = Reach;
      }
      return acc;
    }, {});

    return Object.values(result)
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      )
      .map((week) => ({
        name: week.name,
        data: week.data,
      }));
  };
  const performanceDistribution = {
    series:
      daywiseDistributionData && daywiseDistributionData?.length > 0
        ? formatDaywiseReportData(daywiseDistributionData)
        : [],
    title: "Day Wise Performance Distribution",
  };

  const transformMapData = (inputArray) => {
    const result = inputArray.reduce((acc, { User_count, name }) => {
      acc[name] = User_count;
      return acc;
    }, {});
    return [result];
  };

  const stateAnalyticsData =
    locationMapData && locationMapData?.length > 0
      ? transformMapData(locationMapData)
      : [];

  const deviceDistributionChartData = {
    series:
      deviceDistributionData?.length > 0
        ? transformGraphData(deviceDistributionData, "Device_count", "new_os")
            ?.series
        : [],
    title: "Device Distribution",
    labels:
      deviceDistributionData?.length > 0
        ? transformGraphData(deviceDistributionData, "Device_count", "new_os")
            ?.label
        : [],
  };

  const impressionChartOptions = {
    title: "Impression",
    labels:
      impressionGraphData && impressionGraphData?.length > 0
        ? transformGraphData(impressionGraphData, "VIEWS", "formatted_date")
            ?.label
        : [],
    series:
      impressionGraphData && impressionGraphData?.length > 0
        ? transformGraphData(impressionGraphData, "VIEWS", "formatted_date")
            ?.series
        : [],
  };

  function getWeeksBetween(startDate, endDate) {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    // Ensure end date is after start date
    if (!end.isAfter(start)) {
      return 0; // Handle cases where end date is before or equal to start date
    }

    // Calculate the difference in days, considering weeks start on Sundays
    const diffInDays =
      end.diff(start, "day") + (start.day() === 0 ? 0 : 6 - start.day());

    // Calculate the number of weeks (rounding down for partial weeks)
    return Math.floor(diffInDays / 7);
  }

  const handleRequestNowClick = () => {
    redirectTo(`brand-ads/request-conetnt/${project_id}`);
  };

  // const handleAudienceInsightsClick = () => {
  //   // redirectTo(``);
  // };

  // const handleCreateCampaignClick = () => {
  //   // redirectTo(``);
  // };

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Summary"}
            projectType={"Brand Ads"}
            setDateRangePayload={(e) => {
              setDateRangePayload(e);
              if (getWeeksBetween(e.start_date, e.end_date) > 16) {
                setAnalysisType("month");
              } else {
                setAnalysisType("week");
              }
            }}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : ""
            }
            dateLabel={dateRangePayload}
          />
        </Col>

        <Col md={12}>
          <Card>
            <Card.Body>
              <Row className="mb-md-0 mb-lg-4">
                {!isCamapignAnalyticsLoading &&
                Object.values(camapignAnalyticsData)?.length > 0 ? (
                  <>
                    {countCardData?.map((item, index) => {
                      return (
                        <>
                          <Col
                            lg={3}
                            md={6}
                            sm={12}
                            className="mb-4 mb-md-4 mb-lg-0 "
                          >
                            <CountCard
                              key={index}
                              text={item.text}
                              count={item.count}
                              icon={item.icon}
                              onclickPath={item.onClickPath}
                              bgPrimary={item.bgPrimary}
                            />
                          </Col>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <CountCardSkeleton />
                )}
              </Row>
              {!isCampaignGroupWiseLoading &&
              campaignGroupWiseData?.length > 0 ? (
                <VerticalTabs
                  orientation="horizontal"
                  tabs={campaignGroupWiseData}
                />
              ) : (
                <VerticalTabSkeleton />
              )}
            </Card.Body>
          </Card>
        </Col>

        <Col md={12} className="mb-4">
          <LineChart
            chartRef={chartRef}
            labels={impressionChartOptions.labels}
            graphColor={GRAPH_FILL_COLOR}
            series={impressionChartOptions.series}
            seriesName="Impressions"
            sponsoredContentList={campaignGroupList}
            activeCampaignGroup={activeCampaignGroup}
            setActiveCampaignGroup={setActiveCampaignGroup}
            loading={isImpressionGraphDataLoading}
            chartTitle={"Impressions"}
            hasData={
              impressionChartOptions?.series &&
              Array.isArray(impressionChartOptions?.series) &&
              impressionChartOptions?.series?.length > 0
                ? true
                : false
            }
          />
        </Col>

        <Col md={12} className="mb-0">
          {!isCreativeDataListLoading && creativeDataList?.length > 0 ? (
            <CreativeList data={creativeDataList} />
          ) : (
            <VideoSwiperSkeleton />
          )}
        </Col>

        <Col md={12} lg={6} className="d-flex flex-column pe-2 mb-4">
          {!isLocationMapData && locationMapData?.length > 0 ? (
            <IndiaMap
              title={"Location"}
              stateData={stateAnalyticsData}
              valueSuffix={"%"}
            />
          ) : isLocationMapData ? (
            <MapAnalyticsSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col md={12} lg={6}>
          <Row>
            <Col md={12} className="mb-4">
              {!isDeviceDistributionDataLoading &&
              deviceDistributionData?.length > 0 ? (
                <DonutChart
                  series={deviceDistributionChartData.series}
                  title={deviceDistributionChartData.title}
                  labels={deviceDistributionChartData.labels}
                  graphColor={GRAPH_FILL_COLOR}
                />
              ) : isDeviceDistributionDataLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
            <Col md={12} className="mb-4">
              {!isDaywiseDistributionDataLoading &&
              daywiseDistributionData?.length > 0 ? (
                <HeatMapsChart
                  series={performanceDistribution.series}
                  title={performanceDistribution.title}
                />
              ) : isDaywiseDistributionDataLoading ? (
                <SquareChartSkeleton />
              ) : (
                <PageNotFound
                  title="No data found"
                  // message="Upload one to start analyzing data!"
                  backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                />
              )}
            </Col>
          </Row>
        </Col>

        {/*  */}
        {/* <Col className="px-2 mt-0 mt-md-3 mb-4" xs={12} lg={4} sm={6}>
          <CampaignCard
            icon={findIcon("Request Content", "dual-tone", 40)}
            title={"Request Content!"}
            subTitle={
              " Reach out to the CLIRNET content team to requisition Campaign related content."
            }
            buttonText={"Request Now!"}
            handleOnClick={handleRequestNowClick}
            // linkText={"/brand-ads/request-conetnt/1"}
          />
        </Col> */}

        {/*  */}
        {/* <Col className="px-2 mt-0 mt-md-3 mb-4" xs={12} lg={4} sm={6}>
          <CampaignCard
            icon={findIcon("Video Graph", "dual-tone", 32)}
            title={" Deep Audience Insights"}
            subTitle={
              "Derive deep insights from your campaign using rich Google Analytics data."
            }
            linkText={"Browse audience insights!"}
            // handleOnClick={handleAudienceInsightsClick}
          />
        </Col> */}

        {/*  */}
        {/* <Col className="px-2 mt-0 mt-md-3 mb-4" xs={12} lg={4} sm={6}>
          <CampaignCard
            icon={findIcon("Create Campaign", "dual-tone", 40)}
            title={"Build your Campaign"}
            subTitle={
              "Configure a Content based Campaign for different Social Media before you send it"
            }
            buttonText={"Create Campaign"}
            // handleOnClick={handleCreateCampaignClick}
          />
        </Col> */}
      </Row>
    </>
  );
}

export default Summary;
