import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";

// pages

import AddContent from "../pages/add-content";

export const AddContentRouter = [
  {
    path: "/add-content",
    element: <Default />,
    children: [
      {
        path: "dashboard",
        element: <AddContent />,
      },
     
    ],
  },
];
