export function checkProjectAccess(type, projectDetails) {
  if (
    projectDetails &&
    Array.isArray(projectDetails) &&
    projectDetails?.length > 0 &&
    "project_attributes" in projectDetails[0] &&
    projectDetails[0]?.project_attributes.length > 0
  ) {
    const permissions = projectDetails[0]?.project_attributes.find((item) => {
      if (item.attribute_name.toLowerCase() === type.toLowerCase()) {
        return true;
      }
    });

    if (permissions && permissions?.attribute_value == "true") {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
