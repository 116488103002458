import React, { memo, Fragment, useEffect, useState } from "react";

import { Button, Card, Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import CustomToggle from "../../../../components/dropdowns";
// Apexcharts
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { theme_color } from "../../../../store/setting/selectors";
import { findIcon } from "../../../dashboard/icons";

//Count-up
import CountUp from "react-countup";

//progressbar
// import Progress from "../../components/progress.js";
import Progress from "../../../../components/progress.js";

//flatpickr
import Flatpickr from "react-flatpickr";
import { getService } from "../../../../api/services/get-services";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import CampaignStatsCard from "../components/campaign-stats-card";
import ReactTable from "../../../../lib/table/react-table";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const svg1 = findIcon("Eye", "solid");

const svg2 = findIcon("Action", "dual-tone");

const svg3 = findIcon("Users", "dual-tone");

const svg4 = findIcon("Users", "dual-tone");

const ContentUsageDetails = () => {
  const [loading, setLoading] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState([]);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getCampaignData();
    }
  }, []);
  const getCampaignData = async () => {
    try {
      setLoading(true);
      const response = await getService("campaigns/detail" + `/${params.id}`);
      console.log("content studio details response ", response.data);

      if (response.isError) {
        toast.error(response?.error);
      } else {
        setCampaignDetails(response?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  const themeColor = useSelector(theme_color);
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color5 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}success`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      success: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
      primary: color5.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.success,
    variableColors.warning,
  ];
  useEffect(() => {
    return () => colors;
  });

  const chart1 = {
    options: {
      colors: colors,
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        width: 3,
        dashArray: 0,
      },
      grid: {
        show: true,
        strokeDashArray: 3,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "WhatsApp",
        data: [7, 4, 9, 4, 7, 3, 8],
      },
      {
        name: "SMS",
        data: [3, 5, 3, 7, 4, 6, 9],
      },
      {
        name: "Emial",
        data: [5, 6, 7, 2, 5, 8, 3],
      },
    ],
  };
  const chart2 = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: "50%",
          },
          track: {
            margin: 10,
            strokeWidth: "50%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      labels: ["Apples", "Oranges"],
    },
    series: [85, 75],
  };

  const recomended_segment_demo_data = [
    {
      segment_name: "Cardiology Doctors in Mumbai",
      total_reach: "380",
      Preffer_email: "105",
      Preffer_whatsapp: "112",
      Preffer_SMS: "132",
      created_by: { name: "Indranil Kundu" },
      created_on: { date: "01-06-23", time: "21:10" },
      modified_on: { date: "01-06-23", time: "21:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Delhi",
      total_reach: "470",
      Preffer_email: "155",
      Preffer_whatsapp: "119",
      Preffer_SMS: "156",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "01-06-23", time: "22:20" },
      modified_on: { date: "01-06-23", time: "22:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "ENT Doctors in Bangalore",
      total_reach: "550",
      Preffer_email: "255",
      Preffer_whatsapp: "110",
      Preffer_SMS: "106",
      created_by: { name: "Swagata Das" },
      created_on: { date: "02-06-23", time: "12:20" },
      modified_on: { date: "02-06-23", time: "12:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Nephrology Doctors in Kolkata",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "02-06-23", time: "13:00" },
      modified_on: { date: "02-06-23", time: "13:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Oncology Doctors in Chennai",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "03-06-23", time: "16:20" },
      modified_on: { date: "03-06-23", time: "16:40" },
      status: { id: 1, value: "Draft" },
    },
  ];

  const columns = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },

    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        return value.name;
      },
    },

    {
      Header: "Used on",
      accessor: "modified_on",
      disableFilters: true,
      Cell: ({ value }) => (
        <p className="m-0">
          {value.date} {value.time}
        </p>
      ),
    },
  ];

  const onFrequentlyUsedClick = () => {
    redirectTo("segment/all");
  };

  const { redirectTo } = useRedirect();

  const largest_segment_demo_data = [
    {
      segment_name: "Doctors in Mumbai",
      total_reach: "380",
      Preffer_email: "105",
      Preffer_whatsapp: "112",
      Preffer_SMS: "132",
      created_by: { name: "Indranil Kundu" },
      created_on: { date: "03-06-23", time: "17:05" },
      modified_on: { date: "03-06-23", time: "17:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Dermatology Doctors in Delhi",
      total_reach: "470",
      Preffer_email: "155",
      Preffer_whatsapp: "119",
      Preffer_SMS: "156",
      created_by: { name: "Saif Imroz" },
      created_on: { date: "03-06-23", time: "18:20" },
      modified_on: { date: "03-06-23", time: "18:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Bangalore",
      total_reach: "550",
      Preffer_email: "255",
      Preffer_whatsapp: "110",
      Preffer_SMS: "106",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "03-06-23", time: "22:20" },
      modified_on: { date: "03-06-23", time: "22:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "ENT Doctors in Chennai",
      total_reach: "350",
      Preffer_email: "105",
      Preffer_whatsapp: "170",
      Preffer_SMS: "136",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "05-06-23", time: "09:20" },
      modified_on: { date: "05-06-23", time: "09:40" },
      status: { id: 1, value: "Draft" },
    },
    {
      segment_name: "Doctors in Ranchi",
      total_reach: "550",
      Preffer_email: "135",
      Preffer_whatsapp: "241",
      Preffer_SMS: "131",
      created_by: { name: "Sumit Mondal" },
      created_on: { date: "05-06-23", time: "10:20" },
      modified_on: { date: "05-06-23", time: "10:40" },
      status: { id: 1, value: "Draft" },
    },
  ];

  const columns_largest = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Created by",
      accessor: "created_by",
      disableFilters: true,
      Cell: ({ value }) => {
        return value.name;
      },
    },
    {
      Header: "Reach",
      accessor: "total_reach",
      disableFilters: true,
    },
  ];

  const onLargestClick = () => {
    redirectTo("segment/all");
  };

  const recently_used_segment_data = [
    {
      segment_name: "Brand_1 Launch Campaign",
      sent: "380",
      viewed: "105",
      rejected: "112",
      read: "132",
      clicked: "40",
    },
    {
      segment_name: "Brand_2 Engagement Campaign",
      sent: "470",
      viewed: "155",
      rejected: "119",
      read: "156",
      clicked: "100",
    },
    {
      segment_name: "Brand_3 Recall Campaign",
      sent: "550",
      viewed: "255",
      rejected: "110",
      read: "106",
      clicked: "120",
    },
    {
      segment_name: "Brand_4 BLS Campaign",
      sent: "350",
      viewed: "105",
      rejected: "170",
      clicked: "136",
      read: "77",
    },
    {
      segment_name: "Brand_5 Engagement_Recall Campaign",
      sent: "550",
      viewed: "135",
      rejected: "241",
      clicked: "131",
      read: "47",
    },
  ];

  const columns_recently_used = [
    {
      Header: "Name",
      accessor: "segment_name",
      disableFilters: true,
      isVisible: true,
      Cell: ({ value }) => <p className="text-truncate m-0">{value}</p>,
    },
    {
      Header: "Sent",
      accessor: "sent",
      disableFilters: true,
      Cell: ({ value }) => {
        // console.log("row");
        return value;
      },
    },
    {
      Header: "Viewed",
      accessor: "viewed",
      disableFilters: true,
      Cell: ({ value }) => {
        // console.log("row");
        return value;
      },
    },
    {
      Header: "Rejected",
      accessor: "rejected",
      disableFilters: true,
      Cell: ({ value }) => {
        // console.log("row");
        return value;
      },
    },
    {
      Header: "Read",
      accessor: "read",
      disableFilters: true,
      Cell: ({ value }) => {
        // console.log("row");
        return value;
      },
    },
    {
      Header: "Clicked",
      accessor: "clicked",
      disableFilters: true,
      Cell: ({ value }) => {
        return value;
      },
    },
  ];

  const onRecentClick = () => {
    redirectTo("segment/all");
  };

  const { keyword } = useParams();

  return (
    <Fragment>
      <Row className="m-0">
        <h1 className="mb-1 mt-0 fs-3">{keyword}</h1>
        <Col
          lg={12}
          className="bg-white rounded-3 mb-4 p-3 d-flex justify-content-between align-items-center"
        >
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <h4 className="m-0">{campaignDetails?.campaign_name}</h4>
            </div>
            <ul className="d-flex list-unstyled flex-row gap-4 m-0 mt-2 align-items-center">
              <li eventKey="disabled">
                <a
                  href="javascript:void(0)"
                  className="d-inline-flex align-items-center gap-1 text-success lh-base"
                >
                  {findIcon("Circle", "solid", "14")}
                  Running
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 lh-base"
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                  Edit
                </a>
              </li>
              <li>
                <Button
                  variant="soft-danger"
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 lh-base btn-sm rounded-2"
                >
                  {findIcon("Circle", "dual-tone", "20")}
                  Stop
                </Button>
              </li>
              {/* <li>
                <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 lh-base"
                >
                  {findIcon("Pencil", "dual-tone", "20")}
                  Clone
                </a>
              </li> */}
            </ul>
          </div>
          <div className="form-group m-0 d-flex gap-4">
            {/* <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            /> */}

            {/* <div className="d-flex">
              <span className="bg-soft-warning p-2 rounded-3">
                {findIcon("File", "dual-tone", "24")}
              </span>
              <span className="d-flex flex-column ms-3">
                <b className="fw-400 text-secondary">Campaign Created by</b>{" "}
                <i>Satyananda Ganguly</i>
              </span>
            </div> */}
          </div>
        </Col>
      </Row>
      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
        <Col className="d-flex">
          <CampaignStatsCard
            color="danger"
            svg={svg4}
            percentage="86%"
            counter="440"
            content="Sent"
            tooltipContent="Total number of times the campaign has been delivered to users."
          />
        </Col>
        <Col className="d-flex">
          <CampaignStatsCard
            color="success"
            svg={svg4}
            percentage="10%"
            counter="440"
            content="Delivered"
            tooltipContent="Total number of times the campaign has been delivered to users."
          />
        </Col>
        <Col className="d-flex">
          <CampaignStatsCard
            color="warning"
            svg={svg1}
            percentage="9%"
            counter="1206"
            content="Viewed"
            tooltipContent="Total number of times the campaign has been viewed by users."
          />
        </Col>
        <Col className="d-flex">
          <CampaignStatsCard
            color="primary"
            svg={svg2}
            counter="1,187"
            percentage="27%"
            content="Clicks"
            tooltipContent="Total number of times the campaign has been clicked by users."
          />
        </Col>
        {/* <Col className="d-flex">
          <CampaignStatsCard
            color="info"
            svg={svg3}
            counter="238"
            percentage="43%"
            tooltipContent="Total number of times the campaign has been clicked by users."
            content="CTR"
          />
        </Col> */}
      </Row>
      <Row>
        <Col lg={6} className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
              <Card.Title>
                <h4 className="mb-0">Performance Trend</h4>
                <p className="mb-0 fs-7 text-secondary mt-2">Last 30 Days</p>
              </Card.Title>

              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="text-secondary dropdown-toggle"
                >
                  30 Days
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                  <Dropdown.Item href="#">Last 3 Months</Dropdown.Item>
                  <Dropdown.Item href="#">Last 6 Years</Dropdown.Item>
                  <Dropdown.Item href="#">This Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart1.options}
                series={chart1.series}
                type="line"
                height="300"
              />
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <div className="d-flex align-items-center text-success">
                  {findIcon("Whatsapp", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">WhatsApp</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-warning">
                  {findIcon("Email", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">SMS</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-info">
                  {findIcon("Message-2", "dual-tone", "20")}
                  <div className="ms-2">
                    <span className="fs-7">E-Mail</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="d-flex">
          <Card className="flex-grow-1">
            <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
              <Card.Title>
                <h4 className="mb-0">Conversion performance</h4>
                <p className="mb-0 fs-7 text-secondary mt-2">Last 30 Days</p>
              </Card.Title>

              {/* <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  variant="text-secondary dropdown-toggle">
                  This Year
                </Dropdown.Toggle>
                <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                  <Dropdown.Item href="#">Another action</Dropdown.Item>
                  <Dropdown.Item href="#">Something else here</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart2.options}
                series={chart2.series}
                type="radialBar"
                height="300"
              />
              <div className="d-flex align-items-center justify-content-center mt-2 mt-sm-0">
                <div className="d-flex align-items-center text-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                  <div className="ms-2">
                    <span className="text-secondary">Viewed</span>
                  </div>
                </div>
                <div className="d-flex align-items-center ms-3 text-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <g>
                      <circle
                        cx="12"
                        cy="12"
                        r="8"
                        fill="currentColor"
                      ></circle>
                    </g>
                  </svg>
                  <div className="ms-2">
                    <span className="text-secondary">Clicked</span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12" md="12">
          <Card className="card-block">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav-tabs justify-content-around d-flex"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#frequently-used"
                    type="button"
                    role="tab"
                    aria-controls="frequently-used"
                    aria-selected="true"
                  >
                    Frequently Used Segments
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#largest-segment"
                    type="button"
                    role="tab"
                    aria-controls="largest-segment"
                    aria-selected="false"
                  >
                    5 Largest Segments
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="three"
                    data-bs-toggle="tab"
                    data-bs-target="#recently-created"
                    type="button"
                    role="tab"
                    aria-controls="recently-created"
                    aria-selected="false"
                  >
                    Recently Created Campaigns
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="frequently-used"
                  role="tabpanel"
                  aria-labelledby="frequently-used"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={recomended_segment_demo_data}
                      columns={columns}
                      recordsTotal={10}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="largest-segment"
                  role="tabpanel"
                  aria-labelledby="largest-segment"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={largest_segment_demo_data}
                      columns={columns_largest}
                      recordsTotal={10}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="three"
                  id="recently-created"
                  role="tabpanel"
                  aria-labelledby="recently-created"
                >
                  <div className="table-responsive p-3">
                    <ReactTable
                      data={recently_used_segment_data}
                      columns={columns_recently_used}
                      recordsTotal={10}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
          {/* <CommonTableSkeleton /> */}
        </Col>
      </Row>
    </Fragment>
  );
};

export default ContentUsageDetails;
