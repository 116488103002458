import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function CountCardSkeleton({ width = "100%" }) {
  return (
    <>
      {[...Array(4)].map((_, i) => {
        return (
            <Col md={3} key={i} >
              <Skeleton
                variant="square"
                width={width}
                height={90}
                className="rounded-3 mb-2"
              />
            </Col>
        );
      })}
    </>
  );
}

export default memo(CountCardSkeleton);
