import React from "react";

function CountCard({ count, text, icon, bgPrimary = true }) {
  return (
    <div
      className={`d-flex align-items-start gap-3 ${
        bgPrimary ? "bg-primary" : "border border-primary"
      } rounded-3 p-4`}
    >
      <div
        className={`rounded d-flex align-items-center ${
          bgPrimary ? "text-white" : "text-primary"
        }`}
      >
        {icon}
      </div>
      <div className="d-flex flex-column">
        <h3
          className={`fs-4 fw-semibold m-0 mb-1 ${
            bgPrimary ? "text-white" : "text-black"
          }`}
        >
          {count}
        </h3>

        <h4
          className={`fs-6 fw-light m-0 ${
            bgPrimary ? "text-white-50" : "text-black-50"
          }`}
        >
          {text}
        </h4>
      </div>
    </div>
  );
}

export default CountCard;
