import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import ReactEmailEditor from "../../../../../lib/email-editor/react-email-editor";
import sample from "../../../../../lib/email-editor/sample.json";
import { useParams } from "react-router-dom";
import mobile from "../../../../../assets/images/mobileWhatsapp.png";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { getService } from "../../../../../api/services/get-services";
import { WHATSAPP_TEMPLATE_DETAILS_GET } from "../../../../../api/endpoints/template-management-endpoints";
import { findIcon } from "../../../../dashboard/icons";
import toast from "react-hot-toast";

const WhatsappTemplateDetails = () => {
  const { redirectTo } = useRedirect();

  const [loading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [smsText, setSmsText] = useState("");
  const [templateDetails, setTemplateDetails] = useState({});
  const params = useParams();
  useEffect(() => {
    const id = params.id;
    setTemplateId(id);
    // console.log(id);
    fetchWhatsAppTemplateDetails(id);
  }, []);

  const fetchWhatsAppTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getService(
        WHATSAPP_TEMPLATE_DETAILS_GET + `/${id}`
      );
      // console.log("WHATSAPP_TEMPLATE_DETAILS_GET", response.data);
      if (response.data.success == true) {
        const content = response.data.data.whatsapp_content.replace(
          /^<p>(.*)<\/p>$/,
          "$1"
        );
        if (response.isError) {
          toast.error(response?.error);
        }
        // console.log("content", content);
        setTemplateDetails(response.data.data);
        setSmsText(content);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };
  const handleEditTemplate = () => {
    redirectTo("template-management/whatsapp-template/create/" + templateId);
  };
  const handleBack = () => {
    redirectTo("template-management/whatsapp-template");
  };
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row className="m-0">
                <Col
                  lg="9"
                  className="border border-1 rounded-3 p-0 d-flex flex-column"
                >
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <h6 className="mb-0">Id</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_id}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Name</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Created By</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.created_by?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Approved By</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.approved_by?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Approve</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.approve?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Category</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_category_id?.category_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Sender Name</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_sender}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Template Type</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_template_type?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Whatsapp Action</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_call_to_action?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Header Type</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.whatsapp_header_type?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Status</h6>
                        </td>
                        <td className="text-left text-success">
                          {templateDetails.status?.value}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between px-2 py-2">
                    <Button
                      variant="secondary"
                      onClick={handleBack}
                      className=""
                    >
                      {findIcon("Arrow Left", "outline", "32")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleEditTemplate}
                      className=""
                    >
                      Edit Template
                    </Button>
                  </div>
                </Col>
                <Col lg={3} xs={12} className="position-sticky top-0">
                  <div className="bg-soft-info p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                    <div className="d-flex position-relative mobile-preview">
                      <img
                        src={mobile}
                        alt="mobile"
                        className="mobile h-auto w-100 position-relative"
                      />
                      <div className="h-100 w-100 position-absolute py-5 px-3">
                        <div
                          className="h-100 ps-3"
                          style={{ overflowY: "auto" }}
                        >
                          <span className="d-block position-relative px-3 py-2 rounded-3 mt-5 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                            {smsText}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default WhatsappTemplateDetails;
