import React, { memo }  from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function DashboardMyVideoSkeleton() {
  return (
    <Card>
      <Card.Body>
        <Col md={12} className="mb-3 d-flex justify-content-between">
          <h5 className="mb-1">
            <Skeleton variant="text" />
          </h5>
          <div className="ms-auto">
            <Skeleton variant="text" />
          </div>
        </Col>

        <div className="overflow-hidden">
          <div className="d-flex gap-4 justify-content-between align-items-center">
            {[1, 2, 3].map((data, key) => (
              <div key={key} className="w-100">
                <div className="d-flex gap-3 border rounded-3 p-3">
                  <div className="w-rem-8 h-auto rounded-3 ratio-9x16 flex-shrink-0 overflow-hidden">
                    <Skeleton variant="square" width={230} height="100%" />
                  </div>
                  <div className="d-flex flex-column justify-content-between flex-grow-1">
                    <Skeleton variant="text" width="100%" height={40} />
                    <div className="d-flex justify-content-between align-items-center mt-auto w-100 gap-3 ">
                      <Skeleton variant="text" width={60} height={20} />
                      <Skeleton variant="text" width={40} height={30} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(DashboardMyVideoSkeleton);
