export const fallbackImages = {
  doctubeTemplates: {
    template_1:
      "https://storage.googleapis.com/gl-clirnet-static-assets-prod/engageUnity/doctube-template-1.png",
    template_2:
      "https://storage.googleapis.com/gl-clirnet-static-assets-prod/engageUnity/doctube-template-2.png",
    template_3:
      "https://storage.googleapis.com/gl-clirnet-static-assets-prod/engageUnity/doctube-template-3.png",
  },
  noVideo:
    "https://storage.googleapis.com/gl-clirnet-static-assets-prod/engageUnity/no-video-found.jpg",
  noImage:
    "https://storage.googleapis.com/gl-clirnet-static-assets-prod/engageUnity/no-image.svg",
  noHcpData:
    "https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg",
  noActionData:
    "https://img.freepik.com/free-vector/hand-drawn-no-data-concept_52683-127829.jpg",
};
