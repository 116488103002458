import React, { Fragment, memo, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Image, strong } from "react-bootstrap";
import img4 from "../../../../assets/modules/blog/images/blog-avatar/07.png";
import img5 from "../../../../assets/modules/blog/images/blog-avatar/08.png";
import ContentDetails from "../../../../components/partials/common/content-details";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { getService } from "../../../../api/services/get-services";
import { REQUISITION_DETAILS } from "../../../../api/endpoints/requisition-endpoints";
import { useParams } from "react-router-dom";
import withAuthorization from "../../../../components/hoc/with-authorization";
import RequisitionDetailsLoader from "../../../../skeleton/requisition/requisition-details-loader";
const RequisiitonDetails = () => {
  const { redirectTo } = useRedirect();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [
    {
      requisition_id = "",
      requisition_title = "",
      requisition_description = "",
      requisition_duedate = "",
      status = {},
      created_by,
      priority_id,
      requisition_brand_id,
      requisition_target_source_id,
      requisition_file_type_id,
      requisition_content_category_id,
      requisition_workflow_id,
      requisition_associates,
      requisition_submission,
      reference_files,
      actions,
    },
    setRequisitionDetails,
  ] = useState({});
  const { id } = useParams();
  const fetchRequisitionDetails = async () => {
    const url = `${REQUISITION_DETAILS}${id}`;
    const response = await getService(url);
    if (response) {
      console.log("RESPONSE", response.data);
      setLoading(false);
      if (response?.data?.data) {
        //set requisition details with requisition_submission as parsed json
        setRequisitionDetails({
          ...response.data.data,
          requisition_submission: response.data.data.requisition_submission,
        });
      }
    }
  };
  useEffect(() => {
    fetchRequisitionDetails();
  }, []);
  const onEditClick = () => {
    redirectTo(`requisition/edit/${id}`);
  };
  return (
    <>
      {!loading ? (
        <Row>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row className="px-3">
                  <div className="col-12 col-md-8 d-flex flex-column">
                    <div className="mb-3">
                      {/* <p className="m-0 fs-7">Title</p> */}
                      <h5 className="text-dark m-0">{requisition_title}</h5>
                    </div>
                    <div className="mb-3">
                      <p className="m-0 fs-7">Description</p>
                      <p className="text-dark">{requisition_description}</p>
                    </div>
                    <div className="d-flex flex-row flex-wrap">
                      <span className="rounded-3 bg-soft-primary px-3 py-2 me-3 mb-3 text-black">
                        Exp Delivery Date :{" "}
                        <strong>{requisition_duedate}</strong>
                      </span>
                      <span className="rounded-3 bg-soft-warning px-3 py-2 me-3 mb-3 text-black">
                        Priority :{" "}
                        <strong className="bg-soft-warning px-2 py-1">
                          {priority_id?.priority_name}
                        </strong>
                      </span>
                      <span className="rounded-3 bg-soft-success px-3 py-2 me-3 mb-3 text-black">
                        Status :{" "}
                        <strong className="bg-soft-success px-2 py-1">
                          {status?.value}
                        </strong>
                      </span>
                    </div>
                    {requisition_submission?.map((submission, index) => {
                      return (
                        <div className="d-flex flex-row flex-wrap">
                          <span className="rounded-3 bg-soft-secondary px-3 py-2 me-3 mb-3 text-black">
                            File type:{" "}
                            <strong className="bg-soft-secondary px-2 py-1">
                              {submission?.file_type_id}
                            </strong>
                          </span>
                          <span className="rounded-3 bg-soft-secondary px-3 py-2 me-3 mb-3 text-black">
                            No of Submissions
                            <strong className="bg-soft-secondary px-2 py-1">
                              {submission?.no_of_submissions}
                            </strong>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-12 col-md-4 bg-soft-white text-primary border border-1 border-soft-primary rounded-3 pt-3">
                    <div className="col-12">
                      <p className="m-0 fs-7">Category</p>
                      <p className="text-dark mb-1">
                        New Presentation Design Creation
                      </p>
                    </div>
                    <div className="col-12">
                      <p className="m-0 fs-7">Create/Repurpose</p>
                      <p className="text-dark mb-1">Create</p>
                    </div>
                    <div className="col-12">
                      <p className="m-0 fs-7">Vendor</p>
                      <p className="text-dark mb-1">
                        Nice Marketing and Consultation
                      </p>
                    </div>
                    <div className="col-12">
                      <p className="m-0 fs-7">Created Date</p>
                      <p className="text-dark mb-1">13th March 2023</p>
                    </div>
                    <div className="col-12">
                      <p className="m-0 fs-7">Created By</p>
                      <p className="text-dark mb-1">{created_by?.name}</p>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col
                    lg="12"
                    className="d-flex justify-content-end gap-2 mt-3"
                  >
                    {!edit && (
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0 rounded-3"
                        type="submit"
                        // onClick={() => redirectTo("requisition/listing")}
                        onClick={onEditClick}
                      >
                        Edit Requisition
                      </Button>
                    )}
                    {edit && (
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0 rounded-3"
                        type="submit"
                        onClick={() => redirectTo("requisition/draft")}
                      >
                        Save as draft
                      </Button>
                    )}
                    {edit && (
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0 rounded-3"
                        type="submit"
                        onClick={() => redirectTo("requisition/listing")}
                        // onClick={()=>setEdit(true)}
                      >
                        Save
                      </Button>
                    )}
                    {edit && (
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                        type="submit"
                        onClick={() => redirectTo("requisition/listing")}
                        // onClick={()=>setEdit(true)}
                      >
                        Cancel
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <div className="iq-timeline m-0 d-flex align-items-center justify-content-between position-relative">
                  <ul className="list-inline p-0 m-0 w-100">
                    <li>
                      <div className="time">
                        <span>1st Jan 2023</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots new-timeline-dots"></div>
                        <h6 className="mb-1">Requisition Crated</h6>
                        <div className="d-inline-block w-100">
                          <p>Created by David Janathan Aaron</p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="timeline-dots new-timeline-dots"></div>
                        <h6 className="mb-1">
                          Requisition Approved by Marketing Manager
                        </h6>
                        <div className="d-inline-block w-100">
                          <p>Sarthaki Banerjee</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="time bg-success">
                        <span>2nd Jan 2023</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots new-timeline-dots border-success"></div>
                        <h6 className="mb-1">Approved By Complience</h6>
                        <div className="d-inline-block w-100">
                          <p>Rahim kumar Seikh</p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="timeline-dots new-timeline-dots border-success"></div>
                        <h6 className="mb-1">Approved By Medical</h6>
                        <div className="d-inline-block w-100">
                          <p>Anandiya Thakur</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="time">
                        <span>3rd Jan 2023</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-primary"></div>
                        <h6 className="mb-1">Apporved By Marketing</h6>
                        <div className="d-inline-block w-100">
                          <p>Pranabesh kumar Nandi</p>
                          <p>
                            "Bonbon macaroon jelly beans gummi bears jelly
                            lollipop apple There are many variations of passages
                            of Lorem Ipsum available"
                          </p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-primary"></div>
                        <h6 className="mb-1">Assign to Vendor</h6>
                        <div className="d-inline-block w-100">
                          <p>Nice Marketing and Sales</p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-primary"></div>
                        <h6 className="mb-1">Accepted by Vendor</h6>
                        <div className="d-inline-block w-100">
                          <p>Nice Marketing and Sales</p>
                          <p>
                            "Bonbon macaroon jelly beans gummi bears jelly
                            lollipop apple There are many variations of passages
                            of Lorem Ipsum available"
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="time bg-success">
                        <span>6th Jan 2023</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-success"></div>
                        <h6 className="mb-1">Nice Marketing and Sales</h6>
                        <div className="d-inline-block w-100">
                          <p>Added video Files for Approval</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="time">
                        <span>8th Jan 2020</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-primary"></div>
                        <h6 className="mb-1">Commented By Complience</h6>
                        <div className="d-inline-block w-100">
                          <p>
                            "Bonbon macaroon jelly beans gummi bears jelly
                            lollipop apple There are many variations of passages
                            of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected
                            humour, or randomised words which don't look even
                            slightly believable."
                          </p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-primary"></div>
                        <h6 className="mb-1">Commented By Marketing</h6>
                        <div className="d-inline-block w-100">
                          <p>
                            "Bonbon macaroon jelly beans gummi bears jelly
                            lollipop apple There are many variations of passages
                            of Lorem Ipsum available, but the majority have
                            suffered alteration in some form, by injected
                            humour, or randomised words which don't look even
                            slightly believable."
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="time bg-success">
                        <span>9th Jan 2020</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-primary"></div>
                        <h6 className="mb-1">Commented By Medical</h6>
                        <div className="d-inline-block w-100">
                          <p>
                            "Bonbon macaroon jelly beans gummi bears jelly
                            lollipop apple There are many variations of passages
                            of Lorem Ipsum available."
                          </p>
                        </div>
                      </div>
                      <div className="content">
                        <div className="timeline-dots new-timeline-dots"></div>
                        <h6 className="mb-1">
                          Comments Approved by Marketing Manager
                        </h6>
                        <div className="d-inline-block w-100">
                          <p>Sarthaki Banerjee</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="time">
                        <span>6th Jan 2023</span>
                      </div>
                      <div className="content">
                        <div className="timeline-dots border-success"></div>
                        <h6 className="mb-1">Nice Marketing and Sales</h6>
                        <div className="d-inline-block w-100">
                          <p>Resubmit video Files for Approval</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <RequisitionDetailsLoader />
      )}
    </>
  );
};

export default withAuthorization(memo(RequisiitonDetails));
