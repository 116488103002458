import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Plotly from "plotly.js-dist";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../components/dropdowns";
import { useReOnboardingByRegistration } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";

import HcpReonboardingSkeleton from "../../../../../skeleton/hcp/hcp-re-onboarding-skeleton";
import { dateRangeOptions } from "../../constant/hcp-constatnt";
import CustomDropdown from "./CustomDropdown";

const plotlyConfig = {
  displayModeBar: false,
};

var GRAPH_LAYOUT = {
  displayModeBar: false,
};

const initialDateFilter = dateRangeOptions.find((option) => option.initial);

export default function ReOnboardingByRegistrationGraph() {
  const [payload, setPayload] = useState({
    year: "",
    month: initialDateFilter.value,
    isRefresh: "",
  });
  console.log("payload re-onboarding", payload);

  const [dropdownType, setDropdownType] = useState(initialDateFilter.label);

  const handleSelect = (eventKey, event) => {
    setDropdownType(event.target.innerText);
  };

  /**
   * Re-Onboarding By Registration query
   */
  const {
    data: reOnboardingByRegistration,
    isLoading: isReOnboardingGraphLoading,
  } = useReOnboardingByRegistration(payload, dropdownType);

  useEffect(() => {
    console.log("ReOnboarding by registration", reOnboardingByRegistration);
    if (reOnboardingByRegistration && reOnboardingByRegistration?.data) {
      Plotly.newPlot(
        "re-onbording-by-registration-graph",
        [reOnboardingByRegistration?.data],
        GRAPH_LAYOUT,
        plotlyConfig
      );
    }
  }, [reOnboardingByRegistration]);

  // if loading return skeleton
  if (isReOnboardingGraphLoading) {
    return <HcpReonboardingSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <h4 className="mb-0"> Re-Onboarding By Registration</h4>
          <p className="mb-0 fs-7 text-secondary mt-2">{dropdownType}</p>
        </Card.Title>

        <CustomDropdown
          handleSelect={handleSelect}
          dateFilterOptions={dateRangeOptions}
          dropdownType={dropdownType}
          setPayload={setPayload}
        />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <div id="re-onbording-by-registration-graph"></div>
      </Card.Body>
    </Card>
  );
}
