// AbTestFinalApprovalDetails
import React, { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  FloatingLabel,
  Form,
  FormCheck,
  Image,
  ListGroup,
  ListGroupItem,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from "react-bootstrap";
import CountUp from "react-countup";
import Card from "../../../../components/bootstrap/card";
import avatars11 from "../../../../assets/images/avatars/01.png";
import toast from "react-hot-toast";
import ReactTable from "../../../../lib/table/react-table";
import { findIcon } from "../../../dashboard/icons";
import Progress from "../../../../components/progress";

import mobile from "../../../../assets/images/mobile-bg.png";
import mobileWhatsapp from "../../../../assets/images/mobileWhatsapp.png";
import Flatpickr from "react-flatpickr";

//apexcharts
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";

const AbTestFinalApprovalDetails = () => {
  const [modalShow, setModalShow] = useState(false);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [datashow, setDataShow] = useState(false);
  const [comment, setComment] = useState("");
  const [msgDistributionType, setMsgDistributionType] = useState("");

  const [results, setResults] = useState([]);
  console.log(comment, results);

  const getMsgDistributionType = (e) => setMsgDistributionType(e.target.value);
  const modalClose = () => {
    setViewModalShow(false);
  };
  // const modalOpen = () => {
  //   setModalShow(true);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setViewModalShow(false);
    if (comment !== "") {
      setDataShow(true);
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const data = {
        id: results?.length + 1,
        comment: comment,
        createdAt: date + " " + time,
      };

      setResults((prev) => [...prev, data]);
    } else {
      setDataShow(false);
      toast.error("No comments to show");
    }
  };

  const chart3 = {
    options: {
      chart: {
        height: 385,
        type: "radialBar",
      },
      // colors: colors,
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: true,
              label: "TOTAL",
            },
          },
        },
      },
      labels: ["Indranil Kundu"],
    },
    series: [61],
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Comments",
      accessor: "comment",
    },
    {
      Header: "Added On",
      accessor: "createdAt",
    },
  ];

  console.log("msgDistributionType", msgDistributionType);
  const { id } = useParams();
  console.log("SHOW ID", id);
  return (
    <>
      <Row className="m-0">
        <Col lg="12" className="bg-white rounded-3 pb-3">
          <div className="col-lg-12 p-3 my-2 d-flex flex-column justify-content-start align-items-start">
            <div className="d-block fw-normal text-secondary fs-4">
              Approval- Final Campaign Roll out Campaign For ENT Doctors SMS
            </div>
            <div className="fs-7 w-100 d-flex flex-row justify-content-between align-items-center gap-3 py-1">
              <div className="d-flex flex-row gap-3">
                <span>
                  Created By: <strong>Swagata Goswami</strong>
                </span>
                <span>
                  Date: <strong>24 Mar 2023</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="w-100 p-3 rounded-3 border border-primary border-0 d-flex flex-column align-items-center justify-content-start shadow-lg">
            <Row className="w-100">
              <div className="col-3 p-0">
                <div className="bg-soft-primary text-muted h-100 p-3 rounded-4 d-flex flex-column">
                  <Tab.Container defaultActiveKey="first">
                    <Nav
                      variant="tabs"
                      data-toggle="slider-tab"
                      role="tablist"
                      className="w-100 bg-transparent d-flex flex-row gap-2 mb-3"
                    >
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="first"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-home1"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variation A
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="second"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-profile1"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variation B
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item role="presentation" className="flex-grow-1">
                        <Nav.Link
                          eventKey="third"
                          id="contact-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#pills-contact1"
                          type="button"
                          role="tab"
                          aria-controls="contact"
                          aria-selected="false"
                          className="d-flex align-items-center justify-content-center border border-primary border-1 border-dashed px-2 fs-7"
                        >
                          Variation C
                        </Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                    <Tab.Content className="flex-fill">
                      <Tab.Pane
                        eventKey="first"
                        variant=" fade show active"
                        id="pills-home1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab1"
                      >
                        <div className="position-relative flex-fill">
                          <img
                            src={mobile}
                            alt="mobile"
                            className="h-auto w-100 d-block position-relative z-0"
                          />
                          <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                            <div className="h-100 rounded-5 p-4 mobile-preview position-relative">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                                Dear NAME, We are excited to announce the launch
                                of our new digital glucose monitoring tool. As a
                                valued client, we wanted to ensure you are aware
                                of this innovative product that can greatly
                                improve your health management. Don't miss out
                                on this opportunity to take control of your
                                glucose levels.LINK
                              </span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="second"
                        className="fade"
                        id="pills-profile1"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab1"
                      >
                        <div className="position-relative flex-fill">
                          <img
                            src={mobileWhatsapp}
                            alt="mobile whatsapp"
                            className="h-auto w-100 d-block position-relative z-0"
                          />
                          <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                            <div className="h-100 rounded-5 p-4 whatsapp-preview position-relative">
                              <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base">
                                Dear NAME, We are excited to announce the launch
                                of our new digital glucose monitoring tool. As a
                                valued client, we wanted to ensure you are aware
                                of this innovative product that can greatly
                                improve your health management. Don't miss out
                                on this opportunity to take control of your
                                glucose levels.LINK
                              </span>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane
                        eventKey="third"
                        className="fade h-100"
                        id="pills-contact1"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab1"
                      >
                        <div className="position-relative h-100 overflow-scroll bg-white border border-1 border-dotted rounded-3 p-3 border-secondary text-center">
                          Preview Newsletter template here
                        </div>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
              <div
                className={`col-5 d-flex flex-${
                  id == 1 ? "column" : "row"
                } gap-2`}
              >
                <div className="flex-fill rounded-4 flex-fill border border-primary border-2 border-dashed mb-4">
                  {/* <div className="d-flex flex-column justify-content-between align-items-center px-2">
                    <div className="d-flex flex-column justify-content-center px-2 w-100">
                      <Chart
                        options={chart3.options}
                        series={chart3.series}
                        type="radialBar"
                        height="200"
                      />
                    </div>
                  </div> */}
                  <Form.Check className="mx-4 my-2 form-check form-check d-flex align-items-center justify-content-center gap-3">
                    <FormCheck.Input
                      type="radio"
                      name="radios"
                      className="mt-0"
                      id="exampleRadio1"
                    />
                    <FormCheck.Label
                      className="fw-bold"
                      htmlFor="exampleRadio1"
                    >
                      Variation A
                    </FormCheck.Label>
                  </Form.Check>
                  <div className={`row row-cols-${id == 1 ? 2 : 1} m-0 my-3`}>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-info">
                              {findIcon("View Grid", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Segment Size</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={3} end={850} duration={3} />
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="info"
                              color="info"
                              className="shadow-none w-100"
                              value={23}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-warning">
                              {findIcon("Chart pie", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Pending</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={3} end={23} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="warning"
                              color="warning"
                              className="shadow-none w-100"
                              value={23}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-success">
                              {findIcon("Location Arrow", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Delivered</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={29} end={49} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="success"
                              color="success"
                              className="shadow-none w-100"
                              value={50}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-dark">
                              {findIcon("Lock Closed", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Rejected</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={29} end={28} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="dark"
                              color="dark"
                              className="shadow-none w-100"
                              value={55}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-danger">
                              {findIcon("Eye Fish", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Read</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={9} end={47} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="danger"
                              color="danger"
                              className="shadow-none w-100"
                              value={68}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-primary">
                              {findIcon("Scanner", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Click</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={5} end={23} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="primary"
                              color="primary"
                              className="shadow-none w-100"
                              value={35}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </div>
                </div>
                <div className="flex-fill rounded-4 flex-fill border border-primary border-2 border-dashed mb-4">
                  {/* <div className="d-flex flex-column justify-content-between align-items-center px-2">
                    <div className="d-flex flex-column justify-content-center px-2 w-100">
                      <Chart
                        options={chart3.options}
                        series={chart3.series}
                        type="radialBar"
                        height="200"
                      />
                    </div>
                  </div> */}
                  <Form.Check className="mx-4 my-2 form-check form-check d-flex align-items-center justify-content-center gap-3">
                    <FormCheck.Input
                      type="radio"
                      name="radios"
                      className="mt-0"
                      id="exampleRadio2"
                    />
                    <FormCheck.Label
                      className="fw-bold"
                      htmlFor="exampleRadio2"
                    >
                      Variation B
                    </FormCheck.Label>
                  </Form.Check>

                  <div className={`row row-cols-${id == 1 ? 2 : 1} m-0 my-3`}>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-info">
                              {findIcon("View Grid", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Segment Size</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={3} end={855} duration={3} />
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="info"
                              color="info"
                              className="shadow-none w-100"
                              value={23}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-warning">
                              {findIcon("Chart pie", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Pending</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={3} end={32} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="warning"
                              color="warning"
                              className="shadow-none w-100"
                              value={23}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-success">
                              {findIcon("Location Arrow", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Delivered</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={29} end={38} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="success"
                              color="success"
                              className="shadow-none w-100"
                              value={50}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-dark">
                              {findIcon("Lock Closed", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Rejected</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={29} end={30} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="dark"
                              color="dark"
                              className="shadow-none w-100"
                              value={55}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-danger">
                              {findIcon("Eye Fish", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Read</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={9} end={55} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="danger"
                              color="danger"
                              className="shadow-none w-100"
                              value={68}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col>
                      <Card className="mb-4">
                        <Card.Body className="p-2">
                          <div className="d-flex flex-row align-items-center justify-content-start gap-3">
                            <div className="d-inline-block avatar-40 rounded-3 flex-shrink-0 text-primary">
                              {findIcon("Scanner", "dual-tone", "30")}
                            </div>
                            <div className="text-start">
                              <h5 className="m-0 mb-1 fs-6">Click</h5>
                              <h6 className="m-0 fw-normal text-secondary">
                                <CountUp start={10} end={25} duration={3} /> %
                              </h6>
                            </div>
                          </div>
                          <div className="mt-2 w-100">
                            <Progress
                              softcolors="primary"
                              color="primary"
                              className="shadow-none w-100"
                              value={35}
                              minvalue={0}
                              maxvalue={100}
                              style={{ height: "6px" }}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </div>
                </div>
              </div>
              <div className="col-4 p-0 d-flex flex-column">
                <div className="text-muted h-100 p-3 rounded-4 d-flex flex-column border border-1">
                  <div className="bg-soft-warning p-3 mb-3 d-flex flex-column rounded-4 lh-base">
                    <p className="m-0 mb-2">Configure Message Distribution</p>
                    <div className="m-0 fw-normal">
                      {/* <ListGroup className="bg-transparent">
                        <ListGroupItem
                          as="label"
                          className="bg-transparent border-0 px-0"
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                          />
                          SMS Template
                        </ListGroupItem>
                        <ListGroupItem
                          as="label"
                          className="bg-transparent border-0 px-0"
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                          />
                          WhatsApp Template
                        </ListGroupItem>
                        <ListGroupItem
                          as="label"
                          className="bg-transparent border-0 px-0"
                        >
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            defaultValue=""
                          />
                          Email Template
                        </ListGroupItem>
                      </ListGroup> */}
                      {/* <div className="d-flex justify-content-start align-items-center gap-2"></div> */}
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio3"
                          value={"Send Now"}
                          checked={msgDistributionType === "Send Now"}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio3">
                          Send Now
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio4"
                          value={"Schedule for Later"}
                          checked={msgDistributionType === "Schedule for Later"}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio4">
                          Schedule for Later
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio4"
                          value={"Preferred Day & Time"}
                          checked={
                            msgDistributionType === "Preferred Day & Time"
                          }
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio4">
                          Preferred Day & Time
                        </FormCheck.Label>
                      </Form.Check>
                      <Form.Check className="">
                        <FormCheck.Input
                          type="radio"
                          name="radios_msg_distribution"
                          className=""
                          id="exampleRadio4"
                          value={"Preferred Time"}
                          checked={msgDistributionType === "Preferred Time"}
                          onChange={getMsgDistributionType}
                        />
                        <FormCheck.Label className="" htmlFor="exampleRadio4">
                          Preferred Time
                        </FormCheck.Label>
                      </Form.Check>
                    </div>
                  </div>
                  {msgDistributionType === "Preferred Day & Time" && (
                    <p className="p-0 mb-3">
                      Please select default Day and Time for users who don't
                      have Preferred Day & Time.
                    </p>
                  )}
                  {msgDistributionType === "Preferred Time" && (
                    <p className="p-0 mb-3">
                      Please select default Time for users who don't have
                      Preferred Time.
                    </p>
                  )}
                  {msgDistributionType === "Schedule for Later" && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Select Date and Time</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          placeholder="Select Date and Time"
                          name="date_time"
                        />
                      </Form.Group>
                    </>
                  )}
                  {msgDistributionType === "Preferred Day & Time" && (
                    <div className="d-flex gap-2">
                      <Form.Group
                        className="mb-3 flex-1"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Select Day</Form.Label>
                        <Form.Select
                          // type="datetime-local"
                          placeholder="Please Select Day"
                          name="date_time"
                        >
                          <option value="0">Select day</option>
                          <option value="0"> Monday</option>
                          <option value="0">Tuesday</option>
                          <option value="0">Wednesday</option>
                          <option value="0">Thursday</option>
                          <option value="0">Friday</option>
                          <option value="0">Saturday</option>
                          <option value="0">Sunday</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        className="mb-3 flex-1"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Select Time</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="Select Date and Time"
                          name="date_time"
                        />
                      </Form.Group>
                    </div>
                  )}
                  {msgDistributionType === "Preferred Time" && (
                    <>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Select Time</Form.Label>
                        <Form.Control
                          type="time"
                          placeholder="Select Date and Time"
                          name="date_time"
                        />
                      </Form.Group>
                    </>
                  )}
                  <div>
                    <Form.Floating className="custom-form-floating position-relative d-flex flex-column">
                      <textarea
                        className="form-control"
                        aria-label="With textarea"
                      ></textarea>
                      <Form.Label htmlFor="floatingInput">Comment</Form.Label>
                    </Form.Floating>
                  </div>
                  <div className="d-flex gap-3 mt-3">
                    <Button
                      variant="danger"
                      className="flex-fill d-flex align-items-center justify-content-center fw-semibold rounded-3 px-4 py-2"
                      type="submit"
                    >
                      <span className="">Approve</span>
                    </Button>
                    <Button
                      variant="soft-secondary"
                      className="flex-fill d-flex align-items-center justify-content-center fw-normal rounded-3 px-4 py-2 text-secondary"
                      type="submit"
                    >
                      <span className="">Reject</span>
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AbTestFinalApprovalDetails;
