import { useState } from "react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import dayjs from "dayjs";
import useBackdrop from "./use-backdrop";
import toast from "react-hot-toast";

const useS3FileUpload = () => {
  const [progress, setProgress] = useState(0);
  const S3_BUCKET = "upload-download-clirnet.com";
  const REGION = "ap-south-1";
  const FOLDER_NAME = "DocTube/test-upload-video";
  const AMAZON_S3_BASE = "https://s3.ap-south-1.amazonaws.com";
  function generateRandomFourDigitNumber() {
    let rand = Math.floor(1000 + Math.random() * 9000);
    return rand.toString();
  }
  const uploadFileToStorage = async (file, cb) => {
    setProgress(1);
    let extension = file?.name?.substring(file?.name?.lastIndexOf(".") + 1);
    let fileName = generateRandomFourDigitNumber() + "_" + dayjs().unix();

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${FOLDER_NAME}/${fileName}.${extension}`,
    };

    const client = new S3Client({
      region: REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    try {
      const upload = new Upload({
        client,
        params,
        queueSize: 4, // optional concurrency configuration
        partSize: 1024 * 1024 * 5, // optional size of each part, in bytes, at least 5MB
        leavePartsOnError: false, // optional manually handle dropped parts
      });

      upload.on("httpUploadProgress", (evt) => {
        console.log("EVENT", evt);
        let progress_percentage = Math.round((evt.loaded / evt.total) * 100);
        console.log("PROGRESS_PERCENTAGE", progress_percentage);
        setProgress(progress_percentage);
        if (progress_percentage === 100) {
          let video_url = `${AMAZON_S3_BASE}/${S3_BUCKET}/${FOLDER_NAME}/${fileName}.${extension}`;
          cb(video_url, file.name);
        }
      });

      await upload.done();
    } catch (error) {
      console.error(error);
      setProgress(0);
      toast.error("Something went wrong while uploading the video");
    }
  };

  return { uploadFileToStorage, progress };
};

export default useS3FileUpload;
