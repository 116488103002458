import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "../../components/bootstrap/card";
import { Skeleton } from "@mui/material";

const RequisitionDetailsLoader = () => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row className="px-3">
                <div className="col-12 col-md-8 d-flex flex-column">
                  <div className="mb-3">
                    {/* <p className="m-0 fs-7">Title</p> */}
                    <Skeleton variant="text" width={400} height={30} />
                  </div>
                  <div className="mb-3">
                    <Skeleton variant="text" width={60} height={15} />
                    <Skeleton variant="text" width="70%" height={20} />
                    <Skeleton variant="text" width="70%" height={20} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </div>
                  <div className="d-flex flex-row flex-wrap gap-3 mb-3">
                    <Skeleton variant="rounded" width={200} height={30} />
                    <Skeleton variant="rounded" width={100} height={30} />
                    <Skeleton variant="rounded" width={130} height={30} />
                  </div>
                  <div className="d-flex flex-row flex-wrap gap-3">
                    <Skeleton variant="rounded" width={100} height={30} />
                    <Skeleton variant="rounded" width={120} height={30} />
                  </div>
                </div>
                <div className="col-12 col-md-4 bg-soft-white text-primary border border-1 border-soft-primary rounded-3 pt-3">
                  <div className="col-12">
                    <Skeleton variant="text" width={60} height={15} />
                    <Skeleton variant="text" width="80%" height={20} />
                  </div>
                  <div className="col-12">
                    <Skeleton variant="text" width={60} height={15} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </div>
                  <div className="col-12">
                    <Skeleton variant="text" width={60} height={15} />
                    <Skeleton variant="text" width="80%" height={20} />
                  </div>
                  <div className="col-12">
                    <Skeleton variant="text" width={60} height={15} />
                    <Skeleton variant="text" width="50%" height={20} />
                  </div>
                  <div className="col-12">
                    <Skeleton variant="text" width={60} height={15} />
                    <Skeleton variant="text" width="60%" height={20} />
                  </div>
                </div>
              </Row>
              <Row>
                <Col lg="12" className="d-flex justify-content-end gap-2 mt-3">
                  <Skeleton variant="rounded" width={100} height={30} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="iq-timeline m-0 d-flex align-items-center justify-content-between position-relative">
                <ul className="list-inline p-0 m-0 w-100">
                  <li>
                    <div className="time">
                      <span className="d-flex justify-content-center align-items-center">
                        <Skeleton variant="text" width="70%" height={25} />
                      </span>
                    </div>
                    <div className="content">
                      <div className="timeline-dots new-timeline-dots"></div>
                      <h6 className="d-flex justify-content-end mb-1">
                        <Skeleton variant="text" width="30%" height={20} />
                      </h6>
                      <div className="d-inline-block w-100">
                        <p className="d-flex justify-content-end">
                          <Skeleton variant="text" width="50%" height={20} />
                        </p>
                      </div>
                    </div>
                    <div className="content">
                      <div className="timeline-dots new-timeline-dots"></div>
                      <h6 className="d-flex justify-content-end mb-1">
                        <Skeleton variant="text" width="80%" height={20} />
                      </h6>
                      <div className="d-inline-block w-100">
                        <p className="d-flex justify-content-end">
                          <Skeleton variant="text" width="30%" height={20} />
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="time bg-success">
                      <span className="d-flex justify-content-center align-items-center">
                      <Skeleton variant="text" width="70%" height={25} />
                      </span>
                    </div>
                    <div className="content">
                      <div className="timeline-dots new-timeline-dots border-success"></div>
                      <h6 className="d-flex justify-content-start mb-1">
                        <Skeleton variant="text" width="40%" height={20} />
                      </h6>
                      <div className="d-inline-block w-100">
                        <p className="d-flex justify-content-start">
                          <Skeleton variant="text" width="30%" height={20} />
                        </p>
                      </div>
                    </div>
                    <div className="content">
                      <div className="timeline-dots new-timeline-dots border-success"></div>
                      <h6 className="d-flex justify-content-start mb-1">
                        <Skeleton variant="text" width="50%" height={20} />
                      </h6>
                      <div className="d-inline-block w-100">
                        <p className="d-flex justify-content-start">
                          <Skeleton variant="text" width="20%" height={20} />
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RequisitionDetailsLoader;
