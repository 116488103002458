import React, { useRef, useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";

function ChannelCreateCampaignModal(props) {
  const { redirectTo } = useRedirect();

  const copyCampaignLink = useRef(null);

  const copyToUrlClipboard = () => {
    if (copyCampaignLink.current) {
      copyCampaignLink.current.select();
      document.execCommand("copy");
    }
  };

  const onVideoAllCampaignView = () => {
    props.onHide();
    redirectTo("channel/profile/campaign/1");
    props.closeCurrentModal();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          CONGRATULATION
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="mb-4">
          <Row>
            <Col md={12}>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type="text"
                  ref={copyCampaignLink}
                  value={"https://www.doctube.com"}
                />
                <Button
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                  onClick={copyToUrlClipboard}
                >
                  Copy
                </Button>
              </div>
              <p>
                your campaign link has been successfully copied to clipboard
              </p>
            </Col>
          </Row>
        </Form>

        <div className="d-flex justify-content-between">
          <Button variant="success" onClick={() => onVideoAllCampaignView()}>
            view all campaign
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ChannelCreateCampaignModal;
