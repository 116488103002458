import React, { useEffect, useRef, useState } from "react";
import Titlebar from "../components/Titlebar";
import { initialDate } from "../constant/date-range-constant";
import { Card, Col, Row } from "react-bootstrap";
import CountCard from "../components/CountCard";
import { findIcon } from "../../../../dashboard/icons";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import LineChart from "../../../../../components/chart/LineChart";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import { checkProjectAccess } from "../utils/common";
import {
  formatLocalizedDate,
  formatNumberWithCommas,
  transformGraphData,
  transformHeatMapData,
  transformMapData,
} from "../../../../../common/utils/utils";
import IndiaMap from "../../../../../components/chart/IndiaMap";
import DonutChart from "../../../../../components/chart/DonutChart";
import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import CampaignCard from "../components/CampaignCard";
import VerticalTabs from "../components/VerticalTabs";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { useParams } from "react-router-dom";
import StackedBar from "../../../../../components/chart/StackedBar";
import DashboardDropdown from "../../../../../components/DashboardDropdown";
import { filterOptions } from "../constant/filter-options";
import {
  useGetSummaryAnalytics,
  useGetSummaryList,
  useGetSummaryContentGraph,
  useGetSummaryContentRead,
  useGetSummaryCtaReport,
  useGetSummaryLocationReport,
  useGetSummaryDeviceDistribution,
  useGetSummaryZonalDistribution,
} from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import BasicLineChart from "../../../../../components/chart/BasicLineChart";
import CountCardSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/count-card-skeleton";
import VerticalTabSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/vertical-tab-skeleton";
import Skeleton from "../../../../../components/skeleton";
import PageNotFound from "../../../../PageNotFound";
import MapAnalyticsSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/map-analytics-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import FilterBy from "../components/FilterBy";

function Summary() {
  const { project_id } = useParams();
  const { redirectTo } = useRedirect();

  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});
  const [activeSummaryTab, setActiveSummaryTab] = useState(null);
  const [contentIdsString, setContentIdsString] = useState("");
  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);
  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: initialDate.end_date,
  });
  const { data: summaryList, isLoading: isSummaryListLoading } =
    useGetSummaryList(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
    });
  const contentIdList =
    summaryList && Array.isArray(summaryList) && summaryList.length > 0
      ? summaryList.map((item) => item?.content_id).join(",")
      : "";

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: initialDate.end_date,
      });
    }
  }, [projectDetails]);

  useEffect(() => {
    if (summaryList && summaryList?.length > 0) {
      const content_title =
        summaryList[0]?.fetch_with_image[0]?.content_title || "";
      const id = summaryList[0]?.content_id || 0;
      setActiveSummaryTab({ value: id, label: content_title });
      const contentList = summaryList.map((item) => {
        return item?.content_id || "";
      });
      setContentIdsString(contentList?.join(","));
    }
  }, [summaryList]);

  const [type, setType] = useState(filterOptions?.initial);

  const { data: summaryAnalytics, isLoading: isSummaryAnalyticsLoading } =
    useGetSummaryAnalytics(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: contentIdsString || activeCampaignGroup?.id,
    });

  const { data: contentGraph, isLoading: isContentGraphLoading } =
    useGetSummaryContentGraph(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.id,
    });

  const { data: contentReadGraphData, isLoading: isContentReadLoading } =
    useGetSummaryContentRead(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: activeCampaignGroup?.id,
    });

  const { data: summaryCtaReport, isLoading: isCtaReportLoading } =
    useGetSummaryCtaReport(project_id, {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: contentIdList,
    });

  const { data: LocationMap, isLoading: isMapLocationReportLoading } =
    useGetSummaryLocationReport(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: contentIdList,
    });

  console.log("LocationMap", contentIdList);

  const {
    data: deviceDistributionData,
    isLoading: isDeviceDistributionLoading,
  } = useGetSummaryDeviceDistribution(project_id, type.toLowerCase(), {
    start_date: dateRangePayload.start_date,
    end_date: dateRangePayload.end_date,
    content_id: contentIdList,
  });

  const { data: zoneDistributionData, isLoading: isZonalDistributionLoading } =
    useGetSummaryZonalDistribution(project_id, type.toLowerCase(), {
      start_date: dateRangePayload.start_date,
      end_date: dateRangePayload.end_date,
      content_id: contentIdList,
    });

  // json
  const sponsoredContentList =
    summaryList && Array.isArray(summaryList) && summaryList.length > 0
      ? summaryList?.map((item) => {
          return {
            id: item?.content_id,
            group_title: item?.fetch_with_image[0]?.content_title,
          };
        })
      : [];

  const countCardData = [
    {
      text: "Read Target",
      count: formatNumberWithCommas(summaryAnalytics?.read_target) || "0",
      icon: findIcon("ImpressionTarget", "dual-tone", 42),
      bgPrimary: true,
    },
    {
      text: "Content Views",
      count: formatNumberWithCommas(summaryAnalytics?.total_count) || "0",
      icon: findIcon("ImpressionDelivered", "dual-tone", 42),
      bgPrimary: false,
    },
    {
      text: "Contents",
      count: formatNumberWithCommas(summaryAnalytics?.content_count) || "0",
      icon: findIcon("ImpressionCreatives", "dual-tone", 42),
      bgPrimary: true,
    },
    // {
    //   text: "Infographics",
    //   count: sponsoredAnalyticsData?.infographics || "0",
    //   icon: findIcon("ImpressionCampaigns", "dual-tone", 42),
    //   bgPrimary: false,
    // },
  ];

  const contentViewsChartOptions = {
    labels:
      contentGraph && contentGraph?.length > 0
        ? transformGraphData(contentGraph, "total_count", "formatted_date")
            ?.label
        : [],
    series:
      contentGraph && contentGraph?.length > 0
        ? transformGraphData(contentGraph, "total_count", "formatted_date")
            ?.series
        : [],
  };

  const contentReadChartOptions = {
    labels:
      contentReadGraphData && contentReadGraphData?.length > 0
        ? transformGraphData(
            contentReadGraphData,
            "read_count",
            "formatted_date"
          )?.label
        : [],
    series:
      contentReadGraphData && contentReadGraphData?.length > 0
        ? transformGraphData(
            contentReadGraphData,
            "read_count",
            "formatted_date"
          )?.series
        : [],
  };

  function transformBarChart(data) {
    if (data) {
      const series = [];
      const labels = [];

      console.log("transformBarChart", data);

      for (const item of data) {
        const categoryName = item.cta_type_info?.at(0)?.name || ""; // Assuming the first item in cta_type_info has the name
        const categoryData = item.cta_report.map(
          (report) => report.user_count || 0
        );
        series.push({ name: categoryName, data: categoryData });
        labels.push(...item.cta_report.map((report) => report.date));
      }
      const uniqueLabels = new Set(labels); // Remove duplicates
      return {
        series,
        labels: Array.from(uniqueLabels), // Convert labels set to array
      };
    } else {
      return { series: [], labels: [] };
    }
  }

  const ctaViewReadChartOptions = {
    series: transformBarChart(summaryCtaReport)?.series || [],
    labels: transformBarChart(summaryCtaReport)?.labels || [],
  };

  const transformData = (inputArray) => {
    // Use reduce to accumulate the result into a single object
    const result = inputArray.reduce((acc, { total_count, name }) => {
      acc[name] = total_count;
      return acc;
    }, {});

    return [result];
  };

  const stateAnalyticsData =
    LocationMap && LocationMap?.length > 0 ? transformData(LocationMap) : [];

  const deviceChartOptions = {
    series:
      deviceDistributionData?.length > 0
        ? transformGraphData(
            deviceDistributionData,
            "percentagebrowser_count",
            "Device"
          )?.series
        : [],
    label:
      deviceDistributionData?.length > 0
        ? transformGraphData(
            deviceDistributionData,
            "percentagebrowser_count",
            "Device"
          )?.label
        : [],
  };

  const zonalChartOptions =
    zoneDistributionData && zoneDistributionData?.length > 0
      ? transformHeatMapData(zoneDistributionData, "name", "total_count")
      : [];

  const handleRequestContent = () => {
    redirectTo(`sponsored-content/request-conetnt/${project_id}`);
  };

  const handleDropdownChange = (type) => {
    setType(type);
  };

  useEffect(() => {
    if (
      sponsoredContentList &&
      Array.isArray(sponsoredContentList) &&
      sponsoredContentList?.length > 0 &&
      !("id" in activeCampaignGroup)
    ) {
      setActiveCampaignGroup(sponsoredContentList[0]);
    }
  }, [sponsoredContentList]);

  function generateStackedBarData(data) {
    const series = [];

    for (const item of data) {
      const categoryName = item.content_category_name || ""; // Assuming the first item in cta_type_info has the name
      const contentCount = item.content_count;
      series.push({ name: categoryName, data: [contentCount] });
    }
    return series;
  }

  const StackedBarOptions = {
    series:
      summaryAnalytics?.grouped_count &&
      Array.isArray(summaryAnalytics?.grouped_count)
        ? generateStackedBarData(summaryAnalytics?.grouped_count)
        : [],
  };

  console.log("StackedBarOptions", StackedBarOptions);

  function hasCtaData(data) {
    // Check if data is an array
    if (!Array.isArray(data)) {
      return false;
    }

    // Check if any objects have data greater than 1
    return data.some((object) => {
      // Check if object has a "data" property
      if (!object.hasOwnProperty("data")) {
        return false;
      }

      // Check if "data" is an array and has a length greater than 1
      return Array.isArray(object.data) && object.data.length > 0;
    });
  }

  const hasViewAccess = checkProjectAccess("show_view", projectDetails);
  const hasReadAccess = checkProjectAccess("show_read", projectDetails);
  const hasCTAAccess = checkProjectAccess("show_CTA", projectDetails);

  return (
    <Row>
      <Col md={12}>
        {!isProjectDetailsLoading && (
          <Titlebar
            title={"Summary"}
            projectType={"Sponsored Content"}
            setDateRangePayload={setDateRangePayload}
            projectStartDate={projectDetails[0]?.project_start_date || ""}
            dateLabel={dateRangePayload}
            tabs={summaryList}
            activeSummaryTab={activeSummaryTab}
            setActiveSummaryTab={setActiveSummaryTab}
            isSummaryListLoading={isSummaryListLoading}
          />
        )}
      </Col>

      <Col md={12}>
        <Card>
          <Card.Body>
            <Row className="mb-md-4">
              {!isSummaryAnalyticsLoading &&
              Object.values(summaryAnalytics).length > 0 ? (
                <>
                  {countCardData?.map((item, index) => {
                    return (
                      <>
                        <Col md={3} sm={12} className="mb-4 mb-md-0">
                          <CountCard
                            text={item.text}
                            count={item.count}
                            icon={item.icon}
                            bgPrimary={item.bgPrimary}
                          />
                        </Col>
                      </>
                    );
                  })}

                  {/* coutCard */}

                  {!isSummaryAnalyticsLoading ? (
                    <Col md={3} sm={12} className="mb-4 mb-md-0">
                      <StackedBar
                        data={summaryAnalytics?.grouped_count}
                        series={StackedBarOptions?.series}
                      />
                    </Col>
                  ) : (
                    <Col md={3} sm={12} className="mb-4 mb-md-0">
                      <Skeleton variant="rounded" width="100%" height={90} />
                    </Col>
                  )}
                </>
              ) : (
                <CountCardSkeleton />
              )}
            </Row>

            {!isSummaryListLoading && summaryList?.length > 0 ? (
              <VerticalTabs
                tabs={summaryList}
                activeSummaryTab={activeSummaryTab}
              />
            ) : (
              <VerticalTabSkeleton />
            )}
          </Card.Body>
        </Card>
      </Col>

      {hasViewAccess && (
        <Col md={12} className="mb-4">
          <LineChart
            chartTitle={"Content Views"}
            graphColor={GRAPH_FILL_COLOR}
            labels={contentViewsChartOptions.labels}
            series={contentViewsChartOptions.series}
            sponsoredContentList={sponsoredContentList}
            activeCampaignGroup={activeCampaignGroup}
            setActiveCampaignGroup={setActiveCampaignGroup}
            loading={isContentGraphLoading}
            hasData={
              contentViewsChartOptions?.series &&
              Array.isArray(contentViewsChartOptions?.series) &&
              contentViewsChartOptions?.series?.length > 0
                ? true
                : false
            }
          />
        </Col>
      )}

      {hasReadAccess && (
        <Col md={12} className="mb-4">
          <LineChart
            chartTitle={"Content Read"}
            graphColor={GRAPH_FILL_COLOR}
            labels={contentReadChartOptions.labels}
            series={contentReadChartOptions.series}
            sponsoredContentList={sponsoredContentList}
            activeCampaignGroup={activeCampaignGroup}
            setActiveCampaignGroup={setActiveCampaignGroup}
            loading={isContentReadLoading}
            hasData={
              contentReadChartOptions?.series &&
              Array.isArray(contentReadChartOptions?.series) &&
              contentReadChartOptions?.series?.length > 0
                ? true
                : false
            }
          />
        </Col>
      )}

      {hasCTAAccess && (
        <Col md={12}>
          {!isProjectDetailsLoading &&
          !isCtaReportLoading &&
          !isSummaryListLoading &&
          ctaViewReadChartOptions?.series &&
          Array.isArray(ctaViewReadChartOptions?.series) &&
          hasCtaData(ctaViewReadChartOptions?.series) ? (
            <BasicLineChart
              title={"CTA Views and Read"}
              labels={ctaViewReadChartOptions.labels}
              graphColor={GRAPH_FILL_COLOR}
              series={ctaViewReadChartOptions.series}
            />
          ) : isCtaReportLoading ||
            isProjectDetailsLoading ||
            isSummaryListLoading ? (
            <BarChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
      )}
      <FilterBy handleDropdownChange={handleDropdownChange} type={type} />

      <Col md={12} lg={6} sm={12} className="d-flex flex-column pe-2 mb-4">
        {!isProjectDetailsLoading &&
        !isMapLocationReportLoading &&
        LocationMap?.length > 0 ? (
          <IndiaMap
            stateData={stateAnalyticsData}
            title={"Location"}
            valueSuffix={"%"}
          />
        ) : isMapLocationReportLoading || isProjectDetailsLoading ? (
          <MapAnalyticsSkeleton />
        ) : (
          <PageNotFound
            title="No data found"
            // message="Upload one to start analyzing data!"
            backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
          />
        )}
      </Col>

      <Col md={12} lg={6}>
        <Row>
          <Col md={12} sm={12} className="mb-4">
            {!isProjectDetailsLoading &&
            !isDeviceDistributionLoading &&
            deviceDistributionData?.length > 0 ? (
              <DonutChart
                series={deviceChartOptions.series}
                labels={deviceChartOptions.label}
                title={"Device Distribution"}
                graphColor={GRAPH_FILL_COLOR}
              />
            ) : isDeviceDistributionLoading || isProjectDetailsLoading ? (
              <SquareChartSkeleton />
            ) : (
              <PageNotFound
                title="No data found"
                // message="Upload one to start analyzing data!"
                backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
              />
            )}
          </Col>
          <Col md={12} sm={12} className="mb-4">
            {!isProjectDetailsLoading &&
            !isZonalDistributionLoading &&
            zoneDistributionData?.length > 0 ? (
              <TreeMapChart
                title={"Zonal Distribution"}
                series={zonalChartOptions}
              />
            ) : isZonalDistributionLoading || isProjectDetailsLoading ? (
              <SquareChartSkeleton />
            ) : (
              <PageNotFound
                title="No data found"
                // message="Upload one to start analyzing data!"
                backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
              />
            )}
          </Col>
        </Row>
      </Col>

      {/* <Col className="pe-2 mt-0 mt-md-3 mb-4" xs={12} lg={6} sm={6}>
        <CampaignCard
          icon={findIcon("Request Content", "dual-tone", 40)}
          title={"Request Content!"}
          subTitle={
            " Reach out to the CLIRNET content team to requisition Campaign related content."
          }
          buttonText={"Request Now"}
          handleOnClick={handleRequestContent}
        />
      </Col>

      <Col className="mt-0 mt-md-3 mb-4" xs={12} lg={6} sm={6}>
        <CampaignCard
          icon={findIcon("Request Content", "dual-tone", 40)}
          title={"Request Campaign!"}
          subTitle={
            "Configure a content based campaign for different social media before you send it"
          }
          buttonText={"Create Campaign"}
          // handleOnClick={handleRequestNowClick}
        />
      </Col> */}
    </Row>
  );
}

export default Summary;
