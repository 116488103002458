import React from "react";
import { Button } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import { Link, useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";

function CampaignCard({
  icon,
  title,
  subTitle,
  buttonText,
  linkText,
  handleOnClick
}) {

  return (
    <>
      <div
        className="card m-0 p-3 d-flex flex-row gap-3 overflow-hidden border border-soft-primary h-100"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <div className="position-relative">
          <span className="text-primary icon-60 d-inline-flex align-items-center justify-content-center flex-shrink-0 mt-n1 ms-n1">
            {icon}
          </span>
          <span className="h-rem-6 w-rem-6 position-absolute top-0 start-0 rounded-circle mt-n5 ms-n5 bg-opacity-25 bg-light"></span>
        </div>
        <div className="comment-details d-flex flex-column ps-4">
          <h6 className="mb-2 text-capitalize fw-medium fs-6">{title}</h6>
          <p className="small-text two-line-clamp">{subTitle}</p>

          {buttonText && (
            <Button
              onClick={handleOnClick}
              className="ms-auto rounded-3 px-3 mt-auto"
              variant="primary"
              size="sm"
            >
              {buttonText}
            </Button>
          )}
          {linkText && (
            <Link
              to={linkText}
              onClick={handleOnClick}
              className="text-decoration-underline ms-auto mt-auto"
            >
              {linkText}
              <span className="transform-rotate-45">
                {findIcon("Arrow Up", "outline", 20)}
              </span>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default CampaignCard;
