import React from "react";
import Chart from "react-apexcharts";
import { findIcon } from "../../views/dashboard/icons";
import { GRAPH_FILL_COLOR } from "../../views/workspace/clirnet/sponsored-content/constant/graph-color-scheme";

function StackedBar({ labels, series, title, height = 300, colors }) {
  let chartOptions = {
    series: series,
    options: {
      chart: {
        type: "bar",
        height: 100,
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      grid: {
        show: false,
        padding: {
          right: 14,
          left: 0,
        },
      },
      xaxis: {
        categories: ["Content Type"],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      colors: GRAPH_FILL_COLOR,
    },
  };

  return (
    <div
      id="chart"
      className="d-flex align-items-center justify-content-center p-0 m-0 border border-primary rounded-3"
      style={{ height: 88 }}
    >
      <Chart
        options={chartOptions.options}
        series={chartOptions.series}
        type="bar"
        height="140px"
        width="100%"
      />
    </div>
  );
}

export default StackedBar;
