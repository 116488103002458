export const ALL_REQS_GET = "/requisition/list";
//create requisition and add requisition items
export const CREATE_REQ_POST = "/requisition/create";
export const EDIT_REQ_POST = "/requisition/edit";
export const FIRST_STEP_ADD = "/requisition/first-step-add";
export const THIRD_STEP_ADD = "/requisition/third-step-add/";
export const GET_WORKFLOW = "/requisition/workflows/1";
export const FIRST_STEP_UPDATE = "/requisition/first-step-update/";
export const THIRD_STEP_UPDATE = "/requisition/third-step-update/";
export const ALL_VENDORS = "/requisition/vendors";
export const GET_ASSOCIATES = "/requisition/associates";
export const FILE_UPLOAD = "/fileupload";
export const GET_CONTENT_VAULT = "/requisition/list_content_vault";
export const ADD_CONTENT_TO_VAULT = "/requisition/add_content_vault";
export const REMOVE_FROM_VAULT = "/requisition/delete_content_vault/";
export const DELETE_REQUISITION = "/requisition/delete/";
export const MY_REQUISTIONS = "/requisition/mylist";
export const REQUISITION_DETAILS = "/requisition/detail/";
