import React from "react";
import Skeleton from "../../../../components/skeleton";

const TimelineSkeleton = () => {
  return (
    <>
      <h4 className="d-flex justify-content-center position-relative mb-5">
        <Skeleton height={30} width={200} />
      </h4>

      <div className="iq-timeline m-0 d-flex align-items-center position-relative">
        <ul className="list-inline p-0 m-0 w-100">
          {[1, 2, 3].map((item, rootIndex) => {
            return (
              <li key={rootIndex}>
                <div
                  className={`time shadow-none ${
                    rootIndex % 2 === 0 ? "bg-primary ps-0" : "bg-success pe-0"
                  }`}
                ></div>

                <div className="content">
                  <div
                    className={`timeline-dots new-timeline-dots ${
                      rootIndex % 2 === 0 ? "border-primary" : "border-success"
                    }`}
                  ></div>

                  <h5
                    className={`mb-1 d-flex ${
                      rootIndex % 2 === 0
                        ? "justify-content-start justify-content-md-end"
                        : "justify-content-start"
                    }`}
                  >
                    <Skeleton width={350} height={25} />
                  </h5>

                  <div
                    className={`d-flex gap-3 ${
                      rootIndex % 2 === 0
                        ? "justify-content-start justify-content-md-end"
                        : "justify-content-start"
                    }`}
                  >
                    {[1, 2, 3].map((item, index) => {
                      return (
                        <p className="m-0 fs-7" key={index}>
                          <span
                            className={`${
                              rootIndex % 2 === 0
                                ? "text-primary"
                                : "text-success"
                            }`}
                          >
                            <Skeleton width={80} height={25} />
                          </span>
                        </p>
                      );
                    })}
                  </div>

                  <div className="d-inline-block w-100 text-dark py-2">
                    <p
                      className={`mb-1 d-flex ${
                        rootIndex % 2 === 0
                          ? "justify-content-start justify-content-md-end"
                          : "justify-content-start"
                      }`}
                    >
                      <Skeleton
                        className="rounded-pill"
                        height={25}
                        width={80}
                      />
                    </p>
                  </div>

                  <div className="w-100 rounded-3">
                    <p
                      className={`d-flex m-0 ${
                        rootIndex % 2 === 0
                          ? "justify-content-start justify-content-md-end"
                          : "justify-content-start"
                      }`}
                    >
                      <Skeleton
                        height={250}
                        width={"100%"}
                        className={"m-0 p-0"}
                      />
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TimelineSkeleton;
