import { Fragment, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { CREATE_CONTENT } from "../../../../api/endpoints/add-content-endpoints";
import AddArticle from "../components/add-article";
import AddFile from "../components/add-file";
import AddHtml from "../components/add-html";
import AddMedia from "../components/add-media";
import { cmsGetService } from "../../../../api/services/cms-get-services";
import AddContentTypeLoader from "../../../../skeleton/creative-library/add-content-type-loader";
import AddContentAreaLoader from "../../../../skeleton/creative-library/upload-loader";

const UploadCreative = () => {
  const [type, setType] = useState(null);
  const [subType, setSubType] = useState(null);
  const [formFields, setFormFields] = useState(true);
  const [loading, setLoading] = useState(true);
  const [fileTypeId, setFileTypeId] = useState(null);

  function selectType(btn) {
    if (btn.type === "media") {
      setType(btn.type);
      setSubType(btn.sub[0].type);
    } else setType(btn.type);
  }

  function getData() {
    cmsGetService(CREATE_CONTENT)
      .then((data) => {
        setFormFields(data.data.data);
        setType(data.data.data.filetypes[0].file_type_code);
        setFileTypeId(data.data.data.filetypes[0].file_type_id);
        setLoading(false);
      })
      .catch((e) => console.log("error", e));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Fragment>
        {!loading ? (
          <Row>
            <Col lg="12" className="p-0 p-md-3">
              <div className="d-flex flex-wrap gap-2 mb-3">
                {formFields &&
                  formFields.filetypes &&
                  formFields.filetypes.map((fileType) => (
                    <Button
                      key={fileType.file_type_id}
                      variant={
                        type === fileType.file_type_code
                          ? "primary"
                          : "outline-secondary"
                      }
                      onClick={() => {
                        setType(fileType.file_type_code);
                        setFileTypeId(fileType.file_type_id);
                      }}
                    >
                      Add {fileType.file_type_name}
                    </Button>
                  ))}
              </div>
            </Col>
          </Row>
        ) : (
          <AddContentTypeLoader />
        )}

        {!loading ? (
          <>
            {type === "article" && (
              <Row>
                <AddArticle />
              </Row>
            )}
            {/* End Add Medwiki Section */}

            {/* UPLOAD PDF FILE */}
            {type == "file" && (
              <Row>
                <AddFile
                  type="file"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts="*"
                />
              </Row>
            )}
            {/* UPLOAD VIDEO */}
            {type == "video" && (
              <Row>
                <AddMedia
                  type="video"
                  formFields={formFields}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  accepts="video/*"
                  content_file_type_id={fileTypeId}
                />
              </Row>
            )}
            {/* UPLOAD INFOGRAPHIC */}
            {type == "infographic" && (
              <Row>
                <AddMedia
                  type="image"
                  formFields={formFields}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  accepts="image/*"
                  content_file_type_id={fileTypeId}
                />
              </Row>
            )}
            {/* UPLOAD IMAGE */}
            {type == "image" && (
              <Row>
                <AddMedia
                  type="image"
                  formFields={formFields}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  accepts="image/*"
                  content_file_type_id={fileTypeId}
                />
              </Row>
            )}
            {/* UPLOAD AUDIO */}
            {type == "audio" && (
              <Row>
                <AddMedia
                  type="audio"
                  formFields={formFields}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  accepts="audio/*"
                  content_file_type_id={fileTypeId}
                />
              </Row>
            )}
            {/* UPLOAD PDF */}
            {type == "pdf" && (
              <Row>
                <AddFile
                  type="pdf"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts="application/pdf"
                />
              </Row>
            )}
            {/* UPLOAD PPT */}
            {type == "ppt" && (
              <Row>
                <AddFile
                  type="ppt"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts="application/vnd.ms-powerpoint,
                application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                application/vnd.openxmlformats-officedocument.presentationml.presentation"
                />
              </Row>
            )}
            {/* UPLOAD DOCX */}
            {type == "docx" && (
              <Row>
                <AddFile
                  type="docx"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
              </Row>
            )}
            {/* UPLOAD EXCEL */}
            {type == "excel" && (
              <Row>
                <AddFile
                  type="xlsx"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </Row>
            )}
            {/* UPLOAD zip */}
            {type == "zip" && (
              <Row>
                <AddFile
                  type="zip"
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                  content_file_type_id={fileTypeId}
                  accepts=".zip,.rar,.7zip,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                />
              </Row>
            )}
            {/* UPLOAD HTML */}
            {(type === "html" || type === "email") && (
              <Row className="myClass">
                <AddHtml
                  content_file_type_id={fileTypeId}
                  content_category_id={
                    formFields.category[0].content_category_id
                  }
                  formFields={formFields}
                />
              </Row>
            )}
          </>
        ) : (
          <AddContentAreaLoader />
        )}
      </Fragment>
    </>
  );
};

export default UploadCreative;
