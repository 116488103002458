import * as yup from "yup";

const addVideoInitialValues = {
  title: "",
  title_html: "",
  description: "",
  description_html: "",
  references: "",
  references_html: "",
  referenceFile: "",
  notes: "",
  file: null,
  coverImage: null,
  thumbnail: null,
  speciality: [],
  category: "",
  tags: [],
  brand: "",
  is_featured: false,
  privacy_status: "",
  base_likes: 0,
  template_id: 0,
  content_to_env_price: [
    {
      env_id: "1",
      price: 0,
    },
  ],
};
const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const addVideoSchema = yup.object().shape({
  title: yup.string().required("Please Enter a title"),
  description: yup.string().required("Please Enter a description"),
  references: yup.string().required("Please Enter a references"),
  is_featured: yup.boolean(),
  file: yup.mixed().required("Please add a video"),
  thumbnail: yup.mixed().required("Please add a Thumbnail"),
  coverImage: yup.mixed().required("Please add a Cover Image"),
  // brand: yup.string().required("Please select a brand"),
  notes: yup.string(),
  // privacy_status: yup.string(),
  speciality: yup.array().min(1, "Please select at least one speciality"),

  // tags: yup.array().min(1, "Please select at least one tag"),
  gr_vendor_vid_src: yup.string().matches(URL, "Enter a valid url"),
});

const addImageInitialValues = {
  title: "",
  title_html: "",
  description: "",
  description_html: "",
  references: "",
  references_html: "",
  referenceFile: "",
  notes: "",
  file: null,
  coverImage: null,
  thumbnail: null,
  speciality: [],
  category: "",
  tags: [],
  brand: "",
  is_featured: false,
  privacy_status: "",
  content_to_env_price: [
    {
      env_id: "1",
      price: 0,
    },
  ],
};

const addImageSchema = yup.object().shape({
  title: yup.string().required("Please Enter a title"),
  description: yup.string().required("Please Enter a description"),
  references: yup.string().required("Please Enter a references"),
  is_featured: yup.boolean(),
  file: yup.mixed().required("Please add a video"),
  thumbnail: yup.mixed().required("Please add a Thumbnail"),
  coverImage: yup.mixed().required("Please add a Cover Image"),
  // brand: yup.string().required("Please select a brand"),
  notes: yup.string(),
  // privacy_status: yup.string(),
  speciality: yup.array().min(1, "Please select at least one speciality"),

  // tags: yup.array().min(1, "Please select at least one tag"),
  gr_vendor_vid_src: yup.string().matches(URL, "Enter a valid url"),
});

const addAudioInitialValues = {
  title: "",
  title_html: "",
  description_html: "",
  references_html: "",
  description: "",
  references: "",
  referenceFile: "",
  notes: "",
  file: null,
  coverImage: null,
  thumbnail: null,
  speciality: [],
  category: "",
  tags: [],
  brand: "",
  sponsor: "",
  question_code: "",
};

const addAudioSchema = yup.object().shape({
  title: yup.string().required("Please Enter a title"),
  description: yup.string().required("Please Enter a description"),
  references: yup.string().required("Please Enter a references"),
  file: yup.mixed().required("Please add a video"),
  // brand: yup.string().required("Please select a brand"),
  thumbnail: yup.mixed().required("Please add a Thumbnail"),
  coverImage: yup.mixed().required("Please add a Cover Image"),
  sponsor: yup.string(),
  question_code: yup.string(),
});

export {
  addVideoInitialValues,
  addVideoSchema,
  addImageSchema,
  addImageInitialValues,
  addAudioInitialValues,
  addAudioSchema,
};
