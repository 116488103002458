import {Fragment} from 'react'

//react-bootstrap
import {Row,Col,Image} from 'react-bootstrap'

//components
import Card from '../../../../components/bootstrap/card'

//router
import {Link} from 'react-router-dom'

//progressbar
import Progress from '../../../../components/progress.js'


const SegmentHistory = () => {
  return (
    <Fragment>
      <Row>
        {/* <div className="col-12 col-md-10 mb-4">
          <div className="d-flex justify-content-between">
              <h4 className="mb-0">Segment Studio</h4>
          </div>
        </div> */}
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Segment Studio History</h4>
              </div>
            </Card.Header>
            <Card.Body className="p-0 mt-3">
              <div className="table-responsive">
                <table id="basic-table" className="table table-striped mb-0" role="grid">
                  <thead>
                    <tr>
                      <th>Segment Name</th>
                      <th>Persona(s)</th>
                      <th>Created Date</th>
                      <th>Status</th>
                      <th>Reach</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td>
                          <div className="d-flex align-items-center">
                              <h6>Soft UI XD Version</h6>
                          </div>
                        </td>
                        <td>
                          <div className="iq-media-group iq-media-group-1">
                            <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                            </Link>
                            <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                            </Link>
                            <Link to="#" className="iq-media-1">
                                <div className="icon iq-icon-box-3 rounded-pill">MM</div>
                            </Link>
                          </div>
                        </td>
                        <td>2008/11/28</td>
                        <td><div className="text-info">Active</div></td>
                        <td>
                          <div className="d-flex align-items-center mb-2">
                              <h6>60%</h6>
                          </div>
                          <Progress softcolors="info" color="info" className="shadow-none w-100" value={60} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                        </td>
                    </tr>               
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                            <h6>Add Progress Track</h6>
                        </div>
                      </td>
                      <td>
                        <div className="iq-media-group iq-media-group-1">
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                          </Link>
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                          </Link>
                        </div>
                      </td>
                      <td>2008/11/28</td>
                      <td><div className="text-danger">Active</div></td>
                      <td>
                        <div className="d-flex align-items-center mb-2">
                            <h6>10%</h6>
                        </div>
                        <Progress softcolors="danger" color="danger" className="shadow-none w-100" value={10} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                            <h6>Fix Platform Errors</h6>
                        </div>
                      </td>
                      <td>
                        <div className="iq-media-group iq-media-group-1">
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                          </Link>
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                          </Link>
                        </div>
                      </td>
                      <td>2008/11/28</td>
                      <td><div className="text-success">Completed</div></td>
                      <td>
                        <div className="d-flex align-items-center mb-2">
                            <h6>13%</h6>
                        </div>
                        <Progress softcolors="success" color="success" className="shadow-none w-100" value={10} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                            <h6>Launch Our Mobile App</h6>
                        </div>
                      </td>
                      <td>
                        <div className="iq-media-group iq-media-group-1">
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                          </Link>
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                          </Link>
                              <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">AP</div>
                          </Link>
                              <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">DP</div>
                          </Link>
                        </div>
                      </td>
                      <td>2008/11/28</td>
                      <td><div className="text-success">Completed</div></td>
                      <td>
                        <div className="d-flex align-items-center mb-2">
                            <h6>100%</h6>
                        </div>
                        <Progress softcolors="success" color="success" className="shadow-none w-100" value={100} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                            <h6>Add the New Pricing Page</h6>
                        </div>
                      </td>
                      <td>
                        <div className="iq-media-group iq-media-group-1">
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                          </Link>
                        </div>
                      </td>
                      <td>2008/11/28</td>
                      <td><div className="text-primary">On Schedule</div></td>
                      <td>
                        <div className="d-flex align-items-center mb-2">
                            <h6>25%</h6>
                        </div>
                        <Progress softcolors="primary" color="primary" className="shadow-none w-100" value={25} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                            <h6>Redesign New Online Shop</h6>
                        </div>
                      </td>
                      <td>
                        <div className="iq-media-group iq-media-group-1">
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                          </Link>
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                          </Link>
                        </div>
                      </td>
                      <td>2008/11/28</td>
                      <td><div className="text-warning">Completed</div></td>
                      <td>
                        <div className="d-flex align-items-center mb-2">
                            <h6>40%</h6>
                        </div>
                        <Progress softcolors="warning" color="warning" className="shadow-none w-100" value={40} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                            <h6>Add Progress Track</h6>
                        </div>
                      </td>
                      <td>
                        <div className="iq-media-group iq-media-group-1">
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">SP</div>
                          </Link>
                          <Link to="#" className="iq-media-1">
                              <div className="icon iq-icon-box-3 rounded-pill">PP</div>
                          </Link>
                        </div>
                      </td>
                      <td>2008/11/28</td>
                      <td><div className="text-danger">Active</div></td>
                      <td>
                        <div className="d-flex align-items-center mb-2">
                            <h6>10%</h6>
                        </div>
                        <Progress softcolors="danger" color="danger" className="shadow-none w-100" value={10} minvalue={0} maxvalue={100} style={{height: "6px"}} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  )
}

export default SegmentHistory