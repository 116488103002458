import React from "react";
import { Card, Row } from "react-bootstrap";
import HcpCardSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpCardSkeleton";

const HcpCardDetails = ({ hcpDetail }) => {
  // const { id: hcpId } = useParams();
  // //   const commonPayload = { hcp_id: hcpId };
  // const onSuccess = (response) => {
  //   //response
  //   //console.log("reponse", response);
  // };

  // const {
  //   data: hcpDetail,
  //   refetch: refetchHcpDetails,
  //   isLoading,
  // } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">HCP Details</h4>
        </div>
      </Card.Header>

      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">HCP Id</div>

            <div className="col-5 mb-3">{hcpDetail?.hcp_id ?? "NA"}</div>
            <div className="col-7 mb-3">User Type</div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_type?.hcp_type_name ?? "NA"}
            </div>
            <div className="col-7 mb-3">Age</div>
            <div className="col-4 mb-3">{hcpDetail?.hcp_age ?? "NA"}</div>
            <div className="col-7 mb-3">Gender</div>
            <div className="col-4 mb-3">{hcpDetail?.hcp_gender ?? "NA"}</div>
            <div className="col-7 mb-3">Email</div>
            <div className="col-4 mb-3">{hcpDetail?.hcp_email ?? "NA"}</div>
            <div className="col-7 mb-3">Phone Number</div>
            <div className="col-4 mb-3">{hcpDetail?.hcp_mobile ?? "NA"}</div>
            {/* <div className="col-7 mb-3">Degree</div>
          <div className="col-4 mb-3">
            {hcpDetail?.hcp_to_medical_degrees?.length > 0
              ? extractDegreeNames(
                  hcpDetail?.hcp_to_medical_degrees
                )
              : "NA"}
          </div> */}
            <div className="col-7 mb-3">Country</div>
            <div className="col-4 mb-3">
              {" "}
              {hcpDetail?.hcp_to_country?.country_name ?? "NA"}
            </div>
            <div className="col-7 mb-3">Zone </div>
            <div className="col-4 mb-3">
              {" "}
              {hcpDetail?.hcp_to_zone?.zone_name ?? "NA"}
            </div>
            <div className="col-7 mb-3">State </div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_state?.state_name ?? "NA"}
            </div>
            <div className="col-7 mb-3">City </div>
            <div className="col-4 mb-3">
              {hcpDetail?.hcp_to_city?.city_name ?? "NA"}
            </div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default HcpCardDetails;
