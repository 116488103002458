import React, { memo, useState } from "react";
import { Nav, Tab, Row, Col, Image, Button, Modal } from "react-bootstrap";
import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";

import DropDownMenu from "../DropDownMenu";
import { findIcon } from "../../../../../../dashboard/icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
import { CHANNEL_VIDEO_UPDATE_BASIC } from "../../../../../../../api/endpoints/channel-video-endpoints";
import postService from "../../../../../../../api/services/post-service";
import toast from "react-hot-toast";
import dayjs from "dayjs";

function VideoCard({
  title,
  description,
  videoId,
  coverImage,
  isUploading,
  privacy_status = 0,
  publishStatus,
  videoType = 1,
  processingStatus,
  onPrivacyUpdate,
  publishDate,
}) {
  const privacyOptions = [
    {
      label: "Public",
      value: "0",
    },
    {
      label: "Private",
      value: "1",
    },
  ];
  const [privacy, setPrivacy] = useState(privacy_status);
  const [modalShow, setModalShow] = useState(false);
  const { redirectTo } = useRedirect();
  let now = dayjs(new Date()); //todays date
  let end = dayjs(publishDate); // another date
  let dateDifference = now.diff(end, "s");

  const onVideoDetailsView = (e) => {
    if (processingStatus == 0 || processingStatus != 2) {
      toast.error("Please wait until processing is completed");
      return false;
    }
    redirectTo("channel/video/" + videoId);
  };
  const onVideoAnalyticsView = () => {
    if (publishStatus != 3) {
      toast.error("Publish The Video First");
      return false;
    }
    redirectTo("channel/video/analytics/" + videoId);
  };
  const onVideoCommentsView = () => {
    if (publishStatus != 3) {
      toast.error("Publish The Video First");
      return false;
    }
    redirectTo("channel/video/comments/" + videoId);
  };
  const onCampaignClick = () => {
    if (publishStatus != 3 || dateDifference < 0) {
      toast.error("Publish The Video First");
      return false;
    }
    redirectTo("channel/video/campaign/" + videoId);
  };

  const detailsSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit video details
    </Tooltip>
  );

  const analyticsSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Video Analytics
    </Tooltip>
  );

  const commentsSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Video Comments
    </Tooltip>
  );

  const campaignLinkSectionTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Campaign Link
    </Tooltip>
  );
  const changePrivacyStatus = async (value) => {
    setPrivacy(value);
    let response = await postService(CHANNEL_VIDEO_UPDATE_BASIC, {
      content_id: videoId,
      field: "content_privacy_status",
      value: privacy == 0 ? "1" : "0",
    });
    if (response.data?.success) {
      toast.success("Status Updated Successfully");
      onPrivacyUpdate();
    }
  };
  return (
    <>
      <div className="d-flex align-items-center  gap-3">
        <div
          className={`ratio-16x9 bg-dark rounded-3 d-flex justify-content-center flex-shrink-0 overflow-hidden ${
            videoType == 1 ? "w-rem-10" : ""
          }`}
        >
          {coverImage && (
            <Image
              alt={title}
              src={coverImage}
              onError={(e) => (e.target.src = fallbackImages.noVideo)}
              className={`w-auto mw-100 object-fit-cover ${
                videoType == 1 ? "h-rem-6" : "h-rem-5"
              }`}
            />
          )}
        </div>
        <div className="d-flex flex-column flex-grow-1 channel__videos__table__1stChild__content">
          <div className="d-flex flex-column">
            {title ? (
              <h4 className="fs-6 text-black fw-medium text-truncate mb-1">
                {title}
              </h4>
            ) : (
              <div className="fs-5 text-truncate">Title not found </div>
            )}
            {description && (
              <span className="text-muted fs-7 text-truncate">
                {description}
              </span>
            )}
          </div>
          {!isUploading && (
            <div className="d-flex gap-2 mt-2 ">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 200 }}
                overlay={detailsSectionTooltip}
              >
                <span
                  className={`d-flex align-items-center justify-content-center icon-24 ${
                    processingStatus == 0 || processingStatus != 2
                      ? "opacity-50"
                      : ""
                  }`}
                  onClick={onVideoDetailsView}
                >
                  {findIcon("Edit Icon", "dual-tone", 16)}
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 200 }}
                overlay={analyticsSectionTooltip}
              >
                <span
                  className={`d-flex align-items-center justify-content-center icon-24 ${
                    publishStatus != 3 ? "opacity-50" : ""
                  }`}
                  onClick={onVideoAnalyticsView}
                >
                  {findIcon("Video Graph", "dual-tone", 16)}
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 200 }}
                overlay={commentsSectionTooltip}
              >
                <span
                  className={`d-flex align-items-center justify-content-center icon-24 ${
                    publishStatus != 3 ? "opacity-50" : ""
                  }`}
                  onClick={onVideoCommentsView}
                >
                  {findIcon("Video Comment", "dual-tone", 16)}
                </span>
              </OverlayTrigger>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 50, hide: 200 }}
                overlay={campaignLinkSectionTooltip}
              >
                <span
                  className={`d-flex align-items-center justify-content-center gap-2 icon-24 ${
                    publishStatus != 3 || dateDifference < 0 ? "opacity-50" : ""
                  }`}
                  overlay={campaignLinkSectionTooltip}
                  onClick={onCampaignClick}
                >
                  {findIcon("Video Links", "dual-tone", 16)}
                </span>
              </OverlayTrigger>

              {publishStatus == 3 ? (
                <div className="d-flex align-items-center justify-content-center gap-1 icon-24 w-auto">
                  {privacy == 0 && (
                    <span className="text-success">
                      {findIcon("Globe", "dual-tone", 16)}
                    </span>
                  )}
                  {privacy == 1 && (
                    <span className="text-primary">
                      {findIcon("Private Lock", "dual-tone", 16)}
                    </span>
                  )}

                  {publishStatus == 3 && (
                    <DropDownMenu
                      title="privacy"
                      label={privacy == 1 ? "Private" : "Public"}
                      constantFilterOption={privacyOptions}
                      handleDropdownChange={(item) => {
                        changePrivacyStatus(item);
                      }}
                    />
                  )}
                </div>
              ) : publishStatus == 1 || publishStatus == 2 ? (
                <div
                  title={"Draft"}
                  className="d-flex align-items-center justify-content-center gap-1 icon-24 w-auto"
                >
                  {findIcon("Pending", "dual-tone", 16)}
                </div>
              ) : processingStatus == 1 ? (
                <>
                  {" "}
                  <div
                    title={"In Progress"}
                    className="d-flex align-items-center justify-content-center gap-1 icon-24 w-auto"
                  >
                    {findIcon("In Progress", "dual-tone", 16)}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default memo(VideoCard);
