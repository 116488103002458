import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
} from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Modal,
  Badge,
  FormCheck,
} from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import {
  changeTableStateToPayload,
  statusToBg,
} from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_COUNCIL_POST,
  ALL_COUNCIL_POST,
  DELETE_COUNCIL_POST,
  UPDATE_COUNCIL_POST,
} from "../../../../../api/endpoints/council-endpoints";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../components/form-components/floating-button";
import withAuthorization from "../../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../../skeleton/react-table-loader/react-table-loader";

const AllCouncils = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [councilList, setCouncilList] = useState([]);
  console.log("councilList", councilList);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewCouncil, setViewCouncil] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedCouncil, setSelectedCouncil] = useState(null);
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      council_id: "desc",
    },
  });
  //yup and formik for validation of form fields of council_name

  const validationSchema = yup.object().shape({
    council_name: yup.string().trim().required("Council Name is required"),
    council_description: yup
      .string()
      .required("Council Description is required"),
    // Status: yup.string().required("Council Status is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      council_name: "",
      council_description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("values===>", values);
      const postValues = {
        council_name: values.council_name.trim(),
        council_description: values.council_description.trim(),
      };

      try {
        setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_COUNCIL_POST + "/" + selectedCouncil
            : ADD_COUNCIL_POST,
          {
            ...postValues,
            // council_active: 1,
            status: isEdit ? values.status : 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchCouncils(post_json.current);
          isEdit && setIsEdit(false);
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  //fetch priorities

  const fetchCouncils = async () => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_COUNCIL_POST, post_json.current);
      console.log("COUNCIL_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setCouncilList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("COUNCIL_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (council) => {
    console.log("council===>", council);
    let dataJson = {
      "Council ID": council.council_id,
      "Council Name": council.council_name,
      Description: council.council_description,
      "Created By": council.created_by.name,
      Status: council.status.value,
    };
    setViewCouncil(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteCouncil = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${DELETE_COUNCIL_POST}/${selectedCouncil}`
      );
      if (response) {
        setLoading(false);
        setDeleteModalShow(false);
        fetchCouncils();
        toast.success("Council Deleted Successfully");
      }
    } catch (error) {
      setLoading(false);
      console.log("COUNCIL_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchCouncils(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      fetchCouncils(payload);
    },
    [councilList, loading, post_json.current]
  );
  const onDeleteCouncil = (council) => {
    // alert("deleting" + council.council_id);
    setSelectedCouncil(council.council_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Council ID",
      accessor: "council_id",
    },
    {
      Header: "Council Name",
      accessor: "council_name",
    },
    {
      Header: "Description",
      accessor: "council_description",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Badge bg={statusToBg(value.id)} className="me-2">
          <span className="btn-inner">{value.value}</span>
        </Badge>
      ),
    },
    {
      Header: "Created By",
      accessor: "created_by",
      Cell: ({ row }) => row.original.created_by.name,
    },
    // {
    //   Header: "Modified By",
    //   accessor: "modified_by",
    // },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap">
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => viewModal(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Eye", "dual-tone", 32)}
              </span>
            </Button>
          </div>

          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => onEditCouncilClick(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Pencil", "dual-tone")}
              </span>
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => onDeleteCouncil(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Trash", "dual-tone", 32)}
              </span>
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditCouncilClick = (council) => {
    console.log("council===>", council);
    setIsEdit(true);
    setSelectedCouncil(council.council_id);
    //set formik values
    setValues({
      council_name: council.council_name,
      council_description: council.council_description,
      status: council.status.id,
    });
    setCreateModalShow(true);
  };
  console.log("formic", values);
  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Council Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Council Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                councilList.length > 0 && (
                  <ReactTable
                    data={councilList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteCouncil}
      />
      <ViewModal
        title={"View Council Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewCouncil}
      />
      {/* create a add Council Name modal with Council Name name and description */}
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Council Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Council Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Council Name"
                name="council_name"
                onChange={handleChange}
                value={values.council_name}
                onBlur={handleBlur}
                isInvalid={touched.council_name && errors.council_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.council_name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Council Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Council Description"
                name="council_description"
                onChange={handleChange}
                value={values.council_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.council_description && errors.council_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.council_description}
              </Form.Control.Feedback>
            </Form.Group>
            {isEdit && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  Council Status <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="status"
                  value={values?.status}
                  isInvalid={touched.status && errors.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Select Status</option>
                  <option value="1">Enabled</option>
                  <option value="2">Disabled</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.status}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Council Status</Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Status"
                name="Status"
                onChange={handleChange}
                value={values.Status}
                onBlur={handleBlur}
                isInvalid={touched.Status && errors.Status}
              >
                <option value="">Select Status</option>
                <option value={1}>Enable</option>
                <option value={2}>Disable</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.Status}
              </Form.Control.Feedback>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default withAuthorization(memo(AllCouncils));
