import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";

const CreateSegmentModal = ({ show, modalClose }) => {
  const { redirectTo } = useRedirect();

  const initialValues = {
    segment_name: "",
    segment_description: "",
  };

  const validationSchema = Yup.object().shape({
    segment_name: Yup.string().max(20).required("Please enter a segment name"),
    segment_description: Yup.string()
      .max(50)
      .required("Please enter a description"),
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      // dispatch(addSegment(values));
      redirectTo("segment/create", values);
    },
  });

  const onModalClose = () => {
    handleReset();
    modalClose();
  };
  return (
    <Modal show={show} onHide={onModalClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">Create New Segment</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative d-flex flex-column gap-3">
          <Form.Floating className="custom-form-floating">
            <Form.Control
              type="text"
              className=""
              id="floatingInput1"
              autoComplete="Name Your Segment"
              placeholder="Name Your Segment"
              value={values.segment_name}
              name="segment_name"
              onChange={handleChange}
              isInvalid={touched.segment_name && errors.segment_name}
            />
            <Form.Label htmlFor="floatingInput">Name Your Segment*</Form.Label>
            <Form.Control.Feedback type="invalid">
              {errors.segment_name}
            </Form.Control.Feedback>
          </Form.Floating>
          <Form.Floating className="custom-form-floating">
            <Form.Control
              as={"textarea"}
              type="text"
              className=""
              id="floatingInput1"
              autoComplete="Describe Your Segment"
              placeholder="Describe Your Segment"
              value={values.segment_description}
              name="segment_description"
              onChange={handleChange}
              isInvalid={
                touched.segment_description && errors.segment_description
              }
            />
            <Form.Label htmlFor="floatingInput">
              Describe Your Segment*
            </Form.Label>
            {/* <div className="d-flex justify-content-end mt-2">
              {values.segment_description.length}/160
            </div> */}
            <Form.Control.Feedback type="invalid">
              {errors.segment_description}
            </Form.Control.Feedback>
          </Form.Floating>
          <Button className="" variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSegmentModal;
