import React, { useEffect, useState } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Col, Row, Image } from "react-bootstrap";
import { initialDate } from "../constant/date-range-constant";
import TextPillCard from "../components/TextPillCard";
import CommentSwiper from "../components/CommentSwiper";
import { findIcon } from "../../../../dashboard/icons";
import { useGetCreativeDetails } from "../../../../../queries/query-hooks/workspace/sponsored-content-hook";
import { useParams } from "react-router-dom";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import CardSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/card-skeleton";
import PageNotFound from "../../../../PageNotFound";
import ImageWithFallback from "../../../../../components/ImageWithFallback";
import { fallbackImages } from "../../../../../common/constants/fallback-images";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import {
  formatLocalizedDate,
  getFormatedDate,
} from "../../../../../common/utils/utils";

function ContentDetails() {
  const [dateRangePayload, setDateRangePayload] = useState({
    start_date: initialDate.start_date,
    end_date: initialDate.end_date,
  });
  const { content_id = "", project_id = "" } = useParams();

  const { data: contentDetails, isLoading: isContentDetailsLoading } =
    useGetCreativeDetails(project_id, {
      content_id: content_id,
    });

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  console.log("contentDetails", contentDetails);
  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: initialDate.end_date,
      });
    }
  }, [projectDetails]);

  return (
    <>
      <Row>
        <Col md={12}>
          {!isProjectDetailsLoading && (
            <Titlebar
              title={"Content Detail"}
              projectType={"Sponsored Content"}
              setDateRangePayload={setDateRangePayload}
              projectStartDate={projectDetails[0]?.project_start_date || ""}
              dateLabel={dateRangePayload}
              showDatePicker={false}
            />
          )}
        </Col>

        <Col
          md={
            !isContentDetailsLoading &&
            contentDetails &&
            contentDetails?.fetch_with_contents &&
            Array.isArray(contentDetails?.fetch_with_contents) &&
            contentDetails?.fetch_with_contents[0]?.content_tags &&
            Array.isArray(
              contentDetails?.fetch_with_contents[0]?.content_tags
            ) &&
            contentDetails?.fetch_with_contents[0]?.content_tags?.length > 0
              ? 7
              : 12
          }
          className="mb-4"
        >
          {!isContentDetailsLoading &&
          Object.keys(contentDetails).length > 0 ? (
            <div className="sponsoredContentCard">
              <Card className="m-0 p-0">
                <Card.Body className="d-flex flex-column flex-md-row gap-2">
                  <div className="cardImage">
                    <ImageWithFallback
                      className="rounded-3 w-100  object-fit-cover"
                      src={
                        contentDetails?.fetch_with_contents[0]
                          ?.content_image_data[0]?.content_image_data?.base
                      }
                      fallbackSrc={fallbackImages?.noImage}
                    />
                  </div>

                  <div className="cardDetails d-flex flex-column w-100 flex-grow-1 ps-2">
                    <div className="detailsHeader d-flex justify-content-between align-items-center gap-3 mb-3">
                      <div className="">
                        <img
                          src={
                            contentDetails?.fetch_with_contents[0]
                              ?.content_category?.content_category_icon
                          }
                          alt=""
                          className="icon-22"
                        />
                        {
                          contentDetails?.fetch_with_contents[0]
                            ?.content_category?.content_category_name
                        }
                      </div>
                      <div className="cardLogo icon-30">
                        <Image
                          className="w-100 h-100 object-fit-contain rounded-circle"
                          src={
                            contentDetails?.fetch_with_contents[0]
                              ?.content_sponsors &&
                            Array.isArray(
                              contentDetails?.fetch_with_contents[0]
                                ?.content_sponsors
                            ) &&
                            contentDetails?.fetch_with_contents[0]
                              ?.content_sponsors[0]?.sponsor_data?.client_logo
                          }
                        />
                      </div>
                    </div>

                    <div>
                      <h6 className="fs-5">
                        {contentDetails?.fetch_with_contents[0]
                          ?.content_title || ""}
                      </h6>
                      <p className="fs-7">
                        {getFormatedDate(
                          contentDetails?.fetch_with_contents[0]?.created_at
                        )}
                      </p>
                      <p className="m-0 text-black-50 two-line-clamp ">
                        {contentDetails?.fetch_with_contents[0]
                          ?.content_description || ""}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ) : isContentDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        {console.log("contentDetails", contentDetails)}

        <Col
          md={5}
          className={`mb-4 ${
            !isContentDetailsLoading &&
            contentDetails &&
            contentDetails?.fetch_with_contents &&
            Array.isArray(contentDetails?.fetch_with_contents) &&
            contentDetails?.fetch_with_contents?.length > 0 &&
            contentDetails?.fetch_with_contents[0]?.content_tags &&
            Array.isArray(
              contentDetails?.fetch_with_contents[0]?.content_tags
            ) &&
            contentDetails?.fetch_with_contents[0]?.content_tags?.length < 1 &&
            "d-none"
          }`}
        >
          {!isContentDetailsLoading &&
          contentDetails &&
          contentDetails?.fetch_with_contents &&
          Array.isArray(contentDetails?.fetch_with_contents) &&
          contentDetails?.fetch_with_contents?.length > 0 &&
          contentDetails?.fetch_with_contents[0]?.content_tags &&
          Array.isArray(contentDetails?.fetch_with_contents[0]?.content_tags) &&
          contentDetails?.fetch_with_contents[0]?.content_tags?.length > 0 ? (
            <TextPillCard
              data={
                contentDetails?.fetch_with_contents[0]?.content_tags &&
                Array.isArray(
                  contentDetails?.fetch_with_contents[0]?.content_tags
                )
                  ? contentDetails?.fetch_with_contents[0]?.content_tags?.map(
                      (item) => {
                        return { name: item?.tag_data?.content_tag_name };
                      }
                    )
                  : []
              }
              title={"Tags"}
              textSuccess={true}
              bgSecondary={true}
            />
          ) : isContentDetailsLoading ? (
            <SquareChartSkeleton />
          ) : (
            // <PageNotFound
            //   title="No data found"
            //   // message="Upload one to start analyzing data!"
            //   backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            // />
            <></>
          )}
        </Col>

        <Col md={12}>
          {!isContentDetailsLoading &&
          contentDetails &&
          Array.isArray(contentDetails?.fetch_with_contents) &&
          contentDetails?.fetch_with_contents?.length > 0 &&
          contentDetails?.fetch_with_contents[0]?.content_comments &&
          Array.isArray(
            contentDetails?.fetch_with_contents[0]?.content_comments
          ) &&
          contentDetails?.fetch_with_contents[0]?.content_comments?.length >
            0 ? (
            <CommentSwiper
              comments={
                contentDetails &&
                Array.isArray(contentDetails?.fetch_with_contents) &&
                contentDetails?.fetch_with_contents?.length > 0 &&
                contentDetails?.fetch_with_contents[0]?.content_comments &&
                Array.isArray(
                  contentDetails?.fetch_with_contents[0]?.content_comments
                )
                  ? contentDetails?.fetch_with_contents[0]?.content_comments?.map(
                      (item) => item.comment
                    )
                  : []
              }
              title={"Comments"}
            />
          ) : isContentDetailsLoading ? (
            <SquareChartSkeleton height={130} />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default ContentDetails;
