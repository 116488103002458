import React, { memo, useEffect } from "react";
import SidebarBorderProfileCard from "../../../../components/partials/components/sidebar/sidebar-border-profile-card";
import { Button, Col, Card, Form, Row } from "react-bootstrap";
import FilePicker from "../../../../components/form-components/file-picker";
import ContentDetails from "../../../../components/partials/common/content-details";
import { useFormik } from "formik";
import {
  addFileInitialValues,
  addFileSchema,
  addPDFInitialValues,
  addPDFSchema,
  addPPTInitialValues,
  addPPTSchema,
  adddocxInitialValues,
  adddocxSchema,
  addzipInitialValues,
  addzipSchema,
} from "../constants/add-file";
import uploadService from "../../../../api/services/upload-service";
import Select from "react-select";
import {
  ADD_CONTENT,
  CREATE_CONTENT,
  UPLOAD_FILE,
} from "../../../../api/endpoints/add-content-endpoints";
import BasicQuill from "../../../../plugins/quill-editor";
import toast from "react-hot-toast";
import postService from "../../../../api/services/post-service";
import { getFormatedDate } from "../../../../common/utils/utils";
import * as dayjs from "dayjs";
import AddEnviroment from "./add-enviroment";

const AddFile = ({
  type = "",
  content_category_id,
  formFields,
  content_file_type_id,
  accepts,
  actionData = null,
  onCreateContent,
  brandId = "",
}) => {
  function getInitialValues() {
    if (type === "pdf") return addPDFInitialValues;
    else if (type === "ppt") return addPPTInitialValues;
    else if (type === "docx") return adddocxInitialValues;
    else if (type === "zip") return addzipInitialValues;
    else return addFileInitialValues;
  }

  function getFileSchema() {
    if (type === "pdf") return addPDFSchema;
    else if (type === "ppt") return addPPTSchema;
    else if (type === "docx") return adddocxSchema;
    else if (type === "zip") return addzipSchema;
    else return addFileSchema;
  }
  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: getFileSchema(),
    onSubmit: (value, action) => {
      const payload = {
        content_category_id,
        content_brand_id: value.brand,
        content_featured_status: value.is_featured ? 1 : 0,
        content_title: value.title,
        content_description: value.body,
        content_file_type_id,
        file_type_code: type,
        cleaned_text: "",
        content_privacy_status: value.privacy_status,
        content_publication_date: dayjs(value.publication_date).format(
          "YYYY-MM-DD"
        ),
        content_publishing_status: 1,
        content_to_file: {
          title_html: value.title_html,
          description_html: value.body_html,
          main_file: value.file.name,
        },
        content_to_reference_files: {
          reference_file: [value.referenceFile.name],
        },
        content_to_images: {
          thumbnail: value.thumbnail.name,
          base: value.base.name,
        },
        content_to_sponsor: {
          sponsor_ids: [value.sponsor || "1"],
        },
        content_to_tags: {
          tags_ids: value.tags,
        },
        content_to_attributes: {
          content_question_code: value.question_code,
        },
        content_to_speciality: {
          speciality_ids: value.speciality,
        },
      };
      if (onCreateContent && typeof onCreateContent === "function") {
        onCreateContent(payload);
      } else {
        toast.promise(
          postService(ADD_CONTENT, payload).then((data) => {
            if (!data.isError) {
              if (data.data?.success) {
                resetForm();
              } else {
              }
            }
          }),
          {
            loading: "Uploading...",
            success: "Uploaded Successfully!",
            error: "Something went wrong",
          }
        );
      }
    },
  });
  const {
    touched,
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = formik;
  useEffect(() => {
    if (brandId) {
      setFieldValue("brand", brandId);
    }
  }, []);

  function setFile(files, keyName) {
    if (files.length > 0) {
      let formdata = new FormData();
      [...files].forEach((file) => {
        formdata.append("files[]", file);
      });
      toast.promise(
        uploadService(UPLOAD_FILE, formdata)
          .then((data) => {
            if (data !== null)
              setFieldValue(keyName, {
                name: data.data.data[0].name,
                url: data.data.data[0].url,
              });
          })
          .catch((err) => console.log(err)),
        {
          loading: "Uploading...",
          success: "Uploaded Successfully!",
          error: "Something went wrong",
        }
      );
    } else {
      setFieldValue(keyName, null);
    }
  }
  return (
    <>
      {actionData && (
        <ContentDetails
          brand={
            actionData?.origin?.requisition_brand_id?.principal_entity_name
          }
          date={getFormatedDate(actionData?.created_at)}
          department=""
          title={actionData?.action_name}
          description={actionData?.origin?.requisition_description}
          fileTypes={[]}
          userName={actionData?.action_assign_by?.name}
        />
      )}
      <Col lg="12" className="d-flex">
        <Card className="w-100">
          <Card.Body>
            <Form className="h-100" onSubmit={handleSubmit}>
              <Row className="gap-4 gap-lg-0">
                <Col lg="6">
                  <FilePicker
                    title={
                      values.file !== null
                        ? values.file?.name
                        : type == "file"
                        ? "Add Infographic"
                        : type == "ppt"
                        ? "Add PPT"
                        : type == "pdf"
                        ? "Add PDF"
                        : type == "zip"
                        ? "Add ZIP"
                        : type == "docx"
                        ? "Add DOCX"
                        : type == "xlsx"
                        ? "Add XLSX"
                        : ""
                    }
                    onUpdate={(val) => setFile(val, "file")}
                    accepts={accepts}
                    type={type}
                    source={values.file?.url}
                  />
                  {touched.file && "file" in errors && (
                    <div style={{ color: "red" }}>{errors.file}</div>
                  )}
                  <Row className="gap-4 gap-lg-0 my-4">
                    <Col lg="6">
                      <FilePicker
                        title="Add Cover Photo"
                        onUpdate={(val) => setFile(val, "base")}
                        accepts="image/png, image/gif, image/jpeg"
                        source={values?.base?.url}
                        type="image"
                      />
                      {touched.base && "base" in errors && (
                        <div style={{ color: "red" }}>{errors.base}</div>
                      )}
                    </Col>
                    <Col lg="6">
                      <FilePicker
                        title="Add Thumbnail"
                        onUpdate={(val) => setFile(val, "thumbnail")}
                        accepts="image/png, image/gif, image/jpeg"
                        source={values?.thumbnail?.url}
                        type="image"
                      />
                      {touched.thumbnail && "thumbnail" in errors && (
                        <div style={{ color: "red" }}>{errors.thumbnail}</div>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Form.Group className="form-group mb-4">
                      <Form.Label>Select Tags</Form.Label>
                      <Select
                        options={formFields?.tags?.map(
                          ({ tag_id, tag_name }) => ({
                            value: tag_id,
                            label: tag_name,
                          })
                        )}
                        placeholder="Select Tags"
                        name="tags"
                        isMulti
                        onChange={(value) =>
                          setFieldValue(
                            "tags",
                            value?.map(({ value }) => value) || []
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Select
                        options={(
                          formFields?.specialities || formFields?.specialty
                        )?.map(({ speciality_id, speciality_name }) => ({
                          value: speciality_id,
                          label: speciality_name,
                        }))}
                        isMulti
                        placeholder="Select Speciality"
                        onChange={(selected) =>
                          setFieldValue(
                            "speciality",
                            selected.map(({ value }) => value)
                          )
                        }
                      />
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Is the content featured?"
                        name="is_featured"
                        onChange={(e) =>
                          setFieldValue("is_featured", e.target.checked)
                        }
                      />
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Select
                        className=""
                        id="floatingInput1"
                        name="privacy_status"
                        value={values.privacy_status}
                        onChange={handleChange}
                        isInvalid={
                          touched.privacy_status && !!errors.privacy_status
                        }
                      >
                        <option value="" selected hidden>
                          {" "}
                          Select Privacy Status
                        </option>
                        {formFields &&
                          formFields?.templates &&
                          (formFields?.templates).map((item) => (
                            <option value={item?.template_id}>
                              {item?.template_name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Label htmlFor="floatingInput">
                        Select Privacy Status{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Select
                        className=""
                        id="floatingInput1"
                        name="speciality"
                        value={values.sponsor}
                        onChange={handleChange}
                        isInvalid={touched.sponsor && !!errors.sponsor}
                      >
                        <option value="" selected hidden>
                          {" "}
                          Select Sponsor
                        </option>
                        {formFields &&
                          formFields?.sponsors &&
                          (formFields?.sponsors).map((item) => (
                            <option value={item?.sponsor_id}>
                              {item?.sponsor_name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Label htmlFor="floatingInput">
                        Select Sponsor <span className="text-danger">*</span>
                      </Form.Label>
                    </Form.Floating>

                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="date"
                        placeholder="Publication Date"
                        name="publication_date"
                        value={values.publication_date}
                        onChange={handleChange}
                        isInvalid={
                          touched.publication_date && !!errors.publication_date
                        }
                      />
                      <Form.Label htmlFor="publication_date">
                        Publication Date
                      </Form.Label>
                    </Form.Floating>
                  </Row>
                </Col>
                <Col lg="6">
                  <Row className="h-100 flex-column flex-nowrap">
                    {!brandId && (
                      <Col lg="12" className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            className=""
                            id="floatingInput1"
                            name="brand"
                            value={values.brand}
                            onChange={handleChange}
                            isInvalid={touched.brand && !!errors.brand}
                          >
                            <option value="" selected hidden>
                              {" "}
                              Select Brand
                            </option>
                            {formFields &&
                              formFields?.brands &&
                              formFields?.brands.map((item) => (
                                <option value={item?.principal_entity_id}>
                                  {item?.principal_entity_name}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Label htmlFor="floatingInput">
                            Select Brand <span className="text-danger">*</span>
                          </Form.Label>
                        </Form.Floating>
                      </Col>
                    )}
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        Title <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <div>
                        <BasicQuill
                          name="title"
                          value={values.title}
                          updateData={(value) => {
                            setFieldValue("title", value.rawText);
                            setFieldValue("title_html", value.html);
                          }}

                          // updateData={(e) => setformData(prev => { return { ...prev, article:{...prev.article,title : e} } })}
                        ></BasicQuill>
                        {touched.title && "title" in errors && (
                          <div style={{ color: "red" }}>{errors.title}</div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>
                        Body <span className="text-danger">*</span>{" "}
                      </Form.Label>
                      <div>
                        <BasicQuill
                          name="body"
                          value={values.body}
                          updateData={(value) => {
                            setFieldValue("body", value.rawText);
                            setFieldValue("body_html", value.html);
                          }}
                        ></BasicQuill>
                        {touched.body && "body" in errors && (
                          <div style={{ color: "red" }}>{errors.body}</div>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Floating className="custom-form-floating mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Question Code"
                        name="question_code"
                        value={values.question_code}
                        onChange={handleChange}
                        isInvalid={
                          touched.question_code && !!errors.question_code
                        }
                      />
                      <Form.Label htmlFor="question_code">
                        Question Code
                      </Form.Label>
                    </Form.Floating>
                    <Form.Group className="col-12 form-group">
                      <Form.Label>Add Reference Files</Form.Label>
                      <Form.Control
                        type="file"
                        id="customFile"
                        name="referenceFile"
                        isInvalid={!!errors.referenceFile}
                        onChange={(e) =>
                          setFile(e.target.files, "referenceFile")
                        }
                      />
                    </Form.Group>
                    <Col lg="6">
                      <Form.Floating className="custom-form-floating mb-4">
                        <Form.Control
                          type="number"
                          placeholder="Template ID"
                          name="template_id"
                          value={values.template_id}
                          onChange={handleChange}
                          isInvalid={
                            touched.template_id && !!errors.template_id
                          }
                        />
                        <Form.Label htmlFor="template_id">
                          Template ID
                        </Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="6">
                      <Form.Floating className="custom-form-floating mb-4">
                        <Form.Control
                          type="number"
                          placeholder="Base Likes"
                          name="base_likes"
                          value={values.base_likes}
                          onChange={handleChange}
                          isInvalid={touched.base_likes && !!errors.base_likes}
                        />
                        <Form.Label htmlFor="base_likes">Base Likes</Form.Label>
                      </Form.Floating>
                    </Col>
                    <Col lg="12">
                      <AddEnviroment formik={formik} />{" "}
                    </Col>
                    <Col lg="12" className="d-flex justify-content-end">
                      <Button
                        className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default memo(AddFile);
