export const ONBOARDING_CREATE_TASK_KEY = "ONBOARDING_CREATE_TASK";
export const ONBOARDING_EDIT_DATA = "ONBOARDING_EDIT_DATA";
export const ONBOARDING_STATES = "ONBOARDING_STATES";
export const ONBOARDING_ZONES = "ONBOARDING_ZONES";
export const ONBOARDING_CITIES = "ONBOARDING_CITIES";

export const GET_ONBOARDING_LOG_TYPES = "GET_ONBOARDING_LOG_TYPES";

export const POST_ONBOARDING_TASK_ANALYTICS = "POST_ONBOARDING_TASK_ANALYTICS";
export const POST_ONBOARDING_MEDIUM_ANALYTICS = "POST_ONBOARDING_MEDIUM_ANALYTICS";
export const POST_ONBOARDING_CALL_ANALYTICS = "POST_ONBOARDING_CALL_ANALYTICS";
export const POST_ONBOARDING_CALL_LOG_ANALYTICS = "POST_ONBOARDING_CALL_LOG_ANALYTICS";