import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Badge,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import ReactTable from "../../../../lib/table/react-table";
import dayjs from "dayjs";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../dashboard/icons";
import { CAMPAIGN_LIST_POST } from "../../../../api/endpoints/campaign-endpoints";
import postService from "../../../../api/services/post-service";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import FilterBar from "../../../../components/filter-bar";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";
import Skeleton from "../../../../components/skeleton";
import { FILTER_TYPES, OPERATOR_TYPES } from "../../../../lib/table/constants";
import ActionButtonBar from "../../../../components/partials/common/action-button-bar";

const inputFilters = [
  {
    name: "campaign_name",
    type: FILTER_TYPES.TEXT,
    placeholder: "Campaign Name",
    label: "Campaign Name",
  },
  {
    name: "campaign_description",
    type: FILTER_TYPES.TEXT,
    placeholder: "Campaign Description",
    label: "Campaign Description",
  },
  {
    name: "campaign_distribution_type",

    type: FILTER_TYPES.SELECT,
    placeholder: "Campaign Distribution",
    label: "Campaign Distribution",
    options: [
      { value: "1", label: "Split" },
      { value: "2", label: "all" },
      { value: "3", label: "A/B" },
    ],
  },
  {
    name: "status",
    type: FILTER_TYPES.RADIO,
    placeholder: "Status",
    label: "Status",
    options: [
      { value: "1", label: "Active" },
      { value: "2", label: "Inactive" },
    ],
  },
];

const advancedFilters = [
  {
    name: "campaign_name",
    type: FILTER_TYPES.TEXT,
    placeholder: "Campaign Name",
    label: "Campaign Name",
    operators: OPERATOR_TYPES.IS_OR_IS_NOT,
  },
  {
    name: "campaign_description",
    type: FILTER_TYPES.TEXT,
    placeholder: "Campaign Description",
    label: "Campaign Description",
    operators: OPERATOR_TYPES.IS_OR_IS_NOT,
  },
];

const CampaignListing = () => {
  const [campaignList, setCampaignList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { redirectTo } = useRedirect();

  const post_json = useRef({
    filter: {
      default: "all",
    },
    pagination: {
      limit: 10,
      offset: 0,
    },
  });

  const columns = [
    {
      Header: "",
      accessor: "channels",
      disableFilters: true,
      Cell: ({ row }) => (
        // console.log("my row", row.values?.channels)
        <div className="d-flex flex-column fs-14 gap-3">
          {row.values?.channels.includes("email") &&
            findIcon("Email", "dual-tone", 22)}
          {row.values?.channels.includes("sms") &&
            findIcon("Chat", "dual-tone", 22)}
          {row.values?.channels.includes("whatsapp") &&
            findIcon("Whatsapp", "dual-tone", 22)}
        </div>
      ),
    },
    {
      Header: "Campaign Name",
      accessor: "campaign_name",
      Cell: ({ row }) => (
        // console.log("my row", row)
        <div className="d-flex justify-content-between gap-2">
          <div className="d-flex flex-column">
            <p className="m-0">
              <b>{row.original?.campaign_name}</b>
            </p>
            {/* <p className="m-0">{row.original.campaignName.content}</p> */}
            <p className="m-0">Created by : {row.original?.created_by?.name}</p>
          </div>
          <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body className="d-flex flex-column justify-content-start p-0 gap-2">
                  <Badge
                    pill
                    bg="primary"
                    className="cursor-pointer"
                    onClick={() => {
                      redirectTo(
                        "campaign/campaign-details/" + row.original.id
                      );
                    }}
                  >
                    View report
                  </Badge>
                  <Badge pill bg="warning">
                    Dublicate
                  </Badge>
                  <Badge pill bg="danger">
                    Stop
                  </Badge>
                  {/* <p className="m-0 p-0">View report</p>
                  <p className="m-0 p-0">Dublicate</p>
                  <p className="m-0 p-0">Stop</p> */}
                </Popover.Body>
              </Popover>
            }
          >
            {/* {findIcon("dots-2", "dual-tone")} */}
            <svg
              version="1.1"
              class="icon-22"
              width="22"
              height="22"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 384 384"
              style={{ "enable-background": "new 0 0 384 384;" }}
              xmlSpace="preserve"
            >
              <g>
                <circle cx="192" cy="42.667" r="42.667" fill="currentColor" />
              </g>
              <g>
                <circle cx="192" cy="192" r="42.667" fill="currentColor" />
              </g>
              <g>
                <circle cx="192" cy="341.333" r="42.667" fill="currentColor" />
              </g>
            </svg>
          </OverlayTrigger>
        </div>
      ),
    },
    // {
    //   Header: "Segment",
    //   accessor: "segment",
    //   Cell: ({ row }) => (
    //     console.log("my row", row.values?.segment?.segment_name)
    //     <div className="d-flex flex-column">
    //       {row.values?.segment?.segment_name}
    //     </div>
    //   ),
    // },
    {
      Header: "Start time",
      accessor: "created_at",
      Cell: ({ row }) => (
        // console.log("my row", row.values?.created_at)
        <div className="d-flex flex-column">
          {dayjs(row.values?.created_at).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      Header: "Sent",
      accessor: "sent",
      Cell: ({ row }) => (
        // console.log("my row", row.values?.sent)
        <div className="d-flex flex-column">
          {row.values?.sent ? row.values?.sent : 0}
        </div>
      ),
    },
    {
      Header: "Engaged",
      accessor: "engeged",
      Cell: ({ row }) => (
        // console.log("my row", row.values?.engeged)
        <div className="d-flex flex-column">
          {row.values?.engeged ? row.values?.engeged : 0}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        // console.log("my row", row.values?.status)
        <div className="d-flex align-items-center justify-content-center">
          <Badge
            variant={row.values?.status.id == 1 ? "primary" : "secondary"}
            className="me-2"
          >
            {row.values?.status.value ? row.values?.status.value : "Active"}
          </Badge>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <ActionButtonBar
              buttons={["view", "edit"]}
              row={row}
              onView={(data) => {
                redirectTo("campaign/details/" + row.original.campaign_id);
              }}
              onEdit={() => {
                redirectTo("campaign/edit/" + row.original.campaign_id);
              }}
            />
          </div>
        );
      },
    },
  ];

  const fetchCampaignList = async () => {
    try {
      // setLoading(true);
      const response = await postService(CAMPAIGN_LIST_POST, post_json.current);
      // console.log("email template service ", response.data.data);
      // console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setCampaignList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("USER_SERVICE_ERROR", error);
    }
  };

  useEffect(() => {
    fetchCampaignList();
  }, []);

  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      fetchCampaignList(payload);
    },
    [campaignList, loading, post_json.current]
  );

  return (
    <Fragment>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => redirectTo("campaign/create")}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      {((!loading && campaignList?.length > 0) ||
        (loading && campaignList?.length <= 0)) && (
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
          <div className="d-flex flex-column">
            {loading ? (
              <Skeleton
                variant="text"
                width={100}
                height={20}
                className="mb-2"
              />
            ) : (
              <h3 className="m-0">All Campaings</h3>
            )}
          </div>
          {!loading ? (
            campaignList?.length > 0 ? (
              <div className="d-flex justify-content-end align-items-center rounded flex-wrap gap-3">
                <div className="form-group mb-0 ">
                  <select className="form-select">
                    <option defaultValue>Sort By</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <FilterBar
                  inputs={inputFilters}
                  advancedFilters={advancedFilters}
                />
              </div>
            ) : (
              <></>
            )
          ) : (
            <div className="d-flex justify-content-end align-items-center rounded flex-wrap gap-3">
              <div className="form-group mb-0 ">
                <Skeleton
                  variant="text"
                  width={60}
                  height={30}
                  className="mb-2"
                />
              </div>
              <Skeleton
                variant="text"
                width={60}
                height={30}
                className="mb-2"
              />
            </div>
          )}
        </div>
      )}
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              {!loading ? (
                campaignList?.length > 0 ? (
                  <ReactTable
                    data={campaignList}
                    columns={columns}
                    recordsTotal={totalCount}
                    // isTableStripped={"table table-striped table-hover mb-0"}
                    onTableStateChange={onTableStateChange}
                  />
                ) : (
                  <PageNotFound />
                )
              ) : (
                <CommonTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CampaignListing;
