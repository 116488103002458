import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllAssociation from "../pages/association";
// pages

export const AssociationRouter = [
  {
    path: "/master-management/association",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllAssociation />,
      },
    ],
  },
];
