import React, { Fragment } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import ReactTable from "../../../../lib/table/react-table";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import FilterBar from "../../../../components/filter-bar";

const details = (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-22"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <path
      d="M12.1415 6.5899C11.9075 6.71614 11.7616 6.95618 11.7616 7.21726V11.2827H3.73429C3.32896 11.2827 3 11.604 3 12C3 12.3959 3.32896 12.7172 3.73429 12.7172H11.7616V16.7827C11.7616 17.0447 11.9075 17.2848 12.1415 17.4101C12.3755 17.5372 12.6614 17.5286 12.8875 17.39L20.6573 12.6073C20.8708 12.4753 21 12.2467 21 12C21 11.7532 20.8708 11.5247 20.6573 11.3927L12.8875 6.60998C12.7681 6.5373 12.632 6.5 12.4959 6.5C12.3745 6.5 12.2521 6.5306 12.1415 6.5899Z"
      fill="currentColor"
    ></path>
  </svg>
);

const AllPersonaListing = () => {
  const { redirectTo } = useRedirect();

  const onActionBtnClick = (name) => {
    redirectTo(`hcp-profile/all-persona-details/${name}`);
  };
  const userData = [
    {
      name: "Doctors in Mumbai",
      sources: "DAY807HTV",
      updated: "18-05-23",
      ageGroup: "18-20",
      regionType: "Rural",
      location: "Mumbai",
      creator: "Created by",
      speciality: "MBBS",
      brand: "Medwiki",
      actionDetails: details,
    },
    {
      name: "Medicine Doctors in Kolkata",
      sources: "DAY8HTVV2",
      updated: "10-08-23",
      ageGroup: "20-30",
      regionType: "Urban",
      location: "Kolkata",
      speciality: "Medicine",
      brand: "Medwiki",
      actionDetails: details,
    },
    {
      name: "Doctos in Chennai",
      sources: "DAY807HV2",
      updated: "22-02-23",
      ageGroup: "20-30",
      regionType: "Urban",
      location: "Chennai",
      speciality: "Urology",
      brand: "Medwiki",
      actionDetails: details,
    },
    {
      name: "Cardiology Doctors in Delhi",
      sources: "D807HTVV2",
      updated: "01-04-23",
      ageGroup: "18-20",
      regionType: "Rural",
      location: "Delhi",
      speciality: "Cardiology",
      brand: "Medwiki",
      actionDetails: details,
    },
  ];
  const COLUMNS = [
    {
      Header: "Segment",
      accessor: "name",
    },
    {
      Header: "UTM",
      accessor: "sources",
    },
    {
      Header: "Age Group",
      accessor: "ageGroup",
    },
    {
      Header: "Region Type",
      accessor: "regionType",
    },
    {
      Header: "Speciality",
      accessor: "speciality",
    },
    {
      Header: "Updated",
      accessor: "updated",
    },
    {
      Header: "Action",
      accessor: "actionDetails",
      disableFilters: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Button
            className="btn btn-icon btn-sm rounded-pill"
            onClick={() => onActionBtnClick(row.original.name)}
            role="button"
          >
            <span className="btn-inner">{row.original.actionDetails}</span>
          </Button>
        </div>
      ),
    },
  ];
  const advanceData = [
    { value: "UID", label: "UID", type: "number" },
    {
      value: "Owner",
      label: "Owner",
      type: "string",
      elements: [
        { value: "Owner 1", label: "Owner 1" },
        { value: "Owner 2", label: "Owner 2" },
        { value: "Owner 3", label: "Owner 3" },
      ],
      placeholder: "Select Owner",
    },
    {
      value: "Name",
      label: "Name",
      type: "string",
      placeholder: "Enter name",
    },
    { value: "Age", label: "Age", type: "number" },
    {
      value: "Email",
      label: "Email",
      type: "string",
      placeholder: "Enter mail",
    },
    {
      value: "Phone Number",
      label: "Phone Number",
      type: "string",
      placeholder: "Enter Number",
    },
    {
      value: "Degree",
      label: "Degree",
      type: "string",
      elements: [
        { value: "1", label: "Degree 1" },
        { value: "2", label: "Degree 2" },
        { value: "3", label: "Degree 3" },
      ],
      placeholder: "Select Degree",
    },
    {
      value: "DND",
      label: "DND",
      type: "string",
      elements: [
        { value: "1", label: "Yes" },
        { value: "2", label: "No" },
      ],
      placeholder: "Select DND",
    },
    {
      value: "Gender",
      label: "Gender",
      type: "string",
      elements: [
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
        { value: "3", label: "Others" },
      ],
      placeholder: "Select Gender",
    },

    {
      value: "Register Number",
      label: "Register Number",
      type: "number",
      elements: [
        { value: "1", label: "Register 1" },
        { value: "2", label: "Register 2" },
        { value: "3", label: "Register 3" },
      ],
      placeholder: "Enter reg number",
    },

    {
      value: "User Type",
      label: "User Type",
      type: "string",
      elements: [
        { value: "User Type 1", label: "User Type 1" },
        { value: "User Type 2", label: "User Type 2" },
      ],
      placeholder: "Select User Type",
    },
    {
      value: "Primary Speciality",
      label: "Primary Speciality",
      type: "string",
      elements: [
        { value: "One", label: "One" },
        { value: "Two", label: "Two" },
      ],
      placeholder: "Select Speciality",
    },
    {
      value: "Registration Date",
      label: "Registration Date",
      type: "string",
      elements: [
        {
          name: "start_date",
          type: "date",
          placeholder: "Enter Start Date",
          label: "Start Date",
        },
        {
          name: "end_date",
          type: "date",
          placeholder: "Enter End Date",
          label: "End Date",
        },
      ],
      placeholder: "",
    },
    {
      value: "Region",
      label: "Region",
      type: "string",
      elements: [
        { value: "Region1", label: "Region1" },
        { value: "Region2", label: "Region2" },
        { value: "Region3", label: "Region3" },
      ],
      placeholder: "Select Region",
    },
    {
      value: "City",
      label: "City",
      type: "string",
      elements: [
        { value: "city1", label: "city1" },
        { value: "city2", label: "city2" },
        { value: "city3", label: "city3" },
      ],
      placeholder: "Select City",
    },
    {
      value: "Zone",
      label: "Zone",
      type: "string",
      elements: [
        { value: "zone1", label: "zone1" },
        { value: "zone2", label: "zone2" },
        { value: "zone3", label: "zone3" },
      ],
      placeholder: "Select Zone",
    },
  ];
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">All Persona Listing</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          <div className="form-group mb-0">
            <FilterBar pageType="All-persona" advanceData={advanceData} />
          </div>
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="fancy-table table-responsive rounded">
                <ReactTable
                  data={userData}
                  columns={COLUMNS}
                  recordsPerPage={10}
                  recordsTotal={userData?.length}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AllPersonaListing;
