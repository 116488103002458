import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllActionTypes from "../pages/all-action-types";

// pages

export const ActionTypeRouter = [
  {
    path: "/master-management/action-type",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllActionTypes />,
      },
    ],
  },
];
