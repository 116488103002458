import React from "react";
import Default from "../../../../layouts/dashboard/default";
import CampaignListing from "../pages/campaign-listing";
import CreateCampaign from "../pages/create-campaign";
import CampaignDetails from "../pages/campaign-details";
import CampaignCalendar from "../pages/campaign-calendar";
import CampaignABTesting from "../pages/campain-ab-testing";
import CampaignDashboard from "../pages/campaign-dashboard";
import CampaignJourney from "../pages/campaign-journey";

export const CampaignRouter = [
  {
    path: "campaign",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <CampaignListing />,
      },
      {
        path: "all/:type",
        element: <CampaignListing />,
      },
      {
        path: "create",
        element: <CreateCampaign />,
      },
      {
        path: "edit/:id",
        element: <CreateCampaign />,
      },
      {
        path: "details/:id",
        element: <CampaignDetails />,
      },
      {
        path: "campaign-calendar",
        element: <CampaignCalendar />,
      },
      {
        path: "campaign-ab-testing",
        element: <CampaignABTesting />,
      },
      {
        path: "dashboard",
        element: <CampaignDashboard />,
      },
      {
        path: "journey",
        element: <CampaignJourney />,
      },
    ],
  },
];
