import React from "react";
import ContentDetails from "../../../../components/partials/common/content-details";
import { Card } from "react-bootstrap";
import Masonry from "react-masonry-css";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import { findIcon } from "../../../dashboard/icons";

const RequisitionLeftSection = ({
  brands,
  values,
  selectedFileTypes,
  user,
  vaultContent,
}) => {
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    768: 2,
    500: 1,
  };

  return (
    <>
      <ContentDetails
        brand={
          brands?.find((_) => _.value == values.requisition_brand_id)?.label
        }
        date={values.requisition_duedate}
        title={values.requisition_title}
        fileTypes={selectedFileTypes}
        description={values.requisition_description}
        department={""}
        userName={user?.name}
      />
      {vaultContent?.length > 0 && (
        <>
          <div className="d-flex flex-column">
            <h5 className="mb-3">Content selected for Repurpose</h5>
          </div>
          <Card className="mb-0">
            <Card.Body className="p-3">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {vaultContent?.map((item, _idx) => (
                  <div
                    className="d-flex gap-2 align-items-center p-2 border rounded-3 mb-4 position-relative"
                    key={_idx + 1}
                  >
                    <div className="avatar-40 rounded-3 bg-soft-primary lh-1 d-flex align-items-center justify-content-center flex-shrink-0 p-2">
                      <img
                        className="img-fluid"
                        src={item?.icon || pdf}
                        alt="profile"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = pdf;
                        }}
                      />
                    </div>
                    <h6 className="m-0 textDoubleLine">
                      <small>{item.title}</small>
                    </h6>
                  </div>
                ))}
              </Masonry>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default React.memo(RequisitionLeftSection);
