import React from "react";

// default layout
import Default from "../layouts/dashboard/default";

// Dashboard
import Index from "../views/dashboard/index";
import AlternateDashboard from "../views/dashboard/alternate-dashboard";

// icon pages
import Solid from "../views/dashboard/icons/solid";
import Outline from "../views/dashboard/icons/outline";
import DualTone from "../views/dashboard/icons/dual-tone";

// user pages
import Userprofile from "../views/dashboard/app/user-profile";
import UserAdd from "../views/dashboard/app/user-add";
import UserList from "../views/dashboard/app/user-list";
import UserProfileEdit from "../views/dashboard/app/user-privacy-setting";

// extra
import Admin from "../views/dashboard/admin/admin";

// widget
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import Widgetchart from "../views/dashboard/widget/widgetchart";
import Widgetcard from "../views/dashboard/widget/widgetcard";

// map
import Google from "../views/dashboard/maps/google";

// form
import FormElement from "../views/dashboard/from/form-element";
import FormWizard from "../views/dashboard/from/form-wizard";
import FormValidation from "../views/dashboard/from/form-validation";

// table
import Fancytable from "../views/dashboard/table/fancy-table";
import FixedTable from "../views/dashboard/table/fixed-table";

export const DefaultRouter = [
  {
    path: "/",
    element: <Default />,
    // errorElement: <div>404</div>,
    children: [
      {
        path: "dashboard",
        element: <Index />,
      },
      {
        path: "/dashboard/alternate-dashboard",
        element: <AlternateDashboard />,
      },

      //User
      {
        path: "dashboard/app/user-profile",
        element: <Userprofile />,
      },
      {
        path: "dashboard/app/user-roles",
        element: <UserProfileEdit />,
      },
      {
        path: "dashboard/app/user-add",
        element: <UserAdd />,
      },
      {
        path: "dashboard/app/user-list",
        element: <UserList />,
      },
      // Admin
      {
        path: "dashboard/admin/admin",
        element: <Admin />,
      },
      // Ui-Color

      // Widgets
      {
        path: "dashboard/widget/widgetbasic",
        element: <Widgetbasic />,
      },
      {
        path: "dashboard/widget/widgetchart",
        element: <Widgetchart />,
      },
      {
        path: "dashboard/widget/widgetcard",
        element: <Widgetcard />,
      },
      // Map
      {
        path: "dashboard/map/google",
        element: <Google />,
      },
      // Form
      {
        path: "dashboard/form/form-element",
        element: <FormElement />,
      },
      {
        path: "dashboard/form/form-wizard",
        element: <FormWizard />,
      },
      {
        path: "dashboard/form/form-validation",
        element: <FormValidation />,
      },
      // Table

      {
        path: "dashboard/table/fancy-table",
        element: <Fancytable />,
      },
      {
        path: "dashboard/table/fixed-table",
        element: <FixedTable />,
      },

      // Icon
      {
        path: "dashboard/icon/solid",
        element: <Solid />,
      },
      {
        path: "dashboard/icon/outline",
        element: <Outline />,
      },
      {
        path: "dashboard/icon/dual-tone",
        element: <DualTone />,
      },
    ],
  },
];
