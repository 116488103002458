import React from "react";
import { Button, Table } from "react-bootstrap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const OnboardingProfileDetails = () => {
    const { redirectTo } = useRedirect();
  return (
    <>
      <Table>
        <tbody>
          <tr>
            <td>
              <h6 className="mb-0">Category</h6>
            </td>
            <td className="text-left">Bulk Engagement</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Type</h6>
            </td>
            <td className="text-left">Promotional</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Name</h6>
            </td>
            <td className="text-left">SR_MAY8_TBRECUPD_01</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Flow/Template Id</h6>
            </td>
            <td className="text-left">6458c155d6fc0572c63b6883</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">DLT ID</h6>
            </td>
            <td className="text-left">1107164726356426759</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Sender</h6>
            </td>
            <td className="text-left">CIPLA</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Service type</h6>
            </td>
            <td className="text-left">MedWiki</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Status</h6>
            </td>
            <td className="text-left">Enable</td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Params</h6>
            </td>
            <td className="text-left">
              Recent Updates on TB Treatment Dear Doctor, Click here to register
              -CIPLA
            </td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-center gap-2">
        <Button onClick={() => redirectTo("onboarding/onboarding-task-Approval-listing")}>Approve</Button>
        <Button type="reset" variant="danger" onClick={() => redirectTo("onboarding/manager")}>
          Reject
        </Button>
      </div>
    </>
  );
};

export default OnboardingProfileDetails;
