import React from "react";

const Timeline = ({ engagementReport = [] }) => {
  return (
    <>
      <h4 className="text-center fw-semibold position-relative mb-5">
        <span className="subHeading position-relative">User Engagement</span>
      </h4>

      <div className="iq-timeline m-0 d-flex align-items-center justify-content-between position-relative">
        <ul className="list-inline p-0 m-0 w-100">
          {engagementReport.map((rootItem, rootIndex) => {
            const engagementReportActions = [];
            engagementReportActions.push(
              {
                name: "Touchpoint Sent",
                value: rootItem.touchpoint_sent,
              },
              {
                name: "Delivered",
                value: rootItem.delivered,
              },
              { name: "Sent", value: rootItem.sent },
              { name: "Seen", value: rootItem.seen }
            );

            return (
              <li key={rootIndex}>
                <div
                  className={`time text-center shadow-none ${
                    rootIndex % 2 === 0 ? "bg-primary ps-0" : "bg-success pe-0"
                  }`}
                >
                  <span>{rootItem.engagement_date}</span>
                </div>
                <div className="content">
                  <div
                    className={`timeline-dots new-timeline-dots ${
                      rootIndex % 2 === 0 ? "border-primary" : "border-success"
                    }`}
                  ></div>
                  <h5 className="mb-1">{rootItem.title}</h5>
                  {rootItem.entry_content && (
                    <div className="d-inline-block w-100 text-dark py-2">
                      <p className="text-start m-0 fs-6">
                        {rootItem.entry_content}
                      </p>
                    </div>
                  )}
                  <div
                    className={`d-flex gap-3 ${
                      rootIndex % 2 === 0
                        ? "justify-content-start justify-content-md-end"
                        : "justify-content-start"
                    }`}
                  >
                    {engagementReportActions?.map((item, index) => {
                      if (item.value === undefined) return;
                      if (
                        item.name === "Seen" &&
                        rootItem.channel_name !== "Whatsapp"
                      )
                        return;

                      return (
                        <p className="m-0 fs-7" key={index}>
                          <span
                            className={`${
                              rootIndex % 2 === 0
                                ? "text-primary"
                                : "text-success"
                            } fw-semibold`}
                          >
                            {item.value}{" "}
                          </span>
                          {item.name}
                        </p>
                      );
                    })}
                  </div>
                  {rootItem.channel_name && (
                    <div
                      className={`badge bg-light bg-opacity-25 text-center fw-bold rounded-pill mb-3 mt-3 px-3 py-1 lh-base ${
                        rootIndex % 2 === 0 ? "text-primary" : "text-success"
                      }`}
                    >
                      <span>{rootItem.channel_name}</span>
                    </div>
                  )}
                  {rootItem.template && (
                    <div className="d-inline-block w-100 bg-light bg-opacity-10 p-3 text-dark rounded-3">
                      <p className="text-start m-0 fs-6">{rootItem.template}</p>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Timeline;
