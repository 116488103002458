import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import DropDownMenu from "../DropDownMenu";
import { channelReachFilterOptions, dateFilterOptions } from "../../Constant/date-filter-constant";
import { GRAPH_FILL_COLOR } from "../../Constant/graph-color-scheme";
import DashboardGraphSkeleton from "../../../../../../../skeleton/workspace/doctube/channel/dashboard-graph-skeleton";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetChannelReach } from "../../../../../../../queries/query-hooks/workspace/channel-video-hook";

function ChannelReach() {
  const { channel_id } = useParams();


  const [reachData, setReachData] = useState([])
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDateFilterOption = dateFilterOptions.find(
    (item) => item.initial
  );
  const sortOption = searchParams.get("datefilter") || initialDateFilterOption.value;

  const { data: channelReachData, isLoading: isChannelReachLoading } = useGetChannelReach(channel_id, () => { });
  const [payload, setPayload] = useState({
    type: dateFilterOptions.initial,
  });

  const handleDropdownChange = (key, item) => {
    setSearchParams({ ...searchParams, [item]: key });
  }

  function transformGraphData(data, seriesKey, labelKey) {
    if (!data || data?.length <= 0) {
      return { series: [], label: [] };
    } else {
      return data.reduce(
        (acc, item) => ({
          series: [...acc.series, parseInt(item[seriesKey])],
          label: [...acc.label, item[labelKey]],
        }),
        { series: [], label: [] }
      );
    }
  }


  let channelAnalytics = {
    options: {
      colors: GRAPH_FILL_COLOR,
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      markers: {
        size: [3, 3],
      },
      xaxis: {
        categories:
          transformGraphData(reachData, "views", "level")?.label?.length >
            0
            ? transformGraphData(reachData, "views", "level").label
            : [],
      },
      yaxis: [
        {
          title: {
            text: "Views",
          },
        },
        // {
        //   opposite: true,
        //   title: {
        //     text: "Subscribers",
        //   },
        // },
      ],
    },
    series: [
      {
        name: "Views",
        data:
          transformGraphData(reachData, "views", "level")?.series
            ?.length > 0
            ? transformGraphData(reachData, "views", "level").series
            : [],
      },
      // {
      //   name: "Subscribers",
      //   data:
      //     transformGraphData(reachData, "subscriber_count", "level")?.series
      //       ?.length > 0
      //       ? transformGraphData(reachData, "subscriber_count", "level").series
      //       : [],
      // },
    ],
  };

  useEffect(() => {
    if (channelReachData && Object.keys(channelReachData)?.length > 0) {
      setReachData(channelReachData[sortOption])
    }
  }, [channelReachData, sortOption])


  if (isChannelReachLoading) {
    return <DashboardGraphSkeleton />;
  }

  return (
    <>
      <Card className="flex-grow-1">
        <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
          <Card.Title>
            <h5 className="mb-0 fw-semibold"> Channel Reach</h5>
            {/* <p className="mb-0 fs-7 text-capitalize mt-2">{payload.type}</p> */}
          </Card.Title>
          <DropDownMenu
            title="datefilter"
            constantFilterOption={channelReachFilterOptions}
            handleDropdownChange={handleDropdownChange}
          />
        </Card.Header>
        <Card.Body className="p-2 p-md-4">
          <div id="chart">
            <Chart
              options={channelAnalytics.options}
              series={channelAnalytics?.series}
              type="line"
              height={350}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default ChannelReach;
