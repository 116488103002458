import React, { Fragment, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import ChannelCreateCampaignModal from "../components/campaign/ChannelCreateCampaignModal";
import CampaignCard from "../components/campaign/CampaignCard";
import {
  useGetCampaignList,
  useGetCampaignListByChannel,
} from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import ReactTable from "../../../../../../lib/table/react-table";
import ActionButtonBar from "../../../../../../components/partials/common/action-button-bar";
import CampaignListSkeleton from "../../../../../../skeleton/workspace/doctube/channel/campaign-list-skeleton";
import { findIcon } from "../../../../../dashboard/icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import PageNotFound from "../../../../../PageNotFound";
import useCopyToClipboard from "../../../../../../components/custom-hooks/useCopyToClipboard";
import { fallbackImages } from "../../../../../../common/constants/fallback-images";
import { changeTableStateToPayload } from "../../../../../../common/utils/utils";

function ChannelCampaign() {
  const { redirectTo } = useRedirect();
  const { channel_id } = useParams();

  const copyToClipboard = useCopyToClipboard();
  const [campaignListPayload, setCampaignListPayload] = useState({
    filter: {
      // content_id: {
      //   type: "eq",
      //   value: channel_id,
      // },
    },
    pagination: {
      limit: 5,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
    channel_id: channel_id,
  });

  const { data, isLoading: isCampaignListLoading } =
    useGetCampaignListByChannel(campaignListPayload);

  const { rows: campaignList, totalCount } = data || {
    rows: [],
    totalCount: 0,
  };

  const [modalShow, setModalShow] = useState(false);

  const CAMPAIGN_COLUMNS = [
    {
      columnId: 1,
      Header: "Content",
      accessor: "campaign_name",
      disableFilters: false,
      Cell: ({ row, value }) => {
        const { campaign, content, content_category_id } = row.original || {
          campaign: "",
          content: "",
          content_category_id: "",
        };
        const { content_to_image, content_title } = content || {
          content_to_image: [],
          content_title: "",
        };
        return (
          <div className="videoThumbnail d-flex gap-3">
            {content_to_image?.length > 0 && (
              <div className="ratio-16x9 w-rem-10 bg-dark rounded-3 d-flex justify-content-center flex-shrink-0 overflow-hidden">
                <img
                  src={content_to_image[0]?.content_image?.thumbnail}
                  alt="1"
                  // className="rounded h-rem-5 w-auto"
                  // className="rounded w-rem-10 w-auto object-fit-cover"
                  className="h-rem-6 w-auto mw-100 object-fit-cover"
                  onError={(e) => {
                    e.target.src = fallbackImages.noVideo;
                  }}
                />
              </div>
            )}
            <p className="m-0 d-flex align-items-center text-black text-uppercase two-line-clamp channel-campaign-title">
              {content_title && (
                <p
                  className="m-0 text-uppercase text-truncate"
                  title={content_title}
                >
                  {content_title}{" "}
                  {content_category_id?.content_category_code ==
                    "doctube_channel" && (
                    <span className="text-success">(Channel)</span>
                  )}
                </p>
              )}
            </p>
          </div>
        );
      },
    },
    {
      Header: "Short link",
      accessor: "url_key",
      columnId: 2,
      disableFilters: false,
      Cell: ({ value }) => value?.default_short_url,
    },
    {
      Header: "Campaign Name / UTM",
      accessor: "campaign",
      columnId: 3,
      disableFilters: true,
    },
    {
      Header: "Date",
      accessor: "created_at",
      columnId: 4,
      disableFilters: true,
    },
    {
      columnId: 5,
      Header: "Clicks",
      accessor: "shortlink_analytics",
      disableFilters: true,
      Cell: ({ value }) => {
        return value?.at(0)?.total_clicks || 0;
      },
    },
    {
      columnId: 6,
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row, value }) => {
        const { url_key, content } = row.original || {
          url_key: "",
          content: "",
        };
        return (
          <ActionButtonBar
            buttons={["analytics", "copy"]}
            row={row}
            onAnalytics={(data) => {
              redirectTo(
                `channel/campaign/analytics/${data?.url_key?.url_key}/${channel_id}`
              );
            }}
            onCopy={(data) => {
              copyToClipboard(url_key?.default_short_url);
            }}
          />
        );
      },
    },
  ];

  const onTableStateChange = (state) => {
    console.log("STATE", state);
    let payload = changeTableStateToPayload(state);
    console.log("PAYLOAD", payload);

    // content_id: {
    //   type: "eq",
    //   value: channel_id,
    // },

    // setCampaignListPayload((pre)=>{

    // })

    // setCampaignListPayload((prev) => ({
    //   ...prev,
    //   ...payload,
    // }));
  };
  console.log("campaignListPayload", campaignListPayload);
  const onUploadFile = () => {
    redirectTo("channel/file-upload/" + channel_id);
  };

  // skeleton
  // if (isCampaignListLoading) {
  //   return <CampaignListSkeleton />;
  // }

  return (
    <>
      <div className="d-flex flex-column flex-md-row mb-4">
        <h4 className="fw-semibold mb-3 mb-md-0">Campaign Link</h4>
        <div className="ms-auto d-flex align-items-center">
          <Link
            to={`/channel/videos/${channel_id}`}
            className="text-decoration-underline me-4"
          >
            Browse by video{" "}
            <span className="transform-rotate-45">
              {findIcon("Arrow Up", "outline", 20)}
            </span>
          </Link>
          <Button
            variant="success"
            onClick={() => {
              setModalShow(true);
            }}
          >
            {/* Create Campaign */}
            Create Shortlink
          </Button>
        </div>
      </div>
      {!isCampaignListLoading && campaignList?.length > 0 ? (
        <>
          <Card>
            <Card.Body>
              {campaignList?.length > 0 && totalCount > 0 && (
                <ReactTable
                  data={campaignList}
                  columns={CAMPAIGN_COLUMNS}
                  onTableStateChange={onTableStateChange}
                  recordsTotal={totalCount}
                  initialState={campaignListPayload}
                  // isRowSelectionAvailable={true}
                  onRowSelectionChange={
                    (selectedList) => {}
                    // onRowSelectionChange(selectedList)
                  }
                  recordsPerPage={campaignListPayload.pagination.limit}
                />
              )}
            </Card.Body>
          </Card>
        </>
      ) : isCampaignListLoading ? (
        <CampaignListSkeleton />
      ) : (
        <PageNotFound
          title="Let's launch your audience-growing campaign!"
          buttonText="Upload Video"
          message="Upload a video to showcases your brand or yourself. Grow your audience and reach new heights !"
          backgroundImage={findIcon("Launch-campaign", "dual-tone", 100)}
          onFallbackClick={onUploadFile}
        />
      )}
      <ChannelCreateCampaignModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ChannelCampaign;
