import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Row, Form, Table, Col, Card, Image } from "react-bootstrap";
import ReactEmailEditor from "../../../../../lib/email-editor/react-email-editor";
import sample from "../../../../../lib/email-editor/sample.json";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { TEMPLATE_BANK_DETAILS_GET } from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import { findIcon } from "../../../../dashboard/icons";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

import GrapejsEditor from "../../../../../lib/email-editor/grapejs-editor";

const BankDetails = () => {
  const { redirectTo } = useRedirect();

  const [loading, setLoading] = useState(false);
  const [isInPreviewMode, setIsInPreviwewMode] = useState(true);
  const [bankId, setBankId] = useState("");
  const [bankDetails, setBankDetails] = useState({});
  const emailEditorRef = useRef(null);
  const [templateContent, setTemplateContent] = useState("");
  const [presetHTML, setPresetHTML] = useState(null);
  const [editor, setEditor] = useState(null);

  const params = useParams();

  useEffect(() => {
    const id = params.id;
    setBankId(id);
    fetchBankDetails(id);
  }, []);

  const fetchBankDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getService(TEMPLATE_BANK_DETAILS_GET + `/${id}`);
      // console.log("BANK_DETAILS_GET", response.data);
      if (response.data.success == true) {
        setBankDetails(response.data.data);
        setLoading(false);
        let json_content = response.data.data?.bank_json;
        onLoad(json_content);
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };

  let object = {
    counters: {
      u_column: 1,
      u_row: 1,
      u_content_heading: 1,
    },
    body: {
      id: "-WreHG4CYz",
      rows: [
        {
          id: "IyojXk41KV",
          cells: [1],
          columns: [
            {
              id: "MhxQpWK1YT",
              contents: [
                {
                  id: "yw_Pbl4-7_",
                  type: "heading",
                  values: {
                    containerPadding: "10px",
                    anchor: null,
                    headingType: "h1",
                    fontSize: "22px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    displayCondition: null,
                    _meta: {
                      htmlID: "u_content_heading_1",
                      htmlClassNames: "u_content_heading",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: "testing email with variable {{Short Link}}",
                  },
                },
              ],
              values: {
                backgroundColor: null,
                padding: "0px",
                border: [],
                _meta: {
                  htmlID: "u_column_1",
                  htmlClassNames: "u_column",
                },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: null,
            columnsBackgroundColor: null,
            backgroundImage: {
              url: null,
              fullWidth: true,
              repeat: "no-repeat",
              size: "custom",
              position: "center",
            },
            padding: "0px",
            anchor: null,
            hideDesktop: false,
            _meta: {
              htmlID: "u_row_1",
              htmlClassNames: "u_row",
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      values: {
        popupPosition: "center",
        popupWidth: "600px",
        popupHeight: "auto",
        borderRadius: "10px",
        contentAlign: "center",
        contentVerticalAlign: "center",
        contentWidth: "500px",
        fontFamily: {
          label: "Arial",
          value: "arial,helvetica,sans-serif",
        },
        textColor: "#000000",
        popupBackgroundColor: "#FFFFFF",
        popupBackgroundImage: {
          url: null,
          fullWidth: true,
          repeat: "no-repeat",
          size: "cover",
          position: "center",
        },
        popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
        popupCloseButton_position: "top-right",
        popupCloseButton_backgroundColor: "#DDDDDD",
        popupCloseButton_iconColor: "#000000",
        popupCloseButton_borderRadius: "0px",
        popupCloseButton_margin: "0px",
        popupCloseButton_action: {
          name: "close_popup",
          attrs: {
            onClick:
              "document.querySelector('.u-popup-container').style.display = 'none';",
          },
        },
        backgroundColor: "#e7e7e7",
        backgroundImage: {
          url: null,
          fullWidth: true,
          repeat: "no-repeat",
          size: "custom",
          position: "center",
        },
        preheaderText: null,
        linkStyle: {
          body: true,
          linkColor: "#0000ee",
          linkHoverColor: "#0000ee",
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        _meta: {
          htmlID: "u_body",
          htmlClassNames: "u_body",
        },
      },
    },
    schemaVersion: 15,
  };

  const onEditClick = () => {
    redirectTo("template-management/create-bank/" + bankId);
  };

  const onCloseClick = () => {
    window.history.go(-1);
  };

  const onLoad = (json_content) => {
    console.log("response.data.data", json_content);
    if (json_content) {
      setPresetHTML(JSON.stringify(json_content));
      //emailEditorRef.current?.editor?.loadDesign(json_content);
    }
  };

  const onReady = () => {
    // emailEditorRef.current?.editor?.showPreview("desktop");
  };

  const togglePreview = () => {
    if (isInPreviewMode) {
      emailEditorRef.current?.editor?.hidePreview();
      setIsInPreviwewMode(false);
    } else {
      emailEditorRef.current?.editor?.showPreview("desktop");
      setIsInPreviwewMode(true);
    }
  };
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row className="m-0">
                <Col
                  lg="12"
                  className="border border-1 rounded-3 p-0 d-flex flex-column"
                >
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <h6 className="mb-0">Name</h6>
                        </td>
                        <td className="text-left">{bankDetails.bank_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Created By</h6>
                        </td>
                        <td className="text-left">
                          {bankDetails.created_by?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Status</h6>
                        </td>
                        <td
                          className={
                            bankDetails.status?.value == "Enabled"
                              ? "text-left text-success"
                              : "text-left text-danger"
                          }
                        >
                          {bankDetails.status?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Bank Image</h6>
                        </td>
                        <td className="text-left">
                          <Image
                            src={bankDetails.bank_image}
                            className="theme-color-default-img  img-fluid  avatar-100"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Col lg={12}>
                    <h6 className="mb-2 px-4">Content</h6>
                    <GrapejsEditor
                      ref={emailEditorRef}
                      onReady={onReady}
                      onLoad={onLoad}
                      previewMode
                      presetData={presetHTML}
                      setEditor={(e) => setEditor(e)}
                      editor={editor}
                    />
                  </Col>
                </Col>
                <div className="d-flex justify-content-between py-4">
                  <Button variant="secondary" onClick={onCloseClick}>
                    {findIcon("Arrow Left", "outline", "32")}
                  </Button>
                  <Button variant="primary" onClick={onEditClick}>
                    Edit
                  </Button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BankDetails;
