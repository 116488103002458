import React from "react";
import { Col, Row } from "react-bootstrap";

import useRedirect from "../../../../components/custom-hooks/use-redirect";

import TaskAnalytics from "../components/TaskAnalytics";
import SearchMdAnalytics from "../components/SearchMediumAnalytics";
import CallAnalytics from "../components/CallAnalytics";
import CallLogAnalytics from "../components/CallLogAnalytics";

const OnboardingDashboard = () => {
  const { redirectTo } = useRedirect();

  return (
    <>
      <div className="d-flex mb-4">
        <h3>Onboarding Dashboard</h3>
      </div>
      <Col lg="12">
        <Row>
          <Col lg="6" className="d-flex p-0 px-md-3">
            <TaskAnalytics />
          </Col>

          <Col lg="6" className="d-flex p-0 px-md-3">
            <CallAnalytics />
          </Col>

          <Col lg="6" className="d-flex p-0 px-md-3">
            <CallLogAnalytics />
          </Col>

          <Col lg="6" className="d-flex p-0 px-md-3">
            <SearchMdAnalytics />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default OnboardingDashboard;
