import { Fragment, useState, useRef } from "react";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import DatamapsIndia from "react-datamaps-india";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import {
  useGetChannelAllVideoList,
  useGetChannelDetails,
  useGetChannelStateAnalytics,
} from "../../../../../../queries/query-hooks/workspace/workspace-hook";
import ChannelStatisticsSkeleton from "../../../../../../skeleton/workspace/doctube/channel/channel-statistics-skeleton";
import { findIcon } from "../../../../../dashboard/icons";
import { dateFilterOptions } from "../Constant/date-filter-constant";
import ChannelCreateCampaignModal from "../components/campaign/ChannelCreateCampaignModal";
import ChannelReach from "../components/dashboard/ChannelReach";
import ChannelStatistics from "../components/dashboard/ChannelStatistics";
import CommentApproval from "../components/dashboard/CommentApproval";
import MyVideos from "../components/dashboard/MyVideos";
import UploadVideo from "../components/dashboard/UploadVideo";
import { useGetChannelEditData } from "../../../../../../queries/query-hooks/workspace/channel-video-hook";
import { findValueByField } from "../../../../../../common/utils/utils";
import toast from "react-hot-toast";
import { selectProject } from "../../../../../../store/project/project.selector";
import { useSelector } from "react-redux";

export default function ChannelDashboard() {
  const { channel_id } = useParams();
  const { redirectTo } = useRedirect();
  const [searchParams, setSearchParams] = useSearchParams();
  const project = useSelector(selectProject);
  const { data: channelEditData, isFetching: isLoading } =
    useGetChannelEditData(channel_id, () => {});

  const { channel_detail } = channelEditData || { channel_detail: "" };
  console.log("channel detil", channel_detail);
  const initialDateFilterOption = dateFilterOptions.find(
    (item) => item.initial
  );

  const { data: stateAnalyticsData, isLoading: isStateLoading } =
    useGetChannelStateAnalytics({
      channel_id: channel_id,
      type: "last_year",
      analysis_type: "audience",
      project_id: project?.project_id,
    });

  const allVideoRef = useRef({
    default: "all",
    pagination: {
      limit: 10,
      offset: 0,
    },
    filter: {
      content_category_id: {
        type: "eq",
        value: "30",
      },
      status: {
        type: "eq",
        value: 1,
      },
      // ...selectedFilter,
    },
    join: {
      content_to_contents: {
        reletion: {
          prefix: "content_id",
          suffix: "content_child_id",
        },
        condition: {
          content_parent_id: {
            type: "eq",
            value: channel_id,
          },
        },
      },
    },
    sort: {
      content_id: "DESC",
    },
    project_id: project?.project_id,
    video_type: 1, //reel = 0 || video = 1
    parent_id: channel_id, //437//328
  });
  const { data: channelVideoData, isLoading: isChannelVideoLoading } =
    useGetChannelAllVideoList(allVideoRef.current);

  console.log("channel video data", channelVideoData);
  const [modalShow, setModalShow] = useState(false);
  const { data: channelDetailsData, isLoading: isDetailsLoading } =
    useGetChannelDetails(channel_id);

  const {
    channel_name,
    views_count,
    content_count,
    channel_logo,
    recent_subscriber_count,
    chnnale_cover_image,
    unapproved_comment_count,
  } = channelDetailsData || {
    channel_name: "",
    views_count: 0,
    content_count: 0,
    channel_logo: "",
    recent_subscriber_count: 0,
    chnnale_cover_image: "",
    unapproved_comment_count: 0,
  };

  const onCampaignClick = () => {
    setModalShow(!modalShow);
  };

  const onRequestContentClick = () => {
    redirectTo(`channel/request-conetnt/${channel_id}`);
  };

  const formatMapData = (mapData) => {
    const output = {};
    for (const [key, value] of Object.entries(mapData)) {
      const formattedKey = key.replace(/_/g, " ");
      output[formattedKey] = {
        value: value || 0,
      };
    }
    return output;
  };

  const onAudienceClick = () => {
    toast.success("No Analytics Available");
  };

  return (
    <Fragment>
      <>
        <Row className="mb-4 mx-n2 mt-n2 ">
          <Col className="px-2 mt-3" md={12}>
            <h4 className="fw-semibold mb-0">Channel Dashboard</h4>
          </Col>

          <Col className="px-2 mt-3" xs={12} lg={4} sm={6}>
            {isDetailsLoading ? (
              <ChannelStatisticsSkeleton />
            ) : (
              <ChannelStatistics
                subscriberCount={content_count}
                viewsCount={views_count}
                key={"channel_statistics"}
              />
            )}
          </Col>

          <Col className="px-2 mt-3" xs={12} lg={4} sm={6}>
            <UploadVideo />
          </Col>

          <Col className="px-2 mt-3" xs={12} lg={4} sm={6}>
            <div
              className="card m-0 p-3 d-flex flex-row gap-3 overflow-hidden border border-soft-primary h-100"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="position-relative">
                <span className="text-primary icon-60 d-inline-flex align-items-center justify-content-center flex-shrink-0 mt-n1 ms-n1">
                  {findIcon("Request Content", "dual-tone", 40)}
                </span>
                <span className="h-rem-6 w-rem-6 position-absolute top-0 start-0 rounded-circle mt-n5 ms-n5 bg-opacity-25 bg-light"></span>
              </div>
              <div className="comment-details d-flex flex-column ps-4">
                <h6 className="mb-2 text-capitalize fw-medium fs-6">
                  Request Content!
                </h6>
                <p className="small-text two-line-clamp">
                  Request team Doctube to deliver high quality audience
                  engagement content
                </p>
                {/* <Link href="#" className="text-decoration-underline ms-auto">Request Now! <span className="transform-rotate-45">{findIcon("Arrow Up", "outline", 15)}</span></Link> */}
                <Button
                  className="ms-auto rounded-3 px-3 mt-auto"
                  variant="primary"
                  size="sm"
                  onClick={onRequestContentClick}
                >
                  Request Now!
                </Button>
              </div>
            </div>
          </Col>

          <Col className="px-2 mt-3" xs={12} lg={4} sm={6}>
            <CommentApproval
              channel_id={channel_id}
              commentCount={unapproved_comment_count}
            />
          </Col>
          <Col className="px-2 mt-3" xs={12} lg={4} sm={6}>
            <div
              className="card p-3 m-0 d-flex flex-row gap-3 overflow-hidden border border-soft-primary h-100"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="position-relative">
                <span className="text-primary icon-60 d-inline-flex align-items-center justify-content-center flex-shrink-0 mt-n1 ms-n1">
                  {findIcon("Video Graph", "dual-tone", 32)}
                </span>

                <span className="h-rem-6 w-rem-6 position-absolute top-0 start-0 rounded-circle mt-n5 ms-n5 bg-opacity-25 bg-light"></span>
              </div>
              <div className="comment-details d-flex flex-column ps-4">
                <h6 className="mb-2 text-capitalize fw-medium fs-6">
                  Dive deeper into GA data{" "}
                </h6>
                <p className="small-text two-line-clamp">
                  Understand your target market better & optimize your campaign.
                </p>
                {findValueByField(channel_detail?.content_to_attr, "ga_url") ? (
                  <Link
                    target="_blank"
                    to={`${findValueByField(
                      channel_detail.content_to_attr,
                      "ga_url"
                    )}`}
                    // to={`/embedded-tools/ga-user-analytics/${btoa(findValueByField(channel_detail.content_to_attr, "ga_url"))}`}
                    className="text-decoration-underline ms-auto mt-auto"
                  >
                    Browse audience insights!{" "}
                    <span className="transform-rotate-45">
                      {findIcon("Arrow Up", "outline", 20)}
                    </span>
                  </Link>
                ) : (
                  <Link
                    onClick={onAudienceClick}
                    className="text-decoration-underline ms-auto mt-auto"
                  >
                    Browse audience insights!{" "}
                    <span className="transform-rotate-45">
                      {findIcon("Arrow Up", "outline", 20)}
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col className="px-2 mt-3" xs={12} lg={4} sm={6}>
            <div
              className="card p-3 m-0 d-flex flex-row gap-3 overflow-hidden border border-soft-primary h-100"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="position-relative">
                <span className="text-primary icon-60 d-inline-flex align-items-center justify-content-center flex-shrink-0 mt-n1 ms-n1">
                  {findIcon("Create Campaign", "dual-tone", 40)}
                </span>
                <span className="h-rem-6 w-rem-6 position-absolute top-0 start-0 rounded-circle mt-n5 ms-n5 bg-opacity-25 bg-light"></span>
              </div>
              <div className="comment-details d-flex flex-column ps-4">
                <h6 className="mb-2 text-capitalize fw-medium fs-6">
                  Start sharing your videos!
                </h6>
                <p className="small-text two-line-clamp">
                  Reach a wider audience with the help of a channel & audience
                  tracking system.
                </p>
                <Button
                  className="ms-auto rounded-3 px-3 mt-auto"
                  variant="primary"
                  size="sm"
                  onClick={onCampaignClick}
                >
                  Generate Share Link!
                </Button>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <MyVideos />
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={4} className="d-flex flex-column pe-2">
            <Card
              className="card h-100"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
                <Card.Title>
                  <h5 className="mb-0 fw-semibold">States of India</h5>
                </Card.Title>

                <Dropdown
                  options={dateFilterOptions}
                  constantFilterOption={dateFilterOptions}
                  handleDropdownChange={(key, item) => {
                    setSearchParams({ ...searchParams, [item]: key });
                  }}
                />
              </Card.Header>
              <Card.Body className="p-1 text-center india-map position-relative india-map-dashboard">
                {stateAnalyticsData?.length > 0 && (
                  <>
                    <DatamapsIndia
                      regionData={formatMapData(stateAnalyticsData[0])}
                      hoverComponent={({ value }) => {
                        return (
                          <div>
                            {value.name}: {value.value ?? 0} %
                          </div>
                        );
                      }}
                      mapLayout={{
                        legendTitle: "Darker colour is higher",
                        startColor: "white",
                        endColor: "#005ce6",
                        hoverTitle: "Total",
                        noDataColor: "#13388C",
                        borderColor: "#15607A",
                        hoverColor: "#15946C",
                        hoverBorderColor: "gray",
                      }}
                    />
                    {/* <div className="p-md-2 fs-6 map-legend">Darker colour is higher</div> */}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} lg={8} className="">
            <ChannelReach />
          </Col>
        </Row>
        <ChannelCreateCampaignModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    </Fragment>
  );
}
