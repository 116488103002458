import React from "react";
import Poll from "./Poll";
import { LIVE_POLL_DATA } from "../data/audience-insights-data";
import PollSkeleton from "../../../../../skeleton/workspace/clirnet/events/PollSkeleton";
import { useGetContentQuestion } from "../../../../../queries/query-hooks/workspace/events-hook";
import { useParams } from "react-router-dom";
import { formatLivePollDataForMasterCluster } from "../utils/utils";

const LivePolls = ({ session_id, eventType }) => {
  const { project_id } = useParams();

  const { data: contentQuestions, isLoading: isContentQuestionsLoading } =
    useGetContentQuestion(project_id);

  const formattedPollDataForMasterCluster =
    eventType === 1
      ? formatLivePollDataForMasterCluster(contentQuestions)
      : contentQuestions;

  return (
    <>
      <h6 className="text-success fw-semibold mb-4">Live Poll</h6>

      {!isContentQuestionsLoading ? (
        <div className="d-flex flex-column gap-3 mx-3">
          {formattedPollDataForMasterCluster?.map((item, index) => {
            return <Poll key={`${index}-live-poll`} poll_data={item} />;
          })}
        </div>
      ) : (
        [1, 2].map((item) => {
          return <PollSkeleton key={item} />;
        })
      )}
    </>
  );
};

export default LivePolls;
