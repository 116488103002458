import React, { useState } from "react";
import { fallbackImages } from "../common/constants/fallback-images";

const ImageWithFallback = (prop) => {
  const { src, fallbackSrc = fallbackImages?.noImage } = prop;
  const [isError, setIsError] = useState(false);

  const handleError = () => setIsError(true);

  return (
    <img {...prop} src={isError ? fallbackSrc : src} onError={handleError} />
  );
};

export default ImageWithFallback;
