import React, { useCallback, useEffect, useState } from "react";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import postService from "../../../../../../api/services/post-service";
import uploadService from "../../../../../../api/services/upload-service";
import { FILE_UPLOAD } from "../../../../../../api/endpoints/requisition-endpoints";
import { CHANNEL_ADD } from "../../../../../../api/endpoints/channel-endpoint";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import useBackdrop from "../../../../../../components/custom-hooks/use-backdrop";
import { useCheckChannelName } from "../../../../../../queries/query-hooks/workspace/channel-video-hook";
import useDebounce from "../../../../../../components/custom-hooks/use-debounce";
import app_config from "../../../../../../common/data/app_config";
import { useParams } from "react-router-dom";
import { useGetProjectDetails } from "../../../../../../queries/query-hooks/workspace/workspace-hook";

const IMAGE_BASE_URL = app_config.upload_file_destination;
const CreateChannel = () => {
  const [channelTitle, setChannelTitle] = useState("");
  const [channelDescription, setChannelDescription] = useState("");
  const [channelAvatar, setChannelAvatar] = useState(null);
  const [channelCover, setChannelCover] = useState(null);
  const [isChannelNameExist, setIsChannelNameExist] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [disabled, setDisabled] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const channelTitleToCheck = useDebounce(channelTitle, 2000);
  const { project_id } = useParams();
  const { data: projectDetails } = useGetProjectDetails(project_id);

  const { data, isLoading } = useCheckChannelName(
    channelTitleToCheck,
    searchEnabled && channelTitleToCheck ? true : false
  );

  useEffect(() => {
    if (data) {
      setIsChannelNameExist(!data.success);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading && searchEnabled) {
      showBackdrop();
    } else {
      hideBackdrop();
    }
  }, [isLoading, searchEnabled]);

  const onBlurChannelNameField = useCallback(() => {
    if (channelTitle && channelTitle.length > 0) {
      setSearchEnabled(true);
    } else {
      setSearchEnabled(false);
    }
  }, [channelTitle, searchEnabled]);

  const handleAvatarChange = (e) => {
    setChannelAvatar(e.target.files[0]);
  };

  const handleCoverChange = (e) => {
    setChannelCover(e.target.files[0]);
  };
  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(FILE_UPLOAD, formdata);
      if (response.data?.success) {
        //console.log("RESPONSE", response);
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.url);
        //console.log("URLS", urls);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }
  const { redirectTo } = useRedirect();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !channelTitle ||
      !channelDescription ||
      !channelAvatar ||
      !channelCover
    ) {
      toast.error("Please field all required fields");
    } else {
      showBackdrop();
      setDisabled(true);
      const urls = await uploadFiles([channelAvatar, channelCover]);
      if (urls?.length > 0) {
        let response = await postService(CHANNEL_ADD, {
          content_title: channelTitle,
          content_privacy_status: "0",
          content_status: "1",
          content_brand_id:
            projectDetails?.length > 0
              ? projectDetails[0].principal_entity_id
              : "2",
          project_id: project_id,
          content_category_id: "31",
          content_category_code: "doctube channel",
          content_publishing_status: "3",
          content_description: channelDescription,
          content_to_images: {
            base: urls[1]?.replace(IMAGE_BASE_URL, ""),
            base_full_url: urls[1],
            thumbnail: urls[0]?.replace(IMAGE_BASE_URL, ""),
            thumbnail_full_url: urls[0],
          },
          dt_payload: {
            channel_name: channelTitle,
            about: channelDescription,
            avatar: urls[0],
            cover: urls[1],
            setting_json: "",
            ga_code: "",
            logo: "",
            logo_position: "",
          },
        });
        if (response) {
          redirectTo("project/channels");
          setDisabled(false);
          hideBackdrop();
          toast.success("Channel Created successfully");
        }
      } else {
        setDisabled(false);
        hideBackdrop();
        toast.error("Please field all required fields");
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col className="p-0 p-md-3 pt-md-0">
          <h4 className="mb-4">Create a New Channel</h4>
          <Card>
            <Card.Body>
              <Form
                onSubmit={handleSubmit}
                className="d-flex flex-column gap-4"
              >
                <Form.Group controlId="formChannelTitle">
                  <Form.Label>Channel Title *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter channel title"
                    value={channelTitle}
                    onChange={(e) => setChannelTitle(e.target.value)}
                    onFocus={() => {
                      setSearchEnabled(false);
                      setIsChannelNameExist(false);
                    }}
                    onBlur={onBlurChannelNameField}
                    isInvalid={isChannelNameExist}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`Channel name ${channelTitle} already exists.`}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formChannelDescription">
                  <Form.Label>Channel Description *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter channel description"
                    value={channelDescription}
                    onChange={(e) => setChannelDescription(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formChannelAvatar">
                  <Form.Label>Channel Avatar *</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                  />
                </Form.Group>
                <Form.Group controlId="formChannelCover">
                  <Form.Label>Channel Cover *</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleCoverChange}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="ms-auto"
                  onClick={handleSubmit}
                  disabled={isChannelNameExist || disabled}
                >
                  {disabled ? "Submiting..." : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(CreateChannel);
