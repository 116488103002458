import { useCallback } from "react";

const useCsvDownloader = () => {
  const downloadCSV = useCallback((data, filename = "data.csv") => {
    const csvData = [];

    // Add the column headers
    let headers = [...data?.header];

    // Assuming the first row's date_Data is representative of all rows
    const dateDataArray =
      data?.rows[0]?.[data?.chart?.column]?.split(",") || [];

    console.log("dateDataArray", data, data?.rows[0], data);
    dateDataArray.forEach((_, index) => {
      headers.push(`${[data?.chart?.header]} Day (${index + 1})`);
    });

    csvData.push(headers.join(","));

    const values = [...data?.column];

    // Add the row data
    data?.rows.forEach((row) => {
      const rowArray = values.map((col) => row[col] || "");

      // Add date_Data values
      const dateDataValues = row?.[data?.chart?.column]?.split(",") || [];
      dateDataValues.forEach((value) => rowArray.push(value));

      csvData.push(rowArray.join(","));
    });

    // Convert the array to a string
    const csvString = csvData.join("\n");

    // Create a Blob object from the CSV string
    const blob = new Blob([csvString], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a filename
    link.download = filename;

    // Create a URL for the Blob and set it as the href attribute
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  }, []);

  return { downloadCSV };
};

export default useCsvDownloader;
