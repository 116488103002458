import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col } from "react-bootstrap";

function BarChartSkeleton({ height = 270, width = "100%" }) {
  return (
    <Card className="flex-grow-1 m-0 mb-4">
      <Card.Header className="d-flex align-items-center justify-content-between">
        <Skeleton variant="text" width={"25%"} />
        <div className="d-flex gap-3">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={70} />
        </div>
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Skeleton
          variant="square"
          width={width}
          height={height}
          className="rounded-3"
        />
      </Card.Body>
    </Card>
  );
}

export default memo(BarChartSkeleton);
