import React, { useState } from "react";
import ChannelCreateCampaignMeta from "../components/channel-campaign-create/ChannelCreateCampaignMeta";
import { Row, Col, Card, Button, Form, Table, Collapse } from "react-bootstrap";
import ChannelCreateCampaignModal from "../components/channel-campaign-create/ChannelCreateCampaignModal";
import toast from "react-hot-toast";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";

function ChannelCreateCampaign() {
  const [CollapseOpen, setCollapseOpen] = useState(true);
  const [createCampaignModdal, setCreateCampaignModal] = useState(false);
  const { redirectTo } = useRedirect();

  const onActionAnalyticsView = (id) => {
    redirectTo(`channel/video/campaign/analytics/${id}`);
  };

  const onActionCampaignView = (id) => {
    redirectTo(`channel/profile/campaign/${id}`);
  };

  function handleShortLinkCopy(e) {
    navigator.clipboard
      .writeText(e)
      .then(() => {
        toast.success("Short link copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("Failed to copy short link to clipboard");
      });
  }

  const table = [
    {
      shortLinkUrl: "https://www.youtube.com",
      utm: "MAHGSDVCY WWEFB",
      date: "../../11/2022",
      clicks: 60,
      views: 89,
      // action: "primary",
    },
  ];

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={12}>
            {!CollapseOpen && (
              <div className="d-flex align-items-center mb-3 justify-content-between">
                <h5>Channel Campaign Link</h5>

                <Button
                  className="flex-shrink-0"
                  variant="success"
                  onClick={() => setCollapseOpen(!CollapseOpen)}
                  aria-controls="collapse-text"
                  aria-expanded={CollapseOpen}
                >
                  {/* Create Campaign */}
                  Create Shortlink
                </Button>
              </div>
            )}
          </Col>

          {/*  */}
          {/*  */}

          <Collapse in={CollapseOpen} className="w-100">
            {/* <CreateCampaign setCollapseOpen={setCollapseOpen} CollapseOpen={CollapseOpen} /> */}

            <Row className="pb-3">
              <Col md={6}>
                <ChannelCreateCampaignMeta />
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="exampleInputText1">
                      Campaign Name{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="exampleInputText1"
                    // defaultValue="Mark Jhon"
                    // placeholder="Enter Name"
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label htmlFor="exampleInputText1">
                      Open Graph Title{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="exampleInputText1"
                    // defaultValue="Mark Jhon"
                    // placeholder="Enter Name"
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label htmlFor="exampleInputText1">
                      Open Graph Description
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="exampleInputText1"
                    // defaultValue="Mark Jhon"
                    // placeholder="Enter Name"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="custom-file-input">
                      Upload file
                    </Form.Label>
                    <Form.Control
                      type="file"
                      id="customFile1"
                      label="Upload file"
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end gap-3 mt-3 ">
                    <Button
                      variant="info"
                      className="flex-shrink-0"
                      // onClick={() => setCollapseOpen(!CollapseOpen)}
                      onClick={onActionCampaignView}
                      aria-controls="collapse-text"
                      aria-expanded={CollapseOpen}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => setCreateCampaignModal(true)}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </Col>
              <ChannelCreateCampaignModal
                closeCurrentModal={() => setCollapseOpen(!CollapseOpen)}
                show={createCampaignModdal}
                onHide={() => setCreateCampaignModal(false)}
              />
            </Row>
          </Collapse>

          {/*  */}
          {/*  */}
        </Row>

        <Row className="pt-4">
          <Col md={12} lg={12}>
            <Table striped responsive className="mb-0">
              <thead>
                <tr>
                  <th>Short Link</th>
                  <th>UTM</th>
                  <th>Date</th>
                  <th>Clicks</th>
                  <th>Views</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {table.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <h6>{item.shortLinkUrl}</h6>
                        </div>
                      </td>

                      <td>{item.utm}</td>
                      <td>
                        <div className="mb-2 d-flex align-items-center">
                          <h6>{item.date}</h6>
                        </div>
                      </td>
                      <td>{item.clicks}</td>
                      <td>{item.views}</td>
                      <td>
                        <span className="icon iq-icon-box-3 rounded-pill m-2">
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon-32"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            onClick={() => {
                              onActionAnalyticsView(index);
                            }}
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.33049 2.00049H16.6695C20.0705 2.00049 21.9905 3.92949 22.0005 7.33049V16.6705C22.0005 20.0705 20.0705 22.0005 16.6695 22.0005H7.33049C3.92949 22.0005 2.00049 20.0705 2.00049 16.6705V7.33049C2.00049 3.92949 3.92949 2.00049 7.33049 2.00049ZM12.0495 17.8605C12.4805 17.8605 12.8395 17.5405 12.8795 17.1105V6.92049C12.9195 6.61049 12.7705 6.29949 12.5005 6.13049C12.2195 5.96049 11.8795 5.96049 11.6105 6.13049C11.3395 6.29949 11.1905 6.61049 11.2195 6.92049V17.1105C11.2705 17.5405 11.6295 17.8605 12.0495 17.8605ZM16.6505 17.8605C17.0705 17.8605 17.4295 17.5405 17.4805 17.1105V13.8305C17.5095 13.5095 17.3605 13.2105 17.0895 13.0405C16.8205 12.8705 16.4805 12.8705 16.2005 13.0405C15.9295 13.2105 15.7805 13.5095 15.8205 13.8305V17.1105C15.8605 17.5405 16.2195 17.8605 16.6505 17.8605ZM8.21949 17.1105C8.17949 17.5405 7.82049 17.8605 7.38949 17.8605C6.95949 17.8605 6.59949 17.5405 6.56049 17.1105V10.2005C6.53049 9.88949 6.67949 9.58049 6.95049 9.41049C7.21949 9.24049 7.56049 9.24049 7.83049 9.41049C8.09949 9.58049 8.25049 9.88949 8.21949 10.2005V17.1105Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>

                        <span className="icon iq-icon-box-3 rounded-pill m-2">
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon-32"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            onClick={() => {
                              handleShortLinkCopy(item.shortLinkUrl);
                            }}
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.1801 4.41C17.1801 3.08 18.2601 2 19.5901 2C20.9201 2 22.0001 3.08 22.0001 4.41C22.0001 5.74 20.9201 6.82 19.5901 6.82C18.2601 6.82 17.1801 5.74 17.1801 4.41ZM13.33 14.7593L16.22 11.0303L16.18 11.0503C16.34 10.8303 16.37 10.5503 16.26 10.3003C16.151 10.0503 15.91 9.8803 15.651 9.8603C15.38 9.8303 15.111 9.9503 14.95 10.1703L12.531 13.3003L9.76 11.1203C9.59 10.9903 9.39 10.9393 9.19 10.9603C8.991 10.9903 8.811 11.0993 8.69 11.2593L5.731 15.1103L5.67 15.2003C5.5 15.5193 5.58 15.9293 5.88 16.1503C6.02 16.2403 6.17 16.3003 6.34 16.3003C6.571 16.3103 6.79 16.1893 6.93 16.0003L9.44 12.7693L12.29 14.9103L12.38 14.9693C12.7 15.1393 13.1 15.0603 13.33 14.7593ZM15.45 3.7803C15.41 4.0303 15.39 4.2803 15.39 4.5303C15.39 6.7803 17.21 8.5993 19.45 8.5993C19.7 8.5993 19.94 8.5703 20.19 8.5303V16.5993C20.19 19.9903 18.19 22.0003 14.79 22.0003H7.401C4 22.0003 2 19.9903 2 16.5993V9.2003C2 5.8003 4 3.7803 7.401 3.7803H15.45Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ChannelCreateCampaign;
