import { Fragment, memo, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import { Link } from "react-router-dom";

//apexcharts
import Chart from "react-apexcharts";

//component
import ChoicesJs from "../../components/choices";

//flatpickr
import Flatpickr from "react-flatpickr";

import img3 from "../../assets/modules/file-manager/images/svg/11.svg";
import img2 from "../../assets/modules/file-manager/images/svg/12.svg";
import img1 from "../../assets/modules/file-manager/images/svg/13.svg";
import img9 from "../../assets/modules/file-manager/images/svg/14.svg";
import img10 from "../../assets/modules/file-manager/images/svg/15.svg";

//img
import table1 from "../../assets/images/table/1.png";
import table2 from "../../assets/images/table/2.png";
import table3 from "../../assets/images/table/3.png";
import table4 from "../../assets/images/table/4.png";
import table5 from "../../assets/images/table/5.png";

// Redux Selector / Action
import { useSelector } from "react-redux";

// Import selectors & action from setting store
import * as SettingSelector from "../../store/setting/selectors";

//Count-up
import CountUp from "react-countup";
import useRedirect from "../../components/custom-hooks/use-redirect";
import withAuthorization from "../../components/hoc/with-authorization";
import Folder from "../unity/content-library/components/folder";

//select
const options = [
  { value: "Past 30 Days", label: "Past 30 Days" },
  { value: "Past 60 Days", label: "Past 60 Days" },
  { value: "Past 90 Days", label: "Past 90 Days" },
  { value: "Past 1 year", label: "Past 1 year" },
  { value: "Past 2 year", label: "Past 2 year" },
];

const options1 = [
  { value: "Select Your Account", label: "Select Your Account" },
  { value: "5521000120354", label: "5521000120354" },
  { value: "5521000125145", label: "5521000125145" },
  { value: "5521000129665", label: "5521000129665" },
];

const options2 = [
  { value: "Enter Amount in USD", label: "Enter Amount in USD" },
  { value: "100", label: "100" },
  { value: "200", label: "200" },
  { value: "500", label: "500" },
];

const Index = memo((props) => {
  const { redirectTo } = useRedirect();
  useSelector(SettingSelector.theme_color);

  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const [checked, setChecked] = useState(true);
  const colors = [variableColors.primary, variableColors.info];
  useEffect(() => {
    return () => colors;
  });

  const chart1 = {
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "28%",
          endingShape: "rounded",
          borderRadius: 3,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 3,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      xaxis: {
        categories: ["S", "M", "T", "W", "T", "F", "S"],
        labels: {
          minHeight: 20,
          maxHeight: 20,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          minWidth: 20,
          maxWidth: 20,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
      responsive: [
        {
          breakpoint: 1025,
          options: {
            chart: {
              height: 130,
            },
          },
        },
      ],
    },
    series: [
      {
        name: "Successful deals",
        data: [30, 50, 35, 60, 40, 60, 60],
      },
      {
        name: "Failed deals",
        data: [40, 50, 55, 50, 30, 80, 30],
      },
    ],
  };
  const chart2 = {
    options: {
      colors: colors,
      chart: {
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 3,
      },
      stroke: {
        width: 3,
      },
      grid: {
        show: true,
        strokeDashArray: 7,
      },
      markers: {
        size: 6,
        colors: "#FFFFFF",
        strokeColors: colors,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 0,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Sales",
        data: [10, 82, 75, 68, 47, 60, 49, 91, 108],
      },
    ],
  };
  const chart3 = {
    options: {
      chart: {
        height: 385,
        type: "radialBar",
      },
      colors: colors,
      plotOptions: {
        radialBar: {
          dataLabels: {
            total: {
              show: true,
              label: "TOTAL",
            },
          },
        },
      },
      labels: ["Indranil Kundu", "Sanjoy Dey", "Sumit Mandal", "Arnab Saha"],
    },
    series: [67, 84, 97, 61],
  };
  const svg1 = (
    <svg
      width="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
        fill="currentColor"
      ></path>
      <path
        d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const table = [
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z"
            fill="currentColor"
          ></path>
          <path
            d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Marcus Family.jpg",
      date: "16 Oct,11:23m",
      size: "12 MB",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Work.Doc",
      date: "20 Nov,12:40pm",
      size: "4.3 MB",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M21.3309 7.44251C20.9119 7.17855 20.3969 7.1552 19.9579 7.37855L18.4759 8.12677C17.9279 8.40291 17.5879 8.96129 17.5879 9.58261V15.4161C17.5879 16.0374 17.9279 16.5948 18.4759 16.873L19.9569 17.6202C20.1579 17.7237 20.3729 17.7735 20.5879 17.7735C20.8459 17.7735 21.1019 17.7004 21.3309 17.5572C21.7499 17.2943 21.9999 16.8384 21.9999 16.339V8.66179C21.9999 8.1623 21.7499 7.70646 21.3309 7.44251Z"
            fill="currentColor"
          ></path>
          <path
            d="M11.9051 20H6.11304C3.69102 20 2 18.3299 2 15.9391V9.06091C2 6.66904 3.69102 5 6.11304 5H11.9051C14.3271 5 16.0181 6.66904 16.0181 9.06091V15.9391C16.0181 18.3299 14.3271 20 11.9051 20Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Marcus Family.jpg",
      date: "16 Oct,11:23m",
      size: "12 MB",
      remove: svg1,
    },
    {
      file: (
        <svg
          width="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M16.3328 22H7.66618C4.2769 22 2 19.6229 2 16.0843V7.91672C2 4.37811 4.2769 2 7.66618 2H16.3338C19.7231 2 22 4.37811 22 7.91672V16.0843C22 19.6229 19.7231 22 16.3328 22Z"
            fill="currentColor"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2451 8.67496C11.2451 10.045 10.1301 11.16 8.7601 11.16C7.3891 11.16 6.2751 10.045 6.2751 8.67496C6.2751 7.30496 7.3891 6.18896 8.7601 6.18896C10.1301 6.18896 11.2451 7.30496 11.2451 8.67496ZM19.4005 14.0876C19.6335 14.3136 19.8005 14.5716 19.9105 14.8466C20.2435 15.6786 20.0705 16.6786 19.7145 17.5026C19.2925 18.4836 18.4845 19.2246 17.4665 19.5486C17.0145 19.6936 16.5405 19.7556 16.0675 19.7556H7.6865C6.8525 19.7556 6.1145 19.5616 5.5095 19.1976C5.1305 18.9696 5.0635 18.4446 5.3445 18.1026C5.8145 17.5326 6.2785 16.9606 6.7465 16.3836C7.6385 15.2796 8.2395 14.9596 8.9075 15.2406C9.1785 15.3566 9.4505 15.5316 9.7305 15.7156C10.4765 16.2096 11.5135 16.8876 12.8795 16.1516C13.8132 15.641 14.3552 14.7673 14.827 14.0069L14.8365 13.9916C14.8682 13.9407 14.8997 13.8898 14.9311 13.8391C15.0915 13.5799 15.2495 13.3246 15.4285 13.0896C15.6505 12.7986 16.4745 11.8886 17.5395 12.5366C18.2185 12.9446 18.7895 13.4966 19.4005 14.0876Z"
            fill="currentColor"
          ></path>
        </svg>
      ),
      name: "Apha.mkv",
      date: "08 Oct,05:45pm",
      size: "10 MB",
      remove: svg1,
    },
  ];

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3>Quick Insights</h3>
          <p className="text-primary mb-0">Dashboard</p>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          <div className="d-flex justify-content-between align-items-center">
            <Form.Check
              className="form-switch mb-0 iq-status-switch"
              type="switch"
              id="flexSwitchCheckChecked"
              defaultChecked={checked}
              onClick={() => setChecked(!checked)}
            />
            <label
              className="form-check-label iq-reset-status "
              htmlFor="flexSwitchCheckChecked"
            >
              {checked === true ? "Online" : "Offline"}
            </label>
          </div>
          <div
            className="form-group mb-0 custom-choicejs"
            style={{ minWidth: "145px" }}
          >
            <ChoicesJs options={options} className="js-choice" select="one" />
          </div>
          <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />
          </div>
          <Button type="button" className="primary">
            Analytics
          </Button>
        </div>
      </div>
      <Row>
        {/* <Col lg="3" md="6">
                    <Card className="card-block card-stretch card-height">
                        <Card.Body>
                            <div className="d-flex align-items-start justify-content-between mb-2">
                                <p className="mb-0 text-dark">Gross Volume</p>
                                <Link className="badge rounded-pill bg-soft-primary" to="#javascript:void(0);">
                                    View
                                </Link>
                            </div>
                            <div className="mb-3">
                                <h2 className="counter">$
                                    <CountUp
                                        start={0}
                                        end={199556}
                                        duration={10}
                                        useEasing={true}
                                        separator=","
                                    />
                                </h2>
                                <small>Last updated 1 hour ago.</small>
                            </div>
                            <div>
                                <Chart options={chart1.options} series={chart1.series} type="bar" height="100%"></Chart>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="3" md="6">
                    <Row>
                        <div className="col-12">
                            <Card className="card-block card-stretch card-height">
                                <Card.Body>
                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                        <span className="text-dark ">USD Balance</span>
                                        <Link className="badge rounded-pill bg-soft-primary" to="#javascript:void(0);">
                                            Request Payout
                                        </Link>
                                    </div>
                                    <h2 className="counter">$
                                        <CountUp
                                            start={0}
                                            end={2590}
                                            duration={10}
                                            useEasing={true}
                                            separator=","
                                        />
                                    </h2>
                                    <small>Available to pay out.</small>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12">
                            <Card className="card-block card-stretch card-height">
                                <Card.Body>
                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                        <span className="text-dark">No Of Payments</span>
                                        <Link className="badge rounded-pill bg-soft-primary" to="#javascript:void(0);">
                                            View
                                        </Link>
                                    </div>
                                    <h2 className="counter">
                                        <CountUp
                                            start={0}
                                            end={367}
                                            duration={10}
                                            useEasing={true}
                                        />
                                    </h2>
                                    <small>Transactions this month</small>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </Col> */}
        <Col lg="6">
          <Row>
            <Col>
              <Card
                onClick={() => redirectTo("requisition/listing")}
                className="cursor-pointer"
              >
                <Card.Body className="bg-soft-danger rounded-3">
                  <div className="d-flex align-items-start gap-3 ">
                    <div className="bg-soft-danger rounded p-3 d-flex align-items-center">
                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                        />
                      </svg>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="fs-6 fw-semibold">Review Pending</span>
                      <h4 className="mt-2 mb-0 fw-semibold">
                        <CountUp start={100} end={300} duration={3} />
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                onClick={() => redirectTo("requisition/completed")}
                className="cursor-pointer"
              >
                <Card.Body className="bg-soft-success rounded-3">
                  <div className="d-flex align-items-start gap-3 ">
                    <div className="bg-soft-success rounded p-3 d-flex align-items-center">
                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                        />
                      </svg>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="fs-6 fw-semibold">Approved</span>
                      <h4 className="mt-2 mb-0 fw-semibold">
                        <CountUp start={10} end={240} duration={3} />
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card
                onClick={() => redirectTo("requisition/in-progress")}
                className="cursor-pointer"
              >
                <Card.Body className="bg-soft-warning rounded-3">
                  <div className="d-flex align-items-start gap-3 ">
                    <div className="bg-soft-warning rounded p-3 d-flex align-items-center">
                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                        />
                      </svg>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="fs-6 fw-semibold">
                        Creation In-progress
                      </span>
                      <h4 className="mt-2 mb-0 fw-semibold">
                        <CountUp start={10} end={150} duration={3} />
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                onClick={() => redirectTo("requisition/in-progress")}
                className="cursor-pointer"
              >
                <Card.Body className="bg-soft-primary rounded-3">
                  <div className="d-flex align-items-start gap-3 ">
                    <div className="bg-soft-primary rounded p-3 d-flex align-items-center">
                      <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M13,2.05C18.05,2.55 22,6.82 22,12C22,13.45 21.68,14.83 21.12,16.07L18.5,14.54C18.82,13.75 19,12.9 19,12C19,8.47 16.39,5.57 13,5.08V2.05M12,19C14.21,19 16.17,18 17.45,16.38L20.05,17.91C18.23,20.39 15.3,22 12,22C6.47,22 2,17.5 2,12C2,6.81 5.94,2.55 11,2.05V5.08C7.61,5.57 5,8.47 5,12A7,7 0 0,0 12,19M12,6A6,6 0 0,1 18,12C18,14.97 15.84,17.44 13,17.92V14.83C14.17,14.42 15,13.31 15,12A3,3 0 0,0 12,9L11.45,9.05L9.91,6.38C10.56,6.13 11.26,6 12,6M6,12C6,10.14 6.85,8.5 8.18,7.38L9.72,10.05C9.27,10.57 9,11.26 9,12C9,13.31 9.83,14.42 11,14.83V17.92C8.16,17.44 6,14.97 6,12Z"
                        />
                      </svg>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="fs-6 fw-semibold">
                        Submission Overdue
                      </span>
                      <h4 className="mt-2 mb-0 fw-semibold">
                        <CountUp start={5} end={160} duration={3} />
                      </h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg="6" md="12">
          <Card>
            <Card.Body>
              <Row>
                <Col md="6">
                  <div className="mb-5">
                    <div className="mb-2 d-flex justify-content-between align-items-center">
                      <span className="text-dark">
                        Total Requisition Raised
                      </span>
                      {/* <Link
                        className="badge rounded-pill bg-soft-primary"
                        to="#javascript:void(0);"
                      >
                        View Report
                      </Link> */}
                    </div>
                    <div className="mb-2">
                      <h2 className="counter">
                        <CountUp
                          start={0}
                          end={1200}
                          duration={10}
                          useEasing={true}
                          separator=","
                        />
                      </h2>
                      <small>This Month</small>
                    </div>
                  </div>
                  <div>
                    <div
                      className="d-flex align-items-center mb-3 cursor-pointer"
                      onClick={() => redirectTo("requisition/in-progress")}
                    >
                      <div className="bg-soft-primary avatar-60 rounded">
                        <svg
                          width="35"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M17.554 7.29614C20.005 7.29614 22 9.35594 22 11.8876V16.9199C22 19.4453 20.01 21.5 17.564 21.5L6.448 21.5C3.996 21.5 2 19.4412 2 16.9096V11.8773C2 9.35181 3.991 7.29614 6.438 7.29614H7.378L17.554 7.29614Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M12.5464 16.0374L15.4554 13.0695C15.7554 12.7627 15.7554 12.2691 15.4534 11.9634C15.1514 11.6587 14.6644 11.6597 14.3644 11.9654L12.7714 13.5905L12.7714 3.2821C12.7714 2.85042 12.4264 2.5 12.0004 2.5C11.5754 2.5 11.2314 2.85042 11.2314 3.2821L11.2314 13.5905L9.63742 11.9654C9.33742 11.6597 8.85043 11.6587 8.54843 11.9634C8.39743 12.1168 8.32142 12.3168 8.32142 12.518C8.32142 12.717 8.39743 12.9171 8.54643 13.0695L11.4554 16.0374C11.6004 16.1847 11.7964 16.268 12.0004 16.268C12.2054 16.268 12.4014 16.1847 12.5464 16.0374Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                      <div className="ms-3" style={{ width: "100%" }}>
                        <div className="d-flex justify-content-between  ">
                          <h6>Acceptance Pending</h6>
                          <h6 className="text-body">523</h6>
                        </div>
                        <div
                          className="progress bg-soft-primary shadow-none w-100"
                          style={{ height: "6px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            data-toggle="progress-bar"
                            role="progressbar"
                            aria-valuenow="70"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: "70%",
                              transition: "width 2s ease 0s",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => redirectTo("requisition/in-progress")}
                    >
                      <div className="bg-soft-primary avatar-60 rounded">
                        <svg
                          width="35"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z"
                            fill="currentColor"
                          ></path>
                          <path
                            opacity="0.4"
                            d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z"
                            fill="currentColor"
                          ></path>
                          <circle
                            cx="18"
                            cy="11.8999"
                            r="1"
                            fill="currentColor"
                          ></circle>
                        </svg>
                      </div>
                      <div className="ms-3" style={{ width: "100%" }}>
                        <div className="d-flex justify-content-between  ">
                          <h6>Submission In-review</h6>
                          <h6 className="text-body">624</h6>
                        </div>
                        <div
                          className="progress bg-soft-info shadow-none w-100"
                          style={{ height: "6px" }}
                        >
                          <div
                            className="progress-bar bg-info"
                            data-toggle="progress-bar"
                            role="progressbar"
                            aria-valuenow="33"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              width: "33%",
                              transition: "width 2s ease 0s",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6" className="mb-5 mb-md-0">
                  <div className="iq-scroller-effect">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <span className="text-dark">Your Team Members</span>
                      {/* <Link
                        to="/dashboard/table/border-table"
                        className="badge rounded-pill bg-soft-primary"
                      >
                        All Contacts
                      </Link> */}
                    </div>
                    <div className="d-flex align-items-center iq-slider mb-2 gap-2">
                      <div>
                        <img
                          className="rounded-circle bg-soft-primary img-fluid avatar-40 mb-2"
                          src={table1}
                          alt="profile"
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <img
                          className="rounded-circle bg-soft-primary img-fluid avatar-40 mb-2"
                          src={table2}
                          alt="profile"
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <img
                          className="rounded-circle bg-soft-primary img-fluid avatar-40 mb-2"
                          src={table3}
                          alt="profile"
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <img
                          className="rounded-circle bg-soft-primary img-fluid avatar-40 mb-2"
                          src={table4}
                          alt="profile"
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <img
                          className="rounded-circle bg-soft-primary img-fluid avatar-40 mb-2"
                          src={table5}
                          alt="profile"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    {/* <div>
                      <div className="form-group custom-choicejs">
                        <ChoicesJs
                          options={options1}
                          className="js-choice"
                          select="one"
                        />
                      </div>
                      <div className="form-group custom-choicejs">
                        <ChoicesJs
                          options={options2}
                          className="js-choice"
                          select="one"
                        />
                      </div>
                    </div>
                    <Button className="btn btn-primary w-100 mt-2">
                      Send Money
                    </Button> */}
                    <div className="d-flex justify-content-between align-items-center px-2 gap-4">
                      <div className="d-flex flex-column justify-content-center gap-2 px-2">
                        <Chart
                          options={chart3.options}
                          series={chart3.series}
                          type="radialBar"
                          height="200"
                        />
                        {/* <h6 className="d-flex flex-column justify-content-center align-items-center">
                          Requisitions by Category
                        </h6> */}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="12" md="12" className="mb-5">
          {/* <Card>
            <Card.Body> */}
          <h3 className="mb-0">Content Category Folder</h3>
          <Row className="row-cols-lg-4 row-cols-md-4 row-cols-1">
            <Folder
              foldername="Infographic"
              foldersvg="1"
              img={img3}
              id="1"
              count={10}
              // bgColor='warning'
              redirection={"content-library/category/infographic"}
            />
            <Folder
              foldername="Ad Board"
              foldersvg="2"
              img={img2}
              id="2"
              count={20}
              // bgColor='info'
              redirection={"content-library/category/ad-board"}
            />
            <Folder
              foldername="Podcast"
              foldersvg="3"
              img={img1}
              id="3"
              count={50}
              // bgColor='danger'
              redirection={"content-library/category/podcast"}
            />
            <Folder
              foldername="LBL"
              foldersvg="4"
              img={img9}
              id="4"
              count={6}
              // bgColor='success'
              redirection={"content-library/category/lbl"}
            />
            <Folder
              foldername="HTML"
              foldersvg="5"
              img={img2}
              id="5"
              count={109}
              // bgColor='warning'
              redirection={"content-library/category/html"}
            />
            <Folder
              foldername="Presentations"
              foldersvg="6"
              img={img10}
              id="6"
              count={15}
              // bgColor='secondary'
              redirection={"content-library/category/presentations"}
            />
            {/* <Folder
              foldername="Ui-Kit"
              foldersvg="7"
              img={img3}
              id="7"
              // bgColor='info'
              redirection={"content-library/category/etc"}
            />
            <Folder
              foldername="Design"
              foldersvg="8"
              img={img3}
              id="8"
              // bgColor='danger'
              redirection={"content-library/category/etc"}
            /> */}
          </Row>
          {/* </Card.Body>
          </Card> */}
        </Col>
        <Col lg="6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <h4>Recently Added Content</h4>
              <Link to="content-library/listing" className="text-primary">
                View all
              </Link>
            </Card.Header>
            <div className="table-responsive">
              <table className="table table-borderless iq-file-manager-table mb-0">
                <thead>
                  <tr className="border-bottom bg-transparent text-dark">
                    <th scope="col">Files</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">Size</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {table.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <span className="avatar-40 bg-soft-primary rounded-pill">
                              {item.file}
                            </span>
                            <h6 className="mb-0">{item.name}</h6>
                          </div>
                        </td>
                        <td>
                          <small className="text-muted">{item.date}</small>
                        </td>
                        <td>
                          <small className="text-primary">{item.size}</small>
                        </td>
                        <td>
                          <Link
                            to="#"
                            className="d-flex align-items-center text-danger"
                          >
                            <span className="btn-inner">{item.remove}</span>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col lg="6">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <h4>My Content Widget</h4>
              <Link to="content-library/listing" className="text-primary">
                View all
              </Link>
            </Card.Header>
            <div className="table-responsive">
              <table className="table table-borderless iq-file-manager-table mb-0">
                <thead>
                  <tr className="border-bottom bg-transparent text-dark">
                    <th scope="col">Files</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">Size</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {table.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <span className="avatar-40 bg-soft-primary rounded-pill">
                              {item.file}
                            </span>
                            <h6 className="mb-0">{item.name}</h6>
                          </div>
                        </td>
                        <td>
                          <small className="text-muted">{item.date}</small>
                        </td>
                        <td>
                          <small className="text-primary">{item.size}</small>
                        </td>
                        <td>
                          <Link
                            to="#"
                            className="d-flex align-items-center text-danger"
                          >
                            <span className="btn-inner">{item.remove}</span>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        {/* <Col lg="6" md="12">
          <Card className="card-block card-stretch card-height">
            <Card.Header>
              <div className=" d-flex justify-content-between  flex-wrap">
                <h4 className="card-title">Net Volumes From Sales</h4>
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    href="#"
                    variant="text-secondary dropdown-toggle"
                    size="sm"
                    id="dropdownMenuButton1"
                  >
                    This year
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">Year</Dropdown.Item>
                    <Dropdown.Item href="#">Month</Dropdown.Item>
                    <Dropdown.Item href="#">Week</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card.Header>
            <Card.Body>
              <Chart
                options={chart2.options}
                series={chart2.series}
                type="line"
                height="100%"
                className="dashboard-line-chart"
              ></Chart>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="6" md="12">
          <Card className="card-block">
            <Tab.Container defaultActiveKey="first">
              <div className="tab-bottom-bordered">
                <Nav
                  className="mb-0 nav-tabs justify-content-around d-flex"
                  id="nav-tab1"
                  role="tablist"
                >
                  <Nav.Link
                    className="py-3 "
                    eventKey="first"
                    data-bs-toggle="tab"
                    data-bs-target="#payment"
                    type="button"
                    role="tab"
                    aria-controls="payment"
                    aria-selected="true"
                  >
                    Payments
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="second"
                    data-bs-toggle="tab"
                    data-bs-target="#set"
                    type="button"
                    role="tab"
                    aria-controls="set"
                    aria-selected="false"
                  >
                    Settlements
                  </Nav.Link>
                  <Nav.Link
                    className="py-3"
                    eventKey="three"
                    data-bs-toggle="tab"
                    data-bs-target="#refund"
                    type="button"
                    role="tab"
                    aria-controls="refund"
                    aria-selected="false"
                  >
                    Refunds
                  </Nav.Link>
                </Nav>
              </div>
              <Tab.Content className="iq-tab-fade-up" id="nav-tabContent">
                <Tab.Pane
                  eventKey="first"
                  id="payment"
                  role="tabpanel"
                  aria-labelledby="payment"
                >
                  <div className="table-responsive">
                    <table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,833</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_vxnnjigakm</td>
                          <td className="text-dark">1 Hour Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success ">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,204</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_uwsxaiuhhs</td>
                          <td className="text-dark">23 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,833</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_taxrcfzhny</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,235</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_pknfotsmhl</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,442</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_xqgczqbgto</td>
                          <td className="text-dark">3 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,924</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_eoasrkizdw</td>
                          <td className="text-dark">4 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="second"
                  id="set"
                  role="tabpanel"
                  aria-labelledby="set"
                >
                  <div className="table-responsive">
                    <Table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,298</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_ufsoishqbw</td>
                          <td className="text-dark">7 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success ">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,032</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_fescijfgbb</td>
                          <td className="text-dark">23 Days </td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,514</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_eihghndltk</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,425</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_bvihnfpdfq</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,838</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_afrtmvdyjp</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,613</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_jterqcvjxz</td>
                          <td className="text-dark">5 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Processed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="three"
                  id="refund"
                  role="tabpanel"
                  aria-labelledby="refund"
                >
                  <div className="table-responsive">
                    <Table
                      id="basic-table"
                      className="table mb-0 table-striped"
                      role="grid"
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,866</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_odqethdqye</td>
                          <td className="text-dark">3 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-info ">
                              Process
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$1,637</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_nmngvsosnh</td>
                          <td className="text-dark">22 Days Ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Refunded
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,922</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_uikgtphcpo</td>
                          <td className="text-dark">1 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Refunded
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,563</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_cieqrdyqkp</td>
                          <td className="text-dark">2 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-info">
                              Process
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,334</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_wmdvzpfavx</td>
                          <td className="text-dark">3 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-success">
                              Refunded
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0">$2,632</h6>
                            </div>
                          </td>
                          <td className="text-primary">hui_jplpprjzbr</td>
                          <td className="text-dark">5 month ago</td>
                          <td className="text-end">
                            <span className="badge rounded-pill bg-danger">
                              Failed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <div className="card-footer text-end">
              <Link to="#javascript:void(0);">
                <span className="me-2">View all Settlements</span>
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 5L15.5 12L8.5 19"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Link>
            </div>
          </Card>
        </Col> */}
      </Row>
    </Fragment>
  );
});

Index.displayName = "Index";
export default withAuthorization(Index);
