import Skeleton from "../../components/skeleton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const UserFormLoader = () => {
  return (
    <>
      <Card className="flex-grow-1">
        <Card.Header className="d-flex justify-content-start align-items-center gap-2">
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton variant="text" width={100} height={20} />
        </Card.Header>
        <Card.Body>
          <div className="mb-4">
            <Skeleton variant="text" width={100} height={20} className="mb-2" />
            <Skeleton
              variant="rounded"
              width="100%"
              height={45}
              className="mb-3"
            />
          </div>
          <div className="mb-4">
            <Skeleton variant="text" width={100} height={20} className="mb-2" />
            <Skeleton
              variant="rounded"
              width="100%"
              height={45}
              className="mb-3"
            />
          </div>
          <div className="mb-4">
            <Skeleton variant="text" width={100} height={20} className="mb-2" />
            <Skeleton
              variant="rounded"
              width="100%"
              height={45}
              className="mb-3"
            />
          </div>

          <div className="d-flex justify-content-center justify-content-md-end">
            <Skeleton
              variant="rounded"
              width="15%"
              height={45}
              className="mb-3"
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserFormLoader;
