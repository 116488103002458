import axios from "axios";
const postServiceSmsGenarator = async (data) => {
  try {
    let response = await axios({
      method: "POST",
      url: "https://asia-south1-clirnet-dev.cloudfunctions.net/sms_wp_email_generator_v1",
      data,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response) {
      // console.log("POST_SERVICE_RESPONSE", response);
      const responseData = response.data ?? [];
      switch (response.status) {
        case 200:
          return { data: responseData, isError: false, error: "" };
        case 201:
          return { data: responseData, isError: false, error: "" };
        case 203:
          return { data: responseData, isError: false, error: "" };
        case 204:
          return { data: responseData, isError: false, error: "" };

        default:
          return {
            data: null,
            isError: true,
            error: "Something went wrong. Please try again",
          };
      }
    }
  } catch (error) {
    switch (error.response.status) {
      case 400:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 401:
        return {
          data: null,
          isError: true,
          error: "Please check your credentials and try again",
        };
      case 403:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 404:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
      case 500:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };

      default:
        return {
          data: null,
          isError: true,
          error: "Something went wrong. Please try again",
        };
    }
  }
};
export default postServiceSmsGenarator;
