import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { formatNumberWithCommas } from "../../common/utils/utils";

function RadialBarChart({
  labels,
  max,
  data,
  title = null,
  height = 190,
  color,
}) {
  const percentage = ((data || 0) / (max || 1)) * 100;

  let chartOptions = {
    series: [percentage],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "68%",
          },
          dataLabels: {
            name: {
              offsetY: -10,
              show: title !== null ? false : true,
              color: "#a8a8a8",
              fontWeight: "normal",
              fontSize: "10px",
              formatter: function (val) {
                return val;
              },
            },
            value: {
              offsetY: 10,
              color: "#5cbd9d",
              fontSize: "20px",
              show: title !== null ? false : true,
              formatter: function (val) {
                return formatNumberWithCommas(data);
              },
            },

            track: {
              show: true,
              background: "#f2f2f2",
              strokeWidth: "97%",
              opacity: 1,
              margin: 5,
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                blur: 4,
                opacity: 0.24,
              },
            },
          },
        },
      },
      fill: {
        colors: [color], // Default color for the radial bar
      },
      labels: labels,
    },
  };

  return (
    <>
      <div
        id="chart"
        className="d-flex align-items-center justify-content-center"
      >
        {title !== null && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h5 style={{ color: color, fontWeight: "bold" }}>
              {" "}
              {formatNumberWithCommas(data)}
            </h5>
            {title}
          </div>
        )}
        <Chart
          options={chartOptions.options}
          series={chartOptions.series}
          type="radialBar"
          height={height}
        />
      </div>
    </>
  );
}

export default RadialBarChart;
