import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../components/dropdowns";
import Chart from "react-apexcharts";
import { usePostOnboardingMediumAnalytics } from "../../../../queries/query-hooks/onboarding-hook";
import dateFilterOptions from "../Constant";
import OnboardingCardSkeleton from "../../../../skeleton/onboarding/OnboardingCardSkeleton";
import DashboardDropdown from "../../../../components/DashboardDropdown";

const SearchMdAnalytics = () => {
  const [payload, setPayload] = useState({
    employee_id: "",
    type: dateFilterOptions.initial,
    next: "",
    prev: "",
    startdate: "",
    enddate: "",
  });

  const {
    data: onboardingMediumAnalytics,
    isLoading: isonboardingMediumLoading,
  } = usePostOnboardingMediumAnalytics(payload);

  console.log("searchMediumAnalytics", onboardingMediumAnalytics);

  const handleDropdownChange = (type) => {
    setPayload({
      ...payload,
      type: type,
    });
  };

  // 1 options.optionsMixedChart
  // 2 searchMediumAnalytics.seriesMixedChart

  var options = {
    optionsMixedChart: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: onboardingMediumAnalytics?.data?.labels,
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        max: 100,
      },
    },

    seriesMixedChart: [
      {
        name: "Onboarded",
        type: "line",
        data: [30, 40, 25, 50, 49, 21, 70, 51],
      },
      {
        name: "Approved",
        type: "column",
        data: [23, 12, 54, 61, 32, 56, 81, 19],
      },
      {
        name: "Disapproved",
        type: "column",
        data: [62, 12, 45, 55, 76, 41, 23, 43],
      },
    ],
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -20,
              show: true,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val) {
                return val;
              },
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
    seriesRadial: [76],
    optionsBar: {
      chart: {
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 0,
      },
      xaxis: {
        categories: ["Fav Color"],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.35,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [90, 0, 100],
        },
      },

      legend: {
        position: "bottom",
        horizontalAlign: "right",
      },
    },
    seriesBar: [
      {
        name: "blue",
        data: [32],
      },
      {
        name: "green",
        data: [41],
      },
      {
        name: "yellow",
        data: [12],
      },
      {
        name: "red",
        data: [65],
      },
    ],
  };

  var searchMediumAnalytics = {
    // optionsMixedChart: {
    //   chart: {
    //     id: "basic-bar",
    //     toolbar: {
    //       show: false,
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       columnWidth: "50%",
    //     },
    //   },
    //   stroke: {
    //     width: [4, 0, 0],
    //   },
    //   xaxis: {
    //     categories: [
    //       "04 Mar,24",
    //       "05 Mar,24",
    //       "06 Mar,24",
    //       "07 Mar,24",
    //       "08 Mar,24",
    //       "09 Mar,24",
    //       "10 Mar,24",
    //       "11 Mar,24",
    //     ],
    //   },
    //   markers: {
    //     size: 6,
    //     strokeWidth: 3,
    //     fillOpacity: 0,
    //     strokeOpacity: 0,
    //     hover: {
    //       size: 8,
    //     },
    //   },
    //   yaxis: {
    //     tickAmount: 5,
    //     min: 0,
    //     max: 100,
    //   },
    // },

    seriesMixedChart: [
      {
        name: "user",
        type: "line",
        data: onboardingMediumAnalytics?.data?.user,
      },
      {
        name: "Online",
        type: "column",
        data: onboardingMediumAnalytics?.data?.online,
      },
      {
        name: "Offline",
        type: "column",
        data: onboardingMediumAnalytics?.data?.offline,
      },
    ],

    // optionsRadial: {
    //   plotOptions: {
    //     radialBar: {
    //       startAngle: -135,
    //       endAngle: 225,
    //       hollow: {
    //         margin: 0,
    //         size: "70%",
    //         background: "#fff",
    //         image: undefined,
    //         imageOffsetX: 0,
    //         imageOffsetY: 0,
    //         position: "front",
    //         dropShadow: {
    //           enabled: true,
    //           top: 3,
    //           left: 0,
    //           blur: 4,
    //           opacity: 0.24,
    //         },
    //       },
    //       track: {
    //         background: "#fff",
    //         strokeWidth: "67%",
    //         margin: 0, // margin is in pixels
    //         dropShadow: {
    //           enabled: true,
    //           top: -3,
    //           left: 0,
    //           blur: 4,
    //           opacity: 0.35,
    //         },
    //       },

    //       dataLabels: {
    //         showOn: "always",
    //         name: {
    //           offsetY: -20,
    //           show: true,
    //           color: "#888",
    //           fontSize: "13px",
    //         },
    //         value: {
    //           formatter: function (val) {
    //             return val;
    //           },
    //           color: "#111",
    //           fontSize: "30px",
    //           show: true,
    //         },
    //       },
    //     },
    //   },
    //   fill: {
    //     type: "gradient",
    //     gradient: {
    //       shade: "dark",
    //       type: "horizontal",
    //       shadeIntensity: 0.5,
    //       gradientToColors: ["#ABE5A1"],
    //       inverseColors: true,
    //       opacityFrom: 1,
    //       opacityTo: 1,
    //       stops: [0, 100],
    //     },
    //   },
    //   stroke: {
    //     lineCap: "round",
    //   },
    //   labels: ["Percent"],
    // },
    // seriesRadial: [76],
    // optionsBar: {
    //   chart: {
    //     stacked: true,
    //     stackType: "100%",
    //     toolbar: {
    //       show: false,
    //     },
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //     },
    //   },
    //   dataLabels: {
    //     dropShadow: {
    //       enabled: true,
    //     },
    //   },
    //   stroke: {
    //     width: 0,
    //   },
    //   xaxis: {
    //     categories: ["Fav Color"],
    //     labels: {
    //       show: false,
    //     },
    //     axisBorder: {
    //       show: false,
    //     },
    //     axisTicks: {
    //       show: false,
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //     type: "gradient",
    //     gradient: {
    //       shade: "dark",
    //       type: "vertical",
    //       shadeIntensity: 0.35,
    //       gradientToColors: undefined,
    //       inverseColors: false,
    //       opacityFrom: 0.85,
    //       opacityTo: 0.85,
    //       stops: [90, 0, 100],
    //     },
    //   },

    //   legend: {
    //     position: "bottom",
    //     horizontalAlign: "right",
    //   },
    // },
    // seriesBar: [
    //   {
    //     name: "blue",
    //     data: [32],
    //   },
    //   {
    //     name: "green",
    //     data: [41],
    //   },
    //   {
    //     name: "yellow",
    //     data: [12],
    //   },
    //   {
    //     name: "red",
    //     data: [65],
    //   },
    // ],
  };

  if (isonboardingMediumLoading) {
    return <OnboardingCardSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <h4 className="mb-0">Search Medium Analytics</h4>
          <p className="mb-0 fs-7 text-capitalize mt-2">{payload.type}</p>
        </Card.Title>

        <DashboardDropdown
          handleDropdownChange={handleDropdownChange}
          type={payload.type}
          constantFilterOption={dateFilterOptions.options}
        />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Chart
          options={options?.optionsMixedChart}
          series={searchMediumAnalytics?.seriesMixedChart}
          type="area"
          // height="400"
        />
      </Card.Body>
    </Card>
  );
};

export default SearchMdAnalytics;
