import React from "react";

// layout

import Default from "../../../../layouts/dashboard/default";

// pages
import AddRequisition from "../pages/add-requisition";
import AllRequisitions from "../pages/listing";
import RequisitionDetails from "../pages/details";
import RequisitionDashboard from "../pages/requisition-dashboard";
import InProgress from "../pages/in-progress";
import Completed from "../pages/completed";
import Draft from "../pages/draft";
export const RequisitionRouter = [
  {
    path: "/requisition",
    element: <Default />,
    children: [
      {
        path: "my-requisitions",
        element: <AllRequisitions />,
      },
      {
        path: "dashboard",
        element: <RequisitionDashboard />,
      },
      {
        path: "create",
        element: <AddRequisition />,
      },
      {
        path: "edit/:id",
        element: <AddRequisition />,
      },
      {
        path: "listing",
        element: <AllRequisitions />,
      },
      {
        path: "details/:id",
        element: <RequisitionDetails />,
      },
      {
        path: "in-progress",
        element: <AllRequisitions />,
      },
      {
        path: "completed",
        element: <AllRequisitions />,
      },
      {
        path: "draft",
        element: <AllRequisitions />,
      },
      {
        path: "pending",
        element: <AllRequisitions />,
      },
    ],
  },
];
