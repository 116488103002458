export function transformIndiaMapData(data = []) {
  const result = data.reduce((acc, item) => {
    acc[item.level] = item.percentage;
    return acc;
  }, {});
  return [result];
}

export const formatLivePollDataForMasterCluster = (pollData = []) => {
  if (pollData.length < 1) return [];

  const formattedPollData = pollData?.map((item) => {
    return {
      question: item.survey_question,
      options: item.survey_option_jsonarray?.map((value) => {
        return { label: value.option, percentage: value.percentage };
      }),
    };
  });

  return formattedPollData;
};
