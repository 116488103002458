import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import ReactEmailEditor from "../../../../../lib/email-editor/react-email-editor";
import sample from "../../../../../lib/email-editor/sample.json";
import mobile from "../../../../../assets/images/mobileWhatsapp.png";
import { SMS_TEMPLATE_DETAILS_GET } from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import { useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../dashboard/icons";
import toast from "react-hot-toast";

const SmsTemplateDetails = () => {
  const { redirectTo } = useRedirect();

  const [loading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState("");
  const [smsText, setSmsText] = useState("");
  const [templateDetails, setTemplateDetails] = useState({});
  const params = useParams();

  useEffect(() => {
    const id = params.id;
    setTemplateId(id);
    // console.log(id);
    fetchWhatsAppTemplateDetails(id);
  }, []);

  const fetchWhatsAppTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getService(SMS_TEMPLATE_DETAILS_GET + `/${id}`);
      console.log("WHATSAPP_TEMPLATE_DETAILS_GET", response.data);
      if (response.data.success == true) {
        const content = response.data.data.sms_content.replace(
          /^<p>(.*)<\/p>$/,
          "$1"
        );
        // console.log("content", content);
        setTemplateDetails(response.data.data);
        setSmsText(content);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };
  const handleEditTemplate = () => {
    redirectTo("template-management/sms-template/create/" + templateId);
  };

  const handleBack = () => {
    redirectTo("template-management/sms-template");
  };
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <Row className="m-0">
                <Col
                  lg="8"
                  className="border border-1 rounded-3 p-0 d-flex flex-column"
                >
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <h6 className="mb-0">Category</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.sms_category_id?.category_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Type</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails?.sms_template_type?.value ?? "--"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Name</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.sms_name}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Flow/Template Id</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.sms_flow_id}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">DLT ID</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.sms_dlt_id}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Sender</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.sms_sender}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Status</h6>
                        </td>
                        <td
                          className={
                            templateDetails.status?.value == "Enabled"
                              ? "text-left text-success"
                              : "text-left text-danger"
                          }
                        >
                          {templateDetails.status?.value}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="mb-0">Params</h6>
                        </td>
                        <td className="text-left">
                          {templateDetails.sms_params
                            ? templateDetails.sms_params
                            : ""}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between px-2 py-2">
                    <Button
                      variant="secondary"
                      onClick={handleBack}
                      className=""
                    >
                      {findIcon("Arrow Left", "outline", "32")}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleEditTemplate}
                      className=""
                    >
                      Edit Template
                    </Button>
                  </div>
                </Col>
                <Col lg={4} xs={12} className="position-sticky top-0">
                  <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                    <div className="d-flex position-relative mobile-preview">
                      <img
                        src={mobile}
                        alt="mobile"
                        className="mobile h-auto w-100 position-relative"
                      />
                      <div className="p-2 h-100 w-100 position-absolute p-5">
                        <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                          {smsText}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SmsTemplateDetails;
