import React from "react";

const ColumnTextWrapper = ({
  width = 150,
  onClick,
  className = "",
  children,
}) => {
  return (
    <div
      style={{ maxWidth: `${width}px` }}
      className={"text-truncate " + className}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {children}
    </div>
  );
};

export default ColumnTextWrapper;
