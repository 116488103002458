import React from "react";
import { Button, Modal, Col, Row, Card, Form } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useRequestDataChange } from "../../../../../queries/query-hooks/hcp-profile-hook";

export default function RequestDataChnageModal({
  show,
  onClose,
  onSubmit,
  title,
  body,
  data,
}) {
  const initialValues = {
    field_name: "",
    disposition: "",
    onboarding_manager_id: "",
  };

  const validationSchema = Yup.object().shape({
    field_name: Yup.string().required("Write Field Name"),
    disposition: Yup.string().required("Write Disposition"),
    onboarding_manager_id: Yup.number().required("Select Your Manager"),
  });

  const onRequestCreateSucccess = (response) => {
    console.log("::::", response?.data.message);
    toast.success(`${response.data.message}`);
    onSubmit();
  };
  const { mutate: updateRequestDataChange, isLoading } = useRequestDataChange(
    data?.hcp_id,
    onRequestCreateSucccess
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("values:", values);
      let payload = { ...values, hcp_id: data.hcp_id };
      updateRequestDataChange(payload);
      // onSubmit();
    },
  });

  return (
    <Modal show={show} onHide={onClose} centered={true}>
      <Modal.Header>
        <Modal.Title as="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row className="justify-content-center align-items-start">
            <Col lg={12} xs={12} className="position-relative">
              <div className="p-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                <Row>
                  <Col md={12} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        type="text"
                        id="field_name"
                        name="field_name"
                        value={values?.field_name}
                        isInvalid={touched.field_name && !!errors.field_name}
                        onChange={handleChange}
                      ></Form.Control>
                      <Form.Label htmlFor="manager_id">Field Name</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.field_name}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>

                  <Col md={12} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Control
                        type="text"
                        as={"textarea"}
                        id="disposition"
                        name="disposition"
                        value={values?.disposition}
                        isInvalid={touched.disposition && !!errors.disposition}
                        onChange={handleChange}
                      ></Form.Control>
                      <Form.Label htmlFor="manager_id">Disposition </Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.disposition}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                  <Col md={12} className="mb-4">
                    <Form.Floating className="custom-form-floating">
                      <Form.Select
                        id="onboarding_manager_id"
                        name="onboarding_manager_id"
                        value={values?.onboarding_manager_id}
                        isInvalid={
                          touched.onboarding_manager_id &&
                          !!errors.onboarding_manager_id
                        }
                        onChange={handleChange}
                      >
                        <option value="">Onboarding Manager</option>
                        <option key={1} value={1}>
                          Sumit Mondal
                        </option>
                        <option key={1} value={2}>
                          Souvik Mondal
                        </option>
                      </Form.Select>
                      <Form.Label htmlFor="manager_id">Manager</Form.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.onboarding_manager_id}
                      </Form.Control.Feedback>
                    </Form.Floating>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? "Creating Request..." : "Create Request"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
