import Skeleton from "../../components/skeleton";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const TemplateImageLoader = () => {
  return (
    <>
      <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
        <div className="position-relative flex-fill">
          <Skeleton
            variant="rounded"
            width="100%"
            height={500}
            className="rounded-5"
          />
          {/* <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
            {selectedSms && (
              <div className="h-100 rounded-5 p-4 mobile-preview position-relative ">
                <span className="d-block position-relative px-3 py-2 rounded-3 mt-4 text-secondary fs-7 lh-base shadow-lg shadow bg-white">
                  {selectedSms}
                </span>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TemplateImageLoader;
