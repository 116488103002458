import React from "react";
import { Card } from "react-bootstrap";
import { useHcpDetails } from "../../../../../queries/query-hooks/hcp-profile-hook";
import { useParams } from "react-router-dom";
import HcpInformationSkeleton from "../../../../../skeleton/hcp/HcpProfileDetails/HcpInformationSkeleton";

const DoctorPersona = ({ hcpDetail, isHcpDetailsLoading }) => {
  // const { id: hcpId } = useParams();
  // //   const commonPayload = { hcp_id: hcpId };
  // const onSuccess = (response) => {
  //   //response
  //   //console.log("reponse", response);
  // };

  // const {
  //   data: hcpDetail,
  //   refetch: refetchHcpDetails,
  //   isLoading,
  // } = useHcpDetails(hcpId, !!hcpId, onSuccess);

  if (isHcpDetailsLoading) {
    return <HcpInformationSkeleton />;
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Doctor's Persona</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column gap-3">
          {hcpDetail?.hcp_to_persona.map(
            (data, key) =>
              data?.all_persona?.persona_name && (
                <div className="bg-soft-info p-3 rounded-3">
                  <h5 className="fw-semibold">
                    {data?.all_persona?.persona_name}
                  </h5>
                </div>
              )
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default DoctorPersona;
