import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormCheck,
  Modal,
  Row,
} from "react-bootstrap";
import ReactTable from "../../../../lib/table/react-table";

import * as yup from "yup";
import { useFormik } from "formik";
import postShortLinkService from "../../../../api/services/post-short-link-service";
import {
  ALL_SHORT_LINK_LIST,
  GET_SHORT_LINK_DETAIL,
  GET_SHORT_LINK_UPDATE,
} from "../../../../api/endpoints/short-link-endpoints";
import { getShortLinkService } from "../../../../api/services/get-short-link-service";
import { changeTableStateToPayload } from "../../../../common/utils/utils";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import ViewModal from "../../../../components/partials/common/view-modal";
import { findIcon } from "../../../dashboard/icons";
import CommonTableLoader from "../../../../skeleton/common-table-loader";
import PageNotFound from "../../../PageNotFound";
import dayjs from "dayjs";
const ShortLinkListing = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [shortLinkList, setShortLinkList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewShortLink, setViewShortLink] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedShortLink, setSelectedShortLink] = useState(null);
  const [shortLinkDetails, setShortLinkDetails] = useState({});
  const [previewStatus, setPreviewStatus] = useState(true);
  const { redirectTo } = useRedirect();
  const idRef = useRef("");
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    webhook: yup.string().required("Webhook Url is required"),
    og_title: yup.string().required("Title is required"),
    og_description: yup.string().required("Description is required"),
    og_image: yup.string().required("Image Link is required"),
    og_type: yup.string().required("Type is required"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      webhook: "",
      og_title: "",
      og_description: "",
      og_image: "",
      og_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await postShortLinkService(
          GET_SHORT_LINK_UPDATE + "/" + idRef.current,
          values
        );
        console.log("ACTION_TYPE_SERVICE_RESPONSE", response);
        // if (response) {
        //   setLoading(false);
        //   setCreateModalShow(false);
        //   fetchShortLinkList(post_json.current);
        // }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchShortLinkList = async (data) => {
    try {
      const response = await postShortLinkService(ALL_SHORT_LINK_LIST, data);
      console.log("COUNTRY_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response?.data?.data?.rows?.length > 0) {
        setShortLinkList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("COUNTRY_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (shortLink) => {
    console.log("shortLink===>", shortLink);
    let dataJson = {
      Name: shortLink?.user_id?.name ?? "",
      "Url key": shortLink?.url_key?.url_key ?? "",
      "Destination url": shortLink?.url_key?.destination_url ?? "",
      "Default short url": shortLink?.url_key?.default_short_url ?? "",
      "Content Category":
        shortLink?.content_category_id?.content_category_name ?? "",

      "Link Type": shortLink?.link_type.value ?? "",
      "User name": shortLink?.user_id?.name ?? "",
      "Created at": shortLink?.created_at ?? "",
    };
    setViewShortLink(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  useEffect(() => {
    fetchShortLinkList(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      console.log("state", state);
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchShortLinkList(payload);
    },
    [post_json.current]
  );

  const columnData = [
    {
      Header: "Sl No",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "base_url_id",
      Cell: ({ row }) => row?.original.base_url_id?.name ?? "none",
    },
    {
      Header: "URL Key",
      accessorFn: (row) => row.url_key.url_key,
      Cell: ({ row }) => row?.original.url_key?.url_key ?? "none",
    },
    {
      Header: "Destination URL",
      id: "destination_url",
      accessorFn: ({ row }) => row.url_key.destination_url,
      Cell: ({ row }) => row?.original.url_key?.destination_url ?? "none",
    },
    {
      Header: "Short URL",
      accessorFn: (row) => row.url_key.default_short_url,
      Cell: ({ row }) => row?.original.url_key?.default_short_url ?? "none",
    },
    {
      Header: "Link Type",
      accessor: "link_type",
      Cell: ({ value }) => value?.value ?? "none",
    },
    {
      Header: "User Name",
      accessor: "user_id",
      Cell: ({ value }) => value?.name ?? "none",
    },
    {
      Header: "Contennt Category",
      accessor: "content_category_id",
      Cell: ({ value }) => value?.content_category_name ?? "none",
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }) => dayjs(value).format("DD MMM YYYY"),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap">
          <div className="d-flex justify-content-center gap-2">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => viewModal(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Eye", "dual-tone", 32)}
              </span>
            </Button>
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => editShortLink(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Pencil", "dual-tone", 32)}
              </span>
            </Button>
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() =>
                redirectTo(`short-link/details/${row.original.url_key.url_key}`)
              }
            >
              <span className="btn-inner">
                {findIcon("Arrow Right", "dual-tone", 32)}
              </span>
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const editShortLink = async (shortLink) => {
    // resetForm();
    idRef.current = shortLink.id;
    let getShortLinkDetails = await getShortLinkService(
      GET_SHORT_LINK_DETAIL + "/" + shortLink.id
    );
    if (getShortLinkDetails?.data?.success) {
      let response = getShortLinkDetails?.data?.data;
      setShortLinkDetails(response);
      // setPreviewStatus(
      //   response?.og_title != "" &&
      //     response?.og_description != "" &&
      //     response?.og_image != "" &&
      //     response?.og_type?.value != ""
      // );
      let previewStatus =
        response?.og_title &&
        response?.og_description &&
        response?.og_image &&
        response?.og_type?.value
          ? 1
          : 0;
      console.log("getShortLinkDetails", previewStatus);
      setValues({
        preview: previewStatus,
        webhook: response?.webhook ?? "",
        og_title: response?.og_title ?? "",
        og_description: response?.og_description ?? "",
        og_image: response?.og_image ?? "",
        og_type: response?.og_type?.id ?? "",
      });
    }

    setCreateModalShow(true);
    // setShortLinkDetails
  };
  // const onEditCountryClick = (country) => {
  //   console.log("country===>", country);
  //   setIsEdit(true);
  //   setSelectedShortLink(country.country_id);
  //   //set formik values
  //   setValues({
  //     country_name: country.country_name,
  //     country_code: country.country_code,
  //     country_isd_code: country.country_isd_code,
  //   });
  //   setCreateModalShow(true);
  // };
  const getEditedValue = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const previewStatusHandler = (e) => {
    if (e.target.checked) {
      setValues({
        ...values,
        preview: 1,
      });
    } else {
      setValues({
        ...values,
        preview: 0,
        webhook: "",
        og_title: "",
        og_description: "",
        og_image: "",
        og_type: "",
      });
    }
  };
  console.log("show#==>", touched, errors.webhook);
  return (
    <>
      <Button
        variant="success"
        className="d-flex align-items-center justify-content-center add_floating__button position-fixed rounded-circle p-1 avatar-80 lh-1 border border-white border-2"
        onClick={() => redirectTo("short-link/create")}
      >
        {findIcon("Plus", "outline", "32")}
      </Button>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">All Short Link</h3>
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              {shortLinkList?.length > 0 && !loading ? (
                <ReactTable
                  data={shortLinkList}
                  columns={columnData}
                  recordsTotal={totalCount}
                  onTableStateChange={onTableStateChange}
                  initialState={post_json.current}
                  isRowSelectionAvailable={false}
                  recordsPerPage={10}
                />
              ) : loading ? (
                <CommonTableLoader />
              ) : (
                <PageNotFound />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteCountry}
      /> */}
      <ViewModal
        title={"Short link information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewShortLink}
      />
      <Modal
        show={createModalShow}
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Short Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {shortLinkDetails?.base_url_id &&
              shortLinkDetails?.base_url_id?.name && (
                <Col lg="6">
                  <h6 className="fs-6 fw-400 gray-300">Domain</h6>
                  <p>{shortLinkDetails?.base_url_id?.name}</p>
                </Col>
              )}
            {shortLinkDetails?.url_key &&
              shortLinkDetails?.url_key?.url_key && (
                <Col lg="6">
                  <h6 className="fs-6 fw-400 gray-300">Url Key</h6>
                  <p>{shortLinkDetails?.url_key?.url_key}</p>
                </Col>
              )}
            {shortLinkDetails?.url_key &&
              shortLinkDetails?.url_key?.destination_url && (
                <Col lg="12">
                  <h6 className="fs-6 fw-400 gray-300">Destination Url</h6>
                  <p>{shortLinkDetails?.url_key?.destination_url}</p>
                </Col>
              )}
            {shortLinkDetails?.url_key &&
              shortLinkDetails?.url_key?.default_short_url && (
                <Col lg="6">
                  <h6 className="fs-6 fw-400 gray-300">Default Short Url</h6>
                  <p>{shortLinkDetails?.url_key?.default_short_url}</p>
                </Col>
              )}
            {shortLinkDetails?.link_type &&
              shortLinkDetails?.link_type?.value && (
                <Col lg="6">
                  <h6 className="fs-6 fw-400 gray-300">Link Type</h6>
                  <p>{shortLinkDetails?.link_type?.value}</p>
                </Col>
              )}
            {shortLinkDetails?.content_category_id &&
              shortLinkDetails?.content_category_id?.content_category_name && (
                <Col lg="6">
                  <h6 className="fs-6 fw-400 gray-300">Service</h6>
                  <p>
                    {
                      shortLinkDetails?.content_category_id
                        ?.content_category_name
                    }
                  </p>
                </Col>
              )}
            {shortLinkDetails?.content_id != "0" && (
              <Col lg="6">
                {" "}
                <h6 className="fs-6 fw-400 gray-300">Page Id</h6>
                <p>{shortLinkDetails?.content_id}</p>
              </Col>
            )}
            {shortLinkDetails?.created_at && (
              <Col lg="6">
                {" "}
                <h6 className="fs-6 fw-400 gray-300">Created at</h6>
                <p>
                  {dayjs(shortLinkDetails?.created_at).format("DD MMM YYYY")}
                </p>
              </Col>
            )}
            {shortLinkDetails?.webhook && (
              <Col lg="12">
                <h6 className="fs-6 fw-400 gray-300 mb-3">Webhook</h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    // autoComplete="password"
                    placeholder="Enter your webhook Url"
                    value={values?.webhook ?? ""}
                    isInvalid={errors.webhook}
                    name="webhook"
                    onChange={(e) => getEditedValue(e)}
                  />
                  <label htmlFor="floatingInput">Enter your Webhook</label>
                  <Form.Control.Feedback type="invalid">
                    {errors.webhook}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
            )}
            <div className="d-flex justify-content-start align-items-center my-4">
              <h6 className="m-0 me-3">Preview Content</h6>
              <Form.Check className="form-switch">
                <FormCheck.Input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  value={values?.preview ?? 0}
                  checked={values?.preview == 1}
                  name="preview"
                  onChange={(e) => previewStatusHandler(e)}
                />
              </Form.Check>
            </div>
          </Row>
          {values?.preview == 1 && (
            <Row>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">Title</h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    value={values?.og_title ?? ""}
                    placeholder="Enter your Title"
                    isInvalid={errors.og_title}
                    name="og_title"
                    onChange={(e) => getEditedValue(e)}
                  />
                  <label htmlFor="floatingInput">Enter your Title</label>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_title}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">Description</h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    placeholder="Enter your description"
                    isInvalid={errors.og_description}
                    value={values?.og_description ?? ""}
                    name="og_description"
                    onChange={(e) => getEditedValue(e)}
                  />
                  <label htmlFor="floatingInput">Enter your description</label>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_description}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">Image Link</h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Control
                    type="text"
                    className=""
                    // autoComplete="password"
                    isInvalid={errors.og_image}
                    placeholder="Enter your Image Link"
                    value={values?.og_image ?? ""}
                    name="og_image"
                    onChange={(e) => getEditedValue(e)}
                  />
                  <label htmlFor="floatingInput">Enter your Image Link</label>
                  <Form.Control.Feedback type="invalid">
                    {errors.og_image}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
              <Col lg="6" className="mb-4">
                <h6 className="fs-6 fw-400 gray-300 mb-3">Type</h6>
                <Form.Floating className="custom-form-floating">
                  <Form.Select
                    className=""
                    id="floatingInput1"
                    name="og_type"
                    isInvalid={errors.og_type}
                    value={values?.og_type ?? ""}
                    onChange={(e) => getEditedValue(e)}
                  >
                    <option value="" selected hidden>
                      {" "}
                      Please Select Type
                    </option>
                    <option value="article">Article</option>
                    <option value="website">Website</option>
                  </Form.Select>
                  {/* <Form.Label htmlFor="floatingInput">
                  Select Speciality <span className="text-danger">*</span>
                </Form.Label> */}
                  <Form.Control.Feedback type="invalid">
                    {errors.og_type}
                  </Form.Control.Feedback>
                </Form.Floating>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShortLinkListing;
