import React from "react";

function TabCountCard({ count, text, icon, handleOnclick, iconColor }) {
  return (
    <div
    onClick={handleOnclick}
      className={`d-flex align-items-center justify-content-start gap-3 cursor-pointer rounded-3 px-4 py-3 bg-light bg-opacity-10`}
    >
      <div className={`rounded d-flex align-items-center ${iconColor}`}>
        {icon}
      </div>
      <div className="d-flex flex-column">
        <h3 className={`fs-5 fw-bold m-0 mb-1 text-black`}>{count}</h3>

        <h4 className={`fs-6 fw-light m-0 text-black-50`}>{text}</h4>
      </div>
    </div>
  );
}

export default TabCountCard;
