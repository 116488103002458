import React, { memo } from "react";
import { Card, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import SponsoredMatrix from "./SponsoredMatrix";

function VerticalTabs({ tabs, activeSummaryTab = {} }) {
  if (tabs && Array.isArray(tabs) && tabs?.length > 0) {
    return (
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={activeSummaryTab?.value}
        activeKey={activeSummaryTab?.value}
      >
        <Row>
          {/* <Col sm={3}>
            <Nav className="d-flex flex-column flex-md-column  nav-pills nav-pills-green nav-iconly gap-3 flex-wrap rounded-0 overflow-x-scroll">
              {tabs?.length > 0 &&
                tabs?.map((item, index) => {
                  const content_title =
                    item?.fetch_with_image[0]?.content_title;
                  const id = item?.content_id;

                  return (
                    <Nav.Item key={index}>
                      <Nav.Link
                        key={id}
                        className="px-0 py-3"
                        eventKey={id}
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-${id}`}
                        type="button"
                        role="tab"
                        aria-controls={`nav-${id}`}
                      >
                        <div
                          className="px-3 d-inline-block text-truncate w-100 max-tab-width"
                          title={content_title}
                        >
                          {findIcon("Layers", "dual-tone", 24)}
                          <span className="ms-2">{content_title}</span>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
            </Nav>
          </Col> */}
          <Col sm={12}>
            <Tab.Content className="iq-tab-fade-up w-100">
              {tabs?.length > 0 &&
                tabs?.map((item) => {
                  const content_id = item?.content_id;
                  const tags =
                    item?.fetch_with_image[0]?.content_tags &&
                    Array.isArray(item?.fetch_with_image[0]?.content_tags)
                      ? item?.fetch_with_image[0]?.content_tags?.map(
                          (_item) => _item?.tag_data?.content_tag_name
                        )
                      : [];
                  const group_to_campaign = {};
                  return (
                    <Tab.Pane
                      key={content_id}
                      eventKey={content_id}
                      id={`nav-${content_id}`}
                      role="tabpanel"
                      aria-labelledby={`nav-${content_id}`}
                    >
                      <SponsoredMatrix
                        tags={tags}
                        // impressionChart={impressionChart}
                        content_sponsors={
                          item?.fetch_with_image[0]?.content_sponsors
                        }
                        readChart={{
                          max:
                            item?.fetch_with_image[0]?.content_target
                              ?.read_target || 0,
                          data: item?.content_stats?.reads || 0,
                        }}
                        viewsChart={{
                          max:
                            item?.fetch_with_image[0]?.content_target
                              ?.view_target || 0,
                          data: item?.content_stats?.views || 0,
                        }}
                        hcpEngagedChart={{
                          max:
                            item?.fetch_with_image[0]?.content_target
                              ?.unique_reach_target || 0,
                          data: item?.content_stats?.unique_reach || 0,
                        }}
                        avgDurationChart={
                          item?.content_stats?.average_duration || 0
                        }
                        content={{
                          content_id: item?.content_id,
                          content_title:
                            item?.fetch_with_image[0]?.content_title,
                          content_type:
                            item?.fetch_with_image[0]?.content_category
                              ?.content_category_name,

                          content_image:
                            item?.fetch_with_image[0]?.content_image_data[0]
                              ?.content_image_data,
                          content_date: item?.fetch_with_image[0]?.created_at,
                          content_category_icon:
                            item?.fetch_with_image[0]?.content_category
                              ?.content_category_icon,
                        }}
                      />
                    </Tab.Pane>
                  );
                })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  } else return <></>;
}

export default memo(VerticalTabs);
