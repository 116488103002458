import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Plotly from "plotly.js-dist";
import { Col, Row, Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../components/dropdowns";
import { useOnboardingByRegistration } from "../../../../../queries/query-hooks/hcp-profile-hook";
import Skeleton from "../../../../../components/skeleton";
import HcpReonboardingSkeleton from "../../../../../skeleton/hcp/hcp-re-onboarding-skeleton";
import { dateRangeOptions } from "../../constant/hcp-constatnt";
import CustomDropdown from "./CustomDropdown";

const plotlyConfig = {
  displayModeBar: false,
};

var GRAPH_LAYOUT = {
  displayModeBar: false,
};

const customDateFilterInital = dateRangeOptions.find(
  (option) => option.initial
);

export default function OnboardingByRegistrationGraph() {
  const [payload, setPayload] = useState({
    year: "",
    month: customDateFilterInital.value,
    isRefresh: "",
  });

  const [dropdownType, setDropdownType] = useState(
    customDateFilterInital.label
  );

  const handleSelect = (eventKey, event) => {
    setDropdownType(event.target.innerText);
  };

  /**
   * Onboarding By Registration query
   */
  const {
    data: onboardingByRegistration,
    isLoading: isOnboardingGraphLoading,
    refetch: onboardingByRegistrationRefetch,
  } = useOnboardingByRegistration(payload, dropdownType);

  useEffect(() => {
    console.log("Onboarding by registration", onboardingByRegistration);
    if (onboardingByRegistration && onboardingByRegistration?.data) {
      Plotly.newPlot(
        "onboarding-by-registration",
        [onboardingByRegistration?.data],
        GRAPH_LAYOUT,
        plotlyConfig
      );
    }
  }, [onboardingByRegistration]);
  console.log("onboardingByRegistration", onboardingByRegistration);

  // if loading return skeleton
  if (isOnboardingGraphLoading) {
    return <HcpReonboardingSkeleton />;
  }

  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          {/* {!isOnboardingGraphLoading ? (
            <h4 className="mb-0"> Onboarding By Registration</h4>
          ) : (
            <Skeleton width={100} />
          )}
          {!isOnboardingGraphLoading ? (
            <p className="mb-0 fs-7 text-secondary mt-2">{payload?.type}</p>
          ) : (
            <Skeleton width={50} />
          )} */}

          <h4 className="mb-0"> Onboarding By Registration</h4>
          <p className="mb-0 fs-7 text-secondary mt-2">{dropdownType}</p>
        </Card.Title>

        {/*  */}

        <CustomDropdown
          handleSelect={handleSelect}
          dateFilterOptions={dateRangeOptions}
          dropdownType={dropdownType}
          setPayload={setPayload}
        />

        {/*  */}
        {/*  */}
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        {/* {!isOnboardingGraphLoading ? (
          <div id="onboarding-by-registration"></div>
        ) : (
          <Skeleton width={550} height={450} />
        )} */}
        <div id="onboarding-by-registration"></div>
      </Card.Body>
    </Card>
  );
}
