import React, { useRef } from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";
import { findIcon } from "../../views/dashboard/icons";

// TODO: NOT COMPLETED
function MiniLineChart({ title, series, labels }) {
  const options = {
    series: [
      {
        data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14],
      },
    ],
    chart: {
      type: "line",
      width: 30,
      height: 10,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "100%",
        colors: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#f8f8f8", // Light color for 0 value
            },
            {
              from: 0.1,
              to: Infinity,
              color: "#13388c", // Your original color for non-zero values
            },
          ],
          backgroundBarColors: ["#F8F8F8"], // Light color for entire bar background
        },
      },
    },
    colors: ["#13388c"],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <>
      <Card className="m-0">
        <div className="d-flex flex-column">
          <Card.Body className="p-0 pe-2">
            <div id="chart-basic-column-bar">
              <Chart
                options={options}
                series={[
                  {
                    data: series,
                  },
                ]}
                type="line"
                height={35}
                width={100}
              />
            </div>
          </Card.Body>
        </div>
      </Card>
    </>
  );
}

export default MiniLineChart;
