import React, { memo, useEffect, useRef } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { getService } from "../../../../api/services/get-services";
import {
  ALL_VENDORS,
  GET_ASSOCIATES,
} from "../../../../api/endpoints/requisition-endpoints";
import postService from "../../../../api/services/post-service";
import Select from "react-select";
import toast from "react-hot-toast";
import SubmitButton from "../../../../components/form-components/submit-button";
// import workflow from "../data/workflow";
const RequisitionAssociates = ({
  workflow,
  formValues,
  onSubmitRequisition,
  associateSelectedValues = [],
  user = {},
}) => {
  const [selectedAssociates, setSelectedAssociates] = React.useState([]);

  console.log("WORKFLOW_RULE", workflow);
  const [associatesList, setAssociatesList] = React.useState([]);
  const [vendor, setVendor] = React.useState(null);
  const frontendpostjson = useRef({});

  //function to get the properties of the workflow which have type==specific
  const setVendorId = (workflow) => {
    Object.keys(workflow).forEach((key) => {
      if (
        workflow[key].type == "only" &&
        // key != "requisition_edit" &&
        key != "start"
      ) {
        if (associateSelectedValues.some((item) => item.task == key)) {
          console.log("vendorkey", key);
          let value = associateSelectedValues.find((item) => item.task == key);
          let vendor_id = value.selectedAssociates[0].value;
          console.log("vendor_id", vendor_id);
          setVendor(vendor_id);
          return false;
          //
        }
      }
    });
  };
  const getSpecificProperties = (workflow) => {
    let specificProperties = [];
    Object.keys(workflow).forEach((key) => {
      if (
        workflow[key].type === "specific" ||
        workflow[key].type === "specfic"
      ) {
        specificProperties.push({ key, value: workflow[key] });
        // specificProperties[key] = workflow[key];
      }
    });
    return specificProperties;
  };
  const getOtherProperties = (workflow) => {
    let otherProperties = [];
    Object.keys(workflow).forEach((key) => {
      if (
        workflow[key].type == "only" &&
        key != "requisition_edit" &&
        key != "content_publish" &&
        key != "start"
      ) {
        otherProperties.push({ key, value: workflow[key] });
      }
    });
    return otherProperties;
  };
  const getOwnProperties = (workflow) => {
    let ownProperties = [];
    Object.keys(workflow).forEach((key) => {
      if (key == "requisition_edit" || key == "content_publish") {
        ownProperties.push({ key, value: workflow[key] });
      }
    });
    return ownProperties;
  };
  const [vendors, setVendors] = React.useState([]);

  let postjson = {
    brand_id: parseInt(formValues?.requisition_brand_id),
    file_type_ids: formValues?.file_and_no_of_submissions.map((item) =>
      parseInt(item.file_type)
    ), //array of file type ids
  };

  const fetchAssociates = async (task, department, sequence) => {
    const response = await getService(
      `${GET_ASSOCIATES}/${department}/${task}`
    );
    //console.log("associate_values", response.data.data);
    // setAssociatesList to valeus of the departments
    setAssociatesList((prevState) => {
      //console.log("prevState", prevState);
      let temp = [...prevState];
      temp.find((item) => item.key === task).value.department[
        department
      ].associates = response.data.data;
      return [...temp];
    });

    if (associateSelectedValues.length > 0) {
      console.log("associateSelectedValues", associateSelectedValues);
      let selectedAssociatesTemp = [...associateSelectedValues];
      let index = selectedAssociatesTemp.findIndex(
        (item) => item.department === department && item.task === task
      );
      if (index > -1) {
        let selectedIds = selectedAssociatesTemp[index].selectedAssociates;
        console.log("selectedIds", selectedIds);
        for (const selectedId of selectedIds) {
          let associate = response.data.data.find(
            (item) => item.id == selectedId.value
          );

          selectedId.label = associate?.name ?? "";
        }
      }
      //console.log("selectedAssociatesTemp", selectedAssociatesTemp);
      prepareFrontendPostJson(
        selectedAssociatesTemp[index].selectedAssociates,
        department,
        task,
        sequence
      );
      setSelectedAssociates(selectedAssociatesTemp);
    }
  };
  //console.log("associatesList", associatesList);
  const fetchVendors = async () => {
    let response = await postService(ALL_VENDORS, postjson);
    console.log("vendor_response", response.data.data);
    if (response.data.data.length > 0) {
      let vendor_data = response.data.data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          department:
            item?.user_to_department?.length > 0
              ? item.user_to_department[0].department_id?.department_code
              : "medical",
        };
      });

      setVendors(vendor_data);
    }
  };
  useEffect(() => {
    fetchVendors();

    //console.log("workflow", workflow, getSpecificProperties(workflow));
    setVendorId(workflow);
    setAssociatesList(getSpecificProperties(workflow));
    for (const eachWorkflow of getSpecificProperties(workflow)) {
      //console.log("each-workflow", eachWorkflow);
      let task = eachWorkflow.key;
      let departments = eachWorkflow.value.department;
      for (const department in departments) {
        if (Object.hasOwnProperty.call(departments, department)) {
          const eachDept = departments[department];
          //console.log("eachDept", eachDept);
          const maxSelectionLength = eachDept?.max ?? null;
          const minSelectionLength = eachDept?.min ?? null;
          const isRequired = eachDept?.required != 0;
          const sequence = eachDept?.sequence ?? null;
          fetchAssociates(task, department, sequence);
        }
      }
    }
  }, []);
  const getAssociateValues = (associates) => {
    let associateValues = [];
    let toProceedLoop =
      associates &&
      typeof associates === "object" &&
      Array.isArray(associates) &&
      associates.length > 0;
    if (toProceedLoop) {
      associates?.forEach((associate) => {
        associateValues.push({
          value: associate.id,
          label: associate.name,
        });
      });
    }
    return associateValues;
  };
  const prepareFrontendPostJson = (
    selectedvalues,
    department,
    task,
    sequence
  ) => {
    //prepare the json according to the frontendpostjson format
    //console.log("associatesList", associatesList);
    let temp = [...associatesList];
    if (!(task in frontendpostjson.current)) {
      frontendpostjson.current[task] = {};
    }
    if (!("sequence" in frontendpostjson.current[task])) {
      frontendpostjson.current[task].sequence = [];
    }
    // check if the sequence[sequence] exists in the frontendpostjson
    if (frontendpostjson.current[task].sequence.length > 0) {
      if (!frontendpostjson.current[task].sequence[sequence]) {
        frontendpostjson.current[task].sequence[sequence] = {};
      }
      if (!(department in frontendpostjson.current[task].sequence[sequence])) {
        frontendpostjson.current[task].sequence[sequence][department] = {};
      }
      frontendpostjson.current[task].sequence[sequence][department] =
        selectedvalues.map((item) => {
          return { id: item.value, status: 0 };
        });
      //console.log("frontendpostjson", frontendpostjson.current);
    } else {
      frontendpostjson.current[task].sequence[sequence] = {};
      frontendpostjson.current[task].sequence[sequence][department] =
        selectedvalues.map((item) => {
          return { id: item.value, name: item.label, status: 0 };
        });
      //console.log("frontendpostjson", frontendpostjson.current);
    }
  };
  const handleAssociateChange = (
    selectedvalues,
    department,
    task,
    maximumSelectionLength,
    minSelectionLength,
    sequence,
    required
  ) => {
    if (selectedvalues.length > maximumSelectionLength) {
      toast.error("Maximum Selection Length Exceeded");
      return;
    }
    if (selectedvalues.length < minSelectionLength) {
      toast.error("Minimum Selection Length Not Met");
      return;
    }
    prepareFrontendPostJson(selectedvalues, department, task, sequence);

    setSelectedAssociates((prevState) => {
      //set the selected associates according to values of react-select
      let temp = [...prevState];
      let index = temp.findIndex(
        (item) => item.department === department && item.task === task
      );
      if (index > -1) {
        temp[index].selectedAssociates = selectedvalues;
      } else {
        temp.push({
          department: department,
          task: task,
          selectedAssociates: selectedvalues,
          maximumSelectionLength: maximumSelectionLength,
          minSelectionLength: minSelectionLength,
          sequence: sequence,
          required: required,
        });
      }
      return [...temp];
    });
  };
  //console.log("selectedAssociates", selectedAssociates);
  const onInitiateRequisition = async () => {
    //console.log("frontendpostjson", frontendpostjson.current);
    for (const eachWorkflow of getSpecificProperties(workflow)) {
      //console.log("each-workflow", eachWorkflow);
      let task = eachWorkflow.key;
      let departments = eachWorkflow.value.department;
      for (const department in departments) {
        if (Object.hasOwnProperty.call(departments, department)) {
          const eachDept = departments[department];
          //console.log("eachDeptDuringPost", eachDept);
          //console.log("eachDeptDuringPostFrontEnd", frontendpostjson.current);
          let isRequired = eachDept.min > 0;
          let requiredSequence = eachDept.sequence;
          let minSelection = eachDept.min;
          console.log("requiredSequence", frontendpostjson?.current[task]);
          let selectedItem = frontendpostjson?.current[task]?.sequence[
            requiredSequence
          ]
            ? frontendpostjson?.current[task]?.sequence[requiredSequence][
                department
              ] || null
            : null;

          if (isRequired && selectedItem === null) {
            toast.error(
              "Please select the required number of associates " +
                `(${department} ${taskToPerson(task)})`
            );
            return;
          }
          if (isRequired && selectedItem.length < minSelection) {
            toast.error("Please select the required associates");
            return;
          }
        }
      }
    }
    for (const eachOtherWorkflow of getOtherProperties(workflow)) {
      if (vendors.length > 0) {
        let task = eachOtherWorkflow.key;

        let selectedVendor = vendors.find((item) => item.value == vendor);
        //console.log("selectedVendor", selectedVendor);
        let department = selectedVendor.department;

        frontendpostjson.current[task] = {};
        frontendpostjson.current[task].sequence = [];
        frontendpostjson.current[task].sequence[0] = {};
        frontendpostjson.current[task].sequence[0][department] = [];
        frontendpostjson.current[task].sequence[0][department] = [
          { id: vendor, status: 0 },
        ];

        //console.log("eachOtherWorkflow", eachOtherWorkflow);
      }
    }
    for (const eachOwnWorkflow of getOwnProperties(workflow)) {
      if (user?.id) {
        let task = eachOwnWorkflow.key;
        let department =
          user?.departments?.all_department?.department_name || "NA";

        frontendpostjson.current[task] = {};
        frontendpostjson.current[task].sequence = [];
        frontendpostjson.current[task].sequence[0] = {};
        frontendpostjson.current[task].sequence[0][department] = [];
        frontendpostjson.current[task].sequence[0][department] = [
          { id: user?.id, status: 0 },
        ];
      }
    }
    let temp_post = { ...frontendpostjson.current };
    onSubmitRequisition(temp_post);
  };
  const taskToPerson = (key) => {
    switch (key) {
      case "requisition_review":
        return "requisition reviewer";
      case "requisition_approval":
        return "requisition approver";
      case "content_review":
        return "content reviewer";
      case "content_approval":
        return "content approverer";
      default:
        break;
    }
  };
  return (
    <>
      <Row>
        <Col lg="12">
          <h3 className="mb-4">Select Related Associates</h3>
          <h6 className="mb-4">Please assign the vendor</h6>
          {/* <Row> */}
          <Col className="mb-4">
            <Form.Floating className="custom-form-floating">
              <Form.Select
                className=""
                id="floatingInput1"
                value={vendor}
                onChange={(e) => {
                  //console.log("e", e.target.value);
                  setVendor(e.target.value);
                }}
              >
                <option value="">Please select the vendor</option>
                {vendors.map((item) => {
                  return (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Label htmlFor="floatingInput">Vendor</Form.Label>
            </Form.Floating>
          </Col>
          {/* </Row> */}
        </Col>
        {associatesList.map((_associate, _index) => {
          return (
            <Col lg="12" key={_index + 1}>
              <h6 className="mb-4">
                Please select the {taskToPerson(_associate.key)} below
              </h6>
              {/* <Row> */}

              {_associate.value.department &&
                Object.keys(_associate.value.department).length > 0 &&
                Object.keys(_associate.value.department).map((_department) => {
                  const maxSelectionLength =
                    _associate.value.department[_department]?.max ?? null;
                  const minSelectionLength =
                    _associate.value.department[_department]?.min ?? null;

                  const isRequired =
                    _associate.value.department[_department]?.required != 0;

                  const sequence =
                    _associate.value.department[_department]?.sequence ?? null;

                  return (
                    <Col className="mb-4" key={_index + 1}>
                      <Select
                        name="filetypes"
                        options={getAssociateValues(
                          _associate.value.department[_department]
                            ?.associates ?? null
                        )}
                        placeholder={`Please select the ${_department} ${taskToPerson(
                          _associate.key
                        )}`}
                        // className='selectpicker form-control'
                        // data-style='py-0'

                        value={
                          selectedAssociates.find(
                            (item) =>
                              item.department == _department &&
                              item.task == _associate.key
                          )?.selectedAssociates ?? []
                        }
                        onChange={(selectedvalues) =>
                          handleAssociateChange(
                            selectedvalues,
                            _department,
                            _associate.key,
                            maxSelectionLength ?? null,
                            minSelectionLength ?? null,
                            sequence ?? null,
                            isRequired ?? false
                          )
                        }
                        isMulti
                      />
                      <span className="fs-7 text-primary">
                        {maxSelectionLength == minSelectionLength
                          ? `Select ${maxSelectionLength} options`
                          : `Select between ${minSelectionLength} and ${maxSelectionLength} options`}
                      </span>
                    </Col>
                  );
                })}

              {/* </Row> */}
            </Col>
          );
        })}
      </Row>
      {/* <h6 className="mb-4">Please select the other people to be notified</h6>
      <Col lg="12" className="mb-4">
        <Form.Floating className="custom-form-floating">
          <Multiselect
            options={peopleOptionsOptions}
            displayValue="label"
            placeholder="Select Associates"
            style={{
              chips: {
                background: "#3256b8",
              },
              multiSelectContainer: {
                highlightOption: {
                  background: "#3256b8",
                },
              },
            }}
          />
        </Form.Floating>
      </Col> */}

      <Col lg="12" className="d-flex justify-content-end gap-2">
        <SubmitButton
          className="btn btn-primary flex-grow-1 flex-sm-grow-0"
          type="button"
          onClick={onInitiateRequisition}
        >
          Initiate Requisition
        </SubmitButton>
      </Col>
    </>
  );
};

export default memo(RequisitionAssociates);
