import React from "react";
import Default from "../../../../layouts/dashboard/default";
import CreateSegment from "../pages/create-segment";
import SegmentHistory from "../pages/segment-history";
import Segmentlisting from "../pages/segment-listing";
import SegmentDetails from "../pages/segment-details";
import SegmentDashboard from "../pages/segment-dashboard";
import RecommendedSegments from "../pages/recommended-segments";

export const SegmentRouter = [
  // Animated
  {
    path: "segment",
    element: <Default />,
    children: [
      {
        path: "dashboard",
        element: <SegmentDashboard />,
      },
      {
        path: "listing",
        element: <Segmentlisting />,
      },
      {
        path: "all/:type",
        element: <Segmentlisting />,
      },
      {
        path: "recommended",
        element: <RecommendedSegments />,
      },
      {
        path: "create",
        element: <CreateSegment key={"add"} />,
      },
      {
        path: "edit/:id",
        element: <CreateSegment key={"edit"} />,
      },
      {
        path: "history",
        element: <SegmentHistory />,
      },
      {
        path: "details/:id",
        element: <SegmentDetails />,
      },
    ],
  },
];
