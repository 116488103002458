import React, { memo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../../components/skeleton";

function BasicInfoTabSkeleton() {
  return (
    <Card>
      <Card.Body>
        <div className="d-flex flex-column gap-md-5 gap-4">
          <Row>{<ReuseTextRow />}</Row>

          <Row>
            {<ReuseTextRow />}
            <Skeleton variant="text" className="mt-2 ms-3" width="20%" />
          </Row>

          <Row>
            <Col md={12}>
              <div className="mb-1">
                <Skeleton variant="text" />
              </div>
            </Col>
            <Col md={10} xs={12}>
              <Skeleton
                variant="square"
                className="rounded-2"
                height={100}
                width="100%"
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="mb-1">
                <Skeleton variant="text" />
              </div>
              <Skeleton variant="text" width="82%" />
            </Col>
            <Col md={10} xs={12}>
              <div className="d-flex gap-3">
                <Skeleton
                  variant="square"
                  className="rounded-2"
                  height={40}
                  width="90%"
                />{" "}
                <Skeleton
                  variant="square"
                  className="rounded-2"
                  height={40}
                  width="10%"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="d-flex align-items-start gap-3 mb-3">
              <div className="d-flex gap-2 w-100 align-items-center">
                <Skeleton variant="text" width="30%" className="m-0 p-0" />
                <Skeleton
                  variant="square"
                  height={15}
                  width={50}
                  className="rounded-pill m-0 p-0"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(BasicInfoTabSkeleton);

function ReuseTextRow() {
  return (
    <div>
      <Col md={12}>
        <div className="mb-1">
          <Skeleton variant="text" />
        </div>
        <Skeleton variant="text" width="82%" />
      </Col>
      <Col md={10} xs={12}>
        <Skeleton
          variant="square"
          className="rounded-2"
          height={40}
          width="100%"
        />
      </Col>
    </div>
  );
}
