import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import Table from "react-bootstrap/Table";

function VideoListSkeleton() {
  return (
    <div>
      <Skeleton variant="text" />
      <Table striped bordered>
        <thead>
          <tr>
            <th colSpan={1}>
              <Skeleton variant="text" />
              <div className="bg-white p-2 rounded-3">
                <Skeleton variant="square" width="100%" className="rounded-2" />
              </div>
            </th>
            {[...Array(4)].map((_, i) => (
              <th key={i}>
                <Skeleton variant="text" />
                <div className="bg-white p-2 rounded-3">
                  <Skeleton
                    variant="square"
                    width="100%"
                    className="rounded-2"
                  />
                </div>
              </th>
            ))}
            <th className="m-0 p-4 pt-3 d-flex align-items-start justify-content-start">
              <Skeleton variant="text" />
            </th>
          </tr>
        </thead>

        <tbody>
          {[...Array(5)].map((_, i) => (
            <tr key={i} className="bg-white">
              <td colSpan={1}>
                <div className="d-flex gap-2  ">
                  <div>
                    <Skeleton
                      variant="rect"
                      width={175}
                      height={90}
                      className="rounded-3"
                    />
                  </div>
                  <div>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </div>
                </div>
              </td>

              {[...Array(4)].map((_, i) => (
                <td key={i}>
                  <Skeleton variant="text" />
                </td>
              ))}

              <td>
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <Skeleton variant="square" width={20} className="rounded-3" />
                  <Skeleton variant="square" width={20} className="rounded-3" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default memo(VideoListSkeleton);
