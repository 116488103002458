import { formatLocalizedDate } from "../../../../../common/utils/utils";

export const initialDate = {
  start_date: formatLocalizedDate(
    new Date(new Date().setDate(new Date().getDate() - 8)),
    "YYYY-MM-DD[T]00:00:00.000Z"
  ),

  end_date: formatLocalizedDate(
    new Date(new Date().setDate(new Date().getDate() - 1)),
    "YYYY-MM-DD[T]23:59:59.000Z"
  ),
};
