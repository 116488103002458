import React, {
  Fragment,
  useRef,
  useState,
  useCallback,
  useEffect,
  memo,
} from "react";
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";
import ReactTable from "../../../../../lib/table/react-table";
import postService from "../../../../../api/services/post-service";
import ViewModal from "../../../../../components/partials/common/view-modal";
import DeleteModal from "../../../../../components/partials/common/delete-modal";
import { getService } from "../../../../../api/services/get-services";
import { changeTableStateToPayload } from "../../../../../common/utils/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  ADD_ASSOCIATION_POST,
  ALL_ASSOCIATION_POST,
  CITY_ASSOCIATION_GET,
  CREATE_ASSOCIATION_GET,
  DELETE_ASSOCIATION_POST,
  STATE_ASSOCIATION_GET,
  UPDATE_ASSOCIATION_POST,
  ZONE_ASSOCIATION_GET,
} from "../../../../../api/endpoints/association-endpoints";
import toast from "react-hot-toast";
import FloatingButton from "../../../../../components/form-components/floating-button";
import withAuthorization from "../../../../../components/hoc/with-authorization";
import ReactTableLoader from "../../../../../skeleton/react-table-loader/react-table-loader";

const AllAssociation = () => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [associationList, setAssociationList] = useState([]);
  const [viewModalShow, setViewModalShow] = useState(false);
  const [viewAssociation, setViewAssociation] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const [countryList, setCountryList] = useState([]);
  console.log("countryList", countryList);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  // const associationDetails = useRef({ countryId: "" });
  const post_json = useRef({
    default: "all",

    pagination: {
      limit: 10,
      offset: 0,
    },
    sort: {
      association_id: "desc",
    },
  });
  //yup and formik for validation of form fields of priority_name

  const validationSchema = yup.object().shape({
    // association_id: yup.string().required("Association ID is required"),
    association_parent_id: yup
      .string()
      .trim()
      .required("Parent Association is required")
      .matches(
        /^\S+$/,
        "Parent Association ID must not contain only whitespace"
      ),
    association_name: yup
      .string()
      .trim()
      .required("Association Name is required")
      .matches(/^\S+$/, "Association Name must not contain only whitespace"),
    association_description: yup
      .string()
      .trim()
      .required("Description is required")
      .matches(/^\S+$/, "Description must not contain only whitespace"),
    association_country_id: yup
      .string()
      .trim()
      .required("Country id is required")
      .matches(/^\S+$/, "Country id must not contain only whitespace"),
    association_zone_id: yup
      .string()
      .trim()
      .required("Zone id is required")
      .matches(/^\S+$/, "Zone id must not contain only whitespace"),
    association_state_id: yup
      .string()
      .trim()
      .required("State id is required")
      .matches(/^\S+$/, "State id must not contain only whitespace"),
    association_city_id: yup
      .string()
      .trim()
      .required("City ID is required")
      .matches(/^\S+$/, "City ID must not contain only whitespace"),
  });
  //formik
  const formik = useFormik({
    initialValues: {
      // association_id: "",
      association_parent_id: "",
      association_name: "",
      association_description: "",
      association_country_id: "",
      association_zone_id: "",
      association_state_id: "",
      association_city_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      // console.log("values===>", values);

      const postValues = {
        ...values,
        association_name: values.association_name.trim(),
        association_description: values.association_description.trim(),
      };

      console.log("postValues===>", postValues);

      try {
        setLoading(true);
        const response = await postService(
          isEdit
            ? UPDATE_ASSOCIATION_POST + "/" + selectedAssociation
            : ADD_ASSOCIATION_POST,
          {
            ...postValues,
            // association_active: 1,
            status: 1,
          }
        );
        if (response) {
          setLoading(false);
          setCreateModalShow(false);
          fetchAssociation(post_json.current);
          setIsEdit(false);
          toast.success(response?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("ACTION_TYPE_SERVICE_ERROR", error);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = formik;
  console.log("formic test", values, errors);
  //fetch countries
  const fetchAssociation = async (data) => {
    try {
      // alert("fetching");
      // setLoading(true);
      const response = await postService(ALL_ASSOCIATION_POST, data);
      const response_country_list = await getService(
        CREATE_ASSOCIATION_GET,
        data
      );
      console.log("COUNTRY_LIST", response_country_list?.data?.data?.country);
      console.log("ASSOCIATION_SERVICE_RESPONSE", response.data.data.rows);
      console.log("COLUMNS", Object.keys(response.data.data.rows[0]));
      if (response.data.data.rows.length > 0) {
        setAssociationList(response.data.data.rows);
        setTotalCount(response.data.data.totalCount);
        setLoading(false);
      }
      if (response_country_list?.data?.data?.country?.length > 0) {
        setCountryList(response_country_list?.data?.data?.country);
      }
    } catch (error) {
      setLoading(false);
      console.log("ASSOCIATION_SERVICE_ERROR", error);
    }
  };
  const viewModal = async (association) => {
    console.log("association===>", association);
    let dataJson = {
      "Association ID": association?.association_id ?? "none",
      "Parent Association":
        association?.association_parent_id?.association_name ?? "none",
      Name: association?.association_name ?? "none",
      Description: association?.association_description ?? "none",
      Country: association?.association_country_id?.country_name ?? "none",
      Zone: association?.association_zone_id?.zone_name ?? "none",
      State: association?.association_state_id?.state_name ?? "none",
      City: association?.association_city_id?.city_name ?? "none",
    };
    setViewAssociation(dataJson);
    setViewModalShow(true);
  };
  const viewModalClose = () => {
    setViewModalShow(false);
  };
  const deleteAssociation = async () => {
    try {
      setLoading(true);
      const response = await getService(
        `${DELETE_ASSOCIATION_POST}/${selectedAssociation}`
      );
      if (response) {
        console.log("association delete response", response);
        setLoading(false);
        setDeleteModalShow(false);
        fetchAssociation(post_json.current);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("ASSOCIATION_SERVICE_ERROR", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchAssociation(post_json.current);
  }, []);
  const onTableStateChange = useCallback(
    async (state) => {
      let payload = changeTableStateToPayload(state);
      post_json.current = payload;
      fetchAssociation(payload);
    },
    [associationList, loading, post_json.current]
  );
  const onDeleteAssociation = (association) => {
    setSelectedAssociation(association.association_id);
    setDeleteModalShow(true);
  };
  const columns = [
    {
      Header: "Association ID",
      accessor: "association_id",
    },
    {
      Header: "Parent Association",
      accessor: "association_parent_id",
      Cell: ({ value }) => value?.association_name ?? "none",
      // console.log("parent", value),
    },
    {
      Header: "Association Name",
      accessor: "association_name",
    },
    // {
    //   Header: "Description",
    //   accessor: "association_description",
    // },
    {
      Header: "Association Country",
      accessor: "association_country_id",
      Cell: ({ value }) => value?.country_name,
    },
    {
      Header: "Association Zone",
      accessor: "association_zone_id",
      Cell: ({ value }) => value?.zone_name,
    },
    {
      Header: "Association State",
      accessor: "association_state_id",
      Cell: ({ value }) => value?.state_name,
    },
    {
      Header: "Association City",
      accessor: "association_city_id",
      Cell: ({ value }) => value?.name,
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center gap">
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => viewModal(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Eye", "dual-tone", 32)}
              </span>
            </Button>
          </div>

          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => onEditAssociationClick(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Pencil", "dual-tone")}
              </span>
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => onDeleteAssociation(row.original)}
            >
              <span className="btn-inner">
                {findIcon("Trash", "dual-tone", 32)}
              </span>
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const handleCreate = () => {
    resetForm();
    setCreateModalShow(true);
  };
  const onEditAssociationClick = (association) => {
    console.log("association===>", association);
    setIsEdit(true);
    setSelectedAssociation(association.association_id);
    //set formik values
    setValues({
      association_parent_id:
        association?.association_parent_id?.association_id ?? "none",
      association_name: association?.association_name ?? "none",
      association_description: association?.association_description ?? "none",
      association_country_id:
        association?.association_country_id?.country_id ?? "none",
      association_state_id:
        association?.association_state_id?.state_id ?? "none",
      association_zone_id: association?.association_zone_id?.zone_id ?? "none",
      association_city_id: association?.association_city_id?.city_id ?? "none",
    });
    setCreateModalShow(true);
  };
  // console.log("twwwst", associationDetails.current);
  const getZoneList = async (countryId) => {
    try {
      const response_zone_list = await getService(
        ZONE_ASSOCIATION_GET + "/" + countryId
      );
      console.log("ZONE_LIST", response_zone_list?.data?.data?.zone);
      if (response_zone_list?.data?.data?.zone?.length > 0) {
        setZoneList(response_zone_list?.data?.data?.zone);
      }
    } catch (error) {
      console.log("ASSOCIATION_ZONE_ERROR", error);
    }
  };
  const getStateList = async (zoneId) => {
    try {
      const response_state_list = await getService(
        STATE_ASSOCIATION_GET + "/" + zoneId
      );
      console.log("STATE_LIST", response_state_list?.data?.data?.state);
      if (response_state_list?.data?.data?.state?.length > 0) {
        setStateList(response_state_list?.data?.data?.state);
      }
    } catch (error) {
      console.log("ASSOCIATION_STATE_ERROR", error);
    }
  };
  const getCityList = async (stateId) => {
    try {
      const response_city_list = await getService(
        CITY_ASSOCIATION_GET + "/" + stateId
      );
      console.log("CITY_LIST", response_city_list);
      if (response_city_list?.data?.data?.city?.length > 0) {
        setCityList(response_city_list?.data?.data?.city);
      }
    } catch (error) {
      console.log("ASSOCIATION_CITY_ERROR", error);
    }
  };
  return (
    <Fragment>
      <FloatingButton onClick={handleCreate} />
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h4 className="card-title">All Association Names</h4>
              {/* <Button
                type="button"
                variant="primary"
                className="fw-bold rounded-3"
                onClick={() => handleCreate()}
              >
                {findIcon("Plus", "dual-tone", 32)} Add Association Name
              </Button> */}
            </Card.Header>
            <Card.Body className="px-3">
              {!loading ? (
                associationList.length > 0 && (
                  <ReactTable
                    data={associationList}
                    columns={columns}
                    recordsTotal={totalCount}
                    onTableStateChange={onTableStateChange}
                    initialState={post_json.current}
                    isRowSelectionAvailable={false}
                    recordsPerPage={10}
                  />
                )
              ) : (
                <ReactTableLoader />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        show={deleteModalShow}
        modalClose={() => setDeleteModalShow(false)}
        onSubmit={deleteAssociation}
      />
      <ViewModal
        title={"View Priority Name Information"}
        show={viewModalShow}
        modalClose={viewModalClose}
        data={viewAssociation}
      />
      {/* create a add Priority Name modal with Priority Name name and description */}
      <Modal
        show={createModalShow}
        size="lg"
        onHide={() => {
          setCreateModalShow(false);
          setIsEdit(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Edit" : "Add"} Association Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* ASSOCIATION ID */}
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Association ID</Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Association ID"
                name="association_id"
                onChange={handleChange}
                value={values.association_id}
                onBlur={handleBlur}
                isInvalid={touched.association_id && errors.association_id}
              >
                <option value="">Select Association ID</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.association_id}
              </Form.Control.Feedback>
            </Form.Group> */}
            {/* ASSOCIATION PARENT ID */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Parent Association <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Parent Association"
                name="association_parent_id"
                onChange={handleChange}
                value={values.association_parent_id}
                onBlur={handleBlur}
                isInvalid={
                  touched.association_parent_id && errors.association_parent_id
                }
                disabled={associationList.length <= 0}
              >
                <option value="">Select Parent Association</option>
                {associationList.length > 0 &&
                  associationList.map((_p) => (
                    <option
                      value={_p?.association_parent_id?.association_id}
                      key={_p?.association_parent_id?.association_id}
                    >
                      {_p?.association_parent_id?.association_name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.association_parent_id}
              </Form.Control.Feedback>
            </Form.Group>
            {/* ASSOCIATION NAME */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Association Name"
                name="association_name"
                onChange={handleChange}
                value={values.association_name}
                onBlur={handleBlur}
                isInvalid={touched.association_name && errors.association_name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.association_name}
              </Form.Control.Feedback>
            </Form.Group>
            {/* ASSOCIATION DESCRIPTION */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Description <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Association Description"
                name="association_description"
                onChange={handleChange}
                value={values.association_description}
                onBlur={handleBlur}
                isInvalid={
                  touched.association_description &&
                  errors.association_description
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.association_description}
              </Form.Control.Feedback>
            </Form.Group>

            {/* ASSOCIATION COUNTRY */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Country <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Association Country Id"
                name="association_country_id"
                onChange={(e) => {
                  handleChange(e);
                  getZoneList(e.target.value);
                }}
                value={values.association_country_id}
                onBlur={handleBlur}
                isInvalid={
                  touched.association_country_id &&
                  errors.association_country_id
                }
              >
                <option value="">Select Country</option>
                {countryList.map((_c) => (
                  <option value={_c?.country_id}>{_c?.country_name}</option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.association_country_id}
              </Form.Control.Feedback>
            </Form.Group>

            {/* ASSOCIATION ZONE */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Zone</Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Association State Id"
                name="association_zone_id"
                onChange={(e) => {
                  handleChange(e);
                  getStateList(e.target.value);
                }}
                value={values.association_zone_id}
                onBlur={handleBlur}
                isInvalid={
                  touched.association_zone_id && errors.association_zone_id
                }
                disabled={!values.association_country_id}
              >
                <option value="">Select Zone</option>
                {zoneList.length > 0 &&
                  zoneList?.map((_z) => (
                    <option value={_z?.zone_id}>{_z?.zone_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.association_zone_id}
              </Form.Control.Feedback>
            </Form.Group>

            {/* ASSOCIATION STATE */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>State</Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Association State Id"
                name="association_state_id"
                onChange={(e) => {
                  handleChange(e);
                  getCityList(e.target.value);
                }}
                value={values.association_state_id}
                onBlur={handleBlur}
                isInvalid={
                  touched.association_state_id && errors.association_state_id
                }
                disabled={!values.association_zone_id}
              >
                <option value="">Select State</option>
                {stateList.length > 0 &&
                  stateList?.map((_s) => (
                    <option value={_s?.state_id}>{_s?.state_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.association_state_id}
              </Form.Control.Feedback>
            </Form.Group>

            {/* ASSOCIATION CITY ID */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>City</Form.Label>
              <Form.Select
                // type="text"
                // placeholder="Enter Association City Id"
                name="association_city_id"
                onChange={handleChange}
                value={values.association_city_id}
                onBlur={handleBlur}
                isInvalid={
                  touched.association_city_id && errors.association_city_id
                }
                disabled={!values.association_state_id}
              >
                <option value="">Select Association City</option>
                {cityList.length > 0 &&
                  cityList.map((_c) => (
                    <option value={_c?.city_id}>{_c?.city_name}</option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.association_city_id}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setCreateModalShow(false);
              setIsEdit(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {isEdit ? "Update" : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default withAuthorization(memo(AllAssociation));
