import React, { memo, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";

import mobile from "../../../../../assets/images/mobileWhatsapp.png";
import postService from "../../../../../api/services/post-service";
import {
  WHATSAPP_TEMPLATE_CREATE_GET,
  WHATSAPP_TEMPLATE_EDIT,
  WHATSAPP_TEMPLATE_UPDATE,
} from "../../../../../api/endpoints/template-management-endpoints";
import { getService } from "../../../../../api/services/get-services";
import * as Yup from "yup";
import { useFormik } from "formik";
import postServiceSmsGenarator from "../../../../../api/services/post-service-sms-generator";
import TemplateSuggestionCard from "../../components/template-suggestion-card";
import Masonry from "react-masonry-css";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { WHATSAPP_TEMPLATE_ADD } from "../../../../../api/endpoints/template-management-endpoints";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import PaginationLoader from "../../../../../components/loader/pagination-loader";
import SubmitButton from "../../../../../components/form-components/submit-button";
const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  // 768: 2,
  500: 1,
};
const CreateWhatsappTemplate = ({ modalClose, type }) => {
  const { redirectTo } = useRedirect();
  const templateContentRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [editType, setEditType] = useState("autoGenerateFrom"); //autoGenerateFrom/newTemplate
  const [smsText, setSmsText] = useState("");
  const [openSmsVariableSugession, setOpenSmsVariableSugession] =
    useState(false);
  const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
  const [generatedSmsList, setGeneratedSmsList] = useState([]);
  const [selectedSms, setSelectedSms] = useState("");
  const [templateTone, setTemplateTone] = useState("");
  const [TemplateVariableType, setTemplateVariableType] = useState([]);
  const [templateVariableList, setTemplateVariableList] = useState([]);
  const [selectedVariableType, setSelectedVariableType] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [templateCategory, setTemplateCategory] = useState([]);
  const params = useParams();
  const { state } = useLocation();

  const initialValues = {
    name: "",
    description: "",
    template_name: "",
    whatsapp_category: "",
    whatsapp_template_type: "",
    // template_id: "",
    sender: "",
    header_type: "",
    call_to_action: "",
    whatsapp_language_code: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    template_name: Yup.string().required("Please enter template name"),
    whatsapp_category: Yup.string().required("Please select category"),
    whatsapp_template_type: Yup.string().required(
      "Please select template type"
    ),
    sender: Yup.string().required("Please select sender"),
    header_type: Yup.string().required("Please select header type"),
    is_draft: 0,
  });
  //using useFormik hook

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleReset,
    values,
    errors,
    touched,
    setErrors,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log("submit values ==>", values);

      let {
        template_name,
        whatsapp_category,
        whatsapp_template_type,
        template_id,
        sender,
        header_type,
        is_draft,
      } = values;
      let newObj = {
        is_draft: is_draft ?? "0",
        whatsapp_name: template_name,
        whatsapp_content: smsText,
        whatsapp_category_id: whatsapp_category,
        whatsapp_template_type: whatsapp_template_type,
        whatsapp_sender: sender,
        whatsapp_header_type: header_type,
        //need to make dynamic
        whatsapp_call_to_action: "1",
        whatsapp_duedate: "2023-06-09",
        whatsapp_priority_id: 1,
        whatsapp_workflow_id: 5,
      };

      // const newObj = validateObject(obj);

      // console.log("obj for create ==>", obj);
      if (templateId == "") {
        handleTemplateCreate(newObj);
      } else {
        handleTemplateEdit(newObj);
      }
    },
  });

  const validateObject = (obj) => {
    // console.log("obj to validate ==>", obj);
    const newObj = {};

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === "string") {
        newObj[key] = value.trimStart(); // Remove leading spaces
      } else {
        newObj[key] = value;
      }
    }

    return newObj;
  };

  const handleTemplateCreate = async (obj) => {
    console.log("payload", obj);

    try {
      setLoading(true);
      const response = await postService(WHATSAPP_TEMPLATE_ADD, obj);
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      } else {
        setLoading(false);
        toast.success(response.data.message);
        // console.log("OUTPUT", response.data);
        // let whatsapp_id = response.data.data.whatsapp_id;
        // let actionAddResponse = await postService(
        //   "/template_whatsapp/second-step-add/" + whatsapp_id,
        //   {}
        // );
        // console.log("actionAddResponse", actionAddResponse);

        redirectTo("template-management/whatsapp-template");
      }
    } catch (error) {
      console.log("error while create template ==>", error);
      setLoading(false);
    }
  };

  const handleTemplateEdit = async (obj) => {
    try {
      setLoading(true);
      const response = await postService(
        WHATSAPP_TEMPLATE_UPDATE + `/${templateId}`,
        obj
      );
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
      // console.log("response after create template ==>", response);
      if (response.data.success == true) {
        setLoading(false);
        toast.success(response.data.message);
        redirectTo("template-management/whatsapp-template");
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error while create template ==>", error);
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if (values.name == "") {
      setErrors({ ...errors, name: "Please enter template name" });
      return;
    }
    if (values.description == "") {
      setErrors({ ...errors, description: "Please enter description" });
      return;
    }
    if (!errors.name && !errors.description) {
      getSmsTemplateSuggestion();
    }
  };
  useEffect(() => {
    fetchWhatsAppCreateData();
    const id = params.id;
    if (!id) {
      if (state?.smsEditType) {
        setEditType(state?.smsEditType);
        setSmsText(state?.suggestionSmsContent);
      } else {
        setEditType("autoGenerateFrom");
        setTemplateId("");
      }
    } else {
      setEditType("newTemplate");
      setTemplateId(id);
      fetchWhatsAppTemplateDetails(id);
    }
  }, []);

  const fetchWhatsAppTemplateDetails = async (id) => {
    try {
      setLoading(true);
      const response = await getService(WHATSAPP_TEMPLATE_EDIT + `/${id}`);
      console.log("WHATSAPP_TEMPLATE_DETAILS_GET", response.data);
      const responseData = response.data.data;
      if (response.data.success == true) {
        const content = responseData.template.whatsapp_content.replace(
          /^<p>(.*)<\/p>$/,
          "$1"
        );
        // console.log("content", content);
        setSmsText(content);
        setValues({
          ...values,
          template_name: responseData.template.whatsapp_name,
          whatsapp_category:
            responseData.template.whatsapp_category_id?.category_id,
          whatsapp_template_type:
            responseData.template.whatsapp_template_type?.id,
          // template_id: responseData.template.whatsapp_template_id,
          sender: responseData.template.whatsapp_sender,
          header_type: responseData.template.whatsapp_header_type?.id,
          call_to_action: responseData.template.whatsapp_call_to_action?.id,
          whatsapp_language_code: responseData.template.whatsapp_language_code,
        });
        setTemplateCategory(responseData.category);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };
  const handleSmsText = (e) => {
    setSmsText(e.target.value);
    // setSmsTextLength(e.target.value.length);
  };
  const handleSmsSugession = (e) => {
    setSmsText(`${smsText} ${e.target.outerText}`);
  };
  const handleEmojiClick = (data) => {
    // alert("handleEmojiClick" + data);
    setTemplateTone(data);
  };
  const getSmsTemplateSuggestion = async () => {
    setSuggestionIsLoading(true);
    const templateSuggestionPayload = {
      action: values.name + " " + values.description,
      tone: templateTone,
      type: "whatsapp",
    };
    try {
      // setVariableIsLoading(true);
      const response = await postServiceSmsGenarator(templateSuggestionPayload);
      console.log("suggestion list ", response.data);
      setSuggestionIsLoading(false);
      parseGeneratedSmsList(response.data.output);
    } catch (error) {
      setSuggestionIsLoading(false);
      toast.error("Something went wrong while auto generating suggestions");
      console.log("GENERATION_ERROR", error);
    }
  };
  const parseGeneratedSmsList = (data) => {
    console.log("parseGeneratedSmsList", data);
    if (data) {
      const smsList = JSON.parse(data);
      let generatedList = smsList.map((item) => {
        return Object.values(item)[0];
      });
      setGeneratedSmsList(generatedList);
    }
  };
  const onCreateBtnClick = () => {
    setEditType("newTemplate");
  };
  const onTemplateSuggestionClick = (data) => {
    setSelectedSms(data);
    // setEditType("templateSuggestion");
  };
  const onContinueToEditClick = () => {
    setEditType("newTemplate");
    // setSmsTextLength(selectedSms?.length);
    setSmsText(selectedSms);
  };
  const fetchWhatsAppCreateData = async () => {
    try {
      setLoading(true);
      const response = await getService(WHATSAPP_TEMPLATE_CREATE_GET);
      console.log("Whatsapp-TemplateField", response.data.data);
      if (response.data.data.variable) {
        console.log("Whatsapp-TemplateField data", response.data.data.variable);
        let data = response.data.data.variable;
        let allVariableType = data.map((item) => item.variable_type);
        console.log("allVariableType ==>", allVariableType);
        const uniqueSet = new Set(allVariableType.map((item) => item));
        const uniqueVariableType = [...uniqueSet];
        console.log("uniqueVariableType ==>", uniqueVariableType);
        setTemplateVariableType(uniqueVariableType);
        let variableTypeList = [];
        uniqueVariableType.forEach((item) => {
          let temp = data.filter((item1) => item1.variable_type == item);
          variableTypeList.push(temp);
        });
        console.log("variableTypeList:: ==>", variableTypeList);
        setTemplateVariableList(variableTypeList);

        setLoading(false);
      }
      if (response.data.data.category) {
        setTemplateCategory(response.data.data.category);
        setLoading(false);
      }
      if (response.isError) {
        toast.error(response?.error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // console.log("TemplateField", error);
    }
  };
  const handleTemplateVariableClick = (e) => {
    const textarea = templateContentRef.current;
    if (textarea) {
      const textToAdd = e.target.outerText;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      const textBefore = textarea.value.substring(0, startPos);
      const textAfter = textarea.value.substring(endPos);
      const newText = textBefore + textToAdd + textAfter;

      setSmsText(newText);
    }
  };
  const handleCloseClick = () => {
    if (type === "content-studio") {
      modalClose();
    } else {
      redirectTo("template-management/whatsapp-template");
    }
  };

  const onSaveDraftClick = (args) => {
    console.log("Save draft click", args);
    setValues({ ...args, is_draft: 1 });
    handleSubmit();
    console.log("Save draft click end ");
  };

  // console.log("from errors", errors);
  return (
    <>
      <Card className="p-2 p-md-0 rounded-3">
        <Card.Header className="py-3 px-0">
          <Row className="align-items-center">
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <h3 className="m-0">Create WhatsApp Template</h3>
              <div className="d-flex justify-content-start gap-4 mt-3 mt-md-0">
                {/* {templateId == "" ? (
                  <a
                    href="javascript:void(0)"
                    eventKey="link-1"
                    className="d-inline-flex align-items-center gap-1"
                  >
                    {findIcon("Eye", "solid", "22")}
                    Preview & Test
                  </a>
                ) : (
                  ""
                )} */}
                {/* <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1"
                >
                  {findIcon("Save", "dual-tone", 22)}
                  Save Draft
                </a> */}
                <a
                  href="javascript:void(0)"
                  eventKey="link-1"
                  className="d-inline-flex align-items-center gap-1 link-danger"
                  // onClick={modalClose}
                  onClick={handleCloseClick}
                >
                  {findIcon("Square X", "dual-tone", 22)}
                  Close
                </a>
              </div>
            </div>
          </Row>
        </Card.Header>

        {editType == "newTemplate" && (
          <Card.Body className="pt-0 pt-md-2 px-0 px-md-3">
            <div className="p-4 bg-soft-secondary rounded-3">
              <Row className="justify-content-center align-items-start">
                <Col lg={9} className="position-relative">
                  <div className="p-2 p-md-4 bg-white rounded-3 d-flex flex-column">
                    <Row>
                      {/* name  */}
                      <Col lg={4} md={6} className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="template_name"
                            autoComplete="Name"
                            placeholder="Name"
                            name="template_name"
                            onChange={handleChange}
                            value={values.template_name}
                            isInvalid={
                              touched.template_name && !!errors.template_name
                            }
                          />
                          <Form.Label htmlFor="template_namename">
                            Name
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.template_name}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>

                      {/* sender  */}
                      <Col lg={4} md={6} className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="sender"
                            autoComplete="Name"
                            placeholder="Sender"
                            name="sender"
                            onChange={handleChange}
                            value={values.sender}
                            isInvalid={touched.sender && !!errors.sender}
                          />
                          <Form.Label htmlFor="sender">Sender</Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.sender}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>

                      {/* category  */}
                      <Col lg={4} md={6} className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            // id="whatsapp_category"
                            name="whatsapp_category"
                            value={values.whatsapp_category}
                            isInvalid={
                              touched.whatsapp_category &&
                              !!errors.whatsapp_category
                            }
                            onChange={handleChange}
                          >
                            <option value="">Select Category</option>
                            {templateCategory?.map((item, index) => (
                              <option key={index} value={item.category_id}>
                                {item.category_name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Label htmlFor="whatsapp_category">
                            Category
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.whatsapp_category}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>

                      {/* template type  */}
                      <Col lg={4} md={6} className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            id="whatsapp_template_type"
                            name="whatsapp_template_type"
                            value={values.whatsapp_template_type}
                            isInvalid={
                              touched.whatsapp_template_type &&
                              !!errors.whatsapp_template_type
                            }
                            onChange={handleChange}
                          >
                            <option value="">Select Template Type</option>
                            <option value="0">Promotional</option>
                            <option value="1">Transactional</option>
                          </Form.Select>
                          <Form.Label htmlFor="whatsapp_template_type">
                            Template Type
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.whatsapp_template_type}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>

                      {/* template id  */}
                      {/* <Col lg={4} md={6} className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Control
                            type="text"
                            className=""
                            id="template_id"
                            autoComplete="Name"
                            placeholder="Template ID"
                            name="template_id"
                            onChange={handleChange}
                            value={values.template_id}
                            isInvalid={
                              touched.template_id && !!errors.template_id
                            }
                          />
                          <Form.Label htmlFor="template_namename">
                            Template ID
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.template_id}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col> */}

                      {/* header type  */}
                      <Col lg={4} md={6} className="mb-4">
                        <Form.Floating className="custom-form-floating">
                          <Form.Select
                            // id="whatsapp_category"
                            name="header_type"
                            value={values.header_type}
                            isInvalid={
                              touched.header_type && !!errors.header_type
                            }
                            onChange={handleChange}
                          >
                            <option value="">Select Header Type</option>
                            <option value="NONE">none</option>
                            <option value="VIDEO">video</option>
                            <option value="IMAGE">image</option>
                            <option value="DOCUMENT">document</option>
                          </Form.Select>
                          <Form.Label htmlFor="whatsapp_category">
                            Header Type
                          </Form.Label>
                          <Form.Control.Feedback type="invalid">
                            {errors.header_type}
                          </Form.Control.Feedback>
                        </Form.Floating>
                      </Col>

                      {/* content  */}
                      <Col lg={12} className="mb-4">
                        <Form.Floating className="custom-form-floating position-relative d-flex flex-column sms-suggestion--floatingForm">
                          <textarea
                            ref={templateContentRef}
                            className={
                              // smsText?.length > 160
                              //   ? "form-control border border-1 border-danger"
                              //   :
                              "form-control"
                            }
                            aria-label="With textarea"
                            value={smsText}
                            onChange={(e) => handleSmsText(e)}
                          ></textarea>
                          <Form.Label htmlFor="floatingInput">
                            Message
                          </Form.Label>
                          <Button
                            onClick={() =>
                              setOpenSmsVariableSugession(
                                !openSmsVariableSugession
                              )
                            }
                            variant="warning"
                            className="btn-icon w-auto px-2 position-absolute sms-suggestion__button"
                          >
                            {findIcon("Suggestion", "dual-tone", "24")}
                          </Button>
                        </Form.Floating>
                        {/* <div className="d-flex justify-content-between align-items-center mt-1">
                          <div className="ms-auto">
                            {smsText?.length} whatsapp
                          </div>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                  {openSmsVariableSugession == true ? (
                    <Col lg={12} className="mt-5">
                      <div className="w-100 rounded-3 sms-suggestion mt-0 p-3 h-auto bg-white z-index-2">
                        <div className="justify-content-between align-items-center mb-3 d-flex">
                          <h5 className="mb-0">Message Suggestion</h5>
                          <a
                            href="javascript:void(0)"
                            onClick={() => setOpenSmsVariableSugession(false)}
                            className="d-inline-flex align-items-center gap-1 link-danger"
                          >
                            {findIcon("Square X", "dual-tone", 22)}
                            Close
                          </a>
                        </div>
                        <div className="d-flex gap-3 p-2 border border-1 rounded-3">
                          <div
                            className="nav sms-suggestion__tabNav gap-2 overflow-auto flex-shrink-0 flex-column"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            {TemplateVariableType.map((item) => (
                              <a
                                href="javascript:void(0)"
                                className={`position-relative nav-link flex-grow-1 rounded-3  ${
                                  selectedVariableType == item
                                    ? "bg-primary text-white active"
                                    : "bg-soft-primary text-dark"
                                }`}
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                                onClick={(e) => setSelectedVariableType(item)}
                              >
                                {item}
                                <div className="sms-suggestion__tabNav__icon position-absolute transition">
                                  {findIcon("Arrow Right", "solid", "16")}
                                </div>
                              </a>
                            ))}
                          </div>
                          <div
                            className="tab-content flex-grow-1 p-2"
                            id="v-pills-tabContent"
                          >
                            <div
                              className="tab-pane d-flex gap-2 fade show active flex-wrap "
                              role="tabpanel"
                              aria-labelledby="v-pills-home-tab"
                            >
                              {templateVariableList.map((item) =>
                                item.map((item1) =>
                                  item1.variable_type ==
                                  selectedVariableType ? (
                                    <Badge
                                      pill
                                      bg="soft-info"
                                      className="fw-normal px-3 border border-1 border-info cursor-pointer"
                                      onClick={(e) =>
                                        handleTemplateVariableClick(e)
                                      }
                                    >
                                      {item1.variable_value}
                                    </Badge>
                                  ) : null
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  ) : null}

                  <Col lg={12} className="mb-5 mt-5 d-flex justify-content-end">
                    <SubmitButton
                      variant="primary"
                      onClick={(e) => onSaveDraftClick(values)}
                      disabled={smsText.length == 0}
                    >
                      {loading ? <PaginationLoader /> : "Save As Draft"}
                    </SubmitButton>
                    <SubmitButton
                      className="ms-2"
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={smsText.length == 0}
                    >
                      {templateId ? (
                        loading ? (
                          <PaginationLoader />
                        ) : (
                          "Update"
                        )
                      ) : loading ? (
                        <PaginationLoader />
                      ) : (
                        "Send for Approval"
                      )}
                    </SubmitButton>
                  </Col>
                </Col>

                <Col lg={3} xs={12} className="position-sticky top-0">
                  <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                    <div className="d-flex position-relative mobile-preview">
                      <img
                        src={mobile}
                        alt="mobile"
                        className="mobile h-auto w-100 position-relative"
                      />
                      {smsText && (
                        <div className="p-2 h-100 w-100 position-absolute d-flex pt-5 pb-4">
                          <div className="px-5 mx-100 overflow-auto w-100 flex-grow-1 mt-3">
                            <span className="d-block position-relative px-3 py-2 rounded-3 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                              {smsText}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card.Body>
        )}

        {editType == "autoGenerateFrom" && (
          <Card.Body className="pt-0 pt-md-2 px-0 px-md-3">
            <div className="p-2 p-md-4 bg-soft-secondary rounded-3">
              <Row className="justify-content-center align-items-start">
                <Col lg={9} xs={12} className="position-relative">
                  <div className="p-2 p-md-4 bg-white rounded-3 h-100 d-flex flex-column justify-content-between">
                    <div>
                      <Row>
                        <Col lg={9} className="mb-3">
                          <Form.Floating className="custom-form-floating">
                            <Form.Control
                              type="text"
                              className=""
                              id="floatingInput1"
                              autoComplete="Name"
                              placeholder="Name"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              isInvalid={errors.name}
                              disabled={suggestionIsLoading}
                            />
                            <Form.Label htmlFor="floatingInput">
                              Name
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>
                        <Col lg={9} className="mb-3">
                          <Form.Floating className="h-100 custom-form-floating">
                            <Form.Control
                              as="textarea"
                              type="text"
                              className="h-100"
                              id="floatingInput1"
                              autoComplete="Name"
                              placeholder="Name"
                              name="description"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.description}
                              isInvalid={errors.description}
                              disabled={suggestionIsLoading}
                            />
                            <Form.Label htmlFor="floatingInput">
                              Prompt for Template Creation
                            </Form.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.description}
                            </Form.Control.Feedback>
                          </Form.Floating>
                        </Col>
                        <Col lg={9} className="mb-4 mt-auto ">
                          <p>Tone Selector:</p>
                          <div className="col-12 align-item-between d-flex flex-wrap gap-3">
                            <span
                              aria-label="Professional"
                              onClick={() => handleEmojiClick("Professional")}
                              className={`${
                                templateTone == "Professional"
                                  ? "border-warning fw-semibold"
                                  : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                            >
                              Professional
                            </span>
                            <span
                              aria-label="Casual"
                              onClick={() => handleEmojiClick("Casual")}
                              className={`${
                                templateTone == "Casual"
                                  ? "border-warning fw-semibold"
                                  : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                            >
                              Casual
                            </span>
                            <span
                              aria-label="Serious"
                              onClick={() => handleEmojiClick("Serious")}
                              className={`${
                                templateTone == "Serious"
                                  ? "border-warning fw-semibold"
                                  : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                            >
                              Serious
                            </span>
                            <span
                              aria-label="Humorous"
                              onClick={() => handleEmojiClick("Humorous")}
                              className={`${
                                templateTone == "Humorous"
                                  ? "border-warning fw-semibold"
                                  : "text-secondary"
                              } border border-1 rounded-3 px-3 py-2 bg-soft-warning `}
                            >
                              Humorous
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Col lg={12}>
                      <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                      >
                        {generatedSmsList?.length > 0 &&
                          generatedSmsList.map((item, index) => (
                            <>
                              {/* <h1>sms:{item}</h1> */}
                              <TemplateSuggestionCard
                                data={item}
                                click={onTemplateSuggestionClick}
                                selectedSms={selectedSms}
                                type=""
                              />
                            </>
                          ))}
                      </Masonry>
                    </Col>
                    <Col className="flex-grow-0 pb-2 pb-md-0">
                      <div className="d-flex gap-3 justify-content-end">
                        <Button
                          variant="info"
                          onClick={onCreateBtnClick}
                          disabled={suggestionIsLoading}
                        >
                          Skip
                        </Button>
                        <Button
                          variant="primary"
                          onClick={checkValidation}
                          disabled={suggestionIsLoading}
                        >
                          {!suggestionIsLoading
                            ? "Auto Generate"
                            : "Generating Suggestion..."}
                        </Button>
                        {selectedSms && (
                          <Button
                            variant="primary"
                            onClick={onContinueToEditClick}
                          >
                            Continue To Edit
                          </Button>
                        )}
                      </div>
                    </Col>
                  </div>
                </Col>
                <Col
                  lg={3}
                  xs={12}
                  className="position-sticky top-0 mt-4 mt-md-0"
                >
                  <div className="bg-soft-info h-100 p-2 rounded-5 border border-2 border-dashed border-info d-flex flex-column">
                    {/* <h5>Preview</h5> */}
                    <div className="position-relative flex-fill">
                      <img
                        src={mobile}
                        alt="mobile"
                        className="h-auto w-100 d-block position-relative z-0"
                      />
                      <div className="position-absolute top-0 w-100 h-auto p-2 z-1">
                        {selectedSms && (
                          <div className="p-2 h-100 w-100 position-absolute d-flex pt-5 pb-4">
                            <div className="px-5 mx-100 overflow-auto w-100 flex-grow-1 mt-3">
                              <span className="d-block position-relative px-3 py-2 rounded-3 text-dark fs-7 lh-base shadow-lg shadow bg-white">
                                {selectedSms}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default CreateWhatsappTemplate;
