import { Fragment } from "react";
import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import ReactTable from "../../../../lib/table/react-table";
import useRedirect from "../../../../components/custom-hooks/use-redirect";

const OnboardingManager = () => {
  const { redirectTo } = useRedirect();
  const action = (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="icon-32"
      width="32"
      height="32"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
  const onboarding_tasks = {
    columns: [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Assign To",
        accessor: "assignTo",
      },
      {
        Header: "Assign By",
        accessor: "assignBy",
      },
      {
        Header: "User ID",
        accessor: "userId",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Due Time",
        accessor: "dueTime",
        Cell: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        Header: "Sending Status",
        accessor: "sendingStatus",
      },
      {
        Header: "Complete Date",
        accessor: "completeDate",
        Cell: ({ value }) => (value ? new Date(value).toLocaleString() : ""),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => (
          <div className="d-flex align-items-center justify-content-center">
            <Badge bg={"primary"} className="me-2">
              {row.original.status}
            </Badge>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters:true,
        Cell: ({ row }) => (
          <div className="d-flex justify-content-center">
            <Button
              className="btn btn-icon btn-sm rounded-pill"
              to="#"
              role="button"
              onClick={() => redirectTo("onboarding/onboarding-task-profile-details")}
            >
              <span className="btn-inner">{action}</span>
            </Button>
          </div>
        ),
      },
    ],
    data: [
      {
        slNo: 1,
        id: "TASK-001",
        name: "Complete Project Report",
        assignTo: "John Doe",
        assignBy: "Jane Smith",
        userId: "johndoe123",
        priority: "High",
        startTime: "2023-05-09T09:00:00",
        dueTime: "2023-05-13T17:00:00",
        sendingStatus: "Pending",
        poke: "No",
        completeDate: null,
        status: "In Progress",
        action: "Edit",
      },
      {
        slNo: 2,
        id: "TASK-002",
        name: "Fix Website Bugs",
        assignTo: "Jane Smith",
        assignBy: "John Doe",
        userId: "janesmith123",
        priority: "Medium",
        startTime: "2023-05-10T10:00:00",
        dueTime: "2023-05-14T18:00:00",
        sendingStatus: "Sent",
        poke: "No",
        completeDate: "2023-05-14T14:30:00",
        status: "Completed",
        action: "Edit",
      },
      {
        slNo: 3,
        id: "TASK-003",
        name: "Design New Logo",
        assignTo: "Bob Johnson",
        assignBy: "John Doe",
        userId: "bobjohnson123",
        priority: "Low",
        startTime: "2023-05-11T11:00:00",
        dueTime: "2023-05-15T19:00:00",
        sendingStatus: "Pending",
        poke: "Yes",
        completeDate: null,
        status: "New",
        action: "Edit",
      },
    ],
  };
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">All Tasks</h3>
        </div>
        <div className="d-flex justify-content-between align-items-center rounded flex-wrap gap-3">
          <div className="form-group mb-0 ">
            <select className="form-select">
              <option defaultValue>Sort By</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="form-group mb-0 ">
            <Flatpickr
              options={{ mode: "range", minDate: "today" }}
              className="form-control range_flatpicker"
              placeholder="24 Jan 2022 to 23 Feb 2022"
            />
          </div>
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              <div className="fancy-table table-responsive rounded">
                <ReactTable
                  data={onboarding_tasks.data}
                  columns={onboarding_tasks.columns}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default OnboardingManager;
