import React,{memo} from "react";
import { Fragment } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../components/skeleton";

function AudienceSkeleton() {
  return (
    <>
      <Row>
        {/* dropdown */}
        {/* <div className="channel-dropdown position-absolute end-0 fs-6 bg-white border px-3 py-1 rounded-3 w-auto">
          <Skeleton variant="text" width={70} />
        </div> */}
        {Array.from({ length: 3 }).map((_, index) => (
          <Col key={index} md={4} sm={12}>
            {<RenderCard />}
          </Col>
        ))}
      </Row>

      <Card>
        <Card.Body>
          <Row>
            {Array.from({ length: 2 }).map((_, index) => (
              <Col key={index} md={6} sm={12} lg={6}>
                <Skeleton
                  variant="square"
                  width="100%"
                  height={330}
                  className="rounded-3"
                />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default memo(AudienceSkeleton);

function RenderCard() {
  return (
    <Card>
      <Card.Header>
        <div className="d-flex align-items-center justify-content-center">
          <Skeleton variant="text" width={150} height={30} />
        </div>
      </Card.Header>
      <Card.Body className="p-3 px-2">
        <Skeleton
          variant="square"
          width="100%"
          height={250}
          className="rounded-3"
        />
      </Card.Body>
    </Card>
  );
}
