import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import postService from "../../../../api/services/post-service";
import dayjs from "dayjs";
import article_1 from "../../../../assets/modules/file-manager/images/article_1.jpg";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { useFetchCreateRequisition } from "../../../../queries/query-hooks/requisition-hook";
//yup and formik
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
  FILE_UPLOAD,
  FIRST_STEP_ADD,
  FIRST_STEP_UPDATE,
  GET_CONTENT_VAULT,
  GET_WORKFLOW,
  THIRD_STEP_ADD,
  THIRD_STEP_UPDATE,
} from "../../../../api/endpoints/requisition-endpoints";
import { getService } from "../../../../api/services/get-services";
import uploadService from "../../../../api/services/upload-service";
import app_config from "../../../../common/data/app_config";
import FormSteps from "../../../../components/form-components/form-steps";
import withAuthorization from "../../../../components/hoc/with-authorization";
import RequisitionEditLoader from "../../../../skeleton/requisition/requisition-edit-loader";
import * as authSelectors from "../../../../store/auth/selectors";
import { findIcon } from "../../../dashboard/icons";
import ContentRepurposeStep from "../components/content-repurpose-step";
import RequisitionAssociates from "../components/requisition-associates";
import RequisitionLeftSection from "../components/requisition-left-section";
import SubmitButton from "../../../../components/form-components/submit-button";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";

const AddRequisition = () => {
  const { id } = useParams();
  const isDraft = useRef(false);
  const { redirectTo } = useRedirect();
  const [step, setStep] = useState(1);
  const [brands, setBrands] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);
  const [requisitionAssociatesData, setRequisitionAssociatesData] = useState(
    []
  );
  const [requisitionId, setRequisitionId] = useState("");
  const [priorities, setPriorities] = useState([]);
  const requisitionAssociates = useRef({});
  const [requisitionType, setRequisitionType] = useState("");
  const [workflow, setWorkflow] = useState({});

  const user = useSelector(authSelectors.user);
  // const [user, setUser] = useState({});
  // //console.log(selectedvalues);

  const onSuccess = (data) => {
    //console.log("CREATE_DATA", data);
    // modify brands, content_categories, systems, file_types for react-select
    const brands = data?.brands?.map((brand) => {
      return {
        value: brand.principal_entity_id,
        label: brand.principal_entity_name,
      };
    });
    //console.log("BRANDS", brands);
    const fileTypes = data?.filetypes?.map((file_type) => {
      return { value: file_type.file_type_id, label: file_type.file_type_name };
    });
    //console.log("FILE_TYPES", fileTypes);
    const priorities = data?.priroties?.map((priority) => {
      return { value: priority.priority_id, label: priority.priority_name };
    });
    setPriorities(priorities);
    setBrands(brands);
    setFileTypes(fileTypes);

    if (id) {
      onSuccessEdit(data.requisition);
    }
  };
  const onError = (error) => {
    //console.log("ERROR", error);
  };
  const { data: createData, isLoading } = useFetchCreateRequisition(
    id,
    onSuccess,
    onError
  );

  //console.log("CREATE_DATA", createData);
  let post_json = useRef({
    default: "all",
    pagination: {
      limit: 50,
      offset: 0,
    },
  });
  const [vaultContent, setVaultContent] = useState([]);
  const getVault = async () => {
    let response = await postService(GET_CONTENT_VAULT, post_json.current);
    //console.log("vault response", response);
    let rows = response.data.data?.rows;
    let vault = [];
    if (rows?.length == 0) {
      return;
    }
    rows?.forEach((row) => {
      vault.push({
        id: row.content_id.content_id,
        type: row.content_id.content_file_type_id?.file_type_name,
        title: row.content_id.content_title,
      });
    });
    //console.log("vault", vault);
    setVaultContent(vault);
  };
  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await getService(GET_WORKFLOW);
        //console.log("WORKFLOW", response.data.data);
        if (response.data.data) {
          let workflowResponse = response.data.data;
          setRequisitionType(workflowResponse.workflow_id);

          setWorkflow((prev) => {
            return {
              workflow_id: workflowResponse.workflow_id,
              workflow_type: workflowResponse.workflow_type,
              workflow_rule: workflowResponse.workflow_rule,
            };
          });
        }
      } catch (error) {
        //console.log("ERROR", error);
      }
    };
    fetchWorkflows();
    return () => {};
  }, []);
  useEffect(() => {
    getVault();
    return () => {};
  }, [step]);
  const omit = (obj, arr) =>
    Object.keys(obj)
      .filter((k) => !arr.includes(k))
      .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});

  const getEndPoint = () => {
    if (step == 1 && !id) return FIRST_STEP_ADD;
    else if (step == 1 && id) return FIRST_STEP_UPDATE + id;
    else if (step == 3 && !id) return THIRD_STEP_ADD + requisitionId;
    else if (step == 3 && id) return THIRD_STEP_UPDATE + id;
  };
  //useState for initial form fields
  const initialValues = {
    requisition_title: "",
    requisition_description: "",
    requisition_duedate: "",
    requisition_brand_id: "",
    requisition_priority_id: "",
    file_and_no_of_submissions: [
      {
        id: "file_type_no_of_submissions_0",
        file_type: "",
        no_of_submissions: 0,
      },
    ],
    requisition_files: [],
  };
  //create a yup schema upon above fields
  const validationSchema = Yup.object().shape({
    requisition_title: Yup.string().required(
      "Please enter title of requisition"
    ),
    requisition_description: Yup.string().required(
      "Please enter description of your requisition"
    ),
    requisition_duedate: Yup.date()
      .min(dayjs(), "Selected date cannot be in the past")
      .required("Please enter the due date of your requisition"),
    requisition_brand_id: Yup.string().required(
      "Please select brand from below list"
    ),
    requisition_priority_id: Yup.string().required(
      "Please select priority from below list "
    ),
    requisition_files: Yup.array().required("Please upload reference files"),
    file_and_no_of_submissions: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required("Required"),
        file_type: Yup.string().required("File type is required"),
        no_of_submissions: Yup.number()
          .positive("Number of submissions must be greater than 0")
          .required("Number of submissions is required")
          .typeError("Number of submissions must be a valid number"),
      })
    ),
  });
  const { showBackdrop, hideBackdrop } = useBackdrop();
  //handle form with useFormik hook
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      let postObj = {
        requisition_title: values.requisition_title,
        requisition_description: values.requisition_description,
        requisition_duedate: values.requisition_duedate,
        requisition_priority_id: values.requisition_priority_id,
        requisition_brand_id: values.requisition_brand_id,
        requisition_files: values.requisition_files,
        requisition_submission: values.file_and_no_of_submissions.map(
          (file) => {
            return {
              file_type_id: file.file_type,
              no_of_submissions: file.no_of_submissions,
            };
          }
        ),
        requisition_associates: requisitionAssociates.current,
        requisition_workflow_id: workflow.workflow_id,
      };
      // postService
      //console.log("POST_OBJ", postObj);
      showBackdrop();
      let response = await postService(
        getEndPoint(),
        step == 1
          ? omit(postObj, ["requisition_associates"])
          : { requisition_associates: requisitionAssociates.current }
      );
      //console.log("RESPONSE", response);
      if (response.data.success) {
        hideBackdrop();
        if (isDraft.current) {
          toast.success("Requisition saved to draft");
          isDraft.current = false;
          return;
        }
        if (step == 1 || step == 2) {
          if (response.data?.data?.requisition_id) {
            setRequisitionId(response.data?.data?.requisition_id);
          }
          setStep(step + 1);
          return;
        }
        redirectTo("requisition/listing");
        toast.success(`Requisition ${id ? "update" : "add"}ed successfully`);
      }
    },
  });
  //handle formik values
  const {
    values,
    touched,
    setTouched,
    errors,
    handleChange,
    setValues,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = formik;
  console.log("VALUES", values);
  console.log("ERRORS", errors);
  console.log("Touched", touched);
  const modifyRequisitionAssociatesData = (associates) => {
    let tempAssociates = [];
    //console.log("ASSOCIATESDATA", associates);
    for (const _task in associates) {
      if (Object.hasOwnProperty.call(associates, _task)) {
        const _eachTask = associates[_task];
        let sequences = _eachTask.sequence;
        //console.log("SEQUENCE", sequences);
        for (const sequence of sequences) {
          //console.log("EACHSEQUENCE", sequence);
          for (const dept in sequence) {
            if (Object.hasOwnProperty.call(sequence, dept)) {
              const eachDept = sequence[dept];
              //console.log("EACHDEPT", eachDept);
              tempAssociates.push({
                task: _task,
                department: dept,
                selectedAssociates: eachDept.map((_) => {
                  return { value: _.id };
                }),
              });
            }
          }
        }
      }
    }

    //console.log("TEMPASSOCIATES", tempAssociates);
    setRequisitionAssociatesData(tempAssociates);
  };
  //console.log("REQUISITION_ASSOCIATES_DATA", requisitionAssociatesData);

  const onSuccessEdit = (data) => {
    //console.log("EDIT_DATA", data);
    setValues({
      requisition_title: data.requisition_title,
      requisition_description: data.requisition_description,
      requisition_duedate: data.requisition_duedate,
      requisition_brand_id: data.requisition_brand_id?.principal_entity_id,
      requisition_priority_id: data.requisition_priority_id?.priority_id,
      file_and_no_of_submissions: data.requisition_submission.map((file, i) => {
        return {
          id: `file_type_no_of_submissions_${i}`,
          file_type: file.file_type_id,
          no_of_submissions: file.no_of_submissions,
        };
      }),
      requisition_files: data.reference_files?.map((_) => _.reference_file),
    });

    modifyRequisitionAssociatesData(JSON.parse(data.requisition_associates));
    setRequisitionType(data.requisition_workflow_id);
  };
  const onAddMoreClick = () => {
    const tempFileAndNoOfSubmissions = values.file_and_no_of_submissions
      ? [...values.file_and_no_of_submissions]
      : [];
    tempFileAndNoOfSubmissions.push({
      id: `file_type_no_of_submissions_${tempFileAndNoOfSubmissions.length}`,
      file_type: "",
      no_of_submissions: "",
    });
    setFieldValue("file_and_no_of_submissions", tempFileAndNoOfSubmissions);
  };
  const handleSubmissionFormChange = (e) => {
    const { name, value, id } = e.target;

    const tempFileAndNoOfSubmissions = values.file_and_no_of_submissions
      ? [...values.file_and_no_of_submissions]
      : [];
    const index = tempFileAndNoOfSubmissions.findIndex(
      (fileAndNoOfSubmission) => fileAndNoOfSubmission.id == id
    );
    if (index == -1) {
      tempFileAndNoOfSubmissions.push({ id, [name]: value });
    }
    if (index != -1) {
      tempFileAndNoOfSubmissions[index][name] = value;
    }
    // setFileAndNoOfSubmissions(tempFileAndNoOfSubmissions);
    setValues({
      ...values,
      file_and_no_of_submissions: tempFileAndNoOfSubmissions,
    });

    //console.log("FILE_AND_NO_OF_SUBMISSIONS", tempFileAndNoOfSubmissions);
  };
  const handleChangeRequisitionType = (e) => {
    const { value } = e.target;
    // alert(value);
    setRequisitionType(value);
  };
  const onCrossClick = (id) => {
    if (values.file_and_no_of_submissions.length == 1) {
      toast.error("Atleast one file type is required");
      return;
    }
    const tempFileAndNoOfSubmissions = values.file_and_no_of_submissions
      ? [...values.file_and_no_of_submissions]
      : [];
    const index = tempFileAndNoOfSubmissions.findIndex(
      (fileAndNoOfSubmission) => fileAndNoOfSubmission.id == id
    );
    if (index !== -1) {
      tempFileAndNoOfSubmissions.splice(index, 1);
    }
    console.log("TEMP_FILE_AND_NO_OF_SUBMISSIONS", tempFileAndNoOfSubmissions);
    setValues({
      ...values,
      file_and_no_of_submissions: tempFileAndNoOfSubmissions,
    });
  };
  //console.log("WORKFLOW_RULE", workflowRule);
  const onSubmitRequisition = (associatesValues) => {
    console.log("ASSOCIATES_VALUES", associatesValues);

    requisitionAssociates.current = associatesValues;
    // if (Object.keys(errors) > 0) {
    //   console.log("ERRORS", errors);
    //   toast.error("Please fill all the required fields");
    //   return;
    // }
    handleSubmit();
  };
  const selectedFileTypes = useMemo(() => {
    const tempFileAndNoOfSubmissions = values.file_and_no_of_submissions
      ? [...values.file_and_no_of_submissions]
      : [];
    return tempFileAndNoOfSubmissions.map(
      (fileAndNoOfSubmission) =>
        fileTypes.find(
          (fileType) => fileType.value == fileAndNoOfSubmission.file_type
        )?.label
    );
  }, [values.file_and_no_of_submissions]);
  const handleChangeReferenceFiles = async (e) => {
    const { files } = e.target;
    //console.log("FILES", files);
    // upload images and then setFieldValue of reference_files
    const urls = await uploadFiles([...files]);
    //console.log("URLS", urls);
    if (urls) {
      setValues({
        ...values,
        requisition_files: [...values.requisition_files, ...urls],
      });
    }
  };
  const onCrossImageClick = (e) => {
    let tempRequisitionFiles = values.requisition_files
      ? [...values.requisition_files]
      : [];
    tempRequisitionFiles = tempRequisitionFiles.filter(
      (file, idx) => idx !== e
    );
    setValues({
      ...values,
      requisition_files: tempRequisitionFiles,
    });
  };
  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(FILE_UPLOAD, formdata);
      if (response.data?.success) {
        //console.log("RESPONSE", response);
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);
        //console.log("URLS", urls);
        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }
  const nextStep = () => {
    // if (Object.keys(errors).length > 0) {
    //   toast.error("Please fill all the required fields");
    //   return;
    // }
    handleSubmit();
    // setStep(step + 1);
  };
  const saveToDraft = (e) => {
    e.preventDefault();
    // if (Object.keys(errors).length > 0) {
    //   toast.error("Please fill all the required fields");
    //   return;
    // }
    isDraft.current = true;
    handleSubmit();
  };
  const onStepClick = useCallback(
    (key) => {
      switch (key) {
        case 1:
          setStep(1);
          break;
        case 2:
          if (Object.keys(errors).length > 0) {
            toast.error("Please fill the mandatory fields");
          } else {
            step == 1 ? nextStep() : setStep(2);
          }
          break;
        case 3:
          if (Object.keys(errors).length > 0) {
            toast.error("Please fill the mandatory fields");
          } else {
            step == 1 ? nextStep() : setStep(3);
          }
          break;

        default:
          break;
      }
    },
    [step, errors]
  );
  if (isLoading) return <RequisitionEditLoader />;
  return (
    <>
      <Fragment>
        {/* {step == 2 && <ContentDetails type={""} />} */}
        <FormSteps
          onStepClick={onStepClick}
          steps={[
            {
              name: "Basic",
              active: step == 1,
              completed: step > 1,
            },
            {
              name: "Content Repurpose",
              active: step == 2,
              completed: step > 2,
            },
            {
              name: "Associates",
              active: step == 3,
              completed: step > 3,
            },
          ]}
        />
        {step == 1 && (
          <>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
              <div className="d-flex flex-column">
                <h3 className="m-0">
                  {id ? "Edit" : "Add"} Requisition - Basic
                </h3>
              </div>
            </div>

            <Card>
              <Card.Body>
                <Card className="bg-soft-warning mb-3 shadow-none">
                  <Card.Body className="p-3">
                    <p className="m-0 fs-7">
                      Please submit a requisition with the task details,
                      approvers and vendor. You can specify whether you need a
                      single submission or multiple submissions from the
                      vendor.The requisition and the vendor’s submission need to
                      be approved by the approvers before adding to the content
                      database.
                    </p>
                  </Card.Body>
                </Card>
                <Form>
                  <Row>
                    {/* <div className="col-12 mb-4">
                      <div className="d-flex flex-row gap-3">
                        {workflows.map((workflow, _i) => {
                          let type = workflow.workflow_type?.toLowerCase();
                          let id = workflow.workflow_id;
                          //console.log("TYPE", type);
                          return (
                            <Form.Check
                              key={id}
                              className={
                                "border border-1 rounded-3 px-3 py-2 d-flex align-items-center justify-content-center " +
                                (type == requisitionType
                                  ? "bg-primary text-white"
                                  : "")
                              }
                            >
                              <FormCheck.Input
                                type="radio"
                                name="radios"
                                className="m-0"
                                id={`inline-radio-1${_i}`}
                                value={id}
                                checked={id == requisitionType}
                                onChange={handleChangeRequisitionType}
                              />
                              <FormCheck.Label
                                className="ms-2"
                                htmlFor={`inline-radio-1${_i}`}
                              >
                                {capitalizeFirstLetter(type)}
                              </FormCheck.Label>
                            </Form.Check>
                          );
                        })}
                      </div>
                    </div> */}
                    <div className="col-12 col-md-8">
                      <Form.Floating className="custom-form-floating w-100 mb-4">
                        <Form.Control
                          type="text"
                          className=""
                          id="floatingInput1"
                          autoComplete="Title"
                          placeholder="Title"
                          name="requisition_title"
                          value={values.requisition_title}
                          onChange={handleChange}
                          handleBlur={handleBlur}
                          isInvalid={
                            touched.requisition_title &&
                            !!errors.requisition_title
                          }
                        />
                        <Form.Label htmlFor="floatingInput">Title</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.requisition_title}
                        </Form.Control.Feedback>
                      </Form.Floating>
                      <Form.Floating className="custom-form-floating w-100">
                        <Form.Control
                          as={"textarea"}
                          rows={3}
                          class="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea2"
                          name="requisition_description"
                          value={values.requisition_description}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={
                            touched.requisition_description &&
                            !!errors.requisition_description
                          }
                        ></Form.Control>
                        <Form.Label htmlFor="fname">Description</Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.requisition_description}
                        </Form.Control.Feedback>
                      </Form.Floating>
                    </div>
                    <div className="col-12 col-md-4">
                      <Form.Floating className="custom-form-floating w-100 mb-4">
                        <Form.Control
                          type="date"
                          className=""
                          name="requisition_duedate"
                          value={values.requisition_duedate}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          min={dayjs().format("YYYY-MM-DD")}
                          isInvalid={
                            touched.requisition_duedate &&
                            !!errors.requisition_duedate
                          }
                        />
                        <Form.Label htmlFor="floatingInput">
                          Expected Due Date
                        </Form.Label>
                        <Form.Control.Feedback type="invalid">
                          {errors.requisition_duedate}
                        </Form.Control.Feedback>
                      </Form.Floating>
                      <Form.Floating className="custom-form-floating w-100 mb-4">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          name={"requisition_brand_id"}
                          value={values.requisition_brand_id}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={
                            touched.requisition_brand_id &&
                            !!errors.requisition_brand_id
                          }
                        >
                          <option value="">Select Brand</option>
                          {brands?.map((brand) => (
                            <option value={brand.value} key={brand.value}>
                              {brand.label}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Select Brand
                        </Form.Label>
                        {errors.requisition_brand_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.requisition_brand_id}
                          </Form.Control.Feedback>
                        )}
                      </Form.Floating>
                      <Form.Floating className="custom-form-floating w-100 mb-4">
                        <Form.Select
                          className=""
                          id="floatingInput1"
                          name="requisition_priority_id"
                          value={values.requisition_priority_id}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={
                            touched.requisition_priority_id &&
                            !!errors.requisition_priority_id
                          }
                        >
                          <option value="">Please Select Priority </option>
                          {priorities?.map((priority) => (
                            <option value={priority.value} key={priority.value}>
                              {priority.label}
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Label htmlFor="floatingInput">
                          Priority
                        </Form.Label>
                        {errors.requisition_priority_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.requisition_priority_id}
                          </Form.Control.Feedback>
                        )}
                      </Form.Floating>
                    </div>
                    <Fragment>
                      <Col lg="12" className="mb-4 mt-4">
                        <Row>
                          {values?.file_and_no_of_submissions?.map((e, i) => (
                            <Col
                              lg="6"
                              className="mb-3"
                              key={`file_type_no_of_submissions_${i}`}
                            >
                              <div
                                className={`text-secondary border border-1 ${
                                  errors?.file_and_no_of_submissions?.length >
                                    0 &&
                                  touched?.file_and_no_of_submissions?.length >
                                    0 &&
                                  errors.file_and_no_of_submissions[i] &&
                                  touched.file_and_no_of_submissions[i]
                                    ? "border-danger"
                                    : "border-light"
                                } p-3 rounded-3 w-100 d-flex flex-row gap-2`}
                              >
                                <Form.Floating className="custom-form-floating flex-grow-1 w-auto">
                                  <Form.Select
                                    id={`file_type_no_of_submissions_${i}`}
                                    name="file_type"
                                    value={
                                      values?.file_and_no_of_submissions
                                        ?.length > 0
                                        ? values?.file_and_no_of_submissions[i]
                                            ?.file_type
                                        : null
                                    }
                                    onChange={handleSubmissionFormChange}
                                  >
                                    <option value="">Select File Type</option>
                                    {fileTypes?.map((fileType) => (
                                      <option
                                        value={fileType.value}
                                        key={fileType.value}
                                      >
                                        {fileType.label}
                                      </option>
                                    ))}
                                  </Form.Select>

                                  <Form.Label htmlFor="floatingInput">
                                    Select Type
                                  </Form.Label>
                                </Form.Floating>

                                <Form.Floating className="custom-form-floating  flex-grow-1 w-auto">
                                  <Form.Control
                                    type="number"
                                    min={1}
                                    name="no_of_submissions"
                                    id={`file_type_no_of_submissions_${i}`}
                                    autoComplete="No of Submissions"
                                    placeholder="No of Submissions"
                                    value={
                                      values?.file_and_no_of_submissions
                                        ?.length > 0
                                        ? values?.file_and_no_of_submissions[i]
                                            ?.no_of_submissions
                                        : null
                                    }
                                    onChange={handleSubmissionFormChange}
                                  />
                                  <Form.Label htmlFor="floatingInput">
                                    No of Submissions
                                  </Form.Label>
                                </Form.Floating>
                                <Button
                                  variant="danger"
                                  className="px-3"
                                  id={`file_type_no_of_submissions_${i}`}
                                  onClick={() =>
                                    onCrossClick(
                                      `file_type_no_of_submissions_${i}` //id
                                    )
                                  }
                                >
                                  {findIcon("Trash", "dual-tone", 20)}
                                </Button>
                              </div>
                              {errors?.file_and_no_of_submissions?.length > 0 &&
                                touched?.file_and_no_of_submissions?.length >
                                  0 &&
                                errors.file_and_no_of_submissions[i] &&
                                touched.file_and_no_of_submissions[i] && (
                                  <span className="text-danger fs-7 mb-3">
                                    {errors?.file_and_no_of_submissions[i]
                                      ?.file_type ?? ""}{" "}
                                    {errors?.file_and_no_of_submissions[i]
                                      ?.no_of_submissions ?? ""}
                                  </span>
                                )}
                            </Col>
                          ))}

                          <Col
                            lg="2"
                            className="mb-2 d-flex align-items-center justify-content-start"
                            onClick={onAddMoreClick}
                          >
                            <Button
                              variant="outline-info"
                              className="rounded-3 d-flex align-items-center border-dashed justify-content-center gap-2"
                            >
                              {findIcon("Plus", "dual-tone", 20)}
                              Add More
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg="12" className="mb-4 mt-4">
                        <h6 className="mb-3">Add Reference Files</h6>
                        <Form.Control
                          type="file"
                          id="customFile"
                          multiple
                          onChange={handleChangeReferenceFiles}
                        />

                        {/* preview added images with a cross button at right top corner */}
                        <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                          {values.requisition_files?.map((file, index) => (
                            <div className="position-relative" key={index + 1}>
                              <img
                                className="rounded img-fluid avatar-120"
                                src={app_config.fileStoragePath + file}
                                alt="profile"
                                loading="lazy"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = article_1;
                                }}
                              />
                              <span
                                className="position-absolute top-0 end-0"
                                onClick={() => onCrossImageClick(index)}
                              >
                                {findIcon("Square X", "solid", 20)}
                              </span>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Fragment>

                    {/* {requisitionType == "brief" && (
                      <>
                        <Col lg="12">
                          <h6 className="mb-4">Please assign the vendor</h6>
      
                          <Col className="mb-4">
                            <Form.Floating className="custom-form-floating">
                              <Form.Select className="" id="floatingInput1">
                                <option value="">
                                  Please select the vendor
                                </option>

                                <option value="text">Marketing</option>

                                <option value="video">Medical</option>

                                <option value="audio">Compliance</option>
                              </Form.Select>
                              <Form.Label htmlFor="floatingInput">
                                Vendor
                              </Form.Label>
                            </Form.Floating>
                          </Col>
                        </Col>
                        <Col lg="12">
                          <h6 className="mb-4">
                            Please select the requisition approvers below
                          </h6>
      
                          <Col className="mb-4">
                            <Form.Floating className="custom-form-floating">
                              <Form.Select className="" id="floatingInput1">
                                <option value="">
                                  Please select the medical requisition
                                  approvers
                                </option>

                                <option value="text">Marketing</option>

                                <option value="video">Medical</option>

                                <option value="audio">Compliance</option>
                              </Form.Select>
                              <Form.Label htmlFor="floatingInput">
                                Medical
                              </Form.Label>
                              
                            </Form.Floating>
                          </Col>
                          <Col className="mb-4">
                            <Form.Floating className="custom-form-floating">
                              <Form.Select className="" id="floatingInput1">
                                <option value="">
                                  Please select the marketing requisition
                                  approvers
                                </option>

                                <option value="text">High</option>

                                <option value="video">Medium</option>

                                <option value="audio">Low</option>
                              </Form.Select>
                              <Form.Label htmlFor="floatingInput">
                                Marketing
                              </Form.Label>
                            
                            </Form.Floating>
                          </Col>
                    
                        </Col>
                      </>
                    )} */}

                    <Col lg="12">
                      <div className="col-12 form-group d-flex justify-content-end gap-2">
                        <Button
                          className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                          type="button"
                          onClick={saveToDraft}
                          // onClick={() => redirectTo("requisition/draft")}
                        >
                          Save as Draft
                        </Button>
                        <Button
                          className="btn btn-primary flex-grow-1 flex-sm-grow-0"
                          type="button"
                          onClick={nextStep}
                        >
                          {id ? "Update Requisition" : "Proceed to Next Step"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
        {step == 2 && (
          <ContentRepurposeStep
            brands={brands}
            values={values}
            vault={vaultContent}
            user={user}
            selectedFileTypes={selectedFileTypes}
            setStep={setStep}
          />
        )}
        {step == 3 && (
          <>
            <div className="mb-5">
              <Row>
                <Col lg="7">
                  <div className="p-3 bg-soft-primary rounded-3 overflow-auto scroll-content">
                    <RequisitionLeftSection
                      brands={brands}
                      values={values}
                      selectedFileTypes={selectedFileTypes}
                      user={user}
                      vaultContent={vaultContent}
                    />
                  </div>
                </Col>
                <Col lg="5" className="d-flex">
                  <div className="p-3 bg-soft-primary rounded-3 overflow-auto scroll-content">
                    <Card className="mb-0">
                      <Card.Body>
                        <Form>
                          <Row className="gap-4 gap-lg-0"></Row>

                          <RequisitionAssociates
                            workflow={workflow.workflow_rule}
                            formValues={values}
                            user={user}
                            onSubmitRequisition={onSubmitRequisition}
                            associateSelectedValues={requisitionAssociatesData}
                          />
                        </Form>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Fragment>
    </>
  );
};

export default withAuthorization(memo(AddRequisition));
