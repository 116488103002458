import React, { useRef } from "react";
import { Card, Image, Button, Col } from "react-bootstrap";
import { findIcon } from "../../../../dashboard/icons";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link, useParams } from "react-router-dom";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import { formatLocalizedDate } from "../../../../../common/utils/utils";
import ImageWithFallback from "../../../../../components/ImageWithFallback";

const CreativeList = ({ data }) => {
  const { project_id } = useParams();
  const { redirectTo } = useRedirect();

  const onCreativeClick = (creative, campaign_id) => {
    redirectTo(
      `brand-ads/creative/details/${project_id}/${creative}/${campaign_id}`
    );
  };

  return (
    <>
      {
        <Card data-aos="fade-up" data-aos-delay="500">
          <Card.Body>
            <Col md={12} className="mb-3 d-flex justify-content-between">
              <h5 className="mb-1 fw-semibold">
                Creative Listing
                <span className="bg-soft-primary p-1 fw-semibold d-inline-flex align-items-center justify-content-center rounded-pill px-2 fs-6 ms-2">
                  {data?.length}
                </span>
              </h5>
              <Link
                to={`/brand-ads/creative/${project_id}`}
                className="ms-auto fs-6"
              >
                View All{" "}
                <span className="transform-rotate-45">
                  {findIcon("Arrow Up", "outline", 24)}
                </span>
              </Link>
            </Col>

            <div className="overflow-hidden d-slider1">
              <>
                <Swiper
                  as="ul"
                  className="p-0 m-0 mb-2 list-inline swiper-wrapper"
                  slidesPerView={5}
                  spaceBetween={15}
                  navigation={false}
                  breakpoints={{
                    320: { slidesPerView: 1 },
                    550: { slidesPerView: 2 },
                    991: { slidesPerView: 2 },
                    1400: { slidesPerView: 3 },
                    1500: { slidesPerView: 3 },
                    1920: { slidesPerView: 3 },
                  }}
                  data-aos="fade-up"
                  data-aos-delay="700"
                >
                  {data &&
                    data?.map((item, index) => {
                      const fileType = item?.type;
                      return (
                        <>
                          <SwiperSlide key={`my-video-key}`}>
                            <div className="d-flex gap-3 border rounded-3 p-3">
                              {item.file &&
                                (fileType === "video" ? (
                                  <video
                                    autoPlay
                                    loop
                                    src={item.file}
                                    alt={item.name}
                                    className="rounded-3"
                                    style={{ maxWidth: "5rem", height: "auto" }}
                                  />
                                ) : (
                                  <div className="w-rem-8 h-auto rounded-3 ratio-9x16 flex-shrink-0 overflow-hidden">
                                    <ImageWithFallback
                                      src={item.file}
                                      alt={item.name}
                                      className="w-100 h-100 object-fit-contain rounded-3"
                                    />
                                  </div>
                                ))}
                              <div className="d-flex flex-column justify-content-between flex-grow-1 overflow-hidden">
                                {item.name && (
                                  <h5 className="fw-normal fs-6 m-0 two-line-clamp text-black mb-2 lh-sm">
                                    {item.name}
                                  </h5>
                                )}

                                <div className="d-flex justify-content-between align-items-center ">
                                  {item?.creative_width &&
                                    item?.creative_height && (
                                      <p className="m-0 fs-7">
                                        <span className="text-wrap word-break">
                                          {item.creative_width} x{" "}
                                          {item.creative_height}
                                          px |{" "}
                                          {/* {getRelativeDate(item.modified_on)}{" "} */}
                                          {formatLocalizedDate(
                                            item.modified_on,
                                            "DD-MM-YY"
                                          )}
                                          {/* {item.modified_on} */}
                                        </span>
                                      </p>
                                    )}

                                  <Button
                                    className="ms-auto rounded-2 px-3 py-1"
                                    variant="primary"
                                    size="sm"
                                    onClick={() =>
                                      onCreativeClick(
                                        item.creative_id,
                                        item.campaign_group_id
                                      )
                                    }
                                  >
                                    View
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                </Swiper>
              </>
            </div>
          </Card.Body>
        </Card>
      }
    </>
  );
};

export default CreativeList;
