import React from 'react'
import {
  Card,
  Col,
  Row,
} from "react-bootstrap";
import { useParams } from 'react-router-dom';
export default function DoctubeUserInsight() {
  const { link } = useParams();
  return (
    <Card.Body className="p-0 p-md-4">
      <div className=" bg-soft-secondary rounded-3">
        <Row className="justify-content-center align-items-start">
          {link && <Col lg={12} xs={12} className="position-relative mb-5 mb-lg-0">
            <iframe width="100%" height="720" src={atob(link)} frameborder="0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox" />
          </Col>}
        </Row>
      </div>
    </Card.Body>
  )
}
