import React from "react";
import { Card } from "react-bootstrap";
import Chart from "react-apexcharts";

function PolarChart({ labels, series, title, height = 300, graphColor }) {
  let chartOptions = {
    series: series || [],
    options: {
      colors: graphColor
        ? graphColor
        : ["#13388C", "#159A6C", "#156078", "#1D81A2", "#cecdcd"],
      chart: {
        type: "polarArea",
      },
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "bottom",
      },
      labels: labels || [],
      yaxis: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      // yaxis: {
      //   labels: {
      //     formatter: function (value) {
      //       return value + "%";
      //     },
      //   },
      // },
      tooltip: {
        y: {
          title: {
            formatter: (value) => {
              return value + ` ( % ) `;
            },
          },
        },
      },
    },
  };

  return (
    <>
      <Card className="h-100 m-0">
        <Card.Header className=" align-items-center justify-content-between">
          <h5 className="text-center subHeading position-relative mb-4">
            {title}
          </h5>
        </Card.Header>
        <Card.Body className="p-2">
          <div
            id="chart"
            className="d-flex align-items-center justify-content-center"
          >
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="polarArea"
              height={height}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default PolarChart;
