import React from "react";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "../../../../../components/dropdowns";

function CustomDropdown({
  handleSelect,
  dateFilterOptions,
  dropdownType,
  setPayload,
}) {
  return (
    <Dropdown className="d-flex justify-content-center" onSelect={handleSelect}>
      <Dropdown.Toggle
        variant="text-secondary text-capitalize dropdown-toggle"
        as={CustomToggle}
      >
        {dropdownType}
      </Dropdown.Toggle>
      <Dropdown.Menu aria-labelledby="dropdownMenuButton1">
        {dateFilterOptions.map((option, index) => (
          <Dropdown.Item
            key={index}
            eventKey={`action-${index + 1}`}
            onClick={() => {
              let yearValue = "";
              let monthValue = option.value;
              if (option.label === "This Year") {
                yearValue = option.value;
                monthValue = "";
              }
              setPayload({
                year: yearValue,
                month: monthValue,
                isRefresh: "",
              });
            }}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CustomDropdown;
