import React from 'react'
import {

    Badge,
    Button,
    Card,
    Col,
    Form,
    FormCheck,
    Row,
} from "react-bootstrap";
import Skeleton from '../../components/skeleton';
export default function OnboardingTaskDetailsLoader() {
    return (
        <>
            <Card>
                <Card.Body>
                    <div>
                        <div className="d-flex gap-2">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                        <h4 className="mt-4 mb-0"> <Skeleton width={250} /></h4>
                        <p className="mt-1 mb-0">
                            <Skeleton />
                        </p>
                        <div className="d-flex justify-content-end gap-3 mt-1">
                            <span className="text-dark fs-6"> <Skeleton /></span>
                            <span> | </span>
                            <span className="text-dark fs-6">
                                <Skeleton />
                            </span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
}
