import { memo, Fragment } from "react";

// React-bootstrap
import { Col, Dropdown } from "react-bootstrap";

// Router
import { Link } from "react-router-dom";

//Components
import Card from "../../../../components/bootstrap/card";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../dashboard/icons";

const Folder = memo((props) => {
  const { redirectTo } = useRedirect();
  return (
    <Fragment>
      <Col onClick={() => props.redirection && redirectTo(props.redirection)}>
        <Card
          className={`card-folder mb-0 ${props.bgColor ? "bg-soft-primary" : ""}`}
        >
          <Card.Body>
            <div className="d-flex justify-content-between">
              <Link
                to="#"
                className={`avatar-40 bg-soft-${props.bgColor ?? "primary"
                  } rounded-pill d-flex justify-content-center align-items-center`}
              >
                {findIcon(props?.foldersvg, "solid", "24") ||
                  findIcon("Folder", "solid", "24")}
              </Link>
              <Dropdown
                onClick={(e) => {
                  e.cancelBubble = true;
                  if (e.stopPropagation) {
                    e.stopPropagation();
                  }
                }}
              >
                <Dropdown.Toggle
                  as="svg"
                  width="5"
                  viewBox="0 0 5 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="button"
                  id={`dropdownMenu-${props.id}`}
                >
                  <path
                    d="M2.49927 2.50444V2.49544"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.49927 7.50438V7.49538"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.49927 12.5044V12.4954"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="news-dropdown"
                >
                  <Dropdown.Item href="#">Action</Dropdown.Item>
                  <Dropdown.Item href="#">Another action</Dropdown.Item>
                  <Dropdown.Item href="#">Something else here</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="mt-4">
              <h5 className="">{props.foldername}</h5>
              <p className="mb-0">{props?.count || 0} Files</p>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
});

Folder.displayName = "Folder";
export default Folder;
