import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation } from "swiper";
import { Badge } from "react-bootstrap";

const TableHeaderSwiper = ({
  title,
  setActiveCampaignGroup,
  activeCampaignGroup,
}) => {
  // Set the first active campaign group
  useEffect(() => {
    if (title && title.length > 0) {
      setActiveCampaignGroup({
        index: 0,
        id: title[0].id,
      });
    }
  }, [title, setActiveCampaignGroup]);

  return (
    <div className="overflow-hidden d-slider1 flex-grow-1 me-0 me-md-3">
      <Swiper
        as="ul"
        className="p-0 m-0 mb-3 swiper-wrapper list-inline tableHeaderSwiper"
        modules={[Navigation, Mousewheel]}
        data-aos="fade-up"
        data-aos-delay="700"
        slidesPerView="auto"
        mousewheel={{ sensitivity: 1 }}
      >
        {title &&
          title.map((item, index) => {
            return (
              <SwiperSlide className="cursor-pointer" key={index}>
                <Badge
                  pill
                  bg="light text-primary"
                  onClick={() =>
                    setActiveCampaignGroup({
                      index: index,
                      id: item?.id,
                    })
                  }
                  className={
                    index === activeCampaignGroup.index
                      ? "tertiary-bg-black-50 ms-0 me-2 fs-7 px-3 py-2 border-1 border-primary border border-opacity-25 w-auto"
                      : "ms-2 me-2 fs-7 px-3 py-2 bg-opacity-50 text-black-50 mb-1 mb-md-0 w-auto"
                  }
                >
                  {item.group_title}
                </Badge>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default TableHeaderSwiper;
