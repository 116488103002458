export const dateFilterOptions = [
  {
    label: "Monthly",
    value: "monthly",
    initial: true,
  },
  {
    label: "Daily",
    value: "daily",
    initial: false,
  },
  {
    label: "Weekly",
    value: "weekly",
    initial: false,
  },

  // {
  //   label: "Yearly",
  //   value: "yearly",
  //   initial: false,
  // }
];

export const channelReachFilterOptions = [
  {
    label: "Monthly",
    value: "monthly",
    initial: true,
  },
  {
    label: "Weekly",
    value: "weekly",
    initial: false,
  },

  // {
  //   label: "Yearly",
  //   value: "yearly",
  //   initial: false,
  // }
];


export const audienceFilterOptions = [
  {
    label: "Yesterday",
    value: "daily",
    initial: true,
  },
  {
    label: "Last Week",
    value: "weekly",
    initial: false,
  },
  {
    label: "Last Month",
    value: "monthly",
    initial: false,
  }
];

export const videoAnalyticsFilterOptions = [
  {
    label: "Daily",
    value: "daily",
    initial: false,
  },
  {
    label: "weekly",
    value: "weekly",
    initial: false,
  },
  {
    label: "Monthly",
    value: "monthly",
    initial: true,
  },

  {
    label: "Yearly",
    value: "yearly",
    initial: false,
  },
];



export const videoShortOptions = {
  options: ["Publish Date", "Views", "Draft"],
  initial: "Publish Date",
};

export const videoListSortOption = [{
  label: "Publish Date",
  value: "publish_date",
  initial: true,
},
// {
//   label: "Views",
//   value: "views",
//   initial: false,
// },
{
  label: "Draft",
  value: "draft",
  initial: false,
}]

export const privacyOptions = [
  {
    label: "Public",
    value: "public",
    initial: true,
  },
  {
    label: "Private",
    value: "private",
    initial: false,
  }
]

export const statusOptions = [
  {
    label: "All",
    value: "all",
    initial: true,
  },
  {
    label: "Pending",
    value: "pending",
    initial: false,
  },
  {
    label: "Hidden",
    value: "hidden",
    initial: false,
  },
];


export const commentDateOptions = [
  {
    label: "All",
    value: "all",
    initial: false,
  },
  {
    label: "Recent",
    value: "recent",
    initial: false,
  },
  {
    label: "Last Month",
    value: "monthly",
    initial: true,
  },
  {
    label: "Last Week",
    value: "weekly",
    initial: false,
  },
];

export const analyticsdateFilterOptions = {
  options: ["Weekly", "Daliy", "Monthly"],
  initial: "Monthly",
};

export const analyticsSortOptions = {
  options: ["Weekly", "Daliy", "Monthly"],
  initial: "Monthly",
};
