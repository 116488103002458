import React from "react";
import DatamapsIndia from "react-datamaps-india";
import { Card, Row, Col, Button } from "react-bootstrap";

function IndiaMap({ stateData, title, valueSuffix = "" }) {
  const formatMapData = (mapData) => {
    const output = {};
    for (const [key, value] of Object.entries(mapData)) {
      const formattedKey = key.replace(/_/g, " ");
      output[formattedKey] = {
        value: value || 0,
      };
    }
    return output;
  };
  return (
    <>
      <Card className="card h-100 mb-0" data-aos="fade-up" data-aos-delay="500">
        <Card.Header className="">
          <Card.Title>
            <h5 className="text-center subHeading position-relative">
              {title}
            </h5>
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0 text-center india-map position-relative india-map-dashboard">
          {stateData?.length > 0 && (
            <DatamapsIndia
              regionData={formatMapData(stateData[0])}
              hoverComponent={({ value }) => {
                return (
                  <div>
                    <>
                      {value.name}: {value?.value ?? 0}{" "}
                      {valueSuffix}
                    </>
                  </div>
                );
              }}
              mapLayout={{
                // legendTitle: "Darker colour is higher",
                startColor: "#e2e2fc",
                endColor: "#005ce6",
                hoverTitle: "Total",
                noDataColor: "#fff",
                borderColor: "#15607A",
                hoverColor: "#15946C",
                hoverBorderColor: "gray",
                height: 10,
                weight: 30,
              }}
              // mapLayout={{
              //   startColor: "white",
              //   endColor: "#005ce6",
              //   hoverTitle: "Total",
              //   noDataColor: "#13388C",
              //   borderColor: "#15607A",
              //   hoverColor: "#15946C",
              //   hoverBorderColor: "gray",
              // }}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default IndiaMap;
