import React from "react";

// layout

import Default from "../../../../../layouts/dashboard/default";
import AllCouncils from "../pages/all-councils";
// pages

export const CouncilRouter = [
  {
    path: "/master-management/council",
    element: <Default />,
    children: [
      {
        path: "all",
        element: <AllCouncils />,
      },
    ],
  },
];
