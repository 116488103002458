import React, { memo, Fragment, Suspense, useEffect } from "react";

// router-dom
import { Outlet, useParams } from "react-router-dom";

// Header
import Headerpro from "../../../../../../components/partials/pro/headerstyle/header-pro";

// Sidebar
import ChannelVideoSidebar from "../components/partials/channel-video-sidebar";

// Footer
import Footer from "../../../../../../components/partials/dashboard/footerstyle/footer";

// Import selectors & action from setting store
import * as SettingSelector from "../../../../../../store/setting/selectors";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";
// Scss e-commerce
import "../../../../../../assets/custom/scss/custom.scss";
import "../../../../../../assets/modules/e-commerce/scss/e-commerce.scss";
import { setCurrentChannelVideo } from "../../../../../../store/channel/channel.action";

const ChannelVideoLayout = memo(() => {
  const pageLayout = useSelector(SettingSelector.page_layout);
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    dispatch(setCurrentChannelVideo({ video_id: params.video_id }));
  }, []);
  return (
    <Fragment>
      <ChannelVideoSidebar />
      <main className="main-content">
        <div className="position-relative">
          <Headerpro />
        </div>
        <div
          className={`container-fluid content-inner pb-0 ${pageLayout}`}
          id="page_layout"
        >
          <Suspense fallback={<div className="react-load"></div>}>
            <Outlet />
          </Suspense>
        </div>

        <Footer />
      </main>
      {/* <SettingOffCanvas /> */}
    </Fragment>
  );
});

ChannelVideoLayout.displayName = "ChannelVideoLayout";
export default ChannelVideoLayout;
