import Skeleton from "../components/skeleton";
import { Fragment } from "react";

//Componets

import {
  OverlayTrigger,
  Card,
  Tooltip,
  Dropdown,
  Button,
} from "react-bootstrap";

const NotificationLoader = () => {
  return (
    <Fragment>
      <Card className="mb-2 notification_Card position-relative">
        <Card.Body>
          <ul className="notification-list m-0 p-0">
            <li className="d-flex align-items-center justify-content-between gap-3">
              <div className="user-img img-fluid position-relative">
                <Skeleton variant="circular" width={80} height={80} />
              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="w-50">
                    <Skeleton variant="text" width={150} height={20} />
                    <Skeleton variant="text" width={"100%"} height={20} />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton variant="rounded" width={25} height={25} />
                    <div className="card-header-toolbar d-flex align-items-center cursor-pointer flex-column gap-1">
                      <Skeleton variant="circular" width={7} height={7} />
                      <Skeleton variant="circular" width={7} height={7} />
                      <Skeleton variant="circular" width={7} height={7} />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default NotificationLoader;
