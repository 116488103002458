import React, { memo } from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col } from "react-bootstrap";

function SquareChartSkeleton({ height = 270, width = "100%" }) {
  return (
    <Card className="flex-grow-1 m-0">
      <Card.Header className="d-flex align-items-center justify-content-center">
        <Skeleton variant="text" width={"40%"} />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <Skeleton
          variant="square"
          width={width}
          height={height}
          className="rounded-3"
        />
      </Card.Body>
    </Card>
  );
}

export default memo(SquareChartSkeleton);
