import { memo, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Skeleton from "../../../../../../../components/skeleton";
import { findIcon } from "../../../../../../dashboard/icons";
function FetchVideoLinkModal({ onFetchSubmit, ...props }) {
  //   const { redirectTo } = useRedirect();
  const [isLoading, setIsLoading] = useState(false);
  const copyCampaignLink = useRef(null);

  const copyToUrlClipboard = () => {
    if (copyCampaignLink.current) {
      copyCampaignLink.current.select();
      document.execCommand("copy");
    }
  };

  const [inputData, setInputData] = useState("");

  const fetchVideo = () => {
    let splitted = inputData.split("/");
    let filenameWithExt = splitted[splitted.length - 1];

    let filename = filenameWithExt.split(".")[0];
    let ext = filenameWithExt.split(".")[1];

    const cleanedUrl = inputData.split('?')[0];
    const extension = cleanedUrl.split('.').pop();

    console.log("extension", extension);
    fetch(inputData)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], `${filename}.${extension}`, {
          type: `video/${extension}`,
        });
        onFetchSubmit([file]);
      })
      .catch((error) => {
        console.error("Error fetching video:", error);
        // Optionally, show an error message to the user
      });
  };
  function isProperLink(str) {
    // Regular expression to match URLs
    var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(str);
  }

  const onIputChange = (e) => {
    const { value } = e.target;
    if (!isLoading || (isLoading && !value)) {
      setIsLoading(!isLoading);
    }
    setInputData(value);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-4">
        <Form className="mb-4">
          <Row>
            <Col md={12}>
              <h5 className="mb-3">Add Video Url</h5>

              <div className="d-flex gap-2">
                <Form.Control
                  type="text"
                  ref={copyCampaignLink}
                  value={inputData}
                  onChange={onIputChange}
                />
                <Button
                  className="flex-shrink-0 d-inline-flex gap-2 px-3 align-items-center jucstify-content-center"
                  onClick={copyToUrlClipboard}
                // className=""
                >
                  {findIcon("Search Plus", "dual-tone", 22)}
                  Test
                </Button>
              </div>
              <div className="mt-3">
                {/* {isLoading && <Skeleton height={100} width="100%" />} */}
                {isProperLink(inputData) && (
                  <ReactPlayer
                    url={inputData}
                    width="100%"
                    onReady={() => {
                      setIsLoading(false);
                    }}
                    onError={() => {
                      setIsLoading(false);
                    }}
                    controls
                    muted
                    autoplay
                  />
                )}
              </div>
            </Col>
          </Row>
        </Form>

        <div className="d-flex justify-content-between">
          <Button variant="light" onClick={() => props.onHide()}>
            Cancel
          </Button>

          <Button variant="success" onClick={fetchVideo} disabled={!inputData}>
            Fetch Video
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(FetchVideoLinkModal);
