import React, { memo } from "react";
import { Card, Row } from "react-bootstrap";
import Skeleton from "../../../components/skeleton";

function HcpInformationSkeleton() {
  return (
    <Card className="h-100 bg-soft-warning">
      <Card.Header className="d-flex justify-content-between">
        <Skeleton width={100} height={40} />
      </Card.Header>
      <Card.Body>
        <div className="d-inline-flex flex-wrap justify-content-start align-items-center w-100">
          <Row>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-5 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-5 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-7 mb-3">
              <Skeleton width={70} />
            </div>
            <div className="col-4 mb-3">
              <Skeleton width={70} />
            </div>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(HcpInformationSkeleton);
