import { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";

import * as dayjs from "dayjs";
import { isIOS } from "react-device-detect";
import toast from "react-hot-toast";
// setVideoUploadData
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FILE_UPLOAD } from "../../../../../../api/endpoints/requisition-endpoints";
import uploadService from "../../../../../../api/services/upload-service";
import useRedirect from "../../../../../../components/custom-hooks/use-redirect";
import FilePicker from "../../../../../../components/form-components/file-picker";
import { generateVideoThumbnails } from "../../../../../../lib/video-thumbnail-generator";

import FetchVideoLinkModal from "../components/channel-file-upload/FetchVideoLinkModal";
import SuccessModal from "../../../../../../components/common-components/SuccessModal";
import VideoUploadBox from "../components/channel-file-upload/video-upload-box";
import ThumbnailSelector from "../components/video-details/ThumbnailSelector";
import VideoEncodingDetails from "../components/video-details/VideoEncodingDetails";
import { useQueryClient } from "@tanstack/react-query";
import { findIcon } from "../../../../../dashboard/icons";
import useR2FileUpload from "../../../../../../components/custom-hooks/use-r2-file-upload";
import postService from "../../../../../../api/services/post-service";
import { CHANNEL_VIDEO_ADD } from "../../../../../../api/endpoints/channel-video-endpoints";
import { GET_CHANNEL_VIDEO_LIST } from "../../../../../../queries/query-constants/workspace/workspace-constant";
import useS3FileUpload from "../../../../../../components/custom-hooks/use-s3-file-upload";
import useBackdrop from "../../../../../../components/custom-hooks/use-backdrop";
import app_config from "../../../../../../common/data/app_config";

const IMAGE_BASE_URL = app_config.upload_file_destination;
function ChannelFileUpload() {
  const { redirectTo } = useRedirect();
  const queryClient = useQueryClient();
  const { channel_id } = useParams(); // get channel id form url
  const [thumbnail, setThumbnail] = useState(null);
  const dispatch = useDispatch();
  const { uploadFileToStorage, progress } = useR2FileUpload();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchModal, setFetchModal] = useState(false);
  const [thumbLoader, setThumbLoader] = useState(false);
  const [uploadSuccessModal, setUploadSuccessModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isReel, setIsReel] = useState(false);
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [thumbArray, setThumbArray] = useState([]);
  const [videoDuration, setVideoDuration] = useState(null);
  const [videoDetails, setVideoDetails] = useState({
    videoTitle: "",
    videoDescription: "",
    videoThumbnail: "",
    videoFile: null,
    encodingDetails: {
      fileName: "",
      resolution: "",
      bitrate: "",
      fileSize: "",
      fileFormat: "",
      frameRate: "",
      createdOn: "",
      duration: "",
    },
  });

  /**
   *
   * Function to calculate the Greatest Common Divisor (GCD) using the Euclidean algorithm.
   * @param {number} a - First number.
   * @param {number} b - Second number.
   * @returns {number} - The GCD of a and b.
   */

  function gcd(a, b) {
    while (b !== 0) {
      [a, b] = [b, a % b];
    }
    return a;
  }

  /**
   * Function to minimize a fraction.
   * @param {number} numerator - The numerator of the fraction.
   * @param {number} denominator - The denominator of the fraction.
   * @returns {Object} - An object containing the minimized numerator and denominator.
   */
  function minimizeFraction(numerator, denominator) {
    // Calculate the GCD of the numerator and denominator
    const divisor = gcd(Math.abs(numerator), Math.abs(denominator));

    // Divide both the numerator and denominator by the GCD
    const minimizedNumerator = numerator / divisor;
    const minimizedDenominator = denominator / divisor;

    // Return the minimized fraction
    return {
      numerator: minimizedNumerator,
      denominator: minimizedDenominator,
    };
  }

  function extractVideoInformation(videoFile) {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";
      videoElement.onloadedmetadata = () => {
        const { name, type, size } = videoFile;
        const fileName = name;
        const fileSize = size;
        const fileFormat = type;
        const resolution = `${videoElement.videoWidth}x${videoElement.videoHeight}`;
        const duration = videoElement.duration;
        const frameRate = calculateFrameRate(videoElement);
        const resolutionFraction = minimizeFraction(
          videoElement.videoWidth,
          videoElement.videoHeight
        );

        // Calculating bitrate (not directly available in browser)
        // You may need a server-side solution for accurate bitrate calculation

        // Extract creation date (if available)
        const createdOn = videoFile.lastModifiedDate || new Date();

        // Resolve with extracted information
        resolve({
          encodingDetails: {
            fileName,
            resolution,
            bitrate: "N/A",
            fileSize,
            fileFormat,
            frameRate,
            createdOn: dayjs(createdOn).format("YYYY-MM-DD hh:mm:ss"),
            duration,
            resolutionFraction,
          },
        });
      };

      // Set video source to the file object
      videoElement.src = URL.createObjectURL(videoFile);
    });
  }

  // Function to calculate frame rate (approximation)
  function calculateFrameRate(videoElement) {
    return Math.round(videoElement.playbackRate * 100) / 100; // Approximation
  }
  function nameGenerator(filename) {
    // console.log(filename.substr(0, filename.lastIndexOf('.')) + '.png');
    return filename.substr(0, filename.lastIndexOf(".")) + ".png";
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    console.log("UNIT_8", [u8arr]);

    return new File([u8arr], filename, { type: mime });
  }
  const handleFetchSubmit = (files) => {
    if (files?.length > 0) {
      let data = files[0];
      // Assuming `data` contains the fetched video details
      extractVideoInformation(data)
        .then((videoInfo) => {
          let { duration, fileSize } = videoInfo.encodingDetails;
          //max 1 gb file size and 1 hour duration is allowed
          if (duration > 3600 || fileSize > 1073741824) {
            setIsError(true);
          } else {
            if (
              videoInfo?.encodingDetails?.resolutionFraction?.numerator ===
                16 &&
              videoInfo?.encodingDetails?.resolutionFraction?.denominator === 9
            ) {
              setIsReel(false);
            } else {
              setIsReel(true);
            }
            setVideoDetails((prev) => {
              return {
                videoTitle: data?.name,
                videoDescription: prev.videoDescription,
                videoThumbnail: prev.videoThumbnail,
                videoFile: data,
                encodingDetails: videoInfo?.encodingDetails,
              };
            });
          }
        })
        .catch((error) => {
          console.error("Error extracting video information:", error);
          setVideoDetails((prev) => {
            return {
              ...prev,
              videoTitle: data?.name,
              videoDescription: prev.videoDescription,
              videoThumbnail: prev.videoThumbnail,
              videoFile: data,
            };
          });
        });

      setFetchModal(false); // Close the modal after fetching
    }
  };
  function uploadFiles(files) {
    return new Promise(async (resolve, reject) => {
      // Check if files array is empty
      if (files.length === 0) {
        reject("No files to upload.");
        return;
      }
      //console.log("uploading files", files);
      let formdata = new FormData();
      //console.log("FORMDATA", files);
      files.forEach((file) => {
        formdata.append("files[]", file);
      });

      // 1. Upload files to server
      let response = await uploadService(FILE_UPLOAD, formdata);
      if (response.data?.success) {
        // 2. Simulating successful upload and generating URLs
        const urls = response.data.data.map((file) => file.name);

        resolve(urls);
      } else {
        reject("Something went wrong");
      }
    });
  }
  async function postVideoUpload(payload) {
    let payloadObj = {
      content_file_type_id: 3,
      file_type_code: "video",
      content_parent_id: payload.channelId,
      content_title: payload.videoTitle,
      content_description: payload.videoDescription,
      content_featured_status: payload.is_featured ? 1 : 0,
      content_category_id: payload.content_category_id,
      content_category_code: payload.content_category_code,
      content_brand_id: "1",
      content_privacy_status: "0",
      content_status: "1",
      content_publishing_staus: "2",
      content_to_av: {
        title_html: payload.videoTitle,
        description_html: payload.videoDescription,
        path: payload?.videoUrl,
        main: payload?.videoUrl,
        streaming_link: payload?.videoUrl,
        audio_link: payload?.videoUrl,
        transcript: payload?.videoUrl,
        srt_link: payload?.videoUrl,
      },
      content_to_images: {
        thumbnail: payload?.videoThumbnail?.replace(IMAGE_BASE_URL, ""),
        thumbnail_full_url: payload?.videoThumbnail,
        base: payload?.videoThumbnail?.replace(IMAGE_BASE_URL, ""),
        base_full_url: payload?.videoThumbnail,
      },
      content_to_attributes: {
        encoding_details: JSON.stringify(payload?.encodingDetails),
      },
    };
    showBackdrop();
    let response = await postService(CHANNEL_VIDEO_ADD, payloadObj);
    if (response.isError) {
      toast.error(response.error);
      setIsLoading(false);

      hideBackdrop();
    } else {
      queryClient.invalidateQueries({
        queryKey: GET_CHANNEL_VIDEO_LIST,
      });

      setUploadSuccessModal(true);
      setIsLoading(false);

      hideBackdrop();
      setTimeout(() => {
        setUploadSuccessModal(false);
        redirectTo("channel/videos/" + channel_id);
      }, 3000);
    }
  }
  const handleUploadConfirmation = async () => {
    if (!videoDetails.videoFile) {
      toast.error("Please upload a video");
      return;
    }
    if (!videoDetails.videoTitle) {
      toast.error("Please enter video title");
      return;
    }
    if (!videoDetails.videoDescription) {
      toast.error("Please enter video description");
      return;
    }
    if (!thumbnail) {
      toast.error("Please upload a thumbnail");
      return;
    }
    setIsLoading(true);
    try {
      const urls = await uploadFiles([thumbnail]);
      if (urls?.length > 0) {
        let videoUploadData = {
          ...videoDetails,
          videoThumbnail: urls[0],
          channelId: channel_id,
          content_category_id: isReel ? "33" : "30",
          content_category_code: isReel ? "doctube_reels" : "doctube_video",
        };
        uploadFileToStorage(
          videoUploadData?.videoFile,
          (videoUrl, fileName) => {
            postVideoUpload({ ...videoUploadData, videoUrl, fileName });
          }
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleUploadThumbnail = (files, isNewUpdate) => {
    if (!files?.length) return;
    if (isNewUpdate) {
      setThumbnail(files[0]);
      setThumbArray((prev) => [...files, ...prev]);
    } else {
      setThumbnail(files[0]);
    }
  };

  useEffect(() => {
    if (videoDetails?.videoFile && !isIOS) {
      setThumbArray([]);
      setThumbnail(null);
      generateVideoThumbnails(videoDetails?.videoFile, 2)
        .then((thumbs) => {
          if (thumbs?.length > 0) {
            // setThumbnail(dataURLtoFile(thumbs[0], videoDetails?.videoFile?.name));
            let temp_thumbs = [];
            thumbs?.forEach((_thumb, _idx) => {
              temp_thumbs?.push(
                dataURLtoFile(
                  _thumb,
                  nameGenerator(_idx + videoDetails?.videoFile?.name)
                )
              );
            });
            console.log("THUMB_TEMP", temp_thumbs);
            setThumbArray(temp_thumbs);
            setThumbnail(temp_thumbs[0]);
          }
          setThumbLoader(false);
        })
        .catch((e) => console.log("catch", e));
    }
  }, [videoDetails?.videoFile]);
  const onDelete = () => {
    setThumbArray([]);
    setThumbnail(null);
    setVideoDetails((prev) => {
      return {
        ...prev,
        videoFile: null,
        encodingDetails: {
          fileName: "",
          resolution: "",
          bitrate: "",
          fileSize: "",
          fileFormat: "",
          frameRate: "",
          createdOn: "",
          duration: "",
        },
      };
    });
  };
  const onResetClick = () => {
    setThumbArray([]);
    setThumbnail(null);
    setIsLoading(false);
    setVideoDetails({
      videoTitle: "",
      videoDescription: "",
      videoThumbnail: "",
      videoFile: null,
      encodingDetails: {
        fileName: "",
        resolution: "",
        bitrate: "",
        fileSize: "",
        fileFormat: "",
        frameRate: "",
        createdOn: "",
        duration: "",
      },
    });
  };
  return (
    <>
      <h5 className="mb-4 fw-semibold">Upload Video</h5>
      <Card>
        <Card.Body>
          <Row>
            <Col md={12} className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="one-line-clamp">
                  {videoDetails?.encodingDetails?.fileName}
                </h5>
              </div>
            </Col>
            <Col md={5} className="position-relative pb-3 pb-md-0">
              {/* Drag and drop video file */}
              {/* Your code for file upload */}
              {isError ? (
                <ErrorBlock className="custom-file-picker rounded-3 border-0 bg-warning bg-opacity-10 text-black h-100 p-2" />
              ) : videoDetails.videoFile ? (
                <>
                  <span
                    className="position-absolute top-0 end-0 text-danger z-3 mt-n2 me-n2 cursor-pointer bg-white rounded-circle shadow-sm p-2"
                    onClick={onDelete}
                  >
                    {findIcon("Trash", "dual-tone", 22)}
                  </span>
                  {console.log("video_upload_box", videoDetails)}
                  <VideoUploadBox
                    videoFile={videoDetails.videoFile}
                    thumbnail={thumbnail}
                    setVideoDuration={setVideoDuration}
                  />
                </>
              ) : (
                <FilePicker
                  fileExtensionsInfo={"( mp4,aov,wmv,mkv ) file type supported"}
                  withNetworkSpeed
                  className="custom-file-picker rounded-3 border-0 bg-danger bg-opacity-10 text-black h-100 p-2"
                  title={"Add Video"}
                  onUpdate={handleFetchSubmit}
                  isAddIcon={false}
                  accepts={"video/*"}
                  type={"video"}
                  isFetchLinkAvailable
                  onFetchLinkClick={() => setFetchModal(true)}
                  childrenClass="d-flex align-items-center justify-content-center w-100 border rounded-3 flex-column h-100 border-dashed border-dark p-3"
                />
              )}
            </Col>
            <Col xs={12} md={7}>
              <Form className="h-100 d-flex flex-column">
                <FloatingLabel
                  controlId="title"
                  label="Video title"
                  className="mb-3 custom-form-floating "
                >
                  <Form.Control
                    type="text"
                    placeholder="Video Title"
                    value={videoDetails.videoTitle}
                    onChange={(e) =>
                      setVideoDetails({
                        ...videoDetails,
                        videoTitle: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="description"
                  label="Video description"
                  className="custom-form-floating flex-grow-1 h-auto"
                >
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter video description"
                    name="description"
                    value={videoDetails.videoDescription}
                    onChange={(e) =>
                      setVideoDetails({
                        ...videoDetails,
                        videoDescription: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Form>
            </Col>
          </Row>

          {/* Rendering Encoding Details and Thumbnail sections similar to your existing code */}
          {videoDetails?.encodingDetails?.fileName && (
            <div className="w-100 mt-5">
              <Row>
                <Col md={6}>
                  <VideoEncodingDetails
                    fileName={videoDetails.encodingDetails.fileName}
                    resolution={videoDetails.encodingDetails.resolution}
                    fileFormat={videoDetails.encodingDetails.fileFormat}
                    frameRate={videoDetails.encodingDetails.frameRate}
                    bitrate={videoDetails.encodingDetails.bitrate}
                    fileSize={videoDetails.encodingDetails.fileSize ?? 0}
                    duration={videoDetails.encodingDetails.duration}
                    createdOn={videoDetails.encodingDetails.createdOn}
                  />
                </Col>

                <Col md={6}>
                  <ThumbnailSelector
                    buttonTitle={"Upload Thumbnail"}
                    colorVariant="bg-light"
                    selectedImage={thumbnail}
                    onUpdate={handleUploadThumbnail}
                    images={thumbArray}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row className="mt-4">
            <Col md={12} className="d-flex justify-content-end gap-3">
              <Button variant="light" onClick={onResetClick}>
                Reset
              </Button>
              <Button
                variant="info"
                onClick={handleUploadConfirmation}
                disabled={isLoading}
              >
                {isLoading ? "Uploading..." : "Upload"}
              </Button>
            </Col>
          </Row>
        </Card.Body>

        <FetchVideoLinkModal
          show={fetchModal}
          onHide={() => setFetchModal(false)}
          onFetchSubmit={handleFetchSubmit}
        />
      </Card>

      <SuccessModal
        title="Video uploaded successfully."
        message="You can publish your video after processing completed."
        onFallbackClick={() => setUploadSuccessModal(false)}
        buttonText="Close"
        backgroundImage={findIcon("Square Tick", "dual-tone", 100)}
        isFullHeight={false}
        show={uploadSuccessModal}
        onHide={() => setUploadSuccessModal(false)}
      />
      <ProgressModal progress={progress} />
    </>
  );
}

export default memo(ChannelFileUpload);
function ErrorBlock({ className }) {
  return (
    <div
      className={`sidebar-border-profile-card position-relative m-0 ${className}`}
    >
      <div className="sidebar-border-profile-body d-flex justify-content-center align-items-center w-100 border rounded-3 h-100 border-dashed border-dark p-3">
        <Row>
          <Col className="text-warning">
            {findIcon("Warning", "dual-tone", 80)}
          </Col>
          <Col className="text-start">
            <div className="fs-7">
              The uploaded video exceeds the maximum file size limit or
              duration. Please ensure your video is
              <ul className="text-danger mb-2">
                <li>less than 1GB in size</li>
                <li>No longer than 1 hour in duration</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
function ProgressModal(props) {
  return (
    <Modal
      {...props}
      show={props.progress > 0 && props?.progress < 100}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <div
          className={
            "w-100 d-flex justify-content-center align-items-center bg-white rounded-2"
          }
        >
          <div className="container-fluid">
            <section className="w-100">
              <div className=" d-flex justify-content-center align-items-center flex-column position-relative mb-5">
                {findIcon("upload", "dual-tone", 100)}
                <h4 className="fs-4 fw-medium text-black text-center mt-3 mb-1">
                  Please wait while we upload your video
                </h4>
                <div className="w-100 mt-3">
                  <ProgressBar animated now={props.progress} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
