import { memo, Fragment, useEffect, useState } from "react";
// React-bootstrap
import { Row, Col, Form, Image, Button, Container } from "react-bootstrap";

// Router
import { Link, Navigate, useLocation } from "react-router-dom";

//Img
import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.png";

//Component
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import jwt_decode from "jwt-decode";
import postService from "../../../../api/services/post-service";
import {
  GET_GOOGLE_LOGIN_LINK,
  LOGIN_POST,
  USER_PROFILE,
} from "../../../../api/endpoints/auth-endpoints";
import PaginationLoader from "../../../../components/loader/pagination-loader";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import dayjs from "dayjs";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../../../../store/auth/actions";
import { getService } from "../../../../api/services/get-services";
import { useGetMenuList } from "../../../../queries/query-hooks/common-hook";
import useBackdrop from "../../../../components/custom-hooks/use-backdrop";
import { MENU_LIST } from "../../../../api/endpoints/common-endpoints";
import ReactPlayer from "react-player";
const bgvideo =
  "https://client.clirnet.com/themes/client//videos/WhatsApp%20Video%202022-11-09%20at%207.21.28%20PM%20(1).mp4";

const Signin = memo(() => {
  const [loader, showLoader] = useState(false);
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();

  const { showBackdrop, hideBackdrop } = useBackdrop();
  const {
    data: menus,
    isLoading: isMenuFetching,
    refetch,
  } = useGetMenuList(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const schema = yup.object().shape({
    // email: yup.string().email().required("Please enter your email"),
    password: yup.string().min(6).required("Please enter your password"),
    email: yup
      .string()
      .required("Please enter your email")
      .test("Validate Email", "Please enter valid email", (value) => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      }),
  });

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (value, action) => {
      // redirectTo('dashboard')
      loginEmail(value, action);
    },
  });

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const decodedToken = token ? jwt_decode(token) : null;
    const isExpired = decodedToken
      ? dayjs.unix(decodedToken.exp).diff(dayjs()) < 1
      : true;
    if (token && !isExpired) {
      redirectTo(localStorage.getItem("redirectUrl") || "dashboard");
    }
  }, [token]);

  function findFirstLeaf(menuArray) {
    try {
      for (let i = 0; i < menuArray.length; i++) {
        const menu = menuArray[i];
        if (menu.sub_menu.length === 0) {
          return menu;
        } else {
          const leaf = findFirstLeaf(menu.sub_menu);
          if (leaf) {
            return leaf;
          }
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  const loginEmail = async (value, action) => {
    showLoader(true);

    let formData = new FormData();
    formData.append("email", value.email);
    formData.append("password", value.password);

    let response = await postService(LOGIN_POST, formData);

    if (response.isError) {
      showLoader(false);
      toast.error(response?.error);
    } else {
      showLoader(false);
      if (response?.data?.user?.two_factor_secret) {
        redirectTo("auth/two-factor", {
          user: response.data?.user ?? {},
          userRoles: response.data?.userRoles ?? [],
          token: response.data?.access_token ?? "",
        });
        return;
      }
      let access_token = response.data?.access_token;
      updateStorage(storages.local, "access_token", access_token);
      let userResponse = await getService(USER_PROFILE);
      let profile = userResponse.data?.data;
      console.log("profile", profile);
      //sync the values to store

      let menuResponse = await getService(MENU_LIST);

      if (menuResponse.isError) {
        toast.error(menuResponse?.error);
        showLoader(false);
      } else {
        console.log("menuResponse", menuResponse?.data);
        let redirectUrl = findFirstLeaf(menuResponse?.data?.data)?.menu_code;
        dispatch(
          loginSuccess({
            user: profile || {},
            userRoles: profile?.user_has_permissions ?? [],
            token: access_token,
            isAuthenticated: true,
            redirectUrl: redirectUrl,
          })
        );
        action.resetForm();
        localStorage.setItem("redirectUrl", redirectUrl);
        showLoader(false);
        redirectTo(redirectUrl || "dashboard");
      }
    }
  };

  const handleGoogleSignIn = async () => {
    console.log("google sign in");
    let location = window.location;
    let response = await getService(GET_GOOGLE_LOGIN_LINK, {
      redirect_url:
        location.origin +
        process.env.REACT_APP_SUB_PATH +
        "login/callback/google",
    });
    if (response.isError) {
      toast.error(response?.error);
    } else {
      console.log("google login response", response.data);
      let linkResponse = response.data;
      if (linkResponse?.success) {
        let googleOAuthLink = linkResponse?.data;

        window.location.href = googleOAuthLink;
      }
    }
  };

  return (
    <Fragment>
      <div className="iq-auth-page h-auto">
        <div className="landing">
          <div className="landing__banner_area w-100 d-flex position-relative overflow-hidden py-5">
            <ReactPlayer
              url={bgvideo}
              muted
              controls={false}
              playing={true}
              loop
              className="w-100 h-100 top-0 start-0 object_fit_cover position-absolute"
            />
            <div className="overlay"></div>
            <div className="landing__bannerContent px-md-0 px-5  w-100 position-relative">
              <div className="container h-100 position-relative">
                <Row className="align-items-center text-md-start text-center h-100">
                  <div className="col-md-6 col-12">
                    <Image
                      src="https://ik.imagekit.io/clirnetPlatform/client-admin/logo_Oxadv6aGVq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668528150127"
                      className="landing_logo d-inline mb-md-5 mb-4"
                      alt="logo"
                    />
                    <h1 className="fw-light fs-3 text-white bannerContentTtl text-uppercase">
                      Create on India's
                      <br />{" "}
                      <span className="fw-medium">
                        fastest growing community
                      </span>{" "}
                      of doctors today!
                    </h1>
                  </div>
                  <div className="col-md-6 d-flex col-12 align-items-center justify-content-md-end justify-content-center mt-md-0 mt-5">
                    <div className="position-relative loginArea">
                      <div className="loginAreaIn radius-10 bgColorWhite">
                        <h4 className="text-black fw-medium mb-3 fs-3">
                          Login to your account
                        </h4>
                        <p className="text-black fs-7">
                          Sign in using your username and password. To request
                          access, please use the button below.
                        </p>
                        <Form onSubmit={handleSubmit} className="login-form">
                          <Col lg="12" className="mb-3">
                            <Form.Group>
                              {/* <Form.Label>Email address</Form.Label> */}
                              <Form.Control
                                type="email"
                                placeholder="Enter your email address"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg="12" className="mb-3">
                            <Form.Group>
                              {/* <Form.Label>Enter password</Form.Label> */}
                              <Form.Control
                                type="password"
                                placeholder="Enter your password here"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col lg="12" className="mb-3">
                            <Button
                              className="w-100 rounded-3 py-3"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              {loader ? <PaginationLoader /> : "Sign in"}
                            </Button>
                          </Col>
                          {/* <Col lg="12" className="mb-3">
                            <div className="w-100 d-flex justify-content-center align-items-center flex-column">
                              <Link
                                className="mb-2 text-success"
                                onClick={handleGoogleSignIn}
                              >
                                Sign in with Google
                              </Link>
                            </div>
                          </Col> */}
                        </Form>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </div>

          {/* <div className="w-100 landing__bodyRow text-md-start text-center">
            <div className="container position-relative">
                <div className="row d-flex flex-row-reverse align-items-center">
                    <div className="col-md-6 col-12 px-5 mb-md-0 mb-4">
                      <div className="d-flex justify-content-center align-items-center">
                        <Image src="https://ik.imagekit.io/clirnetPlatform/client-admin/campaign-graphics-01_bqMk7OdkL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668528147289" alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 text-black">
                      <h2 className="fs-1 fw-bold mb-md-5 mb-4 bodyRow_ttl">Upload and preview designs in seconds!
                          <span class='ms-md-0 mx-auto d-block rounded-4 bg-black bodyRow_ttl_border mt-3'></span>
                      </h2>
                      <p>Present your designs on the stage they deserve. Drag and drop your creatives to preview exactly how they would appear to users!</p>
                      <p>Optimize your creatives to ensure maximum visual impact for users.</p>
                      <p>Cut the tedious tasks of creating mock ups, visualize your design on the actual platform.</p>
                    </div>
                </div>
            </div>
          </div> */}

          {/* <div className="w-100 landing__bodyRow text-md-start text-center">
            <div className="container position-relative">
                <div className="row d-flex flex-row-reverse align-items-center">
                    <div className="col-md-6 col-12 px-5 mb-md-0 mb-4">
                      <div className="d-flex justify-content-center align-items-center">
                        <Image src="https://ik.imagekit.io/clirnetPlatform/client-admin/laptop_phone-combine_8Frs8guin.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668528150396" alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 text-black">
                      <h2 className="fs-1 fw-bold mb-md-5 mb-4 bodyRow_ttl">What you see is what you get!
                          <span class='ms-md-0 mx-auto d-block rounded-4 bg-black bodyRow_ttl_border mt-3'></span>
                      </h2>
                      <p>With advanced tools to assess impact, put yourself in the shoes of your target audience to engage with creatives based on your designed user journey.</p>
                      <p>No more "imagine how this would look", or "can you show me how this works?", interact with our systems with real time convenience.</p>
                      <p>Cut through the clutter and get the job done! What you see is exactly what you get!</p>
                    </div>
                </div>
            </div>
          </div> */}

          {/* <div className="w-100 landing__bodyRow text-md-start text-center">
            <div className="container position-relative">
                <div className="row d-flex flex-row-reverse align-items-center">
                    <div className="col-md-6 col-12 px-5 mb-md-0 mb-4">
                      <div className="d-flex justify-content-center align-items-center">
                        <Image src="https://ik.imagekit.io/clirnetPlatform/client-admin/campaign-graphics-02_MWo_E-9Cu.png?ik-sdk-version=javascript-1.4.3&updatedAt=1668528147717" alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="col-md-6 col-12 px-5 text-black">
                      <h2 className="fs-1 fw-bold mb-md-5 mb-4 bodyRow_ttl">Request professional assistance in seconds!
                          <span class='ms-md-0 mx-auto d-block rounded-4 bg-black bodyRow_ttl_border mt-3'></span>
                      </h2>
                      <p>Get professionals to assist you in fine tuning and optimizing designs for the deepest impact!</p>
                      <p>Leverage our expertise to ideate creatives which communicate the BIG IDEA first.</p>
                      <p>Get assistance in adapting your ideas for millions!</p>
                    </div>
                </div>
            </div>
          </div> */}
        </div>

        {/* <Autheffect /> */}
        {/* <Container>
          <Row className="d-flex align-items-center iq-auth-container justify-content-center">
            <Col lg="7" className="py-3 py-md-4 text-center text-lg-start">
              <Image
                src={ciplaUnityLogo}
                alt="Unity"
                className="ciplaLogo mb-2"
              />
              <h5 className="h5 lh-base">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h5>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Body>
                  <div className="mb-2">
                    <h3 className="text-center">Sign In</h3>
                    <p className="text-center">Sign in to stay connected</p>
                  </div>
                  <Row>
                    <Form onSubmit={handleSubmit}>
                      <Col lg="12" className="mb-3">
                        <Form.Group>
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your email address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg="12" className="mb-3">
                        <Form.Group>
                          <Form.Label>Enter password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter your password here"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col
                        lg="12"
                        className="mb-3 d-flex justify-content-between align-items-center"
                      >
                        <Link to="/auth/reset-password">Forget password</Link>
                      </Col>

                      <Col lg="12" className="mb-3">
                        <Button
                          className="w-100 rounded-pill"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {loader ? <PaginationLoader /> : "Sign in"}
                        </Button>
                        
                        <Button
                          variant="danger"
                          className="w-100 mt-3 rounded-pill"
                          onClick={handleGoogleSignIn}
                        >
                          Sign in with Google
                        </Button>
                      </Col>
                    </Form>
                  </Row>
                  <p className="text-center">
                    Don't have account?
                    <Link to="/auth/sign-up" className="ms-2">
                      {" "}
                      Click here to sign up.
                    </Link>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container> */}
      </div>
    </Fragment>
  );
});

Signin.displayName = "Signin";
export default Signin;
