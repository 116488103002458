import { Skeleton } from "@mui/material";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const RequisitionEditLoader = () => {
  return (
    <>
      <ul className="text-center iq-product-tracker mb-0 py-4" id="progressbar">
        <li
          className={`active iq-tracker-position-0`}
          id="iq-tracker-position-1"
        >
          Basic
        </li>
        <li className={`iq-tracker-position-0`} id="iq-tracker-position-2">
          Repurpose
        </li>
        <li className={`iq-tracker-position-0`} id="iq-tracker-position-3">
          Associates
        </li>
      </ul>

      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <Skeleton variant="text" width={250}height={40} />
        </div>
      </div>

      <Card>
        <Card.Body>
          <Card className="bg-soft-warning mb-3 shadow-none">
            <Card.Body className="p-3">
              <Skeleton variant="text" width="100%" height={15} />
              <Skeleton variant="text" width="100%" height={15} />
              <Skeleton variant="text" width="30%" height={15} />
            </Card.Body>
          </Card>
          <Row>
            <div className="col-12 col-md-8">
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-4"
              />
              <Skeleton variant="rounded" width="100%" height={110} />
            </div>
            <div className="col-12 col-md-4">
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-4"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-4"
              />
              <Skeleton
                variant="rounded"
                width="100%"
                height={45}
                className="mb-4"
              />
            </div>
            <Col lg="12" className="mb-4 mt-4">
              <Row>
                <Col lg="6" className="mb-3">
                  <div
                    className={`text-secondary border border-1 p-3 rounded-3 w-100 d-flex flex-row gap-2`}
                  >
                    <Skeleton variant="rounded" width="100%" height={45} />
                    <Skeleton variant="rounded" width="100%" height={45} />
                    <Skeleton variant="rounded" width="20%" height={45} />
                  </div>
                </Col>

                <Col
                  lg="2"
                  className="mb-2 d-flex align-items-center justify-content-start"
                >
                  <Skeleton
                    variant="rounded"
                    width="70%"
                    height={40}
                    className="mb-4"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="mb-4 mt-4">
              <Skeleton
                variant="text"
                width={120}
                height={20}
                className="mb-2"
              />
              <Skeleton variant="rounded" width="100%" height={45} />
            </Col>

            <Col lg="12">
              <div className="col-12 form-group d-flex justify-content-end gap-2">
                <Skeleton
                  variant="rounded"
                  width={100}
                  height={40}
                  className="mb-4"
                />
                <Skeleton
                  variant="rounded"
                  width={130}
                  height={40}
                  className="mb-4"
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default RequisitionEditLoader;
