import { memo, useEffect, useState } from "react";

//react-bootstrap
import {
  Button,
  Container,
  Dropdown,
  Form,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";

//router
import { Link, useLocation } from "react-router-dom";

//component
import CustomToggle from "../../../dropdowns";

//img

// logo

// Redux Selector / Action
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
// Import selectors & action from setting store
import * as SettingSelector from "../../../../store/setting/selectors";

import ciplaUnityLogo from "../../../../assets/images/client-logo/ciplaUnityLogo.png";

import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { LOGOUT_POST } from "../../../../api/endpoints/auth-endpoints";
import postService from "../../../../api/services/post-service";
import { logoutSuccess } from "../../../../store/auth/actions";
import useRedirect from "../../../custom-hooks/use-redirect";
import * as dayjs from "dayjs";

const Headerpro = memo((props) => {
  const dispatch = useDispatch();
  const navbarHide = useSelector(SettingSelector.navbar_show); // array
  const themeFontSize = useSelector(SettingSelector.theme_font_size);
  const headerNavbar = useSelector(SettingSelector.header_navbar);
  useEffect(() => {
    if (headerNavbar === "navs-sticky" || headerNavbar === "nav-glass") {
      window.onscroll = () => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementsByTagName("nav")[0].classList.add("menu-sticky");
        } else {
          document
            .getElementsByTagName("nav")[0]
            .classList.remove("menu-sticky");
        }
      };
    }

    document.getElementsByTagName("html")[0].classList.add(themeFontSize);

    //offcanvase code
    const result = window.matchMedia("(max-width: 1200px)");
    window.addEventListener("resize", () => {
      if (result.matches === true) {
        if (show1 === true) {
          document.documentElement.style.setProperty("overflow", "hidden");
        } else {
          document.documentElement.style.removeProperty("overflow");
        }
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    });
    if (window.innerWidth <= "1200") {
      if (show1 === true) {
        document.documentElement.style.setProperty("overflow", "hidden");
      } else {
        document.documentElement.style.removeProperty("overflow");
      }
    } else {
      document.documentElement.style.removeProperty("overflow");
    }
  });
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    const decodedToken = token ? jwt_decode(token) : null;
    const isExpired = decodedToken
      ? dayjs.unix(decodedToken.exp).diff(dayjs()) < 1
      : true;
    if (isExpired) {
      localStorage.clear();
      sessionStorage.clear();
      redirectTo("");
    }
  }, [token]);

  const [show, setShow] = useState(true);

  const [show1, setShow1] = useState(false);

  //collapse
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);
  const [open15, setOpen15] = useState(false);
  const [open16, setOpen16] = useState(false);
  const [open17, setOpen17] = useState(false);

  //fullscreen
  const fullscreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  let location = useLocation();

  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow3(false);
  const handleShow = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose1 = () => setShow4(false);
  const handleShow1 = () => setShow4(true);

  const { redirectTo } = useRedirect();

  const logoutHandler = async () => {
    let response = await postService(LOGOUT_POST, "");
    // console.log('logout data', response);
    if (response.isError) {
      toast.error(response?.error);
    } else {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(logoutSuccess());
      redirectTo();
    }
  };

  return (
    <Navbar
      expand="xl"
      className={`nav iq-navbar header-hover-menu left-border ${headerNavbar} ${navbarHide.join(
        " "
      )}`}
    >
      <Container fluid className="navbar-inner">
        <Link to="#" className="navbar-brand">
          <Image
            src={ciplaUnityLogo}
            alt="Unity"
            className="ciplaLogo logo-title m-0 icon-24 w-auto"
          />
          {/* </div> */}
        </Link>
        <div
          className="sidebar-toggle"
          data-toggle="sidebar"
          data-active="true"
          onClick={minisidebar}
        >
          <i className="icon d-flex">
            <svg width="20px" viewBox="0 0 24 24" className="icon-20">
              <path
                fill="currentColor"
                d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
              />
            </svg>
          </i>
        </div>
        <div className="d-flex align-items-center justify-content-between product-offcanvas">
          <div className="breadcrumb-title border-end me-3 pe-3 d-none d-xl-block">
            <small className="mb-0 text-capitalize">{`${
              location.pathname === "/dashboard"
                ? "home"
                : "" || location.pathname === "/dashboard/alternate-dashboard"
                ? "alternate dashboard"
                : "" ||
                  location.pathname === "/e-commerce/admin/admin-dashboard"
                ? "admin dashboard"
                : "" || location.pathname === "/e-commerce/user/vendor-dashbord"
                ? "vendor dashboard"
                : "" || location.pathname === "/e-commerce/shop-main"
                ? "shop main"
                : "" || location.pathname === "/e-commerce/shop-left-filter"
                ? "shop - left filter"
                : "" || location.pathname === "/e-commerce/shop-right-filter"
                ? "shop - right filter"
                : "" || location.pathname === "/e-commerce/product-full-grid"
                ? "product grid view"
                : "" || location.pathname === "/e-commerce/product-list"
                ? "product list view"
                : "" || location.pathname === "/e-commerce/categories-list"
                ? "categories list"
                : "" || location.pathname === "/e-commerce/product-details"
                ? "product detail"
                : "" || location.pathname === "/e-commerce/product-details-3d"
                ? "3d product detail"
                : "" || location.pathname === "/e-commerce/product-details-360"
                ? "360 product detail"
                : "" || location.pathname === "/e-commerce/order-process"
                ? "order process"
                : "" || location.pathname === "/e-commerce/invoice"
                ? "invoice"
                : "" || location.pathname === "/e-commerce/wishlist"
                ? "wishlist"
                : "" || location.pathname === "/e-commerce/app/user-profile"
                ? "user profile"
                : "" || location.pathname === "/e-commerce/app/user-list"
                ? "user list"
                : "" || location.pathname === "/social/dashboard"
                ? "dashboard"
                : "" || location.pathname === "/social/newsfeed"
                ? "newsfeed"
                : "" || location.pathname === "/social/friend-list"
                ? "friend list"
                : "" || location.pathname === "/social/friend-requset"
                ? "friend request"
                : "" || location.pathname === "/social/friend-profile"
                ? "friend profile"
                : "" || location.pathname === "/social/profile-badges"
                ? "profile badges"
                : "" || location.pathname === "/social/profile-images"
                ? "profile images"
                : "" || location.pathname === "/social/profile-video"
                ? "profile video"
                : "" || location.pathname === "/social/birthday"
                ? "birthday"
                : "" || location.pathname === "/social/notification"
                ? "notification"
                : "" || location.pathname === "/social/notification"
                ? "notification"
                : "" || location.pathname === "/social/notification"
                ? "notification"
                : "" || location.pathname === "/social/account-setting"
                ? "account setting"
                : "" || location.pathname === "/social/event-list"
                ? "event list"
                : "" || location.pathname === "/social/event-detail"
                ? "event detail"
                : "" || location.pathname === "/social/group"
                ? "group"
                : "" || location.pathname === "/social/group-detail"
                ? "group detail"
                : "" || location.pathname === "/social/social-profile"
                ? "social profile"
                : "" || location.pathname === "/blog"
                ? "dashboard"
                : "" || location.pathname === "/blog/blog-main"
                ? "blog main"
                : "" || location.pathname === "/blog/blog-details"
                ? "blog details"
                : "" || location.pathname === "/blog/blog-grid"
                ? "blog grid"
                : "" || location.pathname === "/blog/blog-list"
                ? "blog list"
                : "" || location.pathname === "/blog/blog-trending"
                ? "blog treding"
                : "" || location.pathname === "/blog/blog-comments"
                ? "comment list"
                : "" || location.pathname === "/blog/blog-category"
                ? "blog category"
                : "" || location.pathname === "/appointment/dashboard"
                ? "appointment"
                : "" || location.pathname === "/appointment/book-appointment"
                ? "book appointment"
                : "" || location.pathname === "/appointment/doctor-visit"
                ? "doctor visit"
                : "" || location.pathname === "/file-manager/dashboard"
                ? "appointment"
                : "" || location.pathname === "/file-manager/image-folder"
                ? "image"
                : "" || location.pathname === "/file-manager/video-folder"
                ? "video"
                : "" || location.pathname === "/file-manager/document-folder"
                ? "document"
                : "" || location.pathname === "/file-manager/all-file"
                ? "all file"
                : "" || location.pathname === "/file-manager/trash"
                ? "trash"
                : "" || location.pathname === "/mail/email"
                ? "email"
                : "" || location.pathname === "/mail/email-compose"
                ? "email compose"
                : "" || location.pathname === "/dashboard/table/bootstrap-table"
                ? "Bootstrap Table"
                : "" || location.pathname === "/dashboard/table/table-data"
                ? "Data Table"
                : "" || location.pathname === "/dashboard/table/border-table"
                ? "Border Table"
                : "" || location.pathname === "/dashboard/table/fancy-table"
                ? "Fancy Table"
                : "" || location.pathname === "/dashboard/table/fixed-table"
                ? "Fixed Table"
                : ""
            }`}</small>
          </div>

          <div
            className={`offcanvas-backdrop fade  ${
              show1 === true ? "show d-block" : "d-none"
            }`}
            onClick={() => setShow1(false)}
          ></div>
        </div>
        <div className="d-flex align-items-center">
          <Button
            id="navbar-toggle"
            bsPrefix="navbar-toggler"
            type="button"
            aria-expanded={show1}
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            onClick={() => setShow1(!show1)}
          >
            <span className="navbar-toggler-icon">
              <span className="mt-1 navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </span>
          </Button>
        </div>
        <div
          className={` navbar-collapse ${
            show1 === true ? "collapse show" : "collapse"
          }`}
          id="navbarSupportedContent"
        >
          <ul className="mb-2 navbar-nav ms-auto align-items-center navbar-list mb-lg-0">
            <Dropdown
              as="li"
              className="nav-item  iq-responsive-menu border-end d-block d-xl-none"
            >
              <Dropdown.Toggle as={CustomToggle} className="btn btn-sm bg-body">
                {/* <svg
                  className="icon-20"
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7669"
                    cy="11.7666"
                    r="8.98856"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></circle>
                  <path
                    d="M18.0186 18.4851L21.5426 22"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg> */}
              </Dropdown.Toggle>
              <Dropdown.Menu
                variant="end"
                aria-labelledby="navbarDropdown-search-11"
                style={{ width: "25rem" }}
              >
                <div className="px-3 py-0">
                  <div className="form-group input-group mb-0">
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    /> */}
                    {/* <span className="input-group-text">
                      <svg
                        className="icon-20"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </span> */}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Item
              className=" iq-full-screen d-none d-xl-block"
              onClick={() => setShow(!show)}
            >
              <Nav.Link id="btnFullscreen" onClick={fullscreen}>
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      className={`normal-screen ${
                        show === true ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5528 5.99656L13.8595 10.8961"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M14.8016 5.97618L18.5524 5.99629L18.5176 9.96906"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M5.8574 18.896L10.5507 13.9964"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.60852 18.9164L5.85775 18.8963L5.89258 14.9235"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                    <svg
                      className={`full-normal-screen ${
                        show === false ? "" : "d-none"
                      } icon-24`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.7542 10.1932L18.1867 5.79319"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M17.2976 10.212L13.7547 10.1934L13.7871 6.62518"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.4224 13.5726L5.82149 18.1398"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6.74391 13.5535L10.4209 13.5723L10.3867 17.2755"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Dropdown as="li" className="nav-item iq-tour ps-3 ps-lg-0">
              <Dropdown.Toggle
                as={CustomToggle}
                variant="py-0  d-flex align-items-center nav-link"
              >
                <div className="btn btn-primary btn-icon btn-sm rounded-pill">
                  <span className="btn-inner">
                    <svg
                      width="32"
                      className="icon-32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.997 15.1746C7.684 15.1746 4 15.8546 4 18.5746C4 21.2956 7.661 21.9996 11.997 21.9996C16.31 21.9996 19.994 21.3206 19.994 18.5996C19.994 15.8786 16.334 15.1746 11.997 15.1746Z"
                        fill="currentColor"
                      ></path>
                      <path
                        opacity="0.4"
                        d="M11.9971 12.5838C14.9351 12.5838 17.2891 10.2288 17.2891 7.29176C17.2891 4.35476 14.9351 1.99976 11.9971 1.99976C9.06008 1.99976 6.70508 4.35476 6.70508 7.29176C6.70508 10.2288 9.06008 12.5838 11.9971 12.5838Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="end">
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                {/* <Dropdown.Item href="/dashboard/app/user-privacy-setting">
                  Privacy Setting
                </Dropdown.Item> */}
                <hr className="dropdown-divider" />
                <Dropdown.Item
                  onClick={logoutHandler}
                  // href="/auth/sign-in"
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </Container>
    </Navbar>
  );
});

Headerpro.displayName = "Headerpro";
export default Headerpro;
