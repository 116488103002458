import React, { useEffect } from "react";
import {
  USER_PROFILE,
  VERIFY_GOOGLE_LOGIN,
} from "../../../../api/endpoints/auth-endpoints";
import { getService } from "../../../../api/services/get-services";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { updateStorage } from "../../../../utilities/setting";
import { storages } from "../../../../common/utils/utils";
import { useDispatch } from "react-redux";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import { loginSuccess } from "../../../../store/auth/actions";
import { Spinner } from "react-bootstrap";
import { MENU_LIST } from "../../../../api/endpoints/common-endpoints";

const SocialHandler = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { redirectTo } = useRedirect();
  function findFirstLeaf(menuArray) {
    try {
      for (let i = 0; i < menuArray.length; i++) {
        const menu = menuArray[i];
        if (menu.sub_menu.length === 0) {
          return menu;
        } else {
          const leaf = findFirstLeaf(menu.sub_menu);
          if (leaf) {
            return leaf;
          }
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  }
  const handleGoogleUrlVerification = async (payload) => {
    let response = await getService(VERIFY_GOOGLE_LOGIN, payload);
    if (response.isError) {
      toast.error(response?.error);
    } else {
      console.log("google login response", response?.data);
      let access_token = response?.data?.access_token ?? "";
      if (!access_token) return;
      updateStorage(storages.local, "access_token", access_token);
      let userResponse = await getService(USER_PROFILE);
      let profile = userResponse.data?.data;
      console.log("profile", profile);
      //sync the values to store

      let menuResponse = await getService(MENU_LIST);
      if (menuResponse.isError) {
        toast.error(menuResponse?.error);
      } else {
        console.log("menuResponse", menuResponse?.data);
        if (menuResponse?.data?.success) {
          let redirectUrl = findFirstLeaf(menuResponse?.data?.data)?.menu_code;
          dispatch(
            loginSuccess({
              user: profile || {},
              userRoles: profile?.user_has_permissions ?? [],
              token: access_token,
              isAuthenticated: true,
              redirectUrl: redirectUrl,
            })
          );

          localStorage.setItem("redirectUrl", redirectUrl);
          redirectTo(redirectUrl || "dashboard");
        } else {
          redirectTo("auth/no-permission");
        }
      }
    }
  };
  //function to take search params as parameter and return an object
  const getSearchParams = (search) => {
    let params = new URLSearchParams(search);
    let paramsObj = Object.fromEntries(params.entries());
    return paramsObj;
  };

  useEffect(() => {
    let searchParams = getSearchParams(location.search);
    let payload = {
      ...searchParams,
      redirect_url:
        window.location.origin +
        process.env.REACT_APP_SUB_PATH +
        "login/callback/google",
    };
    handleGoogleUrlVerification(payload);
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ height: "100vh" }}
    >
      <Spinner animation="border" variant="primary" />
      {/* add a loading text */}
      <span className="mt-3 d-block fs-3 text-black">Please Wait...</span>
    </div>
  );
};

export default SocialHandler;
