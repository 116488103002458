import React, { memo } from "react";
import { useParams } from "react-router-dom";
import withAuthorization from "../../../../components/hoc/with-authorization";

const Brand = () => {
  const { type } = useParams();
  console.log('type',type);
  return <>Brand {type}</>;
};

export default withAuthorization(memo(Brand));
