import React, { useState, useEffect } from "react";
import Titlebar from "../components/Titlebar";
import { Card, Row, Col, Button } from "react-bootstrap";
import HeatMapsChart from "../../../../../components/chart/HeatMapsChart";
import PolarChart from "../../../../../components/chart/PolarChart";
import DonutChart from "../../../../../components/chart/DonutChart";
import { initialDate } from "./../constant/date-range-constant";

import TreeMapChart from "../../../../../components/chart/TreeMapChart";
import ReactTable from "../../../../../lib/table/react-table";
import { GRAPH_FILL_COLOR } from "../constant/graph-color-scheme";
import {
  useGetAudienceAgeWiseReport,
  useGetAudienceDayWiseReport,
  useGetAudienceDeviceDistribution,
  useGetAudienceSpecialityCTRReport,
  useGetAudienceSpecialityReport,
  useGetAudienceUserByScreenSize,
  useGetAudienceZonalDistribution,
  useGetSummaryCampaignGroupList,
} from "../../../../../queries/query-hooks/workspace/brand-ads-hook";
import TableHeaderSwiper from "../components/TableHeaderSwiper";
import { findIcon } from "../../../../dashboard/icons";
import {
  formatLocalizedDate,
  getWeeksBetween,
  transformGraphData,
} from "../../../../../common/utils/utils";
import SparlLineBar from "../../../../../components/chart/SparlLineBar";
import { useParams } from "react-router-dom";
import BarChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/bar-chart-skeleton";
import SquareChartSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/square-chart-skeleton";
import CommonTableLoader from "../../../../../skeleton/common-table-loader";
import NavSwiperSkeleton from "../../../../../skeleton/workspace/clirnet/brand-ads/nav-swiper-skeleton";
import { fallbackImages } from "../../../../../utilities/fallback-images";
import PageNotFound from "../../../../PageNotFound";
import { useGetProjectDetails } from "../../../../../queries/query-hooks/workspace/workspace-hook";
import useCsvDownloader from "../../../../../components/custom-hooks/use-csv-downloader";
const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Audience() {
  const { project_id } = useParams();
  const { downloadCSV } = useCsvDownloader();

  const [analysisType, setAnalysisType] = useState("week");

  const { data: projectDetails, isLoading: isProjectDetailsLoading } =
    useGetProjectDetails(project_id);

  const [dateRangePayload, setDateRangePayload] = useState({
    start_date:
      projectDetails?.[0]?.project_start_date || initialDate.start_date,
    end_date: initialDate.end_date,
  });

  useEffect(() => {
    if (projectDetails && projectDetails?.length > 0) {
      setDateRangePayload({
        start_date: formatLocalizedDate(
          new Date(projectDetails[0]?.project_start_date),
          "YYYY-MM-DD[T]00:00:00.000Z"
        ),
        end_date: initialDate.end_date,
      });
    }
  }, [projectDetails]);
  const [activeCampaignGroup, setActiveCampaignGroup] = useState({});

  function getGroupIdsCommaSeparated(data) {
    return data.map((item) => item.group_id).join(",");
  }

  const { data: dayWiseDistribution, isLoading: isDaywiseDistributionLoading } =
    useGetAudienceDayWiseReport(project_id, {
      start_date: dateRangePayload?.start_date,
      end_date: dateRangePayload?.end_date,
      analysis_type: analysisType,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const { data: ageWiseDistribution, isLoading: isAgewiseDistributionLoading } =
    useGetAudienceAgeWiseReport(project_id, {
      start_date: dateRangePayload?.start_date,
      end_date: dateRangePayload?.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });
  const { data: screenSizeWiseData, isLoading: isScreenSizeWiseDataLoading } =
    useGetAudienceUserByScreenSize(project_id, {
      start_date: dateRangePayload?.start_date,
      end_date: dateRangePayload?.end_date,
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const {
    data: zoneWiseDistribution,
    isLoading: isZonewiseDistributionLoading,
  } = useGetAudienceZonalDistribution(project_id, {
    start_date: dateRangePayload?.start_date,
    end_date: dateRangePayload?.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const {
    data: deviceWiseDistribution,
    isLoading: isDevicewiseDistributionLoading,
  } = useGetAudienceDeviceDistribution(project_id, {
    start_date: dateRangePayload?.start_date,
    end_date: dateRangePayload?.end_date,
    campaign_list:
      projectDetails && projectDetails?.length > 0
        ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
        : "",
  });

  const { data: specialityWiseReport, isLoading: isSpecialityReportFetch } =
    useGetAudienceSpecialityReport(
      project_id,
      {
        start_date: dateRangePayload?.start_date,
        end_date: dateRangePayload?.end_date,
        campaign_list: activeCampaignGroup?.id,
      },
      activeCampaignGroup?.id ? true : false
    );

  const {
    data: specialityWisectrReport,
    isLoading: isSpecialityctrReportFetch,
  } = useGetAudienceSpecialityCTRReport(
    project_id,
    {
      start_date: dateRangePayload?.start_date,
      end_date: dateRangePayload?.end_date,
      campaign_list: activeCampaignGroup?.id,
    },
    activeCampaignGroup?.id ? true : false
  );

  const formatDaywiseReportData = (data) => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const result = data.reduce((acc, { Day, label, Reach }) => {
      if (!acc[label]) {
        acc[label] = {
          name: analysisType === "week" ? `Week ${label}` : label,
          data: daysOfWeek.map((day) => ({ x: day, y: 0 })),
        };
      }
      const dayIndex = daysOfWeek.indexOf(Day);
      acc[label].data[dayIndex].y = Reach;
      return acc;
    }, {});

    return Object.values(result)
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      )
      .map((week) => ({
        name: week.name,
        data: week.data,
      }));
  };

  // console.log("daywise report", dayWiseDistribution && dayWiseDistribution?.length > 0 ? formatDaywiseReportData(dayWiseDistribution) : [])
  const performanceDistribution = {
    series:
      dayWiseDistribution && dayWiseDistribution?.length > 0
        ? formatDaywiseReportData(dayWiseDistribution)
        : [],
  };

  const formatAgeGenderData = (inputArray) => {
    return inputArray.reduce((acc, { age_group, gender, user_count }) => {
      // Find the target object for the current gender
      const target = acc.find((item) => item.name === gender);

      // If the target object exists, push the data into its 'data' array
      if (target) {
        target.data.push({ x: age_group, y: user_count });
      } else {
        // If the target object does not exist, create it
        acc.push({
          name: gender,
          data: [{ x: age_group, y: user_count }],
        });
      }

      return acc;
    }, []);
  };
  const demographicAgeGender = {
    series:
      ageWiseDistribution && ageWiseDistribution?.length > 0
        ? formatAgeGenderData(ageWiseDistribution)
        : [],
  };

  const screenSizeChartOptions = {
    label:
      screenSizeWiseData?.length > 0
        ? transformGraphData(
            screenSizeWiseData,
            "impression_percentage",
            "screen_ratio"
          )?.label
        : [],
    series:
      screenSizeWiseData?.length > 0
        ? transformGraphData(
            screenSizeWiseData,
            "impression_percentage",
            "screen_ratio"
          )?.series
        : [],
    Header: "Users by Screen Size",
  };

  function transformHeatMapData(inputArray) {
    if (!inputArray && inputArray.length === 0) return [];
    return inputArray.map(({ name, user_count }) => ({
      x: name,
      y: user_count,
    }));
  }

  const zonalChartOptions =
    zoneWiseDistribution && zoneWiseDistribution?.length > 0
      ? transformHeatMapData(zoneWiseDistribution)
      : [];

  const TableColumnData = [
    {
      Header: "Specialities",
      columnId: 1,
      accessor: "name",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Viewed",
      columnId: 3,
      accessor: "views",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Avg. Frequency",
      columnId: 3,
      accessor: "average_fre",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Mobile impr",
      columnId: 4,
      accessor: "mobile_imp",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Web impr",
      columnId: 5,
      accessor: "web_imp",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Total impr",
      columnId: 6,
      accessor: "total_impr",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Total CTR",
      columnId: 7,
      accessor: "ctr",
      disableFilters: true,
      Cell: ({ value }) => (value ? value : 0),
    },
    {
      Header: "Trends",
      columnId: 8,
      accessor: "date_Data",
      disableFilters: true,
      Cell: ({ value }) =>
        value && value?.length > 0 ? (
          <SparlLineBar series={value.split(",")} labels={DAYS_OF_WEEK} />
        ) : (
          0
        ),
    },
  ];

  const deviceDistributionChartData = {
    series:
      deviceWiseDistribution?.length > 0
        ? transformGraphData(deviceWiseDistribution, "Device_count", "new_os")
            ?.series
        : [],
    label:
      deviceWiseDistribution?.length > 0
        ? transformGraphData(deviceWiseDistribution, "Device_count", "new_os")
            ?.label
        : [],
  };

  const { data: campaignGroupList, isLoading: isCampaignGroupListLoading } =
    useGetSummaryCampaignGroupList(project_id, {
      campaign_list:
        projectDetails && projectDetails?.length > 0
          ? getGroupIdsCommaSeparated(projectDetails[0]?.project_group_data)
          : "",
    });

  const handleTableCsvDownload = () => {
    downloadCSV(tableCsvFormat);
  };

  const showDemographics =
    projectDetails &&
    projectDetails[0].project_attributes &&
    Array.isArray(projectDetails?.[0]?.project_attributes) &&
    projectDetails?.[0]?.project_attributes?.length > 0
      ? projectDetails?.[0]?.project_attributes?.find(
          (item) => item?.attribute_name === "demographic_by_age"
        )?.attribute_value === "true"
        ? true
        : false
      : true;

  function mergedSpecialityData(locationData, locationMatrix) {
    if (!locationMatrix && locationData) {
      return locationData.map((location) => ({
        ...location,
        unique_views: "loading",
        unique_clicks: "loading",
        ctr: "loading",
      }));
    }
    if (locationData && locationData?.length > 0) {
      const mergedData = locationData.map((location) => {
        const matchingMatrix = locationMatrix.find(
          (matrix) => matrix.name === location.name
        );
        return {
          ...location,
          ...(matchingMatrix || {}),
        };
      });
      return mergedData;
    }
  }

  const specialityTableData = mergedSpecialityData(
    specialityWiseReport,
    specialityWisectrReport
  );

  const tableCsvFormat = {
    header: [
      "Specialities",
      "Viewed",
      "Avg. Frequency",
      "Mobile impr",
      "Web impr",
      "Total impr",
      "Total CTR",
    ],
    column: [
      "name",
      "views",
      "average_fre",
      "mobile_imp",
      "web_imp",
      "total_impr",
      "ctr",
    ],
    // chart: {
    //   header: "Trends",
    //   column: "date_Data",
    // },
    rows: specialityTableData,
  };

  return (
    <>
      <Row>
        <Col md={12} sm={12}>
          <Titlebar
            title={"Audience insights"}
            projectType={"Brand Ads"}
            setDateRangePayload={(e) => {
              setDateRangePayload(e);
              if (getWeeksBetween(e.start_date, e.end_date) > 16) {
                setAnalysisType("month");
              } else {
                setAnalysisType("week");
              }
            }}
            projectStartDate={
              projectDetails?.length > 0
                ? projectDetails[0]?.project_start_date
                : "   "
            }
            dateLabel={dateRangePayload}
          />
        </Col>

        <Col md={6} className="mb-4">
          {!isDaywiseDistributionLoading && dayWiseDistribution?.length > 0 ? (
            <HeatMapsChart
              series={performanceDistribution.series}
              title={"Day Wise Performance Distribution"}
            />
          ) : isDaywiseDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        {projectDetails && showDemographics && (
          <Col md={6} className="mb-4">
            {!isAgewiseDistributionLoading &&
            ageWiseDistribution?.length > 0 ? (
              <HeatMapsChart
                series={demographicAgeGender.series}
                title={"Demographic by Age and Gender"}
              />
            ) : isAgewiseDistributionLoading ? (
              <SquareChartSkeleton />
            ) : (
              <PageNotFound
                title="No data found"
                // message="Upload one to start analyzing data!"
                backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
              />
            )}
          </Col>
        )}

        <Col
          md={projectDetails && showDemographics ? 4 : 6}
          sm={12}
          className="mb-4"
        >
          {!isScreenSizeWiseDataLoading && screenSizeWiseData?.length > 0 ? (
            <PolarChart
              labels={screenSizeChartOptions.label}
              series={screenSizeChartOptions.series}
              title={screenSizeChartOptions.Header}
              graphColor={GRAPH_FILL_COLOR}
            />
          ) : isScreenSizeWiseDataLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col
          md={projectDetails && showDemographics ? 4 : 6}
          sm={12}
          className="mb-4"
        >
          {!isZonewiseDistributionLoading &&
          zoneWiseDistribution?.length > 0 ? (
            <TreeMapChart
              title={"Zonal Distribution"}
              series={zonalChartOptions}
            />
          ) : isZonewiseDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        <Col
          md={projectDetails && showDemographics ? 4 : 6}
          sm={12}
          className="mb-4"
        >
          {!isDevicewiseDistributionLoading &&
          deviceWiseDistribution?.length > 0 ? (
            <DonutChart
              series={deviceDistributionChartData.series}
              title={"Device Distribution"}
              labels={deviceDistributionChartData.label}
              graphColor={GRAPH_FILL_COLOR}
            />
          ) : isDevicewiseDistributionLoading ? (
            <SquareChartSkeleton />
          ) : (
            <PageNotFound
              title="No data found"
              // message="Upload one to start analyzing data!"
              backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
            />
          )}
        </Col>

        {console.log("specialityWiseReport", specialityWiseReport)}

        {showDemographics && (
          <Col md={12} sm={12}>
            <div className="d-flex">
              <h5 className="m-0 text-primary border-bottom border-primary border-2 w-auto px-3 pb-2 ms-4">
                Specialities
              </h5>
            </div>
            <Card>
              <Card.Header className="d-flex flex-row flex-md-row">
                {!isCampaignGroupListLoading && campaignGroupList.length > 0 ? (
                  <TableHeaderSwiper
                    title={campaignGroupList}
                    activeCampaignGroup={activeCampaignGroup}
                    setActiveCampaignGroup={setActiveCampaignGroup}
                  />
                ) : (
                  <NavSwiperSkeleton />
                )}

                {!isSpecialityReportFetch &&
                specialityWiseReport?.length > 0 ? (
                  <div className="d-flex flex-row gap-3 flex-shrink-0">
                    {/* <div className="text-success">
                    {findIcon("DownloadReport", "dual-tone", "18")}
                    <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                      Download Report
                    </span>
                  </div> */}
                    <div
                      className="text-success cursor-pointer"
                      onClick={handleTableCsvDownload}
                    >
                      {findIcon("ExportReport", "dual-tone", "20")}
                      <span className="text-black fs-7 px-2 d-none d-md-inline-block">
                        Export
                      </span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Card.Header>
              <Card.Body>
                {!isSpecialityReportFetch &&
                specialityWiseReport?.length > 0 ? (
                  <ReactTable
                    data={
                      specialityTableData
                      // specialityWiseReport?.length > 10
                      //   ? specialityWiseReport.slice(0, 10)
                      //   : specialityWiseReport
                    }
                    columns={TableColumnData}
                    bordered
                    onTableStateChange={() => {}}
                    recordsTotal={
                      // specialityWiseReport?.length > 10
                      //   ? specialityWiseReport.slice(0, 10)?.length
                      //   : specialityWiseReport
                      specialityWiseReport
                    }
                    // recordsPerPage={10}
                    noDataText="no data"
                    noDataImage={fallbackImages.noActionData}
                    isPaginationShow={false}
                  />
                ) : isSpecialityReportFetch ? (
                  <CommonTableLoader tableRowLength={5} />
                ) : (
                  <PageNotFound
                    title="No data found"
                    // message="Upload one to start analyzing data!"
                    backgroundImage={findIcon("Empty-folder", "dual-tone", 100)}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </>
  );
}

export default Audience;
