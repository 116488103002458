import React, { memo } from "react";
import AudienceSkeleton from "./analytics-audience-skeleton";
import DashboardGraphSkeleton from "./dashboard-graph-skeleton";
import Skeleton from "../../../../components/skeleton";
import { Card, Col, Row } from "react-bootstrap";

function CampaignAnalyticsSkeleton() {
  return (
    <Row>
      <Col md={12}>
        <Skeleton variant="text" width="20%" className="mb-4" />
        <DashboardGraphSkeleton height={300} />
      </Col>

      <AudienceSkeleton />
    </Row>
  );
}

export default memo(CampaignAnalyticsSkeleton);
