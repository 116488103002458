import React, { useState } from "react";
import { findIcon } from "../../../../../../dashboard/icons";
import { Image } from "react-bootstrap";
import { usePostVideoComment } from "../../../../../../../queries/query-hooks/workspace/workspace-hook";
import postService from "../../../../../../../api/services/post-service";
import { POST_COMMENT_ACTION } from "../../../../../../../api/endpoints/channel-endpoint";
import toast from "react-hot-toast";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
import { selectProject } from "../../../../../../../store/project/project.selector";
import { useSelector } from "react-redux";

export default function CommentCard({
  comment_id,
  video_id,
  comment,
  username,
  thumbnail,
  videoTitle,
  commentingTime,
  withVideoDetails,
  onCommentAction,
  approvalStatus,
}) {
  const [loading, setLoading] = useState(false);
  const project = useSelector(selectProject);
  const postCommentAction = async (status) => {
    let payloadObj = {
      comment_id: comment_id ?? 0,
      video_id: video_id ?? 0,
      status: status,
      project_id: project?.project_id,
    };
    console.log("video");
    setLoading(status);
    let response = await postService(POST_COMMENT_ACTION, payloadObj);
    if (response.isError) {
      setLoading(false);

      toast.error(response.error);
      onCommentAction();
    } else {
      setLoading(false);
      toast.success("comment updated successfully");
      onCommentAction();
    }
  };

  return (
    <div className="commentsRow d-flex border-bottom border-1 mb-3 pb-3">
      <div className="opacity-50">{findIcon("Quote", "dual-tone", 24)}</div>
      <div className="d-flex flex-column flex-grow-1 flex-shrink-0 pe-3 ps-2 ps-md-3">
        {comment && <p className="m-0 fs-6 fw-light">{comment}</p>}
        <div className="d-flex flex-column flex-md-row justify-content-between pt-2 mt-auto">
          <div className="fs-7 pb-2 pb-md-0">
            {username && <span className="text-black me-3">@{username}</span>}
            <b className="fw-normal">-{commentingTime}</b>
          </div>
          <div className="d-flex gap-4">
            {approvalStatus != 2 && (
              <span
                className="text-danger d-flex align-items-center gap-2 fs-6 cursor-pointer"
                onClick={() => postCommentAction(2)}
              >
                {findIcon("Hidden", "dual-tone", 20)}{" "}
                {loading == 2 ? "Hiding..." : "Hide"}
              </span>
            )}
            {approvalStatus != 1 && (
              <span
                className="text-success d-flex align-items-center gap-2 fs-6 cursor-pointer"
                onClick={() => postCommentAction(1)}
              >
                {findIcon("Checked", "dual-tone", 16)}
                {loading == 1 ? "Publishing..." : "Publish"}
              </span>
            )}
          </div>
        </div>
      </div>
      {withVideoDetails && (
        <div className="videoThumbnail d-flex flex-column flex-md-row gap-3 align-items-center">
          {thumbnail && (
            <Image
              src={thumbnail}
              alt="1"
              className="rounded h-rem-5 w-rem-8 w-auto"
              onError={(e) => {
                e.target.src = fallbackImages.noVideo;
              }}
            />
          )}
          {videoTitle && (
            <p className="m-0 text-uppercase two-line-clamp fs-7 text-black m-0">
              {videoTitle}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
