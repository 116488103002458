import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import Skeleton from "../../../../../components/skeleton";
import { useGetActivityTimelineData } from "../../../../../queries/query-hooks/hcp-profile-hook";
import PageNotFound from "../../../../PageNotFound";

const ActivityTimeline = ({ hcpId, commonPayload }) => {
  const payload = useRef({
    hcp_id: commonPayload.hcp_id,

    default: "all",

    pagination: {
      limit: 3,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
  });

  const { data: activityTimeline, isLoading: isActivityTimelineLoading } =
    useGetActivityTimelineData(payload.current, !!hcpId);

  return (
    <Card data-aos="fade-up" data-aos-delay="600" className="h-100">
      <Card.Header className="flex-wrap d-flex justify-content-between">
        <div className="header-title">
          <h4 className="mb-2 card-title">
            {isActivityTimelineLoading ? (
              <Skeleton width={100} height={40} />
            ) : (
              <>Activity Timeline</>
            )}
          </h4>
          {!isActivityTimelineLoading && activityTimeline?.rows?.length > 0 && (
            <p className="mb-0">
              <svg className="me-2" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="#17904b"
                  d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z"
                />
              </svg>
              This Month
            </p>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        {!isActivityTimelineLoading && activityTimeline?.rows?.length > 0 ? (
          activityTimeline?.rows?.map((data, ind) => (
            <div className="mb-2 d-flex profile-media align-items-top">
              <div className="mt-1 profile-dots-pills rounded-circle border-primary"></div>
              <div className="ms-4">
                <h6 className="mb-1 ">
                  {" "}
                  Content ID: {data?.content_id}
                  <span className="text-primary">{data?.page_title}</span>
                </h6>
                <h6 className="mb-1 ">
                  Source:
                  <span className="text-primary">{data?.utm_source}</span>
                </h6>

                <span className="mb-0 fs-7">{`Activity Time- ${
                  data.duration
                } Sec ${
                  data.activity_date_time ? `On ${data.activity_date_time}` : ""
                }`}</span>
              </div>
            </div>
          ))
        ) : isActivityTimelineLoading ? (
          <Skeleton width={100} />
        ) : (
          <PageNotFound title="No activity data found" isFullHeight={false} />
        )}
      </Card.Body>
    </Card>
  );
};

export default ActivityTimeline;
