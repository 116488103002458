import React, { memo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Skeleton from "../../../../../components/skeleton";

function AppearanceTabSkeleton() {
  return (
    <Card>
      <Card.Body>
        <Col md={12} className="mb-4">
          <Skeleton variant="text" width="10%" />
        </Col>

        <div className="d-flex flex-column gap-5">
          {/* theme */}
          <Row className="mb-5 align-items-md-center">
            {<DoubleTextSkeleton />}
            <Col md={8}>
              <Row>
                {[1, 2, 3].map((_, i) => {
                  return (
                    <Col key={i} xs={6} md={4}>
                      <div className="form-check position-relative m-0 p-0 d-flex">
                        <div class="form-check-label p-2 rounded-3 overflow-hidden border">
                          <div className="w-100 h-auto rounded-2 overflow-hidden">
                            <Skeleton variant="rect" width={180} height={100} />
                          </div>
                        </div>
                      </div>
                      <h6 className="pt-2">
                        <Skeleton variant="text" />
                      </h6>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>

          {/* color  */}
          <Row className="mb-5 align-items-md-center">
            {<DoubleTextSkeleton />}
            <Col md={8}>
              <Row>
                <Col className="d-flex gap-md-4 gap-5">
                  {[1, 2].map((_, i) => {
                    return (
                      <Skeleton
                      key={i}
                        variant="square"
                        className="rounded-4"
                        width={100}
                        height={30}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>

          {/* upload  */}
          <Row className="mb-5 align-items-md-start">
            {<DoubleTextSkeleton />}
            <Col md={8}>
              <Row>
                {[1, 2, 3].map((_, i) => {
                  return (
                    <Col key={i} sm={6} className="mb-3">
                      <Skeleton variant="text" width="60%" />
                      <Skeleton
                        variant="square"
                        className="rounded-2"
                        width="80%"
                        height={40}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>

          {/* button */}
          <Row className="mb-5 align-items-md-start">
            {<DoubleTextSkeleton />}
            <Col md={8}>
              <Row>
                {[1, 2, 3].map((_,i) => {
                  return (
                    <Col key={i} sm={6} className="mb-3">
                      <Skeleton variant="text" width="50%" />
                      <Skeleton
                        variant="square"
                        height={40}
                        className="rounded-3"
                        width="100%"
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>

          {/* tab feature */}
          <Row className="mb-5 align-items-md-center">
            {<DoubleTextSkeleton />}

            <Col md={8}>
              <Row>
                <Col className="d-flex gap-md-4 gap-3 flex-wrap">
                  {[1, 2, 3, 4].map((_, i) => {
                    return (
                      <div key={i} className=" d-flex gap-2 align-items-center">
                        <Skeleton
                          variant="square"
                          width={15}
                          height={15}
                          className="rounded-2 m-0 p-0"
                        />
                        <Skeleton variant="text" className="m-0 p-0" />
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-5 align-items-md-start">
            {<DoubleTextSkeleton />}

            <Col md={8}>
              <Row>
                <Col className="d-flex  gap-3 flex-wrap">
                  {[1, 2, 3, 4].map((_, i) => {
                    return (
                      <div
                        key={i}
                        className=" d-flex gap-2 align-items-center border p-2 rounded-3"
                      >
                        <Skeleton
                          variant="square"
                          width={15}
                          height={15}
                          className="rounded-2 m-0 p-0"
                        />
                        <Skeleton
                          variant="text"
                          width={100}
                          className="m-0 p-0"
                        />
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Row>
          <Col className="mt-5">
            <div className="d-flex justify-content-end gap-3">
              {[1, 2, 3].map((_, i) => {
                return (
                  <Skeleton
                    key={i}
                    variant="square"
                    width="5%"
                    className="rounded-2"
                    height={20}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default memo(AppearanceTabSkeleton);

function DoubleTextSkeleton() {
  return (
    <Col
      md={4}
      className="mb-3 mb-md-0 justify-content-center align-items-center"
    >
      <div className="mb-1">
        <Skeleton variant="text" width="50%" />
      </div>
      <div className="mb-0">
        <Skeleton variant="text" width="70%" />
      </div>
    </Col>
  );
}
