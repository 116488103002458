import React, { memo }  from "react";
import Skeleton from "../../../../components/skeleton";
import { Card, Col } from "react-bootstrap";

function DashboardGraphSkeleton({ height = 360 }) {
  return (
    <Card className="flex-grow-1">
      <Card.Header className="flex-wrap d-flex align-items-center justify-content-between">
        <Card.Title>
          <Skeleton variant="text" width={70} />
        </Card.Title>
        <Skeleton variant="text" width={50} />
      </Card.Header>
      <Card.Body className="p-2 p-md-4">
        <div>
          <Skeleton
            variant="square"
            width="100%"
            height={height}
            className="rounded-3"
          />
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(DashboardGraphSkeleton);
