import React, { memo, Fragment } from "react";
//React-bootstrap
import { Image, Button, Dropdown, Badge } from "react-bootstrap";
//React-router
import { Link } from "react-router-dom";

import useRedirect from "../../../../../../../components/custom-hooks/use-redirect";
import { findIcon } from "../../../../../../dashboard/icons";
import HTMLReactParser from "html-react-parser";
import { fallbackImages } from "../../../../../../../common/constants/fallback-images";
const ChannelCard = memo(({ channel_id, title, projectType, icon, publishDate, coverImage }) => {
  const { redirectTo } = useRedirect();

  const onManageClick = (id) => {
    redirectTo(`channel/dashboard/${id}`);
  };
  return (
    <Fragment>
      <div className="bg-white d-flex rounded-3 p-3 gap-3 mb-3">
        {/* <div className="text-success">
          {findIcon("Channel Icon", "dual-tone", 22)}
       </div> */}
        <div className="d-flex flex-column w-100">
          <div className="w-100 bg-dark text-center rounded-3 d-flex justify-content-center flex-shrink-0 overflow-hidden">
            <Image
              alt={title}
              src={coverImage}
              onError={(e) => e.target.src = fallbackImages.noImage}
              className="h-rem-10 w-auto mw-100 object-fit-cover"
            />
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            {title && <Link className="text-capitalize m-0 text-success">
              {title}
            </Link>}
            {projectType && <span className>{projectType}</span>}
          </div>
          <div className="d-flex mt-2">
            {publishDate && <span className="fs-7 d-flex justify-content-start align-items-center">
              <i className="pe-1 mt-n1">
                {findIcon("Calendar", "outline", 16)}
              </i>
              {publishDate}
            </span>}
            <Button
              className="ms-auto rounded-2 px-3"
              type="submit"
              variant="outline-primary"
              size="sm"
              onClick={() => onManageClick(channel_id)}
            >
              Go to Channel
            </Button>
          </div >
        </div >
        {/* <div>
          <Dropdown>
            <Dropdown.Toggle
              as="svg"
              width="5"
              viewBox="0 0 5 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="button"
            // id={`dropdownMenu-${props.id}`}
            >
              <path
                d="M2.49927 2.50444V2.49544"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.49927 7.50438V7.49538"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.49927 12.5044V12.4954"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-end rounded-3"
              aria-labelledby="news-dropdown"
            >
              <Dropdown.Item href="#">Add User</Dropdown.Item>
              <Dropdown.Item href="#">View Contacts</Dropdown.Item>
              <Dropdown.Item href="#">Details</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div >

    </Fragment >
  );
});

ChannelCard.displayName = "ChannelCard";
export default ChannelCard;
