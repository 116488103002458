import { Skeleton } from "@mui/material";
import React from "react";
import ContentCardLoader from "./content-card-loader";
import { Card, Col, Row } from "react-bootstrap";
export const ContentMasonryLoader = () => {
  return (
    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
      {[...Array(12)].map((_) => {
        return (
          <Col>
            <ContentCardLoader />
          </Col>
        );
      })}
    </Row>
  );
};
const ContentPageLoader = () => {
  return (
    <Row>
      <Col lg="12">
        <Card>
          <Card.Body className="p-3">
            <Row className="h-100">
              <Col md={4}>
                <Card.Body className="d-flex px-3 flex-column h-100 border border-2 border-dashed border-soft-primary rounded-3">
                  {[0, 1, 2, 3, 4, 5, 6].map((_) => (
                    <h4 className="mb-3">
                      <Skeleton
                        width={`${100 - _ * 5}%`}
                        height={30}
                        variant="text"
                      />
                    </h4>
                  ))}
                </Card.Body>
              </Col>

              <Col lg={8}>
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
                  <Skeleton width={"30%"} height={30} variant="text" />
                </div>

                <ContentMasonryLoader />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ContentPageLoader;
