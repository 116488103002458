import React from "react";
import { useSelector } from "react-redux";
import BackdropComponent from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Backdrop = () => {
  const open = useSelector((state) => state.common.isBackdropShown);

  return (
    <BackdropComponent
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </BackdropComponent>
  );
};

export default Backdrop;
