import * as dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GET_SHORT_LINK_VIEW_DETAILS } from "../../../../api/endpoints/short-link-endpoints";
import postShortLinkService from "../../../../api/services/post-short-link-service";
import ReactTable from "../../../../lib/table/react-table";

const ShortLinkDetails = () => {
  const [viewDetailsList, setViewDetailsList] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const { url_key } = useParams();
  const post_json = useRef({
    filter: {},
    pagination: {
      limit: 3,
      offset: 0,
    },
    sort: {
      id: "desc",
    },
  });
  useEffect(() => {
    getShortLinkViewDetails(post_json.current);
  }, [url_key]);

  const slColumn = [
    {
      Header: "IP Address",
      accessor: "ip_address",
    },
    {
      Header: "OS",
      accessor: "operating_system",
    },
    {
      Header: "OS Version",
      accessor: "operating_system_version",
    },
    {
      Header: "Browser",
      accessor: "browser",
    },
    {
      Header: "Browser Version",
      accessor: "browser_version",
    },
    {
      Header: "Device Type",
      accessor: "device_type",
    },
    {
      Header: "HCP Id",
      accessor: "hcp_id",
    },
    {
      Header: "HCP Type",
      accessor: "hcp_type",
    },

    {
      Header: "Token",
      accessor: "token",
    },
    {
      Header: "Visited At",
      accessor: "visited_at",
      Cell: ({ value }) => dayjs(value).format("DD MMM YYYY"),
    },
  ];
  const getShortLinkViewDetails = async (data) => {
    let response = await postShortLinkService(
      GET_SHORT_LINK_VIEW_DETAILS + "/" + url_key,
      data
    );

    if (response.data.success) {
      setViewDetailsList(response?.data?.data?.rows);
      setTotalCount(response?.data?.data?.totalCount);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-4 gap-3">
        <div className="d-flex flex-column">
          <h3 className="m-0">Short URL Visit Details</h3>
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Card>
            <Card.Body>
              {viewDetailsList.length > 0 && (
                <ReactTable
                  data={viewDetailsList}
                  columns={slColumn}
                  recordsTotal={totalCount}
                  // onTableStateChange={onTableStateChange}
                  initialState={post_json.current}
                  isRowSelectionAvailable={false}
                  recordsPerPage={10}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ShortLinkDetails;
