import { useState, memo, Fragment, useEffect, forwardRef, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Card,
  Badge,
  Dropdown,
  Image,
  Nav,
  Table,
} from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import "swiper/css";
import avatars11 from "../../../../assets/images/avatars/01.png";

//Masonry
import Masonry from "react-masonry-css";
// import LeafletMap from "../components/leafletMap";
import useRedirect from "../../../../components/custom-hooks/use-redirect";
import {
  useEngagementMeterData,
  useGetMetaProfileData,
  useHcpActivityList,
  useHcpDetails,
} from "../../../../queries/query-hooks/hcp-profile-hook";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import networkgraph from "highcharts/modules/networkgraph";
import { head } from "lodash";
import PageNotFound from "../../../PageNotFound";

HighchartsExporting(Highcharts);
networkgraph(Highcharts);
const HcpMetaProfile = () => {
  const { redirectTo } = useRedirect();
  const [request_data_change_modal, set_request_data_change_modal] =
    useState(false);

  const [
    change_recommendation_modal_status,
    set_change_recommendation_modal_status,
  ] = useState(false);
  const { id: hcpId } = useParams();

  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetMetaProfileData(hcpId);
  const { hcp_graph_json: hcpMetaProfileData } = metaData || {
    hcp_graph_json: "",
  };
  const onSuccess = (response) => {
    //response
    console.log("reponse", response);
  };

  const onActivitySuccess = (response) => {
    console.log("onActivitySuccess", response);
    console.log("hcpData", generateActivityChartData(response));
  };
  const { data: hcpDetail, isLoading } = useHcpDetails(
    hcpId,
    !!hcpId,
    onSuccess
  );

  const { data: activityList, isLoading: isActivityLoading } =
    useHcpActivityList(hcpId, !!hcpId, onActivitySuccess);

  const { data: engagementMeterData, isLoading: isEngagementMeterDataLoading } =
    useEngagementMeterData(hcpId, !!hcpId);
  const getVariableColor = () => {
    let prefix =
      getComputedStyle(document.body).getPropertyValue("--prefix") || "bs-";
    if (prefix) {
      prefix = prefix.trim();
    }
    const color1 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary`
    );
    const color2 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}info`
    );
    const color3 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}primary-tint-20`
    );
    const color4 = getComputedStyle(document.body).getPropertyValue(
      `--${prefix}warning`
    );
    return {
      primary: color1.trim(),
      info: color2.trim(),
      warning: color4.trim(),
      primary_light: color3.trim(),
    };
  };
  const variableColors = getVariableColor();
  const colors = [
    variableColors.primary,
    variableColors.info,
    variableColors.warning,
  ];

  useEffect(() => {
    return () => colors;
  });

  function generateActivityChartData(input) {
    const output = [];

    const totalValue = Object.values(input).reduce(
      (acc, value) => acc + value,
      0
    );
    let start = 1;

    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        const value = input[key];
        const name = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the name

        const item = {
          hcp_id: input.hcp_id,
          color: variableColors.primary,
          value,
          id: "circle-progress-01",
          svg: <>{value}</>,
          name,
          start,
          end: (start + value / totalValue) * 100,
        };

        output.push(item);
        start = item.end;
      }
    }

    return output;
  }

  const onRequestDataChnageClick = () => {
    set_request_data_change_modal(!request_data_change_modal);
  };

  const onRequestDataChnageSubmit = () => {
    onRequestDataChnageClick();
  };

  const onRecommendationDataChnageClick = () => {
    set_change_recommendation_modal_status(!change_recommendation_modal_status);
  };

  const seriesData = [
    {
      layoutAlgorithm: {
        enableSimulation: true,
        // initialPositions: function () {
        //   const chart = this.series[0].chart,
        //     width = chart.plotWidth,
        //     height = chart.plotHeight;
        //   console.log("Innitial login:", this.nodes?.length);
        //   this.nodes.forEach(function (node) {
        //     // If initial positions were set previously, use that
        //     // positions. Otherwise use random position:
        //     node.plotX =
        //       node.plotX === undefined ? Math.random() * width : node.plotX;
        //     node.plotY =
        //       node.plotY === undefined ? Math.random() * height : node.plotY;
        //   });
        // },
      },

      nodes: hcpMetaProfileData?.length > 0 ? hcpMetaProfileData[0]?.nodes : [],
      data: hcpMetaProfileData?.length > 0 ? hcpMetaProfileData[0]?.data : [],
    },
  ];

  console.log("hcpMetaProfileData:", hcpDetail);
  const onBackToDetailsClick = () => {
    redirectTo(`hcp-profile/details/${hcpId}`);
  };
  return (
    <Fragment>
      <Col>
        <Card className="h-100">
          <Row>
            <Col lg="12">
              <Card className="mb-3">
                <Card.Body className="p-3">
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex flex-wrap align-items-center">
                      {hcpDetail?.profile_img && (
                        <div className="position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                          <Image
                            className="theme-color-default-img  img-fluid rounded-pill avatar-100"
                            src={hcpDetail.profile_img}
                            alt="profile-pic"
                          />
                        </div>
                      )}
                      {(hcpDetail?.hcp_first_name ||
                        hcpDetail?.hcp_to_primary_speciality) && (
                        <div className="d-flex flex-wrap flex-column align-items-start mb-3 mb-sm-0">
                          {
                            <h4 className="me-2 mb-0 fs-5">
                              Meta Profile For{" "}
                              {hcpDetail?.hcp_first_name +
                                " " +
                                hcpDetail.hcp_middle_name +
                                " " +
                                hcpDetail?.hcp_last_name}
                            </h4>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                  {!isMetaDataLoading &&
                  seriesData?.length > 0 &&
                  seriesData[0]?.nodes?.length > 0 &&
                  seriesData[0]?.data?.length > 0 ? (
                    <Col lg="12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            height: 500,
                            type: "networkgraph",
                            plotBorderWidth: 1,
                          },
                          toolbar: {
                            enabled: false,
                          },
                          title: {
                            text: "",
                          },
                          plotOptions: {
                            // layoutAlgorithm: {
                            //   enableSimulation: true,
                            //   // integration: integration,
                            //   // linkLength: linkLength,
                            // },
                            // networkgraph: {
                            //   keys: ["from", "to"],
                            // },
                            marker: {
                              radius: 5,
                              lineWidth: 1,
                            },
                          },
                          series: seriesData,
                        }}
                      />
                    </Col>
                  ) : (
                    !isMetaDataLoading && (
                      <>
                        <PageNotFound
                          title="No Meta Data Found For This HCP"
                          buttonText="Back To Details"
                          onFallbackClick={onBackToDetailsClick}
                        />
                      </>
                    )
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Fragment>
  );
};

export default HcpMetaProfile;
